import { connect } from 'react-redux';
import {
  acceptInvitationFromPractice,
  deleteSentInvitation
} from '../actions/contactsActions';
import ContactsIAmSharingWithReceivedInvitesTableButtons from '../pages/contacts/children/iAmSharingWithReceivedInvites/ContactsIAmSharingWithReceivedInvitesTable/ContactsIAmSharingWithReceivedInvitesTableButtons';
import { immutableToJsHOC } from './ImmutableToJsHOC';

export default connect(null, {
  acceptInvitationFromPractice,
  deleteSentInvitation
})(immutableToJsHOC(ContactsIAmSharingWithReceivedInvitesTableButtons));
