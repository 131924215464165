import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './TableButtonsBlock.styles';
import { isArray } from 'lodash';

const propTypes = {
  children: PropTypes.node.isRequired
};

export default function TableButtonsBlock({ children }) {
  const classes = useStyles();
  const filteredChildren = isArray(children) ? children.filter(el => typeof el === 'object') : [{}];

  return (
    <div data-testid="buttons" className={`${classes.container} ${filteredChildren.length === 1 && classes.singleButton}`}>
      {children}
    </div>
  );
}

TableButtonsBlock.propTypes = propTypes;
