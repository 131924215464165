import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function CaloriesIcon({ className }) {
  let classes = 'c-icon c-icon--food';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 20 22">
      <title>Calories Consumed</title>
      <desc>Created with Sketch.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(0.000000, 1.000000)">
            <g fill="#282829">
              <path d="M15.755,11.4844 L15.076,16.0634 C15.003,16.5594 14.597,16.9254 14.12,16.9254 L6.161,16.9254 C5.684,16.9254 5.278,16.5594 5.205,16.0634 L4.534,11.5324 C3.413,11.2234 2.542,10.3164 2.289,9.1724 C2.153,7.1354 4.047,7.0434 4.047,7.0434 C6.073,7.3444 6.498,5.8164 6.498,5.8164 C8.66,5.9524 7.958,4.1904 7.958,4.1904 C8.728,4.0544 8.855,3.4164 8.86,2.9484 C9.444,2.6964 10.09,2.5534 10.772,2.5534 C12.927,2.5534 14.738,3.9544 15.273,5.8574 C16.655,6.0854 17.711,7.2854 17.711,8.7334 C17.711,10.0064 16.893,11.0874 15.755,11.4844 M10,0.0004 C4.477,0.0004 0,4.4774 0,10.0004 C0,15.5224 4.477,20.0004 10,20.0004 C15.523,20.0004 20,15.5224 20,10.0004 C20,4.4774 15.523,0.0004 10,0.0004"/>
            </g>
            <path d="M13.3253,11.7666 C13.0343,11.7236 12.7643,11.9376 12.7233,12.2446 L12.3293,15.2016 C12.2883,15.5076 12.4903,15.7916 12.7823,15.8346 C12.8063,15.8386 12.8323,15.8406 12.8563,15.8406 C13.1183,15.8406 13.3463,15.6376 13.3843,15.3576 L13.7783,12.4006 C13.8183,12.0936 13.6163,11.8106 13.3253,11.7666" fill="#2CA9DF"/>
            <path d="M10.1407,11.7617 C9.8467,11.7617 9.6077,12.0127 9.6077,12.3227 L9.6077,15.2797 C9.6077,15.5887 9.8467,15.8397 10.1407,15.8397 C10.4347,15.8397 10.6727,15.5887 10.6727,15.2797 L10.6727,12.3227 C10.6727,12.0127 10.4347,11.7617 10.1407,11.7617" fill="#2CA9DF"/>
            <path d="M6.5037,12.4006 L6.8977,15.3576 C6.9357,15.6376 7.1637,15.8406 7.4247,15.8406 C7.4487,15.8406 7.4747,15.8386 7.4987,15.8346 C7.7907,15.7916 7.9937,15.5076 7.9527,15.2016 L7.5577,12.2446 C7.5177,11.9376 7.2487,11.7236 6.9567,11.7666 C6.6647,11.8106 6.4627,12.0936 6.5037,12.4006 Z" fill="#2CA9DF"/>
            <polygon id="Fill-10" fill="#282829" points="6.8898 7.5322 6.0118 7.5322 4.7628 8.8582 4.7628 7.5322 4.0588 7.5322 4.0588 10.7302 4.7628 10.7302 4.7628 9.7002 5.1658 9.2932 6.1288 10.7302 7.0108 10.7302 5.6398 8.7962"/>
            <path d="M7.9083,10.3877 L7.9553,10.3877 C8.2303,10.5477 8.5233,10.6747 8.8223,10.7857 C8.5233,10.6747 8.2303,10.5477 7.9553,10.3877 L7.9083,10.3877 C7.8693,10.4197 7.8253,10.4437 7.7843,10.4747 C7.8253,10.4437 7.8693,10.4197 7.9083,10.3877" fill="#5D5D5D"/>
            <path d="M7.8116,10.4942 C7.8956,10.5552 7.9646,10.6312 8.0646,10.6742 C8.1016,10.6892 8.1426,10.6962 8.1806,10.7102 C8.0616,10.6332 7.9386,10.5642 7.8286,10.4772 C7.8236,10.4842 7.8166,10.4882 7.8116,10.4942"fill="#282829"/>
            <path d="M7.9083,10.3877 C7.8693,10.4197 7.8253,10.4437 7.7843,10.4747 C7.7933,10.4817 7.8033,10.4877 7.8113,10.4937 C7.8163,10.4887 7.8233,10.4847 7.8283,10.4777 C7.9383,10.5647 8.0623,10.6327 8.1803,10.7097 C8.3473,10.7667 8.5263,10.8017 8.7253,10.8017 C8.7613,10.8017 8.7883,10.7877 8.8223,10.7857 C8.5233,10.6747 8.2303,10.5477 7.9553,10.3877 L7.9083,10.3877 Z" fill="#282829"/>
            <path d="M7.9557,10.3877 C8.2307,10.5477 8.5237,10.6747 8.8227,10.7857 C8.9797,10.7777 9.1327,10.7617 9.2677,10.7117 C9.4327,10.6527 9.5757,10.5667 9.6977,10.4517 C9.8197,10.3387 9.9187,10.2017 9.9937,10.0407 C10.0677,9.8787 10.1147,9.6997 10.1317,9.4987 L9.4517,9.4987 C9.4247,9.7137 9.3507,9.8867 9.2297,10.0187 C9.1087,10.1497 8.9407,10.2147 8.7257,10.2147 C8.5677,10.2147 8.4337,10.1847 8.3227,10.1227 C8.2127,10.0627 8.1227,9.9807 8.0547,9.8787 C7.9857,9.7787 7.9347,9.6637 7.9037,9.5357 C7.8727,9.4097 7.8567,9.2797 7.8567,9.1447 C7.8567,9.0047 7.8727,8.8677 7.9037,8.7377 C7.9347,8.6057 7.9857,8.4897 8.0547,8.3857 C8.1227,8.2837 8.2127,8.1997 8.3227,8.1397 C8.4337,8.0777 8.5677,8.0477 8.7257,8.0477 C8.8127,8.0477 8.8947,8.0617 8.9747,8.0897 C9.0537,8.1177 9.1247,8.1587 9.1867,8.2087 C9.2497,8.2597 9.3017,8.3187 9.3437,8.3857 C9.3857,8.4527 9.4127,8.5267 9.4247,8.6077 L10.1047,8.6077 C10.0847,8.4227 10.0337,8.2577 9.9527,8.1157 C9.8727,7.9717 9.7697,7.8517 9.6457,7.7547 C9.5217,7.6577 9.3817,7.5827 9.2257,7.5327 C9.0687,7.4817 8.9017,7.4557 8.7257,7.4557 C8.4807,7.4557 8.2607,7.4997 8.0647,7.5857 C7.8697,7.6727 7.7047,7.7917 7.5707,7.9447 C7.4357,8.0967 7.3327,8.2757 7.2617,8.4797 C7.1897,8.6847 7.1537,8.9067 7.1537,9.1447 C7.1537,9.3777 7.1897,9.5947 7.2617,9.7967 C7.3327,9.9977 7.4357,10.1737 7.5707,10.3237 C7.6287,10.3877 7.7147,10.4227 7.7847,10.4747 C7.8247,10.4437 7.8697,10.4197 7.9087,10.3877 L7.9557,10.3877 Z" fill="#282829"/>
            <path d="M11.4171,9.4942 L11.8341,8.3202 L11.8431,8.3202 L12.2461,9.4942 L11.4171,9.4942 Z M11.4841,7.5322 L10.2751,10.7302 L10.9831,10.7302 L11.2331,10.0182 L12.4301,10.0182 L12.6711,10.7302 L13.4011,10.7302 L12.2051,7.5322 L11.4841,7.5322 Z" fill="#282829"/>
            <polygon id="Fill-22" fill="#282829" points="14.382 7.5322 13.679 7.5322 13.679 10.7302 15.941 10.7302 15.941 10.1382 14.382 10.1382"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

CaloriesIcon.propTypes = propTypes;

export default CaloriesIcon;
