/* istanbul ignore file */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Container, Grid } from '@material-ui/core';
import ContactsInviteBtn from './children/invites/ContactsInviteBtn/ContactsInviteBtn';
import ContactsIAmSharingWithViewResolver from 'src/components/contacts/ContactsIAmSharingWithViewResolver/ContactsIAmSharingWithViewResolver';
import ContactsSharedWithMeViewResolver from 'src/components/contacts/ContactsSharedWithMeViewResolver/ContactsSharedWithMeViewResolver';
import CountlyTrackedRoute from 'src/components/countly/CountlyTrackedRoute';
import { PAGE_NAMES } from 'src/constants/routesCountlyNameConstants';
import routesConstants from 'src/constants/routes';
import WithOnboardingTracking from 'src/containers/withOnboardingTracking';
import { useStyles } from './Contacts.styles';

function Contacts() {
  const {
    CONTACTS_SHARED_WITH_ME,
    CONTACTS_I_AM_SHARING_WITH,
    CONTACTS
  } = routesConstants;
  const {
    CONTACTS_I_AM_SHARING_WITH: I_AM_SHARING_WITH,
    CONTACTS_SHARED_WITH_ME: SHARED_WITH_ME
  } = PAGE_NAMES;

  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useState(CONTACTS_SHARED_WITH_ME);
  const classes = useStyles();

  // Init with default values
  useEffect(() => {
    location.pathname === CONTACTS && history.replace(CONTACTS_I_AM_SHARING_WITH);
  }, []);

  useEffect(() => {
    const tabValue = location.pathname.indexOf(CONTACTS_I_AM_SHARING_WITH) !== -1 ? CONTACTS_I_AM_SHARING_WITH : CONTACTS_SHARED_WITH_ME;
    setTab(tabValue);
  }, [location.pathname]);

  const handleChange = useCallback((event, linkTo) => {
    history.replace(linkTo);
  }, []);

  return (
    <WithOnboardingTracking type="care">
      <Container maxWidth="lg" className="h-padded-top-lg">
        <Grid container alignItems="flex-end">
          <div className={classes.left}>
            <AppBar position="static" className={classes.root} elevation={0}>
              <Tabs value={tab} onChange={handleChange} className={classes.tabs} aria-label="contacts page">
                <Tab
                  value={CONTACTS_I_AM_SHARING_WITH}
                  label="I AM SHARING WITH"
                  className={classes.tab}
                />
                <Tab
                  value={CONTACTS_SHARED_WITH_ME}
                  label="SHARED WITH ME"
                  className={classes.tab}
                />
              </Tabs>
            </AppBar>
          </div>

          <div className={classes.right}>
            {tab !== CONTACTS_SHARED_WITH_ME && (<ContactsInviteBtn />)}
          </div>
        </Grid>

        <Grid container className={classes.routeWrapper}>
          <CountlyTrackedRoute
            pageName={I_AM_SHARING_WITH}
            path={CONTACTS_I_AM_SHARING_WITH}
            component={ContactsIAmSharingWithViewResolver}
          />
          <CountlyTrackedRoute
            pageName={SHARED_WITH_ME}
            path={CONTACTS_SHARED_WITH_ME}
            component={ContactsSharedWithMeViewResolver}
          />
        </Grid>
      </Container>
    </WithOnboardingTracking>
  );
}

export default Contacts;
