/* istanbul ignore file */
import React from 'react';
import { Iterable } from 'immutable';
import entries from 'lodash/entries';
import reduce from 'lodash/reduce';

export const immutableToJsHOC = WrappedComponent => wrappedComponentProps => {
  const KEY = 0;
  const VALUE = 1;
  const propsEntries = entries(wrappedComponentProps);
  const propsJS = reduce(
    propsEntries,
    (newProps, wrappedComponentProp) => {
      newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(
        wrappedComponentProp[VALUE]
      ) ? wrappedComponentProp[VALUE].toJS() : wrappedComponentProp[VALUE];
      return newProps;
    },
    {}
  );

  return <WrappedComponent {...propsJS} />;
};
