import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '1px'
  },
  'color-default': {
    borderBottom: `1px solid ${theme.palette?.background?.main}`
  },
  'color-body-light': {
    borderBottom: theme.palette?.text?.light // #B7B7B7
  },
  'v-padding-2': {
    paddingTop: '2Px',
    paddingBottom: '2Px'
  },
  'v-padding-4': {
    paddingTop: '4Px',
    paddingBottom: '4Px'
  },
  'v-padding-6': {
    paddingTop: '6Px',
    paddingBottom: '6Px'
  },
  'v-padding-8': {
    paddingTop: '8Px',
    paddingBottom: '8Px'
  },
  'v-padding-12': {
    paddingTop: '12Px',
    paddingBottom: '12Px'
  },
  'v-padding-16': {
    paddingTop: '16Px',
    paddingBottom: '16Px'
  },
  'v-padding-24': {
    paddingTop: '24Px',
    paddingBottom: '24Px'
  },
  'v-padding-48': {
    paddingTop: '48Px',
    paddingBottom: '48Px'
  },
  'v-padding-56': {
    paddingTop: '56Px',
    paddingBottom: '56Px'
  },
  'v-padding-64': {
    paddingTop: '64Px',
    paddingBottom: '64Px'
  }
}));
