import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ChevronIcon from './icons/ChevronIcon';
import MuiTextField from './muiComponents/MuiTextField';
import { normalizeMaxPasswordLength } from '../services/fieldNormalizationService';
import formValidationService from '../services/formValidation';
import { Paper, Dialog } from '@material-ui/core';
import { useStyles } from './ResetPassword.styles';

const propTypes = {
  resetPassword: PropTypes.func.isRequired
};

export default function ResetPassword(props) {
  const { token } = useParams();
  const [submitted, setSubmitted] = useState(false);

  const submit = async (values) => {
    try {
      await props.resetPassword(token, values);
      setSubmitted(true);
    } catch (err) {
      return { password: err.message };
    }
  };

  /* istanbul ignore next */
  const validateFields = (values) => {
    return new formValidationService.Validation()
      .password(values.password)
      .passwordComplexity(values.password)
      .passwordMatches(values.password, values.passwordConfirmation)
      .passwordMaxLength(values.password)
      .getErrors();
  };

  const classes = useStyles();

  return (
    <div className="c-card">
      <div className="c-card__header c-card__header--borderless c-card__header--xs">
        <Link className="c-nav-header" to="/welcome">
          <ChevronIcon/>
          <h2 className="c-nav-header__label">Reset Password</h2>
        </Link>
      </div>
      <div className="c-card__body">
        <div className="h-padded h-center h-flex--justify-center">
          <span>Please enter your new password </span>
        </div>
        <Form onSubmit={submit} validate={validateFields}>
          {({ handleSubmit, pristine, submitting }) => {
            return (
              <form
                name="resetPassword"
                className="c-form"
                onSubmit={handleSubmit}
              >
                <fieldset className="c-fieldset">
                  <Field
                    autoComplete="new-password"
                    autoCorrect="off"
                    spellCheck="off"
                    component={MuiTextField}
                    floatingLabelFixed
                    label="Password"
                    name="password"
                    parse={normalizeMaxPasswordLength}
                    props={{ errorHiddenUnderText: 'Your secure password must be at least 8 characters in length and contain at least one uppercase letter, one lowercase letter and one number.' }}
                    type="password"
                  />
                  <Field
                    autoComplete="new-password"
                    autoCorrect="off"
                    spellCheck="off"
                    component={MuiTextField}
                    floatingLabelFixed
                    label="Confirm Password"
                    name="passwordConfirmation"
                    type="password"
                  />
                </fieldset>
                <button
                  className="c-button c-button--block c-button--action c-button--submit"
                  disabled={pristine || submitting}
                  type="submit"
                  value="Submit"
                >
                  Submit
                </button>
              </form>
            );
          }}
        </Form>
      </div>

      <Dialog
        open={submitted}
        scroll="paper"
        data-testid="dialog-invites"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperComponent={Paper}
        maxWidth="md"
        PaperProps={{
          elevation: 0, classes: { root: classes.paper }
        }}
        BackdropProps={{
          classes: { root: classes.backDrop }
        }}
      >
        <div className="c-success-message">
          <svg className="c-icon" viewBox="0 0 80 80">
            <path fill="#FFFFFF" d="M40,80 C17.90861,80 0,62.09139 0,40 C0,17.90861 17.90861,0 40,0 C62.09139,0 80,17.90861 80,40 C80,62.09139 62.09139,80 40,80 Z M28.5970185,39.8049654 C26.5164617,37.9892714 23.3579264,38.2039851 21.5422324,40.2845418 C19.7265384,42.3650986 19.9412521,45.5236339 22.0218088,47.3393279 L32.5815161,56.5547445 C34.7708124,58.4653352 38.1220566,58.1136929 39.8670834,55.790278 L59.8953837,29.1236114 C61.5537372,26.9155977 61.1081496,23.7812877 58.9001359,22.1229341 C56.6921222,20.4645806 53.5578122,20.9101682 51.8994587,23.1181819 L35.1025937,45.4823563 L28.5970185,39.8049654 Z" id="Combined-Shape"/>
          </svg>
          <h1 className="c-success-message__title">Success!</h1>
          <p className="c-success-message__p">Your password has been reset</p>
          <Link className="c-success-message__link" to="/welcome/sign-in">Continue</Link>
        </div>
      </Dialog>
    </div>
  );
}

ResetPassword.propTypes = propTypes;
