import React from 'react';

export default function GoodMoodIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="#282829" fillRule="evenodd">
        <path d="M11.996 19.39c-3.65 0-6.68-2.245-7.046-5.22l-.012-.096.076-.11a.268.268 0 0 1 .199-.09l.086.014c1.221.428 2.867 2.115 6.801 2.115 3.82 0 5.007-1.506 6.583-2.184l.105-.022c.075 0 .147.033.197.088l.064.07.002.134c-.32 3.022-3.353 5.3-7.055 5.3M8.96 5.967c.894 0 1.377 1.61 1.377 3.124s-.483 3.125-1.377 3.125c-.893 0-1.375-1.61-1.375-3.125 0-1.514.482-3.124 1.375-3.124m6.08 0c.893 0 1.375 1.61 1.375 3.124s-.482 3.125-1.375 3.125c-.895 0-1.377-1.61-1.377-3.125 0-1.514.482-3.124 1.377-3.124M12 1C5.935 1 1 5.936 1 12c0 6.066 4.935 11 11 11s11-4.934 11-11c0-6.064-4.935-11-11-11"/>
        <path d="M11.996 19.39c-3.65 0-6.68-2.245-7.046-5.22l-.012-.096.076-.11a.268.268 0 0 1 .199-.09l.086.014c1.221.428 2.867 2.115 6.801 2.115 3.82 0 5.007-1.506 6.583-2.184l.105-.022c.075 0 .147.033.197.088l.064.07.002.134c-.32 3.022-3.353 5.3-7.055 5.3M8.96 5.967c.894 0 1.377 1.61 1.377 3.124s-.483 3.125-1.377 3.125c-.893 0-1.375-1.61-1.375-3.125 0-1.514.482-3.124 1.375-3.124m6.08 0c.893 0 1.375 1.61 1.375 3.124s-.482 3.125-1.375 3.125c-.895 0-1.377-1.61-1.377-3.125 0-1.514.482-3.124 1.377-3.124M12 1C5.935 1 1 5.936 1 12c0 6.066 4.935 11 11 11s11-4.934 11-11c0-6.064-4.935-11-11-11"/>
      </g>
    </svg>
  );
}
