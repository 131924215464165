import React from 'react';
import PropTypes from 'prop-types';
import MinusIcon from 'src/components/icons/MinusIcon';
import FoodIcon from 'src/components/icons/FoodIcon';
import InsulinIcon from 'src/components/icons/InsulinIcon';
import WaterIcon from 'src/components/icons/WaterIcon';
import BadMoodIcon from 'src/components/icons/mood/BadMoodIcon';
import GoodMoodIcon from 'src/components/icons/mood/GoodMoodIcon';
import NeutralMoodIcon from 'src/components/icons/mood/NeutralMoodIcon';
import StressedMoodIcon from 'src/components/icons/mood/StressedMoodIcon';
import SickMoodIcon from 'src/components/icons/mood/SickMoodIcon';
import CommentsIcon from 'src/components/icons/CommentsIcon';

const propTypes = {
  tags: PropTypes.array
};

const iconRow = [
  { type: 'carbs', component: FoodIcon },
  { type: 'mood' },
  { type: 'water', component: WaterIcon },
  { type: 'insulin', component: InsulinIcon },
  { type: 'comment', component: CommentsIcon }
];

const moodComponents = {
  bad: BadMoodIcon,
  good: GoodMoodIcon,
  neutral: NeutralMoodIcon,
  stressed: StressedMoodIcon,
  sick: SickMoodIcon
};

function LogIconRow({ tags }) {
  if (!tags) {
    return null;
  }

  const moodTag = tags.find(tag => tag.type === 'mood');
  const commentTag = tags.find(tag => tag.type === 'comment');

  return (
    <React.Fragment>
      {iconRow.map((icon, i) => {
        if (icon.type === 'mood' && moodTag) {
          const MoodComponent = moodComponents[moodTag.value];
          return (
            <span className="c-log__icon-wrap" key={i}>
              <MoodComponent key={i}/>
            </span>
          );
        }

        if (icon.type === 'comment' && commentTag) {
          return (
            <span className="c-log__icon-wrap" key={i}>
              {Boolean(commentTag?.value) ?
                <icon.component key={i}/> :
                <MinusIcon key={i}/>}
            </span>
          );
        }

        return (
          <span className="c-log__icon-wrap" key={i}>
            {tags.find(tag => tag.type === icon.type) ?
              <icon.component key={i}/> :
              <MinusIcon key={i}/>}
          </span>
        );
      })}
    </React.Fragment>
  );
}

LogIconRow.propTypes = propTypes;

export default LogIconRow;
