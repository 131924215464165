/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default function MenuIcon({ isActive, onClick }) {
  const buttonClasses = classNames({
    'c-menu-icon h-bg-none': true,
    'is-active': isActive
  });

  return (
    <button className={buttonClasses} data-testid={`openstate-${isActive}`} onClick={onClick}>
      <div className="c-menu-icon__icon">
        <span className="c-menu-icon__line" />
        <span className="c-menu-icon__line" />
        <span className="c-menu-icon__line" />
      </div>
    </button>
  );
}

MenuIcon.propTypes = propTypes;
