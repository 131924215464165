import React from 'react';

import { mediaQuery } from '../utils/svgHelpers';

export default function withElementWidthHOC(WrappedComponent) {
  return class extends React.Component {
    state = {
      containerWidth: mediaQuery(window.innerWidth),
      width: 0
    };

    componentDidMount() {
      this.updateDimensions();
      window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate() {
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
      // if the ref doesn't exist, then exit
      if (!this.container) { return; }
      // get the dimensions from ref={(elm) => { this.container = elm; }} in the view
      const dimensions = this.container.getBoundingClientRect();
      // if the dimensions don't match, update the state
      if (dimensions.width !== this.state.width) {
        this.setState({
          containerWidth: mediaQuery(window.innerWidth),
          width: dimensions.width
        });
      }
    }

    render() {
      return (
        <div ref={(elm) => { this.container = elm; }} style={{ width: '100%' }}>
          <WrappedComponent
            {...this.props}
            width={this.state.width}
          />
        </div>
      );
    }
  };
}
