import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function CaloriesBurnedIcon({ className }) {
  let classes = 'c-icon c-icon--calories-burned';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <title>Calories Burned</title>
      <path className="c-icon__fill" d="M12,2.48a10,10,0,1,0,10,10A10,10,0,0,0,12,2.48Zm3.7,15.21a4.83,4.83,0,0,1-1.75.77c1.37-2.62-2.23-1.84-2.3-4.34-2,1.73-1.11,3.35-.52,4.13l.18.21c-3.24-.48-5.9-2.09-4.85-7.74.9,1.78,1.19,3,1.62,3.13.13-3.37,4.66-4.76,3.48-8.31,5.06,3.76.61,6.12,2.36,7.79a1.69,1.69,0,0,0,2.78-1.77A4.42,4.42,0,0,1,15.7,17.69Z" />
    </svg>
  );
}

CaloriesBurnedIcon.propTypes = propTypes;

export default CaloriesBurnedIcon;
