import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Grid } from '@material-ui/core';
import { useStyles } from './ContactsPracticeInfoViewData.styles';
import { normalizePhone } from '../../../../../../services/fieldNormalizationService';
import { makeNameWithSalutation } from '../../../../../../utils/stringGeneratorHelpers';

const propTypes = {
  practiceInfo: PropTypes.shape({
    cityVillage: PropTypes.string,
    stateProvince: PropTypes.string,
    invitationStatus: PropTypes.string,
    name: PropTypes.string,
    hcpsCount: PropTypes.number,
    phoneNumbers: PropTypes.array,
    address: PropTypes.string,
    country: PropTypes.string,
    practiceId: PropTypes.number,
    hcps: PropTypes.array
  }),
  viewInfo: PropTypes.func.isRequired,
  sendInvite: PropTypes.func.isRequired
};

export default function ContactsPracticeInfoViewData({ practiceInfo, viewInfo, sendInvite }) {
  const classes = useStyles();

  const makeButtonTxt = (invitationStatus) => {
    switch (invitationStatus) {
    case 'connected':
      return 'Connected';
    case 'invite_sent':
      return 'Sent';
    default:
      return 'Invite';
    }
  };

  return (
    <Grid container justifyContent="flex-start" style={{ paddingBottom: '1rem' }} data-testid="practice-data">
      <Grid container alignItems="center"  style={{ padding: '0 0 1.5rem 0' }}>
        <Grid item xs={4} sm={6}>
          <Grid container direction="column">
            <Grid>
              <label className="h-content h-break-word">
                <strong>{practiceInfo.name}</strong>
              </label>
            </Grid>
            <Grid className={classes.text}><label >{practiceInfo.address}</label></Grid>
            <Grid className={classes.text}><label >{practiceInfo.cityVillage}</label></Grid>
            {practiceInfo.phoneNumbers.map((el, key) => (
              <Grid className={classes.text} key={key}>
                <label >{normalizePhone(el.number)}</label>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={8} sm={6}>
          <Grid container alignItems="center" justifyContent="flex-end" className={classes.btnContainer}>
            <button
              disabled={practiceInfo.invitationStatus !== 'invite_available'}
              className="c-button c-button--condensed c-button--action h-no-break-word jest-view"
              onClick={() => sendInvite(practiceInfo.practiceId)}
            >
              {makeButtonTxt(practiceInfo.invitationStatus)}
            </button>
          </Grid>
        </Grid>
      </Grid>

      {!isEmpty(practiceInfo.hcps) && practiceInfo.hcps.map((hcp, key) => {
        return (
          <Grid container style={{ padding: '0 0 0.7rem 0' }} key={key} data-testid="practices">
            <Grid item xs={4} sm={6}>
              <label className="h-content h-break-word h-capitalize">
                {makeNameWithSalutation(hcp.hcpSalutation, hcp.hcpFirstName, hcp.hcpLastName)}
              </label>
            </Grid>

            <Grid item xs={8} sm={6}>
              <Grid container alignItems="center" justifyContent="flex-end" className={classes.btnContainer}>
                <button
                  className="c-button c-button--condensed c-button--hollow h-no-break-word jest-view"
                  onClick={() => viewInfo(hcp.hcpId)}
                >
                  View Info
                </button>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

ContactsPracticeInfoViewData.propTypes = propTypes;
