import actionTypes from '../constants/actionTypes';
import contactsService from '../services/contactsService';

function getSharedWithMeContactsSuccess(response) {
  return {
    type: actionTypes.GET_CONTACTS_SHARED_WITH_ME_SUCCESS,
    payload: response
  };
}

export function getSharedWithMeContacts(searchQuery) {
  return async function (dispatch) {
    try {
      const results = await contactsService.getSharedWithMeContacts(searchQuery);
      dispatch(getSharedWithMeContactsSuccess(results));
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

function getIAmSharingWithContactsSuccess(response) {
  return {
    type: actionTypes.GET_CONTACTS_I_AM_SHARING_WITH_SUCCESS,
    payload: response
  };
}

export function getIAmSharingWithContacts(searchQuery) {
  return async function (dispatch) {
    try {
      const results = await contactsService.getIAmSharingWithContacts(searchQuery);
      dispatch(getIAmSharingWithContactsSuccess(results));
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

export function deleteYourSharingCircle(userId) {
  return async function () {
    await contactsService.deleteYourSharingCircle(userId);
  };
}

export function deleteYourPractice(practiceId) {
  return async function () {
    await contactsService.deleteYourPractice(practiceId);
  };
}

export function deleteSentInvitation(invitationId) {
  return async function () {
    await contactsService.deleteSentInvitation(invitationId);
  };
}

export function sendInvitationReminder(invitationId) {
  return async function () {
    await contactsService.sendInvitationReminder(invitationId);
  };
}

export function acceptInvitationFromPractice(invitationId) {
  return async function () {
    await contactsService.acceptInvitationFromPractice(invitationId);
  };
}

export function resendInvitationToPractice(invitationId) {
  return async function () {
    await contactsService.resendInvitationToPractice(invitationId);
  };
}

export function editIAmSharingWithContactsInfo(invitationId, formData) {
  return async function () {
    await contactsService.editIAmSharingWithContactsInfo(invitationId, formData);
  };
}

export function getAboutContactInfo(profileId) {
  return async function () {
    return await contactsService.getAboutContactInfo(profileId);
  };
}
