import actionTypes from '../constants/actionTypes';
import validicService from '../services/validicService';
import storeService from '../services/store';

function deleteMarketplaceDisconnectSourceFailure(err) {
  return {
    type: actionTypes.DELETE_MARKETPLACE_DISCONNECT_SOURCE_FAILURE,
    error: true,
    payload: err
  };
}

function deleteMarketplaceDisconnectSourceStart() {
  return {
    type: actionTypes.DELETE_MARKETPLACE_DISCONNECT_SOURCE_START
  };
}

function deleteMarketplaceDisconnectSourceSuccess(source) {
  return {
    type: actionTypes.DELETE_MARKETPLACE_DISCONNECT_SOURCE_SUCCESS,
    payload: { source }
  };
}

export function deleteMarketplaceDisconnectSource(source) {
  return async function (dispatch) {
    dispatch(deleteMarketplaceDisconnectSourceStart());

    try {
      await validicService.deleteMarketplaceDisconnectSource(source);
      dispatch(deleteMarketplaceDisconnectSourceSuccess(source));
    } catch (err) {
      dispatch(deleteMarketplaceDisconnectSourceFailure(err));
      throw err;
    }
  };
}

// GET_MARKETPLACE

function getMarketplaceFailure(err) {
  return {
    type: actionTypes.GET_MARKETPLACE_FAILURE,
    error: true,
    payload: err
  };
}

function getMarketplaceStart() {
  return {
    type: actionTypes.GET_MARKETPLACE_START
  };
}

function getMarketplaceSuccess(stats) {
  return {
    type: actionTypes.GET_MARKETPLACE_SUCCESS,
    payload: stats
  };
}

export function getMarketplace(from, to) {
  return async function (dispatch) {
    dispatch(getMarketplaceStart());

    try {
      const stats = await validicService.getMarketplace(from, to);
      dispatch(getMarketplaceSuccess(stats));
    } catch (err) {
      dispatch(getMarketplaceFailure(err));
    }
  };
}

function setDevicePerDataTypeSuccess(type, device) {
  return {
    type: actionTypes.SET_DEVICE_PER_DATA_TYPE,
    payload: { type, device }
  };
}

// SET_DEVICE_PER_DATA_TYPE
export function setDevicePerDataType(type, device, shouldStore) {
  return async function (dispatch) {
    dispatch(setDevicePerDataTypeSuccess(type, device));

    if (!shouldStore) {
      return;
    }

    try {
      const state = storeService.getStore().getState();
      const selectedPatientId = state.selectedPatient.get('patientId');
      const deviceList = (state.validic.get('devices') || {}).toJS();
      !selectedPatientId && await validicService.setDevicePerDataType(deviceList);
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

export function getStoredDevicesPerDataType() {
  return async function (dispatch) {
    try {
      const { data } = await validicService.getStoredDevicesPerDataType();
      const result = {
        caloriesBurned: data.widgetSources.averageCaloriesBurned,
        foods: data.widgetSources.averageCaloriesConsumed,
        carbs: data.widgetSources.averageCarbohydrates,
        water: data.widgetSources.averageHydration,
        sleeps: data.widgetSources.averageSleep,
        steps: data.widgetSources.averageSteps,
        weights: data.widgetSources.averageWeight
      };
      for (const [key, value] of Object.entries(result)) {
        dispatch(setDevicePerDataTypeSuccess(key, value));
      }
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

function syncMHealthDeviceOnServerStart() {
  return {
    type: actionTypes.SYNC_DEVICE_ON_SERVICE_START
  };
}

function syncMHealthDeviceOnServerSuccess() {
  return {
    type: actionTypes.SYNC_DEVICE_ON_SERVICE_SUCCESS
  };
}

function syncMHealthDeviceOnServerFailure() {
  return {
    type: actionTypes.SYNC_DEVICE_ON_SERVICE_FAILURE
  };
}

export function syncMHealthDeviceOnServer(device) {
  return async function (dispatch) {
    dispatch(syncMHealthDeviceOnServerStart());

    try {
      await validicService.syncMHealthDeviceOnServer(device);
      dispatch(syncMHealthDeviceOnServerSuccess());
    } catch (err) {
      dispatch(syncMHealthDeviceOnServerFailure());
      throw err;
    }
  };
}

