import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
});

export default function emergencyEvent(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.GET_EMERGENCY_EVENT_DATA:
    return state.set('emergencyEventData', Immutable.fromJS(action.payload.data));

  default:
    return state;
  }
}
