/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Grid, TableCell, TableBody, TableHead, Paper, TableContainer, Table } from '@material-ui/core';
import { StyledTableRow } from 'src/components/muiStyledComponents/StyledTableRow';
import StyledTableHeadCell from 'src/components/muiStyledComponents/StyledTableHeadCell';
import StyledTablePagination from 'src/components/muiStyledComponents/StyledTablePagination';
import TypesPresenter from 'src/components/contacts/TypesPresenter/TypesPresenter';
import AvatarPresenter from 'src/components/AvatarPresenter';
import usePaginatorHook from 'src/hooks/usePaginatorHook';
import ContactsIAmSharingWithTableButtonsContainer from 'src/containers/ContactsIAmSharingWithTableButtonsContainer';
import { normalizePhone } from 'src/services/fieldNormalizationService';

const propTypes = {
  memoizedFetch: PropTypes.func.isRequired,
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number
};

function ContactsIAmSharingWithTable({ itemsList, itemsListCount, memoizedFetch }) {
  const {
    parsedQuery,
    handleChangeRowsPerPage,
    handleChangePage
  } = usePaginatorHook();
  const { perPage = 5, page = 0 } = parsedQuery;

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="simple table">
        <TableHead data-testid="table-head">
          <StyledTableRow className="h-height-xl">
            <StyledTableHeadCell align="left" style={{ width: 260 }}>Contact Name</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 115 }}>TYPE</StyledTableHeadCell>
            <StyledTableHeadCell align="left">CONTACTS</StyledTableHeadCell>
            <StyledTableHeadCell align="left"  style={{ width: 325 }}>ACTION</StyledTableHeadCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(itemsList) && itemsList.map((item, index) => {
            const tableRowClasses = classNames(
              'jest-item-invite',
              'h-height-xl',
              { 'h-danger': item.isDeclined }
            );

            return (
              <StyledTableRow key={index} className={tableRowClasses} data-testid="table-row">
                <TableCell align="left">
                  <Grid container alignItems="center" wrap="nowrap">
                    <div><AvatarPresenter avatarUrl={item.avatarUrl} /></div>
                    <div>{item.name}</div>
                  </Grid>
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center">
                    <TypesPresenter type={item.type} />
                  </Grid>
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center">
                    {item.phoneNumber && (
                      <Grid container alignItems="center">Phone: {normalizePhone(item.phoneNumber)}</Grid>
                    )}
                    {item.emailAddress && (
                      <Grid container alignItems="center">Email: {item.emailAddress}</Grid>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <ContactsIAmSharingWithTableButtonsContainer
                    isSingleItem={itemsList.length === 1}
                    memoizedFetch={memoizedFetch}
                    item={item}
                  />
                </TableCell>
              </StyledTableRow>
            );
          })}

          {isEmpty(itemsList) ? (
            <StyledTableRow style={{ height: 30 }} className="h-height-xl">
              <TableCell colSpan={4} className="h-center jest-empty">No results found</TableCell>
            </StyledTableRow>
          ) : null}
        </TableBody>
      </Table>

      <StyledTablePagination
        page={Number(page) || 0}
        rowsPerPage={Number(perPage) || 0}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={itemsListCount || 0}
        labelRowsPerPage="Rows:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

ContactsIAmSharingWithTable.propTypes = propTypes;

export default memo(ContactsIAmSharingWithTable);
