/* istanbul ignore file */
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';

export const StyledDialogTitle = withStyles(() => ({
  root: {
    textAlign: 'center',
    fontSize: '24Px',
    lineHeight: '2rem'
  }
}))(DialogTitle);
