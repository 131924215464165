import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
// Components
import LogDay from './LogDay';
// Utils
import { sortByTimestampAndId } from '../../utils/readingHelpers';

const propTypes = {
  patientTargets: PropTypes.object,
  readingRouteId: PropTypes.string,
  readingsList: PropTypes.array
};

function LogList(props) {
  const {
    patientTargets,
    readingRouteId,
    readingsList
  } = props;

  const groupedByReadings = groupBy(readingsList, reading => moment(reading.timestamp).utc().format('dddd, MMM D, YYYY'));
  const mappedReadings = mapValues(groupedByReadings, (readings, label) => ({
    label,
    readings: readings.sort(sortByTimestampAndId),
    sortableTimestamp: new Date(readings[0].timestamp).getTime()
  }));
  const sortedReadings = sortBy(mappedReadings, group => group.sortableTimestamp);
  const groupedReadings = reverse(sortedReadings);

  return (
    <div className="c-card-list c-card-list__log">
      {groupedReadings.map((group) => {
        return (
          <LogDay
            key={group.label}
            label={group.label}
            list={group.readings}
            patientTargets={patientTargets}
            readingRouteId={readingRouteId}
          />
        );
      })}
    </div>
  );
}

LogList.propTypes = propTypes;

export default LogList;
