import moment from 'moment';
/* eslint-disable camelcase */
const defaultErrorObj = {};
const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/; // NOSONAR
const phoneRegex = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/; // NOSONAR
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // NOSONAR

export class Validation {
  constructor(errorObj = defaultErrorObj) {
    this.errorObj = { ...errorObj };
    if (errorObj._error && errorObj._error.slice) {
      this.errorObj._error = errorObj._error.slice();
    }
  }

  anyContactMethod(values, fieldArray) {
    const missing = [];

    fieldArray.forEach((field) => {
      if (!values[field]) {
        missing.push(field);
      }
    });

    if (missing.length === fieldArray.length) {
      fieldArray.forEach((field) => {
        if (!values[field]) {
          this.errorObj[field] = 'At least one contact method is required';
        }
      });
    }

    return this;
  }

  anyPhoneNumber(values, fieldArray) {
    const missing = [];

    fieldArray.forEach((field) => {
      if (!values[field]) {
        missing.push(field);
      }
    });

    if (missing.length === fieldArray.length) {
      fieldArray.forEach((field) => {
        if (!values[field]) {
          this.errorObj[field] = 'At least one phone number is required';
        } else if (values[field] && !phoneRegex.test(values[field])) {
          this.errorObj[field] = 'Please enter a valid phone number';
        }
      });
    }

    return this;
  }

  cellNumberOptional(value, isRequired = false) {
    let errorMsg = null;

    if ((value || isRequired) && !phoneRegex.test(value)) {
      errorMsg = 'Please enter a valid Cell Phone';
    }

    return this.decorateErrorObject('cellNumber', errorMsg);
  }

  homeNumberOptional(value, isRequired = false) {
    let errorMsg = null;

    if (!value && isRequired) {
      errorMsg = 'Phone Number is required';
    } else if (value && !phoneRegex.test(value)) {
      errorMsg = 'Please enter a valid Phone Number';
    }

    return this.decorateErrorObject('homeNumber', errorMsg);
  }

  cellNumberRequired(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Cell Phone is required';
    } else if (!phoneRegex.test(value)) {
      errorMsg = 'Please enter a valid Cell Phone';
    }

    return this.decorateErrorObject('cellNumber', errorMsg);
  }

  cityVillage(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'City is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'City must be at least 2 characters';
    } else if (value.trim().length > 50) {
      errorMsg = 'City must be no more than 50 characters';
    }

    return this.decorateErrorObject('cityVillage', errorMsg);
  }

  comment(value, inputName, name) {
    let errorMsg = null;

    if (!value) {
      errorMsg = `${name} is required`;
    }

    return this.decorateErrorObject(inputName, errorMsg);
  }

  termsAndConditionsAccepted(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'You should accept terms and conditions';
    }

    return this.decorateErrorObject('hasAcceptedTermsAndConditions', errorMsg);
  }

  reviewed(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Required field';
    }

    return this.decorateErrorObject('reviewed', errorMsg);
  }

  reviewingTime(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Required field';
    }

    return this.decorateErrorObject('reviewingTime', errorMsg);
  }

  dateOfBirth(value) {
    let errorMsg = null;
    const momentDate = value && !moment.isMoment(value) ? moment(value, 'MM/DD/YYYY') : value;

    if (!value) {
      errorMsg = 'Date of birth is required';
    } else if (value && moment().diff(momentDate, 'years') < 13) {
      errorMsg = 'Date of birth can\'t be less than 13 years ago';
    } else if (value && moment().diff(momentDate, 'years') > 120) {
      errorMsg = 'Date of birth can\'t be more than 120 years ago';
    }

    return this.decorateErrorObject('dateOfBirth', errorMsg);
  }

  decorateErrorObject(type, message) {
    if (message) {
      this.errorObj[type] = message;
    }

    return this;
  }

  details(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Details are required';
    } else if (value.length < 2) {
      errorMsg = 'Details must be at least 2 characters';
    } else if (value.length > 500) {
      errorMsg = 'Details cannot be more than 500 characters';
    }

    return this.decorateErrorObject('details', errorMsg);
  }

  diabetesType(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Diabetes type is required';
    }

    return this.decorateErrorObject('diabetesType', errorMsg);
  }

  eA1cTarget(value) {
    const v = parseFloat(value);
    const vString = String(value);
    const hasDecimal = vString && vString.includes('.');
    let errorMsg = null;

    if (!hasDecimal) {
      errorMsg = `eA1c must include a decimal point (${v.toFixed(1)})`;
    }

    if (hasDecimal) {
      const splitString = vString.split('.');
      if (splitString[1].length > 1) {
        errorMsg = `Please round eA1c value to nearest decimal point (${v.toFixed(1)})`;
      }
    }

    if (v < 0) {
      errorMsg = 'eA1c must be greater than 0';
    }

    if (v > 30) {
      errorMsg = 'eA1c must be less than 30';
    }

    if (isNaN(v)) {
      errorMsg = 'eA1c must be a number';
    }

    return this.decorateErrorObject('eA1cTarget', errorMsg);
  }

  email(value, formValue = 'emailAddress') {
    let errorMsg = null;

    if (!(value && value.trim())) {
      errorMsg = 'Email Address is required';
    } else if (!emailRegex.test(value.trim())) {
      errorMsg = 'Email Address must contain a username and a domain (i.e. user@example.com)';
    }

    return this.decorateErrorObject(formValue, errorMsg);
  }

  // This duplication is due to server inconsistency
  emailAddress(value) {
    return this.email(value, 'emailAddress');
  }

  emailMatches(email, emailConfirmation) {
    let errorMsg = null;

    if (!email || !emailConfirmation || email.toLowerCase() !== emailConfirmation.toLowerCase()) {
      errorMsg = 'Email addresses do not match';
    }

    return this.decorateErrorObject('emailConfirmation', errorMsg);
  }

  foodServings({ servings }) {
    let errorMsg = null;

    if (!servings) {
      errorMsg = 'This field is required';
    } else if (parseFloat(servings) % 0.5 !== 0) {
      errorMsg = 'Value is invalid. Must be 0.5 serving increments';
    }

    return this.decorateErrorObject('servings', errorMsg);
  }

  startTime(startTime) {
    let errorMsg = null;

    if (!startTime) {
      errorMsg = 'Must include start time';
    }

    return this.decorateErrorObject('startTime', errorMsg);
  }

  endTime(endTime) {
    let errorMsg = null;

    if (!endTime) {
      errorMsg = 'Must include end time';
    }

    return this.decorateErrorObject('endTime', errorMsg);
  }

  endTimeAfterStartTime(startTime, endTime) {
    let errorMsg = null;

    if (!endTime || !startTime || moment(endTime).diff(moment(startTime), 'minutes') <= 0) {
      errorMsg = 'The end time must be later than the start time';
    } else if (moment(endTime).diff(moment(startTime), 'minutes') < 30) {
      errorMsg = 'Testing window should be at least 30 mins';
    }

    return this.decorateErrorObject('endTime', errorMsg);
  }

  firstName(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'First Name is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'First Name must be at least 2 characters';
    } else if (value.trim().length > 30) {
      errorMsg = 'First Name must be not more than 30 characters';
    }

    return this.decorateErrorObject('firstName', errorMsg);
  }

  gender(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Gender is required';
    }

    return this.decorateErrorObject('gender', errorMsg);
  }

  getErrors() {
    return this.errorObj;
  }

  label(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Title is required';
    } else if (value.length < 2) {
      errorMsg = 'Title must be at least 2 characters';
    } else if (value.length > 50) {
      errorMsg = 'Title must be not more than 50 characters';
    }

    return this.decorateErrorObject('label', errorMsg);
  }

  lastName(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Last Name is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'Last Name must be at least 2 characters';
    } else if (value.trim().length > 30) {
      errorMsg = 'Last Name must be not more than 30 characters';
    }

    return this.decorateErrorObject('lastName', errorMsg);
  }

  maxLength(max, value, inputName) {
    const errorMsg = value && value.length > max ? `Must be ${max} characters or less` : null;
    return this.decorateErrorObject(inputName, errorMsg);
  }

  name(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Name is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'Name must be at least 2 characters';
    } else if (value.trim().length > 50) {
      errorMsg = 'Name must be not more than 50 characters';
    }

    return this.decorateErrorObject('name', errorMsg);
  }

  password(value) {
    let errorMsg = null;

    if (!(value && value.trim())) {
      errorMsg = 'Password is required';
    }

    return this.decorateErrorObject('password', errorMsg);
  }

  passwordComplexity(value) {
    let errorMsg = null;

    if (!passwordRegex.test(value)) {
      errorMsg = 'Your secure password must be at least 8 characters in length and contain at least one uppercase letter, one lowercase letter and one number';
    }

    return this.decorateErrorObject('password', errorMsg);
  }

  passwordMatches(password, passwordConfirmation) {
    let errorMsg = null;

    if (password !== passwordConfirmation) {
      errorMsg = 'Passwords do not match';
    }

    return this.decorateErrorObject('passwordConfirmation', errorMsg);
  }

  passwordMaxLength(value) {
    let errorMsg = null;

    if (value && value.length > 30) {
      errorMsg = 'Password must be less than 30 characters in length';
    }

    return this.decorateErrorObject('password', errorMsg);
  }

  homeNumber(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Phone number is required ';
    } else if (!phoneRegex.test(value)) {
      errorMsg = 'Please enter a valid phone number';
    }

    return this.decorateErrorObject('homeNumber', errorMsg);
  }

  phoneNumber(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Phone number is required';
    } else if (!phoneRegex.test(value)) {
      errorMsg = 'Please enter a valid phone number';
    }

    return this.decorateErrorObject('phoneNumber', errorMsg);
  }

  postalCode(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Zip Code is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'ZIP Code must be at least 2 characters';
    } else if (value.trim().length > 10) {
      errorMsg = 'ZIP Code must be no more than 10 characters';
    }

    return this.decorateErrorObject('postalCode', errorMsg);
  }

  practiceAddress(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Practice Address is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'Practice Address must be at least 2 characters';
    } else if (value.trim().length > 255) {
      errorMsg = 'Practice Address must be no more than 255 characters';
    }

    return this.decorateErrorObject('addressOne', errorMsg);
  }

  addressOne(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Address is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'Address must be at least 2 characters';
    } else if (value.trim().length > 255) {
      errorMsg = 'Address must be no more than 255 characters';
    }

    return this.decorateErrorObject('addressOne', errorMsg);
  }

  practiceName(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Practice Name is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'Practice Name must be at least 2 characters';
    } else if (value.trim().length > 255) {
      errorMsg = 'Practice Name must be no more than 255 characters';
    }

    return this.decorateErrorObject('name', errorMsg);
  }

  practicePhoneNumber(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Practice Phone Number is required';
    } else if (!phoneRegex.test(value)) {
      errorMsg = 'Please enter a valid phone number';
    }

    return this.decorateErrorObject('practiceNumber', errorMsg);
  }

  relationship(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Relationship is required';
    } else if (value.trim().length < 2) {
      errorMsg = 'Relationship must be at least 2 characters';
    } else if (value.trim().length > 20) {
      errorMsg = 'Relationship must be not more than 20 characters';
    }

    return this.decorateErrorObject('relationship', errorMsg);
  }

  relationshipNotRequired(value) {
    let errorMsg = null;

    if (value && value.trim().length < 2) {
      errorMsg = 'Relationship must be at least 2 characters';
    } else if (value && value.trim().length > 20) {
      errorMsg = 'Relationship must be not more than 20 characters';
    }

    return this.decorateErrorObject('relationship', errorMsg);
  }

  salutation(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Name Prefix is required';
    }

    return this.decorateErrorObject('salutation', errorMsg);
  }

  specialty(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Speciality is required';
    }

    return this.decorateErrorObject('specialty', errorMsg);
  }

  stateProvince(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'State is required';
    }

    return this.decorateErrorObject('stateProvince', errorMsg);
  }

  targetRange(fieldName, value, min, max) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Required';
    } else if (Number(value) < min || Number(value) > max) {
      errorMsg = `Must be between ${min} - ${max}`;
    }

    return this.decorateErrorObject(fieldName, errorMsg);
  }

  hypoHyperTargetRange(fieldName, value, min, max) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Required';
    } else if (Number(value) <= min || Number(value) >= max) {
      errorMsg = 'Must be between Low / High Alert Setting';
    }

    return this.decorateErrorObject(fieldName, errorMsg);
  }

  topic(value) {
    let errorMsg = null;

    if (!value || !value.trim().length) {
      errorMsg = 'Topic is required';
    }

    return this.decorateErrorObject('topic', errorMsg);
  }
}

export default {
  Validation
};
