import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '40Px'
  },
  paper: {
    background: 'transparent',
    boxShadow: 'none'
  },
  backDrop: {
    background: 'rgba(255, 255, 255, 0.95)'
  },
  logo: {
    width: '95Px',
    height: '95Px'
  },
  root: {
    zIndex: '1400 !important'
  }
}));
