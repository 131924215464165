import moment from 'moment-timezone';
import apiService from './apiService';
import { responseStatusIs, expectFieldWithError } from 'src/utils/formErrorHelpers';
import { mapPhoneNumbers } from 'src/utils/mapPhoneNubmers';
import { trimSpaces } from 'src/utils/formHelpers';

export const registrationErrorMessages = {
  conflict: 'This email address is already in use',
  noMatch: 'Could not find user to match given registration id',
  default: 'There was a problem registering. Please try again.',
  wrongCreds: 'Your credentials could not be verified. Please try again.',
  notFoundCreds: 'Your credentials could not be found. Please try again.',
  errorResetPass: 'There was a problem reseting your password. Please try again.',
  errorSignIn: 'There was a problem signing in. Please try again.',
  errorToken: 'Could not find user to match given token'
};

const API_URL = process.env.API_URL || '';
const AUTH_URL = process.env.AUTH_URL || '';
const PUBLIC_URL = process.env.PUBLIC_URL || '';

async function confirmEmail(token) {
  try {
    const { data } = await apiService.post(`${AUTH_URL}/confirm_email`, { token });
    return { message: data.success };
  } catch (err) {
    let errorMessage = registrationErrorMessages.wrongCreds;

    if (responseStatusIs(err.response, 422)) {
      errorMessage = registrationErrorMessages.notFoundCreds;
    }

    throw new Error(errorMessage);
  }
}

async function forgotPassword(values) {
  try {
    const { data } = await apiService.post(`${AUTH_URL}/forgot_password`, { ...values });
    return { message: data.success };
  } catch (err) {
    const errorMessage = err.response && err.response.data ?
      err.response.data.error :
      registrationErrorMessages.wrongCreds;

    throw new Error(errorMessage);
  }
}

async function refreshAccessToken(refreshToken) {
  try {
    const { data } = await apiService.post(`${AUTH_URL}/refresh`, { refresh_token: refreshToken });
    return {
      expires: moment().add(data.expires_in, 'seconds').valueOf(),
      expiresIn: data.expires_in,
      headers: {
        Authorization: data.access_token
      },
      refreshToken: data.refresh_token,
      scope: data.scope,
      tokenType: data.token_type
    };
  } catch (err) {
    throw new Error(err);
  }
}

async function register(formValues, type = 'patient') {
  const timeZone = moment.tz.guess();
  const formValuesTrimmed = trimSpaces(formValues);

  try {
    const { data } = await apiService.post(`${AUTH_URL}/${type}/register`, { ...formValuesTrimmed, timeZone });
    return data;
  } catch (err) {
    const expectedFieldWithError = expectFieldWithError(err.response, 'emailAddress');
    let errorMessage = registrationErrorMessages.default;

    if (responseStatusIs(err.response, 404)) {
      errorMessage = registrationErrorMessages.noMatch;
    } else if (responseStatusIs(err.response, 409)) {
      errorMessage = registrationErrorMessages.conflict;
    } else if (responseStatusIs(err.response, 411)) {
      // This is case for EC try to create patient account. It should fail and dialog should be shown.
      errorMessage = err.response.data.error;
    } else if (!!expectedFieldWithError) {
      errorMessage = expectedFieldWithError;
    }

    throw new Error(errorMessage);
  }
}

async function registerWithToken(values, type = 'hcp') {
  const timeZone = moment.tz.guess();
  const formValuesTrimmed = trimSpaces(values);
  const params = mapPhoneNumbers(formValuesTrimmed);

  try {
    const { data } = await apiService.post(`${AUTH_URL}/${type}/register/token`, { ...params, timeZone });
    return data; // default = "successfully registered" after this you should log the person in
  } catch (err) {
    const expectedFieldWithError = expectFieldWithError(err.response, 'emailAddress');
    let errorMessage = registrationErrorMessages.default;

    if (responseStatusIs(err.response, 404)) {
      errorMessage = registrationErrorMessages.noMatch;
    } else if (responseStatusIs(err.response, 409)) {
      errorMessage = registrationErrorMessages.conflict;
    } else if (!!expectedFieldWithError) {
      errorMessage = expectedFieldWithError;
    }

    throw new Error(errorMessage);
  }
}

async function resetPassword(token, values) {
  try {
    const { data } = await apiService.put(`${AUTH_URL}/reset_password`, { token, ...values });
    return { message: data.success };
  } catch (err) {
    let errorMessage = registrationErrorMessages.errorResetPass;

    if (responseStatusIs(err.response, 404)) {
      errorMessage = registrationErrorMessages.errorToken;
    }

    throw new Error(errorMessage);
  }
}

async function signIn(values) {
  try {
    const { data } = await apiService.post(`${AUTH_URL}/login`, { ...values });

    return {
      expires: moment().add(data.expires_in, 'seconds').valueOf(),
      expiresIn: data.expires_in,
      headers: {
        Authorization: data.access_token
      },
      refreshToken: data.refresh_token,
      scope: data.scope,
      tokenType: data.token_type
    };
  } catch (err) {
    const errorMessage = err.response && err.response.data ?
      err.response.data.error :
      registrationErrorMessages.errorSignIn;

    throw new Error(errorMessage);
  }
}

async function signOut(refreshToken) {
  const { data } = await apiService.post(`${API_URL}/revoke`, { token: refreshToken });
  return data;
}

export default {
  AUTH_URL,
  PUBLIC_URL,
  confirmEmail,
  forgotPassword,
  refreshAccessToken,
  register,
  registerWithToken,
  resetPassword,
  signIn,
  signOut
};
