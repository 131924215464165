import { stringify } from 'query-string';

/*
 * Generate link with query params for history.location.push
 * @param {string} type - type of link to be generated
 * @param {object} history - history object
 * @param {object} food - food object
 * TODO:Remove 'type=common' in search obj since there are no other types options. It can be hardcoded in url param.
 */
export function generateLinkWithQueries(type, history, food) {
  const name = food.name || food.food_name;

  const link = {
    postRecentFood: {
      pathname: `${history.location.pathname}/food`,
      search: stringify({
        food_id: food.id,
        food_name: encodeURIComponent(name),
        type: 'common',
        nix_item_id: food.nix_item_id,
        editFood: 'ignore',
        submit: 'post',
        servings: food.servings // This is serving count
      })
    },
    postFavoriteFood: {
      pathname: `${history.location.pathname}/food`,
      search: stringify({
        food_name: encodeURIComponent(name),
        type: 'common',
        nix_item_id: food.nix_item_id,
        editFood: 'false',
        submit: 'post',
        servings: food.servings || '' // This is serving count
      })
    },
    put: {
      pathname: `${history.location.pathname}/food`,
      search: stringify({
        food_id: food.id,
        food_name: encodeURIComponent(name),
        type: 'common',
        nix_item_id: food.nix_item_id,
        editFood: 'true',
        submit: 'put',
        servings: food.servings // This is serving count
      })
    },
    post: {
      pathname: `${history.location.pathname}/food`,
      search: stringify({
        food_name: encodeURIComponent(name),
        type: 'common',
        nix_item_id: food.nix_item_id,
        editFood: 'false',
        submit: 'post',
        servings: food.servings || '' // This is serving count
      })
    }
  };

  return link[type];
}

export function foodsPropsMapper(item) {
  return {
    ...item,
    nf_sodium: item.sodium,
    nf_calories: item.calories,
    nf_total_carbohydrate: item.carbohydrates,
    nf_dietary_fiber: item.dietary_fiber,
    nf_cholesterol: item.cholesterol,
    nf_potassium: item.potassium,
    nf_sugars: item.sugars,
    consumed_at: item.createdAt,
    nf_total_fat: item.fat,
    nix_item_id: item.nix_item_id,
    photo: { thumb: item.image },
    food_name: item.name,
    nf_protein: item.protein,
    serving_qty: item.servingSize,
    serving_unit: item.servingUnit,
    serving_weight_grams: item.weight
  };
}

function dropServings(item) {
  return { ...item, servings: null };
}

export function optimizeFavoriteFoodList(data) {
  return data.map(dropServings);
}

export function optimizeRecentFoodList(data) {
  return data.map(foodsPropsMapper);
}

export function diffByIdOrTypeReading(objValue, othValue) {
  if ((!objValue && othValue) || (objValue && !othValue)) {
    return false;
  } else if ((!objValue || !othValue) && objValue === othValue) {
    return true;
  } else if (objValue.id === othValue.id) {
    return true;
  }
}
