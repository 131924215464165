import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import { scrollParentIntoView } from 'src/services/scrollService';
import MeasuresSelect from 'src/components/MeasuresSelect';
import MuiNumberField from 'src/components/muiComponents/MuiNumberField';
import CountlyTrackedButton from 'src/components/countly/CountlyTrackedButton';
import formValidationService from 'src/services/formValidation';
import { handleFormSubmissionErrors } from 'src/services/errorHandlingService';
import { sv } from 'src/assets/css/1_settings/variables';

// Default serving count for initial form servings value
const defaultServingsCount = 1;

export class FoodEdit extends React.Component {
  static propTypes = {
    clearNutritionix: PropTypes.func.isRequired,
    clearNutritionixSelectedFood: PropTypes.func.isRequired,
    deleteReadingFood: PropTypes.func.isRequired,
    editFood: PropTypes.string,
    foodId: PropTypes.string,
    getFavoriteFoods: PropTypes.func.isRequired,
    getRecentFoods: PropTypes.func.isRequired,
    getNutritionixFoodById: PropTypes.func.isRequired,
    getNutritionixFoodByIdAndReadingId: PropTypes.func.isRequired,
    getNutritionixFoodAltMeasures: PropTypes.func.isRequired,
    getReading: PropTypes.func.isRequired,
    getReadingFoods: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    nixItemId: PropTypes.string,
    nutritionix: PropTypes.shape({
      selectedFood: PropTypes.shape({
        servings: PropTypes.any,
        nf_total_carbohydrate: PropTypes.any,
        nf_dietary_fiber: PropTypes.any,
        nf_sodium: PropTypes.any,
        nix_item_id: PropTypes.any,
        food_name: PropTypes.string,
        photo: PropTypes.object,
        nf_calories: PropTypes.number,
        nf_protein: PropTypes.number,
        nf_total_fat: PropTypes.number,
        serving_qty: PropTypes.number,
        serving_unit: PropTypes.string,
        serving_weight_grams: PropTypes.number
      })
    }),
    postReadingFood: PropTypes.func.isRequired,
    putReadingFood: PropTypes.func.isRequired,
    query: PropTypes.string,
    readingId: PropTypes.number,
    servings: PropTypes.string,
    submitType: PropTypes.string,
    type: PropTypes.string,
    to: PropTypes.object
  };

  async componentDidMount() {
    const { foodId, readingId, editFood } = this.props;

    /*
     * case editFood === 'true' for editing foods consumed for current day
     * case editFood === 'false' for getting foods props from nutritionix used for searched foods and favorite meal
     * case editFood === 'ignore' for seeding form with data for recent meal tab
     * getNutritionixFoodAltMeasures will seed selected food with alt_measures in nutrtionix reducer
     */
    if (editFood === 'true') {
      await this.props.getNutritionixFoodByIdAndReadingId(readingId, foodId);
      await this.props.getNutritionixFoodAltMeasures(readingId, this.props.type, this.props.query, this.props.nixItemId);
    } else if (editFood === 'false') {
      await this.props.getNutritionixFoodById(readingId, this.props.type, this.props.query, this.props.nixItemId);
    } else if (editFood === 'ignore') {
      await this.props.getNutritionixFoodAltMeasures(readingId, this.props.type, this.props.query, this.props.nixItemId);
    }
    // Set default servings which are passed via query in url
    const servings = this.props.servings ? parseFloat(this.props.servings) : defaultServingsCount;

    // Set form serving
    this.formInstance && this.formInstance.change('servings', servings);

    /*
    * TODO: Should be removed nutrionix API will become consistent
    * If nutritionix api will return consistent data
    * returnServingUnit function should be changed to food.serving_unit
    * and returnServingUnit function should be deleted
    */
    const servingUnit = this.returnServingUnit(this.props.nutritionix.selectedFood);
    this.formInstance && this.formInstance.change('servingSize', servingUnit);

    // Count and Set default field values
    this.setFormValue(servingUnit, servings);
    scrollParentIntoView('.c-log--selected');
  }

  componentWillUnmount() {
    this.props.clearNutritionixSelectedFood();
  }

  // Update form fields value
  setFormValue = (servingSizeValue, servings) => {
    const food = this.props.nutritionix.selectedFood;

    if (!food) {
      return;
    }

    // In case new food is added we define default serving count
    const foodServings = food.servings || defaultServingsCount;
    /*
     * To make more precise calculations
     * in case saved food serving is bigger
     * than new serving count it will run: foodServings / servings
     * in other case it will run: servings / foodServings,
     * also countCarbs function will use divide or multiply respectively
     */
    const servingsCount = servings > foodServings ? +(servings / foodServings).toFixed(1) : +(foodServings / servings).toFixed(1);
    const useMultiplyOrDevide = servings > foodServings ? 'multiply' : 'divide';

    // get measure from alt_measure prop
    const servingSizeMeasure = this.returnMeasure(food, servingSizeValue);
    const servingWeight = servingSizeMeasure ? servingSizeMeasure.serving_weight : food.serving_weight_grams;

    // Calculation of nutritionix
    const carbohydrates = Math.round(this.countCarbs(food.nf_total_carbohydrate, servingWeight, food.serving_weight_grams, servingsCount, useMultiplyOrDevide));
    const calories = Math.round(this.countCarbs(food.nf_calories, servingWeight, food.serving_weight_grams, servingsCount, useMultiplyOrDevide));
    const dietaryFiber = +(this.countCarbs(food.nf_dietary_fiber, servingWeight, food.serving_weight_grams, servingsCount, useMultiplyOrDevide)).toFixed(2);
    const sodium = +(this.countCarbs(food.nf_sodium, servingWeight, food.serving_weight_grams, servingsCount, useMultiplyOrDevide)).toFixed(2);

    // Set values to form
    this.formInstance && this.formInstance.batch(() => {
      this.formInstance.change('carbohydrates', carbohydrates);
      this.formInstance.change('dietaryFiber', dietaryFiber);
      this.formInstance.change('sodium', sodium);
      this.formInstance.change('calories', calories);
    });
  }

  formInstance = null;

  // Count measures if serving size changed
  countCarbs = (carbsSize, servingWeight, defaultMeasureWeight, servings, mathType) => {
    /*
     * This case is for branded food which has no weight,
     * also no branded food has alt measures.
     * mathType - is for more precise calculations,
     * to handle situations where stored foods
     * servings is bigger or smaller from selected.
     */
    if (!servingWeight || !defaultMeasureWeight) {
      if (mathType === 'divide') {
        return carbsSize / servings;
      }
      return carbsSize * servings;
    }

    if (mathType === 'divide') {
      return ((carbsSize * servingWeight) / defaultMeasureWeight) / servings;
    }
    return ((carbsSize * servingWeight) / defaultMeasureWeight) * servings;
  }

  handleCountChange = (e) => {
    this.setFormValue(this.formValues.servingSize, e.target.value);
  }

  handleSizeChange = (e) => {
    this.setFormValue(e.target.value, this.formValues.servings);
  }

  submit = async ({ servings, servingSize, carbohydrates, dietaryFiber, sodium, calories }) => {
    const { submitType, nutritionix, readingId, foodId } = this.props;

    const preppedFood = {
      carbohydrates,
      servings,
      sodium,
      calories,
      dietary_fiber: dietaryFiber,
      fat: nutritionix.selectedFood.nf_total_fat,
      image: nutritionix.selectedFood.photo.thumb,
      name: nutritionix.selectedFood.food_name,
      nix_item_id: nutritionix.selectedFood.nix_item_id,
      protein: nutritionix.selectedFood.nf_protein
    };

    const measure = this.returnMeasure(nutritionix.selectedFood, servingSize);
    preppedFood.servingSize = measure ? measure.qty : nutritionix.selectedFood.serving_qty;
    preppedFood.servingUnit = measure ? measure.measure : nutritionix.selectedFood.serving_unit;
    preppedFood.weight = measure ? measure.serving_weight : nutritionix.selectedFood.serving_weight_grams;

    try {
      if (submitType === 'put') {
        // Editing food
        await this.props.putReadingFood(readingId, foodId, preppedFood);
      } else {
        // Adding food
        await this.props.postReadingFood(readingId, preppedFood);
      }
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        [
          'servings',
          'carbohydrates',
          'dietaryFiber',
          'sodium',
          'calories'
        ]
      );
    }

    this.props.getReading(readingId);
    this.props.getReadingFoods(readingId);
    this.props.getFavoriteFoods(); // Update favorite meals table
    this.props.getRecentFoods(); // Update recent meals table
    this.props.clearNutritionix();
    this.props.history.replace(this.props.to);
  }

  // Remove food from list
  remove = async () => {
    const { readingId, foodId } = this.props;

    await this.props.deleteReadingFood(readingId, foodId);
    this.props.getReading(readingId);
    this.props.getReadingFoods(readingId);
    this.props.clearNutritionix();
    this.props.history.replace(this.props.to);
  }

  // Find which alt_measure corresponds to pre set servingSizeValue
  returnMeasure = ({ alt_measures }, servingSizeValue) => { // eslint-disable-line
    if (!alt_measures) { return; } // eslint-disable-line
    return alt_measures.find(el => el.measure === servingSizeValue);
  }

  /*
   * This is workaround func since nutrionix API
   * returns weird foods serving_unit values
   * which does not match alt_measures.
   * TODO: Should be removed if nutrionix API will become consistent.
   */
  returnServingUnit = ({ alt_measures, serving_unit }) => { // eslint-disable-line
    if (alt_measures && alt_measures.length) { // eslint-disable-line
      const { measure } = alt_measures.find(el => {
        let searchString = serving_unit?.toString(); // eslint-disable-line
        let splittedMeasure = el.measure.toString();

        if (el.measure.indexOf('(') !== -1 && serving_unit.indexOf('(') === -1) {
          searchString = serving_unit ? serving_unit.split('(')[0] : null; // eslint-disable-line
          splittedMeasure = el.measure.split('(')[0];
        }

        return String(searchString).trim() === String(splittedMeasure).trim();
      });

      return measure;
    }

    return serving_unit; // eslint-disable-line
  }

  // Generate string with selected measure for table header
  returnServiceSizeString = (servingSizeValue) => {
    const { nutritionix } = this.props;
    const measures = this.returnMeasure(nutritionix.selectedFood, servingSizeValue);

    if (!servingSizeValue || !measures) {
      return `${nutritionix.selectedFood.serving_qty} ${nutritionix.selectedFood.serving_unit}`;
    }

    return `${measures.qty} ${measures.measure}`;
  }

  /* istanbul ignore next */
  validateFields(values) {
    return new formValidationService.Validation()
      .foodServings(values)
      .getErrors();
  }

  render() {
    const { nutritionix: { selectedFood } } = this.props;
    const isUpdate = this.props.submitType === 'put';

    if (!selectedFood.food_name) {
      return <div data-testid="jest-wait" className="c-card__body">Please wait...</div>;
    }

    const border = { borderBottom: `0.5px solid ${sv.color.textSecondary}`, opacity: 0.4 };

    return (
      <div className="c-card__body c-card__body--unpadded">
        <div className="c-list-item c-list-item--food">
          <img className="c-list-item__photo" src={selectedFood.photo.thumb} />
          <span className="c-list-item__name">{selectedFood.food_name}</span>
          <span>{this.formValues && this.returnServiceSizeString(this.formValues.servingSize)}</span>
        </div>
        <Form
          onSubmit={this.submit}
          validate={this.validateFields}
        >
          {({ handleSubmit, submitting, form, values }) => {
            this.formValues = values;
            this.formInstance = form;

            const buttonClasses = classNames(
              'c-button',
              { 'c-button--block': !isUpdate },
              { 'c-button--disabled': !values.servings },
              { 'c-button--action c-button--hollow': !!values.servings }
            );

            return (
              <form
                name="foodEditForm"
                className="h-padded c-fieldset"
                onSubmit={handleSubmit}
              >
                <Field
                  component={MuiNumberField}
                  label="Serving Count"
                  onChange={this.handleCountChange}
                  min="0.5"
                  name="servings"
                  step={0.5}
                  type="number"
                  underlineDisabledStyle={border}
                />
                <MeasuresSelect
                  onChange={this.handleSizeChange}
                  food={selectedFood}
                  border={border}
                  hintText="Serving Size"
                  label="Serving Size"
                  name="servingSize"
                />
                <Field
                  component={MuiNumberField}
                  disabled
                  label="Carbohydrates"
                  fullWidth
                  name="carbohydrates"
                  underlineDisabledStyle={border}
                  format={value => { return `${value || 0} grams`; }}
                />
                <Field
                  component={MuiNumberField}
                  disabled
                  label="Dietary Fiber"
                  fullWidth
                  name="dietaryFiber"
                  underlineDisabledStyle={border}
                  format={value => { return `${value || 0} grams`; }}
                />
                <Field
                  component={MuiNumberField}
                  disabled
                  label="Sodium"
                  format={(value) => { return `${value || 0} milligrams`; }}
                  fullWidth
                  name="sodium"
                  underlineDisabledStyle={border}
                />
                <Field
                  component={MuiNumberField}
                  disabled
                  label="Calories"
                  format={(value) => { return value || 0; }}
                  fullWidth
                  name="calories"
                  underlineDisabledStyle={border}
                />
                <hr />
                <div className="c-button-group c-button-group--full-width">
                  {isUpdate && (
                    <CountlyTrackedButton
                      data-testid="jest-button"
                      countlyevent="update"
                      className="c-button c-button--hollow c-button--danger"
                      type="button"
                      onClick={this.remove}
                    >
                      Delete
                    </CountlyTrackedButton>
                  )}
                  <CountlyTrackedButton
                    countlyevent="delete"
                    className={buttonClasses}
                    disabled={submitting}
                    type="submit"
                  >
                    Save
                  </CountlyTrackedButton>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    );
  }
}

export default FoodEdit;

