import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ProfileChecklistContainer from '../containers/ProfileChecklistContainer';
import HypoTrendNotificatorContainer from '../containers/HypoTrendNotificatorContainer';

const propTypes = {
  profileCheckListClosed: PropTypes.bool,
  hypoTrendOpen: PropTypes.bool
};

const MODAL_TYPES = {
  hypo: 'hypo',
  profileChecklist: 'profileChecklist'
};

export default function AppStartStatusReviewer(props) {
  const { profileCheckListClosed, hypoTrendOpen } = props;
  const [showModalType, setShowModalType] = React.useState();

  // We ignore profileCheckList component if hypoTrend should be shown
  React.useEffect(() => {
    if (hypoTrendOpen) {
      return setShowModalType(MODAL_TYPES.hypo);
    } else if (!profileCheckListClosed) {
      return setShowModalType(MODAL_TYPES.profileChecklist);
    }
  }, [profileCheckListClosed, hypoTrendOpen]);

  const closeModal = React.useCallback(() => setShowModalType(''), []);

  return (
    <Fragment>
      {showModalType === MODAL_TYPES.profileChecklist ? <ProfileChecklistContainer closeModal={closeModal} /> : null}
      {showModalType === MODAL_TYPES.hypo ? <HypoTrendNotificatorContainer closeModal={closeModal} /> : null}
    </Fragment>
  );
}

AppStartStatusReviewer.propTypes = propTypes;
