import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import TimeframeRecord from 'src/records/TimeframeRecord';
import { clearSelectedReading, getRangeOfReadings } from 'src/actions/readingsActions';
import { getPatientTargets } from 'src/actions/settingsActions';
import { getStoredDevicesPerDataType } from 'src/actions/validicActions';
import { makeEndDateForApi, makeStartDateForApi } from 'src/utils/dateFormatter';
import Loader from 'src/components/shared/Loader';

export default function withReadingData(WrappedComponent) {
  const propTypes = {
    showProgress: PropTypes.bool,
    clearSelectedReading: PropTypes.func.isRequired,
    getStoredDevicesPerDataType: PropTypes.func.isRequired,
    getPatientTargets: PropTypes.func.isRequired,
    getRangeOfReadings: PropTypes.func.isRequired,
    timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired
  };

  function ReadingDataWrapper (props) {
    const [loading, setLoading] = useState(false);
    const pendingRequests = useRef(0); // Use a ref to track pending requests
    const startFrame = props.timeframe.get('start');
    const endFrame = props.timeframe.get('end');

    const fetchReadings = useCallback(async () => {
      pendingRequests.current++;
      setLoading(true);

      const start = makeStartDateForApi(startFrame);
      const end = makeEndDateForApi(endFrame);
      await props.getRangeOfReadings(start, end, { tags: true, targets: true });
      props.clearSelectedReading();

      pendingRequests.current--;
      if (pendingRequests.current <= 0) {
        setLoading(false);
      }
    }, [startFrame, endFrame]);

    useEffect(() => {
      props.getPatientTargets();
      props.getStoredDevicesPerDataType();
    }, []);

    useEffect(() => {
      fetchReadings();
    }, [startFrame, endFrame]);

    return (
      <>
        <WrappedComponent {...props} />
        <Loader isEnabled={props.showProgress || loading}/>
      </>
    );
  }

  ReadingDataWrapper.propTypes = propTypes;

  function mapStateToProps(state) {
    const isActiveLoadTimestamp = state.readings.get('isActiveLoadTimestamp');

    return {
      showProgress: isActiveLoadTimestamp
    };
  }

  return connect(mapStateToProps, {
    getStoredDevicesPerDataType,
    clearSelectedReading,
    getPatientTargets,
    getRangeOfReadings
  })(ReadingDataWrapper);
}
