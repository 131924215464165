import React from 'react';

export default function SickMoodIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="#282829" fillRule="evenodd">
        <path d="M8.938 6.914c.69 0 1.253.912 1.253 2.034 0 1.121-.563 2.033-1.254 2.033-.69 0-1.252-.912-1.252-2.033 0-1.122.561-2.034 1.253-2.034m6.028 0c.693 0 1.255.912 1.255 2.034 0 1.121-.563 2.033-1.255 2.033-.69 0-1.253-.912-1.253-2.033 0-1.122.563-2.034 1.254-2.034m-7.538 5.598h9.26c.148 0 .291.022.427.064L22.14 7.74C20.473 3.783 16.555 1 12 1 7.455 1 3.543 3.772 1.869 7.717l5.135 4.86c.135-.043.279-.065.426-.065M16.689 20.175h-9.26c-.202 0-.394-.04-.57-.116l-1.204.92A10.943 10.943 0 0 0 12.002 23a10.94 10.94 0 0 0 6.405-2.065l-1.148-.876a1.436 1.436 0 0 1-.57.116M18.034 13.407c.073.174.113.365.113.565v4.744c0 .146-.02.288-.062.42l1.31 1a10.973 10.973 0 0 0 3.192-11.114l-4.553 4.385zM5.97 18.715v-4.743c0-.2.04-.392.114-.566L1.42 8.996a10.976 10.976 0 0 0 3.239 11.19l1.372-1.049a1.475 1.475 0 0 1-.062-.422"/>
        <path d="M8.938 6.914c.69 0 1.253.912 1.253 2.034 0 1.121-.563 2.033-1.254 2.033-.69 0-1.252-.912-1.252-2.033 0-1.122.561-2.034 1.253-2.034m6.028 0c.693 0 1.255.912 1.255 2.034 0 1.121-.563 2.033-1.255 2.033-.69 0-1.253-.912-1.253-2.033 0-1.122.563-2.034 1.254-2.034m-7.538 5.598h9.26c.148 0 .291.022.427.064L22.14 7.74C20.473 3.783 16.555 1 12 1 7.455 1 3.543 3.772 1.869 7.717l5.135 4.86c.135-.043.279-.065.426-.065M16.689 20.175h-9.26c-.202 0-.394-.04-.57-.116l-1.204.92A10.943 10.943 0 0 0 12.002 23a10.94 10.94 0 0 0 6.405-2.065l-1.148-.876a1.436 1.436 0 0 1-.57.116M18.034 13.407c.073.174.113.365.113.565v4.744c0 .146-.02.288-.062.42l1.31 1a10.973 10.973 0 0 0 3.192-11.114l-4.553 4.385zM5.97 18.715v-4.743c0-.2.04-.392.114-.566L1.42 8.996a10.976 10.976 0 0 0 3.239 11.19l1.372-1.049a1.475 1.475 0 0 1-.062-.422"/>
      </g>
    </svg>
  );
}
