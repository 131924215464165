import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function TimingIcon({ className }) {
  let classes = 'c-icon c-icon--timing';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <path className="c-icon__fill c-icon__fill--alt" d="M24,12.5 C24,18.852 18.852,24 12.5,24 C6.149,24 1,18.852 1,12.5 C1,6.148 6.149,1 12.5,1 C18.852,1 24,6.148 24,12.5 Z M12.4854,4.0889 C7.9434,4.0889 4.2624,7.7699 4.2624,12.3119 C4.2624,16.8529 7.9434,20.5339 12.4854,20.5339 C17.0264,20.5339 20.7084,16.8529 20.7084,12.3119 C20.7084,7.7699 17.0264,4.0889 12.4854,4.0889 Z" />
      <path className="c-icon__fill c-icon__fill--blue" d="M14.9229,15.5068 C14.7699,15.5068 14.6149,15.4598 14.4819,15.3648 L11.8169,13.4618 C11.6169,13.3188 11.4979,13.0888 11.4979,12.8428 L11.4979,7.8948 C11.4979,7.4738 11.8389,7.1328 12.2589,7.1328 C12.6799,7.1328 13.0209,7.4738 13.0209,7.8948 L13.0209,12.4498 L15.3659,14.1258 C15.7079,14.3698 15.7869,14.8458 15.5429,15.1878 C15.3949,15.3968 15.1599,15.5068 14.9229,15.5068" />
    </svg>
  );
}

TimingIcon.propTypes = propTypes;

export default TimingIcon;
