import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  hasDataAccess: false,
  healthfeed: {},
  pogoCount: 0,
  isEmailVerified: false,
  isProfileComplete: false,
  location: new Immutable.Map({}),
  relationshipCount: 0,
  scopes: new Immutable.List(),
  skippedSetupRemaining: new Immutable.List(),
  hypoTrendReadings: new Immutable.List(),
  setupRemaining: new Immutable.List()
});

export default function statusReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.REFRESH_TOKEN_START:
  case actionTypes.GET_STATUS_START:
    return state.set('isActive', true);

  case actionTypes.GET_STATUS_SUCCESS:
    return state.withMutations((map) => {
      map.set('id', action.payload.statusInfo.id);
      map.set('setupRemaining', Immutable.fromJS(action.payload.statusInfo.setupRemaining?.filter(el => String(el).trim() !== 'meters')));
      map.set('skippedSetupRemaining', Immutable.fromJS(action.payload.statusInfo.skippedSetupRemaining?.filter(el => String(el).trim() !== 'meters')));
      map.set('scopes', Immutable.fromJS(action.payload.statusInfo.scopes));
      map.set('hasDataAccess', action.payload.statusInfo.hasDataAccess);
      map.set('hypoTrendReadings', Immutable.fromJS(action.payload.statusInfo.hypoTrendReadings));
      map.set('hasAcceptedTermsAndConditions', action.payload.statusInfo.hasAcceptedTermsAndConditions);
      map.set('healthfeed', Immutable.fromJS(action.payload.statusInfo.healthfeed));
      map.set('pogoCount', action.payload.statusInfo.pogoCount);
      map.set('relationshipCount', action.payload.statusInfo.relationshipCount);
      map.set('isProfileComplete', action.payload.statusInfo.isProfileComplete);
      map.set('isEmailVerified', action.payload.statusInfo.isEmailVerified);
      map.set('isActive', false);
      map.set('location', Immutable.fromJS(action.payload.statusInfo.location));
    });

  case actionTypes.UPDATE_STATUS_SKIPPED_SETUP_REMAINGN_SUCCESS:
    return state.set('skippedSetupRemaining', Immutable.fromJS(action.payload.data.skippedSetupRemaining?.filter(el => String(el).trim() !== 'meters')));

  case actionTypes.PUT_TRACKING_MESSAGE_SEEN_STATUS:
    return state.setIn(['location', 'enable_tracking_message_seen'], action.payload);

  case actionTypes.REFRESH_TOKEN_SUCCESS:
  case actionTypes.REFRESH_TOKEN_FAILURE:
  case actionTypes.GET_STATUS_FAILURE:
    return state.set('isActive', false);

  case actionTypes.UPDATE_ACCEPT_TERMS_AND_CONDITIONS:
    return state.set('hasAcceptedTermsAndConditions', true);

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
