import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@material-ui/core';

const propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  floatingLabelFixed: PropTypes.bool,
  disabled: PropTypes.bool,
  errorHiddenUnderText: PropTypes.string,
  endAdornment: PropTypes.node,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  hintText: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitError: PropTypes.node,
    valid: PropTypes.bool
  }).isRequired,
  multiLine: PropTypes.bool,
  type: PropTypes.string,
  rows: PropTypes.number,
  underText: PropTypes.string
};

const defaultProps = {
  type: 'text'
};

function MuiTextField(props) {
  const {
    autoComplete,
    className,
    disabled,
    onChange,
    endAdornment,
    floatingLabelFixed,
    input,
    label,
    meta: { error, touched, submitFailed, submitError, valid },
    multiLine,
    rows = 1,
    type
  } = props;

  const inputProps = { ...input };

  if (disabled) {
    inputProps.disabled = true;
  }

  const showErrorText = (touched && !valid) || submitFailed;

  return (
    <TextField
      classes={{ root: className }}
      label={label}
      type={type}
      focused={floatingLabelFixed}
      error={(showErrorText && (Boolean(error) || Boolean(submitError)))}
      helperText={showErrorText && (error || submitError)}
      autoComplete="current-password"
      fullWidth
      shrink="true"
      minRows={rows}
      multiline={multiLine}
      InputProps={{ endAdornment, autoComplete }}
      {...inputProps}
      onChange={(e) => {
        input.onChange(e);
        onChange && onChange(e);
      }}
    />
  );
}

MuiTextField.propTypes = propTypes;
MuiTextField.defaultProps = defaultProps;

export default MuiTextField;
