import actionTypes from '../constants/actionTypes';
import avatarService from '../services/avatarService';

// GET_AVATAR

function getAvatarFailure(err) {
  return {
    type: actionTypes.GET_AVATAR_FAILURE,
    error: true,
    payload: err
  };
}

function getAvatarStart() {
  return {
    type: actionTypes.GET_AVATAR_START
  };
}

function getAvatarSuccess(avatar) {
  return {
    type: actionTypes.GET_AVATAR_SUCCESS,
    payload: { avatar }
  };
}

// PUT_AVATAR

function putAvatarFailure(err) {
  return {
    type: actionTypes.PUT_AVATAR_FAILURE,
    error: true,
    payload: err
  };
}

function putAvatarStart() {
  return {
    type: actionTypes.PUT_AVATAR_START
  };
}

function putAvatarSuccess() {
  return {
    type: actionTypes.PUT_AVATAR_SUCCESS
  };
}

export function getAvatar() {
  return async function (dispatch) {
    dispatch(getAvatarStart());

    try {
      const avatar = await avatarService.getAvatar();
      dispatch(getAvatarSuccess(avatar));
    } catch (err) {
      dispatch(getAvatarFailure(err));
    }
  };
}

export function putAvatar(avatar) {
  return async function (dispatch) {
    dispatch(putAvatarStart());

    try {
      await avatarService.putAvatar(avatar);
      dispatch(putAvatarSuccess());
    } catch (err) {
      dispatch(putAvatarFailure(err));
      throw err;
    }
  };
}
