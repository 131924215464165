import React from 'react';

export default function StressedMoodIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="#282829" fillRule="evenodd">
        <path d="M17.665 17.366c-.44-.134-.771-.355-1.163-.594-.646-.393-1.215-.523-1.887-.056-.995.691-2.029.726-3.034-.004-.591-.428-1.145-.355-1.748-.001-.447.261-1.128.533-1.533.533-.553 0-1.966-.797-2.219-.934-.329-.179-.764-.463-.664-.692.136-.406.511-.473.862-.377a5.98 5.98 0 0 1 1.38.594c.493.286.91.323 1.398-.02 1.118-.79 2.261-.787 3.378.018.453.326.88.312 1.33.002 1.152-.787 2.306-.79 3.475-.014.22.148.653.396.859.555.19.145.207.16.327.314.26.337-.048.892-.76.676m-7.65-7.032a1.271 1.271 0 1 1-2.543 0 1.271 1.271 0 0 1 2.542 0m-4.014-2.66l3.562-1.937a.495.495 0 0 1 .67.197c.128.239.04.538-.2.668L6.473 8.54A.492.492 0 0 1 6 7.674m10.725 2.66a1.271 1.271 0 1 1-2.542 0 1.271 1.271 0 0 1 2.542 0m-3.037-4.4c.13-.24.43-.327.668-.197l3.563 1.938a.492.492 0 1 1-.472.865l-3.562-1.938a.492.492 0 0 1-.197-.668M12 1C5.935 1 1 5.934 1 12c0 6.065 4.935 11 11 11s11-4.935 11-11c0-6.066-4.935-11-11-11"/>
        <path d="M17.665 17.366c-.44-.134-.771-.355-1.163-.594-.646-.393-1.215-.523-1.887-.056-.995.691-2.029.726-3.034-.004-.591-.428-1.145-.355-1.748-.001-.447.261-1.128.533-1.533.533-.553 0-1.966-.797-2.219-.934-.329-.179-.764-.463-.664-.692.136-.406.511-.473.862-.377a5.98 5.98 0 0 1 1.38.594c.493.286.91.323 1.398-.02 1.118-.79 2.261-.787 3.378.018.453.326.88.312 1.33.002 1.152-.787 2.306-.79 3.475-.014.22.148.653.396.859.555.19.145.207.16.327.314.26.337-.048.892-.76.676m-7.65-7.032a1.271 1.271 0 1 1-2.543 0 1.271 1.271 0 0 1 2.542 0m-4.014-2.66l3.562-1.937a.495.495 0 0 1 .67.197c.128.239.04.538-.2.668L6.473 8.54A.492.492 0 0 1 6 7.674m10.725 2.66a1.271 1.271 0 1 1-2.542 0 1.271 1.271 0 0 1 2.542 0m-3.037-4.4c.13-.24.43-.327.668-.197l3.563 1.938a.492.492 0 1 1-.472.865l-3.562-1.938a.492.492 0 0 1-.197-.668M12 1C5.935 1 1 5.934 1 12c0 6.065 4.935 11 11 11s11-4.935 11-11c0-6.066-4.935-11-11-11"/>
      </g>
    </svg>
  );
}
