/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Table from '@material-ui/core/Table';
import { Grid } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { StyledTableRow } from 'src/components/muiStyledComponents/StyledTableRow';
import StyledTableHeadCell from 'src/components/muiStyledComponents/StyledTableHeadCell';
import AvatarPresenter from 'src/components/AvatarPresenter';
import TypesPresenter from 'src/components/contacts/TypesPresenter/TypesPresenter';
import StyledTablePagination from 'src/components/muiStyledComponents/StyledTablePagination';
import usePaginatorHook from 'src/hooks/usePaginatorHook';
import ContactsIAmSharingSentInvitationsTableButtonsContainer
  from 'src/containers/ContactsIAmSharingSentInvitationsTableButtonsContainer';
import InvitesStatusView from 'src/components/InvitesStatusView';
import { normalizePhone } from 'src/services/fieldNormalizationService';

const propTypes = {
  itemsList: PropTypes.arrayOf(PropTypes.shape({
    avatarUrl: PropTypes.string,
    emailAddress: PropTypes.string,
    invitationId: PropTypes.number,
    name: PropTypes.string,
    relation: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.array
  })),
  memoizedFetch: PropTypes.func.isRequired,
  itemsListCount: PropTypes.number
};

function ContactsIAmSharingWithSentInvitesTable({ itemsList, itemsListCount, memoizedFetch }) {
  const { parsedQuery, handleChangeRowsPerPage, handleChangePage } = usePaginatorHook();
  const { perPage = 5, page = 0 } = parsedQuery;

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow className="h-height-xl">
            <StyledTableHeadCell align="left" style={{ width: 276 }}>Contact Name</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 100 }}>Type</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 310 }}>Contacts</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 150 }}>Status</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 330 }}>Action</StyledTableHeadCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {!isEmpty(itemsList) && itemsList.map((item, index) => {
            const isOutOfDate = ['expired', 'declined'].includes(String(item.status).toLowerCase());
            const tableRowClasses = classNames(
              'jest-item-invite',
              'h-height-xl',
              { 'h-danger': isOutOfDate }
            );

            return (
              <StyledTableRow key={index} className={tableRowClasses}>
                <TableCell align="left">
                  <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item><AvatarPresenter avatarUrl={item.avatarUrl} /></Grid>
                    <Grid item>{item.name}</Grid>
                  </Grid>
                </TableCell>

                <TableCell align="left">
                  <Grid container alignItems="center"><TypesPresenter type={item.type} /></Grid>
                </TableCell>

                <TableCell align="left">
                  {item.phoneNumber && (
                    <Grid container alignItems="center">Phone: {normalizePhone(item.phoneNumber)}</Grid>
                  )}
                  {item.emailAddress && (
                    <Grid container alignItems="center">Email: {item.emailAddress}</Grid>
                  )}
                </TableCell>

                <TableCell align="left">
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <InvitesStatusView status={item.status}/>
                  </Grid>
                </TableCell>

                <TableCell align="center">
                  <ContactsIAmSharingSentInvitationsTableButtonsContainer
                    itemsListCount={itemsList.length}
                    memoizedFetch={memoizedFetch}
                    item={item}
                  />
                </TableCell>
              </StyledTableRow>
            );
          })}

          {isEmpty(itemsList) ? (
            <StyledTableRow style={{ height: 30 }} className="h-height-xl">
              <TableCell colSpan={5} className="h-center jest-empty">No results found</TableCell>
            </StyledTableRow>
          ) : null}
        </TableBody>
      </Table>

      <StyledTablePagination
        page={Number(page) || 0}
        rowsPerPage={Number(perPage) || 0}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={itemsListCount}
        labelRowsPerPage="Rows:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

ContactsIAmSharingWithSentInvitesTable.propTypes = propTypes;

export default memo(ContactsIAmSharingWithSentInvitesTable);
