import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Paper, TableHead, TableContainer, Table, TableBody, TableCell } from '@material-ui/core';
import { StyledTableRow } from 'src/components/muiStyledComponents/StyledTableRow';
import StyledTableHeadCell from 'src/components/muiStyledComponents/StyledTableHeadCell';
import StyledTablePagination from 'src/components/muiStyledComponents/StyledTablePagination';
import usePaginatorHook from 'src/hooks/usePaginatorHook';
import TypesPresenter from 'src/components/contacts/TypesPresenter/TypesPresenter';
import ContactsSharingWithMeTableButtonsContainer from 'src/containers/ContactsSharingWithMeTableButtonsContainer';
import AvatarPresenter from 'src/components/AvatarPresenter';
import PhoneTooltip from 'src/components/contacts/PhoneTooltip';

const propTypes = {
  memoizedFetch: PropTypes.func.isRequired,
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number
};

export default function ContactsSharingWithMeTable({ itemsList, itemsListCount, memoizedFetch }) {
  const { parsedQuery, handleChangeRowsPerPage, handleChangePage } = usePaginatorHook();
  const { perPage = 5, page = 0 } = parsedQuery;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow className="h-height-xl" data-testid="table-head">
            <StyledTableHeadCell align="left" style={{ width: 380 }}>Contact Name</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 100 }}>Type</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 400 }}>EMAIL</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 288 }}>ACTION</StyledTableHeadCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {itemsList.map((patient, index) => {
            return (
              <StyledTableRow key={index} className="h-height-xl" data-testid="table-row">
                <TableCell align="left">
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid><AvatarPresenter avatarUrl={patient.avatarUrl} /></Grid>
                    <Grid>{patient.name}</Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center"><TypesPresenter type={patient.type} /></Grid>
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center">
                    <Grid item xs={10}>{patient.emailAddress}</Grid>
                    {(patient.type || []).includes('Emergency Contacts') && patient?.phoneNumber && (
                      <Grid item xs={2}>
                        <Grid container justifyContent="flex-end">
                          <PhoneTooltip phoneNumber={patient?.phoneNumber}/>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <ContactsSharingWithMeTableButtonsContainer
                    itemsListCount={itemsList.length}
                    patient={patient}
                    memoizedFetch={memoizedFetch}
                  />
                </TableCell>
              </StyledTableRow>
            );
          })}
          {!itemsList.length ? (
            <StyledTableRow style={{ height: 30 }} className="h-height-xl">
              <TableCell colSpan={4} className="h-center jest-empty">No results found</TableCell>
            </StyledTableRow>
          ) : null}
        </TableBody>
      </Table>

      <StyledTablePagination
        page={Number(page) || 0}
        rowsPerPage={Number(perPage) || 0}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={itemsListCount || 0}
        labelRowsPerPage="Rows:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

ContactsSharingWithMeTable.propTypes = propTypes;
