import '@babel/polyfill';
import 'es6-promise/auto';
import './utils/edgeBrowserPolinoms'; // Polynomials of TextEncoder and TextDecoder for EDGE v.44
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import AuthenticatedAppContainer from 'src/containers/AuthenticatedAppContainer';
import TokenHandler from 'src/pages/tokenHandle/TokenHandler';
import Welcome from 'src/components/Welcome';
import PatternsSnackbarContainer from 'src/containers/PatternsSnackbarContainer';
import HelpContactContainer from 'src/containers/HelpContactContainer';
import TermsContainer from 'src/containers/TermsContainer';
import PhoneVerification from 'src/components/PhoneVerification';
import GeolocationContainer from 'src/containers/GeolocationContainer';
import ControlCenter from 'src/components/ControlCenter';
import WithCountly from 'src/containers/withCountly';
import ErrorCenter from 'src/pages/errorCenter/ErrorCenter';
import ConfirmMount from 'src/shared/ConfirmMount';
import ResetPasswordSMS from 'src/components/ResetPasswordSMS';

// Theme and Material UI - only used for specific components
import { ThemeProvider } from '@material-ui/core/styles';
import muiTheme from './utils/muiTheme';
// Store
import storeService from './services/store';
// Styles
import './assets/css/styles.css';

const store = storeService.getStore();
const rootEl = document.getElementById('root');

ReactDOM.createRoot(rootEl)
  .render(
    <ThemeProvider theme={muiTheme}>
      <Provider store={store}>
        <WithCountly>
          <HashRouter>
            <Switch>
              <Route path="/welcome" component={Welcome}/>
              <Route path="/reset-pwd/:token" component={ResetPasswordSMS} />
              <Route path="/token/:token" component={TokenHandler}/>
              <Route path="/help" component={HelpContactContainer}/>
              <Route path="/terms-and-conditions" component={TermsContainer}/>
              <Route path="/phone/verify" component={PhoneVerification}/>
              <Route path="/emergency-event" component={GeolocationContainer}/>
              <Route path="/control-center" component={ControlCenter}/>
              <Route path="/errors" component={ErrorCenter}/>
              <Route component={AuthenticatedAppContainer}/>
            </Switch>
          </HashRouter>
          <PatternsSnackbarContainer/>
          <ConfirmMount />
        </WithCountly>
      </Provider>
    </ThemeProvider>
  );
