import PropTypes from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import moment from 'moment';
import Calendar from './Calendar';
import SelectedDateInputs from './SelectedDateInputs';
import CalendarService from 'src/services/calendarService';

const cal = new CalendarService(0);

export default class DateRangePicker extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    closeOnEsc: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
    defaultEnd: PropTypes.string,
    defaultStart: PropTypes.string,
    setCustomTimeframe: PropTypes.func
  };

  state = {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    selectedFrom: this.props.defaultStart || null,
    selectedTo: this.props.defaultEnd ? moment.utc(this.props.defaultEnd).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
    selecting: 'from'
  };

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeydown);
    }

    if (this.props.closeOnOutsideClick) {
      document.addEventListener('mouseup', this.handleOutsideMouseClick);
      document.addEventListener('touchstart', this.handleOutsideMouseClick);
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeydown);
    }

    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('mouseup', this.handleOutsideMouseClick);
      document.removeEventListener('touchstart', this.handleOutsideMouseClick);
    }
  }

  setInput = type => {
    this.setState({ selecting: type });
  }

  setCustomTimeframe = () => {
    const from = moment(this.state.selectedFrom).utc().startOf('day').format();
    const to = moment(this.state.selectedTo).utc().endOf('day').format();
    this.props.setCustomTimeframe(from, to);
  }

  handleOutsideMouseClick = e => {
    const root = findDOMNode(this.modal);

    if (root.contains(e.target) || (e.button && e.button !== 0)) {
      return;
    }

    e.stopPropagation();
    this.props.closeModal();
  }

  handleKeydown = e => {
    if (e.keyCode === 27) {
      this.props.closeModal();
    }
  }

  selectTo = day => {
    this.setState(() => {
      const newState = {
        selectedTo: day.format()
      };

      return newState;
    });
  }

  selectFrom = day => {
    this.setState(oldState => {
      const newState = {
        selectedFrom: day.format(),
        selecting: 'to'
      };

      if (
        oldState.selectedTo &&
        (day.diff(moment(oldState.selectedTo), 'days') < 31 || day.diff(moment(oldState.selectedTo), 'days') >= 30)
      ) {
        newState.selectedTo = null;
      }

      return newState;
    });
  }

  prev = () => {
    if (this.state.month === 0) {
      return this.setState({
        year: this.state.year - 1,
        month: 11
      });
    }

    return this.setState({
      month: this.state.month - 1
    });
  }

  next = () => {
    if (this.state.month === 11) {
      return this.setState({
        year: this.state.year + 1,
        month: 0
      });
    }

    return this.setState({
      month: this.state.month + 1
    });
  }

  render() {
    const weeks = cal.monthDates(this.state.year, this.state.month).map(week => week.map(day => moment(day)));
    const submitReady = this.state.selectedTo && this.state.selectedFrom;
    const buttonClasses = classNames(
      'c-button',
      'c-button--block',
      { 'c-button--action': submitReady },
      { 'c-button--disabled': !submitReady }
    );

    return (
      <div className="c-card c-card--elevated c-card--white c-date-range-picker" ref={/* istanbul ignore next */node => { this.modal = node; }}>
        <div>
          <SelectedDateInputs
            selecting={this.state.selecting}
            selectedFrom={this.state.selectedFrom}
            selectedTo={this.state.selectedTo}
            setInput={this.setInput}
          />
          <Calendar
            cal={cal}
            monthNumber={this.state.month + 1}
            weeks={weeks}
            next={this.next}
            prev={this.prev}
            select={this.state.selecting === 'from' ? this.selectFrom : this.selectTo}
            selectedFrom={this.state.selectedFrom}
            selectedTo={this.state.selectedTo}
            selecting={this.state.selecting}
            year={this.state.year}
          />
          <button
            data-testid="update"
            className={buttonClasses}
            onClick={this.setCustomTimeframe}
          >
            Update Timeframe
          </button>
        </div>
      </div>
    );
  }
}
