/* istanbul ignore file */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { getStatus } from '../actions/statusActions';
import { postReadingTag, putReadingTag, deleteReadingTag } from '../actions/readingTagsActions';
// Components
import EditHydrationTag from '../components/logs/EditHydrationTag';
import { immutableToJsHOC } from './ImmutableToJsHOC';
// // Selectors
import { makeGetReadingsForSelectedDate } from '../selectors/readingSelectors';

function makeMapStateToProps() {
  const getSelectedDateReadings = makeGetReadingsForSelectedDate();

  return function (state, props) {
    const selectedId = state.readings.get('selectedId');

    return {
      currentDateReadings: getSelectedDateReadings(state, props),
      selectedReading: selectedId ? state.readings.getIn(['cache', selectedId]) : null
    };
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getStatus: bindActionCreators(getStatus, dispatch),
    postReadingTag: bindActionCreators(postReadingTag, dispatch),
    putReadingTag: bindActionCreators(putReadingTag, dispatch),
    deleteReadingTag: bindActionCreators(deleteReadingTag, dispatch)
  };
}

export default connect(makeMapStateToProps, mapDispatchToProps)(immutableToJsHOC(EditHydrationTag));
