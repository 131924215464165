/* istanbul ignore file */
import { connect } from 'react-redux';
import { deletePatientMeter, getPatientMeters } from 'src/actions/settingsActions';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import Meters from 'src/pages/profile/Meters';

const mapStateToProps = (state) => ({
  meters: state.settings.get('meters')
});

export default connect(mapStateToProps, {
  deletePatientMeter,
  getPatientMeters
})(immutableToJsHOC(Meters));
