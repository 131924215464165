import PropTypes from 'prop-types';
import React from 'react';
import { parse, stringify } from 'query-string';
import debounce from 'lodash/debounce';
import SearchBar from '../SearchBar';
import FoodSearchResultsPresenter from './FoodSearchResultsPresenter';

export default class FoodSearch extends React.Component {
  static propTypes = {
    clearNutritionix: PropTypes.func.isRequired,
    setNutritionixQuery: PropTypes.func.isRequired,
    getNutritionixAutocomplete: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired
      }),
      replace: PropTypes.func.isRequired
    }).isRequired,
    nutritionix: PropTypes.shape({
      query: PropTypes.string,
      queries: PropTypes.object
    }),
    readingId: PropTypes.number,
    selectedReading: PropTypes.shape({
      foods: PropTypes.array
    })
  };

  constructor(props) {
    super(props);
    this.getNutritionixAutocompleteDebaunced = debounce(props.getNutritionixAutocomplete, 350);
  }

  componentDidMount() {
    const query = parse(this.props.history.location.search);
    if (!this.props.nutritionix.query && query.search) {
      this.props.setNutritionixQuery(query.search);
    }
  }

  componentDidUpdate(prevProps) {
    const nextQuery = this.props.nutritionix.query;
    if (nextQuery === prevProps.nutritionix.query) {
      return;
    }

    if (nextQuery.length > 0) {
      if (!this.props.nutritionix.queries[nextQuery]) {
        this.getNutritionixAutocompleteDebaunced(nextQuery, this.props.readingId);
      }
      this.props.history.replace({
        pathname: this.props.history.location.pathname,
        search: stringify({
          search: nextQuery
        })
      });
    } else {
      this.props.clearNutritionix();
      this.props.history.replace({
        pathname: this.props.history.location.pathname
      });
    }
  }

  componentWillUnmount() {
    this.getNutritionixAutocompleteDebaunced.cancel();
  }

  updateQuery = (evt) => {
    this.props.setNutritionixQuery(evt.target.value);
  }

  render() {
    const { selectedReading, nutritionix: { query, queries }, history } = this.props;
    const hasFoods = selectedReading && selectedReading.foods && selectedReading.foods.length;
    const foodObj = queries && queries[query];

    return (
      <div className="c-card__body c-card__body--unpadded">
        <SearchBar
          className="c-field c-field--search"
          placeholder="Search food by name, restaurant, or brand"
          onChange={this.updateQuery}
          value={query}
        />
        {!foodObj && !hasFoods &&
        <div className="h-center h-padded-lg">
          Add carbohydrates associated
          <br />
          with your reading.
        </div>}
        {foodObj && !foodObj.common.length && !foodObj.branded.length && (
          <div className="h-center h-padded-lg">
            No results found.
          </div>
        )}
        <FoodSearchResultsPresenter
          isEmpty={!foodObj}
          label="Common"
          history={history}
          list={foodObj && foodObj.common}
        />
        <FoodSearchResultsPresenter
          isEmpty={!foodObj}
          label="Branded"
          history={history}
          list={foodObj && foodObj.branded}
        />
      </div>
    );
  }
}
