import React from 'react';
import ContactsInviteTypes from 'src/pages/contacts/children/invites/ContactsInviteTypes/ContactsInviteTypes';
import ContactsEmergenycContactWelcome from 'src/pages/contacts/children/invites/emergencyContact/ContactsEmergencyContactWelcome/ContactsEmergencyContactWelcome';
import ContactsHcpInviteStatus from 'src/components/contacts/ContactsHcpInviteStatus/ContactsHcpInviteStatus';
import ContactsAddSharingCircleFormContainer from 'src/containers/ContactsAddSharingCircleFormContainer';
import ContactsHcpPracticeInvitationManagerContainer from 'src/containers/ContactsHcpAndPracticeSearchContainer';
import ContactsHcpInfoViewContainer from 'src/containers/ContactsHcpInfoViewContainer';
import ContactsPracticeInfoViewContainer from 'src/containers/ContactsPracticeInfoViewContainer';
import ContactsEmergencyContactFormContainer from 'src/containers/ContactsEmergencyContactFormContainer';
import ContactsSCInviteStatus from 'src/components/contacts/ContactsSCInviteStatus/ContactsSCInviteStatus';
import ContactsECInviteStatus from 'src/components/contacts/ContactsECInviteStatus/ContactsECInviteStatus';
import { fromHcpWrapper } from 'src/pages/contacts/children/invites/hcp/ContactsHcpInfoView/fromHcpWrapper';
import { fromPracticeWrapper } from 'src/pages/contacts/children/invites/hcp/ContactsHcpInfoView/fromPracticeWrapper';

export const contactsInviteViews = {
  inviteTypes: props => React.createElement(ContactsInviteTypes, props),
  hcpSearchView: props => React.createElement(ContactsHcpPracticeInvitationManagerContainer, props),
  hcpInfoView: props => React.createElement(fromHcpWrapper(ContactsHcpInfoViewContainer), props),
  practiceHcpInfoView: props => React.createElement(fromPracticeWrapper(ContactsHcpInfoViewContainer), props),
  practiceInfoView: props => React.createElement(ContactsPracticeInfoViewContainer, props),
  scFormView: props => React.createElement(ContactsAddSharingCircleFormContainer, props),
  ecWelcomeView: props => React.createElement(ContactsEmergenycContactWelcome, props),
  ecFormView: props => React.createElement(ContactsEmergencyContactFormContainer, props),
  successInviteView: props => React.createElement(ContactsHcpInviteStatus, props),
  successECInviteView: props => React.createElement(ContactsECInviteStatus, props),
  successSCInviteView: props => React.createElement(ContactsSCInviteStatus, props)
};

export const contactsViewsConstants = {
  inviteTypes: 'inviteTypes',
  hcpSearchView: 'hcpSearchView',
  hcpInfoView: 'hcpInfoView',
  practiceHcpInfoView: 'practiceHcpInfoView',
  practiceInfoView: 'practiceInfoView',
  scFormView: 'scFormView',
  ecWelcomeView: 'ecWelcomeView',
  ecFormView: 'ecFormView',
  successInviteView: 'successInviteView',
  successECInviteView: 'successECInviteView',
  successSCInviteView: 'successSCInviteView'
};
