/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { setTimeframe, shiftTimeframeBySpan } from '../actions/timeframeActions';
import { defaultOptions } from '../services/timeframeService';
import TimeframeRecord from '../records/TimeframeRecord';
import Timeframe from '../components/Timeframe';

const propTypes = {
  timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired
};

export function TimeframeContainer(props) {
  return <Timeframe {...props} options={defaultOptions} />;
}

TimeframeContainer.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    timeframe: state.timeframe
  };
}

export default connect(mapStateToProps, {
  setTimeframe,
  shiftTimeframeBySpan
})(TimeframeContainer);
