import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function FoodIcon({ className }) {
  let classes = 'c-icon c-icon--food';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <path
        className="c-icon__fill c-icon__fill--alt"
        d="M15.585,21.842 C13.228,21.823 15.097,15.938 15.201,13.053 L14.153,13.045 C13.938,10.612 13.749,3.883 16.057,3.612 C16.266,3.587 16.265,3.822 16.265,3.822 L16.141,12.196 C16.123,13.816 17.955,21.86 15.585,21.842 M10.306,11.441 C9.275,12.014 12.517,21.839 9.598,21.833 C6.685,21.828 9.958,12.013 8.935,11.439 C6.975,10.338 7.777,8.067 7.981,5.971 C8.461,1.036 8.524,4.838 8.518,8.295 C8.517,8.625 9.022,8.625 9.023,8.296 C9.023,7.837 8.761,3.623 9.243,3.623 C9.768,3.623 9.403,7.839 9.402,8.297 C9.402,8.62 9.849,8.621 9.85,8.298 C9.851,7.84 9.696,3.625 10.026,3.625 C10.612,3.625 10.231,7.839 10.231,8.298 C10.23,8.628 10.734,8.628 10.735,8.299 C10.741,4.843 10.817,1.04 11.28,5.977 C11.476,8.072 12.271,10.35 10.306,11.441 M12.5,1 C6.148,1 1,6.149 1,12.5 C1,18.852 6.148,24 12.5,24 C18.851,24 23.999,18.852 23.999,12.5 C23.999,6.149 18.851,1 12.5,1"
      />
    </svg>
  );
}

FoodIcon.propTypes = propTypes;

export default FoodIcon;
