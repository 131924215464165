import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    padding: '1rem 2rem',
    background: '#fff',
    width: '100%',
    flexShrink: 0,
    zIndex: 10,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
  },
  subHeaderContainer: {
    backgroundColor: theme.palette.background?.light,
    padding: '1rem'
  },
  subHeader: {
    color: theme.palette.text?.main,
    padding: '0.5rem 4rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 4rem'
    }
  },
  itemContainer: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: theme.palette.background?.light,
    borderBottom: '1px solid #fff',
    padding: '1rem',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.backgroundActive?.main
    }
  },
  itemIcon: {
    '& > svg': {
      width: '1.25rem',
      heigth: '1.25rem'
    }
  },
  itemTitle: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    width: 'auto'
  },
  strokeIcon: {
    stroke: theme.palette.text?.main
  },
  actionContainer: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& .c-button': {
      width: '190px',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '&:not(:first-child)': {
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
          marginTop: '16Px'
        }
      }
    }
  },
  dialogBodyContainer: {
    padding: 0
  },
  progressContainer: {
    color: theme.palette.background?.light
  }
}));
