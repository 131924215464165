import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import EditTag from './EditTag';
import CountlyTrackedButton from '../countly/CountlyTrackedButton';
import warnAboutUnsavedChangesNoForm from '../../containers/warnAboutUnsavedChangesNoForm';
import isEqualWith from 'lodash/isEqualWith';
import { diffByIdOrTypeReading } from '../../utils/logBookFoods';

export class CommentEditTag extends Component {
  static propTypes = {
    disablePreventRouteLeave: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    postReadingTag: PropTypes.func.isRequired,
    putReadingTag: PropTypes.func.isRequired,
    preventRouteLeave: PropTypes.func.isRequired,
    selectedReading: PropTypes.shape({
      full_tags: PropTypes.array,
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);
    let tag;
    if (props.selectedReading) {
      tag = props.selectedReading.full_tags.find(t => t.type === 'comment');
    }
    this.state = {
      tag,
      value: tag ? tag.value : ''
    };
  }

  componentDidMount() {
    if (!this.props.selectedReading) {
      this.props.history.replace({ pathname: '/log-book' });
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // This is for closing window if user clicks on other date
    if (
      !isEqualWith(prevProps.selectedReading, this.props.selectedReading, diffByIdOrTypeReading) ||
      !this.props.selectedReading
    ) {
      this.props.history.replace({
        pathname: '/log-book'
      });
    }

    if (prevState.value !== this.state.value) {
      this.props.preventRouteLeave();
    }
  }

  isOfTypeComment = el => el.type === 'comment';

  /*
   * Will return tag of type 'water'
   */
  returnTag = () => {
    const { selectedReading } = this.props;
    return selectedReading && selectedReading.full_tags.find(this.isOfTypeComment);
  };

  handleSave = (link) => async () => {
    const { selectedReading: { id }, history } = this.props;
    const tag = { type: 'comment', value: String(this.state.value).trim() };

    this.props.disablePreventRouteLeave();

    const isTag = this.returnTag();

    if (isTag) {
      tag.id = isTag.id;
      await this.props.putReadingTag(id, tag);
    } else {
      await this.props.postReadingTag(id, tag);
    }

    history.push(link);
  };

  handleDelete = (link) => async () => {
    const { selectedReading: { id }, history } = this.props;
    const tag = { type: 'comment', value: '' };

    this.props.disablePreventRouteLeave();

    const isTag = this.returnTag();

    if (isTag) {
      tag.id = isTag.id;
      await this.props.putReadingTag(id, tag);
    } else {
      await this.props.postReadingTag(id, tag);
    }

    history.push(link);
  }

  changeValue = (evt) => {
    this.setState({ value: evt.target.value });
  }

  isSubmitDisabled = () => {
    const isTag = this.returnTag();
    const isEmpty = String(this.state.value).trim() === '';

    if (!isTag && isEmpty) {
      return true;
    }

    return isTag ? this.state.value === isTag.value : isEmpty;
  }

  isDeleteDisabled = () => {
    const isTag = this.returnTag();

    if (!isTag) {
      return true;
    }

    const isSame = this.state.value === isTag.value;
    const isEmpty = String(isTag.value).trim() === '';

    return isEmpty || !isSame;
  }

  render() {
    const { history } = this.props;
    const linkString = '/edit/comment';
    const backLink = history.location.pathname.slice(0, history.location.pathname.length - linkString.length);

    return (
      <EditTag
        backLink={backLink}
        className="c-log__detail c-card"
        label="Comment"
      >
        <div className="c-card__body">
          <div className="h-center h-margin-bottom-lg">
            Please note any comments about this reading.
          </div>

          <TextField
            value={this.state.value}
            onChange={this.changeValue}
            multiLine
            rows={1}
            rowsMax={8}
            fullWidth
            helperText="i.e. Had an apple"
            type="text"
          />
        </div>
        <div className="h-padded">
          <CountlyTrackedButton
            dataTestid="save-button"
            countlyevent="update"
            disabled={this.isSubmitDisabled()}
            className="c-button c-button--block c-button--action  h-margin-bottom-sm"
            onClick={this.handleSave(backLink)}
          >
            Save
          </CountlyTrackedButton>
          <CountlyTrackedButton
            dataTestid="delete-button"
            countlyevent="delete"
            disabled={this.isDeleteDisabled()}
            className="c-button c-button--block c-button--danger-hollow"
            onClick={this.handleDelete(backLink)}
          >
            Remove Data and Save
          </CountlyTrackedButton>
        </div>
      </EditTag>
    );
  }
}

export default warnAboutUnsavedChangesNoForm(CommentEditTag);
