import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  foodsDevices: new Immutable.List([]),
  sleepsDevices: new Immutable.List([]),
  summariesDevices: new Immutable.List([]),
  weightsDevices: new Immutable.List([]),
  workoutsDevices: new Immutable.List([]),
  waterDevices: new Immutable.List([])
});

export default function deviceListReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.GET_PGHD_DEVICE_LIST_SUCCESS:
    return state.withMutations((map) => {
      map.set('foodsDevices', Immutable.fromJS(action.payload.foodsDevices));
      map.set('sleepsDevices', Immutable.fromJS(action.payload.sleepsDevices));
      map.set('summariesDevices', Immutable.fromJS(action.payload.summariesDevices));
      map.set('weightsDevices', Immutable.fromJS(action.payload.weightsDevices));
      map.set('workoutsDevices', Immutable.fromJS(action.payload.workoutsDevices));
    });

  case actionTypes.GET_TAGS_DEVICE_LIST_SUCCESS:
    return state.withMutations((map) => {
      map.set('waterDevices', Immutable.fromJS(action.payload.waterDevices));
    });

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
