/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// Helpers
import { isMealTag, BG_DISPLAY_MIN, BG_DISPLAY_MAX } from '../../utils/readingHelpers';
// Components
import MealTimingGroup from './MealTimingGroup';
import GlucoseReadingSVG from './GlucoseReadingSVG';
import LogBookFetcherContainer from '../../containers/LogBookFetcherContainer';

const propTypes = {
  innerHeight: PropTypes.number.isRequired,
  innerWidth: PropTypes.number.isRequired,
  leftRightSpacing: PropTypes.number.isRequired,
  isSharingCircleView: PropTypes.bool.isRequired,
  selectedReading: PropTypes.object,
  sv: PropTypes.object,
  width: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired
};

function SelectedReading(props) {
  const {
    innerHeight,
    innerWidth,
    leftRightSpacing,
    selectedReading,
    sv,
    width,
    xScale,
    yScale
  } = props;
  const height = sv.spacing.xl + sv.spacing.sm;
  const center = innerWidth / 2;
  const timestamp = moment(selectedReading.timestamp).utc();
  const selectedX = xScale(timestamp.clone());
  const selectedTrianglePath = `M ${selectedX - sv.spacing.md} 0 H ${selectedX + sv.spacing.md} L ${selectedX} ${sv.spacing.md} Z`;
  const rectFill = `url(#${selectedReading.modifier}-gradient)`;
  const sideTextSpacing = sv.spacing.xxl + sv.spacing.sm + sv.spacing.xs;
  const sideTextLeftX = center - sideTextSpacing;
  const sideTextRightX = center + sideTextSpacing;
  let rectX = -leftRightSpacing;
  let rectWidth = innerWidth + leftRightSpacing * 2;
  let rx = 0;
  if (width >= 980) {
    rectX += sv.spacing.sm;
    rectWidth -= sv.spacing.md;
    rx = sv.spacing.lg + sv.spacing.xs;
  }

  let normalizedReading = selectedReading.bloodGlucose;
  if (normalizedReading < BG_DISPLAY_MIN) {
    normalizedReading = BG_DISPLAY_MIN;
  } else if (normalizedReading > BG_DISPLAY_MAX) {
    normalizedReading = BG_DISPLAY_MAX;
  }

  return (
    <g
      id="selected-reading"
    >
      <line
        x1={selectedX}
        x2={selectedX}
        y1={0}
        y2={innerHeight + 5}
        stroke={selectedReading.color}
        strokeWidth={2}
        style={{ pointerEvents: 'none' }}
      />
      <path
        d={selectedTrianglePath}
        fill={selectedReading.color}
        stroke={selectedReading.color}
        strokeWidth={2}
        style={{ pointerEvents: 'none' }}
      />
      <circle
        cx={selectedX}
        cy={yScale(normalizedReading)}
        r={sv.spacing.sm}
        fill="#fff"
        stroke={sv.color.blueGreyOverlay}
        strokeWidth={sv.spacing.sm}
        style={{ pointerEvents: 'none' }}
      />
      <circle
        cx={selectedX}
        cy={yScale(normalizedReading)}
        r={sv.spacing.sm}
        fill="none"
        stroke="#fff"
        strokeWidth={3}
        style={{ pointerEvents: 'none' }}
      />
      <LogBookFetcherContainer
        readingId={selectedReading.id}
        isSelected={false}
      >
        <rect
          fill={rectFill}
          height={height}
          rx={rx}
          width={rectWidth}
          x={rectX}
          y={-(sv.spacing.xl + sv.spacing.xs)}
        />
      </LogBookFetcherContainer>
      <g id="selected-reading-circle" style={{ pointerEvents: 'none' }}>
        <circle
          cx={center}
          cy={-sv.spacing.lg}
          fill={selectedReading.color}
          r={sv.spacing.lg + sv.spacing.md + sv.spacing.sm}
          stroke={sv.color.blueGreyOverlay}
          strokeWidth={12}
        />
        <circle
          cx={center}
          cy={-sv.spacing.lg}
          fill={selectedReading.color}
          r={sv.spacing.lg + sv.spacing.md + sv.spacing.sm}
          stroke="#fff"
          strokeWidth={6}
        />
        <GlucoseReadingSVG value={selectedReading.bloodGlucose} sv={sv} center={center} />
      </g>
      <g id="selected-reading-tag" style={{ pointerEvents: 'none' }}>
        <MealTimingGroup
          color="#fff"
          mealTag={selectedReading.tags.find((t) => isMealTag(t))}
          transform={`translate(${sideTextLeftX - 18}, ${-(sv.spacing.md * 3.5)})`}
        />
        <text
          style={{
            fill: '#fff',
            fontFamily: sv.helveticaRegular,
            fontSize: sv.fontSize.lg,
            fontWeight: 400
          }}
          textAnchor="middle"
          transform={`translate(${sideTextLeftX}, ${-(sv.spacing.sm + sv.spacing.xs)})`}
        >
          {timestamp.format('h:mm A')}
        </text>
      </g>
      <g id="selected-reading-date" style={{ pointerEvents: 'none' }}>
        <text
          style={{
            fill: '#fff',
            fontFamily: sv.helveticaBold,
            fontSize: sv.fontSize.lg,
            fontWeight: 700
          }}
          textAnchor="middle"
          transform={`translate(${sideTextRightX}, ${-(sv.spacing.md + sv.spacing.md + sv.spacing.xs)})`}
        >
          {timestamp.format('dddd')}
        </text>
        <text
          style={{
            fill: '#fff',
            fontFamily: sv.helveticaRegular,
            fontSize: sv.fontSize.md,
            fontWeight: 400
          }}
          textAnchor="middle"
          transform={`translate(${sideTextRightX}, ${-(sv.spacing.sm + sv.spacing.xs)})`}
        >
          {timestamp.format('MMM DD, YYYY')}
        </text>
      </g>
    </g>
  );
}

SelectedReading.propTypes = propTypes;

export default SelectedReading;
