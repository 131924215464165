import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAboutMe, putAboutMe } from '../actions/profileActions';
import { getStatus } from '../actions/statusActions';
import { postPatientMeter } from '../actions/settingsActions';
import { requireAuth } from '../utils/routeHelpers';
import OnboardMeContainer from './OnboardMeContainer';
import AddPogoMeter from '../components/AddPogoMeter';
import onEnter from './onEnter';
import SignOut from './SignOutContainer';
import Logo from '../components/Logo';
import CountlyTrackedRoute from '../components/countly/CountlyTrackedRoute';
import { PAGE_NAMES } from '../constants/routesCountlyNameConstants';
import { immutableToJsHOC } from './ImmutableToJsHOC';

export class OnboardContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      profile: PropTypes.shape({
        getAboutMe: PropTypes.func.isRequired
      }),
      settings: PropTypes.shape({
        postPatientMeter: PropTypes.func.isRequired
      }),
      status: PropTypes.shape({
        getStatus: PropTypes.func.isRequired
      })
    }),
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string
      }),
      push: PropTypes.func.isRequired
    }),
    status: PropTypes.shape({
      hasDataAccess: PropTypes.bool,
      isActive: PropTypes.bool,
      isProfileComplete: PropTypes.bool
    })
  };

  state = {};

  async componentDidMount() {
    await this.props.actions.status.getStatus();
    await this.props.actions.profile.getAboutMe('patient');
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.status.isProfileComplete && nextProps.status.hasDataAccess) {
      nextProps.history.push('/');
    } else if (nextProps.status.isProfileComplete && nextProps.history.location.pathname === '/onboarding/about-me') {
      nextProps.history.push('/onboarding/pogo-meter');
    }

    return null;
  }

  render() {
    if (this.props.status.isActive) {
      return null;
    }

    return (
      <div>
        <div className="l-header l-header--padded">
          <Logo />
          <div className="l-header__right l-header__right--signout">
            <SignOut />
          </div>
        </div>
        <div className="l-container l-container--narrow l-container--spaced">
          <CountlyTrackedRoute pageGroup="Login" pageName={PAGE_NAMES.REGISTRATION_ABOUT_ME} path="/onboarding/about-me" component={OnboardMeContainer} />
          <CountlyTrackedRoute pageGroup="Login" pageName={PAGE_NAMES.REGISTRATION_ADD_POGO} path="/onboarding/pogo-meter" component={AddPogoMeter} />
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    status: state.status
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      profile: bindActionCreators({ getAboutMe, putAboutMe }, dispatch),
      settings: bindActionCreators({ postPatientMeter }, dispatch),
      status: bindActionCreators({ getStatus }, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(onEnter(requireAuth)(immutableToJsHOC(OnboardContainer)));
