/* istanbul ignore file */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  clearNutritionix,
  clearNutritionixSelectedFood,
  getNutritionixFoodByIdAndReadingId,
  getNutritionixFoodAltMeasures,
  getNutritionixFoodById
} from 'src/actions/nutritionixActions';
import {
  deleteReadingFood,
  getRecentFoods,
  getFavoriteFoods,
  getReadingFoods,
  postReadingFood,
  putReadingFood
} from 'src/actions/readingFoodsActions';
import { getReading } from 'src/actions/readingsActions';
import FoodEdit from 'src/components/logs/FoodEdit';
import { immutableToJsHOC } from './ImmutableToJsHOC';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    nutritionix: state.nutritionix
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearNutritionixSelectedFood: bindActionCreators(clearNutritionixSelectedFood, dispatch),
    clearNutritionix: bindActionCreators(clearNutritionix, dispatch),
    deleteReadingFood: bindActionCreators(deleteReadingFood, dispatch),
    getFavoriteFoods: bindActionCreators(getFavoriteFoods, dispatch),
    getReading: bindActionCreators(getReading, dispatch),
    getReadingFoods: bindActionCreators(getReadingFoods, dispatch),
    getRecentFoods: bindActionCreators(getRecentFoods, dispatch),
    getNutritionixFoodById: bindActionCreators(getNutritionixFoodById, dispatch),
    getNutritionixFoodByIdAndReadingId: bindActionCreators(getNutritionixFoodByIdAndReadingId, dispatch),
    getNutritionixFoodAltMeasures: bindActionCreators(getNutritionixFoodAltMeasures, dispatch),
    postReadingFood: bindActionCreators(postReadingFood, dispatch),
    putReadingFood: bindActionCreators(putReadingFood, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(immutableToJsHOC(FoodEdit)));
