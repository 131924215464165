/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const PatternsNavLink = ({ Icon, label, to, onClick, skipExact, TrailingLabel }) => {
  return (
    <NavLink className="c-nav-tab" exact={!skipExact} activeClassName="c-nav-tab--active" to={to} onClick={onClick}>
      <Icon/>
      <span className="c-nav-tab__title">{label}</span>
      {TrailingLabel && <TrailingLabel />}
    </NavLink>
  );
};

PatternsNavLink.propTypes = {
  skipExact: PropTypes.bool,
  Icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  TrailingLabel: PropTypes.func,
  to: PropTypes.string.isRequired
};

export default PatternsNavLink;
