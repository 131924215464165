import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid, Divider } from '@material-ui/core';
import Text from 'src/shared/Text';
import { useHistory } from 'react-router-dom';
import TokenViewWrapper from 'src/components/TokenViewWrapper';
import { useStyles } from './TokenPhoneVerification.styles';

const propTypes = {
  getPhoneNumberVerified: PropTypes.func.isRequired,
  endpointPrefix: PropTypes.string,
  token: PropTypes.string
};

export default function TokenPhoneVerification(props) {
  const history = useHistory();
  const [text, setText] = useState(null);
  const classes = useStyles();
  const { getPhoneNumberVerified, token, endpointPrefix } = props;

  const verifyPhoneNumber = async () => {
    try {
      await getPhoneNumberVerified(token, endpointPrefix);
      setText('We have sent an SMS to your phone number.');
    } catch (err) {
      setText(err?.response?.data?.error || 'Error occurred please try later');
    }
  };

  useEffect(() => {
    verifyPhoneNumber();
  }, []);

  return (
    <TokenViewWrapper>
      <Grid item xs={12}  className={classes.header}>
        <Text fontSize={20} lineHeight={24} font="bold" alignText="left">
          Telephone Confirmation
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Text fontSize={16} lineHeight={22} alignText="left">
          {text || 'Verifying...'}
        </Text>
      </Grid>

      <Divider vPadding="16"/>

      <Grid container justifyContent="center" direction="column">
        <Grid item xs={12}>
          <button onClick={() => history.push('/')} className="c-button c-button--block c-button--hollow">
            Home
          </button>
        </Grid>
      </Grid>
    </TokenViewWrapper>
  );
}

TokenPhoneVerification.propTypes = propTypes;
