import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import InvitationBar from 'src/components/contacts/InvitationBar';
import routesConstants from 'src/constants/routes';
import Text from 'src/shared/Text';

const propTypes = {
  receivedInvitations: PropTypes.shape({
    pending: PropTypes.number
  })
};

function IamSharingInvitesReceivedCount({ receivedInvitations }) {
  const { CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS } = routesConstants;

  if (receivedInvitations?.pending < 1) {
    return null;
  }

  return (
    <InvitationBar
      avatarList={null}
      content={
        <Fragment>
          <Text font="bold" fontSize={18} lineHeight="24" alignText="center">{receivedInvitations?.pending} Received Practice Invitations</Text>
          <Text fontSize={14} alignText="center">Click to Accept or Decline Invitations</Text>
        </Fragment>
      }
      inviteLink={
        <Link to={CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS} className="h-content">
          <strong className="h-c-lime">Go to Practice Invitations {`>`}</strong>
        </Link>
      }
      color="green"
    />
  );
}

IamSharingInvitesReceivedCount.propTypes = propTypes;

export default IamSharingInvitesReceivedCount;
