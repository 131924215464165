import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  content: {
    width: '100%',
    paddingTop: '1rem',
    textAlign: 'center',
    fontSize: '0.888rem',
    marginBottom: '32px'
  }
}));
