import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { determineModifier, isMealTag, mealTagNamePrettifier } from '../../utils/readingHelpers';
import CommentTag from './CommentTag';
import CircleXIcon from '../icons/CircleXIcon';
import CommentsIcon from '../icons/CommentsIcon';
import MealIconChooser from '../icons/MealIconChooser';
import MinusIcon from '../icons/MinusIcon';
import FoodIcon from '../icons/FoodIcon';
import InsulinIcon from '../icons/InsulinIcon';
import MoodIcon from '../icons/MoodIcon';
import TimingIcon from '../icons/TimingIcon';
import WaterIcon from '../icons/WaterIcon';
import GlucoseReading from '../GlucoseReading';

export default class LogDetail extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    isActive: PropTypes.bool,
    isSharingCircleView: PropTypes.bool.isRequired,
    patientTargets: PropTypes.shape({
      targetHigh: PropTypes.number,
      targetLow: PropTypes.number
    }),
    selectedReading: PropTypes.shape({
      bloodGlucose: PropTypes.number,
      tags: PropTypes.array,
      timestamp: PropTypes.string,
      full_tags: PropTypes.array
    })
  };

  state = {
    editing: null
  }

  componentDidMount() {
    if (!this.props.selectedReading) {
      return this.props.history.push(`${this.props.isSharingCircleView ? '/sharing-circle' : ''}/log-book`);
    }
  }

  componentDidUpdate() {
    if (!this.props.selectedReading) {
      return this.props.history.replace(`${this.props.isSharingCircleView ? '/sharing-circle' : ''}/log-book`);
    }
  }

  render() {
    const {
      history,
      match: { params: { id } },
      isSharingCircleView,
      patientTargets,
      selectedReading
    } = this.props;

    if (!selectedReading || !id) {
      return null;
    }

    const time = moment(selectedReading.timestamp).utc();
    const mealTag = selectedReading.tags ? selectedReading.tags.find(isMealTag) : null;
    const modifier = determineModifier(
      selectedReading.bloodGlucose,
      patientTargets.targetHigh,
      patientTargets.targetLow
    );
    const cardHeaderClasses = `c-card__header c-card__header--top-padded c-card__header--${modifier}`;
    const iconClasses = mealTag ? 'c-time__icon c-icon--white' : '';
    const fullTags = selectedReading ? selectedReading.full_tags : null;
    const carbsTag = fullTags ? fullTags.find(tag => tag.type === 'carbs') : null;
    const commentTag = fullTags ? fullTags.find(tag => tag.type === 'comment') : null;
    const insulinTag = fullTags ? fullTags.find(tag => tag.type === 'insulin') : null;
    const moodTag = fullTags ? fullTags.find(tag => tag.type === 'mood') : null;
    const waterTag = fullTags ? fullTags.find(tag => tag.type === 'water') : null;
    const prettyMealTagName = mealTagNamePrettifier(mealTag);

    // <Link/> doesn't have a disable prop, so we're using css
    const linkStyle = isSharingCircleView ? { pointerEvents: 'none' } : null;

    return (
      <div className="c-section">
        <div className="c-card" id="inDetail">
          <div className={cardHeaderClasses}>
            <Link className="c-card__close c-text-and-icon c-text-and-icon--white c-text-and-icon--bold" to={isSharingCircleView ? '/sharing-circle/log-book' : '/log-book'}>
              <CircleXIcon />
              <span className="c-text-and-icon__text">Close</span>
            </Link>
            <div className="c-measurement c-measurement--selected">
              <div className="c-time c-time--stacked">
                <span className="c-time__value">{time.format('h:mm A')}</span>
                {mealTag &&
                  <MealIconChooser className={iconClasses} mealTag={mealTag} />}
              </div>
              <div className="c-reading c-reading--selected">
                <GlucoseReading value={selectedReading.bloodGlucose} />
              </div>
              <div className="c-date c-date--stacked">
                <span className="c-date__day">{time.format('dddd')}</span>
                <span className="c-date__mdy">{time.format('MMM D, YYYY')}</span>
              </div>
            </div>
          </div>
          <div className="c-card__body c-card__body--unpadded">
            <div>
              <Link to={`${history.location.pathname}/edit/timing`} className="c-tag c-tag--detail" style={linkStyle}>
                <TimingIcon className="c-tag__icon" />
                <span className="c-tag__label">Timing</span>
                <span style={{ textTransform: 'capitalize' }}>
                  {mealTag ?
                    <span>{prettyMealTagName}</span> :
                    <MinusIcon />}
                </span>
              </Link>
              <Link to={`${history.location.pathname}/edit/carbs`} className="c-tag c-tag--detail" style={linkStyle}>
                <FoodIcon className="c-tag__icon" />
                <span className="c-tag__label">Carbohydrates</span>
                {carbsTag ?
                  <span>{carbsTag.value} {carbsTag.unit}</span> :
                  <MinusIcon />}
              </Link>
              <Link to={`${history.location.pathname}/edit/mood`} className="c-tag c-tag--detail" style={linkStyle}>
                <MoodIcon className="c-tag__icon" />
                <span className="c-tag__label">Mood / Health</span>
                {moodTag ?
                  <span className="h-capitalize">{moodTag.value}</span> :
                  <MinusIcon />}
              </Link>
              <Link to={`${history.location.pathname}/edit/insulin`} className="c-tag c-tag--detail" style={linkStyle}>
                <InsulinIcon className="c-tag__icon" />
                <span className="c-tag__label">Insulin</span>
                {insulinTag && insulinTag.value ?
                  <span>{insulinTag.value} {parseFloat(insulinTag.value) === 1 ? 'Unit' : insulinTag.unit}</span> :
                  <MinusIcon />}
              </Link>
              <Link to={`${history.location.pathname}/edit/hydration`} className="c-tag c-tag--detail" style={linkStyle}>
                <WaterIcon className="c-tag__icon" />
                <span className="c-tag__label">Hydration</span>
                {waterTag ?
                  <span>{`${waterTag.value} ${waterTag.unit}`}</span> :
                  <MinusIcon />}
              </Link>
              <Link to={`${history.location.pathname}/edit/comment`} className="c-tag c-tag--detail" style={linkStyle}>
                <CommentsIcon className="c-tag__icon" />
                <span className="c-tag__label">Comments</span>
              </Link>
              <CommentTag commentTag={commentTag} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
