import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  sentInvitations: new Immutable.Map({
    invitations: new Immutable.List(),
    sharedWithMeInvitationCount: 0
  }),
  receivedInvitations: new Immutable.Map({
    avatarUrls: new Immutable.List(),
    pending: 0,
    phone_not_verified: 0
  })
});

export default function contactsSharedWithMeInvitationsReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.GET_CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS_SUCCESS:
    return state.set('sentInvitations', Immutable.fromJS(action.payload));

  case actionTypes.GET_CONTACTS_SHARED_WITH_ME_INVITATIONS_COUNT_SUCCESS:
    return state.set('receivedInvitations', Immutable.fromJS(action.payload.receivedInvitations));

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
