import PropTypes from 'prop-types';
import React, { Component } from 'react';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
import isEqualWith from 'lodash/isEqualWith';
import EditTag from './EditTag';
import warnAboutUnsavedChangesNoForm from '../../containers/warnAboutUnsavedChangesNoForm';
import minus from '../../assets/img/log-book-minus.svg';
import plus from '../../assets/img/log-book-plus.svg';
import { countlyTrackHydration } from '../../services/countlyService';
import { diffByIdOrTypeReading } from '../../utils/logBookFoods';
import {
  isOfTypeWater,
  hasTagWater,
  getSelectedReadingWaterTag,
  generateBackLink,
  findWaterTagValue,
  isPreviousReading,
  isNextReading
} from '../../utils/logBookHelpers';

export class EditHydrationTag extends Component {
  static propTypes = {
    currentDateReadings: PropTypes.array,
    disablePreventRouteLeave: PropTypes.func.isRequired,
    deleteReadingTag: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    postReadingTag: PropTypes.func.isRequired,
    putReadingTag: PropTypes.func.isRequired,
    preventRouteLeave: PropTypes.func.isRequired,
    selectedReading: PropTypes.shape({
      timestamp: PropTypes.string,
      full_tags: PropTypes.array,
      id: PropTypes.number
    })
  };

  state = {
    value: 0,
    measurementName: 'fl oz'
  };

  componentDidMount() {
    if (!this.props.selectedReading) {
      this.props.history.replace({ pathname: '/log-book' });
      return;
    }

    this.getSumOfHydrationValueFromPreviousReadings();
    this.getLowestHydrationValueFromNextReadings();

    const selectedReadingWaterTag = getSelectedReadingWaterTag(this.props) || { value: 0, unit: 'fl oz' };
    this.currentReadingValue = Number(selectedReadingWaterTag.value) + Number(this.allPreviousReadingsWaterTagsValueSum);

    this.setState({
      value: this.currentReadingValue,
      measurementName: selectedReadingWaterTag.unit
    });
  }

  componentDidUpdate(prevProps) {
    // This is for closing window if user clicks on other date
    if (
      !isEqualWith(prevProps.selectedReading, this.props.selectedReading, diffByIdOrTypeReading) ||
      !this.props.selectedReading
    ) {
      this.props.history.replace({
        pathname: '/log-book'
      });
    }
  }

  getSumOfHydrationValueFromPreviousReadings = () => {
    const { currentDateReadings, selectedReading } = this.props;

    let previousReadings = currentDateReadings.filter(isPreviousReading(selectedReading));
    previousReadings = previousReadings.length ? previousReadings.filter(hasTagWater) : [];

    // Find the biggest value in array of readings which contains tag water
    const sumOfValuesOfAllWaterTagsValue = sumBy(previousReadings, findWaterTagValue);

    this.allPreviousReadingsWaterTagsValueSum = sumOfValuesOfAllWaterTagsValue ? Number(sumOfValuesOfAllWaterTagsValue) : 0;
  }

  getLowestHydrationValueFromNextReadings = () => {
    const { currentDateReadings, selectedReading } = this.props;

    const filteredReadings = currentDateReadings.filter(isNextReading(selectedReading));

    if (!filteredReadings.length) {
      this.maxAllowedWaterTagValue = 0;
      return;
    }

    const readingWithWaterTag = filteredReadings.filter(hasTagWater);

    if (!readingWithWaterTag.length) {
      this.maxAllowedWaterTagValue = 0;
      return;
    }

    // Find the smallest value in array of readings which contains tag water
    const readingWithLowestWaterTagValue = minBy(readingWithWaterTag, findWaterTagValue);
    const nextReadingWithWaterTag = readingWithLowestWaterTagValue.full_tags.find(isOfTypeWater) || { value: 0 };

    this.maxAllowedWaterTagValue = Number(nextReadingWithWaterTag.value);
  }

  increment = () => {
    this.props.preventRouteLeave();

    this.setState((oldState) => {
      let value;

      if (
        (
          this.maxAllowedWaterTagValue > 0 &&
          (this.maxAllowedWaterTagValue + this.currentReadingValue) > oldState.value
        ) ||
        this.maxAllowedWaterTagValue === 0
      ) {
        value = oldState.value + 8;
      } else {
        value = oldState.value;
      }
      return { value };
    });
  }

  decrement = () => {
    this.props.preventRouteLeave();
    this.setState((oldState) => {
      let value;
      if (this.allPreviousReadingsWaterTagsValueSum < oldState.value) {
        value = oldState.value >= 8 ? oldState.value - 8 : 0;
      } else {
        value = this.allPreviousReadingsWaterTagsValueSum;
      }
      return { value };
    });
  }

  handleSave = (link) => async () => {
    const { history, selectedReading: { id } } = this.props;
    const overalValue = Math.max(0, this.state.value - this.allPreviousReadingsWaterTagsValueSum);

    const tag = { type: 'water', value: overalValue, unit: 'fl oz' };

    this.props.disablePreventRouteLeave();

    const isTag = getSelectedReadingWaterTag(this.props);

    if (isTag) {
      tag.id = isTag.id;
      // We delete tag if it was reduced to 0;
      if (tag.value === 0) {
        await this.props.deleteReadingTag(id, tag.id, tag);
        countlyTrackHydration('delete');
      } else {
        await this.props.putReadingTag(id, tag);
        countlyTrackHydration('update');
      }
    } else {
      await this.props.postReadingTag(id, tag);
      countlyTrackHydration('update');
    }

    history.push(link);
  }

  // Disallow submit 0 if there were no water tag
  isDisabled = () => {
    const isTag = getSelectedReadingWaterTag(this.props);
    let overalValue = this.state.value - this.allPreviousReadingsWaterTagsValueSum;
    overalValue = overalValue < 0 ? 0 : overalValue;

    return !isTag && overalValue === 0;
  }

  render() {
    const { value, measurementName } = this.state;
    const displayTotal = Math.floor((value) / 8) || 0;
    const backLink = generateBackLink(this.props);

    return (
      <EditTag
        backLink={backLink}
        className="c-log__detail c-card"
        label="Hydration"
      >
        <div className="c-card__body">
          <div className="h-center h-margin-bottom-lg">
            Please enter the number of cups of water
            <br />
            you've had so far today.
          </div>
          <p className="h-center">Note: A cup of water is 8oz.</p>
          <div className="c-circle-buttons">
            <button
              data-testid="decrement"
              className="h-cursor__pointer h-bg-none"
              onClick={this.decrement}
            >
              <img src={minus} alt="minus sign" />
            </button>
            <svg width="80" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="12" fill="#2da9df" />
              <text
                dominantBaseline="center"
                fill="#fff"
                fontSize="11"
                fontWeight="bold"
                textAnchor="middle"
                x="0"
                y="0"
                transform="translate(12, 16)"
              >
                {displayTotal}
              </text>
            </svg>
            <button
              data-testid="increment"
              className="h-cursor__pointer h-bg-none"
              onClick={this.increment}
            >
              <img src={plus} alt="minus sign" />
            </button>
          </div>
          <h2 data-testid="measure" className="h-center">{`${value} ${measurementName}`}</h2>
        </div>
        <div className="h-padded">
          <button
            data-testid="save"
            disabled={this.isDisabled()}
            className="c-button c-button--block c-button-action c-button--action"
            onClick={this.handleSave(backLink)}
          >
            Save
          </button>
        </div>
      </EditTag>
    );
  }
}

export default warnAboutUnsavedChangesNoForm(EditHydrationTag);
