/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
  transform: PropTypes.string
};

function ShapeDinner(props) {
  return (
    <path
      {...props}
      d="M12.6587038,1.99999985 C7.27300125,2.29877661 3,6.65803707 3,11.991831 C3,17.5191901 7.58876601,22 13.2492957,22 C16.9538692,22 20.1993912,20.0808064 22,17.2050876 C20.9349364,17.6265668 19.7705566,17.8586887 18.5506556,17.8586887 C13.4756979,17.8586887 9.36163184,13.8414109 9.36163184,8.88584755 C9.36163184,6.11973182 10.6434484,3.64595703 12.6587038,1.99999985 Z"
    />
  );
}

ShapeDinner.propTypes = propTypes;

export default ShapeDinner;
