import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function CommentsIcon({ className }) {
  let classes = 'c-icon c-icon--comments';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <path
        className="c-icon__fill c-icon__fill--alt"
        d="M18.936,14.5 L5.334,14.5 C4.881,14.5 4.513,14.133 4.513,13.679 C4.513,13.225 4.881,12.856 5.334,12.856 L18.936,12.856 C19.39,12.856 19.757,13.225 19.757,13.679 C19.757,14.133 19.39,14.5 18.936,14.5 M5.233,8.694 L18.834,8.694 C19.289,8.694 19.657,9.063 19.657,9.517 C19.657,9.971 19.289,10.338 18.834,10.338 L5.233,10.338 C4.78,10.338 4.412,9.971 4.412,9.517 C4.412,9.063 4.78,8.694 5.233,8.694 M5.233,4.535 L12.135,4.535 C12.589,4.535 12.957,4.903 12.957,5.357 C12.957,5.812 12.589,6.18 12.135,6.18 L5.233,6.18 C4.78,6.18 4.412,5.812 4.412,5.357 C4.412,4.903 4.78,4.535 5.233,4.535 M21.307,1 L2.825,1 C1.804,1 1,1.815 1,2.837 L1,16.453 C1,17.474 1.804,18.313 2.825,18.313 L12.658,18.313 L19.195,22.036 L17.907,18.313 L21.307,18.313 C22.329,18.313 23.181,17.474 23.181,16.453 L23.181,2.837 C23.181,1.815 22.329,1 21.307,1"
      />
    </svg>
  );
}

CommentsIcon.propTypes = propTypes;

export default CommentsIcon;
