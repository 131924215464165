/* istanbul ignore file */
import Confirm from 'src/components/Confirm';
import { createConfirm } from 'src/shared/ConfirmMount/ConfirmMount';

const defaultOptions = {
  cancelText: 'Cancel',
  confirmText: 'Ok',
  headerText: '',
  showCancel: true
};

/**
 * Creates a confirmation modal that must be confirmed or cancelled
 *
 * @param {Node} modalBody React node for the modal body
 * @param {object} options Optional props passed to modal
 * @param {string} options.cancelText Label for cancel button
 * @param {string} options.confirmText Label for confirm button
 * @param {string} options.headerText Header text
 * @param {string} options.showCancel Show cancel button (for alert boxes)
 * @returns {Promise<boolean>} True if confirmed, false if cancelled
 */
export default function (modalBody, options) {
  return createConfirm(Confirm)({
    modalBody,
    ...defaultOptions,
    ...options
  });
}
