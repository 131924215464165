import { getTimeDiff } from 'src/utils/pageNameSelectors';
import storeService from './store';
import { pushCountlyEvent } from './countlyService';

export async function registrationEvent(mountTime = 0) {
  if (!window.Countly) {
    return;
  }

  const dur = getTimeDiff(mountTime);
  const state = await storeService.getHydratedState();
  const userEmailAddress = state.countly.get('userEmailAddress');

  pushCountlyEvent(['add_event', {
    key: 'web.spendVolumePerRegistration',
    dur,
    segmentation: {
      userEmail: userEmailAddress,
      step: 'Registration'
    }
  }]);
}

export async function registrationOnboardEvent(mountTime = 0) {
  if (!window.Countly) {
    return;
  }

  const dur = getTimeDiff(mountTime);
  const state = await storeService.getHydratedState();
  const userEmailAddress = state.countly.get('userEmailAddress');

  pushCountlyEvent(['add_event', {
    key: 'web.spendVolumePerRegistration',
    dur,
    segmentation: {
      userEmail: userEmailAddress,
      step: 'About Me'
    }
  }]);
}

export function registrationAddPogoEvent(mountTime) {
  if (!window.Countly) {
    return;
  }

  return async function () {
    const dur = getTimeDiff(mountTime);
    const state = await storeService.getHydratedState();
    const userEmailAddress = state.countly.get('userEmailAddress');

    pushCountlyEvent(['add_event', {
      key: 'web.spendVolumePerRegistration',
      dur,
      segmentation: {
        userEmail: userEmailAddress,
        step: 'Pairing a POGO meter'
      }
    }]);
  };
}

export async function countlyTrackEventualRegistrationFinish(page) {
  if (!window.Countly) {
    return;
  }

  const state = await storeService.getHydratedState();
  const userEmailAddress = state.countly.get('userEmailAddress');

  pushCountlyEvent(['add_event', {
    key: 'web.registrationFinishedLater',
    segmentation: {
      userEmail: userEmailAddress,
      page
    }
  }]);
}

export function trackRegistrationDropOff(pageName, userId = 'user unknown') {
  if (!window.Countly) {
    return;
  }

  try {
    // Should be synchronous since it is called on tab close
    window.Countly.add_event({
      key: 'web.dropOffRegistrationStep',
      segmentation: {
        userId,
        pageName
      }
    });
  } catch (err) {
    console.error(err); // eslint-disable-line
  }
}
