import { createSelector } from 'reselect';
import moment from 'moment';
import { getPatientTargets } from './settingsSelectors';
import { getPreviousTimeframe, getTimeframe } from './timeframeSelectors';
import { determineModifier, normalizeBloodGlucoseReading, setMealMarker, setReadingColor, timeframeFilter } from '../utils/readingHelpers';
import { sv } from '../assets/css/1_settings/variables.js';

/* istanbul ignore next */
function getReadingsCache(state) {
  return state.readings.get('cache');
}

/* istanbul ignore next */
function getSelectedReading(state) {
  const selectedId = state.readings.get('selectedId');
  return selectedId ? state.readings.getIn(['cache', selectedId]) : null;
}

export function makeGetPreviousReadings() {
  return createSelector(
    [getReadingsCache, getPreviousTimeframe],
    (readingsCache, timeframe) => {
      return readingsCache
        .filter(timeframeFilter(timeframe))
        .map(normalizeBloodGlucoseReading)
        .toList();
    }
  );
}

export function makeGetReadingsInTimeframe() {
  return createSelector(
    [getReadingsCache, getTimeframe],
    (readingsCache, timeframe) => {
      return readingsCache
        .filter(timeframeFilter(timeframe))
        .map((val) => val.set('mealMarker', setMealMarker(val.get('tags'))))
        .map(normalizeBloodGlucoseReading)
        .toList();
    }
  );
}

export function makeGetReadingsInTimeframeForGraph() {
  return createSelector(
    [getReadingsCache, getTimeframe, getPatientTargets],
    (readingsCache, timeframe, patientTargets) => {
      return readingsCache
        .filter(timeframeFilter(timeframe))
        .map(normalizeBloodGlucoseReading)
        .map((val) => {
          const bg = val.get('bloodGlucose');

          return {
            ...val.toJS(),
            color: setReadingColor(bg, patientTargets, sv.color),
            mealMarker: setMealMarker(val.get('tags')),
            y: bg,
            x: moment.utc(val.get('timestamp'))
          };
        });
    }
  );
}

export function makeGetSelectedReadingForGraph() {
  return createSelector(
    [getSelectedReading, getPatientTargets],
    (selectedReading, patientTargets) => {
      let selected = null;
      if (selectedReading) {
        selected = selectedReading.toJS();
        selected.modifier = determineModifier(selected.bloodGlucose, patientTargets.high, patientTargets.low);
        selected.color = sv.color[sv.theme[selected.modifier]];
      }
      return selected;
    }
  );
}

export function makeGetReadingsForSelectedDate() {
  return createSelector(
    [getReadingsCache, getTimeframe, getSelectedReading],
    (readingsCache, timeframe, selectedReading) => {
      return readingsCache
        .filter(timeframeFilter(timeframe))
        .map((val) => val.set('mealMarker', setMealMarker(val.get('tags'))))
        .map(normalizeBloodGlucoseReading)
        .filter((item) => {
          if (!selectedReading || selectedReading.isEmpty()) {
            return true;
          }
          return moment(item.get('timestamp')).isSame(moment(selectedReading.get('timestamp')), 'day');
        })
        .toList();
    }
  );
}
