/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const propTypes = {
  danger: PropTypes.bool,
  skipped: PropTypes.bool,
  checked: PropTypes.bool
};

function CheckCircle({ checked, skipped, danger }) {
  const classes = classNames(
    'c-check-circle',
    { 'c-check-circle--success': checked },
    { 'c-check-circle--skipped': skipped },
    { 'c-check-circle--danger': danger }
  );

  return (
    <svg viewBox="0 0 20 20" className={classes}>
      <circle className="c-check-circle__circle" cx="10" cy="10" r="10" />
      <polyline className="c-check-circle__check" points="5 11.235375 8.16791219 14 14.1764023 6" />
    </svg>
  );
}

CheckCircle.propTypes = propTypes;

export default CheckCircle;
