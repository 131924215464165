import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';
import ContactsRemoveContact from '../../../../../components/contacts/ContactsRemoveContact/ContactsRemoveContact';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import confirm from '../../../../../utils/ConfirmHelpers';

const propTypes = {
  item: PropTypes.object,
  updateTable: PropTypes.func.isRequired,
  deleteYourSharingCircle: PropTypes.func.isRequired,
  deleteYourPractice: PropTypes.func.isRequired
};

export default function RemoveContactBtn({
  item,
  updateTable,
  deleteYourPractice,
  deleteYourSharingCircle
}) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const handleRemove = async () => {
    const confirmOptions = { confirmText: 'Confirm', cancelText: 'Cancel', headerText: 'Remove Contact' };

    if (item.type.length > 1) {
      setIsOpen(true);
      return;
    }

    if (await confirm('Please confirm you want to remove this contact?', confirmOptions)) {
      switch (item.type[0]) {
      case 'Practice':
        try {
          await deleteYourPractice(item.relationId.practiceId);
        } catch (err) {
          console.error(err); // eslint-disable-line
        }
        break;
      case 'Sharing Circle':
        try {
          await deleteYourSharingCircle(item.relationId.carer);
        } catch (err) {
          console.error(err); // eslint-disable-line
        }
        break;
      case 'Emergency Contacts':
        try {
          await deleteYourSharingCircle(item.relationId.emergency_contact);
        } catch (err) {
          console.error(err); // eslint-disable-line
        }
        break;
      default:
        break;
      }

      updateTable();
    }
  };

  const memoizedCloseModal = useCallback(closeModal, []);

  return (
    <Fragment>
      <button
        className="c-button c-button--danger-hollow c-button--condensed h-ws-pre"
        onClick={handleRemove}
      >
        Remove
      </button>

      <Dialog
        open={isOpen}
        onClose={memoizedCloseModal}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        data-testid="dialog-invites"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperComponent={Paper}
        PaperProps={{ elevation: 0 }}
      >
        <ContactsRemoveContact
          item={item}
          closeModal={memoizedCloseModal}
          updateTable={updateTable}
          deleteYourSharingCircle={deleteYourSharingCircle}
        />
      </Dialog>
    </Fragment>
  );
}

RemoveContactBtn.propTypes = propTypes;
