import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@material-ui/core';
import AvatarPresenter from '../AvatarPresenter';
import { useStyles } from './PatientData.styles';
import moment from 'moment';

const propTypes = {
  selectedPatient: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
    emailAddress: PropTypes.string
  })
};

export default function PatientData({ selectedPatient }) {
  const classes = useStyles();
  const name = selectedPatient.name ? selectedPatient.name : `${selectedPatient.firstName} ${selectedPatient.lastName}`;
  const dob = selectedPatient.dateOfBirth ? moment(selectedPatient.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';

  return (
    <Grid container justifyContent="space-between" alignItems="center" className="h-margin-top">
      <Grid item xs={12} sm={8}>
        <Grid container justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
          <div className="h-padded-right">
            <AvatarPresenter avatarUrl={selectedPatient.avatarUrl} classNames="c-avatar__medium--painted"/>
          </div>
          <div>
            <h4 className={classes.name}><strong>{name}</strong></h4>
            <h4 className={classes.email}>{selectedPatient.emailAddress}</h4>
            <h4 className={classes.email}>DOB: {dob}</h4>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

PatientData.propTypes = propTypes;
