import PropTypes from 'prop-types';
import React from 'react';

import hospitalIcon from '../assets/img/icons/hospital-small.svg';
import policeIcon from '../assets/img/icons/police-small.svg';

const icons = {
  hospitalIcon,
  policeIcon
};

const propTypes = {
  list: PropTypes.array,
  type: PropTypes.string.isRequired
};

function GooglePlaceList(props) {
  const { type } = props;
  if (!props.list || !props.list.length) {
    return null;
  }

  return (
    <React.Fragment>
      {props.list.map((place, key) => {
        if (!place) {
          return null;
        }

        return (
          <div key={key} className="c-card-map__list-item h-margin-bottom-lg">
            <div>
              <img src={icons[`${type}Icon`]} className="c-card-map__list-icon"/>
            </div>
            <div>
              <h3 className="c-card-map__list-title">{place.name}</h3>
              <div className="c-card-map__list-text">{place.vicinity}</div>
              <div className="c-card-map__list-phone">
                {place.international_phone_number && (
                  <a href={`tel:${place.international_phone_number}`}>
                    {place.international_phone_number}
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
}

GooglePlaceList.propTypes = propTypes;

export default GooglePlaceList;
