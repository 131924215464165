import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  blockContainer: {
    boxShadow: '0px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 11px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '0',
    marginTop: '5rem'
  },
  imgContainer: {
    height: '72px'
  },
  itemBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  vPadded: {
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  padded: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  vPaddedSmall: {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  paddedSmall: {
    paddingLeft: '8px',
    paddingRight: '8px'
  },
  buttonBlock: {
    padding: '24px 0',
    display: 'flex'
  },
  tagContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    paddingBottom: '26Px'
  }
}));
