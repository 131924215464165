import PropTypes from 'prop-types';
import React, { useReducer, createContext, useCallback, useEffect, useContext } from 'react';
import { contactsInviteViews, contactsViewsConstants } from 'src/constants/contactsInviteViews';

const propTypes = {
  clearInvitesForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

function reducer(state, action) {
  switch (action.type) {
  case 'inviteTypes': return { viewType: contactsViewsConstants.inviteTypes };
  case 'hcpSearchView': return { viewType: contactsViewsConstants.hcpSearchView };
  case 'hcpInfoView': return { viewType: contactsViewsConstants.hcpInfoView, id: action.id };
  case 'practiceHcpInfoView': return {
    viewType: contactsViewsConstants.practiceHcpInfoView,
    id: action.id,
    practiceId: action.practiceId
  };
  case 'practiceInfoView': return { viewType: contactsViewsConstants.practiceInfoView, id: action.id };
  case 'scFormView': return { viewType: contactsViewsConstants.scFormView };
  case 'ecWelcomeView': return { viewType: contactsViewsConstants.ecWelcomeView };
  case 'ecFormView': return { viewType: contactsViewsConstants.ecFormView };
  case 'successInviteView': return { viewType: contactsViewsConstants.successInviteView };
  case 'successECInviteView': return { viewType: contactsViewsConstants.successECInviteView };
  case 'successSCInviteView': return { viewType: contactsViewsConstants.successSCInviteView };
  default: return state;
  }
}

export const ContactsInviteManagerContext = createContext(null);
export const useInboxActionsContext = () => useContext(ContactsInviteManagerContext);

function ContactsInviteManager({ closeModal, clearInvitesForm }) {
  const [state, dispatch] = useReducer(reducer, { viewType: contactsViewsConstants.inviteTypes });

  const switchToView = useCallback((type, data) => {
    const { id, practiceId } = data || {};
    dispatch({ type, id, practiceId });
  }, []);

  // To present hcp details and practice details we pass "id" in to "switchToView" func
  const returnContent = ({ viewType, id, practiceId }) => {
    const component = contactsInviteViews[viewType];
    return component({ id, practiceId });
  };

  // Clear all form data when searching Hcp or Practice to invite on unmount
  useEffect(() => () => clearInvitesForm(), []);

  return (
    <ContactsInviteManagerContext.Provider value={{ closeModal, switchToView }}>
      {returnContent(state)}
    </ContactsInviteManagerContext.Provider>
  );
}

export default ContactsInviteManager;
ContactsInviteManager.propTypes = propTypes;
