import PropTypes from 'prop-types';
import React from 'react';
import BarChartSVG from '../components/svg/BarChartSVG';
import { connect } from 'react-redux';
import { setDevicePerDataType } from '../actions/validicActions';
import HealthDeviceWidget from '../components/HealthDeviceWidget';
import {
  getWaterDeviceList,
  makeGetWaterDevice
} from '../selectors/healthDataSelectors';
import WaterIcon from '../components/icons/WaterIcon';
import { makeGetWaterInTimeframe } from '../selectors/readingTagsSelectors';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  waterTotals: PropTypes.object,
  waterDevice: PropTypes.object,
  waterDevices: PropTypes.array,
  isSharingCircleView: PropTypes.bool,
  setDevicePerDataType: PropTypes.func.isRequired
};

function WidgetHydrationContainer({
  waterTotals,
  waterDevice,
  waterDevices,
  isSharingCircleView,
  setDevicePerDataType: switchDevice
}) {
  return (
    <HealthDeviceWidget
      Chart={BarChartSVG}
      data={waterTotals}
      device={waterDevice}
      sources={waterDevices}
      dataCollectionType="water"
      dataType="value"
      Icon={WaterIcon}
      isSharingCircleView={isSharingCircleView}
      switchDevice={switchDevice}
      title="AVG Hydration"
    />
  );
}

export function makeMapStateToProps() {
  const getWaterDevice = makeGetWaterDevice();
  const getWaterInTimeframe = makeGetWaterInTimeframe();

  return function (state, props) {
    const waterDevice = getWaterDevice(state, props);
    const waterTotals = getWaterInTimeframe(state, props);
    const waterDevices = getWaterDeviceList(state);

    return {
      timeframe: state.timeframe,
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      waterDevices,
      waterTotals,
      waterDevice
    };
  };
}

export default connect(makeMapStateToProps, {
  setDevicePerDataType
})(immutableToJsHOC(WidgetHydrationContainer));

WidgetHydrationContainer.propTypes = propTypes;
