/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  islarge: PropTypes.string,
  transform: PropTypes.string
};

const small = 'M19.48,10.35c-.09-.28-.54-.45-.83-.5l-4.1-.59L12.72,5.55c-.47-1-.91-1-1.37,0L9.51,9.26l-4.1.59c-.29.05-.74.19-.87.43s.24.66.45.89l3,2.89-.7,4.08c0,.29-.14.7.1.87a1.16,1.16,0,0,0,1-.07L12,17l3.66,2c.11.06.66.39,1,.13s.17-.65.12-.93l-.71-4.08,3-2.89c.21-.2.5-.54.41-.82';

const large = 'M22.14,9.76C22,9.38,21.4,9.15,21,9.09l-5.55-.8L13,3.25c-.63-1.3-1.23-1.3-1.85,0l-2.51,5-5.55.8c-.39.06-1,.26-1.17.58s.31.89.6,1.21l4,3.91-1,5.53c-.07.4-.18,1,.14,1.19a1.67,1.67,0,0,0,1.38-.09l5-2.66,5,2.66c.15.08.88.52,1.34.16s.24-.88.17-1.26l-1-5.53,4-3.91c.28-.28.68-.74.55-1.12';

function ShapeFasting(props) {
  let shape = small;
  let pathProps = props;

  if (props.islarge === 'true') {
    shape = props.islarge === 'true' ? large : small;
    pathProps = { ...props };
    delete pathProps.islarge;
  }

  return (
    <g strokeWidth="1" fill="none" stroke={props.color}>
      <path
        {...pathProps}
        d={shape}
      />
    </g>
  );
}

ShapeFasting.propTypes = propTypes;

export default ShapeFasting;
