import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import formValidationService from '../services/formValidation';
import FileInput from './FileInput';
import MuiTextField from './muiComponents/MuiTextField';
import TermsAndCondition from './TermsAndCondition';
import { normalizePhone, normalizeMaxPasswordLength } from '../services/fieldNormalizationService';

export class RegisterSharingCircle extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      authentication: PropTypes.shape({
        registerWithToken: PropTypes.func.isRequired,
        signIn: PropTypes.func.isRequired
      }).isRequired,
      avatar: PropTypes.shape({
        putAvatar: PropTypes.func.isRequired
      }).isRequired,
      status: PropTypes.shape({
        getStatus: PropTypes.func.isRequired
      }).isRequired
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    invitationToken: PropTypes.string.isRequired
  }

  state = {
    image: ''
  }

  formInstance = null;

  register = async (values) => {
    const { authentication, avatar, status } = this.props.actions;
    const registerValues = { ...values, token: this.props.invitationToken };

    try {
      await authentication.registerWithToken(registerValues, 'carer');

      try {
        await authentication.signIn(values);
      } catch (err) {
        return this.props.history.push('/welcome/sign-in');
      }
    } catch (err) {
      this.formInstance.reset();

      return {
        emailAddress: err.message
      };
    }

    try {
      await status.getStatus();
    } catch (err) {
      console.warn(err); // eslint-disable-line no-console
    }

    if (this.state.image) {
      avatar.putAvatar(this.state.image);
    }

    return this.props.history.push('/sharing-circle');
  };

  changePhoto = () => {
    const [file] = document.querySelector('[name=avatar]').files;
    this.setState({ image: file });
  }

  /* istanbul ignore next */
  validateFields(values) {
    return new formValidationService.Validation()
      .firstName(values.firstName)
      .lastName(values.lastName)
      .email(values.emailAddress)
      .emailMatches(values.emailAddress, values.emailConfirmation)
      .password(values.password)
      .passwordComplexity(values.password)
      .passwordMatches(values.password, values.passwordConfirmation)
      .passwordMaxLength(values.password)
      .cellNumberOptional(values.cellNumber)
      .termsAndConditionsAccepted(values.hasAcceptedTermsAndConditions)
      .getErrors();
  }

  render() {
    const imagePreview = this.state.image && URL.createObjectURL(this.state.image);

    return (
      <Form
        onSubmit={this.register}
        validate={this.validateFields}
      >
        {({ handleSubmit, valid, submitting, hasSubmitErrors, pristine, form }) => {
          this.formInstance = form;

          return (
            <form
              name="registerSharingCircle"
              className="c-form"
              onSubmit={handleSubmit}
            >
              <Field
                component={FileInput}
                label="+ Upload Photo"
                image={imagePreview}
                onChange={this.changePhoto}
                name="avatar"
              />
              <fieldset className="c-fieldset">
                <div className="c-fieldset__row">
                  <div className="c-fieldset__field">
                    <Field
                      autoComplete="new-password"
                      component={MuiTextField}
                      label="First Name"
                      name="firstName"
                    />
                  </div>
                  <div className="c-fieldset__field">
                    <Field
                      autoComplete="new-password"
                      component={MuiTextField}
                      label="Last Name"
                      name="lastName"
                    />
                  </div>
                </div>
              </fieldset>
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Email Address (will be your username)"
                name="emailAddress"
              />
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Confirm Email Address"
                name="emailConfirmation"
              />
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Password"
                name="password"
                parse={normalizeMaxPasswordLength}
                props={{ errorHiddenUnderText: 'Your secure password must be at least 8 characters in length and contain at least one uppercase letter, one lowercase letter and one number.' }}
                type="password"
              />
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Confirm Password"
                name="passwordConfirmation"
                type="password"
              />
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Cell Phone Number (optional)"
                name="cellNumber"
                parse={normalizePhone}
              />
              <TermsAndCondition />
              <br />
              <input
                className="c-button c-button--block c-button--hollow"
                disabled={(!valid && !hasSubmitErrors) || pristine || submitting}
                type="submit"
                value="Next"
              />
            </form>
          );
        }}
      </Form>
    );
  }
}

export default RegisterSharingCircle;
