export default {
  PHONE_VERIFICATION: 'ipv',
  CONFIRM_EMAIL: 'cea',
  ADD_METER: 'amr',
  EMERGENCY_CONTACT: 'emc',
  RENEW_RELATIONSHIP: 'rwr',
  RESET_PASSWORD: 'rpd',
  SHARING_CIRCLE_CONTACT: 'shc',
  VIEW_REPORT: 'vrp'
};
