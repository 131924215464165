/* istanbul ignore file */
import { connect } from 'react-redux';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import { doNotShowChecklist } from '../actions/profileChecklistActions';
import ProfileChecklist from 'src/components/ProfileChecklist';

const mapStateToProps = (state) => ({
  status: state.status
});

export default connect(mapStateToProps, {
  doNotShowChecklist
})(immutableToJsHOC(ProfileChecklist));
