import PropTypes from 'prop-types';
import React from 'react';
import FavoriteFoodIconContainer from '../../containers/FavoriteFoodIconContainer';
import { generateLinkWithQueries } from '../../utils/logBookFoods';

const propTypes = {
  list: PropTypes.array,
  ifEmptyText: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  hideDefaultFavoriteStatus: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired
    }).isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired,
  readingId: PropTypes.string.isRequired,
  notEditable: PropTypes.bool,
  setNutritionixSelectedFood: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  showFavoriteStatus: PropTypes.bool
};

// Will generate link to redirect for form
function redirectTo(food, { history, type, setNutritionixSelectedFood }) {
  /*
  * Recent meal should be marked as selectedfood directly here since there is no API for fetching
  * single item of recent meal
  */
  if (type === 'postRecentFood') {
    setNutritionixSelectedFood(food);
  }
  const link = generateLinkWithQueries(type, history, food);

  history.push(link);
}

export default function FoodsListPresenter(props) {
  const {
    list,
    isVisible,
    ifEmptyText,
    showFavoriteStatus,
    readingId
  } = props;

  if (!isVisible) {
    return null;
  }

  if (!list || !list.length) {
    return (
      <React.Fragment>
        {ifEmptyText ? <div data-testid="empty-text" className="h-padded h-center">{ifEmptyText}</div> : ''}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {list.map((food, i) => (
        <div key={i} data-testid="list" className="c-list-item c-list-item--food">
          <FavoriteFoodIconContainer
            isFavorite={!!food.favorite_food_id}
            isVisible={!(props.hideDefaultFavoriteStatus && !food.favorite_food_id) && showFavoriteStatus}
            favoriteFoodId={food.favorite_food_id}
            foodName={food.name}
            nixItemId={food.nix_item_id}
            notEditable={props.notEditable}
            readingId={readingId}
          />
          <img
            className="c-list-item__photo h-cursor__pointer"
            src={food.image} onClick={() => { redirectTo(food, props); }}
          />
          <span
            className="c-list-item__name h-cursor__pointer"
            onClick={() => { redirectTo(food, props); }}
          >
            {food.name}
          </span>
          <span className="c-list-item__icon h-cursor__pointer" onClick={() => { redirectTo(food, props); }}>
            <svg className="c-icon" viewBox="0 0 24 24">
              <polyline strokeWidth="2" points="10 8 14 12.5 10 17"/>
            </svg>
          </span>
        </div>
      ))}
    </React.Fragment>
  );
}

FoodsListPresenter.propTypes = propTypes;
