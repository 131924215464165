/* istanbul ignore file */
import React from 'react';
// Icons
import graphIcon from '../../assets/img/icons/graph-icon.svg';

function LogEmpty() {
  return (
    <div className="c-card-list">
      <div className="c-card">
        <div className="c-card__body h-center">
          <img src={graphIcon} style={{ width: '50px' }}/>
          <h2>No Results</h2>
          <p>There are no readings for the date range selected.  Please choose another date range or take a new reading with your POGO Automatic<sup>&reg;</sup>.</p>
        </div>
      </div>
    </div>
  );
}

export default LogEmpty;
