import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register, registerWithToken, signIn } from '../actions/authenticationActions';
import { postPatientMeterWithToken } from '../actions/settingsActions';
import { putAvatar } from '../actions/avatarActions';
import { putAboutMe } from '../actions/profileActions';
import { getStatus } from '../actions/statusActions';
import Register from '../components/Register';
import RegisterSharingCircle from '../components/RegisterSharingCircle';
import WelcomeNav from '../components/WelcomeNav';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import TOKEN_TYPES from '../constants/tokenTypes';

const propTypes = {
  token: PropTypes.shape({
    shc: PropTypes.string,
    she: PropTypes.string
  })
};

export function RegisterContainer(props) {
  let content = <Register {...props}/>;
  if (props.token.shc || props.token.she) {
    content = <RegisterSharingCircle {...props} invitationToken={props.token.shc || props.token.she} />;
  }

  return (
    <div className="c-card">
      <div className="c-card__body">
        <WelcomeNav />
        <div className="h-padded h-center">Welcome to <strong>Patterns<sup>&reg;</sup></strong>! Let’s get started…</div>
        {content}
      </div>
    </div>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    headers: state.authentication.get('headers'),
    pogoToken: state.token.get(TOKEN_TYPES.ADD_METER),
    token: state.token
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      authentication: bindActionCreators({ register, registerWithToken, signIn }, dispatch),
      avatar: bindActionCreators({ putAvatar }, dispatch),
      profile: bindActionCreators({ putAboutMe }, dispatch),
      settings: bindActionCreators({ postPatientMeterWithToken }, dispatch),
      status: bindActionCreators({ getStatus }, dispatch)
    }
  };
}

RegisterContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(immutableToJsHOC(RegisterContainer));
