/* istanbul ignore file */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LogoSplash from './LogoSplash';
import ForgotPasswordContainer from '../containers/ForgotPasswordContainer';
import RegisterContainer from '../containers/RegisterContainer';
import ResetPasswordContainer from '../containers/ResetPasswordContainer';
import SignInContainer from '../containers/SignInContainer';
// Countly Component
import CountlyTrackedRoute from './countly/CountlyTrackedRoute';
// Constants
import { PAGE_NAMES } from '../constants/routesCountlyNameConstants';

function Welcome() {
  return (
    <div className="l-container l-container--narrow">
      <div className="l-welcome">
        <div className="l-welcome__splash">
          <LogoSplash />
        </div>
        <div className="l-welcome__body">
          <Switch>
            <CountlyTrackedRoute pageGroup="Login" pageName={PAGE_NAMES.LOGIN} path="/welcome/sign-in" component={SignInContainer} />
            <CountlyTrackedRoute pageGroup="Login" pageName={PAGE_NAMES.REGISTRATION} path="/welcome/register" component={RegisterContainer} />
            <Route path="/welcome/forgot-password" component={ForgotPasswordContainer} />
            <Route path="/welcome/reset-password/:token" component={ResetPasswordContainer} />
            <Redirect to="/welcome/sign-in" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
