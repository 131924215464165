import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import ExportReportContainer from '../containers/ExportReportContainer';
import Logo from './Logo';
import MenuIcon from './MenuIcon';
import NavTabs from './NavTabs';
import AvatarPresenter from './AvatarPresenter';

const propTypes = {
  avatar: PropTypes.string
};

export default function Header(props) {
  const [isActive, setIsActive] = React.useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const overlayClasses = classNames('c-overlay jest-toggle', {
    'c-overlay--visible': isActive
  });

  return (
    <Fragment>
      <div className={overlayClasses} data-testid="toggle" onClick={handleToggle} />
      <div className="l-header">
        <div className="l-header__left">
          <MenuIcon isActive={isActive} onClick={handleToggle} />
        </div>
        <div className="l-header__logo">
          <Logo onClick={handleToggle} />
        </div>
        <NavTabs isActive={isActive} onClick={handleToggle} />
        <div className="l-header__right">
          <ExportReportContainer />
          <NavLink
            to="/profile"
            className="c-nav-tab"
            activeClassName="c-nav-tab--active"
          >
            <AvatarPresenter avatarUrl={props.avatar} />
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
}

Header.propTypes = propTypes;
