import PropTypes from 'prop-types';
import React from 'react';
import BarChartSVG from '../components/svg/BarChartSVG';
import { connect } from 'react-redux';
import { setDevicePerDataType } from '../actions/validicActions';
import HealthDeviceWidget from '../components/HealthDeviceWidget';
import {
  getWeightsCacheDeviceList,
  makeGetWeightsDevice,
  makeGroupedWeightsInTimeframe
} from '../selectors/healthDataSelectors';
import WeightsIcon from '../components/icons/WeightsIcon';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  weightTotals: PropTypes.object,
  weightsDevice: PropTypes.object,
  weightsDevices: PropTypes.array,
  isSharingCircleView: PropTypes.bool,
  setDevicePerDataType: PropTypes.func.isRequired
};

function WidgetWeigthsContainer({
  weightTotals,
  weightsDevice,
  weightsDevices,
  isSharingCircleView,
  setDevicePerDataType: switchDevice
}) {
  return (
    <HealthDeviceWidget
      Chart={BarChartSVG}
      device={weightsDevice}
      data={weightTotals}
      sources={weightsDevices}
      dataCollectionType="weights"
      dataType="weight"
      Icon={WeightsIcon}
      isSharingCircleView={isSharingCircleView}
      switchDevice={switchDevice}
      title="AVG Weight"
    />
  );
}

export function makeMapStateToProps() {
  const getWeightsDevice = makeGetWeightsDevice();
  const getGroupedWeights = makeGroupedWeightsInTimeframe();

  return function (state, props) {
    const weightsDevice = getWeightsDevice(state, props);
    const weightTotals = getGroupedWeights(state, props);
    const weightsDevices = getWeightsCacheDeviceList(state);
    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      weightsDevices,
      weightTotals,
      weightsDevice
    };
  };
}

export default connect(makeMapStateToProps, { setDevicePerDataType })(immutableToJsHOC(WidgetWeigthsContainer));
WidgetWeigthsContainer.propTypes = propTypes;
