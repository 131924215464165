import Immutable from 'immutable';
import moment from 'moment';
import { formatAxis } from '../utils/timeframeHelpers';
import { defaultOptions } from '../services/timeframeService';

const INITIAL_STATE = { ...defaultOptions[1] };

class TimeframeRecord extends Immutable.Record(INITIAL_STATE, 'TimeframeRecord') {
  contains(ISOString) {
    return (
      moment(this.get('start')).isSameOrBefore(ISOString) &&
      moment(this.get('end')).isSameOrAfter(ISOString)
    );
  }

  diffDays() {
    return moment(this.get('end')).diff(moment(this.get('start')), 'days');
  }

  makeWidgetsAxis(scale) {
    const start = moment(this.start);
    let multiple = 1;

    if (this.span === 0) {
      return {
        spacing: scale(start.clone().add(1, 'days')) / 2,
        ticks: formatAxis({
          scale,
          numTicks: 1,
          format: 'ddd',
          start: this.start,
          end: this.end
        })
      };
    }

    if (this.span >= 7 && this.span <= 29) {
      multiple = 2;
    }

    if (this.span > 29 && this.span <= 45) {
      multiple = 4;
    }

    if (this.span > 45) {
      multiple = 6;
    }

    const numTicks = Math.floor((this.span + 1) / multiple);

    return {
      spacing: scale(start.clone().add(multiple, 'days')) / 2,
      ticks: formatAxis({
        scale,
        numTicks,
        format: this.span > 6 ? 'DD' : 'dd',
        start: this.start,
        end: this.end
      })
    };
  }

  makeAxis(scale, width = 320) {
    const start = moment(this.start);
    let multiple = 1;
    let numTicks;

    if (this.span === 0) {
      if (width <= 700) {
        multiple = 3;
      } else if (width > 700 && width <= 979) {
        multiple = 2;
      }
      numTicks = 24 / multiple;

      return {
        spacing: scale(start.clone().add(multiple, 'hours')) / 2,
        ticks: formatAxis({
          scale,
          numTicks,
          format: 'h',
          units: 'hours',
          start: this.start,
          end: this.end
        })
      };
    }

    if (this.span >= 7 && width <= 700) {
      multiple = 2;
    }

    // All span over 29 are used in Provider app
    if (this.span > 29 && this.span <= 45) {
      multiple = 2;
    }

    if (this.span > 45) {
      multiple = 3;
    }

    numTicks = Math.floor((this.span + 1) / multiple);

    return {
      spacing: scale(start.clone().add(multiple, 'days')) / 2,
      ticks: formatAxis({
        scale,
        numTicks,
        format: this.span > 6 ? 'DD' : 'dd',
        start: this.start,
        end: this.end
      })
    };
  }
}

export default TimeframeRecord;
