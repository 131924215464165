import React from 'react';
import PropTypes from 'prop-types';
import { BG_DISPLAY_MAX, BG_DISPLAY_MIN } from '../../utils/readingHelpers';

const propTypes = {
  center: PropTypes.number,
  sv: PropTypes.object,
  value: PropTypes.number
};

function GlucoseReading({ value, center, sv }) {
  let text = value;

  if (value >= BG_DISPLAY_MAX) {
    text = 'HI';
  }

  if (value <= BG_DISPLAY_MIN) {
    text = 'LO';
  }

  const textTransform = typeof text === 'number' ? -(sv.spacing.md + sv.spacing.sm) : -sv.spacing.md;

  return (
    <React.Fragment>
      <text
        style={{
          fill: '#fff',
          fontFamily: sv.helveticaBold,
          fontSize: sv.fontSize.xxl + sv.fontSize.xs,
          fontWeight: 700,
          letterSpacing: 1.2
        }}
        textAnchor="middle"
        transform={`translate(${center}, ${textTransform})`}
      >
        {text}
      </text>
      {typeof text === 'number' &&
      <text
        style={{
          fill: '#fff',
          fontFamily: sv.helveticaRegular,
          fontSize: sv.fontSize.lg,
          fontWeight: 400
        }}
        textAnchor="middle"
        transform={`translate(${center}, ${-(sv.spacing.xs)})`}
      >
        mg/dL
      </text>}
    </React.Fragment>
  );
}

GlucoseReading.propTypes = propTypes;

export default GlucoseReading;
