import React from 'react';

export default () => {
  return (
    <svg className="c-nav-tab__icon c-nav-tab__icon-dashboard c-icon c-nav-tab__size-fix" viewBox="0 0 20 20">
      <g id="Icons" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path d="M6.90689656,20 L6.88919043,14.4639301 C7.86588679,15.2508767 9.10502764,15.7232704 10.4528302,15.7232704 C13.5948096,15.7232704 16.1509434,13.1614939 16.1509434,10.0125786 C16.1509434,6.86372971 13.5948096,4.30188679 10.4528302,4.30188679 C7.31085081,4.30188679 4.75471698,6.86372971 4.75471698,10.0125786 C4.75471698,10.0133091 4.75478324,10.0140395 4.75478324,10.0147699 C4.75478324,10.0152348 4.75471698,10.0155668 4.75471698,10.0159652 L4.7866464,20 L4.52830189,20 C2.02738981,20 3.06273397e-16,17.9726102 0,15.4716981 L0,4.52830189 C-3.06273397e-16,2.02738981 2.02738981,4.59410095e-16 4.52830189,0 L15.4716981,0 C17.9726102,-4.59410095e-16 20,2.02738981 20,4.52830189 L20,15.4716981 C20,17.9726102 17.9726102,20 15.4716981,20 L6.90689656,20 Z M10.5283019,13.6603774 C8.55175751,13.6603774 6.94366177,12.0524144 6.94339623,10.0759364 L6.94339623,10.0750734 C6.94359539,8.098529 8.55175751,6.49056604 10.5283019,6.49056604 C12.504979,6.49056604 14.1132075,8.09872816 14.1132075,10.0754717 C14.1132075,12.0521488 12.504979,13.6603774 10.5283019,13.6603774 Z"/>
      </g>
    </svg>
  );
};
