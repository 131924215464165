import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  inbound: new Immutable.List(),
  outbound: new Immutable.List(),
  selfRemoveFromEmergencyContactResponse: new Immutable.Map({
    handling_type: null
  })
});

export default function invitationReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.ACCEPT_INVITATION_START:
    return state.set('isActive', true);

  case actionTypes.ACCEPT_INVITATION_FAILURE:
    return state.set('isActive', false);

  case actionTypes.SELF_REMOVE_FROM_EMERGENCY_CONTACT_SUCCESS:
    return state.set('selfRemoveFromEmergencyContactResponse', Immutable.fromJS(action.payload));

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
