import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import ContactsEditInfoForm from 'src/components/contacts/ContactsEditInfoForm/ContactsEditInfoForm';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { normalizePhone } from 'src/services/fieldNormalizationService';

const propTypes = {
  getAboutContactInfo: PropTypes.func.isRequired,
  memoizedFetch: PropTypes.func.isRequired,
  editIAmSharingWithContactsInfo: PropTypes.func.isRequired,
  item: PropTypes.object
};

export default function EditInfoBtn({
  item,
  getAboutContactInfo,
  editIAmSharingWithContactsInfo,
  memoizedFetch
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const fetchAboutInfo = async () => {
    const data = await getAboutContactInfo(item.memberProfileId);

    setInitialValues({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: normalizePhone(item.phoneNumber) || normalizePhone(data.phoneNumber),
      emailAddress: data.emailAddress
    });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    fetchAboutInfo();
    setIsOpen(true);
  }

  return (
    <Fragment>
      <button
        data-testid="edit-btn"
        className="c-button c-button--action c-button--condensed h-ws-pre jest-action"
        onClick={openModal}
      >
        Edit Info
      </button>

      <Dialog
        open={isOpen}
        onClose={closeModal}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        data-testid="dialog-invites"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperComponent={Paper}
        PaperProps={{ elevation: 0 }}
      >
        <ContactsEditInfoForm
          item={item}
          memoizedFetch={memoizedFetch}
          editIAmSharingWithContactsInfo={editIAmSharingWithContactsInfo}
          closeModal={closeModal}
          initialValues={initialValues}
        />
      </Dialog>
    </Fragment>
  );
}

EditInfoBtn.propTypes = propTypes;
