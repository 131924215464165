import { reduxSignOut } from './authenticationActions';
import accountManagementService from '../services/accountManagementService';

export function terminateAccount() {
  return async function (dispatch) {
    try {
      await accountManagementService.terminateAccount();
      dispatch(reduxSignOut());
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

export function getTerminationDetails() {
  return async function () {
    return await accountManagementService.getTerminationDetails();
  };
}
