import actionTypes from '../constants/actionTypes';
import settingsService from '../services/settingsService';

// DELETE_PATIENT_METER

function deletePatientMeterFailure(err) {
  return {
    type: actionTypes.DELETE_PATIENT_METER_FAILURE,
    error: true,
    payload: err
  };
}

function deletePatientMeterStart() {
  return {
    type: actionTypes.DELETE_PATIENT_METER_START
  };
}

function deletePatientMeterSuccess() {
  return {
    type: actionTypes.DELETE_PATIENT_METER_SUCCESS
  };
}

// GET_NOTIFICATION_SETTINGS

function getNotificationSettingsFailure(err) {
  return {
    type: actionTypes.GET_NOTIFICATION_SETTINGS_FAILURE,
    error: true,
    payload: err
  };
}

function getNotificationSettingsStart() {
  return {
    type: actionTypes.GET_NOTIFICATION_SETTINGS_START
  };
}

function getNotificationSettingsSuccess({ notificationSettings }) {
  return {
    type: actionTypes.GET_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { notificationSettings }
  };
}

// GET_PATIENT_METERS

function getPatientMetersFailure(err) {
  return {
    type: actionTypes.GET_PATIENT_METERS_FAILURE,
    error: true,
    payload: err
  };
}

function getPatientMetersStart() {
  return {
    type: actionTypes.GET_PATIENT_METERS_START
  };
}

function getPatientMetersSuccess(meters) {
  return {
    type: actionTypes.GET_PATIENT_METERS_SUCCESS,
    payload: { meters }
  };
}

// GET_PATIENT_TARGETS

function getPatientTargetsFailure(err) {
  return {
    type: actionTypes.GET_PATIENT_TARGETS_FAILURE,
    error: true,
    payload: err
  };
}

function getPatientTargetsStart() {
  return {
    type: actionTypes.GET_PATIENT_TARGETS_START
  };
}

function getPatientTargetsSuccess(patientTargets) {
  return {
    type: actionTypes.GET_PATIENT_TARGETS_SUCCESS,
    payload: { patientTargets }
  };
}

// POST_PATIENT_METER

function postPatientMeterFailure(err) {
  return {
    type: actionTypes.POST_PATIENT_METER_FAILURE,
    error: true,
    payload: err
  };
}

function postPatientMeterStart() {
  return {
    type: actionTypes.POST_PATIENT_METER_START
  };
}

function postPatientMeterSuccess() {
  return {
    type: actionTypes.POST_PATIENT_METER_SUCCESS
  };
}

// POST_PATIENT_METER_WITH_TOKEN

function postPatientMeterWithTokenFailure(err) {
  return {
    type: actionTypes.POST_PATIENT_METER_WITH_TOKEN_FAILURE,
    error: true,
    payload: err
  };
}

function postPatientMeterWithTokenStart() {
  return {
    type: actionTypes.POST_PATIENT_METER_WITH_TOKEN_START
  };
}

function postPatientMeterWithTokenSuccess(tokenType, token) {
  return {
    type: actionTypes.POST_PATIENT_METER_WITH_TOKEN_SUCCESS,
    payload: {
      tokenType,
      token
    }
  };
}

// PUT_NOTIFICATION_SETTINGS

function putNotificationSettingsFailure(err) {
  return {
    type: actionTypes.PUT_NOTIFICATION_SETTINGS_FAILURE,
    error: true,
    payload: err
  };
}

function putNotificationSettingsStart() {
  return {
    type: actionTypes.PUT_NOTIFICATION_SETTINGS_START
  };
}

function putNotificationSettingsSuccess() {
  return {
    type: actionTypes.PUT_NOTIFICATION_SETTINGS_SUCCESS
  };
}

// PUT_PATIENT_TARGETS

function putPatientTargetsFailure(err) {
  return {
    type: actionTypes.PUT_PATIENT_TARGETS_FAILURE,
    error: true,
    payload: err
  };
}

function putPatientTargetsStart() {
  return {
    type: actionTypes.PUT_PATIENT_TARGETS_START
  };
}

function putPatientTargetsSuccess() {
  return {
    type: actionTypes.PUT_PATIENT_TARGETS_SUCCESS
  };
}

export function deletePatientMeter(meterId) {
  return async function (dispatch) {
    dispatch(deletePatientMeterStart());

    try {
      await settingsService.deletePatientMeter(meterId);
      dispatch(deletePatientMeterSuccess());
    } catch (err) {
      dispatch(deletePatientMeterFailure(err));
      throw err;
    }
  };
}

export function getNotificationSettings(type = 'patient') {
  return async function (dispatch) {
    dispatch(getNotificationSettingsStart());

    try {
      const notificationSettings = await settingsService.getNotificationSettings(type);
      dispatch(getNotificationSettingsSuccess(notificationSettings));
    } catch (err) {
      dispatch(getNotificationSettingsFailure(err));
    }
  };
}

export function getPatientMeters() {
  return async function (dispatch) {
    dispatch(getPatientMetersStart());

    try {
      const meters = await settingsService.getPatientMeters();
      dispatch(getPatientMetersSuccess(meters));
    } catch (err) {
      dispatch(getPatientMetersFailure(err));
    }
  };
}

export function getPatientTargets() {
  return async function (dispatch) {
    dispatch(getPatientTargetsStart());

    try {
      const targets = await settingsService.getPatientTargets();
      dispatch(getPatientTargetsSuccess(targets));
    } catch (err) {
      dispatch(getPatientTargetsFailure(err));
    }
  };
}


export function postPatientMeter(serialNumber) {
  return async function (dispatch) {
    dispatch(postPatientMeterStart());

    try {
      await settingsService.postPatientMeter(serialNumber);
      dispatch(postPatientMeterSuccess());
    } catch (err) {
      dispatch(postPatientMeterFailure(err));
      throw err;
    }
  };
}

export function postPatientMeterWithToken(tokenType, token) {
  return async function (dispatch) {
    dispatch(postPatientMeterWithTokenStart());

    try {
      await settingsService.postPatientMeterWithToken(token);
      dispatch(postPatientMeterWithTokenSuccess(tokenType, token));
    } catch (err) {
      dispatch(postPatientMeterWithTokenFailure(err));
      throw err;
    }
  };
}

export function putNotificationSettings(notificationSettings, settingsFor = 'patient') {
  return async function (dispatch) {
    dispatch(putNotificationSettingsStart());

    try {
      await settingsService.putNotificationSettings(notificationSettings, settingsFor);
      dispatch(putNotificationSettingsSuccess());
    } catch (err) {
      dispatch(putNotificationSettingsFailure(err));
      throw err;
    }
  };
}

export function putPatientTargets(targets) {
  return async function (dispatch) {
    dispatch(putPatientTargetsStart());

    try {
      await settingsService.putPatientTargets(targets);
      dispatch(putPatientTargetsSuccess());
    } catch (err) {
      dispatch(putPatientTargetsFailure(err));
      throw err;
    }
  };
}

// PATIENT_TESTING

function getPatientTestingFailure(err) {
  return {
    type: actionTypes.GET_PATIENT_TESTING_FAILURE,
    error: true,
    payload: err
  };
}

function getPatientTestingStart() {
  return {
    type: actionTypes.GET_PATIENT_TESTING_START
  };
}

function getPatientTestingSuccess(testingTimes) {
  return {
    type: actionTypes.GET_PATIENT_TESTING_SUCCESS,
    payload: { testingTimes }
  };
}

export function getPatientTesting() {
  return async function (dispatch) {
    dispatch(getPatientTestingStart());

    try {
      const testingTimes = await settingsService.getPatientTesting();
      dispatch(getPatientTestingSuccess(testingTimes));
    } catch (err) {
      dispatch(getPatientTestingFailure(err));
    }
  };
}

export function postPatientTesting(testingTime) {
  return async function () {
    try {
      await settingsService.postPatientTesting(testingTime);
    } catch (err) {
      throw err.response && err.response.data;
    }
  };
}

export function deletePatientTesting(testingId) {
  return async function () {
    try {
      await settingsService.deletePatientTesting(testingId);
    } catch (err) {
      throw err.response && err.response.data;
    }
  };
}

export function putPatientTesting(testing, testingId) {
  return async function () {
    try {
      await settingsService.putPatientTesting(testing, testingId);
    } catch (err) {
      throw err.response && err.response.data;
    }
  };
}
