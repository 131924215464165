import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import Radio from '@material-ui/core/Radio';
import { healthMenuIconsCollection } from '../constants/healthComapreMenuConstants';

export default class HealthCompareMenu extends Component {
  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
    closeOnEsc: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
    compareType: PropTypes.string,
    dataTypes: PropTypes.array,
    setComparisonType: PropTypes.func
  };

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeydown);
    }

    if (this.props.closeOnOutsideClick) {
      document.addEventListener('mouseup', this.handleOutsideMouseClick);
      document.addEventListener('touchstart', this.handleOutsideMouseClick);
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeydown);
    }

    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('mouseup', this.handleOutsideMouseClick);
      document.removeEventListener('touchstart', this.handleOutsideMouseClick);
    }
  }

  setType = (type) => () => {
    this.props.setComparisonType(type);
    this.props.closeMenu();
  }

  handleOutsideMouseClick = (e) => {
    const root = findDOMNode(this.menu);
    if (root.contains(e.target) || (e.button && e.button !== 0)) {
      return;
    }

    e.stopPropagation();
    this.props.closeMenu();
  }

  handleKeydown = (e) => {
    if (e.keyCode === 27) {
      this.props.closeMenu();
    }
  }

  render() {
    const { dataTypes } = this.props;

    return (
      <div
        data-testid="compare-menu"
        className="c-health-compare__menu c-health-compare__menu--open"
        ref={/* istanbul ignore next */node => { this.menu = node; }}
      >
        <ul>
          <li
            data-testid="set-type"
            className="c-health-compare__choice"
            onClick={this.setType(null)}
          >
            <span className="c-health-compare__label">Show Glucose Only</span>
            <span>
              <Radio classe={{ root: 'h-margin-right-none' }} checked={!this.props.compareType} />
            </span>
          </li>
          {dataTypes.map(item => {
            const selected = item.value === this.props.compareType;
            const icon = healthMenuIconsCollection[item.value] ? healthMenuIconsCollection[item.value] : '';

            return (
              <li
                key={item.id}
                data-testid="li"
                className="c-health-compare__choice"
                onClick={this.setType(item.value)}
              >
                <span className="c-health-compare__label">{icon}<span className="h-margin-left-sm">{item.label}</span></span>
                <span>
                  <Radio classe={{ root: 'h-margin-right-none' }} checked={selected} />
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
