/* istanbul ignore file */
import { connect } from 'react-redux';
import { getSharedWithMeContacts } from '../actions/contactsActions';
import { clearSelectedPatient } from '../actions/selectedPatientActions';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import SharingCircleSharingWIthMe from '../pages/sharingCircle/children/SharingCircleSharingWithMe/SharingCircleSharingWIthMe';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    itemsList: state.contacts.getIn(['sharingWithMeContacts', 'sharedWithMe']),
    itemsListCount: state.contacts.getIn(['sharingWithMeContacts', 'sharedWithMeCount'])
  };
}

export default connect(mapStateToProps, {
  clearSelectedPatient,
  getSharedWithMeContacts
})(immutableToJsHOC(SharingCircleSharingWIthMe));
