import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  favoriteFoods: new Immutable.List(),
  recentFoods: new Immutable.List()
});

export default function meals(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.GET_FAVORITE_FOODS_SUCCESS:
    return state.set('favoriteFoods', Immutable.fromJS(action.payload));

  case actionTypes.GET_RECENT_FOODS_SUCCESS:
    return state.set('recentFoods', Immutable.fromJS(action.payload));

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
