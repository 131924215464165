/* istanbul ignore file */
import React, { memo, useState, Fragment } from 'react';
import { Prompt } from 'react-router-dom';
import { FormSpy } from 'react-final-form';

// Display a confirmation dialog when leaving the page outside the React Router
// Use memo to only re-render if props change
const WarnAboutUnsavedChanges = memo(() => {
  const [formIsDirty, setFormIsDirty] = useState(false);

  return (
    <Fragment>
      <FormSpy
        subscription={{ dirty: true, submitting: true }}
        onChange={form => {
          setFormIsDirty(form.dirty && !form.submitting);
        }}
        render={() => null}
      />
      <Prompt when={formIsDirty} message="Please confirm you wish to discard your changes." />
    </Fragment>
  );
});

WarnAboutUnsavedChanges.displayName = 'UnsavedChangesDialog';

export default WarnAboutUnsavedChanges;
