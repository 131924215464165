import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import DialogContent from '@material-ui/core/DialogContent';
import { Container, Grid } from '@material-ui/core';
import { contactsViewsConstants } from 'src/constants/contactsInviteViews';
import { StyledDialogTitle } from 'src/components/muiStyledComponents/StyledDialogTitle';
import { StyledDialogActions } from 'src/components/muiStyledComponents/StyledDialogActions';
import { useInboxActionsContext } from 'src/pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import ContactsPracticeInfoViewData from './ContactsPracticeInfoViewData';

const propTypes = {
  practiceInfo: PropTypes.shape({
    cityVillage: PropTypes.string,
    stateProvince: PropTypes.string,
    invitationStatus: PropTypes.string,
    name: PropTypes.string,
    hcpsCount: PropTypes.number,
    address: PropTypes.string,
    country: PropTypes.string,
    practiceId: PropTypes.number,
    hcps: PropTypes.array
  }),
  isSearching: PropTypes.bool,
  invitePractice: PropTypes.func.isRequired,
  getHcpListInPractice: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

const ContactsPracticeInfoView = ({
  id: practiceId,
  practiceInfo,
  getHcpListInPractice,
  invitePractice,
  isSearching
}) => {
  const descriptionElementRef = useRef(null);
  const { switchToView, closeModal } = useInboxActionsContext();

  useEffect(() => { getHcpListInPractice(practiceId); }, [practiceId]);

  const viewInfo = (hcpId) => switchToView(
    contactsViewsConstants.practiceHcpInfoView,
    { practiceId, id: hcpId }
  );

  const goBack = () => {
    switchToView(contactsViewsConstants.hcpSearchView);
  };

  const sendInvite = async (idOfPractice) => {
    await invitePractice(idOfPractice);
    switchToView(contactsViewsConstants.successInviteView);
  };

  return (
    <Container maxWidth="sm" className="h-padded-lg">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <StyledDialogTitle>
          <strong>Add Healthcare Provider</strong>
        </StyledDialogTitle>

        <DialogContent
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {isSearching && (<div className="h-center" data-testid="loading">Is loading...</div>)}
          {!isSearching && isEmpty(practiceInfo) && (<div className="h-center" data-testid="empty">No results</div>)}
          {!isSearching && !isEmpty(practiceInfo) && (
            <ContactsPracticeInfoViewData practiceInfo={practiceInfo} sendInvite={sendInvite} viewInfo={viewInfo} />
          )}
        </DialogContent>

        <StyledDialogActions>
          <button data-testid="back" className="c-button c-button--action" onClick={goBack}>Back</button>
          <button data-testid="cancel" className="c-button c-button--hollow" onClick={closeModal}>Cancel</button>
        </StyledDialogActions>
      </Grid>
    </Container>
  );
};

ContactsPracticeInfoView.propTypes = propTypes;
export default ContactsPracticeInfoView;
