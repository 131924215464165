/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import coachingBroken from 'src/assets/img/icons/coachingBroken.svg';

const propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default function UnavailableView({ title, text }) {
  return (
    <div className="l-window__container">
      <div className="l-window__img-container">
        <img src={coachingBroken} />
      </div>
      <div className="l-window__text-container h-margin-top">
        <h3 className="l-window__title-default">{title}</h3>
      </div>
      <div className="l-window__text-container h-margin-top-lg">
        <p className="l-window__text l-window__text--short ">{text}</p>
      </div>
    </div>
  );
}

UnavailableView.propTypes = propTypes;
