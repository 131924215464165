/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { putPatientMedications } from 'src/actions/profileActions';
import Medications from 'src/pages/profile/Medications';
import { insulins } from 'src/constants/medicationDrugs';
import WithOnboardingTracking from './withOnboardingTracking';

function MedicationsContainer(props) {
  return (
    <WithOnboardingTracking type="medications">
      <Medications {...props} />
    </WithOnboardingTracking>
  );
}

function makeMapStateToProps() {
  return function (state) {
    const types = state.profile.getIn(['medications', 'medicationTypes'])?.toJS();
    const typesValues = types?.valueOf();
    const medicationTypes = {};

    /*
     * We here need to create object like:
     *
     *    const medicationTypes = {
     *        'oral': true,
     *        'insulin': true,
     *    };
     *
     * from const typesValues = ['oral', 'insulin'];
     *
     * Because our form expects form fields(Switch) to have prop names like this.
     */
    for (const value of typesValues) {
      medicationTypes[value] = true;
    }

    const insulinTypes = state.profile.getIn(['medications', 'insulins'])?.toJS();
    const copyOfInsulins = JSON.parse(JSON.stringify(insulins));

    /*
     * We here need to create object like:
     *
     *    const medicationTypes = [
     *        {
     *          'oral': true
     *        },
     *        {
     *          'insulin': true
     *        }
     *     ];
     *
     * from const typesValues = ['oral', 'insulin'];
     *
     * Because our form expects form fields(Switch) to have prop names like this.
     */
    const setInsulinValue = (type) => (insulin) => {
      const index = copyOfInsulins[type]?.findIndex((el) => el[insulin] !== undefined);
      if (index === -1 && String(insulin).trim()) {
        copyOfInsulins[type]?.push({ [insulin]: true });
      }

      if (index > -1) {
        copyOfInsulins[type][index][insulin] = true;
      }
    };

    insulinTypes?.pump?.forEach(setInsulinValue('pump'));
    insulinTypes?.inhaled?.forEach(setInsulinValue('inhaled'));
    insulinTypes?.injectableLong?.forEach(setInsulinValue('injectableLong'));
    insulinTypes?.injectableFast?.forEach(setInsulinValue('injectableFast'));

    return {
      initialValues: {
        medicationTypes,
        insulins: copyOfInsulins
      }
    };
  };
}

export default connect(makeMapStateToProps, { putPatientMedications })(MedicationsContainer);
