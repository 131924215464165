import PropTypes from 'prop-types';
import React from 'react';
import EditTag from './EditTag';
import MealTagsList from './MealTagsList';
import MealTagsButtons from './MealTagsButtons';
import CountlyTrackedButton from '../countly/CountlyTrackedButton';
import warnAboutUnsavedChangesNoForm from '../../containers/warnAboutUnsavedChangesNoForm';
import isEqualWith from 'lodash/isEqualWith';
import { diffByIdOrTypeReading } from '../../utils/logBookFoods';

export class EditTimingTag extends React.Component {
  static propTypes = {
    disablePreventRouteLeave: PropTypes.func.isRequired,
    deleteReadingTag: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired,
      replace: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    }).isRequired,
    postReadingTag: PropTypes.func.isRequired,
    putReadingTag: PropTypes.func.isRequired,
    preventRouteLeave: PropTypes.func.isRequired,
    selectedReading: PropTypes.shape({
      full_tags: PropTypes.array,
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    let mealTag;

    if (props.selectedReading && props.selectedReading.full_tags) {
      mealTag = props.selectedReading.full_tags.find(tag => tag.type === 'meal');
    }

    const prefix = this.returnPrefix(mealTag);
    const value = this.returnValue(prefix, mealTag);
    this.state = {
      prefix,
      mealTag,
      value
    };
  }

  componentDidMount() {
    if (!this.props.selectedReading) {
      this.props.history.replace({ pathname: '/log-book' });
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // This is for closing window if user clicks on other date
    if (
      !isEqualWith(prevProps.selectedReading, this.props.selectedReading, diffByIdOrTypeReading) ||
      !this.props.selectedReading
    ) {
      this.props.history.replace({
        pathname: '/log-book'
      });
    }

    if (prevState.value !== this.state.value) {
      this.props.preventRouteLeave();
    }
  }

  returnValue = (prefix, mealTag) => {
    if (!mealTag || !mealTag.value) {
      return '';
    }

    if (!prefix) {
      return mealTag.value;
    }

    return mealTag.value.split(prefix)[1];
  };

  returnPrefix = (mealTag) => {
    if (!mealTag) {
      return '';
    } else if (String(mealTag.value).includes('post_')) {
      return 'post_';
    } else if (String(mealTag.value).includes('pre_')) {
      return 'pre_';
    }
    return '';
  }

  handleSave = (link) => async () => {
    const { selectedReading: { id }, history } = this.props;
    const tag = { type: 'meal', value: `${this.state.prefix}${this.state.value}` };

    this.props.disablePreventRouteLeave();

    if (this.state.mealTag) {
      tag.id = this.state.mealTag.id;
      await this.props.putReadingTag(id, tag);
    } else {
      await this.props.postReadingTag(id, tag);
    }

    history.push(link);
  }

  handleValue = (value) => {
    this.setState({ value });
  }

  handlePrefix = (prefix) => {
    this.setState({ prefix });
  }

  isSubmitDisabled = () => {
    const { value, prefix } = this.state;

    switch (`${prefix}${value}`) {
    case 'snack':
    case 'fasting':
    case 'post_breakfast':
    case 'post_lunch':
    case 'post_dinner':
    case 'pre_breakfast':
    case 'pre_lunch':
    case 'pre_dinner':
      return false;
    default:
      return true;
    }
  }

  isOfTypeMeal = el => el.type === 'meal';

  /*
   * Will return tag of type 'meal'
   */
  returnTag = () => {
    const { selectedReading } = this.props;
    return selectedReading && selectedReading.full_tags.find(this.isOfTypeMeal);
  };

  isDeleteDisabled = () => {
    const isTag = this.returnTag();

    if (!isTag) { return true; }

    return false;
  }

  handleDelete = (link) => async () => {
    const { selectedReading: { id, full_tags: fullTags }, history } = this.props;
    const tag =  fullTags.find(el => el.type === 'meal');

    await this.props.disablePreventRouteLeave();

    await this.props.deleteReadingTag(id, tag.id, tag);
    history.push(link);
  }

  render() {
    const { history } = this.props;
    const { prefix, value } = this.state;
    const backLink = history.location.pathname.slice(0, history.location.pathname.length - '/edit/timing'.length);
    const isRegularMeal = !(this.state.value === 'fasting' || this.state.value === 'snack');

    return (
      <EditTag
        backLink={backLink}
        className="c-log__detail c-card"
        label="Timing"
      >
        <div className="c-card__body c-card__body--unpadded">
          <div className="h-center h-padded-lg">
            Select the correct pre or post meal
            <br />
            event to tag your reading.
          </div>

          <div className="h-padded">
            <MealTagsButtons
              prefix={prefix}
              value={value}
              handleValue={this.handleValue}
              handlePrefix={this.handlePrefix}
            />
            {isRegularMeal && (
              <MealTagsList
                value={value}
                handleValue={this.handleValue}
              />
            )}
          </div>
          <div className="h-padded">
            <CountlyTrackedButton
              dataTestid="save"
              countlyevent="update"
              disabled={this.isSubmitDisabled()}
              className="c-button c-button--block c-button--action h-margin-bottom-sm"
              onClick={this.handleSave(backLink)}
            >
              Save
            </CountlyTrackedButton>
            <CountlyTrackedButton
              dataTestid="delete"
              countlyevent="delete"
              disabled={this.isDeleteDisabled()}
              className="c-button c-button--block c-button--danger-hollow"
              onClick={this.handleDelete(backLink)}
            >
              Remove Data and Save
            </CountlyTrackedButton>
          </div>
        </div>
      </EditTag>
    );
  }
}

export default warnAboutUnsavedChangesNoForm(EditTimingTag);
