/* istanbul ignore file */
import { connect } from 'react-redux';
import { getIAmSharingWithReceivedInvitations } from '../actions/contactsIAmSharingWithInvitesActions';
import ContactsIAmSharingWithReceivedInvites from '../pages/contacts/children/iAmSharingWithReceivedInvites/ContactsIAmSharingWithReceivedInvites';
import { immutableToJsHOC } from './ImmutableToJsHOC';

function mapStateToProps(state) {
  return {
    itemsList: state.contactsIAmSharingWithInvitations.getIn(['receivedInvitations', 'invitations']),
    itemsListCount: state.contactsIAmSharingWithInvitations.getIn(['receivedInvitations', 'sharingWithReceivedInvitationCount'])
  };
}

export default connect(mapStateToProps, {
  getIAmSharingWithReceivedInvitations
})(immutableToJsHOC(ContactsIAmSharingWithReceivedInvites));
