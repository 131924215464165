import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Container, DialogContent, Grid } from '@material-ui/core';
import { StyledDialogTitle } from 'src/components/muiStyledComponents/StyledDialogTitle';
import { contactsViewsConstants } from 'src/constants/contactsInviteViews';
import { StyledDialogActions } from 'src/components/muiStyledComponents/StyledDialogActions';
import { getIAmSharingWithInvitationsCount } from 'src/actions/contactsIAmSharingWithInvitesActions';
import { useInboxActionsContext } from 'src/pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import ContactsHcpInfoViewData from './ContactsHcpInfoViewData';

const propTypes = {
  hcpInfo: PropTypes.shape({
    salutation: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailAddress: PropTypes.string,
    specialty: PropTypes.string,
    medicalPractices: PropTypes.arrayOf(PropTypes.shape({
      practiceId: PropTypes.number,
      medicalPracticeName: PropTypes.string,
      invitationStatus: PropTypes.string
    }))
  }),
  id: PropTypes.number.isRequired,
  isSearching: PropTypes.bool,
  getHcpInfo: PropTypes.func.isRequired,
  invitePractice: PropTypes.func.isRequired,
  getIAmSharingWithInvitationsCount: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
};

export default function ContactsHcpInfoView({
  hcpInfo,
  goBack,
  id,
  getHcpInfo,
  isSearching,
  invitePractice
}) {
  const descriptionElementRef = useRef(null);
  const { switchToView, closeModal } = useInboxActionsContext();

  const sendInvite = async (practiceId) => {
    await invitePractice(practiceId);
    await getIAmSharingWithInvitationsCount();
    switchToView(contactsViewsConstants.successInviteView);
  };

  useEffect(() => {
    getHcpInfo(id);
  }, [id]);

  return (
    <Container maxWidth="sm" className="h-padded-lg">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <StyledDialogTitle>
          <strong>HCP Info</strong>
        </StyledDialogTitle>

        <DialogContent
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {isSearching && <div className="h-center">Is Loading...</div>}
          {!isSearching && isEmpty(hcpInfo) && <div className="h-center">No results</div>}
          {!isSearching && !isEmpty(hcpInfo) && <ContactsHcpInfoViewData hcpInfo={hcpInfo} sendInvite={sendInvite}/>}
        </DialogContent>

        <StyledDialogActions>
          <button data-testid="jest-proceed" className="c-button c-button--action " onClick={goBack}>Back</button>
          <button data-testid="jest-cancel" className="c-button c-button--hollow" onClick={closeModal}>Cancel</button>
        </StyledDialogActions>
      </Grid>
    </Container>
  );
}

ContactsHcpInfoView.propTypes = propTypes;
