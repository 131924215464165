/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { parse } from 'query-string';

// Actions
import { fetchEmergencyEventData } from '../actions/emergencyEventActions';
// Components
import Geolocation from '../components/Geolocation';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import CircularProgress from '@material-ui/core/CircularProgress';

class GeolocationContainer extends Component {
  static propTypes = {
    emergencyEventData: PropTypes.shape({
      location_known: PropTypes.bool,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      maps_api_key: PropTypes.string,
      radius: PropTypes.number,
      location_updated_at: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      spots: PropTypes.object
    }),
    fetchEmergencyEventData: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired
    }).isRequired
  };

  async componentDidMount() {
    const query = parse(this.props.history.location.search);

    if (!query.code) {
      this.props.history.push('/');
    } else {
      try {
        await this.props.fetchEmergencyEventData(query.code);
      } catch (err) {
        this.props.history.push('/');
        throw err;
      }
    }
  }

  render() {
    if (!this.props.emergencyEventData) {
      return (
        <div className="e-progress__container c-card-map" >
          <CircularProgress color="#2da9df" size={120} thickness={15}/>
          <div className="e-progress__text">Loading...</div>
        </div>
      );
    }

    return (<Geolocation {...this.props}/>);
  }
}

function mapStateToProps(state) {
  return {
    emergencyEventData: state.emergencyEvent.get('emergencyEventData')
  };
}

export default connect(mapStateToProps, {
  fetchEmergencyEventData
})(withRouter(immutableToJsHOC(GeolocationContainer)));
