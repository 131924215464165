import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import SearchedPractice from '../../../../../../components/contacts/SearchedPractice/SearchedPractice';
import SearchResultsEmpty from '../SearchResultsEmpty/SearchResultsEmpty';

const propTypes = {
  isSearching: PropTypes.bool,
  invitePractice: PropTypes.func,
  getIAmSharingWithInvitationsCount: PropTypes.func,
  searchQuery: PropTypes.string,
  searchResults: PropTypes.array
};

function SearchPracticeResults({ searchResults, searchQuery, invitePractice, isSearching, getIAmSharingWithInvitationsCount }) {
  if (isSearching) {
    return <div className="h-center" data-testid="loading">Loading...</div>;
  }

  if (isEmpty(searchResults) && searchQuery.length > 2) {
    return <SearchResultsEmpty/>;
  }

  if (searchQuery.length <= 2) {
    return null;
  }

  const handleInvitePractice = async (practiceId) => {
    await invitePractice(practiceId);
    await getIAmSharingWithInvitationsCount();
  };

  return (
    <div className="h-full-width">
      {searchResults.map((practice, key) => (
        <SearchedPractice invitePractice={handleInvitePractice} practice={practice} key={key} />
      ))}
    </div>
  );
}

SearchPracticeResults.propTypes = propTypes;
export default SearchPracticeResults;
