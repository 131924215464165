import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { contactsViewsConstants } from 'src/constants/contactsInviteViews';
import { StyledDialogTitle } from 'src/components/muiStyledComponents/StyledDialogTitle';
import { StyledDialogActions } from 'src/components/muiStyledComponents/StyledDialogActions';
import { useInboxActionsContext } from 'src/pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import { useStyles } from './ContactsHcpInviteStatus.styles';

export default function ContactsHcpInviteStatus() {
  const classes = useStyles();
  const { switchToView, closeModal } = useInboxActionsContext();

  const switchView = () => {
    switchToView(contactsViewsConstants.hcpSearchView);
  };

  return (
    <Container maxWidth="sm" className="h-padded-lg">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <StyledDialogTitle>
          <strong>Invitation successfully sent</strong>
        </StyledDialogTitle>

        <div className={classes.content}>Your invitation was successfully sent. You can cancel in any time from “Sent
          Invitations” section
        </div>

        <StyledDialogActions>
          <button data-testid="jest-cancel" className="c-button c-button--action" onClick={closeModal}>Close</button>
          <button data-testid="jest-proceed" className="c-button c-button--hollow h-no-break-words" onClick={switchView}>
            Invite Another Contact
          </button>
        </StyledDialogActions>
      </Grid>
    </Container>
  );
}
