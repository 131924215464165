import apiService from './apiService';

async function deleteReadingTag(tagID) {
  const { data } = await apiService.delete(`/tags/${tagID}`);
  return data;
}

async function getRangeOfReadingTags(from, to) {
  const { data } = await apiService.get(`/tags/range/${from}/${to}`, { apiVersion: '/v2' });
  return data;
}

async function getReadingTagByTagID(tagID) {
  const { data } = await apiService.get(`/tags/${tagID}`);
  return data;
}

async function getReadingTags(readingId) {
  const { data } = await apiService.get(`/readings/${readingId}/tags`);
  return data;
}

async function postReadingTag(readingId, tag) {
  const { data } = await apiService.post(`/readings/${readingId}/tags`, tag);
  return data;
}

async function putReadingTag(tag) {
  const { data } = await apiService.put(`/tags/${tag.id}`, tag);
  return data;
}

export default {
  deleteReadingTag,
  getRangeOfReadingTags,
  getReadingTagByTagID,
  getReadingTags,
  postReadingTag,
  putReadingTag
};
