/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import ChevronIcon from 'src/components/icons/ChevronIcon';
import AvatarFieldContainer from './AvatarFieldContainer';
import SharingCircleAboutMeFormContainer from './SharingCircleAboutMeFormContainer';

const SharingCircleAboutMeContainer = () => {
  return (
    <section className="c-section">
      <Link className="c-nav-header" to="/sharing-circle/profile">
        <ChevronIcon />
        <h2 className="c-nav-header__label">About Me</h2>
      </Link>
      <AvatarFieldContainer />
      <SharingCircleAboutMeFormContainer />
    </section>
  );
};

export default SharingCircleAboutMeContainer;
