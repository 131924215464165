import apiService from './apiService';
import { mapPhoneNumbers } from '../utils/mapPhoneNubmers';

async function searchHealthCareProviders(searchQuery = '') {
  try {
    const { data } = await apiService.get(`/health_care_providers/search?${searchQuery}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function searchPractices(searchQuery = '') {
  try {
    const { data } = await apiService.get(`/medical_practices?${searchQuery}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getHcpInfo(id) {
  try {
    const { data } = await apiService.get(`/health_care_providers/${id}/show`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getHcpListInPractice(id) {
  try {
    const { data } = await apiService.get(`/medical_practices/${id}/hcp_list`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function invitePractice(id) {
  try {
    const { data } = await apiService.post(`/medical_practices/${id}/invitation_requests`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function inviteSharingCircleOrEmergencyContact(formData) {
  const formValues = mapPhoneNumbers(formData);
  try {
    const { data } = await apiService.post('/invitations', {
      ...formValues
    });
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

export default {
  inviteSharingCircleOrEmergencyContact,
  invitePractice,
  getHcpInfo,
  getHcpListInPractice,
  searchHealthCareProviders,
  searchPractices
};
