import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  sentInvitations: new Immutable.Map({
    invitations: new Immutable.List(),
    sentInvitationCount: 0
  }),
  receivedInvitations: new Immutable.Map({
    invitations: new Immutable.List(),
    sharingWithReceivedInvitationCount: 0
  }),
  invitationsCount: new Immutable.Map({
    sentInvitations: new Immutable.Map({
      pending: 0,
      declined: 0,
      expired: 0,
      avatarUrl: null
    }),
    receivedPracticeInvitations: new Immutable.Map({
      pending: 0,
      phone_not_verified: 0
    })
  })
});

export default function contactsIAmSharingWithInvitationsReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.GET_CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS_SUCCESS:
    return state.set('sentInvitations', Immutable.fromJS(action.payload));

  case actionTypes.GET_CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS_SUCCESS:
    return state.set('receivedInvitations', Immutable.fromJS(action.payload));

  case actionTypes.GET_I_AM_SHARING_WITH_INVITATIONS_COUNT_SUCCESS:
    return state.set('invitationsCount', Immutable.fromJS(action.payload));

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
