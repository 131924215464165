import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  commentTag: PropTypes.shape({
    value: PropTypes.string
  })
};

export default function CommentTag(props) {
  const { commentTag } = props;
  const comment = commentTag ? commentTag.value : '';

  return (
    <div className="c-tag c-tag--detail">
      <span
        data-testid="jest-comment"
        className="c-tag__comments"
        style={{ whiteSpace: 'pre-line' }}
      >
        {comment}
      </span>
    </div>
  );
}

CommentTag.propTypes = propTypes;
