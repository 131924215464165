import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';

export const StyledDialogActions = withStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    paddingTop: '2rem',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& .c-button': {
      width: '190px',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '&:not(:first-child)': {
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
          marginTop: '16Px'
        }
      }
    }
  }
}))(DialogActions);
