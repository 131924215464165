/* istanbul ignore file */
import { connect } from 'react-redux';
import SearchHcpResults from '../pages/contacts/children/invites/hcp/SearchHcpResults/SearchHcpResults';
import { immutableToJsHOC } from './ImmutableToJsHOC';

function mapStateToProps(state) {
  return {
    searchResults: state.contactsInvites.getIn(['hcpSearchResults', 'hcps']),
    isSearching: state.contactsInvites.get('isSearching')
  };
}

export default connect(mapStateToProps, {})(immutableToJsHOC(SearchHcpResults));
