/* istanbul ignore file */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { acceptInvitation } from '../actions/invitationActions';
import { postPatientMeterWithToken } from '../actions/settingsActions';
import { pleaseKeepSigningMeOut, signIn, reduxSignOut, staySignedIn } from '../actions/authenticationActions';
import { getStatus } from '../actions/statusActions';

import SignIn from '../components/SignIn';
import TOKEN_TYPES from '../constants/tokenTypes';

function mapStateToProps(state) {
  return {
    headers: state.authentication.get('headers'),
    invitationToken: state.token.get(TOKEN_TYPES.SHARING_CIRCLE_CONTACT),
    hasAcceptedTermsAndConditions: state.status.get('hasAcceptedTermsAndConditions'),
    isRefreshing: state.authentication.get('isRefreshing'),
    pogoToken: state.token.get(TOKEN_TYPES.ADD_METER),
    staySignedIn: state.authentication.get('staySignedIn')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      authentication: bindActionCreators(
        {
          pleaseKeepSigningMeOut,
          signIn,
          reduxSignOut,
          staySignedIn
        },
        dispatch
      ),
      invitation: bindActionCreators({ acceptInvitation }, dispatch),
      settings: bindActionCreators({ postPatientMeterWithToken }, dispatch),
      status: bindActionCreators({ getStatus }, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
