/* istanbul ignore file */
import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import NotificationSettingsContainer from 'src/containers/NotificationSettingsContainer';
import TestingAdjustmentContainer from 'src/containers/TestingAdjustmentContainer';
import TestingScheduleContainer from 'src/containers/TestingScheduleContainer';
import GlucloseTargetsContainer from 'src/containers/GlucloseTargetsContainer';
import MetersContainer from 'src/containers/MetersContainer';
import ProfileMenu from 'src/components/ProfileMenu';
import Help from 'src/components/Help';
import AboutMe from 'src/components/AboutMeForm/AboutMe';
import CountlyTrackedRoute from 'src/components/countly/CountlyTrackedRoute';
import MarketplaceContainer from 'src/containers/MarketplaceContainer';
import { PAGE_NAMES } from 'src/constants/routesCountlyNameConstants';
import MedicationsContainer from 'src/containers/MedicationsContainer';

const settingsList = [
  { label: 'About Me', location: '/profile/about-me' },
  { label: 'Notifications', location: '/profile/notifications' },
  { label: 'Glucose Targets', location: '/profile/glucose-targets' },
  { label: 'Testing Schedule', location: '/profile/testing-schedule' },
  { label: 'Medications', location: '/profile/Medications' },
  { label: 'POGO Automatic<sup>&reg;</sup>', location: '/profile/pogo' },
  { label: 'Other Devices & Apps', location: '/profile/Marketplace' },
  { label: 'Help', location: '/profile/help' }
];

function Profile() {
  const history = useHistory();
  const sidebarClasses = classNames(
    'l-split-view__sidebar',
    { 'l-split-view__sidebar--hidden': /.*\/profile\/.+/.test(history.location.pathname) }
  );

  return (
    <div className="l-container l-split-view">
      <div className={sidebarClasses}>
        <ProfileMenu settingsList={settingsList} />
      </div>
      <div className="l-split-view__body">
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.ABOUT_ME} path="/profile/about-me" component={AboutMe} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.NOTIFICATIONS} path="/profile/notifications" component={NotificationSettingsContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.GLUCOSE_TARGETS} path="/profile/glucose-targets" component={GlucloseTargetsContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.TESTING_SCHEDULE} exact path="/profile/testing-schedule" component={TestingScheduleContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.TESTING_SCHEDULE} exact path="/profile/testing-schedule/adjust/:id" component={TestingAdjustmentContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.TESTING_SCHEDULE} exact path="/profile/testing-schedule/create" component={TestingAdjustmentContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.MEDICATIONS} path="/profile/medications" component={MedicationsContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.MARKETPLACE} path="/profile/marketplace" component={MarketplaceContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.METERS} path="/profile/pogo" component={MetersContainer} />
        <CountlyTrackedRoute pageGroup="Profile" pageName={PAGE_NAMES.HELP} path="/profile/help" component={Help} />
      </div>
    </div>
  );
}

export default Profile;
