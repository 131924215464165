import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  backDrop: {
    background: 'rgba(1, 164, 201, 0.6)'
  },
  paper: {
    boxShadow: 'none',
    background: 'transparent',
    color: 'white',
    width: 500
  }
}));
