import React from 'react';
import { FORM_ERROR } from 'final-form';

export function handleFormSubmissionErrors(err, errorKeys) {
  const error = { [FORM_ERROR]: err.error };

  if (err.errors && err.errors.length) {
    errorKeys.forEach(errorKey => {
      const kError = err.errors.find(e => e.fieldName === errorKey);
      if (kError) {
        error[errorKey] = kError.errors && kError.errors.map((e, i) => <span key={i}>{e}<br /></span>);
      }
    });
  }

  return error;
}
