import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import GooglePlaceList from './GooglePlaceList';
import CardList from './CardList';
import patientIcon from 'src/assets/img/icons/patient.svg';
import hospitalIcon from 'src/assets/img/icons/hospital.svg';
import policeIcon from 'src/assets/img/icons/police.svg';
import patternsLogoLockup from 'src/assets/img/icons/patterns_logo_lockup.svg';
import { sv } from 'src/assets/css/1_settings/variables.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const markerIcons = {
  hospitalIcon,
  policeIcon
};

export default class Geolocation extends Component {
  static propTypes = {
    emergencyEventData: PropTypes.shape({
      location_known: PropTypes.bool,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      maps_api_key: PropTypes.string,
      radius: PropTypes.number,
      location_updated_at: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      spots: PropTypes.object
    }),
    maps_api_key: PropTypes.any
  };

  state = {
    expanded: false,
    hospital: [],
    police: []
  }

  componentDidMount() {
    const { emergencyEventData: { maps_api_key } } = this.props; // eslint-disable-line

    if (!window.google) {
      this.loadGoogleMapsApi(maps_api_key);
    } else {
      this.initGeolocationFetch();
    }
  }

  /**
   * Create script tag with google map
   * @param {string} googleApiKey - key to init google map
   */
  loadGoogleMapsApi = (googleApiKey) => {
    const googleMapsScriptExists = document.getElementById('googleMaps');
    if (googleMapsScriptExists) { return; }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}&callback=Function.prototype`;
    script.id = 'googleMaps';
    document.body.appendChild(script);
    // We cannot access google.maps until it's finished loading
    script.addEventListener('load', () => {
      this.initGeolocationFetch();
    });
  }

  /**
   * Display spots on google map
   * @param {string} type
   * @param {object} spots prop of google map obj
   */
  insertNearbySearchResults = ({ spots }, type) => {
    if (!spots[type]) {
      return;
    }

    spots[type].forEach((place) => {
      // Set event if marker exist
      place && this.attachMarkerEventListener(place, type);
    });
    this.setState({ [type]: spots[type] });
  }

  /**
   * Will draw google map.
   * Will init google map and draw patient marker.
   * Will add spots marker on google map.
   */
  initGeolocationFetch = () => {
    const { emergencyEventData } = this.props;
    const lat = emergencyEventData ? emergencyEventData.latitude : '0';
    const lng = emergencyEventData ? emergencyEventData.longitude : '0';
    const clientLatLng = new window.google.maps.LatLng(lat, lng);

    // Create map
    this.map = new window.google.maps.Map(this.googleMapContainer, { center: clientLatLng, zoom: 14 });
    emergencyEventData && emergencyEventData.location_known && this.returnMarker({ lat, lng }, patientIcon, true);

    this.insertNearbySearchResults(emergencyEventData, 'hospital');
    this.insertNearbySearchResults(emergencyEventData, 'police');
  }

  /**
   * Will add onClick event on marker to show infoWindow
   * This method should receive:
   * @param {string} type
   * @param {object} place
   */
  attachMarkerEventListener = (place, type) => {
    const content = this.createMarkerContent(place);
    const icon = markerIcons[`${type}Icon`];
    const marker = this.returnMarker(place, icon);

    marker.addListener('click', () => {
      this.generateInfoWindow(content, marker);
    });
  }

  /**
   * Will generate html for info window
   */
  createMarkerContent = ({ name, vicinity, ...restProps }) => {
    const { international_phone_number: phone } = restProps;

    return (
      `<div>
        <h4 class="h-left h-bold c-card-map__info-window-header">${name}</h4>
        <p class="h-left c-card-map__info-window-address">${vicinity}</p>
        <a class="h-left" href="tel:${phone}">${phone || ''}</a>
      </div>`
    );
  }

  /**
   * Creates marker for google map
   * @param {object} location - lat & lng
   * @param {object} icon - image
   * @param {bool} bindCircle - if circle should be shown
   */
  returnMarker = ({ lat, lng }, icon = null, bindCircle = false) => {
    const { emergencyEventData } = this.props;

    const circle = new window.google.maps.Circle({
      map: this.map,
      clickable: false,
      radius: emergencyEventData ? emergencyEventData.radius : 100,
      fillColor: sv.color.activeDisabled,
      fillOpacity: 0.6,
      strokeColor: sv.color.activeHover,
      strokeOpacity: 1,
      strokeWeight: 1
    });

    const marker = new window.google.maps.Marker({
      icon,
      animation: window.google.maps.Animation.DROP,
      map: this.map,
      position: { lat, lng }
    });

    // Attach circle only for patient marker with predefined radius
    bindCircle && circle.bindTo('center', marker, 'position');
    return marker;
  }

  /**
   * Will show info window on google map
   * @param {string} content - layout for infoWindow
   * @param {object} marker
   */
  generateInfoWindow = (content, marker) => {
    if (this.infoWindow) {
      // Close all previously opened infoWindows if any
      this.infoWindow.close();
    }
    this.infoWindow = new window.google.maps.InfoWindow({ content });
    this.infoWindow.open(this.map, marker);
  }

  makeDateWithTimezoneAbbr = (date) => {
    return `${dayjs(date).format('MM/DD/YYYY hh:mm A')} ${dayjs.tz.guess()}`;
  }

  render() {
    const { emergencyEventData } = this.props;
    const { police, hospital } = this.state;

    const wrapperClasses = classNames({
      'h-position--relative': true,
      'c-card-map--disabled': !emergencyEventData || !emergencyEventData.location_known
    });

    const firstName = emergencyEventData && emergencyEventData.first_name;
    const lastName = emergencyEventData && emergencyEventData.last_name;
    const date = emergencyEventData && this.makeDateWithTimezoneAbbr(emergencyEventData.location_updated_at);

    return (
      <div className="c-card-map__bg">
        <div className="l-container c-card-map__container h-padded-top-lg">
          <div className="c-card-map__header h-margin-bottom">
            <div className="c-card-map__header-img"><img src={patternsLogoLockup}/></div>
            <div className="c-card-map__header-text">
              <strong>{firstName} {lastName}</strong> posted an <strong>abnormal blood glucose reading </strong>
              and is not responding to our attempts to get in touch
            </div>
          </div>
          <div className={wrapperClasses}>
            <div className="c-card-map--disabled-wrap">
              <div className="c-card-map--disabled-text">
                Currently we can not locate {firstName} {lastName}. <br/>
                User is not sharing his GPS location or we can't get a signal. <br/>
                Please follow the guide below and try to update this page in few minutes.
              </div>
            </div>

            <div className="c-card-map" ref={(e) => { this.googleMapContainer = e; }}/>
          </div>

          {emergencyEventData.location_known && (
            <div data-testid="unknown-location" className="h-center">Last known location as of {date}</div>
          )}

          <div>
            <div className="h-margin-bottom h-margin-top-lg">
              <h3 className="c-card-map__title">Please follow this step-by-step guide:</h3>
            </div>

            <div className="h-padded h-bg-white h-border-radius--md">
              <CardList/>
            </div>

            <div className="h-margin-bottom h-margin-top-lg">
              <h3 className="c-card-map__title">Nearest points to get in touch</h3>
            </div>

            <div className="c-card-map__list-wrap h-margin-top-sm h-padded h-bg-white h-border-radius--md">
              {!police.length && !hospital.length && (
                <div className="h-center h-full-width">No results found</div>
              )}
              <GooglePlaceList
                list={police}
                type="police"
              />
              <GooglePlaceList
                list={hospital}
                type="hospital"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
