/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  transform: PropTypes.string
};

function ShapeBefore(props) {
  return (
    <g
      id="shape-legend"
      {...props}
    >
      <circle cx="12" cy="12" r="5" stroke={props.fill || props.stroke} strokeWidth="2" fill="none" />
    </g>
  );
}

ShapeBefore.propTypes = propTypes;

export default ShapeBefore;
