import PropTypes from 'prop-types';
import React from 'react';
import { parse } from 'query-string';
import FoodsListPresenterContainer from 'src/containers/FoodsListPresenterContainer';
import FoodsTabContainer from 'src/containers/FoodsTabContainer';
import EditTag from './EditTag';
import FoodEditContainer from 'src/containers/FoodEditContainer';
import FoodSearchContainer from 'src/containers/FoodSearchContainer';
import isEqualWith from 'lodash/isEqualWith';
import { diffByIdOrTypeReading } from 'src/utils/logBookFoods';

export default class EditCarbsTag extends React.Component {
  static propTypes = {
    clearNutritionix: PropTypes.func.isRequired,
    favoriteFoods: PropTypes.array,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired
      }).isRequired,
      replace: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    nutritionix: PropTypes.shape({
      query: PropTypes.string
    }),
    recentFoods: PropTypes.array,
    selectedReading: PropTypes.shape({
      foods: PropTypes.array,
      id: PropTypes.number
    })
  };

  componentDidMount() {
    !this.props.selectedReading && this.props.history.replace({ pathname: '/log-book' });
  }

  componentDidUpdate(prevProps) {
    // This is for closing window if user clicks on other date
    if (
      !isEqualWith(prevProps.selectedReading, this.props.selectedReading, diffByIdOrTypeReading) ||
      !this.props.selectedReading
    ) {
      this.props.history.replace({ pathname: '/log-book' });
    }
  }

  componentWillUnmount() {
    this.props.clearNutritionix();
  }

  makeBackLink = (path) => {
    switch (true) {
    case /\/log-book\/reading\/\d+\/edit\/carbs\/food$/.test(path):
      return { pathname: path.slice(0, path.length - '/food'.length) };
    case /\/log-book\/reading\/\d+\/edit\/carbs$/.test(path):
      return path.slice(0, path.length - '/edit/carbs'.length);
    default:
      return '/log-book';
    }
  }

  render() {
    const { selectedReading, history, nutritionix: { query } } = this.props;
    const {
      nix_item_id, // eslint-disable-line
      editFood, // This is to differ edit food vs add new food
      food_name, // eslint-disable-line
      food_id, // eslint-disable-line
      servings,
      submit = null,
      type = null
    } = parse(history.location.search);

    const isChildRoute = !!(nix_item_id || food_name); // eslint-disable-line
    const foods = selectedReading ? selectedReading.foods : [];
    const id = selectedReading ? selectedReading.id : 0;
    const backLink = this.makeBackLink(history.location.pathname);

    return (
      <EditTag
        backLink={backLink}
        className="c-log__detail c-card c-log__detail--carbs"
        label="Meals"
      >
        {!isChildRoute ?
          <FoodSearchContainer readingId={id}/> :
          <FoodEditContainer
            editFood={editFood}
            foodId={food_id} // eslint-disable-line
            query={food_name} // eslint-disable-line
            nixItemId={nix_item_id} // eslint-disable-line
            readingId={id}
            servings={servings}
            submitType={submit}
            to={{ pathname: backLink.pathname || backLink }}
            type={type}
          />}

        {!isChildRoute && !query && (
          <div className="c-card__footer">
            <FoodsListPresenterContainer
              history={history}
              isVisible
              list={foods}
              readingId={this.props.match.params.id}
              showFavoriteStatus
              type={'put'}
            />
            <FoodsTabContainer
              favoriteFoods={this.props.favoriteFoods}
              history={history}
              isVisible={!isChildRoute}
              readingId={this.props.match.params.id}
              recentFoods={this.props.recentFoods}
            />
          </div>
        )}
      </EditTag>
    );
  }
}
