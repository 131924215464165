import apiService from './apiService';

async function getSharedWithReceivedInvitations(searchQuery = '') {
  try {
    const { data } = await apiService.get(`/v2/contacts/shared_with_me_received_invitations?${searchQuery}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getSharedWithInvitationsCount() {
  try {
    const { data } = await apiService.get('/v2/contacts/count_shared_with_me_invitations');
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function acceptReceivedInvitation(token) {
  try {
    const { data } = await apiService.put('/invitations/relationship', {
      token
    });
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

export default {
  acceptReceivedInvitation,
  getSharedWithReceivedInvitations,
  getSharedWithInvitationsCount
};
