import apiService from './apiService';

async function terminateAccount() {
  try {
    const { data } = await apiService.post('/accounts/terminate');
    return { data };
  } catch (err) {
    throw err;
  }
}

async function getTerminationDetails() {
  try {
    const { data } = await apiService.get('/wordings/user_deactivate');
    return data;
  } catch (err) {
    throw err;
  }
}

export default {
  terminateAccount,
  getTerminationDetails
};
