/* istanbul ignore file */
import actionTypes from '../constants/actionTypes';

export function openSnackbar(message) {
  return {
    type: actionTypes.OPEN_SNACKBAR,
    payload: { message }
  };
}

export function closeSnackbar() {
  return { type: actionTypes.CLOSE_SNACKBAR };
}
