import actionTypes from '../constants/actionTypes';
import invitationService from '../services/invitationService';
import sharingService from '../services/sharingService';

// ACCEPT_INVITATION
function acceptInvitationFailure(err) {
  return {
    type: actionTypes.ACCEPT_INVITATION_FAILURE,
    error: true,
    payload: err
  };
}

function acceptInvitationStart() {
  return {
    type: actionTypes.ACCEPT_INVITATION_START
  };
}

function acceptInvitationSuccess() {
  return {
    type: actionTypes.ACCEPT_INVITATION_SUCCESS
  };
}

function selfRemoveFromEmergencyContactSuccess(payload) {
  return {
    type: actionTypes.SELF_REMOVE_FROM_EMERGENCY_CONTACT_SUCCESS,
    payload
  };
}

export function acceptInvitation(token) {
  return async function (dispatch) {
    dispatch(acceptInvitationStart());

    try {
      await invitationService.acceptInvitation(token);
      dispatch(acceptInvitationSuccess());
    } catch (err) {
      dispatch(acceptInvitationFailure(err));
      throw err;
    }
  };
}

export function renewConnection(token) {
  return async function () {
    await invitationService.renewConnection(token);
  };
}

export function selfRemoveFromEmergencyContact(token, confirm) {
  return async function (dispatch) {
    const data = await sharingService.selfRemoveFromEmergencyContact(token, confirm);
    dispatch(selfRemoveFromEmergencyContactSuccess(data));
  };
}
