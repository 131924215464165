/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Icons
import LogoSplash from './LogoSplash';

export default class TermsComponent extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    updateIsAgreeTermsAndConditions: PropTypes.func.isRequired
  };

  submit = async () => {
    try {
      await this.props.updateIsAgreeTermsAndConditions();
      this.props.history.push('/dashboard');
    } catch (err) {
      // Swallow for now
    }
  };

  render() {
    return (
      <div className="l-container l-container--narrow">
        <div className="l-welcome">
          <div className="l-welcome__splash">
            <LogoSplash/>
          </div>
          <div className="l-welcome__body">
            <div className="c-card">
              <div className="c-card__body">
                <div className="h-center h-padded-top">
                  <strong>Patterns<sup>&reg;</sup> Personal Wellness System</strong> updated terms and conditions
                </div>
                <div className="h-center h-padded-top h-padded-sides">
                  <span>By selecting "Agree", you acknowledge that you agree to our updated </span>
                  <a href="https://s3.amazonaws.com/uploaders.patternsforpogo.com/docs/terms_and_conditions.pdf" target="_blank">
                    Terms & Conditions
                  </a>.
                </div>
                <hr className="h-padded-top"/>
                <button
                  className="c-button c-button--block c-button--action"
                  onClick={this.submit}
                >
                  Agree
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
