/* istanbul ignore file */
import { connect } from 'react-redux';
import { postReadingTag, putReadingTag, deleteReadingTag } from 'src/actions/readingTagsActions';
import CommentEditTag from 'src/components/logs/CommentEditTag';
import { immutableToJsHOC } from './ImmutableToJsHOC';

function makeMapStateToProps() {
  return function (state) {
    const selectedId = state.readings.get('selectedId');

    return {
      selectedReading: selectedId ? state.readings.getIn(['cache', selectedId]) : null
    };
  };
}

export default connect(makeMapStateToProps, {
  postReadingTag,
  putReadingTag,
  deleteReadingTag
})(immutableToJsHOC(CommentEditTag));
