import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  incomingInvitationsFromPractice: new Immutable.List()
});

export default function incomingInvitationReducer(state = INITIAL_STATE, action) { // eslint-disable-line complexity
  switch (action.type) {
  case actionTypes.GET_INCOMING_INVITATIONS_FROM_PRACTICE_START:
    return state.set('isActive', true);

  case actionTypes.GET_INCOMING_INVITATIONS_FROM_PRACTICE_SUCCESS:
    return state.withMutations((map) => {
      map.set('incomingInvitationsFromPractice', Immutable.fromJS(action.payload.invitations));
      map.set('isActive', false);
    });

  case actionTypes.GET_INCOMING_INVITATIONS_FROM_PRACTICE_FAILURE:
    return state.set('isActive', false);

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
