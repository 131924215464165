import apiService from './apiService';
import authenticationService from './authenticationService';

async function selfRemoveFromEmergencyContact(token, confirm) {
  const { data } = await apiService.post(`${authenticationService.AUTH_URL}/unlink_contact/${token}`, { confirm });
  return data;
}

export default {
  selfRemoveFromEmergencyContact
};
