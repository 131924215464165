import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import ContactsIAmSharingReceivedInvitationsTable from '../ContactsIAmSharingWithReceivedInvitesTable';
import ContactsTypesFilter from 'src/components/contacts/ContactsTypesFilter/ContactsTypesFilter';
import SearchContacts from 'src/components/contacts/SearchContacts';
import routesConstants from 'src/constants/routes';
import useFetchDataHook from 'src/hooks/useFetchDataHook';
import Loader from 'src/components/shared/Loader';
import Text from 'src/shared/Text/Text';

const propTypes = {
  getIAmSharingWithReceivedInvitations: PropTypes.func.isRequired,
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number
};

export default function ContactsIAmSharingWithReceivedInvites({ getIAmSharingWithReceivedInvitations, itemsList, itemsListCount }) {
  const { CONTACTS_I_AM_SHARING_WITH } = routesConstants;
  const { memoizedFetch, loading } = useFetchDataHook(getIAmSharingWithReceivedInvitations);

  return (
    <Container style={{ padding: '0' }}>
      <Link to={CONTACTS_I_AM_SHARING_WITH} className="h-c-primary"><strong>{'<'} Back to People I am sharing with</strong></Link>

      <Grid container className="h-padded-top h-padded-bottom">
        <Text fontSize="24" lineHeight="32" font="bold">Received Practice Invitations</Text>
      </Grid>

      <div className="l-contact-filter h-padded-bottom-lg">
        <ContactsTypesFilter skipType={['emergency_contacts', 'sharing_circle']}/>
        <SearchContacts/>
      </div>

      <ContactsIAmSharingReceivedInvitationsTable
        memoizedFetch={memoizedFetch}
        itemsList={itemsList}
        itemsListCount={itemsListCount}
      />

      <Loader isEnabled={loading}/>
    </Container>
  );
}

ContactsIAmSharingWithReceivedInvites.propTypes = propTypes;
