import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '4px 16px',
    border: '1px solid #2DA9DF',
    borderRadius: '4px',
    width: '100%'
  }
}));
