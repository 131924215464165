import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false
});

function avatarReducer(state = INITIAL_STATE, action) { // eslint-disable-line complexity
  switch (action.type) {
  case actionTypes.GET_AVATAR_START:
  case actionTypes.PUT_AVATAR_START:
    return state.set('isActive', true);

  case actionTypes.GET_AVATAR_SUCCESS:
    return state.withMutations((map) => {
      map.set('image', action.payload.avatar);
      map.set('isActive', false);
    });

  case actionTypes.GET_AVATAR_FAILURE:
  case actionTypes.PUT_AVATAR_FAILURE:
  case actionTypes.PUT_AVATAR_SUCCESS:
    return state.set('isActive', false);

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}

export default avatarReducer;
