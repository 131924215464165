import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

import { formatTestingTimeForList, isDefaultMeal } from '../utils/testingTimeHelpers';
import ChevronIcon from './icons/ChevronIcon';
import BreakfastIcon from './icons/BreakfastIcon';
import LunchIcon from './icons/LunchIcon';
import DinnerIcon from './icons/DinnerIcon';
import TimingIcon from './icons/TimingIcon';

function getDisplayMealIcon(mealIcon) {
  switch (mealIcon) {
  case 'breakfast':
    return BreakfastIcon;
  case 'lunch':
    return LunchIcon;
  case 'dinner':
    return DinnerIcon;
  default:
    return TimingIcon;
  }
}

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  testingTimes: PropTypes.array.isRequired
};

export default function TestingSchedule(props) {
  const sortedTestingTimes = sortBy(props.testingTimes, ['startTime']);
  const filteredEnabledTestingTimes = props.testingTimes.filter(el => el.enabled);

  return (
    <section className="c-section">
      <Link className="c-nav-header" to="/profile">
        <ChevronIcon />
        <h2 className="c-nav-header__label">Testing Schedule</h2>
      </Link>
      <div className="h-margin-bottom-lg">
        <div className="c-list-item c-list-item--header">
          <span className="c-list-item__title">My Daily Goal is</span>
          <span>
            <span className="c-list-item__title">{filteredEnabledTestingTimes.length}</span>
            <span className="c-list-item__subtitle">Readings</span>
          </span>
        </div>
        <div className="c-list-item c-list-item--header">
          <span className="c-list-item__subtitle">Testing Times</span>
        </div>
        {sortedTestingTimes.map((tTime, i) => {
          const mealIcon = isDefaultMeal(tTime.icon) ? tTime.icon : 'timing';
          const DisplayIcon = getDisplayMealIcon(mealIcon);
          const iconClasses = `c-testing-time__icon c-icon c-icon--${mealIcon}`;
          const adjustmentLink = `/profile/testing-schedule/adjust/${tTime.id}`;
          const endTime = formatTestingTimeForList(tTime.endTime);
          const startTime = formatTestingTimeForList(tTime.startTime);
          const linkClasses = tTime.enabled ? 'c-list-item' : 'c-list-item c-list-item--disabled';

          return (
            <Link to={adjustmentLink} key={i} className={linkClasses}>
              <div className="c-testing-time">
                <DisplayIcon className={iconClasses} />
                <div className="c-label-pair">
                  <h4 data-testid="schedule-name" className="c-label-pair__title">{tTime.label}</h4>
                  {tTime.enabled ?
                    <div className="c-label-pair__times">
                      <span className="c-label-pair__hour">{startTime}</span>
                      <span> - </span>
                      <span className="c-label-pair__hour">{endTime}</span>
                    </div> :
                    <div className="c-label-pair__times">inactive</div>}
                </div>
              </div>
              <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
            </Link>
          );
        })}
      </div>
      <Link data-testid="add-schedule" to="/profile/testing-schedule/create" className="c-button c-button--block c-button--hollow h-margin-bottom-sm">
        Add Testing Time
      </Link>
      <p className="h-center h-padded-sm">Add testing times or edit ranges to fit your schedule</p>
    </section>
  );
}

TestingSchedule.propTypes = propTypes;
