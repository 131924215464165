/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { pie } from 'd3-shape';
import { scaleOrdinal } from 'd3-scale';
import PieSlice from './PieSlice';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  padAngle: PropTypes.number,
  sv: PropTypes.shape({
    color: PropTypes.object,
    fontSize: PropTypes.shape({
      xxl: PropTypes.number
    }),
    fontWeight: PropTypes.shape({
      bold: PropTypes.number
    }),
    helveticaBold: PropTypes.string,
    spacing: PropTypes.shape({
      sm: PropTypes.number
    })
  }),
  x: PropTypes.number,
  y: PropTypes.number
};

function Pie({ data, sv, x, y, innerRadius, outerRadius, padAngle }) {
  const colorScale = scaleOrdinal([sv.color.red, sv.color.yellow, sv.color.green]);
  const pieData = data.length ? pie()(data) : pie()([100]);

  return (
    <g transform={`translate(${x}, ${y})`}>
      {pieData.map((value, i) => {
        return (
          <PieSlice
            key={i}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            padAngle={padAngle}
            value={value}
            fill={data.length ? colorScale(i) : sv.color.backgroundActiveLight}
            label={(data.length && value.data) || null}
            sv={sv}
          />
        );
      })}
      <text
        fill={sv.color.primaryColor}
        textAnchor="middle"
        x={0}
        y={sv.spacing.sm + 2}
        fontFamily={sv.helveticaBold}
        fontSize={sv.fontSize.xxl}
        fontWeight={sv.fontWeight.bold}
      >
        %
      </text>
    </g>
  );
}

Pie.propTypes = propTypes;

export default Pie;
