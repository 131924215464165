import PropTypes from 'prop-types';
import React from 'react';
import BarChartSVG from '../components/svg/BarChartSVG';
import { connect } from 'react-redux';
import { setDevicePerDataType } from '../actions/validicActions';
import HealthDeviceWidget from '../components/HealthDeviceWidget';
import {
  getFoodsCacheDeviceList,
  makeGetFoodsDevice,
  makeGroupedCaloriesConsumedInTimeframe
} from '../selectors/healthDataSelectors';
import CaloriesIcon from '../components/icons/CaloriesIcon';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  caloriesConsumedTotals: PropTypes.object,
  caloriesConsumedDevice: PropTypes.object,
  caloriesConsumedDevices: PropTypes.array,
  isSharingCircleView: PropTypes.bool,
  setDevicePerDataType: PropTypes.func.isRequired
};

function WidgetCaloriesConsumedContainer({
  caloriesConsumedTotals,
  caloriesConsumedDevice,
  caloriesConsumedDevices,
  isSharingCircleView,
  setDevicePerDataType: switchDevice
}) {
  return (
    <HealthDeviceWidget
      Chart={BarChartSVG}
      data={caloriesConsumedTotals}
      dataCollectionType="foods"
      dataType="totalCalories"
      device={caloriesConsumedDevice}
      Icon={CaloriesIcon}
      isSharingCircleView={isSharingCircleView}
      sources={caloriesConsumedDevices}
      switchDevice={switchDevice}
      title="AVG Calories Consumed"
    />
  );
}

export function makeMapStateToProps() {
  const getGroupedCaloriesConsumed = makeGroupedCaloriesConsumedInTimeframe();
  const getFoodsDevice = makeGetFoodsDevice();

  return function (state, props) {
    const caloriesConsumedDevice = getFoodsDevice(state, props);
    const caloriesConsumedTotals = getGroupedCaloriesConsumed(state, props);
    const caloriesConsumedDevices = getFoodsCacheDeviceList(state);

    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      caloriesConsumedDevice,
      caloriesConsumedTotals,
      caloriesConsumedDevices
    };
  };
}

export default connect(makeMapStateToProps, {
  setDevicePerDataType
})(immutableToJsHOC(WidgetCaloriesConsumedContainer));

WidgetCaloriesConsumedContainer.propTypes = propTypes;
