import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import { Dialog, Paper, Container } from '@material-ui/core';
import HcpSearchBar from '../HcpSearchBar/HcpSearchBar';
import SearchHcpResultsContainer from '../../../../../../containers/SearchHcpResultsContainer';
import SearchPracticeResultsContainer from '../../../../../../containers/SearchPracticeResultsContainer';
import { StyledDialogActions } from '../../../../../../components/muiStyledComponents/StyledDialogActions';
import { contactsViewsConstants } from '../../../../../../constants/contactsInviteViews';
import { StyledDialogTitle } from '../../../../../../components/muiStyledComponents/StyledDialogTitle';
import { useInboxActionsContext } from '../../../../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import { useStyles } from './ContactsHcpAndPracticeSearch.styles';

const propTypes = {
  searchHealthCareProviders: PropTypes.func.isRequired,
  searchPractices: PropTypes.func.isRequired,
  clearInvitesSearchResults: PropTypes.func.isRequired,
  clearInvitesForm: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
  type: PropTypes.string,
  storeSearchQuery: PropTypes.func.isRequired,
  storeType: PropTypes.func.isRequired
};

function ContactsHcpAndPracticeSearch({
  storeSearchQuery,
  storeType,
  searchQuery,
  type,
  searchHealthCareProviders,
  clearInvitesSearchResults,
  clearInvitesForm,
  searchPractices
}) {
  const descriptionElementRef = useRef(null);
  const classes = useStyles();
  const fetch = type === 'practice' ? searchPractices : searchHealthCareProviders;
  const fetchItems = useMemo(() => debounce(fetch, 600), [fetch]);

  const { closeModal, switchToView } = useInboxActionsContext();

  useEffect(() => {
    searchQuery.length > 2 && fetchItems(`q=${searchQuery}`);
    searchQuery.length === 0 && clearInvitesSearchResults();
  }, [searchQuery, type]);

  const handleChange = (event) => {
    clearInvitesSearchResults();
    storeType(event.target.value);
  };

  const switchView = () => {
    clearInvitesForm();
    switchToView(contactsViewsConstants.inviteTypes);
  };

  return (
    <Dialog
      open
      scroll="paper"
      fullWidth
      maxWidth="sm"
      data-testid="dialog-invites"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperComponent={Paper}
      PaperProps={{
        elevation: 0
      }}
    >
      <Container maxWidth="sm" className="h-padded-lg">
        <StyledDialogTitle style={{ textAlign: 'center' }}>
          <strong>Add Healthcare Provider</strong>
        </StyledDialogTitle>

        <h4 className={classes.title}><strong>Show result only for:</strong></h4>

        <FormControl component="fieldset" className={classes.fullWidth}>
          <RadioGroup name="searchType" row value={type} onChange={handleChange} aria-label="search type select">
            <FormControlLabel
              className={classes.formControlLabel}
              value="practice"
              label="Search Practice"
              control={<Radio inputProps={{ 'data-testid': 'radio-button-practice' }}/>}
            />
            <FormControlLabel
              value="hcp"
              label="Search HCP"
              control={<Radio inputProps={{ 'data-testid': 'radio-button-hcp' }}/>}
            />
          </RadioGroup>
        </FormControl>

        <div className={classes.searchResults}>
          <HcpSearchBar
            placeholder={type === 'hcp' ? 'Search by HCP name or phone' : 'Search by practice name, phone, or location'}
            setSearchQuery={storeSearchQuery}
            searchQuery={searchQuery}
          />
        </div>

        <DialogContent
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          className={classes.dialog}
        >
          {type === 'hcp' && <SearchHcpResultsContainer searchQuery={searchQuery}/>}
          {type !== 'hcp' && <SearchPracticeResultsContainer searchQuery={searchQuery}/>}
        </DialogContent>

        <StyledDialogActions>
          <button data-testid="jest-back" className="c-button c-button--action" onClick={switchView}>Back</button>
          <button data-testid="jest-cancel" className="c-button c-button--hollow" onClick={closeModal}>Cancel</button>
        </StyledDialogActions>
      </Container>
    </Dialog>
  );
}

export default ContactsHcpAndPracticeSearch;

ContactsHcpAndPracticeSearch.propTypes = propTypes;
