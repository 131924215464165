export const insulins = {
  injectableFast: [
    { 'Humulin 70/30': false },
    { 'Novolin 70/30': false },
    { 'Novolog 70/30': false },
    { 'Humulin 50/50': false },
    { 'Humalog Mix 75/25': false }
  ],
  injectableLong: [
    { 'Lantus': false },
    { 'Toujeo': false },
    { 'Levemir': false },
    { 'Tresiba': false }
  ],
  pump: [
    { 'Humalog': false },
    { 'Novolog': false },
    { 'Apidra': false }
  ],
  inhaled: [
    { 'Afreeza': false }
  ]
};
