import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  userEmailAddress: '',
  pageName: '',
  pageGroup: ''
});

export default function countlyReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.COUNTLY_SET_USER_EMAIL:
    return state.set('userEmailAddress', action.payload);
  case actionTypes.COUNTLY_SET_PAGE:
    return state.withMutations((map) => {
      map.set('pageName', action.payload.pageName);
      map.set('pageGroup', action.payload.pageGroup);
    });
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;
  default:
    return state;
  }
}
