import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { immutableToJsHOC } from 'src/containers/ImmutableToJsHOC';
import LogEmpty from './LogEmpty';
import LogList from './LogList';
import LogLoading from './LogLoading';
import TimeframeContainer from 'src/containers/TimeframeContainer';
import EditCarbsTagContainer from 'src/containers/EditCarbsTagContainer';
import EditHydrationTagContainer from 'src/containers/EditHydrationTagContainer';
import EditTimingTagContainer from 'src/containers/EditTimingTagContainer';
import EditInsulinTagContainer from 'src/containers/EditInsulinTagContainer';
import EditMoodTagContainer from 'src/containers/EditMoodTagContainer';
import CommentTagContainer from 'src/containers/CommentTagContainer';
import LogDetailContainer from 'src/containers/LogDetailContainer';
import CountlyTrackedRoute from 'src/components/countly/CountlyTrackedRoute';
import { PAGE_NAMES } from 'src/constants/routesCountlyNameConstants';

class LogBook extends React.Component {
  static propTypes = {
    filteredReadings: PropTypes.array,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired
    }).isRequired,
    isSharingCircleView: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    patientTargets: PropTypes.object,
    readingsActive: PropTypes.bool,
    settings: PropTypes.shape({
      isActive: PropTypes.bool
    })
  };

  returnLogContent = () => {
    const { patientTargets, filteredReadings, readingsActive, settings, match: { params: { id } } } = this.props;
    const size = !!filteredReadings.length;
    const loading = readingsActive || settings.isActive;

    let logContent = <LogEmpty />;
    if (loading && !size) {
      logContent = <LogLoading />;
    } else if (size) {
      logContent = (
        <LogList
          patientTargets={patientTargets}
          readingsList={filteredReadings}
          readingRouteId={id}
        />
      );
    }

    return logContent;
  }

  render() {
    const {
      isSharingCircleView,
      history: { location: { pathname } }
    } = this.props;

    const sidebarClasses = classNames(
      'l-split-view__sidebar',
      'l-split-view__sidebar--medium',
      { 'l-split-view__sidebar--hidden': /.*\/\d+/.test(pathname) } // NOSONAR
    );

    return (
      <div className="l-container">
        <TimeframeContainer />
        <div className="l-split-view">
          <div className={sidebarClasses}>{this.returnLogContent()}</div>
          <div className="l-split-view__body">
            {!isSharingCircleView && (
              <Fragment>
                <CountlyTrackedRoute exact pageGroup="Logbook" pageName={PAGE_NAMES.LOG_BOOK} path="/log-book/reading/:id" component={LogDetailContainer} />
                <CountlyTrackedRoute pageGroup="Logbook" pageName={PAGE_NAMES.LOG_BOOK_TIMING} path="/log-book/reading/:id/edit/timing" component={EditTimingTagContainer} />
                <CountlyTrackedRoute pageGroup="Logbook" pageName={PAGE_NAMES.LOG_BOOK_CARBS} path="/log-book/reading/:id/edit/carbs" component={EditCarbsTagContainer} />
                <CountlyTrackedRoute pageGroup="Logbook" pageName={PAGE_NAMES.LOG_BOOK_MOOD} path="/log-book/reading/:id/edit/mood" component={EditMoodTagContainer} />
                <CountlyTrackedRoute pageGroup="Logbook" pageName={PAGE_NAMES.LOG_BOOK_INSULIN} path="/log-book/reading/:id/edit/insulin" component={EditInsulinTagContainer} />
                <CountlyTrackedRoute pageGroup="Logbook" pageName={PAGE_NAMES.LOG_BOOK_HYDRATION} path="/log-book/reading/:id/edit/hydration" component={EditHydrationTagContainer} />
                <CountlyTrackedRoute pageGroup="Logbook" pageName={PAGE_NAMES.LOG_BOOK_COMMENT} path="/log-book/reading/:id/edit/comment" component={CommentTagContainer} />
              </Fragment>
            )}
            {isSharingCircleView && (
              <CountlyTrackedRoute exact pageGroup="Logbook" id="jest-route" pageName={PAGE_NAMES.LOG_BOOK} path="/sharing-circle/log-book/reading/:id" component={LogDetailContainer} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default immutableToJsHOC(LogBook);
