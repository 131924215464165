/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { countlyChangeDeviceId, countlyTrackTabClose } from '../services/countlyService';
import storeService from '../services/store';

// Docs here: https://code.count.ly/sdk/web/countly.js.html
// Api Docs here: http://countly.github.io/countly-sdk-web/Countly.html#.begin_session
// Granular session control.
// https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-#changing-device-id
// Track forms
// Countly.q.push(['track_forms']);
// Countly.q.push(['collect_from_forms']);

export default class WithCountly extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }

  componentDidMount() {
    if (!window.Countly) {
      this.loadCountly();
    } else {
      this.initCountly();
    }

    // Handle tab close vent
    window.addEventListener('beforeunload', this.handleTabCloseAndPageRefresh);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleTabCloseAndPageRefresh);
  }

  handleTabCloseAndPageRefresh = () => {
    countlyTrackTabClose();
  };

  loadCountly = () => {
    const clyScriptExists = document.getElementById('cly');
    if (clyScriptExists) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://countly.staging.patternsforpogo.com/sdk/web/countly.min.js';
    script.id = 'cly';
    document.body.appendChild(script);
    script.addEventListener('load', this.initCountly);
  };

  initCountly = () => {
    // Docs here: https://code.count.ly/sdk/web/countly.js.html
    // Some default pre init
    const Countly = window.Countly || {};
    Countly.q = Countly.q || [];

    // Provide your app key that you retrieved from Countly dashboard
    Countly.app_key = process.env.COUNTLY_APP_KEY;

    // If you use your own server, make sure you have https enabled
    Countly.url = process.env.COUNTLY_DNS_SERVER;

    // after how many minutes user should be counted as inactive, if he did not perform any actions, as mouse move, scroll or keypress
    Countly.inactivity_time = 5;

    // app version
    Countly.app_version = 0.1;

    // we track session manually, so we don't use cookies for session
    Countly.use_session_cookie = false;

    // Trigger all request only via post
    Countly.force_post = true;

    // allow tracking which views/pages does user access
    Countly.q.push(['views']);
    Countly.q.push(['track_errors']);
    Countly.q.push(['track_links']);

    try {
      Countly.init();
      this.updateUserIdOnLoadIfExist();
    } catch (err) {
      console.warn(err); // eslint-disable-line
    }
  }

  updateUserIdOnLoadIfExist = async () => {
    const state = await storeService.getHydratedState();
    const userEmailAddress = state.countly.get('userEmailAddress');

    userEmailAddress && countlyChangeDeviceId(userEmailAddress);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}
