import React from 'react';
import PropTypes from 'prop-types';

export default (beforeEnter, WaitingComponent) => Target => {
  return class OnEnter extends React.Component {
    static propTypes = {
      history: PropTypes.shape({
        location: PropTypes.shape({
          pathname: PropTypes.string.isRequired
        }).isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired
    };

    state = {
      ifDone: false
    };

    async UNSAFE_componentWillMount() {  // eslint-disable-line
      await beforeEnter(this.props.history.location, this.props.history.replace);
      this.setState({ ifDone: true });
    }

    render() {
      const $wait = WaitingComponent ?
        <WaitingComponent {...this.props} /> :
        null;

      return this.state.ifDone ?
        <Target {...this.props} /> :
        $wait;
    }
  };
};
