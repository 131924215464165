import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { Container, Grid } from '@material-ui/core';
import { StyledDialogActions } from '../../../../../../components/muiStyledComponents/StyledDialogActions';
import { contactsViewsConstants } from '../../../../../../constants/contactsInviteViews';
import { StyledDialogTitle } from '../../../../../../components/muiStyledComponents/StyledDialogTitle';
import { useInboxActionsContext } from '../../../../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import { useStyles } from './ContactsEmergencyContactWelcome.styles';

function ContactsEmergencyContactWelcome() {
  const classes = useStyles();
  const { switchToView, closeModal } = useInboxActionsContext();

  const switchView = () => {
    switchToView(contactsViewsConstants.ecFormView);
  };

  return (
    <Container maxWidth="sm" className="h-padded-lg">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <StyledDialogTitle>
          <strong>Emergency Contact</strong>
        </StyledDialogTitle>

        <DialogContent className={classes.content}>
          Your emergency contacts will receive notifications when your blood glucose is low and you are unresponsive.
          During a high or low glucose event, Patterns® will notify you twice. If you are unresponsive, we'll escalate
          the situation and alert your emergency contacts. When you add an emergency contact, they will receive an email
          that will allow them to accept or decline your request.
        </DialogContent>

        <StyledDialogActions>
          <button data-testid="jest-cancel" className="c-button c-button--hollow" onClick={closeModal}>Cancel</button>
          <button data-testid="jest-proceed" className="c-button c-button--action" onClick={switchView}>Proceed</button>
        </StyledDialogActions>
      </Grid>
    </Container>
  );
}

export default ContactsEmergencyContactWelcome;
