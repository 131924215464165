import actionTypes from 'src/constants/actionTypes';
import TimeframeRecord from 'src/records/TimeframeRecord';

export const INITIAL_STATE = new TimeframeRecord();

// span, units, start, end, label

export default function timeframeReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.SET_TIMEFRAME:
    return state.withMutations((map) => {
      map.set('span', action.payload.timeframe.span);
      map.set('units', action.payload.timeframe.units);
      map.set('label', action.payload.timeframe.label);
      map.set('start', action.payload.timeframe.start);
      map.set('end', action.payload.timeframe.end);
    });

  case actionTypes.SHIFT_TIMEFRAME_BY_SPAN:
    return state.withMutations((map) => {
      map.set('start', action.payload.timeframe.start);
      map.set('end', action.payload.timeframe.end);
    });

  case actionTypes.SIGN_OUT:
  case actionTypes.SIGN_IN:
  case actionTypes.CLEAR_SELECTED_PATIENT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
