/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { clearSelectedReading, getRangeOfReadings } from 'src/actions/readingsActions';
import { getReadingFood } from 'src/actions/readingFoodsActions';
import { postReadingTag, putReadingTag } from 'src/actions/readingTagsActions';
import { getPatientTargets } from 'src/actions/settingsActions';
import TimeframeRecord from 'src/records/TimeframeRecord';
import LogBook from 'src/components/logs/LogBook';
import { makeGetReadingsInTimeframe } from 'src/selectors/readingSelectors';
import { makeEndDateForApi, makeStartDateForApi } from 'src/utils/dateFormatter';

export class LogBookContainer extends React.Component {
  static propTypes = {
    clearSelectedReading: PropTypes.func.isRequired,
    getPatientTargets: PropTypes.func.isRequired,
    getRangeOfReadings: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired
    }).isRequired,
    postReadingTag: PropTypes.func.isRequired,
    readings: PropTypes.shape({
      list: PropTypes.array
    }),
    timeframe: PropTypes.instanceOf(TimeframeRecord)
  };

  componentDidMount() {
    this.props.getPatientTargets();
    const start = makeStartDateForApi(this.props.timeframe.get('start'));
    const end = makeEndDateForApi(this.props.timeframe.get('end'));
    this.props.getRangeOfReadings(start, end, { tags: true, fullTags: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.timeframe && !this.props.timeframe.equals(prevProps.timeframe)) {
      const start = makeStartDateForApi(this.props.timeframe.get('start'));
      const end = makeEndDateForApi(this.props.timeframe.get('end'));
      this.props.getRangeOfReadings(start, end, { tags: true, fullTags: true });
      this.props.clearSelectedReading();

      if (this.props.match.params.id) {
        this.props.history.push('/log-book');
      }
    } else if (this.props.match.params.id && this.props.readings.list && !this.props.readings.list.find(reading => `${reading.id}` === `${this.props.match.params.id}`)) {
      this.props.history.push('/log-book');
    }
  }

  render() {
    return <LogBook {...this.props} />;
  }
}

/* istanbul ignore next */
function makeMapStateToProps() {
  const getFilteredReadings = makeGetReadingsInTimeframe();

  return function (state, props) {
    return {
      filteredReadings: getFilteredReadings(state, props),
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      patientTargets: state.settings.get('patientTargets'),
      readings: state.readings,
      readingsActive: state.readings.get('isActive'),
      settings: state.settings,
      timeframe: state.timeframe
    };
  };
}

export default connect(makeMapStateToProps, {
  clearSelectedReading,
  getPatientTargets,
  getRangeOfReadings,
  getReadingFood,
  postReadingTag,
  putReadingTag
})(LogBookContainer);
