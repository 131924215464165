import PropTypes from 'prop-types';
import React from 'react';
import BarChartSVG from '../components/svg/BarChartSVG';
import { connect } from 'react-redux';
import { setDevicePerDataType } from '../actions/validicActions';
import HealthDeviceWidget from '../components/HealthDeviceWidget';
import {
  getFoodsCacheDeviceList,
  makeGetCarbsDevice,
  makeGroupedCarbsConsumedInTimeframe
} from '../selectors/healthDataSelectors';
import FoodIcon from '../components/icons/FoodIcon';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  carbsTotals: PropTypes.object,
  carbsDevice: PropTypes.object,
  carbsDevices: PropTypes.array,
  isSharingCircleView: PropTypes.bool,
  setDevicePerDataType: PropTypes.func.isRequired
};

function WidgetCarbsContainer({
  carbsTotals,
  carbsDevice,
  carbsDevices,
  isSharingCircleView,
  setDevicePerDataType: switchDevice
}) {
  return (
    <HealthDeviceWidget
      Chart={BarChartSVG}
      data={carbsTotals}
      dataCollectionType="carbs"
      dataType="carbohydrates"
      device={carbsDevice}
      Icon={FoodIcon}
      isSharingCircleView={isSharingCircleView}
      sources={carbsDevices}
      switchDevice={switchDevice}
      title="AVG Carbohydrates"
    />
  );
}

export function makeMapStateToProps() {
  const getCarbsInTimeframe = makeGroupedCarbsConsumedInTimeframe();
  const getCarbsDevice = makeGetCarbsDevice();

  return function (state, props) {
    const carbsDevice = getCarbsDevice(state, props);
    const carbsTotals = getCarbsInTimeframe(state, props);
    const carbsDevices = getFoodsCacheDeviceList(state);

    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      carbsDevices,
      carbsDevice,
      carbsTotals
    };
  };
}

export default connect(makeMapStateToProps, {
  setDevicePerDataType
})(immutableToJsHOC(WidgetCarbsContainer));

WidgetCarbsContainer.propTypes = propTypes;
