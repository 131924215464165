import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import debounce from 'lodash/debounce';
import { returnQueryString, returnUrlQueryIfSingleItemOnNotFirstPage } from '../utils/urlQueryHelpers';

// This hook will trigger "fetchItems" callback on url "GET" params change
const useFetchDataHook = (fetchItems) => {
  const [loading, setLoading] = useState(false);
  const pendingRequests = useRef(0); // Use a ref to track pending requests
  const history = useHistory();
  const { search, pathname } = useLocation();
  const parsedQuery = parse(search);

  const updateURLParamsAndRerouteIfSingleItem = (urlQuery) => {
    const modifiedQuery = returnUrlQueryIfSingleItemOnNotFirstPage(urlQuery);
    history.push(`${pathname}?${stringify(modifiedQuery)}`);
  };

  const fetch = async (urlQuery) => {
    pendingRequests.current++;
    setLoading(true);

    const aggregatedQuery = returnQueryString(urlQuery);
    await fetchItems(aggregatedQuery);

    pendingRequests.current--;
    if (pendingRequests.current <= 0) {
      setLoading(false);
    }
  };

  const fetchNewItemsDebounce = useMemo(() => debounce(fetch, 600), []);

  /**
   * @type - has 2 values "edit and update", "edit" for deletion,
   * where "update" for changing item state
   * @isSingleItem - if single item in table we just redirect. Used if we remove
   * last item in table
   */
  const memoizedFetch = useCallback((type = 'update', isSingleItem = false) => {
    // We should handle here case if there is 1 single item in page which is not
    // on first page. So we need to decrement page number in url.
    if (isSingleItem && type === 'edit') {
      updateURLParamsAndRerouteIfSingleItem(parsedQuery);
      return;
    }

    fetchNewItemsDebounce(parsedQuery);
  }, [search]); // use "search" since it is string

  /*
   * We here listen to url params change, and fetch data if some params are changed.
   * And skipp API call on first render
   */
  useEffect(() => {
    memoizedFetch();
  }, [search]);

  useEffect(() => {
    return () => fetchNewItemsDebounce.cancel();
  }, []);

  return {
    memoizedFetch,
    loading
  };
};

export default useFetchDataHook;
