/* istanbul ignore file */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Actions
import {
  getNutritionixAutocomplete,
  setNutritionixQuery,
  clearNutritionix
} from '../actions/nutritionixActions';
// Components
import { immutableToJsHOC } from '../containers/ImmutableToJsHOC';
import FoodSearch from '../components/logs/FoodSearch';

/* istanbul ignore next */
function mapStateToProps(state) {
  const selectedId = state.readings.get('selectedId');

  return {
    nutritionix: state.nutritionix,
    selectedReading: state.readings.getIn(['cache', selectedId])
  };
}

export default connect(mapStateToProps, {
  getNutritionixAutocomplete,
  setNutritionixQuery,
  clearNutritionix
})(withRouter(immutableToJsHOC(FoodSearch)));
