import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function WaterIcon({ className }) {
  let classes = 'c-icon c-icon--water';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <g fill="none" transform="translate(5 2)">
        <path transform="translate(0 4.19)" className="c-icon__fill c-icon__fill--alt" d="M9.9928,14.4043 C9.9868,14.4423 9.9318,14.4893 9.8928,14.4893 L3.9438,14.4893 C3.9048,14.4893 3.8508,14.4423 3.8448,14.4043 L2.5708,6.2213 C2.6758,6.1853 2.8338,6.1533 3.0678,6.1493 L3.0698,6.1493 L3.0728,6.1493 L3.1138,6.1493 C3.4828,6.1433 3.6748,6.2433 3.8778,6.3483 L3.9038,6.3623 C4.1518,6.4873 4.4338,6.6293 5.0078,6.6383 C5.5608,6.6293 5.8418,6.4873 6.0918,6.3623 L6.1268,6.3433 C6.3068,6.2503 6.5078,6.1413 6.8898,6.1493 L6.9148,6.1483 C6.9268,6.1493 6.9368,6.1473 6.9528,6.1493 C7.3238,6.1433 7.5138,6.2433 7.7168,6.3483 L7.7438,6.3623 C7.9918,6.4873 8.2738,6.6293 8.8468,6.6383 C9.4008,6.6293 9.6818,6.4873 9.9308,6.3623 L9.9668,6.3433 C10.1648,6.2403 10.3528,6.1433 10.7288,6.1493 L10.7468,6.1493 L10.7748,6.1493 C11.0068,6.1533 11.1628,6.1853 11.2658,6.2213 L9.9928,14.4043 Z M13.5228,0.9523 C13.3508,0.7513 13.0958,0.6343 12.8208,0.6343 L9.6158,0.6343 C9.6568,1.4073 9.4148,2.1323 8.9168,2.6883 C8.3898,3.2773 7.6488,3.6033 6.8198,3.6083 C5.9988,3.6033 5.2568,3.2773 4.7308,2.6893 C4.2328,2.1323 3.9898,1.4073 4.0318,0.6343 L0.9158,0.6343 C0.6418,0.6343 0.3858,0.7513 0.2138,0.9523 C0.0418,1.1543 -0.0312,1.4253 0.0128,1.6963 L2.1958,15.1133 C2.2768,15.6103 2.7348,16.0003 3.2388,16.0003 L10.4978,16.0003 C11.0018,16.0003 11.4598,15.6103 11.5408,15.1133 L13.7228,1.6963 C13.7668,1.4253 13.6938,1.1543 13.5228,0.9523 L13.5228,0.9523 Z" />
        <path className="c-icon__fill c-icon__fill--blue" d="M4.7355,5.6799 C4.8495,6.0459 5.0555,6.3689 5.3295,6.6209 C5.4385,6.7219 5.5595,6.8109 5.6895,6.8869 C5.8195,6.9639 5.9595,7.0279 6.1075,7.0759 C6.2555,7.1239 6.4105,7.1589 6.5735,7.1759 C6.6555,7.1849 6.7385,7.1899 6.8225,7.1909 L6.8235,7.1909 L6.8235,7.1909 C6.9085,7.1899 6.9915,7.1849 7.0735,7.1759 C7.2355,7.1589 7.3925,7.1239 7.5405,7.0759 C7.6885,7.0279 7.8275,6.9639 7.9575,6.8869 C8.0875,6.8109 8.2085,6.7219 8.3185,6.6209 C8.5925,6.3689 8.7975,6.0459 8.9115,5.6799 C8.9575,5.5329 8.9885,5.3789 9.0045,5.2189 C9.0125,5.1399 9.0155,5.0589 9.0155,4.9759 C9.0155,4.9269 9.0105,4.8749 9.0065,4.8259 C9.0045,4.7919 9.0045,4.7599 9.0015,4.7269 C8.9785,4.5099 8.9315,4.3089 8.8655,4.1199 C8.8005,3.9309 8.7175,3.7529 8.6205,3.5809 C8.5255,3.4089 8.4165,3.2419 8.3025,3.0769 C7.7285,2.2519 6.9915,1.4449 6.8235,0.0059 L6.8235,0.0019 L6.8225,-0.0001 L6.8225,0.0059 C6.5875,1.6869 5.7345,2.4679 5.1635,3.3559 C5.0075,3.5979 4.8735,3.8479 4.7785,4.1259 C4.7145,4.3119 4.6685,4.5099 4.6455,4.7269 C4.6425,4.7599 4.6425,4.7919 4.6405,4.8259 C4.6365,4.8749 4.6315,4.9269 4.6315,4.9759 C4.6315,5.0589 4.6345,5.1399 4.6425,5.2189 C4.6585,5.3789 4.6895,5.5329 4.7355,5.6799" />
      </g>
    </svg>
  );
}

WaterIcon.propTypes = propTypes;

export default WaterIcon;
