import PropTypes from 'prop-types';
import React from 'react';
import BlankAvatar from './svg/BlankAvatar';

const propTypes = {
  avatarUrl: PropTypes.string,
  classNames: PropTypes.string
};

export default function AvatarPresenter({ avatarUrl, classNames }) {
  return (
    <div className={`c-avatar ${classNames}`}>
      {!avatarUrl ?
        <BlankAvatar /> :
        <img data-testid="avatarImg" className="c-avatar__image jest_avatar" src={avatarUrl} alt="avatar" />
      }
    </div>
  );
}

AvatarPresenter.propTypes = propTypes;
