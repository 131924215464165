/* istanbul ignore file */
import { connect } from 'react-redux';

// Actions
import {
  addFavoriteFood,
  removeFavoriteFood,
  getFavoriteFoods,
  getRecentFoods,
  getReadingFoods
} from '../actions/readingFoodsActions';
// Components
import FavoriteFoodIcon from '../components/FavoriteFoodIcon';

export default connect(null, {
  addFavoriteFood,
  removeFavoriteFood,
  getFavoriteFoods,
  getRecentFoods,
  getReadingFoods
})(FavoriteFoodIcon);
