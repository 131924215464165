import actionTypes from '../constants/actionTypes';

export function setSelectedPatient(patient) {
  return {
    type: actionTypes.SET_SELECTED_PATIENT,
    payload: patient
  };
}

export function clearSelectedPatient() {
  return { type: actionTypes.CLEAR_SELECTED_PATIENT };
}
