/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { arc } from 'd3-shape';

const propTypes = {
  fill: PropTypes.string,
  innerRadius: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outerRadius: PropTypes.number,
  padAngle: PropTypes.number,
  sv: PropTypes.shape({
    color: PropTypes.shape({
      primaryColor: PropTypes.string
    }),
    fontSize: PropTypes.shape({
      lg: PropTypes.number
    }),
    fontWeight: PropTypes.shape({
      bold: PropTypes.number
    }),
    helveticaBold: PropTypes.string,
    spacing: PropTypes.shape({
      md: PropTypes.number
    })
  }),
  value: PropTypes.shape({
    data: PropTypes.number,
    endAngle: PropTypes.number,
    index: PropTypes.number,
    padAngle: PropTypes.number,
    startAngle: PropTypes.number,
    value: PropTypes.number
  })
};

function PieSlice(props) {
  const {
    fill,
    innerRadius = 0,
    label,
    outerRadius,
    padAngle,
    sv,
    value
  } = props;
  const labelr = outerRadius + sv.spacing.md;
  const sliceArc = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .padAngle(padAngle);
  const c = sliceArc.centroid(value);
  const x = c[0];
  const y = c[1];
  const h = Math.sqrt((x * x) + (y * y));

  return (
    <g>
      <path
        d={sliceArc(value)}
        fill={fill}
      />
      <text
        transform={`translate(${x / h * labelr}, ${y / h * labelr})`}
        dy=".35em"
        textAnchor="middle"
        fill={sv.color.primaryColor}
        fontFamily={sv.helveticaBold}
        fontSize={sv.fontSize.lg}
        fontWeight={sv.fontWeight.bold}
      >
        {label}
      </text>
    </g>
  );
}

PieSlice.propTypes = propTypes;

export default PieSlice;
