import { connect } from 'react-redux';
import { invitePractice, getHcpInfo } from 'src/actions/contactsInvitesActions';
import ContactsHcpInfoView from 'src/pages/contacts/children/invites/hcp/ContactsHcpInfoView/ContactsHcpInfoView';
import { getIAmSharingWithInvitationsCount } from 'src/actions/contactsIAmSharingWithInvitesActions';
import { immutableToJsHOC } from './ImmutableToJsHOC';

function mapStateToProps(state) {
  return {
    hcpInfo: state.contactsInvites.get('hcpInfo'),
    isSearching: state.contactsInvites.get('isSearching')
  };
}

export default connect(mapStateToProps, {
  getHcpInfo,
  invitePractice,
  getIAmSharingWithInvitationsCount
})(immutableToJsHOC(ContactsHcpInfoView));
