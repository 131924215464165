/* istanbul ignore file */
import React from 'react';
import { Prompt } from 'react-router-dom';

function warnAboutUnsavedChangesNoForm(WrappedComponent) {
  class WarnAboutUnsavedChangesNoForm extends React.Component {
    state = {
      isChanged: false
    };

    preventRouteLeave = () => {
      this.setState({
        isChanged: true
      });
    }

    disablePreventRouteLeave = () => {
      this.setState({
        isChanged: false
      });
    }

    render() {
      const newProps = {
        disablePreventRouteLeave: this.disablePreventRouteLeave,
        preventRouteLeave: this.preventRouteLeave
      };

      return (
        <React.Fragment>
          <Prompt when={this.state.isChanged} message="Leave with unsaved change?" />
          <WrappedComponent {...this.props} {...newProps} />
        </React.Fragment>
      );
    }
  }

  WarnAboutUnsavedChangesNoForm.displayName = `WarnAboutUnsavedChangesNoForm(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WarnAboutUnsavedChangesNoForm;
}

export default warnAboutUnsavedChangesNoForm;
