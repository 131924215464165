import PropTypes from 'prop-types';
import React from 'react';
import SleepIcon from 'src/assets/img/icons/sleepIcon.svg';
import StepsIcon from 'src/assets/img/icons/stepsIcon.svg';
import HydrationIcon from 'src/assets/img/icons/hydrationIcon.svg';
import CaloriesBurnedIcon from 'src/assets/img/icons/calories_burend_Icon.svg';
import WeightsIcon from 'src/assets/img/icons/weights_icon.svg';
import CaloriesIcon from 'src/assets/img/icons/calories_consumed_icon.svg';
import FoodIcon from 'src/assets/img/icons/food_icon.svg';
import Text from 'src/shared/Text/Text';
import { Grid } from '@material-ui/core';
import { useStyles } from './Tag.styles';

const propTypes = {
  text: PropTypes.string,
  type: PropTypes.string
};

export default function Tag({ text, type }) {
  const classes = useStyles();

  const types = {
    weight: WeightsIcon,
    carbohydrates: FoodIcon,
    caloriesConsumed: CaloriesIcon,
    steps: StepsIcon,
    sleep: SleepIcon,
    water: HydrationIcon,
    calories: CaloriesBurnedIcon
  };

  return (
    <div className={classes.container}>
      <Grid container justifyContent="flex-start" wrap="nowrap">
        <img src={types[type]} alt="logo" style={{ marginRight: '5px' }} />
        <Text fontSize={16} lineHeight={24} color="active" font="bold">
          {text}
        </Text>
      </Grid>
    </div>
  );
}

Tag.propTypes = propTypes;
