import PropTypes from 'prop-types';
import React from 'react';
import AvatarPresenter from 'src/components/AvatarPresenter';
import { useStyles } from './InvitationBar.styles';

const propTypes = {
  avatarList: PropTypes.array,
  content: PropTypes.node,
  inviteLink: PropTypes.node,
  type: PropTypes.string,
  color: PropTypes.oneOf(['green', 'blue'])
};

function InvitationBar(props) {
  const { avatarList, content, inviteLink, color } = props;
  const classes = useStyles();
  const classesList = `${classes.container} ${color === 'green' ? classes.green : classes.blue}`;

  return (
    <div className={`${classesList} invitationBar`}>
      {avatarList && (
        <div className={classes.avatarContainer}>
          {avatarList.map((avatarUrl, key) => (
            <span className="c-avatar c-avatar__list" key={key}>
              <AvatarPresenter avatarUrl={avatarUrl}/>
            </span>
          ))}
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.description}>
          {content}
        </div>
        <div className={classes.link}>
          {inviteLink}
        </div>
      </div>
    </div>
  );
}

InvitationBar.propTypes = propTypes;
export default InvitationBar;
