import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import closeIcon from 'src/assets/img/icons/close_dark.svg';
import { registrationAddPogoEvent } from 'src/services/countlyRegistrationTrackService';
import Text from 'src/shared/Text';
import { Grid } from '@material-ui/core';
import { useStyles } from './AddPogoMeter.styles';

const propTypes = {
  isModal: PropTypes.bool,
  onRequestClose: PropTypes.func
};

let mountTime = null;

const AddPogoMeter = ({ isModal, onRequestClose }) => {
  const classes = useStyles();

  // React.useEffect is used since just useEffect can not be mocked
  React.useEffect(() => {
    mountTime = dayjs();
    return () => {
      mountTime = null;
    };
  }, []);

  return (
    <Grid data-testid="add-pogo-view" container className={isModal ? classes.container : classes.containerModal}>
      <Grid item xs={12}>
        <Grid container className={classes.header} justifyContent="center">
          <Text data-testid={isModal ? 'isModal' : 'isNotModal'} fontSize={20} lineHeight={24} font="bold" color="body" alignText="center">
            Add POGO Automatic<sup>&reg;</sup>
          </Text>
        </Grid>
        {isModal && (
          <Grid item className={classes.close} onClick={onRequestClose}>
            <img src={closeIcon} alt="close"/>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} className={classes.description}>
        <Text fontSize={16} lineHeight={18} color="body" alignText="center">
          Adding your POGO Automatic<sup>&reg;</sup> to <strong>Patterns<sup>&reg;</sup></strong> allows you to
          track your readings over time.
        </Text>
      </Grid>

      <Grid item xs={12} className={classes.listWrapper}>
        <ol>
          <li className={classes.list}>
            Download and Install the <a href={process.env.WINDOWS_USB_UPLOADER} onClick={registrationAddPogoEvent(mountTime)}>Windows</a>, or <a href={process.env.OSX_USB_UPLOADER} onClick={registrationAddPogoEvent(mountTime)}>Mac</a> <strong>Patterns<sup>&reg;</sup></strong> Uploader
          </li>
          <li className={classes.list}>
            Connect your POGO Automatic<sup>&reg;</sup> to your computer with a standard micro USB cable
          </li>
          <li className={classes.list}>
            Start the <strong>Patterns<sup>&reg;</sup></strong> Uploader and Sign In to your <strong>Patterns<sup>&reg;</sup></strong> account to complete the connection process
          </li>
        </ol>
      </Grid>

      <Grid item xs={12} justifyContent="center" className={classes.button}>
        <Link className="c-button c-button--block c-button--hollow c-button--lg" to="/sharing-circle">
          Sharing Circle View
        </Link>
      </Grid>
    </Grid>
  );
};

AddPogoMeter.propTypes = propTypes;

export default AddPogoMeter;
