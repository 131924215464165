export const PAGE_NAMES = {
  ABOUT_ME: 'About Me',
  CARE_CIRCLE: 'Sharing Circle',
  // Contacts
  CONTACTS_SHARED_WITH_ME: 'Shared With Me',
  CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS: 'Shared With Me / Received Invitations',
  CONTACTS_I_AM_SHARING_WITH: 'I Am Sharing With',
  CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS: 'I Am Sharing With / Sent Invitations',
  CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS: 'I Am Sharing With / Received Invitations',
  CONTACTS_EC: 'Emergency',
  CONTACTS_EC_EDIT: 'Edit - Emergency',
  CONTACTS_EC_NEW: 'Add - Emergency',
  CONTACTS_HCP: 'HC Providers',
  CONTACTS_HCP_EDIT: 'Edit - HC Providers',
  CONTACTS_PRACTICE_EDIT: 'Edit - Practice',
  CONTACTS_HCP_NEW: 'Add - HC Providers',
  CONTACTS_SC: 'My Sharing Circle',
  CONTACTS_SC_EDIT: 'Edit - My Sharing Circle',
  CONTACTS_SC_NEW: 'Add - My Sharing Circle',
  DASHBOARD: 'Dashboard',
  GLUCOSE_TARGETS: 'Glucose Targets',
  HEALTHFEED: 'Healthfeed',
  HELP: 'Help',
  LOGIN: 'Login',
  LOG_BOOK: 'Log Book',
  LOG_BOOK_TIMING: 'Timing',
  LOG_BOOK_CARBS: 'Carbohydrates',
  LOG_BOOK_MOOD: 'Mood/Health',
  LOG_BOOK_INSULIN: 'Insulin',
  LOG_BOOK_HYDRATION: 'Hydration',
  LOG_BOOK_COMMENT: 'Comments',
  MARKETPLACE: 'Connect Other Devices & Apps',
  MEDICATIONS: 'Medications',
  METERS: 'POGO Automatic',
  NOTIFICATIONS: 'Notifications',
  REGISTRATION: 'Registration',
  REGISTRATION_ABOUT_ME: 'Registration - About Me',
  REGISTRATION_ADD_POGO: 'Registration - Add Meter',
  TESTING_SCHEDULE: 'Testing Schedule',
  TRENDS: 'Trends'
};
