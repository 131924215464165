import { connect } from 'react-redux';

import TestingAdjustment from '../components/TestingAdjustment';
import {
  postPatientTesting,
  deletePatientTesting,
  getPatientTesting,
  putPatientTesting
} from '../actions/settingsActions';

export function mapStateToProps(state, ownProps) {
  const testingTimeId = Number(ownProps.match.params.id) || undefined;
  const testingTimeImmutable = state.settings.get('testingTimes').find(t => t.get('id') === testingTimeId);

  return {
    testingTimeId,
    initialValues: {
      enabled: testingTimeImmutable ? testingTimeImmutable.get('enabled') : true,
      endTime: testingTimeImmutable ? testingTimeImmutable.get('endTime') : undefined,
      label: testingTimeImmutable ? testingTimeImmutable.get('label') : undefined,
      startTime: testingTimeImmutable ? testingTimeImmutable.get('startTime') : undefined
    }
  };
}

const TestingAdjustmentContainer = connect(mapStateToProps, {
  postPatientTesting,
  deletePatientTesting,
  getPatientTesting,
  putPatientTesting
})(TestingAdjustment);

TestingAdjustmentContainer.displayName = 'TestingAdjustmentContainer';
export default TestingAdjustmentContainer;
