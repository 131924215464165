import React from 'react';
// Icons
import CaloriesBurnedIcon from '../components/icons/CaloriesBurnedIcon';
import FoodIcon from '../components/icons/FoodIcon';
import CaloriesIcon from '../components/icons/CaloriesIcon';
import SleepsIcon from '../components/icons/SleepsIcon';
import StepsIcon from '../components/icons/StepsIcon';
import WeightsIcon from '../components/icons/WeightsIcon';
import WaterIcon from '../components/icons/WaterIcon';

export const healthMenuIconsCollection = {
  steps: React.createElement(StepsIcon),
  carbs: React.createElement(FoodIcon),
  weights: React.createElement(WeightsIcon),
  caloriesBurned: React.createElement(CaloriesBurnedIcon),
  foods: React.createElement(CaloriesIcon),
  sleeps: React.createElement(SleepsIcon),
  water: React.createElement(WaterIcon)
};
