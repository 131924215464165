import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { parse } from 'query-string';
import apiService from 'src/services/apiService';
import { Grid } from '@material-ui/core';
import Text from 'src/shared/Text';
import Divider from 'src/shared/Divider';
import TokenViewWrapper from 'src/components/TokenViewWrapper';
import { useStyles } from './EmergencyContactAcceptDecline.styles';

const AUTH_URL = process.env.AUTH_URL || '';

export default function EmergencyContactAcceptDecline() {
  const history = useHistory();
  const { token } = useParams();
  const location = useLocation();
  const { status } = parse(location.search);

  const [showConfirm, setShowConfirm] = React.useState(status === 'decline');
  const [message, setMessage] = React.useState('Please Wait...');
  const classes = useStyles();

  const acceptInvite = async () => {
    try {
      const { data } = await apiService.get(`${AUTH_URL}/v2/sharing/${token}`);
      setMessage(data?.success);
    } catch (e) {
      setMessage(e?.response?.data?.error);
    }
  };

  const declineInvite = async () => {
    try {
      await apiService.delete(`${AUTH_URL}/sharing/${token}`);
      setMessage('Your contact has been notified that you have declined the request to view their data.');
    } catch (e) {
      setMessage(e?.response?.data?.error);
    }
    setShowConfirm(false);
  };

  React.useEffect(() => {
    status === 'accept' && acceptInvite();
    status === 'decline' && setMessage('Are you sure you want to decline this patient’s request to receive notifications when this patient has logged an emergency-level glucose reading?');
    status !== 'accept' && status !== 'decline' && history.push('/welcome/sign-in');
  }, [status]);

  return (
    <TokenViewWrapper>
      <Grid item xs={12} className={classes.header}>
        <Text fontSize={20} lineHeight={24} font="bold" alignText="left">
          Emergency Contact Invitation
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Text fontSize={16} lineHeight={22} alignText="left">{message}</Text>
      </Grid>

      <Divider vPadding="16"/>

      {!showConfirm && (
        <Grid container justifyContent="center" direction="column">
          <Grid item xs={12}>
            <Link to="/welcome/sign-in" className="c-button c-button--block c-button--active">
              Home
            </Link>
          </Grid>
        </Grid>
      )}

      {showConfirm && (
        <Grid container justifyContent="center" direction="column">
          <Grid item xs={12}>
            <button data-testid="jest-decline-btn" onClick={declineInvite} className="c-button c-button--block c-button--danger">
              Decline
            </button>
          </Grid>
        </Grid>
      )}
    </TokenViewWrapper>
  );
}
