import React from 'react';

export default function NeutralMoodIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path id="a" d="M23 1v22H1V1z"/>
        <path id="c" d="M23 1v22H1V1z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a"/>
        </mask>
        <path fill="#282829" d="M14.742 11.37c-.69 0-1.253-.851-1.253-1.899 0-1.047.562-1.9 1.253-1.9.692 0 1.255.853 1.255 1.9 0 1.048-.563 1.9-1.255 1.9m-2.695 5.495c-.78 0-4.363-.297-4.363-.664 0-.366 3.583-.664 4.363-.664.779 0 3.95.298 3.95.664 0 .367-3.171.664-3.95.664m-3.11-9.295c.692 0 1.255.853 1.255 1.9 0 1.048-.563 1.9-1.254 1.9-.691 0-1.254-.852-1.254-1.9 0-1.047.563-1.9 1.254-1.9M12 1C5.934 1 1 5.934 1 12c0 6.064 4.934 11 11 11s11-4.936 11-11c0-6.066-4.934-11-11-11" mask="url(#b)"/>
        <mask id="d" fill="#fff">
          <use xlinkHref="#c"/>
        </mask>
        <path fill="#282829" d="M14.742 11.37c-.69 0-1.253-.851-1.253-1.899 0-1.047.562-1.9 1.253-1.9.692 0 1.255.853 1.255 1.9 0 1.048-.563 1.9-1.255 1.9m-2.695 5.495c-.78 0-4.363-.297-4.363-.664 0-.366 3.583-.664 4.363-.664.779 0 3.95.298 3.95.664 0 .367-3.171.664-3.95.664m-3.11-9.295c.692 0 1.255.853 1.255 1.9 0 1.048-.563 1.9-1.254 1.9-.691 0-1.254-.852-1.254-1.9 0-1.047.563-1.9 1.254-1.9M12 1C5.934 1 1 5.934 1 12c0 6.064 4.934 11 11 11s11-4.936 11-11c0-6.066-4.934-11-11-11" mask="url(#d)"/>
      </g>
    </svg>
  );
}
