import PropTypes from 'prop-types';
import React from 'react';
import sortBy from 'lodash/sortBy';
import LogRow from './LogRow';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const propTypes = {
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  patientTargets: PropTypes.object,
  readingRouteId: PropTypes.string
};

function LogDay(props) {
  const {
    label,
    list,
    patientTargets,
    readingRouteId
  } = props;

  const sortedList = sortBy(list, reading => dayjs(reading.timestamp)).reverse();

  return (
    <div className="c-card h-overflow--visible">
      <div className="c-card__header c-card__header--xs c-card__header--borderless c-card__header--spaced">
        <h3 className="c-card__title">{label}</h3>
        <span>mg/dL</span>
      </div>
      <ul className="c-log-list">
        {sortedList.map((reading) => {
          if (!reading.id) { return null; }

          return (
            <LogRow
              key={reading.id}
              patientTargets={patientTargets}
              reading={reading}
              readingRouteId={readingRouteId}
            />
          );
        })}
      </ul>
    </div>
  );
}

LogDay.propTypes = propTypes;

export default LogDay;
