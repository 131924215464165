import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function LunchIcon({ className }) {
  let classes = 'c-icon c-icon--food';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <path
        className="c-icon__fill c-icon__fill--dark"
        d="M10.0800357,18.2678368 C9.89707607,17.7353001 9.33662481,17.4961181 8.82501481,17.735112 L4.67644537,19.6730787 C4.16627609,19.9113997 3.95238987,19.6958866 4.20027627,19.1885255 L6.21035031,15.0744101 C6.45753865,14.5684778 6.23036699,14.0030517 5.69960982,13.8102831 L1.39577886,12.2471509 C0.866516339,12.0549251 0.867666716,11.7512939 1.40170732,11.5678176 L5.7321632,10.0800357 C6.26469992,9.89707607 6.50388193,9.33662481 6.26488795,8.82501481 L4.32692128,4.67644537 C4.08860032,4.16627609 4.30411342,3.95238987 4.81147448,4.20027627 L8.92558989,6.21035031 C9.43152219,6.45753865 9.9969483,6.23036699 10.1897169,5.69960982 L11.7528491,1.39577886 C11.9450749,0.866516339 12.2487061,0.867666716 12.4321824,1.40170732 L13.9199643,5.7321632 C14.1029239,6.26469992 14.6633752,6.50388193 15.1749852,6.26488795 L19.3235546,4.32692128 C19.8337239,4.08860032 20.0476101,4.30411342 19.7997237,4.81147448 L17.7896497,8.92558989 C17.5424613,9.43152219 17.769633,9.9969483 18.3003902,10.1897169 L22.6042211,11.7528491 C23.1334837,11.9450749 23.1323333,12.2487061 22.5982927,12.4321824 L18.2678368,13.9199643 C17.7353001,14.1029239 17.4961181,14.6633752 17.735112,15.1749852 L19.6730787,19.3235546 C19.9113997,19.8337239 19.6958866,20.0476101 19.1885255,19.7997237 L15.0744101,17.7896497 C14.5684778,17.5424613 14.0030517,17.769633 13.8102831,18.3003902 L12.2471509,22.6042211 C12.0549251,23.1334837 11.7512939,23.1323333 11.5678176,22.5982927 L10.0800357,18.2678368 Z"
      />
    </svg>
  );
}

LunchIcon.propTypes = propTypes;

export default LunchIcon;
