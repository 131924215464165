import React from 'react';

export default function SearchIcon() {
  return (
    <svg className="c-field__icon" width="24" height="24" viewBox="0 0 24 24" transform="scale(0.75)">
      <g>
        <path d="M9 17C4.581722 17 1 13.418278 1 9 1 4.581722 4.581722 1 9 1 13.418278 1 17 4.581722 17 9 17 13.418278 13.418278 17 9 17zM9 15C12.3137085 15 15 12.3137085 15 9 15 5.6862915 12.3137085 3 9 3 5.6862915 3 3 5.6862915 3 9 3 12.3137085 5.6862915 15 9 15zM15.2928932 16.7071068L20.7928932 22.2071068C21.1834175 22.5976311 21.8165825 22.5976311 22.2071068 22.2071068 22.5976311 21.8165825 22.5976311 21.1834175 22.2071068 20.7928932L16.7071068 15.2928932C16.3165825 14.9023689 15.6834175 14.9023689 15.2928932 15.2928932 14.9023689 15.6834175 14.9023689 16.3165825 15.2928932 16.7071068z" />
      </g>
    </svg>
  );
}
