export function readingFormat(params) {
  const formattedParams = [];
  let returnString = '';

  if (params && params.fullTags) {
    formattedParams.push('full_tags=show');
  }

  if (params && params.tags) {
    formattedParams.push('tags=show');
  }

  if (params && params.targets) {
    formattedParams.push('targets=show');
  }

  if (params && params.foods) {
    formattedParams.push('foods=show');
  }

  if (formattedParams.length) {
    returnString += '?';
    returnString += formattedParams.join('&');
  }

  return returnString;
}
