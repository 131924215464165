import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'left',
    width: '100%',
    paddingTop: '18px',
    paddingBottom: '5px',
    fontSize: '18Px',
    lineHeight: '1.222rem'
  },
  fullWidth: {
    width: '100%'
  },
  formControlLabel: {
    paddingRight: '1rem'
  },
  searchResults: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  dialog: {
    height: '35vh'
  }
}));
