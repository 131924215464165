import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';

/**
 * This hooks handles search input logic and modifies url get params
 * @returns {{
 * handleSearch: handleSearch,
 * parsedQuery: parsedQuery,
 * }}
 */
export default function useSearchHook() {
  const history = useHistory();
  const { search } = useLocation();
  const parsedQuery = parse(search, { arrayFormat: 'bracket' });

  const searchBy = (e) => {
    const queryVal = e.target.value;
    let getParams = { ...parsedQuery, q: queryVal, perPage: 5,  page: 0 };

    // Clear page related params
    if (!queryVal) {
      // eslint-disable-next-line no-unused-vars
      const { perPage, page, q, ...rest } = parsedQuery;
      getParams = { ...rest };
    }

    const url = stringify(getParams, { arrayFormat: 'bracket' });
    history.replace({ search: url });
  };

  const handleSearch = useCallback(searchBy, [parsedQuery]);

  return { parsedQuery, handleSearch };
}
