import axios from 'axios';
import { refreshAccessToken, reduxSignOut } from 'src/actions/authenticationActions';
import storeService from './store';
import { openSnackbar } from 'src/actions/snackbarActions';

export const API_URL = process.env.API_URL || '';

let refreshingPromise = null;

export async function addAuthenticationHeaders(config) {
  const store = storeService.getStore();
  const state = store.getState();

  const headers = state.authentication.get('headers');
  if (headers && headers.has('Authorization')) {
    config.headers.common.Authorization = `Bearer ${headers.get(
      'Authorization'
    )}`;
  }

  return config;
}

export async function invalidateHeaders(error) {
  const response = error.response;
  const status = response && response.status;
  const store = storeService.getStore();

  // If we get a 401
  if (status === 401) {
    // Start the process of refreshing the token
    // read: set the appropriate flags on the reducer so subsequent requests
    // will be caught above in the request interceptor
    // Get the refresh token
    const refreshToken = store.getState().authentication.get('refreshToken');
    // Send the actual refresh token request
    // And set our local var refreshingPromise to this, so all requests can
    // use the same promise
    // Then return the promise here, so the response back to the original
    // action that called the result will wait
    if (!refreshingPromise) {
      refreshingPromise = store.dispatch(refreshAccessToken(refreshToken));
    }

    try {
      const authInfo = await refreshingPromise;
      // Use the new authorization token!
      error.config.headers.Authorization = `Bearer ${
        authInfo.headers.Authorization
      }`;
      refreshingPromise = null;
      // re-send the original request with new token
      return await axios(error.config);
    } catch (err) {
      // either the refresh or the retry failed so sign out
      store.dispatch(reduxSignOut());
      window.location.assign('/#/welcome/sign-in');
      // "throw error" needed because otherwise "catch" in actions is not triggered
      // and f.e. loader in "ReadingDataWrapper" can not hide.
      throw error;
    }
  }

  // Otherwise throw the error
  throw error;
}

const scopedEndpoints = [
  'marketplace',
  'pghd',
  'readings',
  'report',
  'tags',
  'targets',
  'testing',
  'widget_sources'
];

export function scopeRequest(config) {
  const state = storeService.getStore().getState();
  const selectedPatientId = state.selectedPatient.get('patientId');
  const route = config.url;
  const apiVersion = config?.apiVersion || '';

  if (
    route &&
    selectedPatientId &&
    scopedEndpoints.some(endpoint => route.includes(endpoint))
  ) {
    config.url = `${apiVersion}/patient/${selectedPatientId}${route}`;
  } else {
    config.url = `${apiVersion}${route}`;
  }

  return config;
}

export function dispatchAvailableErrors(error) {
  // this error that comes back from the patterns api is a human-readable message
  const message = error?.response?.data?.error;
  const status = error?.response?.status;

  if (![404].includes(status) && message) {
    const store = storeService.getStore();
    store.dispatch(openSnackbar(message));
  }

  throw error;
}

export function forbiddenPatientData(error) {
  const response = error && error.response;
  const status = response && response.status;
  const store = storeService.getStore();

  if (status === 403 && response.data.error === 'forbidden') {
    store.dispatch(openSnackbar('This patient has removed your access'));
    window.location.href = '/#/';
  }

  throw error;
}

export function init() {
  const api = axios.create({
    baseURL: API_URL
  });

  api.interceptors.request.use(scopeRequest);
  api.interceptors.request.use(addAuthenticationHeaders);
  api.interceptors.response.use(res => res, invalidateHeaders);
  api.interceptors.response.use(res => res, forbiddenPatientData);
  api.interceptors.response.use(res => res, dispatchAvailableErrors);

  return api;
}

export default init();
