import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  name: {
    fontSize: '0.888rem',
    wordBreak: 'break-word'
  },
  buttonGroup: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: '50%',
    justifyContent: 'flex-end',

    '& > .c-button': {
      width: '50%',

      '&:last-child': {
        marginLeft: '8Px'
      }
    }
  }
}));
