/* istanbul ignore file */
import { connect } from 'react-redux';

import TerminateAccountButton from '../components/buttons/TerminateAccountButton';
import { terminateAccount, getTerminationDetails } from '../actions/accountManagementActions';

export default connect(null, {
  terminateAccount,
  getTerminationDetails
})(TerminateAccountButton);
