/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useStyles } from './Text.styles';

const propTypes = {
  alignText: PropTypes.oneOf(['center', 'right', 'left']),
  children: PropTypes.node,
  color: PropTypes.oneOf(['body', 'secondary', 'body-light', 'error', 'active', 'lime', 'yellow']),
  background: PropTypes.oneOf(['error', undefined]),
  className: PropTypes.string,
  font: PropTypes.oneOf(['bold', 'regular']),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  whiteSpace: PropTypes.oneOf(['pre'])
};

export default function Text(props) {
  const { font, lineHeight, fontSize, color, background, alignText, className, children, whiteSpace } = props;
  const classes = useStyles();
  const classesNames = classNames(
    className,
    classes.default,
    classes[`font-${font || 'regular'}`],
    classes[`line-height-${lineHeight || 16}`],
    classes[`font-size-${fontSize || 14}`],
    classes[`color-${color || 'body'}`],
    classes[`background-${background || 'body'}`],
    classes[`align-text-${alignText || 'left'}`],
    classes[`white-space-${whiteSpace || 'initial'}`]
  );

  return (
    <div className={classesNames}>{children}</div>
  );
}

Text.propTypes = propTypes;
