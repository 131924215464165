import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function MinusIcon({ className }) {
  let classes = 'c-icon c-icon--minus';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <rect className="c-icon__fill c-icon__fill--alt" x="6" y="11" width="12" height="3" rx="1" />
    </svg>
  );
}

MinusIcon.propTypes = propTypes;

export default MinusIcon;
