import { connect } from 'react-redux';
import { inviteSharingCircleOrEmergencyContact } from '../actions/contactsInvitesActions';
import ContactsAddSharingCircleForm from '../pages/contacts/children/invites/sharingCircle/ContactsAddSharingCircleForm/ContactsAddSharingCircleForm';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import { getIAmSharingWithInvitationsCount } from '../actions/contactsIAmSharingWithInvitesActions';

export default connect(null, {
  inviteSharingCircleOrEmergencyContact,
  getIAmSharingWithInvitationsCount
})(immutableToJsHOC(ContactsAddSharingCircleForm));
