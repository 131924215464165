import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ContactsTypesFilter from 'src/components/contacts/ContactsTypesFilter/ContactsTypesFilter';
import SearchContacts from 'src/components/contacts/SearchContacts';
import routesConstants from 'src/constants/routes';
import useFetchDataHook from 'src/hooks/useFetchDataHook';
import ContactsSharedWithMeReceivedInvitesTable from 'src/pages/contacts/children/sharedWithMeReceivedInvites/ContactsSharedWithMeReceivedInvitesTable';
import Text from 'src/shared/Text';
import { Container, Grid } from '@material-ui/core';

const propTypes = {
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number,
  getSharedWithReceivedInvitations: PropTypes.func.isRequired
};

export default function SharingCircleSharedWithMeReceivedInvites({ getSharedWithReceivedInvitations, itemsList, itemsListCount = 0 }) {
  const { SHARING_CIRCLE_SHARED_WITH_ME } = routesConstants;
  const { memoizedFetch } = useFetchDataHook(getSharedWithReceivedInvitations);

  return (
    <Container style={{ padding: '0' }}>
      <Link to={SHARING_CIRCLE_SHARED_WITH_ME} className="h-c-primary" data-testid="link">
        <strong>{'<'} Back to People sharing with me</strong>
      </Link>

      <Grid container className="h-padded-top h-padded-bottom">
        <Text fontSize="24" lineHeight="32" font="bold">Received Invitations</Text>
      </Grid>

      <div className="l-contact-filter h-padded-bottom-lg">
        <ContactsTypesFilter skipType={['practices']}/>
        <SearchContacts/>
      </div>

      <ContactsSharedWithMeReceivedInvitesTable
        memoizedFetch={memoizedFetch}
        itemsList={itemsList}
        itemsListCount={itemsListCount}
      />
    </Container>
  );
}

SharingCircleSharedWithMeReceivedInvites.propTypes = propTypes;
