import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    flexGrow: 1,
    width: 'auto',
    zIndex: 20
  },
  tab: {
    borderBottom: `2PX solid ${theme.palette.text?.secondary}`,
    color: theme.palette.text?.main,
    fontFamily: 'Helvetica Neue, HelveticaNeue, Helvetica, Arial sans-serif',
    fontSize: '0.8rem',
    minHeight: '34Px',
    opacity: 1,
    '&:not(:last-child)': {
      marginRight: '0.5rem'
    },
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width: '50%'
    }
  },
  tabs: {
    alignItems: 'end',
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    minHeight: 0,
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2Px 2Px 0 0',
      borderBottom: `2PX solid ${theme.palette.primary.main}`,
      color: theme.palette.common.white,
      fontWeight: 'bold'
    }
  },
  left: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  right: {
    alignItems: 'baseline',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      paddingTop: '1rem',
      width: '100%'
    }
  },
  routeWrapper: {
    paddingTop: '32px'
  }
}));
