import React, { Fragment } from 'react';
import CountlyTrackedRoute from '../../countly/CountlyTrackedRoute';
import ContactsIAmSharingSentInvitationsContainer from '../../../containers/ContactsIAmSharingSentInvitesContainer';
import ContactsIAmSharingReceivedInvitationsContainer from '../../../containers/ContactsIAmSharingReceivedInvitesContainer';
import ContactsIAmSharingContainer from '../../../containers/ContactsIAmSharingContainer';
import { PAGE_NAMES } from '../../../constants/routesCountlyNameConstants';
import routesConstants from '../../../constants/routes';

export default function ContactsIAmSharingWithViewResolver() {
  const {
    CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS,
    CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS,
    CONTACTS_I_AM_SHARING_WITH
  } = routesConstants;
  const {
    CONTACTS_I_AM_SHARING_WITH: I_AM_SHARING_WITH,
    CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS: SENT_INVITATIONS,
    CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS: RECEIVED_INVITATIONS
  } = PAGE_NAMES;

  return (
    <Fragment>
      <CountlyTrackedRoute
        exact
        pageName={I_AM_SHARING_WITH}
        path={CONTACTS_I_AM_SHARING_WITH}
        component={ContactsIAmSharingContainer}
      />
      <CountlyTrackedRoute
        exact
        pageName={SENT_INVITATIONS}
        path={CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS}
        component={ContactsIAmSharingSentInvitationsContainer}
      />
      <CountlyTrackedRoute
        exact
        pageName={RECEIVED_INVITATIONS}
        path={CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS}
        component={ContactsIAmSharingReceivedInvitationsContainer}
      />
    </Fragment>
  );
}
