/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

import ShapeAfter from './ShapeAfter';
import ShapeBefore from './ShapeBefore';
import ShapeFasting from './ShapeFasting';
import ShapeSnack from './ShapeSnack';
import ShapeNoTag from './ShapeNoTag';

const propTypes = {
  inner: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }),
  isMobile: PropTypes.bool,
  p: PropTypes.object,
  sv: PropTypes.object,
  transform: PropTypes.string
};

function Legend({ inner, isMobile, sv, p, transform }) {
  const group = {
    center: (inner.width / 5) / 2 + sv.spacing.sm,
    width: inner.width / 5
  };
  const iconY = isMobile ? -sv.spacing.md : -(sv.spacing.md + 1);

  return (
    <g
      id="shape-legend"
      transform={transform}
      width={inner.width}
    >
      <g transform={`translate(${group.center}, 0)`}>
        <text color={sv.color.primaryColor} textAnchor="end" style={{ ...p }}>No Tag</text>
        <ShapeNoTag fill={sv.color.primaryColor} transform={`translate(${sv.spacing.xs}, ${iconY})`} />
      </g>
      <g transform={`translate(${group.width}, 0)`}  width={group.width}>
        <text color={sv.color.primaryColor} transform={`translate(${group.center}, 0)`} textAnchor="end" style={{ ...p }}>Fasting</text>
        <ShapeFasting color={sv.color.primaryColor} transform={`translate(${group.center + sv.spacing.xs}, ${iconY})`}/>
      </g>
      <g transform={`translate(${group.width * 2}, 0)`} width={group.width}>
        <text color={sv.color.primaryColor} textAnchor="end" transform={`translate(${group.center}, 0)`} style={{ ...p }}>Before</text>
        <ShapeBefore stroke={sv.color.primaryColor} transform={`translate(${group.center + sv.spacing.xs}, ${iconY})`} />
      </g>
      <g transform={`translate(${group.width * 3}, 0)`} width={group.width}>
        <text color={sv.color.primaryColor} textAnchor="end" transform={`translate(${group.center}, 0)`} style={{ ...p }}>After</text>
        <ShapeAfter fill={sv.color.primaryColor} transform={`translate(${group.center + sv.spacing.xs}, ${iconY})`} />
      </g>
      <g transform={`translate(${group.width * 4}, 0)`} width={group.width}>
        <text color={sv.color.primaryColor} textAnchor="end" transform={`translate(${group.center}, 0)`} style={{ ...p }}>Snack</text>
        <ShapeSnack fill={sv.color.primaryColor} transform={`translate(${group.center + sv.spacing.xs}, ${iconY})`} />
      </g>
    </g>
  );
}

Legend.propTypes = propTypes;

export default Legend;
