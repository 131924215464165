import React from 'react';

export default () => {
  return (
    <svg className="c-nav-tab__icon c-nav-tab__icon-trends c-icon" viewBox="0 0 24 20">
      <g transform="translate(-129.000000, -4284.000000)">
        <g transform="translate(0.000000, 4253.000000)">
          <g transform="translate(130.000000, 31.000000)">
            <polygon points="2 11.4992838 5.50078382 6.89954034 9.17523045 10.1242151 12.5681023 2.27777835 16.0260853 4.66934558 20 0 20 16 2 16" />
            <path className="c-icon__stroke" d="M-7.63278329e-17,19 L22,19" />
          </g>
        </g>
      </g>
    </svg>
  );
};

