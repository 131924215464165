/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import SearchBar from '../../../../../../components/SearchBar';

const propTypes = {
  placeholder: PropTypes.string,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func.isRequired
};

const HcpSearchBar = memo(({ searchQuery, setSearchQuery, placeholder }) => {
  const onChange = (e) => setSearchQuery(e.target.value);

  return (
    <SearchBar
      data-testid="searchBar"
      className="c-field h-full-width"
      onChange={onChange}
      placeholder={placeholder}
      value={searchQuery}
    />
  );
});

HcpSearchBar.propTypes = propTypes;
export default HcpSearchBar;
