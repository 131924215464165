import React from 'react';

export default () => {
  return (
    <svg className="c-nav-tab__icon c-nav-tab__icon-logbook c-icon" viewBox="0 0 15 20">
      <g transform="translate(-228.000000, -4284.000000)">
        <g transform="translate(0.000000, 4253.000000)">
          <path d="M235.083333,51 C238.99535,51 242.166667,47.8286836 242.166667,43.9166667 C242.166667,40.0046497 235.083333,31 235.083333,31 C235.083333,31 228,40.0046497 228,43.9166667 C228,47.8286836 231.171316,51 235.083333,51 Z" />
        </g>
      </g>
    </svg>
  );
};

