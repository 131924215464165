/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import ChevronIcon from '../icons/ChevronIcon';
import AvatarFieldContainer from 'src/containers/AvatarFieldContainer';
import AboutMeFormContainer from 'src/containers/AboutMeFormContainer';

export default function AboutMe() {
  return (
    <section className="c-section">
      <Link className="c-nav-header" to="/profile">
        <ChevronIcon />
        <h2 className="c-nav-header__label">About Me</h2>
      </Link>

      <AvatarFieldContainer />
      <AboutMeFormContainer />
    </section>
  );
}
