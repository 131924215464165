import { connect } from 'react-redux';
import { inviteSharingCircleOrEmergencyContact } from '../actions/contactsInvitesActions';
import ContactsEmergencyContactForm from '../pages/contacts/children/invites/emergencyContact/ContactsEmergencyContactForm/ContactsEmergencyContactForm';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import { getIAmSharingWithInvitationsCount } from '../actions/contactsIAmSharingWithInvitesActions';

export default connect(null, {
  inviteSharingCircleOrEmergencyContact,
  getIAmSharingWithInvitationsCount
})(immutableToJsHOC(ContactsEmergencyContactForm));
