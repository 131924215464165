import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import confirm from 'src/utils/ConfirmHelpers';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import validicService from 'src/services/validicService';
import ChevronIcon from 'src/components/icons/ChevronIcon';
import Text from 'src/shared/Text/Text';
import ChevronRight from 'src/assets/img/icons/chevron-right-dark.svg';
import Divider from 'src/shared/Divider/Divider';
import Disconnected from './childrens/Disconnected/Disconnected';
import Connected from './childrens/Connected/Connected';
import { useStyles } from './Marketplace.styles';

const propTypes = {
  connected: PropTypes.array,
  deleteMarketplaceDisconnectSource: PropTypes.func.isRequired,
  disconnected: PropTypes.array,
  syncOnServerStatus: PropTypes.bool,
  getMarketplace: PropTypes.func.isRequired,
  syncMHealthDeviceOnServer: PropTypes.func.isRequired
};

export default function Marketplace({
  getMarketplace,
  connected,
  disconnected,
  syncOnServerStatus,
  syncMHealthDeviceOnServer,
  deleteMarketplaceDisconnectSource
}) {
  const [device, setDevice] = useState(null);
  const classes = useStyles();

  const handleDisconnect = (source) => async () => {
    if (await confirm('Please confirm you want to remove this data source?', {
      headerText: 'Remove data source',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    })) {
      setDevice(null);
      await deleteMarketplaceDisconnectSource(source.disconnect_url);
      await getMarketplace();
    }
  };

  const handleConnect = (source) => async () => {
    try {
      const { data } = await validicService.getMarketplaceLink(`${source.connect_url}?redirectUrl=web`);
      window.open(data.authUrl);
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  // eslint-disable-next-line camelcase
  const handleSync = (source) => async () => {
    try {
      await syncMHealthDeviceOnServer(source.sync_url);
      await getMarketplace();
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  const handleSelect = (selectedDevice) => () => {
    setDevice(selectedDevice);
  };

  const handleBack = () => {
    setDevice(null);
  };

  useEffect(() => {
    getMarketplace();
  }, []);

  if (device && device.connected === false) {
    return (
      <Disconnected
        device={disconnected.find(el => el.type === device.type) || {}}
        handleBack={handleBack}
        handleConnect={handleConnect}
      />
    );
  }

  if (device && device.connected === true) {
    return (
      <Connected
        device={connected.find(el => el.type === device.type) || {}}
        handleSync={handleSync}
        syncOnServerStatus={syncOnServerStatus}
        handleBack={handleBack}
        handleDisconnect={handleDisconnect}
      />
    );
  }

  return (
    <Container className={classes.container}>
      <Grid container>
        <Link className="c-nav-header" to="/profile">
          <ChevronIcon />
          <h2 className="c-nav-header__label">Other Devices & Apps</h2>
        </Link>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Text fontSize={14} lineHeight={16}>
              Connect your health devices via applications like Fitbit and Google Fit, to track your activity, nutrition, weight, and sleep data in <strong>Patterns<sup>&reg;</sup></strong>.
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.blockWrapper}>
        <Grid item xs={12}>
          <Grid container className={classes.blockContainer}>
            <Grid item xs={12} className={classes.item}>
              <Text fontSize={16} lineHeight={24} color="gray-dark" font="bold">
                Health Devices and Applications
              </Text>
            </Grid>

            {connected.map((deviceItem, index) => {
              return (
                <Fragment key={index}>
                  <Divider/>
                  <Grid data-testid="connected" item key={index} xs={12} className={classes.item}>
                    <Grid
                      container
                      wrap="nowrap"
                      data-testid="connectLink"
                      justifyContent="space-between"
                      className="h-cursor__pointer"
                      onClick={handleSelect(deviceItem)}
                    >
                      <Grid item style={{ paddingRight: '8px' }} className={classes.itemBlock}>
                        <img src={deviceItem.logo_url} alt="logo" style={{ width: '32px', height: '32px' }}/>
                      </Grid>
                      <Grid item xs={10} className={classes.itemBlock}>
                        <Grid container justifyContent="space-between">
                          <Grid item className={classes.itemBlock}>
                            <Text fontSize={16} lineHeight={20} color="gray-dark" font="bold">
                              {deviceItem.display_name}
                            </Text>
                          </Grid>
                          <Grid item className={classes.itemBlock}>
                            <Text fontSize={16} lineHeight={20} color="lime" font="bold">
                              Connected
                            </Text>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.itemBlock}>
                        <img src={ChevronRight} alt="right"/>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}

            {disconnected.map((deviceItem, index) => {
              return (
                <Fragment key={index}>
                  <Divider/>
                  <Grid data-testid="disconnected" item key={index} xs={12} className={classes.item}>
                    <Grid
                      container
                      wrap="nowrap"
                      justifyContent="space-between"
                      className="h-cursor__pointer"
                      onClick={handleSelect(deviceItem)}
                    >
                      <Grid item style={{ paddingRight: '8px' }} className={classes.itemBlock}>
                        <img src={deviceItem.logo_url} alt="logo" style={{ width: '32px', height: '32px' }}/>
                      </Grid>
                      <Grid item xs={10} className={classes.itemBlock}>
                        <Text fontSize={16} lineHeight={20} color="gray-dark" font="bold">
                          {deviceItem.display_name}
                        </Text>
                      </Grid>
                      <Grid item className={classes.itemBlock}>
                        <img src={ChevronRight} alt="right"/>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

Marketplace.propTypes = propTypes;
