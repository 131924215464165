import PropTypes from 'prop-types';
import React from 'react';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import StarIcon from 'src/components/icons/Star';
import confirm from 'src/utils/ConfirmHelpers';

export class FavoriteFoodIcon extends React.Component {
  static propTypes = {
    addFavoriteFood: PropTypes.func.isRequired,
    favoriteFoodId: PropTypes.number,
    foodName: PropTypes.string,
    getFavoriteFoods: PropTypes.func.isRequired,
    getRecentFoods: PropTypes.func.isRequired,
    getReadingFoods: PropTypes.func.isRequired,
    isFavorite: PropTypes.bool,
    isVisible: PropTypes.bool,
    notEditable: PropTypes.bool,
    nixItemId: PropTypes.string,
    readingId: PropTypes.string,
    removeFavoriteFood: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.handleToggleThrottled = throttle(this.handleToggle, 1000);
  }

  componentWillUnmount() {
    this.handleToggleThrottled.cancel();
  }

  /*
  * Will add food to favorite list or remove
  */
  handleToggle = async () => {
    const {
      foodName,
      nixItemId,
      favoriteFoodId,
      isFavorite,
      addFavoriteFood,
      removeFavoriteFood
    } = this.props;

    const confirmOptions = {
      cancelText: 'No',
      confirmText: 'Yes'
    };

    if (isFavorite) {
      if (await confirm('Are you sure you want to remove the item from your favorites list?', confirmOptions)) {
        await removeFavoriteFood(favoriteFoodId);
        this.fetchAllFoods();
      }
    } else {
      await addFavoriteFood({ name: foodName, nix_item_id: nixItemId });
      this.fetchAllFoods();
    }
  }

  fetchAllFoods = () => {
    const {
      getFavoriteFoods,
      getRecentFoods,
      getReadingFoods,
      readingId
    } = this.props;

    getFavoriteFoods();
    getRecentFoods();
    getReadingFoods(readingId);
  }

  render() {
    if (!this.props.isVisible) {
      return null;
    }

    const classes = classNames(
      'h-bg-none',
      'h-padded-right',
      { 'h-cursor__pointer': !this.props.notEditable }
    );

    return (
      <button
        data-testid="button"
        className={classes}
        onClick={() => { !this.props.notEditable && this.handleToggleThrottled(); }}
      >
        <StarIcon isActive={this.props.isFavorite}/>
      </button>
    );
  }
}

export default FavoriteFoodIcon;
