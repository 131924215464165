import storeService from '../services/store';
import { parse } from 'query-string';
// Countly
import { countlyTrackEventualRegistrationFinish } from '../services/countlyRegistrationTrackService';

export async function requireAuth(location, replace) {
  const state = await storeService.getHydratedState();
  const token = state.authentication.getIn(['headers', 'Authorization']);

  if (!token) {
    const query = {
      ...parse(location.search),
      next: location.pathname
    };

    replace({
      query,
      pathname: '/welcome/sign-in'
    });

    return false;
  }

  return true;
}

export async function requireDataAccess(location, replace) {
  const state = await storeService.getHydratedState();
  const token = state.authentication.getIn(['headers', 'Authorization']);
  const isPatient = state.status.get('scopes').includes('patient');

  if (!token) {
    const query = parse(location.search);

    if (location.pathname !== '/') {
      query.next = location.pathname;
    }

    replace({ query, pathname: '/welcome/sign-in' });
    return false;
  } else if (!isPatient) {
    replace({ pathname: '/sharing-circle' });
  } else if (!state.status.get('isProfileComplete')) {
    // Track eventual finish registration
    countlyTrackEventualRegistrationFinish('About Me');
    replace({ pathname: '/onboarding/about-me' });
  } else if (!state.status.get('hasDataAccess')) {
    // Track eventual finish registration
    countlyTrackEventualRegistrationFinish('Pairing a POGO meter');
    replace({ pathname: '/onboarding/pogo-meter' });
  }

  return true;
}
