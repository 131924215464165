import { createConfirmationCreater, createMountPoint, createReactTreeMounter } from 'react-confirm';
import React from 'react';

const mounter = createReactTreeMounter();
export const createConfirm = createConfirmationCreater(mounter);
export const MountPointConfirm = createMountPoint(mounter);

export default function ConfirmMount() {
  return (<MountPointConfirm/>);
}
