import apiService from './apiService';

async function getIAmSharingWithSentInvitations(searchQuery = '') {
  try {
    const { data } = await apiService.get(`/v2/contacts/sharing_with_invitations?${searchQuery}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getIAmSharingWithReceivedInvitations(searchQuery = '') {
  try {
    const { data } = await apiService.get(`/contacts/sharing_with_received_invitations?${searchQuery}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getIAmSharingWithInvitationsCount() {
  try {
    const { data } = await apiService.get('/v2/contacts/count_sharing_with_invitations');
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function resendInviteToPatient(inviteId) {
  try {
    const { data } = await apiService.post(`/contacts/${inviteId}/invitation_resend_request`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

export default {
  resendInviteToPatient,
  getIAmSharingWithInvitationsCount,
  getIAmSharingWithSentInvitations,
  getIAmSharingWithReceivedInvitations
};
