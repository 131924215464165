import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const endISO8601 = dayjs.utc().endOf('day').format();
const startISO8601day1 = dayjs.utc().startOf('day').format();
const startISO8601day7 = dayjs.utc().endOf('day').subtract(6, 'days').startOf('day').format();
const startISO8601day14 = dayjs.utc().endOf('day').subtract(13, 'days').startOf('day').format();
const startISO8601day30 = dayjs.utc().endOf('day').subtract(29, 'days').startOf('day').format();

export const defaultOptions = [
  { label: '1 Day', span: 0, units: 'days', start: startISO8601day1, end: endISO8601 },
  { label: '7 Days', span: 6, units: 'days', start: startISO8601day7, end: endISO8601 },
  { label: '14 Days', span: 13, units: 'days', start: startISO8601day14, end: endISO8601 },
  { label: '30 Days', span: 29, units: 'days', start: startISO8601day30, end: endISO8601 }
];

export const reportOptions = [
  {
    id: 1,
    label: 'Today',
    from: startISO8601day1,
    to: endISO8601
  },
  {
    id: 2,
    label: '7 Days',
    from: startISO8601day7,
    to: endISO8601
  },
  {
    id: 3,
    label: '14 Days',
    from: startISO8601day14,
    to: endISO8601
  },
  {
    id: 4,
    label: '30 Days',
    from: startISO8601day30,
    to: endISO8601
  }
];

export function convertOptionToTimeframe(options) {
  if (!options) {
    return defaultOptions[1];
  }

  return options;
}

// This function expects "startTime" and "endTime"
// to be in utc. If it is not, bugs will occur
export function makeOptionsForCustomTimeframe(startTime, endTime) {
  const timeDiff = dayjs.utc(endTime).diff(dayjs.utc(startTime));
  // Convert the time difference to days
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return {
    end: endTime, // important: should be in UTC
    span: daysDiff,
    start: startTime, // important: should be in UTC
    label: `${daysDiff + 1} days`,
    units: 'days'
  };
}

export function handlePreviousDateFrame(startDateStr, endDateStr) {
  const diffDays = moment(endDateStr).utc().diff(moment(startDateStr), 'days');
  if (diffDays === 0) {
    const startDate = moment(startDateStr).utc().subtract(1, 'days').startOf('day');
    const endDate = moment(startDateStr).utc().subtract(1, 'days').endOf('day');

    return { end: endDate.format(), start: startDate.format() };
  }

  const startDate = moment(startDateStr).utc().subtract(diffDays, 'days').startOf('day');
  const endDate = moment(startDateStr).utc().endOf('day');

  return { end: endDate.format(), start: startDate.format() };
}

export function handleNextDateFrame(startDateStr, endDateStr) {
  const diffDays = moment(endDateStr).utc().diff(moment(startDateStr), 'days');
  if (diffDays === 0) {
    const startDate = moment(endDateStr).utc().add(1, 'days').startOf('day');
    const endDate = moment(endDateStr).utc().add(1, 'days').endOf('day');

    return { end: endDate.format(), start: startDate.format() };
  }

  const startDate = moment(endDateStr).utc().startOf('day');
  const endDate = moment(endDateStr).utc().add(diffDays, 'days').endOf('day');

  return { end: endDate.format(), start: startDate.format() };
}

export function makeCurrentReportOption(from, to) {
  const label = `Current Range: ${moment(from).utc().format('MMM DD')} - ${moment(to).utc().format('ll')}`;
  return { id: 5, label, from, to };
}
