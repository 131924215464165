import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  btnContainer: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: '50%',
    justifyContent: 'flex-end',

    '& > .c-button': {
      width: '50%',

      '&:last-child': {
        marginLeft: '8Px'
      }
    }
  },
  text: {
    fontSize: '0.888rem',
    lineHeight: '1rem'
  }
}));
