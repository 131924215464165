import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: '32Px',
    fontSize: '1.34rem',
    lineHeight: '2rem'
  },
  block: {
    display: 'flex',
    paddingBottom: '2rem',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
      paddingBottom: '1rem'
    }
  }
}));
