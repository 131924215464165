import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '40Px'
  },
  dialogContent: {
    padding: '16Px 0'
  },
  buttonGroup: {
    paddingTop: '32px'
  }
}));
