import { connect } from 'react-redux';
import { invitePractice, getHcpListInPractice } from 'src/actions/contactsInvitesActions';
import ContactsPracticeInfoView from 'src/pages/contacts/children/invites/hcp/ContactsPracticeInfoView/ContactsPracticeInfoView';
import { immutableToJsHOC } from './ImmutableToJsHOC';

function mapStateToProps(state) {
  return {
    practiceInfo: state.contactsInvites.get('practiceInfo'),
    isSearching: state.contactsInvites.get('isSearching')
  };
}

export default connect(mapStateToProps, {
  getHcpListInPractice,
  invitePractice
})(immutableToJsHOC(ContactsPracticeInfoView));
