import PropTypes from 'prop-types';
import React from 'react';

import GlucoseSummaryRow from './GlucoseSummaryRow';
import PercentGoalCompletedRow from './PercentGoalCompletedRow';
import {
  getCompletion,
  getGlucoseMetrics,
  getNumDays
} from '../../utils/trendHelpers';

const propTypes = {
  filterID: PropTypes.string.isRequired,
  goalsPerDay: PropTypes.number,
  graphContainer: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  highGlucose: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  lowGlucose: PropTypes.number.isRequired,
  prevVals: PropTypes.array.isRequired,
  readingVals: PropTypes.array.isRequired,
  styleVars: PropTypes.shape({
    sv: PropTypes.object.isRequired,
    bold: PropTypes.object,
    subHeading: PropTypes.object,
    p: PropTypes.object
  }).isRequired,
  xDomain: PropTypes.array.isRequired
};

function DashboardGlucoseSummary(props) {
  const {
    filterID,
    goalsPerDay = 3,
    graphContainer,
    highGlucose,
    isMobile,
    lowGlucose,
    prevVals,
    readingVals,
    styleVars,
    xDomain
  } = props;
  const {
    bold,
    p,
    subHeading,
    sv
  } = styleVars;

  const numDays = getNumDays(xDomain[1], xDomain[0]);

  const metrics = getGlucoseMetrics(readingVals, prevVals, highGlucose, lowGlucose, sv.color);
  const completion = getCompletion(readingVals, prevVals, numDays, goalsPerDay);

  // Outer
  const height = sv.spacing.md * (isMobile ? 25 : 16);
  const width = isMobile ? graphContainer.width - sv.spacing.md : sv.spacing.md * 48;
  const containerRectTranslation = {
    x: isMobile ? sv.spacing.sm : graphContainer.width / 2 - (sv.spacing.md * 24),
    y: graphContainer.height
  };
  const containerRectStyle = {
    fill: '#fff',
    filter: `url(#${filterID})`,
    strokeWidth: 2,
    stroke: sv.color.textLight
  };
  // Inner
  const margin = {
    bottom: sv.spacing.lg,
    left: isMobile ? sv.spacing.md : sv.spacing.lg + sv.spacing.sm,
    right: isMobile ? sv.spacing.md : sv.spacing.lg + sv.spacing.sm,
    top: isMobile ? sv.spacing.lg * 1.125 : sv.spacing.lg * 1.5
  };
  const inner = {
    height: height - margin.top - margin.bottom,
    width: width - margin.left - margin.right
  };
  // Columns
  const column = {
    height: isMobile ? inner.height / 2 : inner.height - margin.bottom,
    width: isMobile ? inner.width : inner.width / 2 - sv.spacing.md
  };
  const secondColumnTransform = isMobile ?
    `translate(0, ${sv.spacing.lg * metrics.length + sv.spacing.xl + sv.spacing.md})` :
    `translate(${column.width + sv.spacing.md * 2}, ${sv.spacing.lg})`;

  return (
    <g id="glucose-summary" transform={`translate(${containerRectTranslation.x}, ${containerRectTranslation.y})`}>
      <rect height={height} rx={sv.spacing.xs} style={containerRectStyle} width={width} x="0" y="0" />
      <g height={inner.height} transform={`translate(${margin.left}, ${margin.top})`} width={inner.width}>
        {!isMobile &&
          <line
            stroke={sv.color.textSecondary}
            strokeWidth={1}
            x1={inner.width / 2}
            x2={inner.width / 2}
            y1={sv.spacing.lg + sv.spacing.md}
            y2={inner.height - sv.spacing.md}
          />}

        <text style={bold}>GLUCOSE SUMMARY</text>
        <text textAnchor="end" transform={`translate(${inner.width}, 0)`} style={p}>
          {numDays} days
        </text>

        <g transform={`translate(0, ${sv.spacing.lg})`}>
          <text style={subHeading} transform={`translate(${column.width - sv.spacing.xxl}, 0)`}>
            VALUE
          </text>
          <text style={subHeading} transform={`translate(${column.width - sv.spacing.lg - sv.spacing.sm}, 0)`}>
            TREND
          </text>
          <g transform={`translate(0, ${sv.spacing.lg})`} width={inner.width / 2}>
            {metrics.map((metric, i) => {
              return (
                <GlucoseSummaryRow
                  key={i}
                  {...metric}
                  styleVars={styleVars}
                  transform={`translate(0, ${i * sv.spacing.lg})`}
                  width={column.width}
                />
              );
            })}
          </g>
        </g>

        <g transform={secondColumnTransform}>
          <text style={subHeading}>
            # OF RESULTS
          </text>
          <g transform={`translate(0, ${sv.spacing.lg})`}>
            <GlucoseSummaryRow
              inResultsSection
              label="Goal"
              styleVars={styleVars}
              transform={'translate(0, 0)'}
              value={completion.goal}
              width={column.width}
            />
            <GlucoseSummaryRow
              inResultsSection
              label="Complete"
              styleVars={styleVars}
              transform={`translate(0, ${sv.spacing.lg})`}
              value={completion.complete}
              width={column.width}
            />
            <PercentGoalCompletedRow
              direction={completion.direction}
              label="% of Goal Completed"
              styleVars={styleVars}
              transform={`translate(0, ${sv.spacing.lg * 1.75})`}
              value={completion.percentage}
              width={column.width}
            />
          </g>
        </g>
      </g>
    </g>
  );
}

DashboardGlucoseSummary.propTypes = propTypes;

export default DashboardGlucoseSummary;
