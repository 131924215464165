import { connect } from 'react-redux';
import {
  editIAmSharingWithContactsInfo,
  deleteYourSharingCircle,
  getAboutContactInfo,
  deleteYourPractice
} from '../actions/contactsActions';
import ContactsIAmSharingWithTableButtons from '../pages/contacts/children/iAmSharingWith/ContactsIAmSharingWithTable/ContactsIAmSharingWithTableButtons';
import { immutableToJsHOC } from './ImmutableToJsHOC';

export default connect(null, {
  getAboutContactInfo,
  editIAmSharingWithContactsInfo,
  deleteYourSharingCircle,
  deleteYourPractice
})(immutableToJsHOC(ContactsIAmSharingWithTableButtons));
