import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
const userOffset = dayjs().utcOffset();

export function convertToTimeZone(dateString) {
  // Create a new Date object from the UTC date string
  const date = new Date(dateString);

  // Get the UTC time in milliseconds
  const utcTime = date.getTime();

  // Get the local time zone offset in minutes from the browser
  const timeZoneOffsetInMinutes = new Date().getTimezoneOffset();

  // Calculate the offset in milliseconds
  const offsetInMilliseconds = timeZoneOffsetInMinutes * 60 * 1000;

  const localTime = utcTime - offsetInMilliseconds;

  // Create a new Date object from the local time
  const localDate = new Date(localTime);

  // Get the time zone offset in hours and minutes
  const offsetHours = Math.floor(Math.abs(timeZoneOffsetInMinutes) / 60);
  const offsetMinutes = Math.abs(timeZoneOffsetInMinutes) % 60;

  // Create the time zone offset string
  const offsetString = `${timeZoneOffsetInMinutes < 0 ? '-' : '+'}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;

  // Return the local date string with the time zone offset
  return localDate.toISOString().replace('Z', offsetString);
}

export function countUniqueDates(arr, prop = 'timestamp') {
  // Create an empty object to hold the unique dates
  const uniqueDates = {};

  // Loop through the array of objects and add each date to the object as a key
  for (let i = 0; i < arr.length; i++) {
    if (String(arr[i][prop])) {
      const date = String(convertToTimeZone(arr[i][prop])).substring(0, 10);
      uniqueDates[date] = true;
    }
  }

  // Count the number of keys in the object
  const count = Object.keys(uniqueDates).length;

  // Return the count
  return count;
}

export function adjustTimeByOffset(dateString, offsetSeconds) {
  // Parse the date string and create a Date object
  const date = new Date(dateString);

  // Get the current time in milliseconds
  const currentTime = date.getTime();

  // Calculate the offset time in milliseconds
  const offsetTime = offsetSeconds * 1000;

  // Adjust the time by adding the offset
  const adjustedTime = currentTime + offsetTime;

  // Create a new Date object with the adjusted time
  const adjustedDate = new Date(adjustedTime);

  // Return the adjusted date string
  return adjustedDate.toISOString();
}

// We should post to backend readings with offset.
// This is to handle case if we need readings from 01.01.2021 to 01.02.2021
// We might have readings dated 01.03.2021 yet with offset +3h which after correlation to offset
// will make reading 01.02.2021
export function makeStartDateForApi(start) {
  return dayjs.utc(start).utcOffset(userOffset, true).format();
}

// We should post to backend readings with offset.
// This is to handle case if we need readings from 01.01.2021 to 01.02.2021
// We might have readings dated 01.03.2021 yet with offset +3h which after correlation to offset
// will make reading 01.02.2021
export function makeEndDateForApi(end) {
  return dayjs.utc(end).utcOffset(userOffset, true).endOf('day').format();
}

export function shouldSwitchTimeframe(data) {
  const timeFrameEndDateUTC = dayjs().utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
  const timeFrameStartDateUTC = dayjs().utc().subtract(6, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
  const lastReadingDateUTC = dayjs.utc(data.timestamp).utcOffset(data.utcOffset / 60).format('YYYY-MM-DDTHH:mm:ss[Z]');
  const dateIsSameOrAfter = dayjs(lastReadingDateUTC).isSameOrAfter(timeFrameStartDateUTC, 'day');
  const dateIsSameOrBefore = dayjs(lastReadingDateUTC).isSameOrBefore(timeFrameEndDateUTC, 'day');
  return dateIsSameOrAfter && dateIsSameOrBefore;
}

export function cutTimeZone(date, type) {
  const timeValue = type === 'from' ? 'T00:00:00Z' : 'T23:59:59Z';
  const parts = String(date).split(/[T\+]/);
  return `${parts[0]}${timeValue}`;
}
