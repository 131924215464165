/* istanbul ignore file */
import { connect } from 'react-redux';
import { invitePractice } from '../actions/contactsInvitesActions';
import SearchPracticeResults from '../pages/contacts/children/invites/hcp/SearchPracticeResults/SearchPracticeResults';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import { getIAmSharingWithInvitationsCount } from '../actions/contactsIAmSharingWithInvitesActions';

function mapStateToProps(state) {
  return {
    searchResults: state.contactsInvites.getIn(['practiceSearchResults', 'medicalPractices']),
    isSearching: state.contactsInvites.get('isSearching')
  };
}

export default connect(mapStateToProps, {
  invitePractice,
  getIAmSharingWithInvitationsCount
})(immutableToJsHOC(SearchPracticeResults));
