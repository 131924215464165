import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import ChevronIcon from 'src/components/icons/ChevronIcon';
import confirm from 'src/utils/ConfirmHelpers';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid } from '@material-ui/core';
import Text from 'src/shared/Text/Text';
import Divider from 'src/shared/Divider/Divider';
import ArrowActive from 'src/assets/img/icons/arrow-down.svg';
import ModalContainer from 'src/components/modals/ModalContainer';
import AddPogoMeter from 'src/components/AddPogoMeter';
import { useStyles } from './Meters.styles';

const propTypes = {
  deletePatientMeter: PropTypes.func.isRequired,
  getPatientMeters: PropTypes.func.isRequired,
  meters: PropTypes.array.isRequired
};

export default function Meters(props) {
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();

  const deleteMeter = (id) => async () => {
    if (await confirm('Are you sure you want to remove this meter from your account?')) {
      await props.deletePatientMeter(id);
      await props.getPatientMeters();
    }
  };

  return (
    <Container className={classes.container}>
      <Grid container>
        <Link className="c-nav-header" to="/profile">
          <ChevronIcon />
          <h2 className="c-nav-header__label">POGO Automatic<sup>&reg;</sup></h2>
        </Link>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<img src={ArrowActive}/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text fontSize={16} lineHeight={24} font="bold" color="active">
                How to Sync Using Your Phone
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <ol className={classes.listWrapper}>
                <li>Log into the Patterns app on your phone</li>
                <li>Bring the POGO & phone within 10 ft of each other.</li>
                <li>Press briefly once the M button on the side of the POGO.</li>
                <li>Wait 5 - 10 secs.</li>
              </ol>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.divider}>
        <Divider/>
      </Grid>

      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <Grid container className={classes.header}>
            <Text fontSize={16} lineHeight={20} font="bold">
              Connected POGO Automatic<sup>&reg;</sup>
            </Text>
          </Grid>
        </Grid>
      </Grid>

      {(!props.meters || !props.meters.length) && (
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} className={classes.meterHeader}>
            <Text fontSize={16} lineHeight={20} font="bold" color="body">
              No meters connected
            </Text>
          </Grid>
        </Grid>
      )}

      {props.meters?.map((meter, index, array) => (
        <>
          <Grid key={meter.id} container className={classes.wrapper}>
            <Grid item xs={12} className={classes.meterHeader}>
              <Text fontSize={16} lineHeight={24} font="bold" color="active">
                POGO Automatic<sup>&reg;</sup>
              </Text>
            </Grid>

            <Grid item xs={12} className={classes.meterBody}>
              <Grid item xs={12} className={classes.meterData}>
                <Grid container>
                  <Text fontSize={16} lineHeight={20} font="bold" color="body">
                    Serial Number:
                  </Text>
                </Grid>
                <Grid container>
                  <Text fontSize={16} lineHeight={20} font="regular" color="body">
                    {meter.serialNumber}
                  </Text>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.meterData}>
                <Grid container>
                  <Text fontSize={16} lineHeight={20} font="bold" color="body">
                    Last Successful Sync:
                  </Text>
                </Grid>
                <Grid container>
                  <Text fontSize={16} lineHeight={20} font="regular" color="body">
                    {dayjs(meter.lastSyncTime).format('MM/DD/YYYY [at] hh:mm A')}
                  </Text>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.meterData}>
                <Grid container>
                  <Text fontSize={16} lineHeight={20} font="bold" color="body">
                    Most Recent Sync Attempt:
                  </Text>
                </Grid>
                <Grid container>
                  <Text fontSize={16} lineHeight={20} font="regular" color="body">
                    {meter.mostRecentSyncAttempt ?
                      dayjs(meter.mostRecentSyncAttempt).format('MM/DD/YYYY [at] hh:mm A') :
                      'N/A'}
                  </Text>
                </Grid>
              </Grid>
            </Grid>

            {props.meters?.length > 1 && (
              <Grid item xs={12}>
                <button data-testid={`pogo${meter.id}`} className={`c-button c-button--danger-hollow ${classes.button}`} onClick={deleteMeter(meter.id)}>
                  Remove
                </button>
              </Grid>
            )}
          </Grid>

          {array?.length - 1 > index && (
            <Grid item xs={12} className={classes.divider}>
              <Divider/>
            </Grid>
          )}
        </>
      ))}

      <Grid container className={classes.buttonWrapper}>
        <button
          className="c-button c-button--block c-button--hollow "
          data-testid="showPogoModal"
          onClick={() => setOpenModal(true)}
        >
          Add POGO Automatic<sup>&reg;</sup>
        </button>
      </Grid>

      <ModalContainer open={openModal} onClose={() => setOpenModal(false)}>
        <AddPogoMeter isModal onRequestClose={() => setOpenModal(false)}/>
      </ModalContainer>
    </Container>
  );
}

Meters.propTypes = propTypes;
