/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import PhoneTooltipText from './PhoneTooltipText';
import ecPhone from 'src/assets/img/icons/ec-phone.svg';
import { useStyles } from './PhoneTooltip.styles';
import { detectIfIsMobile } from 'src/utils/commonUtils';

const propTypes = {
  phoneNumber: PropTypes.string
};

export default function PhoneTooltip({ phoneNumber }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const isMobile = detectIfIsMobile();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const tooltipProps = {
    onClose: handleTooltipClose,
    open,
    disableFocusListener: true,
    disableHoverListener: true,
    disableTouchListener: true
  };

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            {...tooltipProps}
            placement="left-start"
            PopperProps={{ disablePortal: true }}
            classes={{ tooltip: classes.tooltip }}
            title={<span><PhoneTooltipText phoneNumber={phoneNumber}/></span>}
          >
            <img onClick={handleTooltipOpen} src={ecPhone} alt="phone icon"/>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <Tooltip
      title={<span><PhoneTooltipText phoneNumber={phoneNumber}/></span>}
      classes={{ tooltip: classes.tooltip }}
      placement="left-start"
    >
      <img src={ecPhone} alt="phone icon"/>
    </Tooltip>
  );
}

PhoneTooltip.propTypes = propTypes;
