import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
// Constants
import { roleList } from '../constants/contactPageRoleList';

/**
 * This hooks reads url get params and set enabled status to "type"
 * @returns {{
 * filterList: filterList,
 * }}
 */

export default function useContactTypesGetHook(skipType = []) {
  const { search } = useLocation();
  const { contactTypes } = parse(search, { arrayFormat: 'bracket' });

  const filterList = roleList.filter(el => !skipType.includes(el.type)).map(el => {
    if (!contactTypes || !contactTypes.length) {
      el.enabled = true;
      return el;
    }

    el.enabled = contactTypes.some(type => type === el.type);
    return el;
  });

  return { filterList };
}
