/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import circle from '../../assets/img/icons/circle.svg';

const propTypes = {
  selecting: PropTypes.string,
  selectedFrom: PropTypes.string,
  selectedTo: PropTypes.string,
  setInput: PropTypes.func
};

function SelectedDateInputs({ selecting, selectedFrom, selectedTo, setInput }) {
  const fromClasses = classNames(
    'c-selected-date-inputs__group',
    { 'c-selected-date-inputs__group--selected': selecting === 'from' }
  );
  const toClasses = classNames(
    'c-selected-date-inputs__group',
    { 'c-selected-date-inputs__group--selected': selecting === 'to' }
  );

  return (
    <div className="c-selected-date-inputs">
      <div className={fromClasses} onClick={() => setInput('from')}>
        <label className="c-selected-date-inputs__label">From</label>
        <div className="c-selected-date-inputs__input">
          <span className="h-padded-right-sm">
            <img src={circle} alt="circle"/>
          </span>
          {selectedFrom && moment(selectedFrom).format('MMM D, YYYY')}
        </div>
      </div>
      <div className="c-selected-date-inputs__break">to</div>
      <div className={toClasses} onClick={() => setInput('to')}>
        <label className="c-selected-date-inputs__label">To</label>
        <div className="c-selected-date-inputs__input">
          <span className="h-padded-right-sm">
            <img src={circle} alt="circle"/>
          </span>
          {selectedTo && moment(selectedTo).format('MMM D, YYYY')}
        </div>
      </div>
    </div>
  );
}

SelectedDateInputs.propTypes = propTypes;

export default SelectedDateInputs;
