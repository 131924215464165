import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  syncOnServerStatus: false,
  connected: new Immutable.List(),
  disconnected: new Immutable.List(),
  devices: new Immutable.Map({
    weights: null,
    caloriesBurned: null,
    steps: null,
    foods: null,
    sleeps: null,
    water: null,
    carbs: null
  }),
  query: ''
});

export default function validicReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.SET_DEVICE_PER_DATA_TYPE:
    return state.setIn(['devices', action.payload.type], action.payload.device);

  case actionTypes.GET_MARKETPLACE_START:
    return state.set('isActive', true);

  case actionTypes.SYNC_DEVICE_ON_SERVICE_START:
    return state.set('syncOnServerStatus', true);

  case actionTypes.GET_MARKETPLACE_SUCCESS:
    return state.withMutations((map) => {
      map.set('connected', Immutable.fromJS(action.payload?.directConnection?.connected));
      map.set('disconnected', Immutable.fromJS(action.payload?.directConnection?.disconnected));
      map.set('isActive', false);
    });

  case actionTypes.GET_MARKETPLACE_FAILURE:
    return state.set('isActive', false);

  case actionTypes.SYNC_DEVICE_ON_SERVICE_SUCCESS:
  case actionTypes.SYNC_DEVICE_ON_SERVICE_FAILURE:
    return state.set('syncOnServerStatus', false);

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
