import PropTypes from 'prop-types';
import React from 'react';
import { countlyTrackRoute } from '../../services/countlyService';

export default class CountlyTrackRoute extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    pageName: PropTypes.string.isRequired,
    pageGroup: PropTypes.string,
    setPageName: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  };

  componentDidMount() {
    this.rememberPageNameAndTriggerTracker();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.rememberPageNameAndTriggerTracker();
    }
  }

  componentWillUnmount() {
    this.triggerTrackPage();
  }

  rememberPageNameAndTriggerTracker = () => {
    if (this.props.pageName) {
      this.triggerTrackPage();
      this.props.setPageName(this.props.pageName, this.props.pageGroup);
    }
  }

  triggerTrackPage = () => {
    const { pageName, pageGroup } = this.props;

    pageName && countlyTrackRoute(pageName, pageGroup);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}
