import LogoSplash from 'src/components/LogoSplash';
import React from 'react';
import ResetPasswordContainer from 'src/containers/ResetPasswordContainer';

export default function ResetPasswordSMS() {
  return (
    <div className="l-container l-container--narrow">
      <div className="l-welcome">
        <div className="l-welcome__splash">
          <LogoSplash />
        </div>
        <div className="l-welcome__body">
          <ResetPasswordContainer />
        </div>
      </div>
    </div>
  );
}
