import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string
};

function ChevronIcon({ className }) {
  return (
    <svg className={`c-icon ${className || ''}`} viewBox="0 0 24 24">
      <g className="c-icon__stroke">
        <polyline points="9 5 15 12 9 19" />
      </g>
    </svg>
  );
}

ChevronIcon.propTypes = propTypes;

export default ChevronIcon;
