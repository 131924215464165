import range from 'lodash/range';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export function determineMultipleFromWidth(width) {
  // If we're on a small display
  if (width <= 700) {
    return 3; // once every 3 ticks
  } else if (width > 700 && width <= 979) {
    return 2; // once every 2 ticks
  }

  return 1;
}

export function formatAxis({ scale, end, start, numTicks, units = 'days', format = 'DD' }) {
  const startWrapped = moment(start);
  const diffDays = moment(end).diff(startWrapped, units) + 1;
  const step = Math.floor(diffDays / numTicks);

  return range(numTicks).map((i) => {
    const time = startWrapped.clone().utc().add(step * i, units);
    return {
      x: scale(time),
      label: time.format(format)
    };
  });
}

export function makeStandardDayAxis(scale, width) {
  const multiple = determineMultipleFromWidth(width);
  const tickWidth = scale(multiple * 3600000);
  const numTicks = 24 / multiple; // 24hrs / multiple of x ticks

  return {
    spacing: tickWidth / 2,
    ticks: range(numTicks).map((i) => {
      const milli = i * multiple * 60 * 60 * 1000;
      return {
        x: scale(milli),
        label: i * multiple <= 12 ? (i * multiple || 12) : i * multiple - 12
      };
    })
  };
}

export function timeframeEndTimeFilter(timeframe) {
  return function (mapWithTimestamp) {
    const ts = moment(mapWithTimestamp.get('endTimestamp'));
    return (
      ts.isSameOrAfter(timeframe.start) &&
      ts.isSameOrBefore(timeframe.end)
    );
  };
}

// Used in reports
export function makeAxis({ end, scale, span, start }) {
  let multiple = 1;
  let format = 'dd DD';
  let numTicks;

  if (span === 0) {
    numTicks = 24;

    return {
      spacing: scale(start.clone().add(multiple, 'hours')) / 2,
      ticks: formatAxis({ scale, end, start, numTicks, units: 'hours', format: 'h' })
    };
  }

  if (span > 7) {
    format = 'DD';
  }

  // All span over 29 are used in Provider app
  if (span > 29 && span <= 45) {
    multiple = 2;
  }

  if (span > 45) {
    multiple = 3;
  }

  numTicks = Math.floor((span + 1) / multiple);

  return {
    spacing: scale(start.clone().add(multiple, 'days')) / 2,
    ticks: formatAxis({
      scale,
      numTicks,
      format,
      start,
      end,
      units: 'days',
      multiple
    })
  };
}

export function timeframeFilter(timeframe) {
  return function (mapWithTimestamp) {
    const startDate = dayjs.utc(timeframe.start.format());
    const endDate = dayjs.utc(timeframe.end.format());
    const targetDate = dayjs.utc(mapWithTimestamp.get('timestamp'));

    return dayjs(targetDate).isBetween(startDate, endDate, 'day', '[]');
  };
}
