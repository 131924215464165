/* istanbul ignore file */
import { connect } from 'react-redux';

import SharingCircleNotificationSettings from '../components/sharingCircle/SharingCircleNotificationSettings';
import {
  getNotificationSettings,
  putNotificationSettings
} from '../actions/settingsActions';

const mapStateToProps = state => ({
  initialValues: {
    addCircleEvents: state.settings.toJS().notificationSettings.addCircleEvents
  },
  isActive: state.settings.get('isActive'),
  notificationScope: state.status.get('scopes').includes('carer') ? 'carer' : 'hcp'
});

export default connect(mapStateToProps, {
  getNotificationSettings,
  putNotificationSettings
})(SharingCircleNotificationSettings);
