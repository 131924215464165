import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

/*
 * I am using @material-ui/core/styles,
 * since it will allow in future to move this components as separate
 * git repo and import later, also styles this way are isolated from project
 */

export default withStyles((theme) => ({
  head: {
    '&:not(:last-child)': {
      borderRight: '2Px solid #fff'
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    lineHeight: '1rem',
    textTransform: 'uppercase',
    height: '56px'
  }
}))(TableCell);
