export function responseStatusIs(response, expectedStatus) {
  return response && response.status === expectedStatus;
}

export function setErrors(props) {
  return props.anyTouched && props.error ? props.error : [];
}

export function expectFieldWithError(response, fieldName) {
  if (!response.data || !response.data.errors) {
    return false;
  }

  const fieldNameError = response.data.errors.find(el => el.fieldName === fieldName);
  return fieldNameError ? fieldNameError.errors[0] : '';
}
