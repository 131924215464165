export const statesArray = [
  { value: 'AL', name: 'Alabama (AL)' },
  { value: 'AK', name: 'Alaska (AK)' },
  { value: 'AZ', name: 'Arizona (AZ)' },
  { value: 'AR', name: 'Arkansas (AR)' },
  { value: 'CA', name: 'California (CA)' },
  { value: 'CO', name: 'Colorado (CO)' },
  { value: 'CT', name: 'Connecticut (CT)' },
  { value: 'DE', name: 'Delaware (DE)' },
  { value: 'DC', name: 'District of Columbia (DC)' },
  { value: 'FL', name: 'Florida (FL)' },
  { value: 'GA', name: 'Georgia (GA)' },
  { value: 'HI', name: 'Hawaii (HI)' },
  { value: 'ID', name: 'Idaho (ID)' },
  { value: 'IL', name: 'Illinois (IL)' },
  { value: 'IN', name: 'Indiana (IN)' },
  { value: 'IA', name: 'Iowa (IA)' },
  { value: 'KS', name: 'Kansas (KS)' },
  { value: 'KY', name: 'Kentucky (KY)' },
  { value: 'LA', name: 'Louisiana (LA)' },
  { value: 'ME', name: 'Maine (ME)' },
  { value: 'MD', name: 'Maryland (MD)' },
  { value: 'MA', name: 'Massachusetts (MA)' },
  { value: 'MI', name: 'Michigan (MI)' },
  { value: 'MN', name: 'Minnesota (MN)' },
  { value: 'MS', name: 'Mississippi (MS)' },
  { value: 'MO', name: 'Missouri (MO)' },
  { value: 'MT', name: 'Montana (MT)' },
  { value: 'NE', name: 'Nebraska (NE)' },
  { value: 'NV', name: 'Nevada (NV)' },
  { value: 'NH', name: 'New Hampshire (NH)' },
  { value: 'NJ', name: 'New Jersey (NJ)' },
  { value: 'NM', name: 'New Mexico (NM)' },
  { value: 'NY', name: 'New York (NY)' },
  { value: 'NC', name: 'North Carolina (NC)' },
  { value: 'ND', name: 'North Dakota (ND)' },
  { value: 'OH', name: 'Ohio (OH)' },
  { value: 'OK', name: 'Oklahoma (OK)' },
  { value: 'OR', name: 'Oregon (OR)' },
  { value: 'PA', name: 'Pennsylvania (PA)' },
  { value: 'PR', name: 'Puerto Rico (PR)' },
  { value: 'RI', name: 'Rhode Island (RI)' },
  { value: 'SC', name: 'South Carolina (SC)' },
  { value: 'SD', name: 'South Dakota (SD)' },
  { value: 'TN', name: 'Tennessee (TN)' },
  { value: 'TX', name: 'Texas (TX)' },
  { value: 'UT', name: 'Utah (UT)' },
  { value: 'VT', name: 'Vermont (VT)' },
  { value: 'VA', name: 'Virginia (VA)' },
  { value: 'WA', name: 'Washington (WA)' },
  { value: 'WV', name: 'West Virginia (WV)' },
  { value: 'WI', name: 'Wisconsin (WI)' },
  { value: 'WY', name: 'Wyoming (WY)' }
];
