import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import FoodsListPresenterContainer from '../../containers/FoodsListPresenterContainer';

export default class FoodsTab extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    favoriteFoods: PropTypes.array,
    history: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
    readingId: PropTypes.string.isRequired,
    recentFoods: PropTypes.array,
    updateActiveMealTab: PropTypes.func.isRequired
  };

  state = {
    value: this.props.activeTab || 'recent'
  };

  handleChange = (value) => {
    this.setState({ value });
  }

  render() {
    const { value } = this.state;

    const aTab = classNames(
      'h-bg-none',
      'h-cursor__pointer',
      'c-tab c-tab--grey-border',
      { 'c-tab--active': value === 'recent' }
    );
    const bTab = classNames(
      'h-bg-none',
      'h-cursor__pointer',
      'c-tab c-tab--grey-border',
      { 'c-tab--active': value === 'favorite' }
    );
    if (!this.props.isVisible) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="c-tabs c-tabs--no-border h-full-width h-padded-sides">
          <button
            data-testid="recent"
            className={aTab}
            onClick={() => {
              this.handleChange('recent');
              this.props.updateActiveMealTab('recent');
            }}
          >
            <div className="c-tab__title c-tab__forms-title">Recent Meals</div>
          </button>
          <button
            data-testid="favorite"
            className={bTab}
            onClick={() => {
              this.handleChange('favorite');
              this.props.updateActiveMealTab('favorite');
            }}
          >
            <div className="c-tab__title c-tab__forms-title">Favorite Meals</div>
          </button>
        </div>

        <div className="h-full-width h-margin-bottom">
          <FoodsListPresenterContainer
            ifEmptyText={'No recent items.'}
            isVisible={value === 'recent'}
            history={this.props.history}
            list={this.props.recentFoods}
            readingId={this.props.readingId}
            type={'postRecentFood'}
            showFavoriteStatus
          />
          <FoodsListPresenterContainer
            ifEmptyText={'No favorite items.'}
            isVisible={value === 'favorite'}
            history={this.props.history}
            list={this.props.favoriteFoods}
            readingId={this.props.readingId}
            type={'postFavoriteFood'}
            showFavoriteStatus
          />
        </div>
      </React.Fragment>
    );
  }
}
