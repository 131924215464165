/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  innerHeight: PropTypes.number.isRequired,
  sv: PropTypes.object,
  xAxis: PropTypes.shape({
    spacing: PropTypes.number.isRequired,
    ticks: PropTypes.array.isRequired
  }).isRequired,
  xAxisLabel: PropTypes.object
};

function XAxis({ innerHeight, sv, xAxis, xAxisLabel }) {
  return (
    <g
      id="x-ticks"
      transform="translate(0, 7)"
      style={{ pointerEvents: 'none' }}
    >
      {xAxis.ticks.map((tick, i) => {
        return (
          <g key={i}>
            {i > 0 && (
              <line
                x1={tick.x}
                x2={tick.x}
                y1={-sv.spacing.xs}
                y2={innerHeight}
                stroke={sv.color.textLight}
                strokeWidth="1"
                strokeDasharray="2,2"
              />
            )}
            <text
              x={tick.x + xAxis.spacing}
              y={innerHeight + 10}
              textAnchor="middle"
              dominantBaseline="baseline"
              style={xAxisLabel}
            >
              {tick.label}
            </text>
          </g>
        );
      })}
    </g>
  );
}

XAxis.propTypes = propTypes;

export default XAxis;
