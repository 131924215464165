import PropTypes from 'prop-types';
import React from 'react';
import BarChartSVG from '../components/svg/BarChartSVG';
import { connect } from 'react-redux';
import { setDevicePerDataType } from '../actions/validicActions';
import HealthDeviceWidget from '../components/HealthDeviceWidget';
import {
  getSleepsCacheDeviceList,
  makeGetSleepsDevice,
  makeGroupedSleepsInTimeframe
} from '../selectors/healthDataSelectors';
import SleepsIcon from '../components/icons/SleepsIcon';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  sleepTotals: PropTypes.object,
  sleepsDevice: PropTypes.object,
  sleepsDevices: PropTypes.array,
  isSharingCircleView: PropTypes.bool,
  setDevicePerDataType: PropTypes.func.isRequired
};

function WidgetSleepsContainer({
  sleepTotals,
  sleepsDevice,
  sleepsDevices,
  isSharingCircleView,
  setDevicePerDataType: switchDevice
}) {
  return (
    <HealthDeviceWidget
      Chart={BarChartSVG}
      data={sleepTotals}
      dataCollectionType="sleeps"
      dataType="totalSleepDuration"
      device={sleepsDevice}
      durationInHours
      Icon={SleepsIcon}
      isSharingCircleView={isSharingCircleView}
      sources={sleepsDevices}
      switchDevice={switchDevice}
      title="AVG Sleep"
    />
  );
}

export function makeMapStateToProps() {
  const getGroupedSleeps = makeGroupedSleepsInTimeframe();
  const getSleepsDevice = makeGetSleepsDevice();

  return function (state, props) {
    const sleepsDevice = getSleepsDevice(state, props);
    const sleepTotals = getGroupedSleeps(state, props);
    const sleepsDevices = getSleepsCacheDeviceList(state);

    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      sleepsDevices,
      sleepsDevice,
      sleepTotals
    };
  };
}

export default connect(makeMapStateToProps, {
  setDevicePerDataType
})(immutableToJsHOC(WidgetSleepsContainer));

WidgetSleepsContainer.propTypes = propTypes;
