import React from 'react';

export default function BadMoodIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path fill="#282829" d="M15.963 12.315c-.747 0-1.332-1.126-1.332-2.563 0-1.436.585-2.561 1.332-2.561.746 0 1.331 1.125 1.331 2.561 0 1.437-.585 2.563-1.331 2.563m-6.529 7.023l-.08.056-.132.01a.369.369 0 0 1-.31-.171l-.093-.144.071-.207c.652-1.395 1.815-2.23 3.109-2.23 1.554 0 2.328.556 3.111 2.233l.072.151-.094.197a.369.369 0 0 1-.31.172h-.097l-.113-.066c-.68-.483-1.542-.727-2.57-.727-1.025 0-1.887.244-2.564.726m-3.505-4.2c.12-.472 1.26-1.785 1.39-1.933l.154-.176.051.227c.043.193.416 1.892.296 2.365a.976.976 0 0 1-1.891-.482M9.44 9.752c0 1.437-.585 2.563-1.331 2.563-.747 0-1.332-1.126-1.332-2.563 0-1.436.585-2.561 1.332-2.561.746 0 1.331 1.125 1.331 2.561M12 1C5.935 1 1 5.934 1 12c0 6.067 4.935 11 11 11s11-4.933 11-11c0-6.066-4.935-11-11-11"/>
        <path fill="#2da9df" d="M5.732 15.216c.155-.609 1.624-2.3 1.792-2.491l.198-.227.066.294c.055.247.536 2.436.381 3.044a1.26 1.26 0 0 1-1.53.91 1.26 1.26 0 0 1-.907-1.53"/>
        <g>
          <path fill="#282829" d="M15.963 12.315c-.747 0-1.332-1.126-1.332-2.563 0-1.436.585-2.561 1.332-2.561.746 0 1.331 1.125 1.331 2.561 0 1.437-.585 2.563-1.331 2.563m-6.529 7.023l-.08.056-.132.01a.369.369 0 0 1-.31-.171l-.093-.144.071-.207c.652-1.395 1.815-2.23 3.109-2.23 1.554 0 2.328.556 3.111 2.233l.072.151-.094.197a.369.369 0 0 1-.31.172h-.097l-.113-.066c-.68-.483-1.542-.727-2.57-.727-1.025 0-1.887.244-2.564.726m-3.505-4.2c.12-.472 1.26-1.785 1.39-1.933l.154-.176.051.227c.043.193.416 1.892.296 2.365a.976.976 0 0 1-1.891-.482M9.44 9.752c0 1.437-.585 2.563-1.331 2.563-.747 0-1.332-1.126-1.332-2.563 0-1.436.585-2.561 1.332-2.561.746 0 1.331 1.125 1.331 2.561M12 1C5.935 1 1 5.934 1 12c0 6.067 4.935 11 11 11s11-4.933 11-11c0-6.066-4.935-11-11-11"/>
          <path fill="#2da9df" d="M5.732 15.216c.155-.609 1.624-2.3 1.792-2.491l.198-.227.066.294c.055.247.536 2.436.381 3.044a1.26 1.26 0 0 1-1.53.91 1.26 1.26 0 0 1-.907-1.53"/>
        </g>
      </g>
    </svg>
  );
}
