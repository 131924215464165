import apiService from './apiService';

async function getNutritionixAutocomplete(query, readingId) {
  const { data } = await apiService.get(`/nutritionix/search?query=${query}&readingId=${readingId}`);
  return data;
}

async function getNutritionixFoodById(readingId, type, val, nixItemId) {
  const searchByNixId = nixItemId ? `&nix_item_id=${nixItemId}` : '';
  const { data } = await apiService.get(`/nutritionix/food_search?${type}=${val}${searchByNixId}&readingId=${readingId}`);
  return data;
}

async function getNutritionixFoodByIdAndReadingId(readingId, foodId) {
  const { data } = await apiService.get(`/readings/${readingId}/foods/${foodId}`);
  return data;
}

export default {
  getNutritionixAutocomplete,
  getNutritionixFoodByIdAndReadingId,
  getNutritionixFoodById
};
