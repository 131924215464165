import PropTypes from 'prop-types';
import React from 'react';
import confirm from '../utils/ConfirmHelpers';
import LogoSplash from './LogoSplash';
import pageData from '../constants/ecAttritionPageData';

export default class ECUnsubscribe extends React.Component {
  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    handlingType: PropTypes.string,
    selfRemoveFromEC: PropTypes.func.isRequired,
    token: PropTypes.string
  };

  clickHandler = async () => {
    if (this.props.handlingType !== 'needs_confirmation') {
      return this.props.history.push('/');
    }

    const confirmOptions = { confirmText: 'Yes', cancelText: 'No' };
    const { firstName, lastName } = this.props;
    const confirmMessage = `Are you sure you want to stop being an emergency contact to ${firstName} ${lastName}?`;

    if (await confirm(confirmMessage, confirmOptions)) {
      // 1 is used for confirming, its from backend requirement
      this.props.selfRemoveFromEC(this.props.token, 1);
    }
  }

  render() {
    const { firstName, lastName, handlingType } = this.props;
    const { ec } = pageData;
    const data = ec[handlingType];

    return (
      <div className="l-container l-container--narrow">
        <div className="l-welcome">
          <div className="l-welcome__splash">
            <LogoSplash />
          </div>
          <div className="l-welcome__body">
            <div className="c-card">
              <div className="c-card__body">
                {!data ? (
                  <p data-testid="ecAttritionWait">Please Wait...</p>
                ) : (
                  <React.Fragment>
                    <h2>{data.title}</h2>
                    <p>{data.text(firstName, lastName)}</p>
                    <hr/>
                    <button
                      data-testid="ecAttritionButton"
                      onClick={this.clickHandler}
                      className="c-button c-button--block c-button--action"
                    >
                      {data.buttonText}
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
