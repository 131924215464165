import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import _omit from 'lodash/omit';
import MuiTextField from './muiComponents/MuiTextField';
import MuiCheckbox from './muiComponents/MuiCheckbox';
import WelcomeNav from './WelcomeNav';
import TOKEN_TYPES from '../constants/tokenTypes';
import ERROR_MESSAGES from '../constants/errorMessages';
import formValidationService from '../services/formValidation';
import { countlyStartUserSession } from '../services/countlyService';

export class SignIn extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      authentication: PropTypes.shape({
        pleaseKeepSigningMeOut: PropTypes.func.isRequired,
        signIn: PropTypes.func.isRequired,
        reduxSignOut: PropTypes.func.isRequired,
        staySignedIn: PropTypes.func.isRequired
      }),
      invitation: PropTypes.shape({
        acceptInvitation: PropTypes.func.isRequired
      }).isRequired,
      settings: PropTypes.shape({
        postPatientMeterWithToken: PropTypes.func.isRequired
      }).isRequired,
      status: PropTypes.shape({
        getStatus: PropTypes.func.isRequired
      }).isRequired
    }),
    hasAcceptedTermsAndConditions: PropTypes.bool,
    history: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.object
      }).isRequired,
      push: PropTypes.func.isRequired
    }).isRequired,
    invitationToken: PropTypes.string,
    isRefreshing: PropTypes.bool,
    pogoToken: PropTypes.string,
    staySignedIn: PropTypes.bool
  };

  componentDidUpdate() {
    if (this.props.isRefreshing) {
      this.props.actions.authentication.reduxSignOut();
    }
  }

  doPostPogoToken = async () => {
    // If there is an add meter token, attempt to post it
    if (this.props.pogoToken) {
      try {
        await this.props.actions.settings.postPatientMeterWithToken(
          TOKEN_TYPES.ADD_METER,
          this.props.pogoToken
        );
      } catch (err) {
        console.warn(err); // eslint-disable-line no-console
      }
    }
  }

  doAcceptInvite = async () => {
    // If there is an invitation token, attempt to accept it
    if (this.props.invitationToken) {
      try {
        await this.props.actions.invitation.acceptInvitation(this.props.invitationToken);
      } catch (err) {
        console.warn(err); // eslint-disable-line no-console
      }
    }
  }

  doAcceptTermsAndConditions = () => {
    const query = this.props.history.location.query;

    if (this.props.hasAcceptedTermsAndConditions === false) {
      this.props.history.push('/terms-and-conditions');
    } else {
      this.props.history.push({
        pathname: query && query.next ? query.next : '/',
        query: _omit(query, 'next')
      });
    }
  }

  signIn = async (values) => {
    try {
      await this.props.actions.authentication.signIn(values);
      values.emailAddress && countlyStartUserSession(values.emailAddress);
      await this.props.actions.status.getStatus();
    } catch (err) {
      let message = String(err.message).toLowerCase();
      // It's a feature. As requested we should insert clickable
      // phone number as error text message.
      if (
        message === 'deactivated' ||
        message === 'invalid credentials' ||
        message === 'locked' ||
        message === 'blocked'
      ) {
        message = ERROR_MESSAGES[err.message];
      }

      return { password: message };
    }

    await this.doPostPogoToken();
    await this.doAcceptInvite();
    this.doAcceptTermsAndConditions();
  };

  toggleStaySignedIn = () => {
    return this.props.staySignedIn ?
      this.props.actions.authentication.pleaseKeepSigningMeOut() :
      this.props.actions.authentication.staySignedIn();
  };

  /* istanbul ignore next */
  validateFields(values) {
    return new formValidationService.Validation()
      .email(values.emailAddress)
      .password(values.password)
      .getErrors();
  }

  render() {
    return (
      <div className="c-card">
        <div className="c-card__body">
          <WelcomeNav />
          <div className="h-padded h-center">
            Welcome to <strong>Patterns<sup>&reg;</sup>!</strong>
          </div>
          <div className="h-padded h-left">
            <strong>Patterns<sup>&reg;</sup> Personal Wellness System</strong> helps you easily view your <strong>POGO Automatic<sup>&reg;</sup></strong> glucose results and spot trends to adjust your daily diabetes management.
          </div>

          <Form
            onSubmit={this.signIn}
            validate={this.validateFields}
            render={({ handleSubmit, valid, pristine, submitting, hasSubmitErrors }) => {
              return (
                <form
                  name="signIn"
                  className="c-form h-margin-bottom"
                  onSubmit={handleSubmit}
                >
                  <fieldset className="c-fieldset">
                    <Field
                      component={MuiTextField}
                      floatingLabelFixed
                      label="Email Address"
                      name="emailAddress"
                    />
                    <Field
                      component={MuiTextField}
                      floatingLabelFixed
                      label="Password"
                      name="password"
                      type="password"
                    />
                    <div className="h-padded">
                      <MuiCheckbox
                        checked={this.props.staySignedIn}
                        label="Stay Signed In"
                        labelPosition="right"
                        name="staySignedIn"
                        onCheck={this.toggleStaySignedIn}
                        type="checkbox"
                      />
                    </div>
                  </fieldset>
                  <input
                    className="c-button c-button--block c-button--action c-button--submit"
                    disabled={(!valid && !hasSubmitErrors) || pristine || submitting}
                    type="submit"
                    value="Sign In"
                  />
                </form>
              );
            }}
          />
          <div className="h-right">
            <Link className="h-bold" to="/welcome/forgot-password">
              Forgot Password
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SignIn;
