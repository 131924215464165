import actionTypes from '../constants/actionTypes';
import readingTagsService from '../services/readingTagsService';
import { makePGHDDeviceList } from '../utils/deviceList';
import { adjustTimeByOffset } from 'src/utils/dateFormatter';

// GET_RANGE_OF_READING_TAGS
function getRangeOfReadingTagsFailure(err) {
  return {
    type: actionTypes.GET_RANGE_OF_READING_TAGS_FAILURE,
    error: true,
    payload: err
  };
}

function getRangeOfReadingTagsStart() {
  return {
    type: actionTypes.GET_RANGE_OF_READING_TAGS_START
  };
}

function getRangeOfReadingTagsSuccess({ tags, totals }) {
  const tagsAdjustedByOffset = tags?.map((reading) => ({
    ...reading, createdAt: adjustTimeByOffset(reading.createdAt, reading.utcOffset)
  }));

  return {
    type: actionTypes.GET_RANGE_OF_READING_TAGS_SUCCESS,
    payload: { tags: tagsAdjustedByOffset, totals }
  };
}

function makeTagsDeviceListSuccess(data) {
  const waterDevices = makePGHDDeviceList(data.tags);
  return {
    type: actionTypes.GET_TAGS_DEVICE_LIST_SUCCESS,
    payload: { waterDevices }
  };
}

export function getRangeOfReadingTags(from, to) {
  return async function (dispatch) {
    dispatch(getRangeOfReadingTagsStart());

    try {
      const data = await readingTagsService.getRangeOfReadingTags(from, to);
      dispatch(getRangeOfReadingTagsSuccess(data));
      dispatch(makeTagsDeviceListSuccess(data));
    } catch (err) {
      dispatch(getRangeOfReadingTagsFailure(err));
    }
  };
}

// POST_READING_TAG

function postReadingTagFailure(err) {
  return {
    type: actionTypes.POST_READING_TAG_FAILURE,
    error: true,
    payload: err
  };
}

function postReadingTagStart() {
  return {
    type: actionTypes.POST_READING_TAG_START
  };
}

function postReadingTagSuccess(readingId, tag) {
  return {
    type: actionTypes.POST_READING_TAG_SUCCESS,
    payload: {
      readingId,
      tag
    }
  };
}

export function postReadingTag(readingId, tag) {
  return async function (dispatch) {
    dispatch(postReadingTagStart());

    try {
      const data = await readingTagsService.postReadingTag(readingId, tag);
      dispatch(postReadingTagSuccess(readingId, data));
    } catch (err) {
      dispatch(postReadingTagFailure(err));
      throw err;
    }
  };
}

// PUT_READING_TAG

function putReadingTagFailure(err) {
  return {
    type: actionTypes.PUT_READING_TAG_FAILURE,
    error: true,
    payload: err
  };
}

function putReadingTagStart() {
  return {
    type: actionTypes.PUT_READING_TAG_START
  };
}

function putReadingTagSuccess(readingId, tag) {
  return {
    type: actionTypes.PUT_READING_TAG_SUCCESS,
    payload: {
      readingId,
      tag
    }
  };
}

export function putReadingTag(readingId, tag) {
  return async function (dispatch) {
    dispatch(putReadingTagStart());

    try {
      const data = await readingTagsService.putReadingTag(tag);
      dispatch(putReadingTagSuccess(readingId, data));
    } catch (err) {
      dispatch(putReadingTagFailure(err));
      throw err;
    }
  };
}

function deleteReadingTagSuccess(readingId, tag) {
  return {
    type: actionTypes.DELETE_READING_TAG_SUCCESS,
    payload: {
      readingId,
      tag
    }
  };
}

function deleteReadingTagStart() {
  return {
    type: actionTypes.DELETE_READING_TAG_START
  };
}

function deleteReadingTagFailure() {
  return {
    type: actionTypes.DELETE_READING_TAG_FAILURE
  };
}

export function deleteReadingTag(readingId, tagID, tag) {
  return async function (dispatch) {
    dispatch(deleteReadingTagStart());

    try {
      await readingTagsService.deleteReadingTag(tagID);
      dispatch(deleteReadingTagSuccess(readingId, tag));
    } catch (err) {
      dispatch(deleteReadingTagFailure());
      throw err;
    }
  };
}
