import apiService from './apiService';
import moment from 'moment-timezone';
import { normalizePhone } from './fieldNormalizationService';
import { mapPhoneNumbers } from 'src/utils/mapPhoneNubmers';
import { trimSpaces } from 'src/utils/formHelpers';

const updateAboutMeData = (data) => {
  if (moment(data.dateOfBirth, 'YYYY-MM-DD').isValid()) {
    data.dateOfBirth = moment(data.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }

  if (data.phoneNumbers && data.phoneNumbers.length) {
    data.phoneNumbers = data.phoneNumbers.forEach((phone) => {
      data[`${phone.label}Number`] = normalizePhone(phone.number);
      data[`${phone.label}NumberVerified`] = phone.status;
    });
  }
  return data;
};

async function getAboutMe(type = 'patient') {
  try {
    const { data } = await apiService.get(`/${type}/about`);

    return updateAboutMeData(data);
  } catch (err) {
    throw new Error(err);
  }
}

async function getPatientMedications() {
  try {
    const { data } = await apiService.get('/medications');
    return {
      insulins: data.insulins,
      medicationTypes: data.medicationTypes || []
    };
  } catch (err) {
    throw new Error(err);
  }
}

async function putAboutMe(values, type, withTimeZone) {
  const timeZone = withTimeZone ? { timeZone: moment.tz.guess() } : {};
  const formValuesTrimmed = trimSpaces(values);
  const valuesWithDOB = {
    ...formValuesTrimmed,
    ...timeZone,
    dateOfBirth: values.dateOfBirth && moment(formValuesTrimmed.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD')
  };

  const params = mapPhoneNumbers(valuesWithDOB);

  try {
    const { data } = await apiService.put(`${type}/about`, params);

    return updateAboutMeData(data);
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function putPatientMedications(values) {
  try {
    const { data } = await apiService.put('/medications', values);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

export default {
  getAboutMe,
  getPatientMedications,
  putAboutMe,
  putPatientMedications
};
