import PropTypes from 'prop-types';
import React from 'react';
import UnavailableView from '../components/UnavailableView';

export default class Healthfeed extends React.Component {
  static propTypes = {
    healthfeedLoadStatus: PropTypes.bool.isRequired
  };

  state = {
    onLineMode: true
  };

  componentDidMount() {
    this.switchOfflineView();
    if (typeof navigator.onLine !== 'undefined') {
      window.addEventListener('offline', this.switchOfflineView, false);
      window.addEventListener('online', this.switchOfflineView, false);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.healthfeedLoadStatus === false &&
      nextProps.healthfeedLoadStatus === true
    ) {
      return true;
    }

    if (nextState.onLineMode !== this.state.onLineMode) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (typeof navigator.onLine !== 'undefined') {
      window.removeEventListener('offline', this.switchOfflineView, false);
      window.removeEventListener('online', this.switchOfflineView, false);
    }
  }

  switchOfflineView = () => {
    if (typeof navigator.onLine !== 'undefined') {
      this.setState({ onLineMode: navigator.onLine });
    }
  }

  render() {
    if (this.state.onLineMode === false) {
      return (
        <UnavailableView title="Looks like you are offline." />
      );
    }

    if (!this.props.healthfeedLoadStatus) {
      return null;
    }

    return (
      <div className="l-container">
        <iframe
          data-testid="iframe"
          className="c-healthfeed"
          name="healthfeed"
          src="/healthfeed.html"
        />
      </div>
    );
  }
}
