import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  filterID: PropTypes.string
};

function DropShadowFilter({ filterID = 'dropShadow' }) {
  return (
    <filter id={filterID}>
      <feGaussianBlur in="SourceGraphic" stdDeviation="7" result="shadow" />
      <feOffset in="shadow" dx="0" dy="2" result="shadow" />
      <feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0.3 0" result="shadow" />
      <feBlend in="SourceGraphic" in2="shadow" />
    </filter>
  );
}

DropShadowFilter.propTypes = propTypes;

export default DropShadowFilter;
