import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import MuiSelectField from 'src/components/muiComponents/MuiSelectField';
import MuiTextField from 'src/components/muiComponents/MuiTextField';
import MuiDatePicker from 'src/components/muiComponents/MuiDatePicker';
import WarnAboutUnsavedChanges from 'src/containers/warnAboutUnsavedChanges';
import { normalizePhone } from 'src/services/fieldNormalizationService';
import formValidationService from 'src/services/formValidation';
import { handleFormSubmissionErrors } from 'src/services/errorHandlingService';
import { statesArray } from 'src/constants/countryStates';
import AboutPhoneStatus from 'src/components/AboutPhoneStatus';

const propTypes = {
  cellNumberVerified: PropTypes.string,
  getAboutMe: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  putAboutMe: PropTypes.func.isRequired
};

export default function AboutMeForm(props) {
  const history = useHistory();

  useEffect(() => {
    props.getAboutMe();
  }, []);

  const submit = async (values) => {
    const formValues = { ...values, diabetesType: Number(values.diabetesType) };

    try {
      await props.putAboutMe(formValues);
      history.push('/profile/notifications'); // go to next page
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        [
          'firstName',
          'lastName',
          'dateOfBirth',
          'gender',
          'salutation',
          'diabetesType',
          'addressOne',
          'cityVillage',
          'stateProvince',
          'postalCode'
        ]
      );
    }
  };

  const validateFields = (values) => {
    return (new formValidationService.Validation())
      .dateOfBirth(values.dateOfBirth)
      .cellNumberRequired(values.cellNumber)
      .homeNumberOptional(values.homeNumber)
      .addressOne(values.addressOne)
      .cityVillage(values.cityVillage)
      .stateProvince(values.stateProvince)
      .firstName(values.firstName)
      .gender(values.gender)
      .diabetesType(values.diabetesType)
      .salutation(values.salutation)
      .lastName(values.lastName)
      .postalCode(values.postalCode)
      .getErrors();
  };

  return (
    <Form initialValues={props.initialValues} onSubmit={submit} validate={validateFields}>
      {({ handleSubmit, submitting, pristine }) => {
        return (
          <form name="aboutMeForm" className="c-form" onSubmit={handleSubmit}>
            <WarnAboutUnsavedChanges />
            <Field data-testid="salutation" name="salutation" component={MuiSelectField} label="Name Prefix" className="h-mui-native__select">
              <MenuItem value="Dr.">Dr.</MenuItem>
              <MenuItem value="Mr.">Mr.</MenuItem>
              <MenuItem value="Miss">Miss</MenuItem>
              <MenuItem value="Mrs.">Mrs.</MenuItem>
              <MenuItem value="Ms.">Ms.</MenuItem>
              <MenuItem value="No answer">No Answer</MenuItem>
            </Field>
            <Field
              component={MuiTextField}
              label="First Name"
              name="firstName"
            />
            <Field
              component={MuiTextField}
              label="Last Name"
              name="lastName"
            />
            <Field
              data-testid="date-picker-input"
              component={MuiDatePicker}
              floatingLabelText="DOB*"
              name="dateOfBirth"
              openToYearSelection
              id="uniqueid" // This is to fix warning explained here https://github.com/mui-org/material-ui/issues/4659
            />
            <p className="h-center h-padded">*Date of Birth is used to verify your identity with your Healthcare Provider when sharing your data.</p>
            <Field data-testid="gender" name="gender" component={MuiSelectField} label="Gender" className="h-mui-native__select">
              <MenuItem value="m">Male</MenuItem>
              <MenuItem value="f">Female</MenuItem>
              <MenuItem value="n">No Answer</MenuItem>
            </Field>
            <Field data-testid="diabetesType" name="diabetesType" component={MuiSelectField} label="Diabetes Type" className="h-mui-native__select">
              <MenuItem value={1}>Type 1</MenuItem>
              <MenuItem value={2}>Type 2</MenuItem>
              <MenuItem value={3}>Prediabetes</MenuItem>
              <MenuItem value={4}>Gestational</MenuItem>
            </Field>
            <Field
              component={MuiTextField}
              label="Address"
              name="addressOne"
            />
            <Field
              component={MuiTextField}
              label="City"
              name="cityVillage"
            />
            <Field
              component={MuiSelectField}
              className="h-mui-native__select"
              label="State"
              name="stateProvince"
              data-testid="stateProvince"
            >
              <MenuItem value="" />
              {statesArray.map((countryState, key) => {
                return (<MenuItem value={countryState.value} key={key}>{countryState.name}</MenuItem>);
              })}
            </Field>
            <Field
              component={MuiTextField}
              label="ZIP Code"
              name="postalCode"
            />
            <Field
              component={MuiTextField}
              label="Cell Phone"
              name="cellNumber"
              parse={normalizePhone}
              placeholder="Cell Phone"
              endAdornment={
                <AboutPhoneStatus status={props.cellNumberVerified}/>
              }
            />
            <Field
              component={MuiTextField}
              label="Home Phone"
              name="homeNumber"
              parse={normalizePhone}
              placeholder="Home Phone"
            />
            <input
              className="c-button c-button--block c-button--action c-button--submit"
              disabled={pristine || submitting}
              type="submit"
              value="Save"
            />
          </form>
        );
      }}
    </Form>
  );
}

AboutMeForm.propTypes = propTypes;
