/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import TerminateAccountButtonContainer from '../containers/TerminateAccountButtonContainer';
// Icons
import ChevronIcon from './icons/ChevronIcon';

export default function Help() {
  return (
    <section className="c-section">
      <Link className="c-nav-header" to="/profile">
        <ChevronIcon />
        <h2 className="c-nav-header__label">Help</h2>
      </Link>
      <div className="c-well c-well--sm c-well--dim">Resources</div>
      <a href="https://s3.amazonaws.com/uploaders.patternsforpogo.com/docs/terms_and_conditions.pdf" target="_blank" className="c-list-item">
        <span className="c-list-item__label">Terms and Conditions</span>
        <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
      </a>
      <a href="https://pogoandpatternshelp.com/" target="_blank" className="c-list-item">
        <span className="c-list-item__label">Visit the Patterns<sup>&reg;</sup> Knowledge Base</span>
        <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
      </a>
      <Link to="/help" className="c-list-item">
        <span className="c-list-item__label">Email Customer Care</span>
        <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
      </Link>
      <div className="c-list-item">
        <span className="c-list-item__label">Call Customer Service at <a href="tel:+18554647646">1-855-IMI-POGO (464-7646)</a></span>
        <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
      </div>
      <div className="c-well c-well--sm c-well--dim">Open Source Licenses</div>
      <div className="h-padded-top">
        <TerminateAccountButtonContainer />
      </div>
    </section>
  );
}
