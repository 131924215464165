import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';

export default withStyles(() => ({
  root: {
    fontSize: '0.777rem'
  },
  input: {
    fontSize: '0.777rem'
  },
  caption: {
    fontSize: '0.777rem'
  }
}))(TablePagination);
