/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import GlucoseTargets from '../components/GlucoseTargets';
import { getPatientTargets, putPatientTargets } from '../actions/settingsActions';
import { getStatus } from '../actions/statusActions';
import WithOnboardingTracking from './withOnboardingTracking';

function GlucoseTargetsContainer(props) {
  return (
    <WithOnboardingTracking type="targets">
      <GlucoseTargets {...props} />
    </WithOnboardingTracking>
  );
}

function mapStateToProps(state) {
  return {
    initialValues: {
      hypoLimit: state.settings.getIn(['patientTargets', 'hypoLimit']),
      targetLow: state.settings.getIn(['patientTargets', 'targetLow']),
      targetHigh: state.settings.getIn(['patientTargets', 'targetHigh']),
      hyperLimit: state.settings.getIn(['patientTargets', 'hyperLimit']),
      preMealLow: state.settings.getIn(['patientTargets', 'preMealLow']),
      preMealHigh: state.settings.getIn(['patientTargets', 'preMealHigh']),
      postMealLow: state.settings.getIn(['patientTargets', 'postMealLow']),
      postMealHigh: state.settings.getIn(['patientTargets', 'postMealHigh']),
      eA1cTarget: state.settings.getIn(['patientTargets', 'eA1cTarget'])
    }
  };
}

export default connect(mapStateToProps, {
  getPatientTargets,
  getStatus,
  putPatientTargets
})(GlucoseTargetsContainer);
