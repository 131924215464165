import actionTypes from '../constants/actionTypes';

export function setUserEmail(email) {
  return {
    type: actionTypes.COUNTLY_SET_USER_EMAIL,
    payload: email
  };
}

export function setPageName(pageName, pageGroup) {
  return {
    type: actionTypes.COUNTLY_SET_PAGE,
    payload: {
      pageName,
      pageGroup
    }
  };
}
