/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import DayHeadings from './DayHeadings';
import MonthControl from './MonthControl';
import Week from './Week';

dayjs.extend(utc);

const propTypes = {
  monthNumber: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  select: PropTypes.func,
  selectedFrom: PropTypes.string,
  selectedTo: PropTypes.string,
  selecting: PropTypes.string,
  weeks: PropTypes.array,
  year: PropTypes.number
};

function Calendar(props) {
  const {
    monthNumber,
    next,
    prev,
    select,
    selectedFrom,
    selectedTo,
    selecting,
    weeks,
    year
  } = props;

  return (
    <div className="c-calendar">
      <MonthControl
        monthName={dayjs.utc().month(monthNumber - 1).format('MMMM')}
        next={next}
        prev={prev}
        year={year}
      />
      <DayHeadings days={weeks[0]} />
      {weeks.map((week, i) => {
        return (
          <Week
            key={i}
            days={week}
            monthNumber={monthNumber}
            select={select}
            selectedFrom={selectedFrom}
            selectedTo={selectedTo}
            selecting={selecting}
          />
        );
      })}
    </div>
  );
}

Calendar.propTypes = propTypes;

export default Calendar;
