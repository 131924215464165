/* istanbul ignore file */
import { connect } from 'react-redux';

import PatternsSnackbar from '../components/PatternsSnackbar';
import { closeSnackbar } from '../actions/snackbarActions';

const mapStateToProps = state => {
  return {
    message: state.snackbar.get('message'),
    open: state.snackbar.get('open')
  };
};

export default connect(mapStateToProps, { closeSnackbar })(PatternsSnackbar);
