import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ChevronIcon from 'src/components/icons/ChevronIcon';
import { Field } from 'react-final-form';
import MuiToggle from 'src/components/muiComponents/MuiToggle';
import PropTypes from 'prop-types';
import Text from 'src/shared/Text/Text';
import { useStyles } from './SelectorTypes.styles';
import { Grid } from '@material-ui/core';
import Divider from 'src/shared/Divider/Divider';

const propTypes = {
  values: PropTypes.object.isRequired,
  setInsulinType: PropTypes.func.isRequired
};

export default function SelectorTypes({ values, setInsulinType }) {
  const classes = useStyles();

  const returnCountOfTrueValues = (insulinType) => {
    let count = 0;
    for (const obj of insulinType) {
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key) && obj[key] === true) {
          count++;
        }
      }
    }
    return count;
  };

  return (
    <Fragment>
      <Link className="c-nav-header" to="/profile">
        <ChevronIcon />
        <h2 className="c-nav-header__label">Medications</h2>
      </Link>

      <h2 className="c-section__title">Tell us how you are managing your diabetes</h2>

      <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
        <Grid item xs={10}>
          <Text font="bold" fontSize={18} lineHeight={20}>
            Oral Medication
          </Text>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <Field
              component={MuiToggle}
              type="checkbox"
              name="medicationTypes.oral"
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider color="body-light"/>

      <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
        <Grid item xs={10}>
          <Text font="bold" fontSize={18} lineHeight={20}>
            Insulin
          </Text>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <Field
              component={MuiToggle}
              type="checkbox"
              name="medicationTypes.insulin"
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider color="body-light"/>

      {values?.medicationTypes?.insulin && (
        <div>
          <div className={classes.subHeader}>
            <Text fontSize={16} lineHeight={32} color="secondary">
              How are you administering (or taking) insulin?
            </Text>
          </div>

          <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Text font="bold" fontSize={18} lineHeight={20}>
                Pen or Syringe
              </Text>
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <Field
                  component={MuiToggle}
                  type="checkbox"
                  name="medicationTypes.injectable"
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider color="body-light"/>

          {values?.medicationTypes?.injectable && (
            <div className="c-indented-list">
              <div data-testid="long-acting" className="c-list-item h-cursor__pointer" onClick={() => setInsulinType('longActing')}>
                <Text font="bold" fontSize={18} lineHeight={32} className="h-cursor__pointer">
                  Long Acting
                </Text>
                <div className="c-list-item__flex-right">
                  {returnCountOfTrueValues(values?.insulins?.injectableLong)} Insulins
                </div>
                <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
              </div>

              <div data-testid="fast-acting" className="c-list-item h-cursor__pointer" onClick={() => setInsulinType('fastActing')}>
                <Text font="bold" fontSize={18} lineHeight={32} className="h-cursor__pointer">
                  Fast Acting
                </Text>
                <div className="c-list-item__flex-right">
                  {returnCountOfTrueValues(values?.insulins?.injectableFast)} Insulins
                </div>
                <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
              </div>
            </div>
          )}

          <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Text font="bold" fontSize={18} lineHeight={20}>
                Pump
              </Text>
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <Field
                  component={MuiToggle}
                  type="checkbox"
                  name="medicationTypes.pump"
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider color="body-light"/>

          {values?.medicationTypes?.pump && (
            <div data-testid="pump-fast-acting" className="c-indented-list">
              <div className="c-list-item h-cursor__pointer" onClick={() => setInsulinType('pump')}>
                <Text font="bold" fontSize={18} lineHeight={32} className="h-cursor__pointer">
                  Fast Acting
                </Text>
                <div className="c-list-item__flex-right">
                  {returnCountOfTrueValues(values?.insulins?.pump)} Insulins
                </div>
                <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
              </div>
            </div>
          )}

          <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Text font="bold" fontSize={18} lineHeight={20}>
                Inhaled
              </Text>
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <Field
                  component={MuiToggle}
                  type="checkbox"
                  name="medicationTypes.inhaled"
                />
              </Grid>
            </Grid>
          </Grid>

          {values?.medicationTypes?.inhaled && (
            <div data-testid="inhaled-insulins" className="c-indented-list">
              <div className="c-list-item h-cursor__pointer" onClick={() => setInsulinType('inhaled')}>
                <Text font="bold" fontSize={18} lineHeight={32} className="h-cursor__pointer">
                  Inhaled Insulins
                </Text>
                <div className="c-list-item__flex-right">
                  {returnCountOfTrueValues(values?.insulins?.inhaled)} Insulins
                </div>
                <ChevronIcon className="c-icon__black-stroke c-icon--lg"/>
              </div>
            </div>
          )}
        </div>
      )}

      <input
        className="c-button c-button--block c-button--action c-button--submit"
        type="submit"
        value="Save"
      />
    </Fragment>
  );
}

SelectorTypes.propTypes = propTypes;
