import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  notificationSettings: new Immutable.Map({
    addCircleEvents: new Immutable.Map({}),
    changeCircleEvents: new Immutable.Map({}),
    hypoEvents: new Immutable.Map({}),
    hyperEvents: new Immutable.Map({}),
    newReadings: new Immutable.Map({}),
    proactiveTestingReminders: new Immutable.Map({})
  }),
  patientTargets: new Immutable.Map(),
  testingTimes: new Immutable.List([]),
  meters: new Immutable.List()
});

export default function settingsReducer(state = INITIAL_STATE, action) { // eslint-disable-line complexity
  switch (action.type) {
  case actionTypes.REFRESH_TOKEN_START:
  case actionTypes.DELETE_PATIENT_METER_START:
  case actionTypes.GET_NOTIFICATION_SETTINGS_START:
  case actionTypes.GET_PATIENT_METERS_START:
  case actionTypes.GET_PATIENT_TARGETS_START:
  case actionTypes.GET_PATIENT_TESTING_START:
  case actionTypes.POST_PATIENT_METER_START:
  case actionTypes.PUT_NOTIFICATION_SETTINGS_START:
  case actionTypes.PUT_PATIENT_TARGETS_START:
    return state.set('isActive', true);

  case actionTypes.GET_NOTIFICATION_SETTINGS_SUCCESS:
    return state.withMutations((map) => {
      map.set('notificationSettings', Immutable.fromJS(action.payload.notificationSettings));
      map.set('isActive', false);
    });

  case actionTypes.GET_PATIENT_TARGETS_SUCCESS:
    return state.withMutations((map) => {
      map.set('patientTargets', Immutable.fromJS(action.payload.patientTargets));
      map.set('isActive', false);
    });

  case actionTypes.GET_PATIENT_METERS_SUCCESS:
    return state.withMutations((map) => {
      map.set('meters', Immutable.fromJS(action.payload.meters));
      map.set('isActive', false);
    });

  case actionTypes.GET_PATIENT_TESTING_SUCCESS:
    return state.withMutations((map) => {
      map.set('testingTimes', Immutable.fromJS(action.payload.testingTimes));
      map.set('isActive', false);
    });

  case actionTypes.REFRESH_TOKEN_SUCCESS:
  case actionTypes.DELETE_PATIENT_METER_SUCCESS:
  case actionTypes.POST_PATIENT_METER_SUCCESS:
  case actionTypes.PUT_NOTIFICATION_SETTINGS_SUCCESS:
  case actionTypes.PUT_PATIENT_TARGETS_SUCCESS:
  case actionTypes.DELETE_PATIENT_METER_FAILURE:
  case actionTypes.GET_PATIENT_METERS_FAILURE:
  case actionTypes.GET_PATIENT_TARGETS_FAILURE:
  case actionTypes.POST_PATIENT_METER_FAILURE:
  case actionTypes.PUT_NOTIFICATION_SETTINGS_FAILURE:
  case actionTypes.PUT_PATIENT_TARGETS_FAILURE:
  case actionTypes.GET_PATIENT_TESTING_FAILURE:
  case actionTypes.GET_NOTIFICATION_SETTINGS_FAILURE:
    return state.set('isActive', false);

  case actionTypes.EDIT_PATIENT_TESTING:
    return state.withMutations(map => {
      map.update('testingTimes', list => list.update(action.payload.index, () => Immutable.fromJS(action.payload.testingTime)));
    });

  case actionTypes.SIGN_OUT:
  case actionTypes.REFRESH_TOKEN_FAILURE:
    return INITIAL_STATE;

  default:
    return state;
  }
}
