/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  days: PropTypes.array
};

function DayHeadings({ days }) {
  return (
    <div className="c-calendar__week">
      {days.map((day, i) => {
        return (
          <div
            key={i}
            className="c-calendar__day c-calendar__day--heading"
          >
            {day.format('ddd')}
          </div>
        );
      })}
    </div>
  );
}

DayHeadings.propTypes = propTypes;

export default DayHeadings;
