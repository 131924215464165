import apiService from './apiService';

async function getRangeOfPGHD(from, to) {
  const { data } = await apiService.get(`/pghd/range/${from}/${to}`);
  return data;
}

async function getRangeOfSummaries(from, to) {
  const { data } = await apiService.get(`/summaries/range/${from}/${to}`);
  return data;
}

async function getRangeOfWeights(from, to) {
  const { data } = await apiService.get(`/weights/range/${from}/${to}`);
  return data;
}

export default {
  getRangeOfPGHD,
  getRangeOfSummaries,
  getRangeOfWeights
};
