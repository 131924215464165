import moment from 'moment';

export const dataTypes = [
  { id: 1, label: 'Steps', value: 'steps', unit: 'steps', store: 'validic', totals: 'stepTotals', graph: 'bar' },
  { id: 3, label: 'Weight', value: 'weights', unit: 'weight', store: 'validic', totals: 'weightTotals', graph: 'bar' },
  { id: 7, label: 'Hydration', value: 'water', unit: 'value', store: 'validic', totals: 'waterTotals', graph: 'bar' },
  { id: 4, label: 'Calories Burned', value: 'caloriesBurned', unit: 'caloriesBurned', store: 'validic', totals: 'caloriesBurnedTotals', graph: 'bar' },
  { id: 6, label: 'Sleep', value: 'sleeps', unit: 'totalSleepDuration', store: 'validic', totals: 'sleepTotals', graph: 'bar' },
  { id: 2, label: 'Carbohydrates', value: 'carbs', unit: 'carbohydrates', store: 'validic', totals: 'carbsTotals', graph: 'bar' },
  { id: 5, label: 'Calories Consumed', value: 'foods', unit: 'calories', store: 'validic', totals: 'caloriesConsumedTotals', graph: 'bar' }
];

export function createPerDayArray(dataArray, type, numDays, startTime) {
  const dayArray = [];
  for (let i = 0; i < numDays; i++) {
    // starting day
    const day = moment.utc(startTime).add(i, 'days');
    // current day value
    const dayVal = {
      start: day.clone().startOf('day'),
      end: day.clone().endOf('day')
    };
    // Only loop through vals if there are vals in array
    if (dataArray && dataArray.length) {
      // Loop through dataArray to find values in the timeframe
      dataArray.forEach((v) => {
        const ts = v.timestamp || v.endTimestamp;

        if (dayVal.start.isSameOrBefore(ts) && dayVal.end.isSameOrAfter(ts) && v[type]) {
          if (!dayVal[type]) {
            dayVal[type] = 0;
          }
          if (v.timestamp) {
            dayVal.timestamp = v.timestamp;
          }
          if (v.endTimestamp) {
            dayVal.endTimestamp = v.endTimestamp;
          }
          dayVal[type] += v[type];
        }
      });
    }
    // Add the day regardless
    dayArray.push(dayVal);
  }
  // return the array
  return dayArray;
}

export function deteremineAverageValue(data, deviceType) {
  if (data && data[deviceType]) {
    return data[deviceType].average < 1 ? 1 : data[deviceType].average;
  }
}

export function returnValueForHeader(avgValue, durationInHours) {
  if (!avgValue) {
    return '--';
  }

  if (durationInHours) {
    const hours = Math.floor(avgValue / 60);
    const minutes = Math.floor((avgValue - (hours * 60)));

    return `${hours} hours ${minutes} minutes`;
  }

  return avgValue;
}

export function returnValueForChart(avgValue, durationInHours) {
  if (!avgValue) {
    return '';
  }

  if (durationInHours) {
    return Math.round(avgValue / 60);
  }

  return avgValue;
}

export function getDataType(compareType) {
  return dataTypes.find((types) => types.value === compareType) || {};
}

export function hasSomeLengthOfValues(data, deviceType) {
  return data &&
    data[deviceType] &&
    data[deviceType].values &&
    data[deviceType].values.length;
}

export function makeComparisonValues(deviceType, dataType, values, timeframe) {
  const comparisonValues = values && deviceType && values[deviceType];

  return createPerDayArray(
    comparisonValues && comparisonValues.values,
    dataType.unit,
    timeframe.diffDays() + 1,
    timeframe.get('start')
  );
}

function getMinMaxValues(obj) {
  const keys = Object.keys(obj);
  let maxValue = (obj[keys[0]] || {}).sum;

  for (let i = 0; i < keys.length; i++) {
    const value = obj[keys[i]].sum;
    if (value > maxValue) {
      maxValue = value;
    }
  }

  return [0, Math.round(maxValue + maxValue * 0.1)];
}

export function makeYDomainValues(arr, type) {
  if (!arr) {
    return [0, 0];
  }
  // Create an object to hold the summed values for each date
  const sums = {};

  // Loop through the array of objects and add the value to the sum for each date
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const ts = item.timestamp || item.endTimestamp;
    if (ts) {
      const date = String(ts).substring(0, 10);
      const sumValue = item[type];

      if (sums[date]) {
        sums[date].sum += sumValue;
      } else {
        sums[date] = { sum: sumValue };
      }
    }
  }

  // Return an array with the minimum and maximum values
  return getMinMaxValues(sums);
}
