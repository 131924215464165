import PropTypes from 'prop-types';
import React, { Fragment, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { StyledDialogTitle } from '../../../../../components/muiStyledComponents/StyledDialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { StyledDialogActions } from '../../../../../components/muiStyledComponents/StyledDialogActions';
import { Container, Grid, Dialog } from '@material-ui/core';

const propTypes = {
  item: PropTypes.object,
  deleteSentInvitation: PropTypes.func.isRequired,
  memoizedFetch: PropTypes.func.isRequired,
  itemsListCount: PropTypes.number
};

export default function DeclineBtn({
  item,
  deleteSentInvitation,
  memoizedFetch,
  itemsListCount
}) {
  const [isOpen, setIsOpen] = useState(false);
  const descriptionElementRef = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const handleRemove = async () => {
    try {
      await deleteSentInvitation(item.invitationId);
      memoizedFetch('edit', itemsListCount === 1);
      closeModal();
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  return (
    <Fragment>
      <button
        className="c-button c-button--danger-hollow c-button--condensed c-button--trailing h-ws-pre jest-cancel"
        onClick={openModal}
      >
        Decline
      </button>

      <Dialog
        open={isOpen}
        onClose={closeModal}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        data-testid="dialog-invites"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperComponent={Paper}
        PaperProps={{ elevation: 0 }}
      >
        <Container maxWidth="sm" className="h-padded-lg">
          <Grid container justifyContent="space-between" alignItems="center" direction="column">
            <StyledDialogTitle>
              <strong>Decline Invitation</strong>
            </StyledDialogTitle>
            <DialogContent
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="h-center h-padded-bottom">
                Please confirm that you want to decline this invitation?
              </div>
            </DialogContent>
            <StyledDialogActions>
              <button className="c-button c-button--hollow jest-cancel" onClick={closeModal}>
                Cancel
              </button>
              <button
                className="c-button c-button--action jest-proceed"
                onClick={handleRemove}
              >
                Confirm
              </button>
            </StyledDialogActions>
          </Grid>
        </Container>
      </Dialog>
    </Fragment>
  );
}

DeclineBtn.propTypes = propTypes;
