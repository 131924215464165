import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// Will group by date prop
const reduceDataByProp = (propName) => (acc, item) => {
  if (acc[item.date]) {
    acc[item.date] = {
      ...acc[item.date],
      [propName]: Number(acc[item.date][propName]) + Number(item[propName])
    };
  } else {
    acc[item.date] = item;
  }
  return acc;
};

function dataForDevice(data, deviceType) {
  if (data[deviceType] === undefined) {
    return [];
  }

  return data[deviceType].values;
}

export function mapStepsData(data, deviceType) {
  const mapedSource = orderBy(dataForDevice(data, deviceType), 'timestamp', 'desc')
    .filter(datum => !!datum.steps)
    .map(datum => ({
      steps: datum.steps,
      date: dayjs.utc(datum.timestamp).format('MMM D, YYYY')
    }));

  const result = mapedSource.reduce(reduceDataByProp('steps'), {});

  return Object.values(result || {});
}

export function mapSleepData(data, deviceType) {
  const mapedSource = orderBy(dataForDevice(data, deviceType), 'endTimestamp', 'desc')
    .filter(datum => !!datum.totalSleepDuration)
    .map(datum => ({
      minutes: datum.totalSleepDuration,
      date: dayjs.utc(datum.endTimestamp).format('MMM D, YYYY')
    }));
  const result = mapedSource.reduce(reduceDataByProp('minutes'), {});

  return Object.values(result || {});
}

export function mapWeightData(data, deviceType) {
  const mappedSource = orderBy(dataForDevice(data, deviceType), 'timestamp', 'desc')
    .filter(datum => !!datum.weight)
    .map(datum => ({
      pounds: datum.weight,
      date: dayjs.utc(datum.timestamp).format('MMM D, YYYY')
    }));

  return Object.values(mappedSource || {});
}

export function mapCaloriesBurnedData(data, deviceType) {
  const mappedSource = orderBy(dataForDevice(data, deviceType), 'timestamp', 'desc')
    .filter(datum => !!datum.caloriesBurned)
    .map(datum => ({
      kcal: datum.caloriesBurned,
      date: dayjs.utc(datum.timestamp).format('MMM D, YYYY')
    }));
  const result = mappedSource.reduce(reduceDataByProp('kcal'), {});

  return Object.values(result || {});
}

export function mapHydrationData(data, deviceType) {
  const mappedSource = orderBy(dataForDevice(data, deviceType), 'timestamp', 'desc')
    .filter(datum => !!datum.value)
    .map(datum => ({
      ounces: datum.value,
      date: dayjs.utc(datum.timestamp).format('MMM D, YYYY')
    }));
  const result = mappedSource.reduce(reduceDataByProp('ounces'), {});

  return Object.values(result || {});
}

export function mapCarbsData(data, deviceType) {
  const mappedSource = orderBy(dataForDevice(data, deviceType), 'timestamp', 'desc')
    .filter(datum => !!datum.carbohydrates)
    .map(datum => ({
      carbs: datum.carbohydrates || 0,
      date: dayjs.utc(datum.timestamp).format('MMM D, YYYY')
    }));
  const result = mappedSource.reduce(reduceDataByProp('carbs'), {});

  return Object.values(result || {});
}

export function mapCaloriesConsumedData(data, deviceType) {
  const grouped = Object.values(groupBy(dataForDevice(data, deviceType), datum => new Date(datum.timestamp).toDateString()));

  const reduced = grouped.reduce((acc, foods) => {
    acc.push({
      sortableTimestamp: foods[0].timestamp,
      timestamp: dayjs.utc(foods[0].timestamp).format('MMM D, YYYY'),
      total: foods.reduce((total, food) => total + food.calories, 0)
    });
    return acc;
  }, []);

  const mappedSource = orderBy(reduced, 'sortableTimestamp', 'desc')
    .filter(datum => !!datum.total)
    .map(datum => ({
      kcal: datum.total,
      date: datum.timestamp
    }));

  const result = mappedSource.reduce(reduceDataByProp('kcal'), {});

  return Object.values(result || {});
}

export const stepsHeaders = [
  { label: 'Steps', dataKey: 'steps', boldValue: true },
  { label: 'Date', dataKey: 'date' }
];

export const sleepHeaders = [
  { label: 'Minutes', dataKey: 'minutes', boldValue: true },
  { label: 'Date', dataKey: 'date' }
];

export const weightHeaders = [
  { label: 'Lbs', dataKey: 'pounds', boldValue: true },
  { label: 'Date', dataKey: 'date' }
];

export const caloriesHeaders = [
  { label: 'Calories', dataKey: 'kcal', boldValue: true },
  { label: 'Date', dataKey: 'date' }
];

export const hydrationHeaders = [
  { label: 'Fl Oz.', dataKey: 'ounces', boldValue: true },
  { label: 'Date', dataKey: 'date' }
];

export const carbsHeaders = [
  { label: 'Carbohydrates', dataKey: 'carbs', boldValue: true },
  { label: 'Date', dataKey: 'date' }
];
