/* istanbul ignore file */
import React from 'react';
import { Route } from 'react-router-dom';

import ProfileMenu from '../ProfileMenu';
import SharingCircleAboutMeContainer from '../../containers/SharingCircleAboutMeContainer';
import SharingCircleNotificationSettingsContainer from '../../containers/SharingCircleNotificationSettingsContainer';
import SCCreateAccountButtonContainer from '../../containers/SCCreateAccountButtonContainer';

const settingsList = [
  { container: SharingCircleAboutMeContainer, label: 'About Me', location: '/sharing-circle/profile/about-me' },
  { container: SharingCircleNotificationSettingsContainer, label: 'Notifications', location: '/sharing-circle/profile/notifications' }
];

export default () => {
  return (
    <div>
      <div className="l-container l-split-view">
        <div className="l-split-view__sidebar">
          <ProfileMenu settingsList={settingsList} showSignOut={false} />
          <div className="c-sidebar h-padded-no-top">
            <SCCreateAccountButtonContainer />
          </div>
        </div>
        <div className="l-split-view__body">
          {settingsList.map((setting, idx) => <Route exact key={idx} path={setting.location} component={setting.container} />)}
        </div>
      </div>
    </div>
  );
};
