/* istanbul ignore file */
import moment from 'moment';

export function getPreviousTimeframe(state) {
  return {
    start: moment(state.timeframe.get('start'))
      .subtract(state.timeframe.get('span'), state.timeframe.get('units'))
      .format(),
    end: moment(state.timeframe.get('start')).endOf('day').format()
  };
}

export function getTimeframe(state) {
  return {
    start: moment.utc(state.timeframe.get('start')),
    end: moment.utc(state.timeframe.get('end')),
    span: state.timeframe.get('span'),
    units: state.timeframe.get('units')
  };
}
