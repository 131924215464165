/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { countlyTrackButton } from '../../services/countlyService';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  countlyevent: PropTypes.string.isRequired,
  countlyview: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  dataTestid: PropTypes.string,
  disabled: PropTypes.bool,
  tag: PropTypes.string
};

const defaultProps = {
  tag: 'button'
};

export default function CountlyTrackedButton(props) {
  const { onClick, children, ...restProps } = props;
  const DynamicTag = props.tag;

  function onClickCallback() {
    // some buttons have type="submit" attribute, and they don't have click event
    onClick && onClick();
    countlyTrackButton(props.countlyevent, props.countlyview);
  }

  return (
    <DynamicTag data-testid={props.dataTestid || 'tag'} {...restProps} onClick={onClickCallback}>
      {children}
    </DynamicTag>
  );
}

CountlyTrackedButton.propTypes = propTypes;

CountlyTrackedButton.defaultProps = defaultProps;
