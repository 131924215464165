import { createSelector } from 'reselect';
import moment from 'moment';
import { map } from 'lodash';
import { getTimeframe } from './timeframeSelectors';
import { timeframeEndTimeFilter, timeframeFilter } from '../utils/timeframeHelpers';
import { convertToTimeZone, countUniqueDates } from '../utils/dateFormatter';

/* istanbul ignore next */
function getFoodsCache(state) {
  return state.healthData.getIn(['foods', 'cache']);
}

/* istanbul ignore next */
export function getFoodsCacheDeviceList(state) {
  return state.deviceList.get('foodsDevices');
}

/* istanbul ignore next */
function getSleepsCache(state) {
  return state.healthData.getIn(['sleeps', 'cache']);
}

/* istanbul ignore next */
export function getSleepsCacheDeviceList(state) {
  return state.deviceList.get('sleepsDevices');
}

/* istanbul ignore next */
function getSummariesCache(state) {
  return state.healthData.getIn(['summaries', 'cache']);
}

/* istanbul ignore next */
export function getSummariesCacheDeviceList(state) {
  return state.deviceList.get('summariesDevices');
}

/* istanbul ignore next */
function getWeightsCache(state) {
  return state.healthData.getIn(['weights', 'cache']);
}

export function getWeightsCacheDeviceList(state) {
  return state.deviceList.get('weightsDevices');
}

/* istanbul ignore next */
export function getWaterDeviceList(state) {
  return state.deviceList.get('waterDevices');
}

/* istanbul ignore next */
function getWeightsDevice(state) {
  return state.validic.getIn(['devices', 'weights']);
}

/* istanbul ignore next */
function getCaloriesBurnedDevice(state) {
  return state.validic.getIn(['devices', 'caloriesBurned']);
}

/* istanbul ignore next */
function getFoodsDevice(state) {
  return state.validic.getIn(['devices', 'foods']);
}

/* istanbul ignore next */
function getSleepsDevice(state) {
  return state.validic.getIn(['devices', 'sleeps']);
}

/* istanbul ignore next */
function getStepsDevice(state) {
  return state.validic.getIn(['devices', 'steps']);
}

/* istanbul ignore next */
function getWaterDevice(state) {
  return state.validic.getIn(['devices', 'water']);
}

/* istanbul ignore next */
function getCarbsDevice(state) {
  return state.validic.getIn(['devices', 'carbs']);
}

export function makeGetCaloriesBurnedDevice() {
  return createSelector(
    [getSummariesCacheDeviceList, getCaloriesBurnedDevice],
    (connectedDevices, deviceType) => {
      return connectedDevices.find((device) => device.get('type') === deviceType);
    }
  );
}

export function makeGetFoodsDevice() {
  return createSelector(
    [getFoodsCacheDeviceList, getFoodsDevice],
    (connectedDevices, deviceType) => {
      return connectedDevices.find((device) => device.get('type') === deviceType);
    }
  );
}

export function makeGetSleepsDevice() {
  return createSelector(
    [getSleepsCacheDeviceList, getSleepsDevice],
    (connectedDevices, deviceType) => {
      return connectedDevices.find((device) => device.get('type') === deviceType);
    }
  );
}

export function makeGetStepsDevice() {
  return createSelector(
    [getSummariesCacheDeviceList, getStepsDevice],
    (connectedDevices, deviceType) => {
      return connectedDevices.find((device) => device.get('type') === deviceType);
    }
  );
}

export function makeGetWeightsDevice() {
  return createSelector(
    [getWeightsCacheDeviceList, getWeightsDevice],
    (connectedDevices, deviceType) => {
      return connectedDevices.find((device) => device.get('type') === deviceType);
    }
  );
}

export function makeGetWaterDevice() {
  return createSelector(
    [getWaterDeviceList, getWaterDevice],
    (connectedDevices, deviceType) => {
      return connectedDevices.find((device) => device.get('type') === deviceType);
    }
  );
}

export function makeGetCarbsDevice() {
  return createSelector(
    [getFoodsCacheDeviceList, getCarbsDevice],
    (connectedDevices, deviceType) => {
      return connectedDevices.find((device) => device.get('type') === deviceType);
    }
  );
}

export function makeGroupedCaloriesBurnedFromSummariesInTimeframe() {
  return createSelector(
    [getSummariesCache, getTimeframe, getSummariesCacheDeviceList],
    (summariesCache, timeframe, connectedDevices) => {
      return summariesCache
        .filter(timeframeFilter(timeframe))
        .toList()
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceFiltered = source.filter(el => !!el.get('caloriesBurned'));
          const sourceConverted = sourceFiltered.toJS();
          const total = source.reduce((acc, cur) => cur.get('caloriesBurned') + acc, 0);
          const uniqueDatesCount = countUniqueDates(sourceConverted);
          const avarageCalc = total / uniqueDatesCount;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc);

          return {
            total,
            average,
            details: connectedDevices.find((d) => d.get('type') === type),
            values: sourceConverted
          };
        });
    }
  );
}

export function makeGroupedCarbsConsumedInTimeframe() {
  return createSelector(
    [getFoodsCache, getTimeframe, getFoodsCacheDeviceList],
    (cache, timeframe, connectedDevices) => {
      return cache
        .filter(timeframeFilter(timeframe))
        .toList()
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceFiltered = source.filter(el => !!el.get('carbohydrates'));
          const sourceConverted = sourceFiltered.toJS();
          const total = source.reduce((acc, cur) => cur.get('carbohydrates') + acc, 0);
          const uniqueDatesCount = countUniqueDates(sourceConverted);
          const avarageCalc = total / uniqueDatesCount;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc);

          return {
            total,
            average,
            details: connectedDevices.find((d) => d.get('type') === type),
            values: sourceConverted
          };
        });
    }
  );
}

export function makeGroupedCaloriesConsumedInTimeframe() {
  return createSelector(
    [getFoodsCache, getTimeframe, getFoodsCacheDeviceList],
    (cache, timeframe, connectedDevices) => {
      return cache
        .filter(timeframeFilter(timeframe))
        .toList()
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceFiltered = source.filter(el => !!el.get('calories'));
          const sourceConverted = sourceFiltered.toJS();
          const total = source.reduce((acc, cur) => cur.get('calories') + acc, 0);
          const values = sourceConverted.map(value => ({
            ...value,
            totalCalories: value.calories
          }));
          const uniqueDatesCount = countUniqueDates(sourceConverted);
          const avarageCalc = total / uniqueDatesCount;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc);

          return {
            total,
            average,
            details: connectedDevices.find((d) => d.get('type') === type),
            values
          };
        });
    }
  );
}

export function makeGroupedSleepsInTimeframe() {
  return createSelector(
    [getSleepsCache, getTimeframe, getSleepsCacheDeviceList],
    (sleepsCache, timeframe, connectedDevices) => {
      return sleepsCache
        .filter(timeframeEndTimeFilter(timeframe))
        .toList()
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceFiltered = source.filter(el => !!el.get('totalSleepDuration'));
          const sourceConverted = sourceFiltered.toJS();
          const total = source.reduce((acc, cur) => cur.get('totalSleepDuration') + acc, 0);
          const uniqueDatesCount = countUniqueDates(sourceConverted, 'endTimestamp');
          const avarageCalc = total / uniqueDatesCount;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc / 60);

          return {
            average,
            total: Math.round(total / 60),
            details: connectedDevices.find((d) => d.get('type') === type),
            values: map(sourceConverted, (el) => {
              el.totalSleepDuration = el.totalSleepDuration / 60;
              return el;
            })
          };
        });
    }
  );
}

export function makeGroupedStepsFromSummariesInTimeframe() {
  return createSelector(
    [getSummariesCache, getTimeframe, getSummariesCacheDeviceList],
    (summariesCache, timeframe, connectedDevices) => {
      return summariesCache
        .filter(timeframeFilter(timeframe))
        .toList()
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceFiltered = source.filter(el => !!el.get('steps'));
          const total = sourceFiltered.reduce((acc, cur) => cur.get('steps') + acc, 0);
          const sourceConverted = sourceFiltered.toJS();
          const uniqueDatesCount = countUniqueDates(sourceConverted);
          const avarageCalc = total / uniqueDatesCount;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc);

          return {
            total,
            average,
            details: connectedDevices.find((d) => d.get('type') === type),
            values: sourceConverted
          };
        });
    }
  );
}

export function makeGroupedWeightsInTimeframeForMhealthPage() {
  const kgConversion = 2.20462;

  return createSelector(
    [getWeightsCache, getTimeframe, getWeightsCacheDeviceList],
    (weightsCache, timeframe, connectedDevices) => {
      return weightsCache
        .filter(timeframeFilter(timeframe))
        .toList()
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceFiltered = source.filter((el) => !!el.get('weight'));
          const sourceConverted = sourceFiltered.sortBy((f) => moment(f.get('timestamp')).valueOf()).toJS();
          const sourceInKg = sourceConverted.map((datum) => ({ ...datum, weight: datum.weight * kgConversion }));
          const total = sourceInKg.reduce((acc, cur) => cur.weight + acc, 0);
          const uniqueDatesCount = countUniqueDates(sourceConverted);
          const avarageCalc = total / uniqueDatesCount;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc);

          return {
            total,
            average,
            details: connectedDevices.find((d) => d.get('type') === type),
            values: sourceInKg
          };
        });
    }
  );
}

export function makeGroupedWeightsInTimeframe() {
  const kgConversion = 2.20462;

  return createSelector(
    [getWeightsCache, getTimeframe, getWeightsCacheDeviceList],
    (weightsCache, timeframe, connectedDevices) => {
      return weightsCache
        .filter(timeframeFilter(timeframe))
        .toList()
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceFiltered = source.filter((el) => !!el.get('weight'));
          const groupedByTimestamp = sourceFiltered.groupBy((unit) => String(convertToTimeZone(unit.get('timestamp'))).substring(0, 10));

          const sourceConverted = groupedByTimestamp.map((item) => {
            const itemJS = item.toJS();
            const resultItem = itemJS.reduce((accum, data) => {
              const accumData = { ...data, weight: (accum.weight || 0) + data.weight  * kgConversion };
              return accumData;
            }, {});

            return {
              ...resultItem,
              weight: resultItem.weight / itemJS.length
            };
          }).toJS();

          const sourceConvertedValues = Object.values(sourceConverted);
          const total = sourceConvertedValues.reduce((acc, cur) => cur.weight + acc, 0);
          const avarageCalc = total / sourceConvertedValues.length;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc);

          return {
            total,
            average,
            details: connectedDevices.find((d) => d.get('type') === type),
            values: sourceConvertedValues
          };
        });
    }
  );
}
