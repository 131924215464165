import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Radio from '@material-ui/core/Radio';
import DateRangePicker from './DateRangePicker';
import ChevronIcon from './icons/ChevronIcon';
import TimeframeRecord from '../records/TimeframeRecord';
import { countlyTrackTimeframeButton } from '../services/countlyService';
import {
  handleNextDateFrame,
  handlePreviousDateFrame,
  makeOptionsForCustomTimeframe
} from '../services/timeframeService';

const today = moment().endOf('day');

export default class Timeframe extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    setTimeframe: PropTypes.func.isRequired,
    shiftTimeframeBySpan: PropTypes.func.isRequired,
    timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired
  };

  state = {
    isCustom: false,
    customOpen: false,
    menuOpen: false
  };

  setCustomTimeframe = (startTime, endTime) => {
    if (!(startTime && endTime)) { return; }

    this.props.setTimeframe(makeOptionsForCustomTimeframe(startTime, endTime));
    this.setState({ isCustom: true, customOpen: false, menuOpen: false });

    // Countly timeframe tracker
    countlyTrackTimeframeButton('custom');
  }

  setTimeframe = (option) => () => {
    this.props.setTimeframe(option);
    this.toggleMenu();
    this.setState({ isCustom: false });
    // Countly timeframe tracker
    countlyTrackTimeframeButton(option.span + 1);
  }

  shouldDisableButton = () => {
    const end = moment(this.props.timeframe.get('end'));
    // Check if next date is in future, if yes than class to next button should be added
    // to show disabled state
    const nextEnd = end.utc().endOf('day').format();

    return today.isSameOrBefore(nextEnd);
  }

  handlePreviousTimeframe = () => {
    this.props.shiftTimeframeBySpan(
      handlePreviousDateFrame(this.props.timeframe.get('start'), this.props.timeframe.get('end'))
    );
    // Countly timeframe tracker
    countlyTrackTimeframeButton('previous');
  };

  handleNextTimeframe = () => {
    this.props.shiftTimeframeBySpan(
      handleNextDateFrame(this.props.timeframe.get('start'), this.props.timeframe.get('end'))
    );
    // Countly timeframe tracker
    countlyTrackTimeframeButton('next');
  }

  triggerCustomToggle = () => {
    !this.state.customOpen && this.toggleCustom();
  }

  toggleCustom = () => {
    this.setState(oldState => ({ customOpen: !oldState.customOpen }));
  }

  toggleMenu = () => {
    this.setState(oldState => ({ menuOpen: !oldState.menuOpen }));
  }

  render() {
    const { options, timeframe } = this.props;
    const start = moment(timeframe.get('start')).utc().format('MMM DD');
    const end = moment(timeframe.get('end')).utc().format('ll');
    const disabledNextButton = this.shouldDisableButton();

    const menuClasses = classNames(
      'c-timeframe__menu',
      { 'c-timeframe__menu--open': this.state.menuOpen }
    );
    const customClasses = classNames(
      'c-timeframe__choice',
      'c-timeframe__choice--custom',
      { 'c-timeframe__choice--selected': this.state.isCustom }
    );
    const nextButtomClasses = classNames(
      'c-icon--primary',
      { 'c-icon__stroke': !disabledNextButton }
    );

    return (
      <div className="c-timeframe">
        <div className="c-timeframe__output">
          <button
            data-testid="adjustBtn"
            className="c-timeframe__icon c-timeframe__icon--prev h-bg-none"
            onClick={this.handlePreviousTimeframe}
          >
            <ChevronIcon className="c-icon--primary c-icon__stroke"/>
          </button>
          <div data-testid="timeframeBtn" className="c-timeframe__display" onClick={this.toggleMenu}>
            <div className="c-timeframe__label">Timeframe:</div>
            <div className="c-timeframe__value h-ws-pre">
              {`${start} - ${end}`}
            </div>
          </div>
          <button
            disabled={disabledNextButton}
            className="c-timeframe__icon c-timeframe__icon--next h-bg-none"
            onClick={this.handleNextTimeframe}
          >
            <ChevronIcon className={nextButtomClasses}/>
          </button>
        </div>
        <div className={menuClasses}>
          <ul className="c-timeframe__default-options">
            {options.map((option, i) => {
              const selected = option.span === timeframe.get('span');
              const choiceClasses = classNames(
                'c-timeframe__choice',
                { 'c-timeframe__choice--selected': selected }
              );

              return (
                <li
                  key={i}
                  data-testid="option"
                  className={`${choiceClasses} h-cursor__pointer`}
                  onClick={this.setTimeframe(option)}
                >
                  <span>{option.label}</span>
                  <span className="c-timeframe__radio">
                    <Radio checked={selected} />
                  </span>
                </li>
              );
            })}
          </ul>
          <div
            data-testid="customRangeBtn"
            className={`${customClasses} h-cursor__pointer`}
            onClick={this.triggerCustomToggle}
          >
            <span>Custom Range</span>
          </div>
          {this.state.customOpen &&
            <DateRangePicker
              closeOnOutsideClick
              closeOnEsc
              defaultEnd={timeframe.get('end')}
              defaultStart={timeframe.get('start')}
              setCustomTimeframe={this.setCustomTimeframe}
              closeModal={this.toggleCustom}
            />}
        </div>
      </div>
    );
  }
}
