import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  query: '',
  queries: new Immutable.Map(),
  selectedFood: new Immutable.Map()
});

export default function nutritionixReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.REFRESH_TOKEN_START:
  case actionTypes.GET_NUTRITIONIX_FOOD_BY_ID_START:
  case actionTypes.GET_NUTRITIONIX_AUTOCOMPLETE_START:
    return state.set('isActive', true);

  case actionTypes.CLEAR_NUTRITIONIX_SELECTED_FOOD:
    return state.set('selectedFood', new Immutable.Map());

  case actionTypes.SET_NUTRITIONIX_QUERY:
    return state.set('query', action.payload.query);

  case actionTypes.SET_NUTRITIONIX_SELECTED_FOOD:
    return state.set('selectedFood', Immutable.fromJS(action.payload));

  case actionTypes.GET_NUTRITIONIX_FOOD_ALT_MEASURES:
    return state.setIn(['selectedFood', 'alt_measures'], action.payload.alt_measures);

  case actionTypes.GET_NUTRITIONIX_AUTOCOMPLETE_SUCCESS:
    return state.withMutations((map) => {
      map.setIn(['queries', action.payload.query], Immutable.fromJS(action.payload.foodObj));
      map.set('isActive', false);
    });

  case actionTypes.GET_NUTRITIONIX_FOOD_BY_ID_SUCCESS:
    return state.withMutations((map) => {
      map.set('selectedFood', Immutable.fromJS(action.payload.food));
      map.set('isActive', false);
    });

  case actionTypes.REFRESH_TOKEN_SUCCESS:
  case actionTypes.REFRESH_TOKEN_FAILURE:
  case actionTypes.GET_NUTRITIONIX_AUTOCOMPLETE_FAILURE:
    return state.set('isActive', false);

  case actionTypes.CLEAR_NUTRITIONIX:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
