import PropTypes from 'prop-types';
import React from 'react';
import confirm from '../utils/ConfirmHelpers';

const propTypes = {
  visible: PropTypes.bool
};

function SCCreateAccountButton({ visible }) {
  function handleClick() {
    const defaultOptions = {
      confirmText: 'Ok',
      headerText: 'Attention',
      showCancel: false
    };

    const bodyText = <span>To be a <strong>Patterns<sup>&reg;</sup></strong> patient, you need to download the mobile version of <strong>Patterns<sup>&reg;</sup></strong> from the Apple App Store or Google Play Store, register for a patient account on the mobile app and pair a POGO Automatic<sup>&reg;</sup> monitor. If you do not have an iOS or Android phone, please call Customer Support at <a href="tel:+18554647646">1-855-IMI-POGO (464-7646)</a>.</span>;
    confirm(bodyText, defaultOptions);
  }

  if (!visible) {
    return null;
  }

  return (
    <button data-testid="jest-button" className="c-button c-button--block c-button--hollow h-ws-pre" onClick={handleClick}>
      Upgrade Profile to Patient
    </button>
  );
}

SCCreateAccountButton.propTypes = propTypes;

export default SCCreateAccountButton;
