/* istanbul ignore file */
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, createMigrate, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import thunk from 'redux-thunk';
import localForage from 'localforage';
import combinedReducers from '../reducers';
import ReadingsRecord from '../records/ReadingsRecord';
import TimeframeRecord from '../records/TimeframeRecord';
// migrations
import migrationManifest from './migrations';

const persistConfig = {
  key: 'primary',
  version: 1,
  storage: localForage,
  migrate: createMigrate(migrationManifest, { debug: false }),
  transforms: [
    immutableTransform({ records: [ReadingsRecord, TimeframeRecord] })
  ],
  whitelist: [
    'activeMealTab',
    'avatar',
    'authentication',
    'countly',
    'healthData',
    'healthPlan',
    'profile',
    'readings',
    'readingTags',
    'screensSeenStatus',
    'selectedPatient',
    'settings',
    'status',
    'timeframe',
    'token',
    'validic'
  ]
};
const finalReducer = persistReducer(persistConfig, combinedReducers);

class Store {
  constructor() {
    /*
     * redux-devtools-extension is lib to track app state changes using browser,
     * there are better alternatives(not requiring to implement any code)
     * for that and i think it could be removed,
     * yet Cypress.io framework can benefit from this.
     */
    const composeEnhancers = composeWithDevTools({});

    this.store = createStore(
      finalReducer,
      composeEnhancers(
        applyMiddleware(thunk)
      )
    );

    this.hydrateStore();

    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('../reducers', () => {
        const nextRootReducer = require('../reducers').default;
        this.store.replaceReducer(nextRootReducer);
      });
    }
  }

  getHydratedState() {
    return this.hydrateStore().then(() => this.store.getState());
  }

  getStore() {
    return this.store;
  }

  hydrateStore() {
    if (!this.hydratedStore) {
      this.hydratedStore = new Promise((resolve) => {
        this.persistedStore = persistStore( // eslint-disable-line no-unused-vars
          this.store,
          null,
          () => {
            resolve(this.store.getState());
          }
        );

        if (process.env.NODE_ENV === 'production') {
          const hash = localStorage.getItem('hash');

          if (hash !== __webpack_hash__) { // eslint-disable-line
            this.persistedStore.purge();
            localStorage.setItem('hash', __webpack_hash__); // eslint-disable-line
          }
        }
      });
    }

    return this.hydratedStore;
  }
}

export default new Store();
