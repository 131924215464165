import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { countlyEndUserSession } from 'src/services/countlyService';

export class SignOut extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      authentication: PropTypes.shape({
        signOut: PropTypes.func.isRequired
      })
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    refreshToken: PropTypes.string
  };

  signOut = () => {
    this.props.actions.authentication.signOut(this.props.refreshToken);
    // track logout
    countlyEndUserSession();
    this.props.history.push('/welcome');
  }

  render() {
    const classes = classNames(
      'c-button',
      'c-button--danger-hollow',
      'c-button--sign-out',
      'h-no-break-words'
    );

    return (
      <button
        data-testid="sign-out"
        className={classes}
        onClick={this.signOut}
      >
        Log Out
      </button>
    );
  }
}

export default withRouter(SignOut);
