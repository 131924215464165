/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStatus } from '../actions/statusActions';
import { putMarkSetupRemainingComplete } from '../actions/profileChecklistActions';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
  status: PropTypes.object,
  putMarkSetupRemainingComplete: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired
};

function WithOnboardingTracking(props) {
  useEffect(() => {
    if (!props.type) {
      return;
    }

    const fetchData = async () => {
      const hasToPassType = props.status?.setupRemaining?.includes(props.type);
      hasToPassType && await props.putMarkSetupRemainingComplete([props.type]);
      hasToPassType && props.getStatus();
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

WithOnboardingTracking.propTypes = propTypes;

const mapStateToProps = (state) => ({
  status: state.status
});

export default connect(mapStateToProps, {
  putMarkSetupRemainingComplete,
  getStatus
})(immutableToJsHOC(WithOnboardingTracking));

