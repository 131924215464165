export function normalizeDateOfBirth(value) {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
}

export function normalizePhone(value) {
  if (!value) {
    return undefined;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 6) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }

  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
}

// Used Number because values are passed as strings and isDirty becomes true
export function normalizeInteger(value) {
  if (!value) {
    return value;
  }

  return Number(value.replace(/[^0-9]+/g, ''));
}

export function normalizeFloat(value) {
  if (!value) {
    return value;
  }

  return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
}

export function normalizeMaxPasswordLength(value) {
  return value.length > 30 ? value.slice(0, 30) : value;
}
