/* istanbul ignore file */
import { connect } from 'react-redux';
import {
  syncMHealthDeviceOnServer,
  deleteMarketplaceDisconnectSource,
  getMarketplace
} from 'src/actions/validicActions';
import Marketplace from 'src/pages/profile/Marketplace';
import { immutableToJsHOC } from './ImmutableToJsHOC';

function mapStateToProps(state) {
  return {
    syncOnServerStatus: state.validic.get('syncOnServerStatus'),
    connected: state.validic.get('connected'),
    disconnected: state.validic.get('disconnected')
  };
}

export default connect(mapStateToProps, {
  syncMHealthDeviceOnServer,
  deleteMarketplaceDisconnectSource,
  getMarketplace
})(immutableToJsHOC(Marketplace));
