import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import ContactsTypesFilter from 'src/components/contacts/ContactsTypesFilter/ContactsTypesFilter';
import SearchContacts from 'src/components/contacts/SearchContacts';
import useFetchDataHook from 'src/hooks/useFetchDataHook';
import Loader from 'src/components/shared/Loader';
import IamSharingInvitesCountContainer from 'src/containers/IamSharingInvitesCountContainer';
import ContactsIAmSharingWithTable from '../ContactsIAmSharingWithTable';
import { useStyles } from './ContactsIAmSharingWith.styles';

const propTypes = {
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number,
  getIAmSharingWithContacts: PropTypes.func.isRequired,
  getIAmSharingWithInvitationsCount: PropTypes.func.isRequired
};

export default function ContactsIAmSharingWith(props) {
  const { getIAmSharingWithContacts, itemsList, itemsListCount, getIAmSharingWithInvitationsCount } = props;
  const classes = useStyles();
  // eslint-disable-next-line no-empty-pattern
  const { memoizedFetch, loading } = useFetchDataHook(getIAmSharingWithContacts);

  useEffect(() => {
    getIAmSharingWithInvitationsCount();
  }, []);

  return (
    <Container style={{ padding: '0' }}>
      <section>
        <IamSharingInvitesCountContainer/>
      </section>

      <h2 className={classes.title}>People I am sharing with</h2>

      <div className={classes.block}>
        <ContactsTypesFilter/>
        <SearchContacts/>
      </div>

      <ContactsIAmSharingWithTable memoizedFetch={memoizedFetch} itemsList={itemsList} itemsListCount={itemsListCount} />

      <Loader isEnabled={loading}/>
    </Container>
  );
}

ContactsIAmSharingWith.propTypes = propTypes;
