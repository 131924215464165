import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
// HOCs
import withViewportWidth from '../../containers/withViewportWidth';
// Components
import DashboardGraphMessageGroup from './DashboardGraphMessageGroup';
import DropShadowFilter from './defs/DropShadowFilter';
import Legend from './Legend';
import ShapeAfter from './ShapeAfter';
import ShapeBefore from './ShapeBefore';
import ShapeFasting from './ShapeFasting';
import ShapeSnack from './ShapeSnack';
import ShapeNoTag from './ShapeNoTag';
import XAxis from './XAxis';
import YAxis from './YAxis';
// Helpers
import graphLine from '../../utils/graphLine';
import { makeStandardDayAxis } from '../../utils/timeframeHelpers';
import {
  getDayMillisecondsFromTimestamp,
  setColor,
  yDomain
} from '../../utils/trendHelpers';
import { setLeftRightSpacing } from '../../utils/svgHelpers';
// Styling
import { bold, p, sv, xAxisLabel, yAxisLabel } from '../../assets/css/1_settings/variables.js';

const propTypes = {
  hasAnyReadings: PropTypes.bool,
  patientTargets: ImmutablePropTypes.mapContains({
    targetHigh: PropTypes.number,
    targetLow: PropTypes.number
  }),
  readingVals: ImmutablePropTypes.list,
  width: PropTypes.number
};

function StandardDayGraphSVG(props) {
  const { hasAnyReadings = false, patientTargets, readingVals, width } = props;
  const dropShadowID = 'dropShadow';
  const svgContainer = {
    height: 360,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    width: width || 900
  };

  // Set default left and right spacing
  const leftRightSpacing = setLeftRightSpacing(sv.spacing, width);

  // Margins
  const margin = {
    bottom: sv.spacing.xl,
    left: leftRightSpacing,
    right: leftRightSpacing,
    top: sv.spacing.md
  };

  // Inner
  const inner = {
    width: svgContainer.width - margin.left - margin.right,
    height: svgContainer.height - margin.top - margin.bottom
  };

  const low = patientTargets.get('targetLow') || 80;
  const high = patientTargets.get('targetHigh') || 180;

  const addColor = setColor(sv.color, high, low);

  const vals = readingVals
    .sortBy((t) => t.get('timestamp'))
    .toArray()
    .map((val) => {
      const bg = val.get('bloodGlucose');
      const timeInDay = getDayMillisecondsFromTimestamp(val.get('timestamp'));

      return {
        ...val.toJS(),
        color: addColor(bg),
        y: bg,
        x: timeInDay
      };
    });

  // Domains
  const xDomain = [0, 1000 * 60 * 60 * 24];

  // Scales
  const xScale = scaleLinear().domain(xDomain).range([0, inner.width]);
  const yScale = scaleLinear().domain(yDomain).range([inner.height, 0]);

  // Axes
  const xAxis = makeStandardDayAxis(xScale, inner.width);
  const yAxis = {
    ticks: [
      { y: yScale(low), label: low, type: 'low' },
      { y: yScale(high), label: high, type: 'high' }
    ]
  };

  // Set trend path
  const trendPath = line()
    .x(d => xScale(d.x))
    .y(d => yScale(d.y));

  return (
    <svg
      id="standard-day-graph"
      height={svgContainer.height}
      style={svgContainer}
      width={svgContainer.width}
    >
      <defs>
        <DropShadowFilter filterID={dropShadowID} />
      </defs>
      <g height={inner.height} width={inner.width} transform={`translate(${margin.left}, ${margin.top})`}>
        <XAxis
          innerHeight={inner.height}
          sv={sv}
          xAxis={xAxis}
          xAxisLabel={xAxisLabel}
        />
        {vals && vals.length &&
          <path
            d={trendPath(graphLine(vals.sort((a, b) => a.x - b.x), true))}
            stroke={sv.color.active}
            strokeWidth="2"
            fill="none"
          />}
        {vals && vals.length && vals.map((val) => {
          let useFill = false;

          switch (val.mealMarker) {
          case 'AFTER':
            val.shape = ShapeAfter;
            break;
          case 'BEFORE':
            val.shape = ShapeBefore;
            break;
          case 'FASTING':
            useFill = true;
            val.shape = ShapeFasting;
            break;
          case 'SNACK':
            val.shape = ShapeSnack;
            break;
          default:
            val.shape = ShapeNoTag;
          }

          return (
            <val.shape
              key={val.id}
              fill={!useFill ? val.color : 'none'}
              color={useFill ? val.color : ''}
              transform={`translate(${xScale(val.x) - 12}, ${yScale(val.y) - 12})`}
            />
          );
        })}
        <YAxis
          innerWidth={inner.width}
          sv={sv}
          xAxisLabel={xAxisLabel}
          yAxis={yAxis}
          yAxisLabel={yAxisLabel}
        />
      </g>
      <Legend
        inner={inner}
        isMobile={svgContainer.width < 720}
        p={p}
        sv={sv}
        transform={`translate(${margin.left}, ${svgContainer.height - sv.spacing.md * 1.25})`}
      />
      {!vals.length &&
        <DashboardGraphMessageGroup
          bold={bold}
          container={svgContainer}
          filterID={dropShadowID}
          hasAnyReadings={hasAnyReadings}
          p={p}
          sv={sv}
        />}
    </svg>
  );
}

StandardDayGraphSVG.propTypes = propTypes;

export default withViewportWidth(StandardDayGraphSVG);
