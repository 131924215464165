/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from './icons/SearchIcon';

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string
};

function SearchBar({ placeholder, onChange, value, className }) {
  return (
    <div className={className}>
      <input
        onChange={onChange}
        value={value}
        className="c-field__input"
        type="search"
        required
      />
      <SearchIcon />
      <span className="c-field__bar" />
      <label className="c-field__label">{placeholder}</label>
    </div>
  );
}

SearchBar.propTypes = propTypes;
export default SearchBar;
