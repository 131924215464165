import actionTypes from '../constants/actionTypes';
import profileChecklistService from '../services/profileChecklistService';

function doNotShowChecklistSuccess(payload) {
  return {
    type: actionTypes.UPDATE_STATUS_SKIPPED_SETUP_REMAINGN_SUCCESS,
    payload
  };
}

export function doNotShowChecklist(skippedSetupRemaining) {
  return async function (dispatch) {
    const skippedSetup = await profileChecklistService.doNotShowChecklist(skippedSetupRemaining);
    dispatch(doNotShowChecklistSuccess(skippedSetup));
  };
}

export function putMarkSetupRemainingComplete(item) {
  return async function () {
    await profileChecklistService.putMarkSetupRemainingComplete(item);
  };
}
