/* istanbul ignore file */
import { connect } from 'react-redux';
import { setSelectedReadingId } from 'src/actions/readingsActions';
import { getPatientTesting } from 'src/actions/settingsActions';
import { makeGetPreviousReadings, makeGetReadingsInTimeframeForGraph } from 'src/selectors/readingSelectors';
import Dashboard from 'src/pages/dashboard/Dashboard';
import { makeGetWaterInTimeframe } from 'src/selectors/readingTagsSelectors';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import {
  makeGroupedCaloriesBurnedFromSummariesInTimeframe,
  makeGroupedCaloriesConsumedInTimeframe,
  makeGroupedCarbsConsumedInTimeframe,
  makeGroupedSleepsInTimeframe,
  makeGroupedStepsFromSummariesInTimeframe,
  makeGroupedWeightsInTimeframe
} from 'src/selectors/healthDataSelectors';

function makeMapStateToProps() {
  const getFilteredReadings = makeGetReadingsInTimeframeForGraph();
  const getPreviousReadings = makeGetPreviousReadings();

  const getGroupedCaloriesBurnedFromSummaries = makeGroupedCaloriesBurnedFromSummariesInTimeframe();
  const getGroupedCaloriesConsumed = makeGroupedCaloriesConsumedInTimeframe();
  const getGroupedSleeps = makeGroupedSleepsInTimeframe();
  const getGroupedStepsFromSummaries = makeGroupedStepsFromSummariesInTimeframe();
  const getGroupedWeights = makeGroupedWeightsInTimeframe();
  const getCarbsInTimeframe = makeGroupedCarbsConsumedInTimeframe();
  const getWaterInTimeframe = makeGetWaterInTimeframe();

  return function (state, props) {
    const selectedId = state.readings.get('selectedId');

    const caloriesBurnedTotals = getGroupedCaloriesBurnedFromSummaries(state, props);
    const caloriesConsumedTotals = getGroupedCaloriesConsumed(state, props);
    const carbsTotals = getCarbsInTimeframe(state, props);
    const sleepTotals = getGroupedSleeps(state, props);
    const stepTotals = getGroupedStepsFromSummaries(state, props);
    const waterTotals = getWaterInTimeframe(state, props);
    const weightTotals = getGroupedWeights(state, props);

    const selectedReading = selectedId && state.readings.getIn(['cache', selectedId]) || null;

    const filteredReadings = getFilteredReadings(state, props);
    const previousReadings = getPreviousReadings(state, props);

    return {
      selectedReading,
      filteredReadings,
      previousReadings,
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      patientTargets: state.settings.get('patientTargets'),
      testingTimes: state.settings.get('testingTimes'),
      validic: state.validic,
      caloriesBurnedTotals,
      caloriesConsumedTotals,
      carbsTotals,
      sleepTotals,
      stepTotals,
      waterTotals,
      weightTotals
    };
  };
}

export default connect(makeMapStateToProps, {
  getPatientTesting,
  setSelectedReadingId
})(immutableToJsHOC(Dashboard));
