import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import HealthCompareMenu from './HealthCompareMenu';

export default class HealthCompare extends React.Component {
  static propTypes = {
    compareType: PropTypes.string,
    dataTypes: PropTypes.array,
    setComparisonType: PropTypes.func
  };

  state = {
    menuOpen: false
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  }

  toggleMenu = () => {
    this.setState(oldState => ({ menuOpen: !oldState.menuOpen }));
  }

  render() {
    const { compareType, dataTypes } = this.props;
    const buttonClasses = classNames(
      'c-health-compare__display',
      { 'c-health-compare__display--active': this.state.menuOpen }
    );
    const filteredDataTypes = dataTypes.filter(item => item.value === compareType);
    const selected = filteredDataTypes[0];

    return (
      <div className="c-health-compare">
        <div className={buttonClasses} data-testid="compare" onClick={this.toggleMenu}>
          <div className="c-health-compare__label">Health Data:</div>
          <div className="c-health-compare__value">{!!selected ? selected.label : 'Compare'}</div>
        </div>
        {this.state.menuOpen &&
          <HealthCompareMenu
            closeMenu={this.closeMenu}
            closeOnEsc
            closeOnOutsideClick
            compareType={this.props.compareType}
            dataTypes={dataTypes}
            setComparisonType={this.props.setComparisonType}
          />}
      </div>
    );
  }
}
