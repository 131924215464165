import moment from 'moment';
import dayjs from 'dayjs';
import { PAGE_NAMES } from 'src/constants/routesCountlyNameConstants';
import storeService from './store';
import {
  getTimeDiff,
  getPageNameFromStore,
  getPageGroupFromStore,
  getUserEmailAddressFromStore
} from 'src/utils/pageNameSelectors';
import { trackRegistrationDropOff } from './countlyRegistrationTrackService';

let sessionStartTime;

/**
 * So the idea is to change the device id on user's login. But there are two scenarios.
 *
 * Anonymous user comes to the website, logs in, and you identify the user as wife and set
 * the email address as device_id wife@domain.com with merging.
 * But then, the wife logs out, and the husband logs in, in which case you want to
 * change device_id to husband@domain.com but without merging, because you want to
 * identify them as separate users on server side.
 *
 * Which means what you need to do is to check if the currently stored device id in web
 * SDK is the email address or not. If not, you can change the device id with merging;
 * if it is email, you can change the device id without merging.
 *
 * Code could look something like this:
 * If it does not contain @ so probably not an email address, but you can use your own logic
 * if (!Countly.device_id || Countly.device_id.indexOf('@') === -1) {
 *     Countly.q.push(['change_id', email_address, true]); //we change device id with merging
 * } else {
 *     Countly.q.push(['change_id', email_address]); //we change device id without merging
 * }
 *
 * @param emailAddress
 */
export function countlyChangeDeviceId(emailAddress) {
  if (!window.Countly) {
    return;
  }

  if (!window.Countly.device_id || window.Countly.device_id.indexOf('@') === -1) { // does not contain @ so probably not an email address
    window.Countly.q.push(['change_id', emailAddress, true]); // we change device id with merging
  } else {
    window.Countly.q.push(['change_id', emailAddress]); // we change device id without merging
  }
}

export function pushCountlyEvent(queryParams) {
  try {
    window.Countly.q.push(queryParams);
  } catch (err) {
    console.error(err); // eslint-disable-line
  }
}

export async function countlyStartUserSession(emailAddress) {
  if (!window.Countly) {
    return;
  }

  sessionStartTime = dayjs();

  countlyChangeDeviceId(emailAddress);
  pushCountlyEvent(['begin_session']);
}

export async function countlyEndUserSession() {
  if (!window.Countly) {
    return;
  }

  const state = await storeService.getHydratedState();
  const pageName = getPageNameFromStore(state);
  const sessionDuration = getTimeDiff(sessionStartTime);

  pushCountlyEvent(['add_event', {
    key: 'web.userDropOf',
    segmentation: {
      pageName,
      sessionDuration
    }
  }]);

  pushCountlyEvent(['end_session']);
}

export function countlyTrackRoute(pageName, group = '') {
  if (!window.Countly) {
    return;
  }

  if (window.Countly && pageName) {
    pushCountlyEvent(['track_pageview', pageName, null, { group }]);
  }
}

export async function countlyTrackTabClose(event) {
  if (!window.Countly) {
    return;
  }

  event?.preventDefault();
  const state = await storeService.getHydratedState();
  const pageName = getPageNameFromStore(state);
  const userEmail = getUserEmailAddressFromStore(state);
  const sessionDuration = getTimeDiff(sessionStartTime);

  // we should use synchronous approach since it is triggered when user close tab
  try {
    // we track if user has closed tab and has not
    // finished registration. So we separately
    // track registration page steps
    if (
      pageName === PAGE_NAMES.REGISTRATION ||
      pageName === PAGE_NAMES.REGISTRATION_ABOUT_ME ||
      pageName === PAGE_NAMES.REGISTRATION_ADD_POGO
    ) {
      const userId = pageName === PAGE_NAMES.REGISTRATION || !userEmail ? 'user unknown' : userEmail;
      trackRegistrationDropOff(pageName, userId);
    }

    window.Countly.end_session(sessionDuration);
    window.Countly.add_event({
      key: 'web.tabClose',
      segmentation: {
        pageName,
        sessionDuration
      }
    });
  } catch (err) {
    console.error(err); // eslint-disable-line
  }
}

export async function countlyPopulateUserDetails() {
  const diabetesTypeName = {
    1: 'Type 1',
    2: 'Type 2',
    3: 'Prediabetes',
    4: 'Gestational'
  };

  const state = await storeService.getHydratedState();
  const gender = String(state.profile.get('gender')).toUpperCase();
  const diabetesType = state.profile.get('diabetesType');
  const firstName = state.profile.get('firstName');
  const lastName = state.profile.get('lastName');
  const phone = state.profile.get('cellNumber');
  const zipCode = state.profile.get('postalCode');
  const dateOfBirth = state.profile.get('dateOfBirth');
  const byear = dateOfBirth ? moment(dateOfBirth, 'MM/DD/YYYY').format('YYYY') : undefined;
  const email = state.countly.get('userEmailAddress');

  const details = {
    gender,
    byear,
    email,
    phone,
    name: `${firstName} ${lastName}`,
    custom: {
      diabetesType: diabetesTypeName[diabetesType],
      zipCode,
      dateOfBirth: state.profile.get('dateOfBirth')
    }
  };

  pushCountlyEvent(['user_details', details]);
}

export function countlyTrackHydration(event) {
  event && pushCountlyEvent(['add_event', {
    key: `web.${event}`,
    segmentation: {
      pageName: PAGE_NAMES.LOG_BOOK_HYDRATION,
      pageGroup: 'Logbook'
    }
  }]);
}

export async function countlyTrackButton(event, view) {
  const state = await storeService.getHydratedState();
  const pageName = getPageNameFromStore(state);
  const pageGroup = getPageGroupFromStore(state);

  pushCountlyEvent(['add_event', {
    key: `web.${event}`,
    segmentation: {
      view,
      pageName,
      pageGroup
    }
  }]);
}

export async function countlyTrackTimeframeButton(timeframe) {
  const state = await storeService.getHydratedState();
  const pageName = getPageNameFromStore(state);
  const pageGroup = getPageGroupFromStore(state);

  pushCountlyEvent(['add_event', {
    key: 'web.timeframe',
    segmentation: {
      timeframe,
      pageName,
      pageGroup
    }
  }]);
}
