import PropTypes from 'prop-types';
import React from 'react';
import BarChartSVG from '../components/svg/BarChartSVG';
import { connect } from 'react-redux';
import { setDevicePerDataType } from '../actions/validicActions';
import HealthDeviceWidget from '../components/HealthDeviceWidget';
import {
  getSummariesCacheDeviceList,
  makeGetCaloriesBurnedDevice,
  makeGroupedCaloriesBurnedFromSummariesInTimeframe
} from '../selectors/healthDataSelectors';
import CaloriesBurnedIcon from '../components/icons/CaloriesBurnedIcon';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  caloriesBurnedTotals: PropTypes.object,
  caloriesBurnedDevice: PropTypes.object,
  caloriesBurnedDevices: PropTypes.array,
  isSharingCircleView: PropTypes.bool,
  setDevicePerDataType: PropTypes.func.isRequired
};

function WidgetCaloriesBurnedContainer({
  caloriesBurnedTotals,
  caloriesBurnedDevice,
  caloriesBurnedDevices,
  isSharingCircleView,
  setDevicePerDataType: switchDevice
}) {
  return (
    <HealthDeviceWidget
      Chart={BarChartSVG}
      data={caloriesBurnedTotals}
      dataCollectionType="caloriesBurned"
      dataType="caloriesBurned"
      device={caloriesBurnedDevice}
      Icon={CaloriesBurnedIcon}
      isSharingCircleView={isSharingCircleView}
      sources={caloriesBurnedDevices}
      switchDevice={switchDevice}
      title="AVG Calories Burned"
    />
  );
}

export function makeMapStateToProps() {
  const getGroupedCaloriesBurnedFromSummaries = makeGroupedCaloriesBurnedFromSummariesInTimeframe();
  const getCaloriesBurnedDevice = makeGetCaloriesBurnedDevice();

  return function (state, props) {
    const caloriesBurnedDevice = getCaloriesBurnedDevice(state, props);
    const caloriesBurnedTotals = getGroupedCaloriesBurnedFromSummaries(state, props);
    const caloriesBurnedDevices = getSummariesCacheDeviceList(state);

    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      caloriesBurnedDevices,
      caloriesBurnedDevice,
      caloriesBurnedTotals
    };
  };
}

export default connect(makeMapStateToProps, {
  setDevicePerDataType
})(immutableToJsHOC(WidgetCaloriesBurnedContainer));

WidgetCaloriesBurnedContainer.propTypes = propTypes;
