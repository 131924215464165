import actionTypes from '../constants/actionTypes';
import statusService from '../services/statusService';

function getStatusFailure(err) {
  return {
    type: actionTypes.GET_STATUS_FAILURE,
    error: true,
    payload: err
  };
}

function getStatusStart() {
  return {
    type: actionTypes.GET_STATUS_START
  };
}

function getStatusSuccess(statusInfo) {
  return {
    type: actionTypes.GET_STATUS_SUCCESS,
    payload: { statusInfo }
  };
}

export function getStatus() {
  return async function (dispatch) {
    dispatch(getStatusStart());

    try {
      const statusInfo = await statusService.getStatus();
      dispatch(getStatusSuccess(statusInfo));
    } catch (err) {
      dispatch(getStatusFailure(err));
    }
  };
}

function updateIsSeenTrackingMessageStatusSuccess(status) {
  return {
    type: actionTypes.PUT_TRACKING_MESSAGE_SEEN_STATUS,
    payload: status
  };
}

export function updateIsSeenTrackingMessageStatus() {
  return async function (dispatch) {
    const { enable_tracking_message_seen } = await statusService.updateIsSeenTrackingMessageStatus(); // eslint-disable-line
    dispatch(updateIsSeenTrackingMessageStatusSuccess(enable_tracking_message_seen));
  };
}

function updateIsAgreeTermsAndConditionsSuccess() {
  return {
    type: actionTypes.UPDATE_ACCEPT_TERMS_AND_CONDITIONS
  };
}

export function updateIsAgreeTermsAndConditions() {
  return async function (dispatch) {
    await statusService.updateIsAgreeTermsAndConditions();
    dispatch(updateIsAgreeTermsAndConditionsSuccess());
  };
}
