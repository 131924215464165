import dayjs from 'dayjs';
import actionTypes from 'src/constants/actionTypes';
import readingsService from 'src/services/readingsService';
import {
  adjustTimeByOffset,
  makeEndDateForApi,
  makeStartDateForApi
} from 'src/utils/dateFormatter';
import { defaultOptions } from 'src/services/timeframeService';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

function getRangeOfReadingsFailure(err) {
  return {
    type: actionTypes.GET_RANGE_OF_READINGS_FAILURE,
    error: true,
    payload: err
  };
}

function getRangeOfReadingsStart() {
  return {
    type: actionTypes.GET_RANGE_OF_READINGS_START
  };
}

function getRangeOfReadingsSuccess({ readings }) {
  // Since whole app is moved to use UTC time, we modify readings timestamp with offset
  const readingsAdjustedByOffset = readings?.map((reading) => ({
    ...reading, timestamp: adjustTimeByOffset(reading.timestamp, reading.utcOffset)
  }));

  return {
    type: actionTypes.GET_RANGE_OF_READINGS_SUCCESS,
    payload: { readings: readingsAdjustedByOffset }
  };
}

function getReadingFailure(err) {
  return {
    type: actionTypes.GET_READING_FAILURE,
    error: true,
    payload: err
  };
}

function getReadingStart() {
  return {
    type: actionTypes.GET_READING_START
  };
}

function getReadingSuccess(reading) {
  // Since whole app is moved to use UTC time, we modify readings timestamp with offset
  const readingWithUpdatedTimestamp = {
    ...reading,
    timestamp: adjustTimeByOffset(reading.timestamp, reading.utcOffset)
  };

  return {
    type: actionTypes.GET_READING_SUCCESS,
    payload: { reading: readingWithUpdatedTimestamp }
  };
}

export function getReading(readingId) {
  return async function (dispatch) {
    dispatch(getReadingStart());

    try {
      const reading = await readingsService.getReading(readingId);
      dispatch(getReadingSuccess(reading));
    } catch (err) {
      dispatch(getReadingFailure(err));
    }
  };
}

function updateTimeFrameSuccess(data) {
  const timestamp = {
    start: defaultOptions[1]?.start,
    end: defaultOptions[1]?.end
  };

  if (data?.timestamp) {
    timestamp.start = dayjs(data?.timestamp).utc().endOf('day').subtract(6, 'days').startOf('day').format();
    timestamp.end = dayjs(data?.timestamp).utc().endOf('day').format();
  }

  const timeframe = {
    custom: false,
    start: timestamp.start,
    end: timestamp.end,
    label: "7 Days",
    span: 6,
    units: "days"
  };

  return {
    type: actionTypes.SET_TIMEFRAME,
    payload: { timeframe }
  };
}

export function getRangeOfReadings(from, to, format) {
  return async function (dispatch) {
    dispatch(getRangeOfReadingsStart());

    try {
      const data = await readingsService.getRangeOfReadings(from, to, format);
      dispatch(getRangeOfReadingsSuccess(data));
    } catch (err) {
      dispatch(getRangeOfReadingsFailure(err));
    }
  };
}

function getLastKnowReadingStart() {
  return {
    type: actionTypes.GET_LAST_KNOWN_READING_DATE_START
  };
}

function getLastKnowReadingSuccess() {
  return {
    type: actionTypes.GET_LAST_KNOWN_READING_DATE_SUCCESS
  };
}

function getLastKnowReadingFailure() {
  return {
    type: actionTypes.GET_LAST_KNOWN_READING_DATE_FAILURE
  };
}

export function getLastKnownReading() {
  return async function (dispatch) {
    try {
      // "defaultOptions[1]" means that we take for 7 last days
      const start = makeStartDateForApi(defaultOptions[1]?.start);
      const end = makeEndDateForApi(defaultOptions[1]?.end);
      const readingsData = await readingsService.getRangeOfReadings(start, end, { tags: true, fullTags: true });

      // In case there are no readings for default timeframe we should fetch timeframe
      // where there are readings.
      if (!readingsData?.readings?.length) {
        dispatch(getLastKnowReadingStart());
        const data = await readingsService.getLastKnownReading();
        dispatch(getLastKnowReadingSuccess());
        dispatch(updateTimeFrameSuccess(data));
        return;
      }

      dispatch(updateTimeFrameSuccess());
      dispatch(getRangeOfReadingsSuccess(readingsData));
    } catch (err) {
      dispatch(getLastKnowReadingFailure());
      console.error(err); // eslint-disable-line
    }
  };
}

export /* istanbul ignore next */ function clearSelectedReading() {
  return {
    type: actionTypes.CLEAR_SELECTED_READING
  };
}

export /* istanbul ignore next */ function setSelectedReadingId(readingId) {
  return {
    type: actionTypes.SET_SELECTED_READING_ID,
    payload: { readingId }
  };
}
