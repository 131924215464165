import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  eligibilityScreenSeenStatus: new Immutable.Map({})
});

export default function screensSeenStatusReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case actionTypes.UPDATE_SEEN_STATUS_SCREEN:
    return state.updateIn(['eligibilityScreenSeenStatus'], (ids) => {
      return ids.mergeDeepWith((prev, next) => next, action.payload);
    });

  default:
    return state;
  }
}
