/* istanbul ignore file */
import { connect } from 'react-redux';
import { getSharedWithInvitationsCount } from '../actions/contactsSharedWithMeInvitesActions';
import SharingCircleSharedWithInvitationsBar from '../components/sharingCircle/SharingCircleSharedWithInvitationsBar';
import { immutableToJsHOC } from './ImmutableToJsHOC';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    receivedInvitations: state.contactsSharedWithMeInvitations.get('receivedInvitations')
  };
}

export default connect(mapStateToProps, {
  getSharedWithInvitationsCount
})(immutableToJsHOC(SharingCircleSharedWithInvitationsBar));
