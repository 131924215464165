import moment from 'moment';
import partial from 'lodash/partial';
import { extent } from 'd3-array';

function isSameMomentGranular(firstDate, secondDate, granular) {
  return moment(firstDate).isSame(moment(secondDate), granular);
}

function sumXandYProps(acc, el) {
  acc.x += el.x;
  acc.y += el.y;

  return acc;
}

/**
 * If yDomain value is in same date it will increment counter and
 * add value to overal amount of values of this date
 * @param el
 * @param granular
 * @param item
 * @param key
 * @param array
 * @returns [el1,el2,el3]
 * where el1 = xDomain value
 * where el2 = sum of all yDomain values in same date
 * where el3 = amount of all yDomain values in same date
 */
function mapByDate(el, granular, item, key, array) {
  if (isSameMomentGranular(item[0], el.x, granular)) {
    item[1] += el.y; // Add to previous value
    item[2]++; // Increment counter

    // Set end point in last reading time. Blue curve will end on last reading
    if (array.length > 1) { item[0] = el.x; }
  }

  return item;
}

/**
 * This will loop through array and produce new array [[el1,el2,el3]]
 * where el1 = xDomain value
 * where el2 = sum of all yDomain values in same date
 * where el3 = amount of all yDomain values in same date
 * @param granular - could be 'day' or 'hour'
 * @param acc - accumulator
 * @param el
 * @returns array of arrays, [[el1,el2,el3]]
 */
function mapByGranular(granular, acc, el) {
  if (
    !acc.length ||
    !isSameMomentGranular(acc[acc.length - 1][0], el.x, granular)
  ) {
    acc.push([el.x, el.y, 1]);
    return acc;
  }

  return acc.map(partial(mapByDate, el, granular));
}

function makeAvaragePointValue(el) {
  return { x: el[0].valueOf(), y: Math.round(el[1] / el[2]) };
}

function makePointForCurve(pointsArray) {
  if (!pointsArray || !pointsArray.length) {
    return 0;
  }

  if (pointsArray.length === 1) {
    return pointsArray[0];
  }

  const pointReduced = pointsArray.reduce(sumXandYProps, { x: 0, y: 0 });

  pointReduced.x = pointReduced.x / pointsArray.length;
  pointReduced.y = pointReduced.y / pointsArray.length;

  return pointReduced;
}

/**
 * Will produce array where all values for yDomain in chart are average per day or hour values.
 * If for ex. we have 2 readings in same granular(f.e. day), result would be sum of two
 * readings devided by 2.
 * @param points
 * @param isWithinDay
 * @returns {*}
 */
function sortReadingsByGranularAndGetAvarage(points, isWithinDay = true) {
  const granular = isWithinDay ? 'hour' : 'day';
  return points.reduce(partial(mapByGranular, granular), [])
    .map(makeAvaragePointValue);
}


function returnPointsForCurve(pointsArray) {
  const anchorPointsArray1 = pointsArray.splice(0, 1);
  const anchorPointsArray2 = pointsArray.splice(-1);
  const controlPointsArray1 = pointsArray.splice(0, Math.floor(pointsArray.length / 2));
  const controlPointsArray2 = [...pointsArray];
  /*
   * anchorPointStart - is start point where curve will start.
   * anchorPointEnd - is end point where curve will end
   * controlPoint1 - is first control point
   * controlPoint2 - is second control point
   */
  const anchorPointStart = makePointForCurve(anchorPointsArray1);
  const anchorPointEnd = makePointForCurve(anchorPointsArray2);
  const controlPoint1 = makePointForCurve(controlPointsArray1);
  const controlPoint2 = makePointForCurve(controlPointsArray2);

  return [anchorPointStart, controlPoint1, controlPoint2, anchorPointEnd].filter(el => el !== 0);
}

function makAadjustedY(i, points) {
  if (points.length <= 1) {
    return null;
  }

  /*
   * aP = anchor point
   * cP = control point
   */
  const [aP1, cP1, cP2, aP2] = points;
  const p0 = aP1 ? aP1.y : 0;
  const p1 = cP1 ? cP1.y : 0;
  const p2 = cP2 ? cP2.y : 0;
  const p3 = aP2 ? aP2.y : 0;

  /*
   * Depending on points length there should be different formulas used
   * every point between last and first is considered control point
   * and logic which is used is called De Casteljau's Algorithm and Bezier Curves
   * link to read http://www.malinc.se/m/DeCasteljauAndBezier.php
   */
  switch (points.length) {
  case 2:
    /* this is to draw linear
     * (1-t)*p0+t*p1,t∈[0,1].
     */
    return (1 - i) * p0 + i * p1;
  case 3:
    /* if we have 1 control point we use Quadratic Bezier curve formula
     * (1−t)^2*p0+2*(1−t)*t*p1+t^2*p2,t∈[0,1].
     */
    return Math.pow((1 - i), 2) * p0 + 2 * (1 - i) * i * p1 + Math.pow((i), 2) * p2;
  case 4:
    /* if we have 2 control points we use Cubic Bezier curve formula
     * (1−t)^3*p0+3*(1−t)^2*t*P1+3*(1−t)*t^2*p2+t^3*p3,t∈[0,1].
     */
    return Math.pow((1 - i), 3) * p0 + (3 * Math.pow((1 - i), 2) * i * p1) + (3 * (1 - i) * Math.pow(i, 2) * p2) + Math.pow((i), 3) * p3;
  default:
    return null;
  }
}

function graphLine(pointsArray, isWithinDay = true, linePointsCount = 200) {
  const pointsByGranularArray = sortReadingsByGranularAndGetAvarage(pointsArray, isWithinDay);
  const pointsForCurve = returnPointsForCurve(pointsByGranularArray);
  const sortedByDatePointsArray = pointsForCurve.sort((a, b) => (a.x - b.x));

  // This will return array with start point in time and end point in time for given points
  const xDomain = extent(sortedByDatePointsArray, d => d.x);

  // Difference in time needed for counting iterator for x => domain in chart.
  const timeDifference = xDomain[1] - xDomain[0];

  // Defining iterators for x => domain and y => domain
  const xIteratorStep = timeDifference / linePointsCount;
  const yIteratorStep = 1 / linePointsCount;

  const line = [];
  for (let i = 0; i < linePointsCount; i++) {
    const xIterator = xDomain[0] + xIteratorStep * i;
    const yIterator = yIteratorStep * i;

    line.push({
      x: moment(xIterator),
      y: makAadjustedY(yIterator, sortedByDatePointsArray)
    });
  }

  return line;
}

export default graphLine;
