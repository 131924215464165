import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function WeightsIcon({ className }) {
  let classes = 'c-icon c-icon--weights';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 20 20">
      <title>Weight</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, 0.000000)">
          <g>
            <path d="M17.0201,16.3671 C16.7531,17.0281 16.2881,17.2051 15.4321,17.2051 L10.9991,17.2051 L6.5681,17.2051 C5.7121,17.2051 5.2471,17.0281 4.9801,16.3671 L3.8251,5.6861 C3.8411,5.2261 4.0321,4.4881 5.0911,4.2861 C5.0911,4.2861 6.1471,4.0651 7.6181,3.8871 C8.1661,2.5691 9.4641,1.6431 10.9801,1.6431 C12.4941,1.6431 13.7911,2.5671 14.3401,3.8821 C15.8331,4.0611 16.9091,4.2861 16.9091,4.2861 C17.9681,4.4881 18.1591,5.2261 18.1741,5.6861 L17.0201,16.3671 Z M10.9991,0.0001 C5.4771,0.0001 0.9991,4.4771 0.9991,10.0001 C0.9991,15.5221 5.4771,20.0001 10.9991,20.0001 C16.5231,20.0001 20.9991,15.5221 20.9991,10.0001 C20.9991,4.4771 16.5231,0.0001 10.9991,0.0001 L10.9991,0.0001 Z" fill="#282829"/>
            <path d="M12.65,3.9882 L11.607,5.0312 C11.638,5.1092 11.656,5.1942 11.656,5.2832 C11.656,5.6562 11.353,5.9592 10.98,5.9592 C10.606,5.9592 10.304,5.6562 10.304,5.2832 C10.304,4.9102 10.606,4.6072 10.98,4.6072 C11.093,4.6072 11.201,4.6352 11.296,4.6852 L12.321,3.6592 C12.412,3.5682 12.559,3.5682 12.65,3.6592 C12.741,3.7502 12.741,3.8972 12.65,3.9882 M10.98,2.4232 C9.4,2.4232 8.12,3.7032 8.12,5.2832 C8.12,6.8622 9.4,8.1422 10.98,8.1422 C12.559,8.1422 13.839,6.8622 13.839,5.2832 C13.839,3.7032 12.559,2.4232 10.98,2.4232" fill="#2CA9DF"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

WeightsIcon.propTypes = propTypes;

export default WeightsIcon;
