import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Container, Grid } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import MuiTextField from '../../../../../../components/muiComponents/MuiTextField';
import CountlyTrackedButton from '../../../../../../components/countly/CountlyTrackedButton';
import { StyledDialogActions } from '../../../../../../components/muiStyledComponents/StyledDialogActions';
import { StyledDialogTitle } from '../../../../../../components/muiStyledComponents/StyledDialogTitle';
import formValidationService from '../../../../../../services/formValidation';
import { handleFormSubmissionErrors } from '../../../../../../services/errorHandlingService';
import { normalizePhone } from '../../../../../../services/fieldNormalizationService';
import MuiFormControlledCheckbox from '../../../../../../components/muiComponents/MuiFormControlledCheckbox';
import { contactsViewsConstants } from '../../../../../../constants/contactsInviteViews';
import { useInboxActionsContext } from '../../../../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';

const propTypes = {
  inviteSharingCircleOrEmergencyContact: PropTypes.func.isRequired,
  getIAmSharingWithInvitationsCount: PropTypes.func.isRequired
};

function ContactsEmergencyContactForm({ getIAmSharingWithInvitationsCount, inviteSharingCircleOrEmergencyContact }) {
  const { closeModal, switchToView } = useInboxActionsContext();

  const submit = async (values) => {
    const formValues = {
      ...values,
      scopes: values.scope ? ['carer', 'emergency_contact'] :  ['emergency_contact']
    };

    try {
      await inviteSharingCircleOrEmergencyContact(formValues);
      getIAmSharingWithInvitationsCount();
      switchToView(contactsViewsConstants.successECInviteView);
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        ['firstName', 'lastName', 'emailAddress']
      );
    }
  };

  const validateFields = (values) => {
    return new formValidationService.Validation()
      .firstName(values.firstName)
      .lastName(values.lastName)
      .email(values.emailAddress, 'emailAddress')
      .homeNumber(values.homeNumber)
      .getErrors();
  };

  return (
    <Container maxWidth="sm" className="h-padded-lg">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <StyledDialogTitle>
          <strong>Add Emergency Contact</strong>
        </StyledDialogTitle>

        <Form
          onSubmit={submit}
          validate={validateFields}
        >
          {({ handleSubmit, isActive }) => {
            return (
              <DialogContent>
                <form name="emergencyContactForm" onSubmit={handleSubmit}>
                  <Field
                    component={MuiTextField}
                    label="Name"
                    name="firstName"
                  />
                  <Field
                    component={MuiTextField}
                    label="Last Name"
                    name="lastName"
                  />
                  <Field
                    component={MuiTextField}
                    label="Email Address"
                    name="emailAddress"
                  />
                  <Field
                    component={MuiTextField}
                    label="Phone Number"
                    name="homeNumber"
                    parse={normalizePhone}
                    placeholder="Phone Number"
                  />
                  <div className="h-padded-top">
                    <Field
                      label={<span>Also make an Sharing Circle</span>}
                      name="scope"
                      component={MuiFormControlledCheckbox}
                      type="checkbox"
                    />
                  </div>
                  <StyledDialogActions>
                    <button className="c-button c-button--hollow jest-cancel" type="button" onClick={closeModal}>
                      Cancel
                    </button>
                    <CountlyTrackedButton
                      countlyevent="create"
                      className="c-button c-button--action"
                      disabled={isActive}
                      type="submit"
                    >
                      Send Invitation
                    </CountlyTrackedButton>
                  </StyledDialogActions>
                </form>
              </DialogContent>
            );
          }}
        </Form>
      </Grid>
    </Container>
  );
}

export default ContactsEmergencyContactForm;
ContactsEmergencyContactForm.propTypes = propTypes;
