import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '18Px'
  },
  subHeader: {
    background: theme.palette?.background?.light,
    padding: '9Px 18Px',
    borderBottom: `1Px solid ${theme.palette?.background?.light}`
  }
}));
