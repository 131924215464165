import apiService from './apiService';

const AUTH_URL = process.env.AUTH_URL || '';

async function deleteInvitation(inviteId) {
  const { data } = await apiService.delete(`/invitations/${inviteId}`);
  return data;
}

async function acceptInvitation(token) {
  return await apiService.put('/invitations/relationship', { token });
}

async function renewConnection(token) {
  return await apiService.put(`${AUTH_URL}/sharing/renew`, { token });
}

export default {
  acceptInvitation,
  renewConnection,
  deleteInvitation
};
