import { connect } from 'react-redux';
import { getSharedWithMeContacts } from 'src/actions/contactsActions';
import { clearSelectedPatient } from 'src/actions/selectedPatientActions';
import ContactsSharingWithMe from 'src/pages/contacts/children/sharingWithMe/ContactsSharingWithMe';
import { immutableToJsHOC } from './ImmutableToJsHOC';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    itemsList: state.contacts.getIn(['sharingWithMeContacts', 'sharedWithMe']),
    itemsListCount: state.contacts.getIn(['sharingWithMeContacts', 'sharedWithMeCount'])
  };
}

export default connect(mapStateToProps, {
  clearSelectedPatient,
  getSharedWithMeContacts
})(immutableToJsHOC(ContactsSharingWithMe));
