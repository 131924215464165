import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  filterID: PropTypes.string
};

function LinearGradientFilter({ filterID = 'linearGradient' }) {
  return (
    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={filterID}>
      <stop stopColor="#FFFFFF" offset="0%" />
      <stop stopColor="#F9F9F9" offset="100%" />
    </linearGradient>
  );
}

LinearGradientFilter.propTypes = propTypes;

export default LinearGradientFilter;
