import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { Container, Grid } from '@material-ui/core';
import MuiTextField from 'src/components/muiComponents/MuiTextField';
import { StyledDialogTitle } from 'src/components/muiStyledComponents/StyledDialogTitle';
import { normalizePhone } from 'src/services/fieldNormalizationService';
import { handleFormSubmissionErrors } from 'src/services/errorHandlingService';
import formValidationService from 'src/services/formValidation';
import { StyledDialogActions } from 'src/components/muiStyledComponents/StyledDialogActions';

const propTypes = {
  editIAmSharingWithContactsInfo: PropTypes.func.isRequired,
  memoizedFetch: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object,
  initialValues: PropTypes.object
};

export default function ContactsEditInfoForm({
  editIAmSharingWithContactsInfo,
  item,
  memoizedFetch,
  closeModal,
  initialValues
}) {
  const submit = async (values) => {
    try {
      const { firstName, lastName } = values;
      await editIAmSharingWithContactsInfo(item.memberProfileId, { firstName, lastName });
      memoizedFetch();
      closeModal();
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        [
          'firstName',
          'lastName'
        ]
      );
    }
  };

  const validateFields = (values) => {
    return new formValidationService.Validation()
      .firstName(values.firstName)
      .lastName(values.lastName)
      .homeNumberOptional(values.phoneNumber)
      .getErrors();
  };

  const isSharingCircleOnly = item.type.includes('Sharing Circle') && item.type.length === 1;

  return (
    <Container maxWidth="sm" className="h-padded-lg">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <StyledDialogTitle>
          <strong>Edit Info</strong>
        </StyledDialogTitle>

        <Form
          initialValues={initialValues}
          onSubmit={submit}
          validate={validateFields}
        >
          {({ handleSubmit, isActive }) => {
            return (
              <form
                className="c-form"
                onSubmit={handleSubmit}
              >
                <Field
                  component={MuiTextField}
                  label="First Name"
                  name="firstName"
                />
                <Field
                  component={MuiTextField}
                  label="Last Name"
                  name="lastName"
                />
                {!isSharingCircleOnly && (
                  <Field
                    disabled
                    component={MuiTextField}
                    label="Home Phone"
                    name="phoneNumber"
                    parse={normalizePhone}
                    placeholder="Home Phone"
                  />
                )}
                <Field
                  disabled
                  autoComplete="new-password"
                  component={MuiTextField}
                  label="Email Address"
                  name="emailAddress"
                />
                <StyledDialogActions>
                  <button
                    className="c-button c-button--hollow jest-cancel"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="c-button c-button--action jest-proceed"
                    disabled={isActive}
                    type="submit"
                  >
                    Save
                  </button>
                </StyledDialogActions>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Container>
  );
}

ContactsEditInfoForm.propTypes = propTypes;
