import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '16Px 0',
    padding: '16Px',
    boxShadow: '0px 2px 11px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px -1px rgba(0, 0, 0, 0.20)'
  },
  wrapper: {
    padding: '8Px 16Px'
  },
  header: {
    padding: '12Px 0'
  },
  meterBody: {
    padding: '8Px 0'
  },
  buttonWrapper: {
    paddingTop: '24Px'
  },
  meterData: {
    padding: '4Px 0'
  },
  divider: {
    padding: '8Px 16Px'
  },
  listWrapper: {
    '& li': {
      counterIncrement: 'my-counter'
    },
    '& li:before': {
      content: 'counter(my-counter) ". "',
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  },
  button: {
    width: '200Px',
    maxWidth: '100%'
  }
}));
