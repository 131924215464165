/* istanbul ignore file */
import { connect } from 'react-redux';
import SharingCirclePatientDataViewHeader from '../components/sharingCircle/SharingCirclePatientDataViewHeader';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const mapStateToProps = (state) => {
  const isPatient = state.status.get('scopes').includes('patient');
  const hasDataAccess = state.status.get('hasDataAccess');
  const isCompletePatient = hasDataAccess && isPatient;

  return {
    isCompletePatient,
    selectedPatient: state.selectedPatient.get('selectedPatient')
  };
};

export default connect(mapStateToProps)(immutableToJsHOC(SharingCirclePatientDataViewHeader));
