import React from 'react';
import group from '../assets/img/icons/map-group.svg';
import hospital from '../assets/img/icons/map-hospital.svg';
import watch from '../assets/img/icons/map-watch.svg';
import phone from '../assets/img/icons/map-phone.svg';
import police from '../assets/img/icons/map-police.svg';

const list = [
  {
    title: '<strong>Contact your friend by phone.</strong> If you cannot reach them and think they are in medical danger, call 911.',
    icon: phone,
    text: 'If we provided location information, this is the best estimate as to where your friend is based on their phone\'s GPS signal – not an exact location. Try your best to give emergency services a good idea of where they might be.'
  },
  {
    title: '<strong>Visit your friend or arrange someone else to check on them.</strong>',
    icon: group,
    text: 'If we provided location information for your friend, feel free to compare their GPS location to places they often go.'
  },
  {
    title: '<strong>Use the local police to conduct a wellness check.</strong>',
    icon: police,
    text: 'Wellness check <a href="https://thelawdictionary.org/article/what-is-a-police-welfare-check/" target="_blank">https://thelawdictionary.org/article/what-is-a-police-welfare-check/</a>'
  },
  {
    title: '<strong>Call local hospitals.</strong>',
    icon: hospital,
    text: 'If you suspect your friend may have been taken to a hospital, we provided the front desk phone number for local hospitals to inquire about their status'
  },
  {
    title: '<strong>Be patient and calm.</strong>',
    icon: watch,
    text: 'Keep looking over your options in step 1 - 4 and determine if you should adjust your outreach strategy. If we have your friend\'s GPS location information, we will continuously update it here for up to 24 hours.'
  }
];

function CardList() {
  return (
    <React.Fragment>
      {list.map((item, key) => (
        <div data-testid="list" className="c-card-map-list__wrapper" key={key}>
          <div className="c-card-map-list__icon-wrap">
            <div className="c-card-map-list__icon"><img src={item.icon} alt={item.title}/></div>
          </div>
          <div className="c-card-map-list__num"><span>{key + 1}</span></div>
          <div className="c-card-map-list__carousel-wrapper">
            <div className="c-card-map--unshadowed">
              <div
                className="c-card-map-list__text"
                dangerouslySetInnerHTML={{ __html: item.title }} // eslint-disable-line
              />
              <div
                className="c-card-map-list__text"
                dangerouslySetInnerHTML={{ __html: item.text }} // eslint-disable-line
              />
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}

export default CardList;
