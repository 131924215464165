import React, { memo } from 'react';
// Components
import SearchBar from '../SearchBar';
// Hooks
import useSearchHook from '../../hooks/useSearchHook';

function SearchContacts() {
  const { parsedQuery: { q = '' }, handleSearch } = useSearchHook();

  return (
    <div className="c-search__contacts">
      <SearchBar
        className="h-position--relative"
        onChange={handleSearch}
        value={q}
        placeholder="Search by Contact Name"
      />
    </div>
  );
}

export default memo(SearchContacts);
