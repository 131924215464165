import actionTypes from '../constants/actionTypes';
import contactsSharedWithMeInvitationsService from '../services/contactsSharedWithMeInvitationsService';

function getSharedWithReceivedInvitationsSuccess(response) {
  return {
    type: actionTypes.GET_CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS_SUCCESS,
    payload: response
  };
}

export function getSharedWithReceivedInvitations(searchQuery) {
  return async function (dispatch) {
    try {
      const results = await contactsSharedWithMeInvitationsService.getSharedWithReceivedInvitations(searchQuery);
      dispatch(getSharedWithReceivedInvitationsSuccess(results));
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

function getSharedWithInvitationsCountSuccess(payload) {
  return {
    type: actionTypes.GET_CONTACTS_SHARED_WITH_ME_INVITATIONS_COUNT_SUCCESS,
    payload
  };
}

export function getSharedWithInvitationsCount() {
  return async function (dispatch) {
    try {
      const results = await contactsSharedWithMeInvitationsService.getSharedWithInvitationsCount();
      dispatch(getSharedWithInvitationsCountSuccess(results));
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

export function acceptReceivedInvitation(token) {
  return async function () {
    try {
      const data = await contactsSharedWithMeInvitationsService.acceptReceivedInvitation(token);
      return data;
    } catch (err) {
      throw err;
    }
  };
}
