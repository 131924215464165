import PropTypes from 'prop-types';
import React from 'react';
import ShapeBreakfast from '../svg/ShapeBreakfast';
import ShapeLunch from '../svg/ShapeLunch';
import ShapeDinner from '../svg/ShapeDinner';

const mealTags = [
  { value: 'breakfast', label: 'Breakfast', component: ShapeBreakfast },
  { value: 'lunch', label: 'Lunch', component: ShapeLunch },
  { value: 'dinner', label: 'Dinner', component: ShapeDinner }
];

export default class MealTagsList extends React.Component {
  static propTypes = {
    handleValue: PropTypes.func.isRequired,
    value: PropTypes.string
  };

  changeValue = (value) => () => {
    this.props.handleValue(value);
  }

  render() {
    return (
      <div className="c-icon-grid">
        {mealTags.map((tag, i) => {
          const selected = tag.value === this.props.value;
          const circle = selected ? '#2da9df' : '#fff';
          const circleStroke = selected ? '#2da9df' : '#b8cad1';
          const shape = selected ? '#fff' : '#b8cad1';

          return (
            <div key={i} data-testid={tag.value} className="c-icon-grid__group h-cursor__pointer" onClick={this.changeValue(tag.value)}>
              <svg className="c-icon-grid__icon" viewBox="0 0 56 56" width="80">
                <circle cx="28" cy="28" r="27" fill={circle} stroke={circleStroke} strokeWidth={1}/>
                <tag.component color={shape} transform="translate(16, 16)"/>
              </svg>
              <h3 data-testid={`${tag.value}-${selected}`} className="c-icon-grid__label">{tag.label}</h3>
            </div>
          );
        })}
      </div>
    );
  }
}
