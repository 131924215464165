import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '2Px',
    padding: '40Px',
    position: 'relative'
  },
  containerModal: {
    borderRadius: '2Px',
    padding: '40Px',
    position: 'relative',
    boxShadow: '0px 3px 20px 7px rgba(0, 0, 0, 0.10)'
  },
  header: {
    padding: '18Px'
  },
  close: {
    margin: '10Px',
    padding: '18Px',
    position: 'absolute',
    right: 0,
    top: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  description: {
    marginBottom: '18Px',
    padding: '8Px'
  },
  listWrapper: {
    padding: '0 18Px 18Px'
  },
  list: {
    listStyle: 'decimal',
    padding: '8Px',
    textAlign: 'left'
  },
  button: {
    display: 'flex'
  }
}));
