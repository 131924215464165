import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string,
  direction: PropTypes.string,
  inResultsSection: PropTypes.bool,
  label: PropTypes.string.isRequired,
  styleVars: PropTypes.shape({
    bold: PropTypes.object.isRequired,
    p: PropTypes.object.isRequired,
    sv: PropTypes.object.isRequired
  }).isRequired,
  transform: PropTypes.string.isRequired,
  units: PropTypes.string,
  value: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired
};

function GlucoseSummaryRow(props) {
  const { color, direction, inResultsSection, label, styleVars, transform, units, value, width } = props;
  const { bold, p, sv } = styleVars;
  const iconSize = 24;
  const style = {
    label: {
      ...p,
      fill: sv.color.primaryColor
    },
    value: {
      ...bold,
      fill: color || sv.color.primaryColor
    }
  };
  let rotation = 90;
  if (direction === 'up') {
    rotation = 0;
  } else if (direction === 'down') {
    rotation = 180;
  }

  return (
    <g className="c-glucose-summary-row" transform={transform}>
      <text style={style.label}>{label}</text>
      <text
        style={style.value}
        textAnchor="end"
        transform={`translate(${width - sv.spacing.xl}, 0)`}
      >
        {value}
        {units ? units : ''}
      </text>
      {(direction && !isNaN(value)) || inResultsSection ?
        <g
          height={iconSize}
          transform={`translate(${width - iconSize - sv.spacing.sm}, ${-(iconSize / 1.333)}) rotate(${rotation} ${iconSize / 2} ${iconSize / 2})`}
          width={iconSize}
        >
          <rect x="0" y="0" height={iconSize} width={iconSize} fill="none" stroke="none" />
          <g
            fill="none"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            transform="translate(8.000000, 6.000000)"
          >
            <polyline points="0.464466094 4.70710678 4 1.17157288 7.53553391 4.70710678" />
            <path d="M4,1.17157288 L4,9.7475469" />
          </g>
        </g> :
        <text style={style.value} transform={`translate(${width - iconSize - sv.spacing.sm + 8}, ${-(iconSize / 1.333) + 18})`}>--</text>}
    </g>
  );
}

GlucoseSummaryRow.propTypes = propTypes;

export default GlucoseSummaryRow;
