/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import TableButtonsBlock from 'src/components/shared/tableButtonsBlock/TableButtonsBlock';
import EditInfoBtn from './EditInfoBtn';
import RemoveContactBtn from './RemoveContactBtn';

const propTypes = {
  isSingleItem: PropTypes.bool,
  item: PropTypes.object,
  memoizedFetch: PropTypes.func.isRequired,
  editIAmSharingWithContactsInfo: PropTypes.func.isRequired,
  getAboutContactInfo: PropTypes.func.isRequired,
  deleteYourSharingCircle: PropTypes.func.isRequired,
  deleteYourPractice: PropTypes.func.isRequired
};

export default function ContactsIAmSharingWithTableButtons(props) {
  const {
    item,
    isSingleItem,
    memoizedFetch,
    editIAmSharingWithContactsInfo,
    getAboutContactInfo,
    deleteYourSharingCircle,
    deleteYourPractice
  } = props;
  const isHcp = item.type.includes('Practice');

  const updateTable = () => {
    memoizedFetch('edit', isSingleItem);
  };

  return (
    <TableButtonsBlock>
      {!isHcp && (
        <EditInfoBtn
          getAboutContactInfo={getAboutContactInfo}
          memoizedFetch={memoizedFetch}
          item={item}
          editIAmSharingWithContactsInfo={editIAmSharingWithContactsInfo}
        />
      )}
      <RemoveContactBtn
        updateTable={updateTable}
        deleteYourSharingCircle={deleteYourSharingCircle}
        deleteYourPractice={deleteYourPractice}
        item={item}
      />
    </TableButtonsBlock>
  );
}

ContactsIAmSharingWithTableButtons.propTypes = propTypes;
