/* istanbul ignore file */
import apiService from './apiService';
import authenticationService from './authenticationService';

async function postHelpEmail(values) {
  const { data } = await apiService.post(`${authenticationService.AUTH_URL}/help_email`, values);
  return data;
}

export default {
  postHelpEmail
};
