import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form';
import FileInput from './FileInput';

const propTypes = {
  avatar: PropTypes.string,
  getAvatar: PropTypes.func.isRequired,
  putAvatar: PropTypes.func.isRequired
};

function AvatarField(props) {
  async function onChange() {
    const file = document.querySelector('[name=avatar]').files[0];
    await props.putAvatar(file);
    await props.getAvatar();
  }

  return (
    <Form onSubmit={() => {}}>
      {() => (
        <form name="avatarForm">
          <Field
            component={FileInput}
            label="+ Upload New Photo"
            name="avatar"
            image={props.avatar}
            onChange={onChange}
          />
        </form>
      )}
    </Form>
  );
}

AvatarField.propTypes = propTypes;

export default AvatarField;
