import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  name: {
    fontSize: '1.34rem',
    lineHeight: '2rem',
    textTransform: 'capitalize'
  },
  email: {
    fontSize: '0.777rem'
  }
}));
