import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function StepsIcon({ className }) {
  let classes = 'c-icon c-icon--steps';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <title>Steps</title>
      <path className="c-icon__fill c-icon__fill--alt" d="M12.11,5.19a2,2,0,1,0-2-2A2,2,0,0,0,12.11,5.19ZM18.36,21l-1.88-4.53a1.17,1.17,0,0,0-.29-.42L13.72,13.6V11.38l2.32,2,.15.16a.9.9,0,0,0,1.26-1.28L17.19,12v0s-3-2.89-3.48-3.93V7.88a1.62,1.62,0,0,0-1.61-1.61,1.55,1.55,0,0,0-.9.28L8.29,8.49a.92.92,0,0,0-.3.29L5.68,12.42a.9.9,0,0,0,1.51,1L9.4,9.91l1.1-.74v4.12a10.39,10.39,0,0,1-.76,1.23l-4,6.31a1.25,1.25,0,0,0,2.12,1.34l3.58-5.66.76-.88,2.06,2L16,22a1.26,1.26,0,0,0,2.32-1Z" />
    </svg>
  );
}

StepsIcon.propTypes = propTypes;

export default StepsIcon;
