const nonDigit = /[^\d]/g;

export function mapPhoneNumbers(values) {
  const params = {
    phoneNumbers: [],
    ...values
  };

  if (values.cellNumber) {
    params.phoneNumbers.push({
      label: 'cell',
      number: values.cellNumber.replace(nonDigit, ''),
      preferred: true
    });
  }

  if (values.homeNumber) {
    params.phoneNumbers.push({
      label: 'home',
      number: values.homeNumber.replace(nonDigit, '')
    });
  }

  // eslint-disable-next-line no-unused-vars
  const { homeNumber, cellNumber, ...rest } = params;

  return { ...rest };
}
