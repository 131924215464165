import { makeStyles } from '@material-ui/core/styles';
import { sv } from 'src/assets/css/1_settings/variables';

export const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    flexGrow: '1',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
      flexWrap: 'wrap',
      width: '100%'
    },
    '& > *': {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'start',
        display: 'flex',
        width: '100%'
      }
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '1rem',
      width: '100%'
    }
  },
  title: {
    paddingRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0'
    }
  },
  btn: {
    background: 'transparent',
    marginRight: '1rem'
  },
  btnContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0'
    }
  },
  icon: {
    marginRight: '12Px'
  },
  disabled: {
    color: sv.color.textLight
  }
}));
