import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
// Components
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const propTypes = {
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitError: PropTypes.node,
    valid: PropTypes.bool
  }).isRequired,
  children: PropTypes.node
};

function MuiSelectField(props) {
  const {
    className,
    disabled,
    input,
    label,
    children,
    onChange,
    meta: {
      error,
      touched,
      submitFailed,
      submitError,
      valid
    }
  } = props;

  const showErrorText = (touched && !valid) || submitFailed;
  const formControlClasses = classNames('h-full-width', className);

  return (
    <FormControl className={formControlClasses}>
      <InputLabel htmlFor="uncontrolled-native" >{label}</InputLabel>
      <Select
        data-testid={props['data-testid']}
        error={(showErrorText && (Boolean(error) || Boolean(submitError)))}
        disabled={disabled}
        inputProps={{ ...input }}
        onChange={(event) => {
          input.onChange(event.target.value);
          onChange && onChange(event);
        }}
      >
        {children}
      </Select>
      {(showErrorText && (Boolean(error) || Boolean(submitError))) ? <FormHelperText error>{error || submitError}</FormHelperText> : ''}
    </FormControl>
  );
}

MuiSelectField.propTypes = propTypes;

export default MuiSelectField;
