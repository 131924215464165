/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, Paper } from '@material-ui/core';

const propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default function ModalContainer({ children, ...restProps }) {
  return (
    <Dialog
      scroll="paper"
      fullWidth
      maxWidth="md"
      data-testid="dialog-invites"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperComponent={Paper}
      PaperProps={{
        elevation: 0,
        style: {
          width: 544,
          maxHeight: 'calc(100vh - 72)',
          boxShadow: '0 1px 3px rgb(0 0 0 / 10%), 0 0.375rem 1.5rem rgb(0 0 0 / 10%)',
          ...(restProps?.paperStyle ?? {})
        }
      }}
      BackdropProps={{ style: { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.75)' } }}
      {...restProps}
    >
      {children}
    </Dialog>
  );
}

ModalContainer.propTypes = propTypes;
