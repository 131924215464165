import PropTypes from 'prop-types';
import React from 'react';
// Components
import GraphIconGroup from './GraphIconGroup';

const propTypes = {
  bold: PropTypes.object,
  container: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  p: PropTypes.object,
  sv: PropTypes.object,
  filterID: PropTypes.string.isRequired
};

function mediaQuery(width, max = 1215) {
  return width < max ? width : max;
}

function DashboardGraphMessageGroup(props) {
  const { bold, container, filterID, p, sv } = props;
  // Message
  const messageMaxWidth = sv.spacing.md * 42;
  const backgroundRect = {
    fill: '#000',
    height: container.height - sv.spacing.xxl,
    opacity: 0.3,
    width: mediaQuery((container.width - sv.spacing.lg), messageMaxWidth)
  };
  const messageRect = {
    fill: '#fff',
    filter: `url(#${filterID})`,
    height: backgroundRect.height,
    opacity: 0.9,
    width: backgroundRect.width
  };
  const messageTranslation = `translate(
    ${messageRect.width < messageMaxWidth ? sv.spacing.md : ((container.width - messageMaxWidth) / 2)},
    ${Math.floor((container.height - messageRect.height) / 2)}
  )`;
  const typeGroup = {
    x: messageRect.width / 2,
    headingY: messageRect.height / 2,
    paragraphY: messageRect.height - (messageRect.height / 3)
  };
  const iconSize = 48;
  const message = {
    line1: <tspan>There are no readings for the date range selected.</tspan>,
    line2: <tspan>Please choose another date range</tspan>,
    line3: <tspan>or take a new reading with your POGO Automatic&#174;.</tspan>
  };

  const fontSize = container.width < 768 ? sv.fontSize.sm : sv.fontSize.lg;

  return (
    <g transform={messageTranslation}>
      <rect
        rx={sv.spacing.xs}
        style={backgroundRect}
      />
      <rect
        height={messageRect.height}
        rx={sv.spacing.xs}
        style={messageRect}
        width={messageRect.width}
      />
      <GraphIconGroup
        transform={`translate(${(messageRect.width - iconSize) / 2}, ${messageRect.height / 4.5})`}
        color="#2da9df"
      />
      <text
        transform={`translate(${typeGroup.x}, ${typeGroup.headingY})`}
        textAnchor="middle"
        dominantBaseline="baseline"
        style={bold}
      >
        No Results
      </text>
      <g transform={`translate(${typeGroup.x}, ${typeGroup.paragraphY})`}>
        <text
          textAnchor="middle"
          style={{ ...p, fontSize }}
          dominantBaseline="baseline"
        >
          {message.line1}
        </text>
      </g>
      <g transform={`translate(${typeGroup.x}, ${typeGroup.paragraphY + sv.fontSize.lg + sv.spacing.sm})`}>
        <text
          textAnchor="middle"
          style={{ ...p, fontSize }}
          dominantBaseline="baseline"
        >
          {message.line2}
        </text>
      </g>
      <g transform={`translate(${typeGroup.x}, ${typeGroup.paragraphY + sv.fontSize.lg + sv.spacing.sm + sv.fontSize.lg + sv.spacing.sm})`}>
        <text
          textAnchor="middle"
          style={{ ...p, fontSize }}
          dominantBaseline="baseline"
        >
          {message.line3}
        </text>
      </g>
    </g>
  );
}

DashboardGraphMessageGroup.propTypes = propTypes;

export default DashboardGraphMessageGroup;
