import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Grid, Paper, DialogContent } from '@material-ui/core';
import CheckCircleStatus from 'src/components/CheckCircleStatus';
import ChevronIcon from 'src/components/icons/ChevronIcon';
import confirm from 'src/utils/ConfirmHelpers';
import routesConstants from 'src/constants/routes';
import Text from 'src/shared/Text/Text';
import { useStyles } from './ProfileChecklist.styles';

export const settingsList = [
  { name: 'about', label: 'About Me', loc: '/profile/about-me' },
  { name: 'notifications', label: 'Notifications', loc: '/profile/notifications' },
  { name: 'targets', label: 'Glucose Targets', loc: '/profile/glucose-targets' },
  { name: 'testing', label: 'Testing Schedule', loc: '/profile/testing-schedule' },
  { name: 'medications', label: 'Medications', loc: '/profile/Medications' },
  { name: 'care', label: 'Contacts', loc: routesConstants.CONTACTS_I_AM_SHARING_WITH }
];

const propTypes = {
  doNotShowChecklist: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  status: PropTypes.shape({
    skippedSetupRemaining: PropTypes.array,
    setupRemaining: PropTypes.array
  })
};

export default function ProfileChecklist({ doNotShowChecklist, closeModal, status: { setupRemaining } }) {
  const classes = useStyles();
  const progressVal = Math.round(((settingsList.length - setupRemaining.length) / settingsList.length) * 100);

  const forgetOnboarding = async () => {
    const confirmOptions = {
      cancelText: 'Cancel',
      confirmText: 'Confirm'
    };

    if (await confirm('This will permanently remove the onboarding steps for Patterns. Please confirm you want to continue or click cancel to go back.', confirmOptions)) {
      doNotShowChecklist(setupRemaining);
      closeModal();
    }
  };

  const returnProgress = () => {
    return `${settingsList.length - setupRemaining.length} / ${settingsList.length}`;
  };

  const returnTitle = () => {
    const allSettingsCount = settingsList.length - setupRemaining.length;
    return allSettingsCount === settingsList.length ? 'All complete. Nicely done.' : 'Thank you. Just a bit more.';
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      scroll="paper"
      fullWidth
      maxWidth="sm"
      data-testid="dialog-profile-checklist"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperComponent={Paper}
      PaperProps={{ elevation: 0, style: { maxWidth: '544Px' } }}
      BackdropProps={{ classes: { root: classes.backDrop } }}
    >
      <Grid container>
        <Grid item xs={12} className={classes.titleContainer}>
          <Text alignText="center" font="bold" fontSize={20} lineHeight={28}>
            {returnTitle()}
          </Text>
        </Grid>
      </Grid>

      <Grid container className={classes.subHeaderContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.subHeader}>
            <Text alignText="center" fontSize={16} lineHeight={28}>
              Get the most out of <strong>Patterns<sup>&reg;</sup></strong> by completing your profile.
            </Text>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Text font="bold" alignText="center" color="body-light" fontSize={11}>
              {`${returnProgress()} Complete`}
            </Text>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <svg className="c-progress" width="300px" viewBox="0 0 100 4">
              <rect className="c-progress__bar" x="0" y="0" width="100" height="4"/>
              <rect className="c-progress__bar c-progress__bar--filled" x="0" y="0" width={progressVal} height="4" rx="1"/>
            </svg>
          </Grid>
        </Grid>
      </Grid>

      <DialogContent tabIndex={-1} className={classes.dialogBodyContainer}>
        <Grid container>
          <Grid item xs={12}>
            {settingsList.map((setting, i) => {
              const needsSetup = setupRemaining.includes(setting.name);

              return (
                <Link key={i} to={setting.loc} onClick={closeModal} className={classes.itemContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs="auto" className={classes.itemIcon}>
                      <CheckCircleStatus checked={!needsSetup}/>
                    </Grid>
                    <Grid item className={classes.itemTitle}>
                      <Text font="bold" alignText="left" fontSize={18}>{setting.label}</Text>
                    </Grid>
                    <Grid item xs="auto" className={classes.strokeIcon}>
                      <ChevronIcon/>
                    </Grid>
                  </Grid>
                </Link>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>

      <Grid container>
        <Grid item xs={12} className={classes.actionContainer}>
          <button
            className="c-button c-button--block c-button--hollow h-margin-sm"
            onClick={closeModal}
          >
            Not Right Now
          </button>
          <button
            data-testid="jest-confirm"
            className="c-button c-button--block c-button--hollow h-margin-sm"
            onClick={forgetOnboarding}
          >
            Don't Show Again
          </button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

ProfileChecklist.propTypes = propTypes;
