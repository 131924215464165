import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import * as authenticationActionCreators from '../actions/authenticationActions';
import SignOut from '../components/SignOut';

function mapStateToProps(state) {
  return {
    headers: state.authentication.get('headers'),
    refreshToken: state.authentication.get('refreshToken')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      authentication: bindActionCreators(authenticationActionCreators, dispatch)
    }
  };
}

export {
  mapStateToProps,
  mapDispatchToProps
};
export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
