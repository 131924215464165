/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import CountlyTrackRouteContainer from '../../containers/CountlyTrackRouteContainer';

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  pageName: PropTypes.string,
  pageGroup: PropTypes.string,
  properties: PropTypes.object
};

export default function CountlyTrackedRoute({ component: Component, pageName, pageGroup, properties, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => (
        <CountlyTrackRouteContainer pageName={pageName} pageGroup={pageGroup} {...routeProps}>
          <Component {...routeProps} {...properties} />
        </CountlyTrackRouteContainer>
      )}
    />
  );
}

CountlyTrackedRoute.propTypes = propTypes;
