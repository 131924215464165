import PropTypes from 'prop-types';
import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

const propTypes = {
  classes: PropTypes.object,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.date,
    onChange: PropTypes.func.isRequired
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    submitError: PropTypes.node,
    error: PropTypes.string
  })
};

function MuiTimePicker(props) {
  const {
    classes,
    input,
    meta: {
      submitError,
      error,
      touched,
      valid
    }
  } = props;

  const showErrorText = touched && !valid;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimePicker
        autoOk
        ampm
        classes={classes}
        error={(showErrorText && (Boolean(error) || Boolean(submitError)))}
        helperText={showErrorText && (error || submitError)}
        label="12hr Format"
        name={input.name}
        value={input.value ? input.value : null}
        onChange={(date) => input.onChange(date)}
      />
    </MuiPickersUtilsProvider>
  );
}

MuiTimePicker.propTypes = propTypes;

export default MuiTimePicker;
