import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  sharingWithMeContacts: new Immutable.Map({
    sharedWithMe: new Immutable.List(),
    sharedWithMeCount: 0
  }),
  sharingWithContacts: new Immutable.Map({
    sharingWith: new Immutable.List(),
    sharingWithCount: 0
  })
});

export default function contactsReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.GET_CONTACTS_SHARED_WITH_ME_SUCCESS:
    return state.set('sharingWithMeContacts', Immutable.fromJS(action.payload));

  case actionTypes.GET_CONTACTS_I_AM_SHARING_WITH_SUCCESS:
    return state.set('iAmSharingWithContacts', Immutable.fromJS(action.payload));

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
