import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import ChevronIcon from 'src/components/icons/ChevronIcon';

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  validateDuplicate: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
};

export default function DrugListWrapper({ push, goBack, children, type, header, validateDuplicate }) {
  const defaultError = ' '; // Should be non-empty string
  const [drug, setDrug] = useState('');
  const [error, setError] = useState(defaultError);

  const addDrug = () => {
    const value = String(drug).trim();

    if (value?.length > 100 || !value?.length) {
      setError('Medication Name must be 100 characters or less');
      return;
    }

    const shouldAddDrug = validateDuplicate(value);
    shouldAddDrug && push(type, { [value]: true });
    setDrug('');
  };

  return (
    <Fragment>
      <a data-testid="go-back" className="c-nav-header h-cursor__pointer" onClick={goBack}>
        <ChevronIcon />
        <h2 className="c-nav-header__label">{header}</h2>
      </a>

      {children}

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={10}>
          <TextField
            fullWidth
            value={drug}
            onChange={(e) => setDrug(e.target.value)}
            label="Add Drug or Manufacturer"
            type="text"
            helperText={error}
          />
        </Grid>
        <Grid item xs={2}>
          <button
            type="button"
            className="c-inline-form__button c-button c-button--action v-MuiTextField-root"
            onClick={addDrug}
          >
            +
          </button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

DrugListWrapper.propTypes = propTypes;
