/* istanbul ignore file */
import { connect } from 'react-redux';
import { getStatus } from 'src/actions/statusActions';
import { getAboutMe, putAboutMe } from 'src/actions/profileActions';
import SharingCircleAboutMeForm from 'src/pages/sharingCircle/children/profile/children/SharingCircleAboutMeForm';

const mapStateToProps = state => ({
  cellNumberVerified: state.profile.get('cellNumberVerified'),
  initialValues: {
    firstName: state.profile.get('firstName'),
    lastName: state.profile.get('lastName'),
    cellNumber: state.profile.get('cellNumber')
  }
});

export default connect(mapStateToProps, {
  getAboutMe,
  getStatus,
  putAboutMe
})(SharingCircleAboutMeForm);
