/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Text from 'src/shared/Text';
import { normalizePhone } from 'src/services/fieldNormalizationService';

const propTypes = {
  phoneNumber: PropTypes.string
};

const TooltipText = ({ phoneNumber }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Text font="bold" fontSize={16} lineHeight={24} color="body">
          Your EC uses this phone to notify you:
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text fontSize={16} lineHeight={24} color="body">
          {normalizePhone(phoneNumber)}
        </Text>
      </Grid>
    </Grid>
  );
};

TooltipText.propTypes = propTypes;
export default TooltipText;
