import apiService from './apiService';
const AUTH_URL = process.env.AUTH_URL || '';

async function getPhoneNumberVerified(token, prefix) {
  const { data } = await apiService.get(`${AUTH_URL}/${prefix}/initiate/${token}`);
  return data;
}

export default {
  getPhoneNumberVerified
};
