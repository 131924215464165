import actionTypes from '../constants/actionTypes';
import emergencyEventService from '../services/emergencyEventService';

function fetchEmergencyEventDataSuccess(payload) {
  return {
    type: actionTypes.GET_EMERGENCY_EVENT_DATA,
    payload
  };
}

export function fetchEmergencyEventData(code) {
  return async function (dispatch) {
    const formResponse = await emergencyEventService.fetchEmergencyEventData(code);
    dispatch(fetchEmergencyEventDataSuccess(formResponse));
  };
}
