/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getIAmSharingWithInvitationsCount } from 'src/actions/contactsIAmSharingWithInvitesActions';
import IamSharingInvitesSentCount from 'src/components/contacts/IamSharingInvitesSentCount';
import IamSharingInvitesReceivedCount from 'src/components/contacts/IamSharingInvitesReceivedCount';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  receivedInvitations: PropTypes.shape({
    pending: PropTypes.number
  }),
  sentInvitations: PropTypes.shape({
    avatarUrls: PropTypes.array,
    pending: PropTypes.number,
    expired: PropTypes.number,
    declined: PropTypes.number
  })
};

function IamSharingInvitesCountContainer({ receivedInvitations, sentInvitations }) {
  useEffect(() => {
    getIAmSharingWithInvitationsCount();
  }, []);

  return (
    <>
      <IamSharingInvitesSentCount sentInvitations={sentInvitations}/>
      <IamSharingInvitesReceivedCount receivedInvitations={receivedInvitations}/>
    </>
  );
}

IamSharingInvitesCountContainer.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    receivedInvitations: state.contactsIAmSharingWithInvitations.getIn(['invitationsCount', 'receivedPracticeInvitations']),
    sentInvitations: state.contactsIAmSharingWithInvitations.getIn(['invitationsCount', 'sentInvitations'])
  };
}

export default connect(mapStateToProps)(immutableToJsHOC(IamSharingInvitesCountContainer));
