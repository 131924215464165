export const MHEALTH_TYPES = {
  CALORIES_BURNED: 'caloriesBurned',
  CALORIES_CONSUMED: 'foods',
  CARBS: 'carbs',
  HYDRATION: 'water',
  SLEEP: 'sleeps',
  STEPS: 'steps',
  WEIGHT: 'weights'
};

export const MHEALTH_LABELS = {
  [MHEALTH_TYPES.CALORIES_BURNED]: 'Calories Burned Data',
  [MHEALTH_TYPES.CALORIES_CONSUMED]: 'Calories Consumed Data',
  [MHEALTH_TYPES.CARBS]: 'Carbohydrates Data',
  [MHEALTH_TYPES.HYDRATION]: 'Hydration Data',
  [MHEALTH_TYPES.SLEEP]: 'Sleep Data',
  [MHEALTH_TYPES.STEPS]: 'Steps Data',
  [MHEALTH_TYPES.WEIGHT]: 'Weight Data'
};
