import apiService from './apiService';

const AUTH_URL = process.env.AUTH_URL || '';

async function fetchEmergencyEventData(code) {
  try {
    const { data } =  await apiService.post(`${AUTH_URL}/emergency_events`, { code });
    return { data };
  } catch (err) {
    const errorMessage = 'There was problem when fetching emergency event data';

    throw new Error(errorMessage);
  }
}

export default {
  fetchEmergencyEventData
};
