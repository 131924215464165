import sc from 'src/assets/img/icons/sharing_circle_list.svg';
import scDisabled from 'src/assets/img/icons/sharing_circle_list_disabled.svg';
import ec from 'src/assets/img/icons/emergency_contact_list.svg';
import ecDisabled from 'src/assets/img/icons/emergency_contact_list_disabled.svg';
import hcp from 'src/assets/img/icons/hcp_list.svg';
import hcpDisabled from 'src/assets/img/icons/hcp_list_disabled.svg';

export const roleList = [
  { type: 'sharing_circle', activeIcon: sc, disabledIcon: scDisabled, title: 'Sharing Circle', enabled: true },
  { type: 'emergency_contacts', activeIcon: ec, disabledIcon: ecDisabled, title: 'Emergency Contacts', enabled: true },
  { type: 'practices', activeIcon: hcp, disabledIcon: hcpDisabled, title: 'HCP/Practice', enabled: true }
];
