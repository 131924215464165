import React from 'react';

function CircleXIcon() {
  return (
    <svg className="c-icon" viewBox="0 0 24 24">
      <path
        className="c-icon__fill"
        d="M13.4142136,12 L15.7071068,9.70710678 C16.0976311,9.31658249 16.0976311,8.68341751 15.7071068,8.29289322 C15.3165825,7.90236893 14.6834175,7.90236893 14.2928932,8.29289322 L12,10.5857864 L9.70710678,8.29289322 C9.31658249,7.90236893 8.68341751,7.90236893 8.29289322,8.29289322 C7.90236893,8.68341751 7.90236893,9.31658249 8.29289322,9.70710678 L10.5857864,12 L8.29289322,14.2928932 C7.90236893,14.6834175 7.90236893,15.3165825 8.29289322,15.7071068 C8.68341751,16.0976311 9.31658249,16.0976311 9.70710678,15.7071068 L12,13.4142136 L14.2928932,15.7071068 C14.6834175,16.0976311 15.3165825,16.0976311 15.7071068,15.7071068 C16.0976311,15.3165825 16.0976311,14.6834175 15.7071068,14.2928932 L13.4142136,12 Z M12,4 C16.4178257,4 20,7.58171429 20,12 C20,16.4182857 16.4178257,20 12,20 C7.58103889,20 4,16.4182857 4,12 C4,7.58171429 7.58103889,4 12,4 Z"
      />
    </svg>
  );
}

export default CircleXIcon;
