/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  isActive: PropTypes.bool
};

export default function StarIcon({ isActive }) {
  const color = isActive ? '#FDB62C' : '#70777A';

  return (
    <svg width="24px" height="23px" viewBox="0 0 24 23">
      <g id="Web-Refined" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1260.000000, -518.000000)" fill={color}>
          <polygon id="fav_star_icon_active" points="1272 535.6 1279.4 541 1276.6 532.2 1284 527 1275 527 1272 518 1269 527 1260 527 1267.4 532.2 1264.6 541"/>
        </g>
      </g>
    </svg>

  );
}

StarIcon.propTypes = propTypes;
