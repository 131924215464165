import actionTypes from '../constants/actionTypes';
import nutritionixService from '../services/nutritionixService';

// Utils
import { foodsPropsMapper } from '../utils/logBookFoods';

// GET_NUTRITIONIX_AUTOCOMPLETE
function getNutritionixAutocompleteFailure(err) {
  return {
    type: actionTypes.GET_NUTRITIONIX_AUTOCOMPLETE_FAILURE,
    error: true,
    payload: err
  };
}

function getNutritionixAutocompleteStart() {
  return {
    type: actionTypes.GET_NUTRITIONIX_AUTOCOMPLETE_START
  };
}

function getNutritionixAutocompleteSuccess(query, foodObj) {
  return {
    type: actionTypes.GET_NUTRITIONIX_AUTOCOMPLETE_SUCCESS,
    payload: {
      query,
      foodObj
    }
  };
}

// GET_NUTRITIONIX_FOOD_BY_ID
function getNutritionixFoodByIdFailure(err) {
  return {
    type: actionTypes.GET_NUTRITIONIX_FOOD_BY_ID_FAILURE,
    error: true,
    payload: err
  };
}

function getNutritionixFoodByIdStart() {
  return {
    type: actionTypes.GET_NUTRITIONIX_FOOD_BY_ID_START
  };
}

function getNutritionixFoodByIdSuccess(food) {
  return {
    type: actionTypes.GET_NUTRITIONIX_FOOD_BY_ID_SUCCESS,
    payload: {
      food
    }
  };
}

export function clearNutritionix() {
  return {
    type: actionTypes.CLEAR_NUTRITIONIX
  };
}

export function clearNutritionixSelectedFood() {
  return {
    type: actionTypes.CLEAR_NUTRITIONIX_SELECTED_FOOD
  };
}

export function getNutritionixAutocomplete(query, readingId) {
  return async function (dispatch) {
    dispatch(getNutritionixAutocompleteStart());

    try {
      const foodObj = await nutritionixService.getNutritionixAutocomplete(query, readingId);
      dispatch(getNutritionixAutocompleteSuccess(query, foodObj));
    } catch (err) {
      dispatch(getNutritionixAutocompleteFailure(err));
      throw err;
    }
  };
}

export function getNutritionixFoodById(readingId, type, val, nixItemId) {
  return async function (dispatch) {
    dispatch(getNutritionixFoodByIdStart());

    try {
      const result = await nutritionixService.getNutritionixFoodById(readingId, type, val, nixItemId);
      dispatch(getNutritionixFoodByIdSuccess(result.foods[0]));
    } catch (err) {
      dispatch(getNutritionixFoodByIdFailure(err));
      throw err;
    }
  };
}

export function getNutritionixFoodByIdAndReadingId(readingId, foodId) {
  return async function (dispatch) {
    dispatch(getNutritionixFoodByIdStart());

    try {
      const response = await nutritionixService.getNutritionixFoodByIdAndReadingId(readingId, foodId);
      const result = foodsPropsMapper(response);
      dispatch(getNutritionixFoodByIdSuccess(result));
    } catch (err) {
      dispatch(getNutritionixFoodByIdFailure(err));
      throw err;
    }
  };
}

function getNutritionixFoodAltMeasuresSuccess(response) {
  return {
    type: actionTypes.GET_NUTRITIONIX_FOOD_ALT_MEASURES,
    payload: response
  };
}

export function getNutritionixFoodAltMeasures(readingId, type, val, nixItemId) {
  return async function (dispatch) {
    const { foods } = await nutritionixService.getNutritionixFoodById(readingId, type, val, nixItemId);
    dispatch(getNutritionixFoodAltMeasuresSuccess(foods[0]));
  };
}

export function setNutritionixQuery(query) {
  return {
    type: actionTypes.SET_NUTRITIONIX_QUERY,
    payload: {
      query
    }
  };
}

export function setNutritionixSelectedFood(food) {
  return {
    type: actionTypes.SET_NUTRITIONIX_SELECTED_FOOD,
    payload: food
  };
}
