import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: '2rem'
  },
  content: {
    textAlign: 'center',
    fontSize: '0.888rem',
    marginBottom: '24px'
  }
}));
