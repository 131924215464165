import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Logo from '../Logo';
import SignOutContainer from '../../containers/SignOutContainer';
import AvatarPresenter from '../AvatarPresenter';
import { Container } from '@material-ui/core';
import PatternsNavLink from '../PatternsNavLink';
import routesConstants from '../../constants/routes';
import { ContactsLink } from '../svg/links';

const propTypes = {
  avatar: PropTypes.string,
  getAvatar: PropTypes.func.isRequired
};

export default function SharingCircleHeader({ avatar, getAvatar }) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => { getAvatar(); }, []);

  const overlayClasses = classNames({
    'c-overlay': true,
    'c-overlay--visible': isActive
  });

  const navClasses = classNames('l-nav-tabs l-nav-tabs--right', {
    'l-nav-tabs--visible': isActive
  });

  return (
    <Container maxWidth="lg">
      <div className={overlayClasses} onClick={() => setIsActive(active => !active)} />
      <div className="l-header">
        <div className="l-header__logo" style={{ paddingLeft: '20px' }}>
          <Logo />
        </div>

        <nav className={navClasses}>
          <PatternsNavLink
            data-testid="patNav"
            to={routesConstants.SHARING_CIRCLE_SHARED_WITH_ME}
            onClick={() => setIsActive(active => !active)}
            Icon={ContactsLink}
            label="Contacts"
          />
        </nav>

        <div className="l-header__right">
          <SignOutContainer />
          <NavLink
            to="/sharing-circle/profile"
            className="c-nav-tab"
            activeClassName="c-nav-tab--active"
          >
            <AvatarPresenter avatarUrl={avatar} />
          </NavLink>
        </div>
      </div>
    </Container>
  );
}

SharingCircleHeader.propTypes = propTypes;
