/* istanbul ignore file */
import { connect } from 'react-redux';
// Components
import SCCreateAccountButton from '../components/SCCreateAccountButton';
import { immutableToJsHOC } from './ImmutableToJsHOC';

export function makeMapStateToProps() {
  return function (state) {
    const isPatient = state.status.get('scopes').includes('patient');
    const isCarer = state.status.get('scopes').includes('carer');

    return {
      visible: !isPatient && isCarer
    };
  };
}

export default connect(makeMapStateToProps)(immutableToJsHOC(SCCreateAccountButton));
