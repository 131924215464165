import Immutable from 'immutable';
import { REHYDRATE } from 'redux-persist';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false
});

export default function tokenReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.REFRESH_TOKEN_START:
    return state.set('isActive', true);

  case actionTypes.SET_TOKEN: {
    return state.set(action.payload.tokenType, action.payload.token);
  }

  case actionTypes.REFRESH_TOKEN_SUCCESS:
  case actionTypes.REFRESH_TOKEN_FAILURE:
    return state.set('isActive', false);

  case REHYDRATE:
    return action.payload && action.payload.token ? action.payload.token.merge(state) : state; // custom redux-persist rehydrate functionality for bugfix/ITY-1567

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;
  default:
    return state;
  }
}
