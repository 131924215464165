import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  label: {
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100 %'
  }
}));
