/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { StyledTableRow } from 'src/components/muiStyledComponents/StyledTableRow';
import StyledTableHeadCell from 'src/components/muiStyledComponents/StyledTableHeadCell';
import StyledTablePagination from 'src/components/muiStyledComponents/StyledTablePagination';
import TypesPresenter from 'src/components/contacts/TypesPresenter/TypesPresenter';
import usePaginatorHook from 'src/hooks/usePaginatorHook';
import ContactsIAmSharingWithReceivedInvitesTableButtonsContainer from 'src/containers/ContactsIAmSharingWithReceivedInvitesTableButtonsContainer';
import { Grid } from '@material-ui/core';

const propTypes = {
  memoizedFetch: PropTypes.func.isRequired,
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number
};

function ContactsIAmSharingWithReceivedInvitesTable({ itemsList, itemsListCount, memoizedFetch }) {
  const { parsedQuery, handleChangeRowsPerPage, handleChangePage } = usePaginatorHook();
  const { perPage = 5, page = 0 } = parsedQuery;

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow className="h-height-xl">
            <StyledTableHeadCell align="left" style={{ width: 270 }}>Contact Name</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 100 }}>Type</StyledTableHeadCell>
            <StyledTableHeadCell align="left" >ADDRESS</StyledTableHeadCell>
            <StyledTableHeadCell align="left" >EMAIL</StyledTableHeadCell>
            <StyledTableHeadCell align="left" style={{ width: 325 }}>ACTION</StyledTableHeadCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {itemsList.map((item, index) => {
            const tableRowClasses = classNames(
              'jest-item-invite',
              'h-height-xl',
              { 'h-danger': item.isDeclined }
            );

            return (
              <StyledTableRow key={index} className={tableRowClasses}>
                <TableCell align="left">
                  <Grid container alignItems="center">
                    <div>{item.name}</div>
                    {item.isDeclined && <div className="h-c-danger">Declined</div>}
                  </Grid>
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center">
                    <TypesPresenter type={item.type} />
                  </Grid>
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center">{item.address}</Grid>
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center">{item.emailAddress}</Grid>
                </TableCell>
                <TableCell align="center">
                  <ContactsIAmSharingWithReceivedInvitesTableButtonsContainer itemsListCount={itemsList.length} memoizedFetch={memoizedFetch} item={item} />
                </TableCell>
              </StyledTableRow>
            );
          })}
          {!itemsList.length ? (
            <StyledTableRow style={{ height: 30 }} className="h-height-xl">
              <TableCell colSpan={5} className="h-center jest-empty">No results found</TableCell>
            </StyledTableRow>
          ) : null}
        </TableBody>
      </Table>
      <StyledTablePagination
        page={Number(page) || 0}
        rowsPerPage={Number(perPage) || 0}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={itemsListCount || 0}
        labelRowsPerPage="Rows:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

ContactsIAmSharingWithReceivedInvitesTable.propTypes = propTypes;
export default memo(ContactsIAmSharingWithReceivedInvitesTable);
