import { connect } from 'react-redux';
import { setSelectedPatient } from 'src/actions/selectedPatientActions';
import { deleteYourSharingCircle } from 'src/actions/contactsActions';
import ContactsSharingWithMeTableButtons from 'src/pages/contacts/children/sharingWithMe/ContactsSharingWithMeTableButtons';
import { immutableToJsHOC } from 'src/containers/ImmutableToJsHOC';
import { getLastKnownReading } from 'src/actions/readingsActions';

export default connect(null, {
  getLastKnownReading,
  setSelectedPatient,
  deleteYourSharingCircle
})(immutableToJsHOC(ContactsSharingWithMeTableButtons));
