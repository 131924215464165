import React from 'react';
import { useHistory } from 'react-router-dom';

export default function GoToMarketPlaceButton() {
  const history = useHistory();

  const handleClick = () => {
    history.push('/profile/Marketplace');
  };

  return (
    <button
      data-testid="marketPlace"
      onClick={handleClick}
      className="c-button c-button--condensed c-button--hollow c-button--action"
    >
      <span>+ Add More Data Sources</span>
    </button>
  );
}
