export function canScroll(el) {
  return (el && typeof el === 'object' && !!el.scrollIntoView);
}

export function scrollParentIntoView(selector) {
  const selected = document.querySelector(selector);

  if (canScroll(selected)) {
    const parent = selected.offsetParent;

    if (canScroll(parent)) {
      parent.scrollIntoView();
    } else {
      selected.scrollIntoView();
    }
  }
}

/**
  * Will scroll to the element
  * Usage: scrollSelectorIntoView('.js-scroll-to');
  */
export function scrollSelectorIntoView(selector) {
  const selected = document.querySelector(selector);

  if (canScroll(selected)) {
    selected.scrollIntoView();
  }
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}
