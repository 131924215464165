import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.3rem',
    marginBottom: '8px',
    [theme.breakpoints.down('xs')]: {
      padding: '0.6rem'
    },
    width: '100%',
    '&:last-child': {
      marginBottom: '32px'
    }
  },
  blue: {
    background: theme.palette?.backgroundActive?.main,
    border: `1.5px solid ${theme.palette?.backgroundActive?.dark}`
  },
  green: {
    background: theme.palette?.success?.light,
    border: `1.5px solid ${theme.palette?.success?.main}`
  },
  avatarContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    paddingRight: '1.3rem',
    '& > .c-avatar:not(:first-child)': {
      marginLeft: '-10Px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-20Px'
      }
    }
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  description: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1

  },
  link: {
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end',
      display: 'flex'
    }
  }
}));
