import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';
import Text from 'src/shared/Text/Text';
import Divider from 'src/shared/Divider/Divider';
import { insulins } from 'src/constants/medicationDrugs';
import confirm from 'src/utils/ConfirmHelpers';
import MuiToggle from 'src/components/muiComponents/MuiToggle';
import { useStyles } from './DrugList.styles';

const propTypes = {
  fields: PropTypes.object,
  index: PropTypes.any,
  drugGroup: PropTypes.string,
  name: PropTypes.any
};

export default function DrugList({ fields, index, name, drugGroup }) {
  const classes = useStyles();
  const drugName = Object.keys(fields.value[index])[0];
  const confirmOptions = {
    confirmText: 'Delete',
    cancelText: 'Cancel',
    headerText: 'Remove Medication'
  };

  const onUnselectToggle = async (event, value, input) => {
    const drugsDefaultList = insulins[drugGroup];
    const drugIsManuallyAdded = drugsDefaultList?.find((el) => {
      return Object.keys(el)[0] === drugName;
    });

    if (drugIsManuallyAdded) {
      input.onChange(value);
      return;
    }

    if (!drugIsManuallyAdded) {
      if (value === false && await confirm('Are you sure you want to permanently delete this medication from your profile?', confirmOptions)) {
        fields.remove(index);
        return;
      }

      if (value === true) {
        input.onChange(value);
      }
    }
  };

  return (
    <>
      <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
        <Grid item xs={10}>
          <Text font="bold" fontSize={18} lineHeight={20}>
            {drugName}
          </Text>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <Field
              component={MuiToggle}
              type="checkbox"
              onChange={onUnselectToggle}
              name={`${name}.${drugName}`}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider color="body-light"/>
    </>
  );
}

DrugList.propTypes = propTypes;
