import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

/**
 * This hooks reads url get params and set enabled status to "type"
 * @returns {{
 * typeList: typeList,
 * }}
 */

export default function useTypePresenterHook(type) {
  const { search } = useLocation();
  const { contactTypes } = parse(search, { arrayFormat: 'bracket' });

  const mapTypes = {
    'sharing circle': 'sharing_circle',
    'emergency contacts': 'emergency_contacts',
    practice: 'practices'
  };

  // If it doesn't exist or just a string we just return it
  if (!type || typeof type === 'string') {
    return mapTypes[String(type).toLowerCase()];
  }

  const typeList = type.map(el => {
    if (!contactTypes || !contactTypes.length) {
      return { name: mapTypes[String(el).toLowerCase()], enabled: true };
    }

    return {
      name: mapTypes[String(el).toLowerCase()],
      enabled: contactTypes.includes(mapTypes[String(el).toLowerCase()])
    };
  });

  return typeList;
}
