import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  activeTab: 'recent'
});

export default function activeMealTab(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.UPDATE_ACTIVE_MEAL_TAB:
    return state.set('activeTab', action.payload);
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;
  default:
    return state;
  }
}
