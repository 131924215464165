/* istanbul ignore file */
export default {
  ec: {
    declined_by_ec: {
      title: 'Emergency Contact Removal',
      text: (firstname, secondName) => `You were already removed as an emergency contact for ${firstname} ${secondName}.`,
      buttonText: 'Go to main page'
    },
    pending_relationship: {
      title: 'Emergency Contact Removal',
      text: (firstname, secondName) => `You have declined request for being emergency contact for ${firstname} ${secondName}.`,
      buttonText: 'Go to main page'
    },
    needs_confirmation: {
      title: 'Emergency Contact Removal',
      text: (firstname, secondName) => `You are about to remove yourself from being an emergency contact for ${firstname} ${secondName}.`,
      buttonText: 'Confirm'
    },
    revoked_by_patient: {
      title: 'Emergency Contact Removal',
      text: (firstname, secondName) => `You are no longer an emergency contact for ${firstname} ${secondName}.`,
      buttonText: 'Go to main page'
    },
    successfully_removed: {
      title: 'Emergency Contact Removal',
      text: (firstname, secondName) => `You are no longer an emergency contact for ${firstname} ${secondName}.`,
      buttonText: 'Go to main page'
    }
  }
};
