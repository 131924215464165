import Immutable from 'immutable';

const INITIAL_STATE = {
  cache: new Immutable.Map(),
  isActive: false,
  isActiveLoadTimestamp: false,
  selectedId: null
};

export default class ReadingsRecord extends Immutable.Record(INITIAL_STATE, 'ReadingsRecord') {
}
