/* istanbul ignore file */
import React from 'react';

const BlankAvatar = () => {
  return (
    <svg className="c-avatar__icon" width="22px" viewBox="0 0 18 22">
      <circle cx="9" cy="6" r="6" />
      <path d="M18,19.6568542 C18,16.5326599 13.9705627,14 9,14 C4.02943725,14 0,16.5326599 0,19.6568542 C0,22.7810486 18,22.7810486 18,19.6568542 Z" />
    </svg>
  );
};

export default BlankAvatar;
