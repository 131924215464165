import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  fullName: '',
  patientId: null
});

export default function selectedPatientReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.SET_SELECTED_PATIENT:
    return state.withMutations((data) => {
      data.set('fullName', action.payload.name);
      data.set('patientId', action.payload.id);
      data.set('selectedPatient', action.payload);
    });
  case actionTypes.CLEAR_SELECTED_PATIENT:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;
  default:
    return state;
  }
}
