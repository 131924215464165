/* istanbul ignore file */
import { connect } from 'react-redux';

import HelpContact from '../components/HelpContact';
import { openSnackbar } from '../actions/snackbarActions';
import { postHelpContact } from '../actions/helpContactActions';

const mapStateToProps = state => ({
  isLoggedIn: Boolean(state.authentication.getIn(['headers', 'Authorization']))
});

export default connect(mapStateToProps, {
  openSnackbar,
  postHelpContact
})(HelpContact);
