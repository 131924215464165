import apiService from './apiService';

async function deleteReadingFood(readingId, foodId) {
  const { data } = await apiService.delete(`/readings/${readingId}/foods/${foodId}`);
  return data;
}

async function getReadingFood(readingId, foodId) {
  const { data } = await apiService.get(`/readings/${readingId}/foods/${foodId}`);
  return data;
}

async function postReadingFood(readingId, food) {
  try {
    const { data } = await apiService.post(`/readings/${readingId}/foods`, food);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function putReadingFood(readingId, foodId, foodToPut) {
  try {
    const { data } = await apiService.put(`/readings/${readingId}/foods/${foodId}`, foodToPut);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getReadingFoods(readingId) {
  const { data } = await apiService.get(`/readings/${readingId}/foods`);
  return data;
}

async function getFavoriteFoods() {
  const { data } = await apiService.get('/foods/favorite');
  return data;
}

async function getRecentFoods() {
  const { data } = await apiService.get('/foods/recent');
  return data;
}

async function addFavoriteFood(food) {
  return await apiService.post('/foods/favorite', food);
}

async function removeFavoriteFood(id) {
  return await apiService.delete(`/foods/favorite/${id}`);
}

export default {
  deleteReadingFood,
  getReadingFood,
  postReadingFood,
  putReadingFood,
  getReadingFoods,
  getFavoriteFoods,
  getRecentFoods,
  addFavoriteFood,
  removeFavoriteFood
};
