import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TimeframeRecord from '../records/TimeframeRecord';

const propTypes = {
  timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired
};

export default function withTimeFrame(WrappedComponent) {
  function WithTimeFrameWrapper(props) {
    return (
      <WrappedComponent {...props}/>
    );
  }

  WithTimeFrameWrapper.propTypes = propTypes;

  function makeMapStateToProps() {
    return function (state) {
      return {
        timeframe: state.timeframe
      };
    };
  }

  return connect(makeMapStateToProps)(WithTimeFrameWrapper);
}
