/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SharedWithMeInvitesCountContainer from 'src/containers/SharedWithMeInvitesCountContainer';
import ContactsTypesFilter from 'src/components/contacts/ContactsTypesFilter/ContactsTypesFilter';
import SearchContacts from 'src/components/contacts/SearchContacts';
import ContactsSharingWithMeTable from 'src/pages/contacts/children/sharingWithMe/ContactsSharingWithMeTable';
import useFetchDataHook from 'src/hooks/useFetchDataHook';
import Loader from 'src/components/shared/Loader';
import { Container } from '@material-ui/core';
import { useStyles } from './ContactsSharingWithMe.styles';

const propTypes = {
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number,
  clearSelectedPatient: PropTypes.func.isRequired,
  getSharedWithMeContacts: PropTypes.func.isRequired
};

function ContactsSharingWithMe(props) {
  const { getSharedWithMeContacts, itemsListCount, itemsList } = props;
  const classes = useStyles();
  const { memoizedFetch, loading } = useFetchDataHook(getSharedWithMeContacts);

  useEffect(() => {
    props.clearSelectedPatient({});
  }, []);

  return (
    <Container style={{ padding: '0' }}>
      <section>
        <SharedWithMeInvitesCountContainer />
      </section>

      <h2 className={classes.title}>People sharing with me</h2>

      <div className={classes.block}>
        <ContactsTypesFilter skipType={['practices']} />
        <SearchContacts/>
      </div>

      <ContactsSharingWithMeTable itemsList={itemsList} itemsListCount={itemsListCount} memoizedFetch={memoizedFetch}/>

      <Loader isEnabled={loading}/>
    </Container>
  );
}

ContactsSharingWithMe.propTypes = propTypes;
export default ContactsSharingWithMe;
