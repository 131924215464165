/* istanbul ignore file */
import { connect } from 'react-redux';
import { getAvatar } from '../actions/avatarActions';
import SharingCircleHeader from '../components/sharingCircle/SharingCircleHeader';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const mapStateToProps = (state) => {
  return {
    avatar: state.avatar.get('image')
  };
};

export default connect(mapStateToProps, {
  getAvatar
})(immutableToJsHOC(SharingCircleHeader));
