import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import TimeframeRecord from 'src/records/TimeframeRecord';
import { getRangeOfPGHD } from 'src/actions/healthDataActions';
import { getMarketplace } from 'src/actions/validicActions';
import { getRangeOfReadingTags } from 'src/actions/readingTagsActions';
import { makeEndDateForApi, makeStartDateForApi } from 'src/utils/dateFormatter';

export default function withHealthData(WrappedComponent) {
  const propTypes = {
    getMarketplace: PropTypes.func.isRequired,
    getRangeOfPGHD: PropTypes.func.isRequired,
    getRangeOfReadingTags: PropTypes.func.isRequired,
    timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired
  };

  function HealthDataWrapper(props) {
    const startFrame = props.timeframe.get('start');
    const endFrame = props.timeframe.get('end');

    const getRanges = useCallback(() => {
      const start = makeStartDateForApi(startFrame);
      const end = makeEndDateForApi(endFrame);
      props.getRangeOfPGHD(start, end);
      props.getRangeOfReadingTags(start, end);
    }, [startFrame, endFrame]);

    useEffect(() => {
      props.getMarketplace();
    }, []);

    useEffect(() => {
      getRanges();
    }, [startFrame, endFrame]);

    return (
      <WrappedComponent {...props} />
    );
  }

  HealthDataWrapper.propTypes = propTypes;

  return connect(null, {
    getRangeOfReadingTags,
    getMarketplace,
    getRangeOfPGHD
  })(HealthDataWrapper);
}
