import PropTypes from 'prop-types';
import React from 'react';
import StepsIcon from '../components/icons/StepsIcon';
import BarChartSVG from '../components/svg/BarChartSVG';
import { connect } from 'react-redux';
import { setDevicePerDataType } from '../actions/validicActions';
import HealthDeviceWidget from '../components/HealthDeviceWidget';
import {
  getSummariesCacheDeviceList,
  makeGetStepsDevice,
  makeGroupedStepsFromSummariesInTimeframe
} from '../selectors/healthDataSelectors';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  stepTotals: PropTypes.object,
  stepsDevice: PropTypes.object,
  isSharingCircleView: PropTypes.bool,
  setDevicePerDataType: PropTypes.func.isRequired,
  stepsDevices: PropTypes.array
};

function WidgetStepsContainer({
  stepTotals,
  stepsDevice,
  isSharingCircleView,
  setDevicePerDataType: switchDevice,
  stepsDevices
}) {
  return (
    <HealthDeviceWidget
      Chart={BarChartSVG}
      data={stepTotals}
      dataCollectionType="steps"
      dataType="steps"
      device={stepsDevice}
      Icon={StepsIcon}
      isSharingCircleView={isSharingCircleView}
      sources={stepsDevices}
      switchDevice={switchDevice}
      title="AVG Steps"
    />
  );
}

export function makeMapStateToProps() {
  const getGroupedStepsFromSummaries = makeGroupedStepsFromSummariesInTimeframe();
  const getStepsDevice = makeGetStepsDevice();

  return function (state, props) {
    const stepsDevice = getStepsDevice(state, props);
    const stepTotals = getGroupedStepsFromSummaries(state, props);
    const stepsDevices = getSummariesCacheDeviceList(state);

    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      stepsDevices,
      stepsDevice,
      stepTotals
    };
  };
}

export default connect(makeMapStateToProps, {
  setDevicePerDataType
})(immutableToJsHOC(WidgetStepsContainer));

WidgetStepsContainer.propTypes = propTypes;
