import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@material-ui/core';
import { useInboxActionsContext } from 'src/pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import { contactsViewsConstants } from 'src/constants/contactsInviteViews';
import { makeNameWithSalutation } from 'src/utils/stringGeneratorHelpers';
import { useStyles } from './SearchedHcp.styles';

const propTypes = {
  hcp: PropTypes.shape({
    id: PropTypes.number,
    salutation: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
};

const SearchedHcp = ({ hcp }) => {
  const classes = useStyles();
  const { switchToView } = useInboxActionsContext();

  const viewInfo = (hcpId) => switchToView(contactsViewsConstants.hcpInfoView, { id: hcpId });

  return (
    <Grid
      container
      justifyContent="space-between"
      data-testid="item"
      style={{ padding: '0 0 0.7rem 0' }}
    >
      <Grid item xs={4} sm={6}>
        <Grid container justifyContent="flex-start" direction="column">
          <label className={classes.name}>
            <strong>
              {makeNameWithSalutation(hcp.salutation, hcp.firstName, hcp.lastName)}
            </strong>
          </label>
        </Grid>
      </Grid>

      <Grid item xs={8} sm={6}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
          className={classes.buttonGroup}
        >
          <button
            data-testid="viewInfo"
            className="c-button c-button--condensed c-button--hollow"
            onClick={() => viewInfo(hcp.id)}
          >
            View Info
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchedHcp.propTypes = propTypes;
export default SearchedHcp;
