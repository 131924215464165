export function mediaQuery(width, max = 1215) {
  return width < max ? width : max;
}

export function setLeftRightSpacing(spacing, width) {
  let leftRightSpacing = spacing.sm;

  if (width > 700 && width < 980) {
    leftRightSpacing = spacing.md;
  } else if (width >= 980) {
    leftRightSpacing = spacing.xl;
  }

  return leftRightSpacing;
}
