import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const labels = [
  { prefix: 'pre_', value: '', label: 'pre-meal' },
  { prefix: 'post_', value: '', label: 'post-meal' },
  { prefix: '', value: 'fasting', label: 'fasting' },
  { prefix: '', value: 'snack', label: 'snack' }
];

export default class MealTagsButtons extends React.Component {
  static propTypes = {
    handleValue: PropTypes.func.isRequired,
    handlePrefix: PropTypes.func.isRequired,
    value: PropTypes.string,
    prefix: PropTypes.string
  };

  constructor(props) {
    super(props);

    const index = this.returnIndex(labels, this.props.prefix, this.props.value);
    this.state = {
      index
    };
  }

  returnIndex = (labelsArray, prefix, value) => {
    let index = -1;
    if (prefix) {
      index = labelsArray.findIndex(el => el.prefix === this.props.prefix);
    } else if (value) {
      index = labelsArray.findIndex(el => el.value === this.props.value);
    }

    return index;
  }

  handleSelect = (el, index) => () => {
    const shouldTriggerValueChange = !(el.prefix && ['breakfast', 'lunch', 'dinner'].indexOf(this.props.value) !== -1);

    if (shouldTriggerValueChange) {
      this.props.handleValue(el.value);
    }

    this.props.handlePrefix(el.prefix);
    this.setState({ index });
  };

  returnClassnames = (index) => {
    return classNames(
      'c-button c-button--condensed h-capitalize',
      { 'c-button--active': this.state.index === index },
      { 'c-button--hollow': this.state.index !== index }
    );
  };

  render() {
    return (
      <div className="c-button-group c-button-group--full-width h-margin-bottom-lg">
        {labels.map((el, index) => {
          return (
            <button
              key={index}
              data-testid={`${el.prefix}${el.label}`}
              className={this.returnClassnames(index)}
              onClick={this.handleSelect(el, index)}
            >
              {el.label}
            </button>
          );
        })}
      </div>
    );
  }
}
