import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import ShapeAfter from './ShapeAfter';
import ShapeBefore from './ShapeBefore';
import ShapeFasting from './ShapeFasting';
import ShapeNoTag from './ShapeNoTag';
import ShapeSnack from './ShapeSnack';

export default class DashboardGraphData extends Component {
  static propTypes = {
    innerHeight: PropTypes.number.isRequired,
    innerWidth: PropTypes.number.isRequired,
    isReport: PropTypes.bool,
    leftRightSpacing: PropTypes.number.isRequired,
    readingVals: PropTypes.array.isRequired,
    selectedReading: PropTypes.object,
    setSelectedReadingId: PropTypes.func,
    sv: PropTypes.object,
    width: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    xScale: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isDown: false,
      isMoving: false,
      downPositionX: 0,
      moveDeltaX: 0
    };

    this.handleEmptySelectedReading = this.handleEmptySelectedReading.bind(this);
    this.onDown = this.onDown.bind(this);
    this.onUp = this.onUp.bind(this);
    this.onMove = this.onMove.bind(this);
    this.setPosition = this.setPosition.bind(this);
  }

  componentDidMount() {
    // Mouse Events
    document.addEventListener('mousemove', this.onMove);
    document.addEventListener('mouseup', this.onUp);
    // Touch Events
    document.addEventListener('touchmove', this.onMove);
    document.addEventListener('touchend', this.onUp);

    this.handleEmptySelectedReading();
  }

  componentDidUpdate() {
    this.handleEmptySelectedReading();
  }

  componentWillUnmount() {
    // Mouse Events
    document.removeEventListener('mousemove', this.onMove);
    document.removeEventListener('mouseup', this.onUp);
    // Touch Events
    document.removeEventListener('touchmove', this.onMove);
    document.removeEventListener('touchend', this.onUp);
  }

  onDown(e) {
    const pt = (e.changedTouches && e.changedTouches[0]) || e;
    const scaledPt = pt.clientX - ((this.props.windowWidth - pt.target.getBoundingClientRect().width) / 2);
    this.setPosition(pt.clientX);
    const selected = this.props.readingVals
      .reduce((acc, val) => {
        if (!acc) { return val; }
        const a = this.props.xScale(moment(acc.timestamp));
        const v = this.props.xScale(moment(val.timestamp));
        return Math.abs(a - scaledPt) > Math.abs(v - scaledPt) ? val : acc;
      }, null);
    // Update
    this.props.setSelectedReadingId(selected.id);
  }

  onUp() {
    if (this.state.isDown) {
      this.setState({
        isDown: false,
        isMoving: false
      });
    }
  }

  onMove(e) {
    if (this.state.isDown) {
      const pt = (e.changedTouches && e.changedTouches[0]) || e;
      const moveDeltaX = pt.clientX - this.state.downPositionX;

      this.setState({
        isMoving: true,
        moveDeltaX
      });
    }
  }

  setPosition(x) {
    this.setState({
      downPositionX: x,
      isDown: true,
      isMoving: false,
      moveDeltaX: 0
    });
  }

  handleEmptySelectedReading() {
    if (!this.props.selectedReading && this.props.readingVals.length) {
      const selected = this.props.readingVals
        .reduce((acc, val) => {
          if (!acc) {
            return val;
          }
          return acc.timestamp > val.timestamp ? acc : val;
        }, null);
      this.props.setSelectedReadingId(selected.id);
    }
  }

  render() {
    return (
      <g>
        {!this.props.isReport &&
          <rect
            id="dashboard-graph-hit-area"
            fill="rgba(255, 255, 255, 0)"
            x={0}
            y={3}
            width={this.props.innerWidth}
            height={this.props.innerHeight}
            onMouseDown={this.onDown}
            onTouchStart={this.onDown}
          />}
        <g id="circles" >
          {this.props.readingVals.map((val, index) => {
            let useFill = false;

            switch (val.mealMarker) {
            case 'AFTER':
              val.shape = ShapeAfter;
              break;
            case 'BEFORE':
              val.shape = ShapeBefore;
              break;
            case 'FASTING':
              useFill = true;
              val.shape = ShapeFasting;
              break;
            case 'SNACK':
              val.shape = ShapeSnack;
              break;
            default:
              val.shape = ShapeNoTag;
            }

            return (
              <val.shape
                key={val.id || index}
                fill={!useFill ? val.color : 'none'}
                color={useFill ? val.color : ''}
                transform={`translate(${this.props.xScale(val.x) - 12}, ${this.props.yScale(val.y) - 12})`}
                onClick={() => this.props.setSelectedReadingId(val.id)}
              />
            );
          })}
        </g>
      </g>
    );
  }
}
