/* istanbul ignore file */
import { connect } from 'react-redux';

// Actions
import { postReadingTag, putReadingTag, deleteReadingTag } from '../actions/readingTagsActions';
// Components
import EditTimingTag from '../components/logs/EditTimingTag';
import { immutableToJsHOC } from './ImmutableToJsHOC';

function makeMapStateToProps() {
  return function (state) {
    const selectedId = state.readings.get('selectedId');

    return {
      selectedReading: selectedId ? state.readings.getIn(['cache', selectedId]) : null
    };
  };
}

export default connect(makeMapStateToProps, {
  postReadingTag,
  putReadingTag,
  deleteReadingTag
})(immutableToJsHOC(EditTimingTag));
