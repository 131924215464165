/* istanbul ignore file */
if (typeof window.TextEncoder === 'undefined') {
  window.TextEncoder = function TextEncoder() {}; // eslint-disable-line

  TextEncoder.prototype.encode = function(s) { // eslint-disable-line
    const e = new Uint8Array(s.length);

    for (let i = 0; i < s.length; i += 1) {
      e[i] = s.charCodeAt(i);
    }

    return e;
  };
}

if (typeof window.TextDecoder === 'undefined') {
  window.TextDecoder = function TextDecoder() {}; // eslint-disable-line

  TextDecoder.prototype.decode = function(arr) { // eslint-disable-line
    let d = '';

    for (let i = 0; i < arr.length; i += 1) {
      d += String.fromCharCode(arr[i]);
    }

    return d;
  };
}
