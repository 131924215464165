import PropTypes from 'prop-types';
import React from 'react';
// Colors
import { sv }  from '../../assets/css/1_settings/variables.js';
// Component
import MealTimingGroup from '../svg/MealTimingGroup';

const propTypes = {
  className: PropTypes.string,
  mealTag: PropTypes.string
};

function MealIconChooser({ className, mealTag }) {
  // Color
  let color = '#525252';
  if (className && className.includes('alt')) { color = sv.color.primaryColor; }
  if (className && className.includes('white')) { color = '#fff'; }
  if (className && className.includes('action')) { color = sv.color.active; }

  // Pre & Post Meal
  return (
    <svg className="c-icon c-icon--wide" viewBox="0 0 36 24">
      <MealTimingGroup color={color} mealTag={mealTag} />
    </svg>
  );
}

MealIconChooser.propTypes = propTypes;

export default MealIconChooser;
