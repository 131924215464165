import { connect } from 'react-redux';
import { deleteSentInvitation } from '../actions/contactsActions';
import { acceptReceivedInvitation } from '../actions/contactsSharedWithMeInvitesActions';
import ContactsSharedWithMeReceivedInvitesTableButtons from '../pages/contacts/children/sharedWithMeReceivedInvites/ContactsSharedWithMeReceivedInvitesTable/ContactsSharedWithMeReceivedInvitesTableButtons';
import { immutableToJsHOC } from './ImmutableToJsHOC';

export default connect(null, {
  acceptReceivedInvitation,
  deleteSentInvitation
})(immutableToJsHOC(ContactsSharedWithMeReceivedInvitesTableButtons));
