import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function SleepsIcon({ className }) {
  let classes = 'c-icon c-icon--sleeps';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <title>Sleep</title>
      <path className="c-icon__fill c-icon__fill--alt" d="M10.0135 11.792L14.2515 11.792C15.0385 11.792 15.6825 12.437 15.6825 13.225L17.8535 13.225 17.8535 9.808C17.8535 9.657 17.7855 9.516 17.6675 9.422 16.1435 8.22 14.2225 7.5 12.1325 7.5 10.0415 7.5 8.1205 8.22 6.5965 9.422 6.4795 9.515 6.4095 9.657 6.4095 9.808L6.4095 13.225 8.5805 13.225C8.5805 12.437 9.2255 11.792 10.0135 11.792M17.8314 14.6499L6.4324 14.6499C5.6444 14.6499 4.9994 15.2949 4.9994 16.0819L4.9994 22.8849C4.9994 23.0159 5.1074 23.1239 5.2384 23.1239L6.9104 23.1239C7.0414 23.1239 7.1484 23.0159 7.1484 22.8849L7.1484 21.7989 17.1154 21.7989 17.1154 22.8849C17.1154 23.0159 17.2234 23.1239 17.3544 23.1239L19.0254 23.1239C19.1564 23.1239 19.2644 23.0159 19.2644 22.8849L19.2644 16.0819C19.2644 15.2949 18.6194 14.6499 17.8314 14.6499" />
      <path className="c-icon__fill c-icon__fill--blue" d="M9.5746 6.4507L11.3796 6.4507C11.4186 6.4507 11.4506 6.4187 11.4506 6.3797L11.4506 5.9377C11.4506 5.8987 11.4186 5.8667 11.3796 5.8667L10.4346 5.8667C10.3956 5.8667 10.3816 5.8397 10.4026 5.8067L11.4036 4.2947C11.4256 4.2617 11.4426 4.2027 11.4426 4.1627L11.4426 3.8837C11.4426 3.8447 11.4106 3.8127 11.3716 3.8127L9.6796 3.8127C9.6406 3.8127 9.6086 3.8447 9.6086 3.8837L9.6086 4.3257C9.6086 4.3647 9.6406 4.3967 9.6796 4.3967L10.5116 4.3967C10.5506 4.3967 10.5646 4.4237 10.5426 4.4567L9.5426 5.9697C9.5206 6.0017 9.5026 6.0607 9.5026 6.0997L9.5026 6.3797C9.5026 6.4187 9.5346 6.4507 9.5746 6.4507M12.5926 5.5278L15.7926 5.5278C15.8316 5.5278 15.8636 5.4958 15.8636 5.4558L15.8636 4.5958C15.8636 4.5568 15.8316 4.5248 15.7926 4.5248L14.0696 4.5248C14.0296 4.5248 14.0146 4.4978 14.0376 4.4648L15.8106 1.7848C15.8326 1.7518 15.8496 1.6928 15.8496 1.6528L15.8496 1.0718C15.8496 1.0318 15.8186.9998 15.7786.9998L12.7736.9998C12.7346.9998 12.7016 1.0318 12.7016 1.0718L12.7016 1.9308C12.7016 1.9708 12.7346 2.0028 12.7736 2.0028L14.3016 2.0028C14.3416 2.0028 14.3556 2.0288 14.3346 2.0628L12.5606 4.7438C12.5386 4.7768 12.5206 4.8358 12.5206 4.8748L12.5206 5.4558C12.5206 5.4958 12.5526 5.5278 12.5926 5.5278" />
    </svg>
  );
}

SleepsIcon.propTypes = propTypes;

export default SleepsIcon;
