/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import MHealthList from '../components/MHealthList';
import { MHEALTH_LABELS, MHEALTH_TYPES } from '../constants/mHealthConstants';
import {
  caloriesHeaders,
  carbsHeaders,
  hydrationHeaders,
  mapCaloriesBurnedData,
  mapCaloriesConsumedData,
  mapCarbsData,
  mapHydrationData,
  mapSleepData,
  mapStepsData,
  mapWeightData,
  sleepHeaders,
  stepsHeaders,
  weightHeaders
} from '../utils/mHealthHelpers.js';
import {
  makeGetCaloriesBurnedDevice,
  makeGetCarbsDevice,
  makeGetFoodsDevice,
  makeGetSleepsDevice,
  makeGetStepsDevice,
  makeGetWaterDevice,
  makeGetWeightsDevice,
  makeGroupedCaloriesBurnedFromSummariesInTimeframe,
  makeGroupedCaloriesConsumedInTimeframe,
  makeGroupedCarbsConsumedInTimeframe,
  makeGroupedSleepsInTimeframe,
  makeGroupedStepsFromSummariesInTimeframe,
  makeGroupedWeightsInTimeframeForMhealthPage
} from '../selectors/healthDataSelectors';
import { makeGetWaterInTimeframe } from '../selectors/readingTagsSelectors';

const propTypes = {
  data: PropTypes.array.isRequired,
  device: PropTypes.string,
  headers: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
};

/* istanbul ignore next */
function MHealthContainer(props) {
  return <MHealthList {...props} />;
}

export const makeMapStateToProps = () => {
  const getGroupedCaloriesBurnedFromSummaries = makeGroupedCaloriesBurnedFromSummariesInTimeframe();
  const getGroupedCaloriesConsumed = makeGroupedCaloriesConsumedInTimeframe();
  const getGroupedSleeps = makeGroupedSleepsInTimeframe();
  const getGroupedStepsFromSummaries = makeGroupedStepsFromSummariesInTimeframe();
  const getGroupedWeights = makeGroupedWeightsInTimeframeForMhealthPage();
  const getCarbsInTimeframe = makeGroupedCarbsConsumedInTimeframe();
  // selected device per widget
  const getCaloriesBurnedDevice = makeGetCaloriesBurnedDevice();
  const getFoodsDevice = makeGetFoodsDevice();
  const getSleepsDevice = makeGetSleepsDevice();
  const getStepsDevice = makeGetStepsDevice();
  const getWeightsDevice = makeGetWeightsDevice();
  const getWaterDevice = makeGetWaterDevice();
  const getCarbsDevice = makeGetCarbsDevice();
  // tags
  const getWaterInTimeframe = makeGetWaterInTimeframe();

  return (state, props) => {
    const caloriesBurnedDevice = getCaloriesBurnedDevice(state, props);
    const caloriesBurnedTotals = getGroupedCaloriesBurnedFromSummaries(state, props);
    const caloriesConsumedDevice = getFoodsDevice(state, props);
    const caloriesConsumedTotals = getGroupedCaloriesConsumed(state, props);
    const carbsDevice = getCarbsDevice(state, props);
    const carbsTotals = getCarbsInTimeframe(state, props);
    const sleepsDevice = getSleepsDevice(state, props);
    const sleepTotals = getGroupedSleeps(state, props);
    const stepsDevice = getStepsDevice(state, props);
    const stepTotals = getGroupedStepsFromSummaries(state, props);
    const waterDevice = getWaterDevice(state, props);
    const waterTotals = getWaterInTimeframe(state, props);
    const weightsDevice = getWeightsDevice(state, props);
    const weightTotals = getGroupedWeights(state, props);

    const type = props.match.params.type;

    const mHealthProps = {
      data: [],
      headers: [],
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      title: MHEALTH_LABELS[type],
      type
    };

    switch (type) {
    case MHEALTH_TYPES.STEPS:
      // eslint-disable-next-line no-case-declarations
      const stepsDeviceJS = stepsDevice && stepsDevice.toJS() || {};
      // eslint-disable-next-line no-case-declarations
      const stepTotalsJS = stepTotals && stepTotals.toJS();
      mHealthProps.device = stepsDeviceJS.type;
      mHealthProps.data = mapStepsData(stepTotalsJS, mHealthProps.device);
      mHealthProps.headers = stepsHeaders;
      break;
    case MHEALTH_TYPES.SLEEP:
      // eslint-disable-next-line no-case-declarations
      const sleepsDeviceJS = sleepsDevice && sleepsDevice.toJS() || {};
      // eslint-disable-next-line no-case-declarations
      const sleepTotalsJS = sleepTotals && sleepTotals.toJS();
      mHealthProps.device = sleepsDeviceJS.type;
      mHealthProps.data = mapSleepData(sleepTotalsJS, mHealthProps.device);
      mHealthProps.headers = sleepHeaders;
      break;
    case MHEALTH_TYPES.WEIGHT:
      // eslint-disable-next-line no-case-declarations
      const weightsDeviceJS = weightsDevice && weightsDevice.toJS() || {};
      // eslint-disable-next-line no-case-declarations
      const weightTotalsJS = weightTotals && weightTotals.toJS();
      mHealthProps.device = weightsDeviceJS.type;
      mHealthProps.data = mapWeightData(weightTotalsJS, mHealthProps.device);
      mHealthProps.headers = weightHeaders;
      break;
    case MHEALTH_TYPES.CALORIES_BURNED:
      // eslint-disable-next-line no-case-declarations
      const caloriesBurnedDeviceJS = caloriesBurnedDevice && caloriesBurnedDevice.toJS() || {};
      // eslint-disable-next-line no-case-declarations
      const caloriesBurnedTotalsJS = caloriesBurnedTotals && caloriesBurnedTotals.toJS();
      mHealthProps.device = caloriesBurnedDeviceJS.type;
      mHealthProps.data = mapCaloriesBurnedData(caloriesBurnedTotalsJS, mHealthProps.device);
      mHealthProps.headers = caloriesHeaders;
      break;
    case MHEALTH_TYPES.HYDRATION:
      // eslint-disable-next-line no-case-declarations
      const waterDeviceJS = waterDevice && waterDevice.toJS() || {};
      // eslint-disable-next-line no-case-declarations
      const waterTotalsJS = waterTotals && waterTotals.toJS();
      mHealthProps.device = waterDeviceJS.type;
      mHealthProps.data = mapHydrationData(waterTotalsJS, mHealthProps.device);
      mHealthProps.headers = hydrationHeaders;
      break;
    case MHEALTH_TYPES.CARBS:
      // eslint-disable-next-line no-case-declarations
      const carbsDeviceJS = carbsDevice && carbsDevice.toJS() || {};
      // eslint-disable-next-line no-case-declarations
      const carbsTotalsJS = carbsTotals && carbsTotals.toJS();
      mHealthProps.device = carbsDeviceJS.type;
      mHealthProps.data = mapCarbsData(carbsTotalsJS, mHealthProps.device);
      mHealthProps.headers = carbsHeaders;
      break;
    case MHEALTH_TYPES.CALORIES_CONSUMED:
      // eslint-disable-next-line no-case-declarations
      const caloriesConsumedDeviceJS = caloriesConsumedDevice && caloriesConsumedDevice.toJS() || {};
      // eslint-disable-next-line no-case-declarations
      const caloriesConsumedTotalsJS = caloriesConsumedTotals && caloriesConsumedTotals.toJS();
      mHealthProps.device = caloriesConsumedDeviceJS.type;
      mHealthProps.data = mapCaloriesConsumedData(caloriesConsumedTotalsJS, mHealthProps.device);
      mHealthProps.headers = caloriesHeaders;
      break;
    default:
      break;
    }

    if (mHealthProps.data.length === 0) {
      props.history.replace('/dashboard');
    }

    return mHealthProps;
  };
};

MHealthContainer.propTypes = propTypes;

export default connect(makeMapStateToProps, null)(MHealthContainer);
