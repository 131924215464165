export function trimSpaces(values) {
  const firstName = values.firstName ? String(values.firstName).trim() : '';
  const lastName = values.lastName ? String(values.lastName).trim() : '';

  let trimedVals = {};

  if (firstName) {
    trimedVals = { ...trimedVals, firstName };
  }

  if (lastName) {
    trimedVals = { ...trimedVals, lastName };
  }

  return {
    ...values,
    ...trimedVals
  };
}
