// TODO: Figure out how to do something like this instead
// const variables = require('./variables.css'),

const sv = {
  color: {
    active: '#2da9df',
    activeHover: '#2a9cce',
    activeDisabled: '#93d2ed',
    blueGreyOverlay: '#f3fcff',
    primaryColor: '#282829',
    textSecondary: '#5d5d5d',
    textLight: '#b7b7b7',
    backgroundDark: '#5d5d5d',
    backgroundLight: '#f9f9f9',
    backgroundActiveLight: '#f3fCff',
    green: '#8fd600',
    red: '#e74113',
    yellow: '#fdb62c'
  },
  helveticaBold: '"HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif',
  helveticaRegular: '"HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif',
  fontSize: {
    xxl: 16 * 1.875,
    xl: 16 * 1.25,
    lg: 16,
    md: 16 * 0.875,
    sm: 16 * 0.75,
    xs: 16 * 0.625
  },
  fontWeight: {
    bold: 700,
    normal: 400,
    light: 200
  },
  spacing: {
    xxl: 16 * 6,
    xl: 16 * 4,
    lg: 16 * 2,
    md: 16,
    sm: 16 / 2,
    xs: 16 / 4
  },
  theme: {
    danger: 'red',
    goal: 'green',
    warning: 'yellow'
  }
};

const bold = {
  fill: sv.color.primaryColor,
  fontFamily: sv.helveticaBold,
  fontSize: sv.fontSize.lg,
  fontWeight: 700
};

const p = {
  fill: sv.color.primaryColor,
  fontFamily: sv.helveticaRegular,
  fontSize: sv.fontSize.lg
};

const subHeading = {
  ...bold,
  fontSize: sv.fontSize.sm
};

const xAxisLabel = {
  fill: sv.color.textSecondary,
  fontFamily: sv.helveticaRegular,
  fontSize: sv.fontSize.md
};

const yAxisLabel = {
  fill: sv.color.primaryColor,
  fontFamily: sv.helveticaBold,
  fontSize: sv.fontSize.lg,
  fontWeight: 700
};

module.exports = {
  bold,
  p,
  subHeading,
  sv,
  xAxisLabel,
  yAxisLabel
};
