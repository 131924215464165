import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { useInboxActionsContext } from '../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import { contactsViewsConstants } from '../../../constants/contactsInviteViews';
import { useStyles } from './SearchedPractice.styles';

const propTypes = {
  invitePractice: PropTypes.func.isRequired,
  practice: PropTypes.shape({
    address: PropTypes.string,
    cityVillage: PropTypes.string,
    country: PropTypes.string,
    emailAddress: PropTypes.string,
    invitationStatus: PropTypes.string,
    name: PropTypes.string,
    phoneNumbers: PropTypes.array,
    placeId: PropTypes.string,
    postalCode: PropTypes.string,
    practiceId: PropTypes.number,
    stateProvince: PropTypes.string
  })
};

const SearchedPractice = ({ practice, invitePractice }) => {
  const classes = useStyles();
  const { switchToView } = useInboxActionsContext();

  const sendInvite = async (practiceId) => {
    await invitePractice(practiceId);
    switchToView(contactsViewsConstants.successInviteView);
  };

  const viewInfo = (practiceId) => {
    switchToView(contactsViewsConstants.practiceInfoView, { id: practiceId });
  };

  const makeButtonTxt = (invitationStatus) => {
    switch (invitationStatus) {
    case 'connected':
      return 'Connected';
    case 'invite_sent':
      return 'Sent';
    default:
      return 'Invite';
    }
  };

  return (
    <Fragment data-testid="results">
      <Grid
        container
        justifyContent="space-between"
        data-testid="item"
        style={{ padding: '0 0 0.7rem 0' }}
      >
        <Grid item xs={4} sm={6}>
          <Grid container justifyContent="flex-start" direction="column">
            <label className={classes.name}><strong>{practice.name}</strong></label>
          </Grid>
        </Grid>

        <Grid item xs={8} sm={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            className={classes.buttonGroup}
          >
            <button
              disabled={practice.invitationStatus !== 'invite_available'}
              className="c-button c-button--condensed c-button--action jest-invite"
              onClick={() => sendInvite(practice.practiceId)}
            >
              {makeButtonTxt(practice.invitationStatus)}
            </button>
            <button
              data-testid="viewInfo"
              className="c-button c-button--condensed c-button--hollow jest-view"
              onClick={() => viewInfo(practice.practiceId)}
            >
              View Info
            </button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

SearchedPractice.propTypes = propTypes;
export default SearchedPractice;
