import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isSearching: false,
  searchQuery: '',
  type: 'practice',
  hcpSearchResults: new Immutable.Map({
    hcps: new Immutable.List(),
    hcpsCount: 0
  }),
  practiceSearchResults: new Immutable.Map({
    medicalPractices: new Immutable.List(),
    medicalPracticesCount: 0
  }),
  hcpInfo: new Immutable.Map(),
  practiceInfo: new Immutable.Map()
});

export default function contactsInvitesReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.SEARCH_HEALTHCARE_PROVIDERS_SUCCESS:
    return state.withMutations((map) => {
      map.set('hcpSearchResults', Immutable.fromJS(action.payload));
      map.set('isSearching', false);
    });

  case actionTypes.SEARCH_PRACTICES_SUCCESS:
    return state.withMutations((map) => {
      map.set('practiceSearchResults', Immutable.fromJS(action.payload));
      map.set('isSearching', false);
    });

  case actionTypes.GET_HCP_LIST_IN_PRACTICE_SUCCESS:
    return state.withMutations((map) => {
      map.set('practiceInfo', Immutable.fromJS(action.payload));
      map.set('isSearching', false);
    });

  case actionTypes.SEARCH_PRACTICES_START:
  case actionTypes.GET_HCP_LIST_IN_PRACTICE_START:
  case actionTypes.SEARCH_HEALTHCARE_PROVIDERS_START:
  case actionTypes.GET_HCP_INFO_START:
    return state.set('isSearching', true);

  case actionTypes.SEARCH_HEALTHCARE_PROVIDERS_FAILURE:
  case actionTypes.GET_HCP_LIST_IN_PRACTICE_FAILURE:
  case actionTypes.SEARCH_PRACTICES_FAILURE:
  case actionTypes.GET_HCP_INFO_FAILURE:
    return state.set('isSearching', false);

  case actionTypes.STORE_INVITES_SEARCH_QUERY:
    return state.set('searchQuery', action.payload);

  case actionTypes.STORE_INVITES_TYPE:
    return state.set('type', action.payload);

  case actionTypes.GET_HCP_INFO_SUCCESS:
    return state.withMutations((map) => {
      map.set('hcpInfo', Immutable.fromJS(action.payload));
      map.set('isSearching', false);
    });

  case actionTypes.CLEAR_INVITES_SEARCH_RESULTS:
    return state.withMutations((map) => {
      map.set('practiceSearchResults', Immutable.fromJS(action.payload));
      map.set('hcpSearchResults', Immutable.fromJS(action.payload));
      map.set('isSearching', false);
    });

  case actionTypes.RESET_INVITES_FORM:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
