import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { setToken } from 'src/actions/tokenActions';
import { renewConnection } from 'src/actions/invitationActions';
import { getPhoneNumberVerified } from 'src/actions/verificationActions';
import { openSnackbar } from 'src/actions/snackbarActions';
import authenticationService from 'src/services/authenticationService';
import TOKEN_TYPES from 'src/constants/tokenTypes';
import { Grid } from '@material-ui/core';
import Text from 'src/shared/Text/Text';
import TokenPhoneVerification from 'src/components/TokenPhoneVerification';
import TokenViewWrapper from 'src/components/TokenViewWrapper';
import EmergencyContactAcceptDecline from 'src/components/EmergencyContactAcceptDecline/EmergencyContactAcceptDecline';
import SharingCircleAcceptDecline from 'src/components/SharingCircleAcceptDecline/SharingCircleAcceptDecline';
import { useStyles } from './TokenHandler.styles';

const propTypes = {
  getPhoneNumberVerified: PropTypes.func.isRequired,
  renewConnection: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired
};

export function TokenHandler(props) {
  const location = useLocation();
  const history = useHistory();
  const { token } = useParams();
  const [message, setMessage] = useState('Verifying...');

  const tokenType = token.slice(0, 3);
  const urlParams = new URLSearchParams(location.search);
  const endpointPrefix = urlParams.get('endpointPrefix');
  const classes = useStyles();

  const handleView = async () => {
    props.setToken(tokenType, token);

    switch (tokenType) {
    case TOKEN_TYPES.RESET_PASSWORD: // reset password
      history.push(`/welcome/reset-password/${token}`);
      break;
    case TOKEN_TYPES.VIEW_REPORT: // view report // less urgent
      history.replace(`${authenticationService.AUTH_URL}/report/${token}`);
      break;
    case TOKEN_TYPES.ADD_METER: // add meter
      history.push('/welcome/register');
      break;
    case TOKEN_TYPES.RENEW_RELATIONSHIP:
      try {
        await props.renewConnection(token);
        setMessage('Your Sharing Circle relationship has been renewed.');
      } catch (error) {
        history.push('/welcome/sign-in');
      }
      break;
    case TOKEN_TYPES.EMERGENCY_CONTACT: // emergency contact accept & decline
    case TOKEN_TYPES.SHARING_CIRCLE_CONTACT:
    case TOKEN_TYPES.PHONE_VERIFICATION:
      break;
    default:
      history.push('/welcome/sign-in');
    }
  };

  useEffect(() => {
    handleView();
  }, []);

  if (tokenType === TOKEN_TYPES.EMERGENCY_CONTACT) {
    return (
      <EmergencyContactAcceptDecline />
    );
  }

  if (tokenType === TOKEN_TYPES.SHARING_CIRCLE_CONTACT) {
    return (
      <SharingCircleAcceptDecline />
    );
  }

  if (tokenType === TOKEN_TYPES.PHONE_VERIFICATION) {
    return (
      <TokenPhoneVerification
        getPhoneNumberVerified={props.getPhoneNumberVerified}
        token={token}
        endpointPrefix={endpointPrefix}
      />
    );
  }

  return (
    <TokenViewWrapper>
      <Grid item xs={12}>
        <Text fontSize={16} lineHeight={22} alignText="center">
          {message}
        </Text>
      </Grid>

      <Grid container justifyContent="center" direction="column" className={classes.buttonWrapper}>
        <Grid item xs={12}>
          <Link to="/welcome/sign-in" className="c-button c-button--block c-button--action">
            Go to Patterns
          </Link>
        </Grid>
      </Grid>
    </TokenViewWrapper>
  );
}

TokenHandler.propTypes = propTypes;

export default connect(null, {
  getPhoneNumberVerified,
  renewConnection,
  openSnackbar,
  setToken
})(TokenHandler);
