/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import Text from 'src/shared/Text';
import { IconButton, Tooltip, Zoom } from '@material-ui/core';
import { useStyles } from './AboutPhoneStatus.styles';

const propTypes = {
  status: PropTypes.string
};

export default function AboutPhoneStatus({ status }) {
  const classes = useStyles();

  const wordingMap = {
    pending: 'Pending',
    verified: 'Verified',
    unverified: 'Not Verified'
  };

  const tooltipText = {
    pending: `Please check your phone for a text message from <strong>Patterns<sup>&reg;</sup></strong> with verification information.`,
    verified: 'Your phone number has been verified.',
    unverified: 'Phone number was not verified. Please update and save.'
  };

  const colorMap = {
    pending: 'body-light',
    verified: 'lime',
    unverified: 'error'
  };

  const statusLowerCase = String(status).toLowerCase();

  return (
    <Tooltip
      title={<div dangerouslySetInnerHTML={{ __html: tooltipText[statusLowerCase] }} /* eslint-disable-line */ />}
      placement="top-start"
      TransitionComponent={Zoom}
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton>
        <Text fontSize="16" lineHeight="24" font="bold" alignText="center" whiteSpace="pre" color={colorMap[statusLowerCase]}>
          {wordingMap[statusLowerCase]}
        </Text>
      </IconButton>
    </Tooltip>
  );
}

AboutPhoneStatus.propTypes = propTypes;
