export function makePGHDDeviceList(data) {
  const sources = new Set();
  data.forEach((el) => {
    const name = el.source;
    sources.add(name);
  });
  const sourceList = Array.from(sources);
  return sourceList.map((sourceName) => ({
    name: sourceName,
    type: sourceName,
    display_name: sourceName,
    connected: true,
    logo_url: null
  }));
}
