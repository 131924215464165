import React, { Fragment } from 'react';
import CountlyTrackedRoute from '../../countly/CountlyTrackedRoute';
import ContactsSharingWithMeReceivedInvitesContainer from '../../../containers/ContactsSharingWithMeReceivedInvitesContainer';
import ContactsSharingWithMeContainer from '../../../containers/ContactsSharingWithMeContainer';
import { PAGE_NAMES } from '../../../constants/routesCountlyNameConstants';
import routesConstants from '../../../constants/routes';

export default function ContactsSharedWithMeViewResolver() {
  const {
    CONTACTS_SHARED_WITH_ME,
    CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS
  } = routesConstants;
  const {
    CONTACTS_SHARED_WITH_ME: SHARING_WITH_ME,
    CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS: RECEIVED_INVITATIONS
  } = PAGE_NAMES;

  return (
    <Fragment>
      <CountlyTrackedRoute
        exact
        pageName={SHARING_WITH_ME}
        path={CONTACTS_SHARED_WITH_ME}
        component={ContactsSharingWithMeContainer}
      />
      <CountlyTrackedRoute
        exact
        pageName={RECEIVED_INVITATIONS}
        path={CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS}
        component={ContactsSharingWithMeReceivedInvitesContainer}
      />
    </Fragment>
  );
}
