/* istanbul ignore file */
import actionTypes from '../constants/actionTypes';

export function setToken(tokenType, token) {
  return {
    type: actionTypes.SET_TOKEN,
    payload: {
      tokenType,
      token
    }
  };
}
