import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ChevronIcon from './icons/ChevronIcon';

const propTypes = {
  data: PropTypes.array.isRequired,
  device: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    boldValue: PropTypes.bool
  })).isRequired,
  isSharingCircleView: PropTypes.bool,
  title: PropTypes.string.isRequired
};

function MHealthList(props) {
  const { data, device, headers, title } = props;
  const backLink = props.isSharingCircleView ? '/sharing-circle/dashboard' : '/dashboard';

  return (
    <div className="l-container">
      <div className="l-sidebar h-padded">
        <div className="c-card">
          <span className="c-nav-header c-card__header c-card__header--xs">
            <Link to={backLink}>
              <ChevronIcon className="h-position--unset" />
            </Link>
            <h2 data-testid="js-jest-title" className="c-nav-header__label c-card__title--bold">{title}</h2>
            <span data-testid="js-jest-device" className="h-capitalize h-ws-pre">{device}</span>
          </span>
          <div className="c-card__content h-full-width">
            <div className="h-highlight--no-padding c-log">
              {headers.map(({ label }, i) => <span key={i}>{label}</span>)}
            </div>
            <ul className="c-log-list">
              {data.map((row, i) => (
                <li data-testid="li" key={i} className="c-log">
                  {headers.map(({ dataKey, boldValue }, j) => {
                    return (
                      <span key={j} className={boldValue && 'h-bold'}>
                        {boldValue ? (row[dataKey]).toLocaleString(undefined, { maximumFractionDigits: 0 }) : row[dataKey]}
                      </span>
                    );
                  })}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

MHealthList.propTypes = propTypes;

export default MHealthList;
