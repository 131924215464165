import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: '540Px',
    margin: '0 auto',
    padding: '18Px'
  },
  splash: {
    display: 'flex',
    padding: '36Px',
    '& > svg': {
      width: '280Px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '8vh 0.5rem 2rem'
    }
  },
  container: {
    borderRadius: '2px',
    boxShadow: '0 1px 3px rgb(0 0 0 / 10%), 0 6px 24px rgb(0 0 0 / 10%)',
    padding: '36Px 72Px'
  }
}));
