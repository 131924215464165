/* istanbul ignore file */
const migrationManifest = {
  1: (state) => state,
  2: (state) => {
    return {
      ...state,
      status: state.status.withMutations((map) => {
        map.set('isProfileComplete', map.get('profileComplete'));
        map.delete('profileComplete');
      })
    };
  }
};

export default migrationManifest;
