import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isRefreshing: false,
  staySignedIn: false,
  idleTime: 900000
});

export default function authentication(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.REFRESH_TOKEN_START:
    return state.set('isRefreshing', true);

  case actionTypes.REFRESH_TOKEN_SUCCESS:
  case actionTypes.SIGN_IN:
    return state.withMutations((map) => {
      map.set('expires', action.payload.authenticationInfo.expires);
      map.set('expiresIn', action.payload.authenticationInfo.expiresIn);
      map.set('headers', Immutable.fromJS(action.payload.authenticationInfo.headers));
      map.set('refreshToken', action.payload.authenticationInfo.refreshToken);
      map.set('scope', action.payload.authenticationInfo.scope);
      map.set('tokenType', action.payload.authenticationInfo.tokenType);
      map.set('isRefreshing', false);
    });

  case actionTypes.REFRESH_TOKEN_FAILURE:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  case actionTypes.STAY_SIGNED_IN:
    return state.set('staySignedIn', true);

  case actionTypes.PLEASE_KEEP_SIGNING_ME_OUT:
    return state.set('staySignedIn', false);

  default:
    return state;
  }
}
