import actionTypes from '../constants/actionTypes';
import authenticationService from '../services/authenticationService';
import { setUserEmail } from './countlyActions';

function forgotPasswordFailure(err) {
  return {
    type: actionTypes.FORGOT_PASSWORD_FAILURE,
    error: true,
    payload: err
  };
}

function forgotPasswordStart() {
  return {
    type: actionTypes.FORGOT_PASSWORD_START
  };
}

function forgotPasswordSuccess() {
  return {
    type: actionTypes.FORGOT_PASSWORD_SUCCESS
  };
}

export /* istanbul ignore next */ function pleaseKeepSigningMeOut() {
  return {
    type: actionTypes.PLEASE_KEEP_SIGNING_ME_OUT
  };
}

function refreshTokenFailure() {
  return {
    type: actionTypes.REFRESH_TOKEN_FAILURE
  };
}

export /* istanbul ignore next */ function refreshTokenStart() {
  return {
    type: actionTypes.REFRESH_TOKEN_START
  };
}

function refreshTokenSuccess(authenticationInfo) {
  return {
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    payload: { authenticationInfo }
  };
}

function registerFailure(err) {
  return {
    type: actionTypes.REGISTER_FAILURE,
    error: true,
    payload: err
  };
}

function registerStart() {
  return {
    type: actionTypes.REGISTER_START
  };
}

function registerSuccess(authenticationInfo) {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    payload: { authenticationInfo }
  };
}

function resetPasswordFailure(err) {
  return {
    type: actionTypes.RESET_PASSWORD_FAILURE,
    error: true,
    payload: err
  };
}

function resetPasswordStart() {
  return {
    type: actionTypes.RESET_PASSWORD_START
  };
}

function resetPasswordSuccess() {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS
  };
}

function signInFailure() {
  return {
    type: actionTypes.SIGN_IN_FAILURE
  };
}

function signInStart() {
  return {
    type: actionTypes.SIGN_IN_START
  };
}

function signInSuccess(authenticationInfo) {
  return {
    type: actionTypes.SIGN_IN,
    payload: { authenticationInfo }
  };
}

export /* istanbul ignore next */ function staySignedIn() {
  return {
    type: actionTypes.STAY_SIGNED_IN
  };
}

export function forgotPassword(values) {
  return async function (dispatch) {
    dispatch(forgotPasswordStart());
    try {
      await authenticationService.forgotPassword(values);
      dispatch(forgotPasswordSuccess());
    } catch (err) {
      dispatch(forgotPasswordFailure(err));
      throw err;
    }
  };
}

export function refreshAccessToken(refreshToken) {
  return async function (dispatch) {
    try {
      const authenticationInfo = await authenticationService.refreshAccessToken(refreshToken);
      dispatch(refreshTokenSuccess(authenticationInfo));
      return authenticationInfo;
    } catch (err) {
      dispatch(refreshTokenFailure());
      throw err;
    }
  };
}

export function register(formVals, type) {
  return async function (dispatch) {
    dispatch(registerStart());
    try {
      const authenticationInfo = await authenticationService.register(formVals, type);
      dispatch(registerSuccess(authenticationInfo));
      // store user email for further usage by Countly
      dispatch(setUserEmail(formVals.emailAddress));
    } catch (err) {
      dispatch(registerFailure(err));
      throw err;
    }
  };
}

export function registerWithToken(values, type = 'hcp') {
  return async function (dispatch) {
    dispatch(registerStart());
    try {
      const authenticationInfo = await authenticationService.registerWithToken(values, type);
      dispatch(registerSuccess(authenticationInfo));
      // store user email for further usage by Countly
      dispatch(setUserEmail(values.emailAddress));
    } catch (err) {
      dispatch(registerFailure(err));
      throw err;
    }
  };
}

export function resetPassword(token, values) {
  return async function (dispatch) {
    dispatch(resetPasswordStart());

    try {
      await authenticationService.resetPassword(token, values);
      dispatch(resetPasswordSuccess());
    } catch (err) {
      dispatch(resetPasswordFailure(err));
      throw err;
    }
  };
}

export function signIn(values) {
  return async function (dispatch) {
    dispatch(signInStart());

    try {
      const authenticationInfo = await authenticationService.signIn(values);
      dispatch(signInSuccess(authenticationInfo));
      // store user email for further usage by Countly
      dispatch(setUserEmail(values.emailAddress));
    } catch (err) {
      dispatch(signInFailure());
      throw err;
    }
  };
}

export /* istanbul ignore next */ function reduxSignOut() {
  return {
    type: actionTypes.SIGN_OUT
  };
}

export function signOut(refreshToken) {
  return async function (dispatch) {
    try {
      await authenticationService.signOut(refreshToken);
    } catch (err) {
      console.error(err); // eslint-disable-line
    }

    dispatch(reduxSignOut());
  };
}
