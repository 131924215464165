import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function isDefaultMeal(label) {
  if (typeof label !== 'string') {
    return false;
  }

  return String(label.toLowerCase()).trim() === 'breakfast' ||
    String(label.toLowerCase()).trim() === 'lunch' ||
    String(label.toLowerCase()).trim() === 'dinner';
}

export function formatTestingTimeForList(inputTime) {
  const hours = inputTime.getHours();
  const minutes = inputTime.getMinutes();
  const meridianIndicator = hours < 12 ? 'am' : 'pm';
  const outputHours = hours < 12 ? hours : Math.abs(12 - hours);
  const outputMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${outputHours || 12}:${outputMinutes} ${meridianIndicator}`;
}

export function formatTestingTimeForServer(inputTime) {
  const hours = inputTime.getHours();
  const minutes = inputTime.getMinutes();
  const outputHours = hours < 10 ? `0${hours}` : hours;
  const outputMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${outputHours}:${outputMinutes}`;
}

export function prepTestingTimeForForm(testingTime) {
  const startTime = dayjs(testingTime.startTime, 'hh:mm').toISOString();
  const endTime = dayjs(testingTime.endTime, 'hh:mm').toISOString();
  return { ...testingTime, endTime, startTime };
}

export function prepTestingTimeForServer(testingTime) {
  delete testingTime.id;

  return {
    ...testingTime,
    endTime: formatTestingTimeForServer(testingTime.endTime),
    startTime: formatTestingTimeForServer(testingTime.startTime)
  };
}

export function prepTestingTimeArrayForServer(testingTimes, updatedTime) {
  if (updatedTime) {
    if (updatedTime.id) {
      return testingTimes.map((tTime) => {
        if (updatedTime.id === tTime.id) {
          return prepTestingTimeForServer(updatedTime);
        }

        return prepTestingTimeForServer(tTime);
      });
    }

    return testingTimes.concat(updatedTime).map((tTime) => {
      return prepTestingTimeForServer(tTime);
    });
  }

  return testingTimes.map((tTime) => {
    return prepTestingTimeForServer(tTime);
  });
}
