import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  foods: new Immutable.Map({
    isActive: false,
    cache: new Immutable.Map()
  }),
  sleeps: new Immutable.Map({
    isActive: false,
    cache: new Immutable.Map()
  }),
  summaries: new Immutable.Map({
    isActive: false,
    cache: new Immutable.Map()
  }),
  weights: new Immutable.Map({
    isActive: false,
    cache: new Immutable.Map()
  }),
  workouts: new Immutable.Map({
    isActive: false,
    cache: new Immutable.Map()
  })
});

export default function healthDataReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.GET_RANGE_OF_PGHD_START:
    return state.withMutations((map) => {
      map.setIn(['foods', 'isActive'], true);
      map.setIn(['sleeps', 'isActive'], true);
      map.setIn(['summaries', 'isActive'], true);
      map.setIn(['weights', 'isActive'], true);
      map.setIn(['workouts', 'isActive'], true);
    });

  case actionTypes.GET_RANGE_OF_PGHD_SUCCESS:
    return state.withMutations((map) => {
      map.setIn(['foods', 'cache'], new Immutable.Map(
        action.payload.foods.map((value) => {
          return [value.id, Immutable.fromJS(value)];
        })
      ));
      map.setIn(['sleeps', 'cache'], new Immutable.Map(
        action.payload.sleeps.map((value) => {
          return [value.id, Immutable.fromJS(value)];
        })
      ));
      map.setIn(['summaries', 'cache'], new Immutable.Map(
        action.payload.summaries.map((value) => {
          return [value.id, Immutable.fromJS(value)];
        })
      ));
      map.setIn(['weights', 'cache'], new Immutable.Map(
        action.payload.weights.map((value) => {
          return [value.id, Immutable.fromJS(value)];
        })
      ));
      map.setIn(['workouts', 'cache'], new Immutable.Map(
        action.payload.workouts.map((value) => {
          return [value.id, Immutable.fromJS(value)];
        })
      ));
      map.setIn(['foods', 'isActive'], false);
      map.setIn(['sleeps', 'isActive'], false);
      map.setIn(['summaries', 'isActive'], false);
      map.setIn(['weights', 'isActive'], false);
      map.setIn(['workouts', 'isActive'], false);
    });

  case actionTypes.GET_RANGE_OF_PGHD_FAILURE:
    return state.withMutations((map) => {
      map.setIn(['foods', 'isActive'], false);
      map.setIn(['sleeps', 'isActive'], false);
      map.setIn(['summaries', 'isActive'], false);
      map.setIn(['weights', 'isActive'], false);
      map.setIn(['workouts', 'isActive'], false);
    });

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
