import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette?.background?.main
    },
    '&.h-danger': {
      backgroundColor: theme.palette?.error?.light
    }
  }
}))(TableRow);
