/* istanbul ignore file */
import React from 'react';
import { Route } from 'react-router-dom';
import ECUnsubscribeContainer from 'src/containers/ECUnsubscribeContainer';

export default function ControlCenter() {
  return (
    <Route path="/control-center/ec/:token" component={ECUnsubscribeContainer}/>
  );
}
