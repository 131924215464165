import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import TimeframeRecord from '../records/TimeframeRecord';
import MuiCheckbox from './muiComponents/MuiCheckbox';
import { hasSomeLengthOfValues, returnValueForHeader } from '../utils/healthDataHelpers';
import CountlyTrackedButton from './countly/CountlyTrackedButton';
import withTimeFrame from '../containers/withTimeFrame';
import { Grid } from '@material-ui/core';
import Text from '../shared/Text/Text';

export class HealthDeviceWidget extends React.Component {
  static propTypes = {
    Chart: PropTypes.func.isRequired,
    data: PropTypes.object,
    dataCollectionType: PropTypes.string,
    dataType: PropTypes.string.isRequired,
    device: PropTypes.shape({
      display_name: PropTypes.string,
      type: PropTypes.string,
      logo_url: PropTypes.string
    }),
    durationInHours: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    Icon: PropTypes.func,
    isSharingCircleView: PropTypes.bool,
    sources: PropTypes.array,
    switchDevice: PropTypes.func.isRequired,
    timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired,
    title: PropTypes.string
  };

  state = {
    showList: false
  };

  componentDidMount() {
    this.setDeviceIfNoneIsSelected();
  }

  componentDidUpdate() {
    this.setDeviceIfNoneIsSelected();
  }

  setDeviceIfNoneIsSelected = () => {
    // if we already have a device or there aren't any sources then don't attempt to switch device
    if ((this.props.device && this.props.device.type) ||
      !this.props.sources ||
      this.props.sources.length === 0) {
      return;
    }

    // otherwise, we don't have a device and there are available sources, so pick the first one
    const deviceType = this.props.sources[0] && this.props.sources[0].type;
    if (!this.props.device && deviceType) {
      this.props.switchDevice(this.props.dataCollectionType, deviceType);
    }
  }

  toggleList = (deviceType) => () => {
    this.setState({ showList: !Boolean(deviceType) });

    if (deviceType && this.props.device.type !== deviceType) {
      this.props.switchDevice(this.props.dataCollectionType, deviceType, true);
    }
  }

  render() {
    const {
      Chart,
      data,
      dataCollectionType,
      dataType,
      device,
      durationInHours,
      Icon,
      sources,
      timeframe,
      title
    } = this.props;

    const deviceType = device && device.type;
    // why is display_name is present is explained in pogoHelpers.js
    const deviceName = device && (device.display_name || device.type);
    const avgValue = data[deviceType] && data[deviceType].average;
    const headerValue = returnValueForHeader(avgValue, durationInHours);
    const chartValue = avgValue || '';
    const hasValue = !isNaN(avgValue);
    const hasSomeSource = hasSomeLengthOfValues(data, deviceType);
    const routePrefix = this.props.isSharingCircleView ? '/sharing-circle/mhealth' : '/mhealth';

    return (
      <div className="c-card c-card--widget c-widget">
        <div className="c-widget__header" onClick={/* istanbul ignore next */ () => hasValue && this.props.history.push(`${routePrefix}/${dataCollectionType}`)}>
          <Icon className="c-icon" />
          <h3 className="c-widget__title">{title}</h3>
          <h3 className="h-bold">{headerValue || '-'}</h3>
        </div>
        {deviceType && (
          <div className="c-widget__row" data-testid="show-list" onClick={this.toggleList()}>
            <span className="c-widget__label">
              Source:
              <span
                className="h-capitalize"
                dangerouslySetInnerHTML={{ __html: ` ${deviceName}` }} // eslint-disable-line
              />
            </span>
            <span className="c-widget__icon">
              <svg className="c-icon" viewBox="0 0 24 24">
                <polyline strokeWidth="2" points="10 8 14 12.5 10 17" />
              </svg>
            </span>
          </div>
        )}
        <div className="c-card__body c-card__body--unpadded c-card__widget">
          {(!deviceType || !hasSomeSource) && (
            <Grid container style={{ height: '100%', padding: '32Px' }}>
              <Grid item xs={12} style={{ height: '100%', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                <Grid container style={{ padding: '12PX 0' }}>
                  <Text fontSize={16} lineHeight={18} font="bold" alignText="center">
                    There is no data for the source and date range selected.
                  </Text>
                </Grid>
                <Grid container style={{ padding: '12PX 0' }}>
                  <Text fontSize={16} lineHeight={18} alignText="center">
                    Please choose another source or date range.
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          )}
          {deviceType && !!hasSomeSource && (
            <Link to={`${routePrefix}/${dataCollectionType}`}>
              <Chart
                avgValue={chartValue}
                source={deviceType}
                timeframe={timeframe}
                type={dataType}
                values={data}
              />
            </Link>
          )}
        </div>
        {this.state.showList &&
          <div className={!deviceType ? 'c-card__body' : 'c-widget__overlay'}>
            <div className="c-widget__header">
              <Icon className="c-icon" />
              <h3 className="c-widget__title">{title}</h3>
              <h3 className="h-bold">
                {headerValue}
              </h3>
            </div>
            <div className="c-widget__with-scroll">
              {sources.map((source, i) => (
                <CountlyTrackedButton
                  key={i}
                  tag="div"
                  countlyevent="update"
                  countlyview={title}
                  className="c-widget__row"
                  onClick={this.toggleList(source.type)}
                >
                  <img className="c-widget__image" src={source.logo_url}/>
                  <span className="c-widget__label">Source:
                    <span
                      className="h-capitalize"
                      dangerouslySetInnerHTML={{ __html: source.display_name ? ` ${source.display_name}` : ` ${source.type}` }} // eslint-disable-line
                    />
                  </span>
                  <span className="c-widget__icon c-widget__icon--checkbox">
                    <MuiCheckbox
                      checked={deviceType === source.type || (dataCollectionType === source.type)}
                      onCheck={this.toggleList(source.type)}
                    />
                  </span>
                </CountlyTrackedButton>
              ))}
            </div>
          </div>}
      </div>
    );
  }
}

export default withTimeFrame(withRouter(HealthDeviceWidget));
