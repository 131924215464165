/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import Dropzone from 'react-dropzone';
// Icons
import plus from '../assets/img/icons/plus.svg';

const propTypes = {
  image: PropTypes.string,
  onChange: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    touched: PropTypes.bool
  }).isRequired,
  type: PropTypes.string
};

const defaultProps = {
  type: 'avatar'
};

const dropzoneRef = createRef();
const onDropHandler = () => {
  dropzoneRef.current.open();
};

export default function FileInput(props) {
  const {
    image,
    onChange,
    input: {
      name
    },
    label,
    meta: {
      error,
      touched
    }
  } = props;


  return (
    <div className="c-file-input">
      <div className="c-file-input__container">
        {image && <img className="c-file-input__image" src={image} alt="avatar" id="avatar_img"/>}
        <Dropzone
          accept="image/jpeg, image/png"
          ref={dropzoneRef}
          onDrop={(e) => {
            props.input.onChange(e);
            onChange && onChange();
          }}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="c-file-input__circle">
              <input data-testid="fileInput" {...getInputProps()} name={name}/>
              {!image && label}
            </div>
          )}
        </Dropzone>
        <div className="c-file-input__cover"/>
        {error && touched ? error : ''}
        <div
          className="c-file-input__add-icon h-cursor__pointer"
          onClick={onDropHandler}
        >
          <img src={plus}/>
        </div>
      </div>
    </div>
  );
}

FileInput.propTypes = propTypes;
FileInput.defaultProps = defaultProps;
