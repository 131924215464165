import PropTypes from 'prop-types';
import React from 'react';
import Text from 'src/shared/Text';

const propTypes = {
  status: PropTypes.string
};

export default function InvitesStatusView({ status }) {
  const wordingMap = {
    pending: 'Pending',
    accepted: 'Accepted',
    expired: 'Expired',
    revoked: 'Revoked',
    suspended: 'Suspended',
    overriden: 'Overriden',
    phone_not_verified: 'Phone Not Verified',
    declined: 'Declined'
  };

  const colorMap = {
    pending: 'secondary',
    accepted: 'secondary',
    revoked: 'secondary',
    suspended: 'secondary',
    overriden: 'secondary',
    phone_not_verified: 'yellow',
    expired: 'error',
    declined: 'error'
  };

  return (
    <Text fontSize="16" lineHeight="20" color={colorMap[String(status).toLowerCase()]}>
      {wordingMap[String(status).toLowerCase()]}
    </Text>
  );
}

InvitesStatusView.propTypes = propTypes;
