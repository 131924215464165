import PropTypes from 'prop-types';
import React from 'react';

// Shapes
import ShapeBreakfast from './ShapeBreakfast';
import ShapeDinner from './ShapeDinner';
import ShapeFasting from './ShapeFasting';
import ShapeLunch from './ShapeLunch';
import ShapeSnack from './ShapeSnack';

const propTypes = {
  color: PropTypes.string,
  mealTag: PropTypes.string,
  transform: PropTypes.string
};

function MealTimingGroup({ color, mealTag, transform }) {
  if (!mealTag) { return null; }

  if (mealTag === 'fasting') {
    return (
      <g id="jest-fasting-g" className="c-icon__fill" transform={transform || ''}>
        <ShapeFasting islarge="true" color={color} transform="translate(6, 0)"/>
      </g>
    );
  }

  if (mealTag === 'snack') {
    return (
      <g id="jest-snack-g" className="c-icon__fill" transform={transform || ''}>
        <ShapeSnack islarge="true" fill={color} transform="translate(6, 0)"/>
      </g>
    );
  }

  const mealInfo = mealTag.split('_');

  if (mealInfo[0] === 'pre') {
    return (
      <g className="c-icon__fill" transform={transform || ''}>
        <circle cx="6" cy="12" r="3" fill="none" stroke={color} strokeWidth="2" />
        {mealInfo[1] === 'breakfast' && <ShapeBreakfast fill={color} transform="translate(12, 0)" />}
        {mealInfo[1] === 'lunch' && <ShapeLunch fill={color} transform="translate(12, 0)" />}
        {mealInfo[1] === 'dinner' && <ShapeDinner fill={color} transform="translate(12, 0)" />}
      </g>
    );
  }

  if (mealInfo[0] === 'post') {
    return (
      <g className="c-icon__fill" transform={transform || ''}>
        <circle cx="30" cy="12" r="4" fill={color} />
        {mealInfo[1] === 'breakfast' && <ShapeBreakfast fill={color} />}
        {mealInfo[1] === 'lunch' && <ShapeLunch fill={color} />}
        {mealInfo[1] === 'dinner' && <ShapeDinner fill={color} />}
      </g>
    );
  }
}

MealTimingGroup.propTypes = propTypes;

export default MealTimingGroup;
