import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Container, Dialog, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
import MuiTextField from '../../../../../../components/muiComponents/MuiTextField';
import CountlyTrackedButton from '../../../../../../components/countly/CountlyTrackedButton';
import formValidationService from '../../../../../../services/formValidation';
import { handleFormSubmissionErrors } from '../../../../../../services/errorHandlingService';
import MuiFormControlledCheckbox from '../../../../../../components/muiComponents/MuiFormControlledCheckbox';
import { StyledDialogActions } from '../../../../../../components/muiStyledComponents/StyledDialogActions';
import { StyledDialogTitle } from '../../../../../../components/muiStyledComponents/StyledDialogTitle';
import { normalizePhone } from '../../../../../../services/fieldNormalizationService';
import { contactsViewsConstants } from '../../../../../../constants/contactsInviteViews';
import { useInboxActionsContext } from '../../../../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import { useStyles } from './ContactsAddSharingCircleForm.styles';

const propTypes = {
  inviteSharingCircleOrEmergencyContact: PropTypes.func.isRequired,
  getIAmSharingWithInvitationsCount: PropTypes.func.isRequired
};

function ContactsAddSharingCircleForm(props) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  let formInstance = null;

  const { inviteSharingCircleOrEmergencyContact, getIAmSharingWithInvitationsCount } = props;
  const { closeModal, switchToView } = useInboxActionsContext();

  const submit = async (values) => {
    const formValues = {
      ...values,
      scopes: values.scope ? ['carer', 'emergency_contact'] :  ['carer']
    };

    try {
      await inviteSharingCircleOrEmergencyContact(formValues);
      getIAmSharingWithInvitationsCount();
      switchToView(contactsViewsConstants.successSCInviteView);
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        ['firstName', 'firstName', 'emailAddress']
      );
    }
  };

  const handleChange = (val) => {
    val && setIsOpen(true);
  };

  const cancelInviteToEc = () => {
    formInstance.change('scope', false);
    setIsOpen(false);
  };

  const handleProceed = () => {
    setIsOpen(false);
  };

  const validateFields = (values) => {
    return new formValidationService.Validation()
      .firstName(values.firstName)
      .lastName(values.lastName)
      .email(values.emailAddress, 'emailAddress')
      .homeNumberOptional(values.homeNumber, values.scope)
      .getErrors();
  };

  return (
    <Container maxWidth="sm" className="h-padded-lg">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <StyledDialogTitle>
          <strong>Add Sharing Circle Contact</strong>
        </StyledDialogTitle>

        <Form
          onSubmit={submit}
          validate={validateFields}
        >
          {({ handleSubmit, isActive, values, form }) => {
            formInstance = form;

            return (
              <DialogContent>
                <form
                  name="addSharingCircleForm"
                  className="c-form"
                  onSubmit={handleSubmit}
                >
                  <Field
                    data-testid="firstName"
                    component={MuiTextField}
                    label="Name"
                    name="firstName"
                  />
                  <Field
                    component={MuiTextField}
                    label="Last Name"
                    name="lastName"
                  />
                  <Field
                    component={MuiTextField}
                    label="Email"
                    name="emailAddress"
                  />
                  {values.scope && (
                    <Field
                      component={MuiTextField}
                      label="Phone Number"
                      name="homeNumber"
                      parse={normalizePhone}
                      placeholder="Phone Number"
                    />
                  )}
                  <div className="h-padded-top">
                    <Field
                      label={<span>Also make an Emergency Contact</span>}
                      name="scope"
                      handleChange={handleChange}
                      component={MuiFormControlledCheckbox}
                      type="checkbox"
                    />
                  </div>
                  <StyledDialogActions>
                    <button className="c-button c-button--hollow jest-cancel" type="button" onClick={closeModal}>Cancel</button>
                    <CountlyTrackedButton
                      countlyevent="create"
                      className="c-button c-button--action"
                      disabled={isActive}
                      type="submit"
                    >
                      Send Invitation
                    </CountlyTrackedButton>
                  </StyledDialogActions>
                </form>
              </DialogContent>
            );
          }}
        </Form>
      </Grid>
      <Dialog
        open={isOpen}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        data-testid="dialog-info"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperComponent={Paper}
        PaperProps={{ elevation: 0 }}
        BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.75)' } }}
      >
        <Container maxWidth="sm" className="h-padded-lg">
          <Grid container justifyContent="space-between" alignItems="center" direction="column">
            <StyledDialogTitle>
              <strong>Emergency Contact</strong>
            </StyledDialogTitle>

            <DialogContent className={classes.content}>
              Your emergency contacts will receive notifications when your blood glucose is low and you are unresponsive.
              During a high or low glucose event, Patterns® will notify you twice. If you are unresponsive, we'll escalate
              the situation and alert your emergency contacts. When you add an emergency contact, they will receive an email
              that will allow them to accept or decline your request.
            </DialogContent>

            <StyledDialogActions>
              <button data-testid="jest-cancel" className="c-button c-button--hollow" onClick={cancelInviteToEc}>Cancel</button>
              <button data-testid="jest-proceed" className="c-button c-button--action" onClick={handleProceed}>Proceed</button>
            </StyledDialogActions>
          </Grid>
        </Container>
      </Dialog>
    </Container>
  );
}

export default ContactsAddSharingCircleForm;

ContactsAddSharingCircleForm.propTypes = propTypes;
