import PropTypes from 'prop-types';
import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const propTypes = {
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.date,
    onChange: PropTypes.func
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitError: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.node,
    pristine: PropTypes.bool
  }),
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
  views: PropTypes.array,
  variant: PropTypes.string,
  format: PropTypes.string,
  onClose: PropTypes.func,
  openTo: PropTypes.string,
  onOpen: PropTypes.func
};

const endDate = new Date();
const startDate = new Date();
startDate.setFullYear(startDate.getFullYear() - 120);

const defaultProps = {
  maxDate: endDate,
  minDate: startDate,
  format: 'MM/DD/YYYY',
  variant: 'dialog',
  views: ['year', 'month', 'date'],
  openTo: 'year'
};

function MuiDatePicker(props) {
  const {
    input,
    meta: {
      error,
      touched,
      submitFailed,
      submitError,
      valid
    },
    disabled,
    format,
    maxDate,
    minDate,
    openTo,
    onClose,
    onOpen,
    disableFuture,
    disablePast,
    variant,
    views,
    label
  } = props;

  const inputProps = { ...input };

  const showErrorText = (touched && !valid) || submitFailed;
  const defaultDate = inputProps.value ? moment(inputProps.value, format).toDate() : undefined;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        {...inputProps}
        data-testid={props['data-testid']}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        error={(showErrorText && (Boolean(error) || Boolean(submitError)))}
        helperText={showErrorText && (error || submitError)}
        format={format}
        fullWidth
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date) => {
          inputProps.onChange(date);
        }}
        onClose={onClose}
        onOpen={onOpen}
        openTo={openTo}
        value={defaultDate}
        variant={variant}
        views={views}
      />
    </MuiPickersUtilsProvider>
  );
}

MuiDatePicker.propTypes = propTypes;
MuiDatePicker.defaultProps = defaultProps;

export default MuiDatePicker;
