/* istanbul ignore file */
import { createTheme } from '@material-ui/core/styles';
import { sv } from 'src/assets/css/1_settings/variables';

// https://v4.mui.com/customization/palette/#palette
// https://v4.mui.com/customization/default-theme/?expand-path=$.palette
const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 544,
      md: 980,
      lg: 1220,
      xl: 1920
    }
  },
  shadows: ['none'],
  fontFamily: 'Helvetica Neue, HelveticaNeue, Helvetica, Arial sans-serif',
  palette: {
    common: {
      danger: '#FFEAE4',
      white: '#ffffff'
    },
    primary: {
      contrastText: '#ffffff',
      main: '#2da9df'
    },
    secondary: { // Paints main colors in app
      main: '#2da9df'
    },
    text: {
      main: '#282829',
      secondary: '#5d5d5d',
      light: '#b7b7b7',
      disabled: '#282829'
    },
    background: { // Grey-ish
      main: '#f5f5f5',
      light: '#f9f9f9'
    },
    backgroundActive: { // Blue-ish
      main: '#d9f4ff',
      dark: '#2da9df',
      light: '#f3fcff'
    },
    error: {
      main: '#e74113',
      light: '#ffeae4', // background
      dark: '#f19e87', // disabled
      contrastText: '#e74113'
    },
    yellow: {
      main: '#ffb700'
    },
    success: {
      main: '#79b400',
      light: '#f1f9e8'
    },
    action: {
      active: sv.color.active,
      selected: sv.color.active,
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: sv.color.active,
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)'
      }
    },
    MuiDialog: {
      paper: {
        boxShadow: '0 1px 3px rgb(0 0 0 / 10%), 0 0.375rem 1.5rem rgb(0 0 0 / 10%)',
        maxWidth: '544Px'
      }
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        width: '100%'
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: 'rgba(0, 0, 0, 0.54)'
        }
      }
    },
    MuiTextField: {
      root: {
        marginTop: '1.3rem',
        marginBottom: '0.3rem'
      }
    },
    // margins are set in mui-native-select.css since it is wrapper in FormControl which is commonly used
    MuiNativeSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none'
        }
      }
    },
    MuiTablePagination: {
      root: {
        '& p': {
          marginBottom: 0
        }
      }
    },
    MuiTableContainer: {
      root: {
        borderRadius: '4Px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
      }
    },
    MuiTable: {
      root: {
        minWidth: '980Px'
      }
    },
    MuiTableRow: {
      root: {
        '&.h-height-xl': {
          height: '56Px'
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: '0 16Px'
      }
    },
    MuiTableSortLabel: {
      root: {
        color: '#ffffff',
        opacity: 1,
        lineHeight: '18Px',
        '&.MuiTableSortLabel-active': {
          color: '#ffffff',
          opacity: 1,
          lineHeight: '18Px'
        }
      },
      icon: {
        '&.h-dark': {
          color: '#ffffff !important'
        }
      }
    }
  }
});

export default muiTheme;

