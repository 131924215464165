/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import InvitationBar from 'src/components/contacts/InvitationBar';
import routesConstants from 'src/constants/routes';
import Text from 'src/shared/Text';

const propTypes = {
  sentInvitations: PropTypes.shape({
    avatarUrls: PropTypes.array,
    pending: PropTypes.number,
    expired: PropTypes.number,
    declined: PropTypes.number
  })
};

function IamSharingInvitesSentCount({ sentInvitations }) {
  const { CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS } = routesConstants;
  const invitesCount = Object.values(sentInvitations).reduce((acc, val) => acc + val, 0);

  if (invitesCount < 1) {
    return null;
  }

  return (
    <InvitationBar
      avatarList={null}
      content={
        <Fragment>
          <Text font="bold" fontSize={18} lineHeight="24" alignText="center">
            {`${invitesCount} Sent Invitations `}
            {(sentInvitations?.declined > 0 || sentInvitations?.expired > 0) && (
              <span className="h-c-danger">
                (
                {sentInvitations?.declined > 0 && `${sentInvitations?.declined} Declined `}
                {sentInvitations?.expired > 0 && `${sentInvitations?.expired} Expired`}
                )
              </span>
            )}
          </Text>
          <Text fontSize={14} alignText="center">Click to See Status of Invitations</Text>
        </Fragment>
      }
      inviteLink={
        <Link to={CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS} className="h-content">
          <strong>Go to Sent Invitations {`${'>'}`}</strong>
        </Link>
      }
      color="blue"
    />
  );
}

IamSharingInvitesSentCount.propTypes = propTypes;

export default IamSharingInvitesSentCount;
