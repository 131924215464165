import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: '2rem'
  },
  title: {
    textAlign: 'center',
    width: '100%',
    paddingTop: '1rem',
    fontSize: '18Px',
    lineHeight: '1.222rem',
    paddingBottom: '10px'
  },
  label: {
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100 %',
    paddingBottom: '1rem'
  }
}));
