/* istanbul ignore file */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoSplash from 'src/components/LogoSplash';
import { Container, Grid } from '@material-ui/core';
import { useStyles } from './ErrorCenter.styles.js';
import { parse } from 'query-string';

export default function ErrorCenter() {
  const { search } = useLocation();
  const { msg, origin, title } = parse(search, { arrayFormat: 'bracket' });
  const classes = useStyles();

  const makeLink = (key) => {
    switch (key) {
    case 'Marketplace':
      return '/Marketplace';
    default:
      return '/welcome/sign-in';
    }
  };

  return (
    <Container maxWidth="sm" className={classes.wrapper}>
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.splash}>
          <LogoSplash />
        </Grid>
      </Grid>

      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" direction="column">
            <Grid item xs={12}>
              <h2 className="c-nav-header__label">{title || 'Something went wrong'}</h2>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" direction="column">
            <Grid item xs={12}>
              <p className="h-center">{msg || '...'}</p>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" direction="column" className={classes.buttonWrapper}>
            <Grid item xs={12}>
              <Link to={makeLink(origin)} className="c-button c-button--block c-button--action">
                Go to Patterns
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
