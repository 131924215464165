/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import onEnter from 'src/containers/onEnter';
import SharingCircleHeaderContainer from 'src/containers/SharingCircleHeaderContainer';
import DashboardContainer from 'src/containers/DashboardContainer';
import MHealthContainer from 'src/containers/MHealthContainer';
import LogBookContainer from 'src/containers/LogBookContainer';
import TrendsContainer from 'src/containers/TrendsContainer';
import SharingCirclePatientDataViewHeaderContainer from 'src/containers/SharingCirclePatientDataViewHeaderContainer';
import routeConstants from 'src/constants/routes';
import { requireAuth } from 'src/utils/routeHelpers';
import SharingCircle from 'src/pages/sharingCircle/children/SharingCircle/SharingCircle';

const propTypes = {
  patientDataViewMode: PropTypes.bool.isRequired
};

function SharingCircleMain({ patientDataViewMode }) {
  const {
    SHARING_CIRCLE_SHARED_WITH_ME,
    SHARING_CIRCLE_TRENDS,
    SHARING_CIRCLE_LOG_BOOK,
    SHARING_CIRCLE_MHEALTH,
    SHARING_CIRCLE_DASHBOARD
  } = routeConstants;

  return (
    <Fragment>
      {patientDataViewMode ? <SharingCirclePatientDataViewHeaderContainer /> : <SharingCircleHeaderContainer/>}

      <Route path={SHARING_CIRCLE_SHARED_WITH_ME} component={SharingCircle} />
      <Route exact path={SHARING_CIRCLE_DASHBOARD} component={DashboardContainer} />
      <Route exact path={SHARING_CIRCLE_TRENDS} component={TrendsContainer} />
      <Route path={SHARING_CIRCLE_LOG_BOOK} component={LogBookContainer} />
      <Route exact path={SHARING_CIRCLE_MHEALTH} component={MHealthContainer} />
    </Fragment>
  );
}

SharingCircleMain.propTypes = propTypes;
export default onEnter(requireAuth)(SharingCircleMain);
