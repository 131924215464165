import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@material-ui/core';
import confirm from 'src/utils/ConfirmHelpers';

const propTypes = {
  resendInviteToPatient: PropTypes.func.isRequired,
  sendInvitationReminder: PropTypes.func.isRequired,
  resendInvitationToPractice: PropTypes.func.isRequired,
  deleteSentInvitation: PropTypes.func.isRequired,
  memoizedFetch: PropTypes.func.isRequired,
  itemsListCount: PropTypes.number,
  item: PropTypes.shape({
    avatarUrl: PropTypes.string,
    emailAddress: PropTypes.string,
    invitationId: PropTypes.number,
    name: PropTypes.string,
    relation: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.array
  })
};

export default function ContactsIAmSharingSentInvitationsTableButtons({
  item,
  itemsListCount,
  memoizedFetch,
  resendInviteToPatient,
  deleteSentInvitation,
  resendInvitationToPractice,
  sendInvitationReminder
}) {
  const isHcp = item.type.includes('Practice');
  const isOutOfDate = ['expired', 'declined'].includes(String(item.status).toLowerCase());

  const handleSendReminder = async () => {
    const confirmOptions = {
      confirmText: 'Close', showCancel: false, headerText: 'Invitation successfully re-sent'
    };
    const confirmMessage = 'Your invitation was successfully re-sent. You can cancel at any time from “Sent Invitations” section';

    try {
      await sendInvitationReminder(item.invitationId);
      await confirm(confirmMessage, confirmOptions);
      // We should re-fetch table data
      memoizedFetch();
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  const handleResend = async () => {
    const confirmOptions = {
      confirmText: 'Close', showCancel: false, headerText: 'Invitation successfully re-sent'
    };
    const confirmMessage = 'Your invitation was successfully re-sent. You can cancel at any time from “Sent Invitations” section';

    try {
      await resendInviteToPatient(item.invitationId);
      await confirm(confirmMessage, confirmOptions);
      // We should re-fetch table data
      memoizedFetch();
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  const handleResendHcp = async () => {
    const confirmOptions = {
      confirmText: 'Close', showCancel: false, headerText: 'Invitation successfully re-sent'
    };
    const confirmMessage = 'Your invitation was successfully re-sent. You can cancel at any time from “Sent Invitations” section';

    try {
      await resendInvitationToPractice(item.invitationId);
      await confirm(confirmMessage, confirmOptions);
      // We should re-fetch table data
      memoizedFetch();
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  const handleCancel = async () => {
    const confirmOptions = {
      confirmText: 'Confirm', cancelText: 'Cancel', headerText: 'Cancel Invitation'
    };
    const confirmMessage = 'Please confirm that you want to cancel this invitation?';

    if (await confirm(confirmMessage, confirmOptions)) {
      try {
        await deleteSentInvitation(item.invitationId);
        memoizedFetch('edit', itemsListCount === 1);
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    }
  };

  const handleRemove = async () => {
    const confirmOptions = {
      confirmText: 'Confirm', cancelText: 'Cancel', headerText: 'Delete Invitation'
    };
    const confirmMessage = 'Please confirm that you want to delete this invitation?';

    if (await confirm(confirmMessage, confirmOptions)) {
      try {
        await deleteSentInvitation(item.invitationId);
        memoizedFetch('edit', itemsListCount === 1);
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    }
  };

  return (
    <Grid container style={{ columnGap: '16Px', display: 'flex', flexWrap: 'nowrap' }} justifyContent="center">
      {isHcp && isOutOfDate && (
        <>
          <Grid item xs={6} zeroMinWidth>
            <button
              className="c-button c-button--hollow c-button--condensed h-ws-pre h-full-width jest-resend"
              onClick={handleResendHcp}
            >
              Resend Request
            </button>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <button
              className="c-button c-button--danger-hollow c-button--condensed h-ws-pre h-full-width jest-delete"
              onClick={handleRemove}
            >
              Delete
            </button>
          </Grid>
        </>
      )}

      {isHcp && !isOutOfDate && (
        <>
          <Grid item xs={6}/>
          <Grid item xs={6} zeroMinWidth>
            <button
              className="c-button c-button--hollow c-button--condensed h-ws-pre h-full-width jest-cancel"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </Grid>
        </>
      )}

      {!isHcp && isOutOfDate && (
        <>
          <Grid item xs={6} zeroMinWidth>
            <button
              className="c-button c-button--hollow c-button--condensed h-ws-pre h-full-width jest-resend"
              onClick={handleResend}
            >
              Resend Request
            </button>
          </Grid>
          <Grid item xs={6} shrink={0} zeroMinWidth>
            <button
              className="c-button c-button--danger-hollow c-button--condensed h-ws-pre h-full-width jest-delete"
              onClick={handleRemove}
            >
              Delete
            </button>
          </Grid>
        </>
      )}

      {!isHcp && !isOutOfDate && (
        <>
          <Grid item xs={6} zeroMinWidth>
            <button
              className="c-button c-button--action c-button--condensed c-button--trailing h-ws-pre h-full-width jest-send-reminder"
              onClick={handleSendReminder}
            >
              Send Reminder
            </button>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <button
              className="c-button c-button--hollow c-button--condensed h-ws-pre h-full-width jest-cancel"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

ContactsIAmSharingSentInvitationsTableButtons.propTypes = propTypes;
