import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  message: '',
  open: false
});

export default function snackbarReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.OPEN_SNACKBAR:
    return state.withMutations((map) => {
      map.set('message', action.payload.message);
      map.set('open', true);
    });
  case actionTypes.CLOSE_SNACKBAR:
    return state.set('open', false);
  default:
    return state;
  }
}
