import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import MuiToggle from './muiComponents/MuiToggle';
import Switch from '@material-ui/core/Switch';
import confirm from '../utils/ConfirmHelpers';
import WarnAboutUnsavedChanges from '../containers/warnAboutUnsavedChanges';
import ChevronIcon from './icons/ChevronIcon';
import { areAllChecked } from 'src/utils/notificationFormHelpers';
import { Container } from '@material-ui/core';

const propTypes = {
  actions: PropTypes.shape({
    settings: PropTypes.shape({
      getNotificationSettings: PropTypes.func.isRequired,
      putNotificationSettings: PropTypes.func.isRequired
    })
  }),
  cellNumberVerified: PropTypes.string,
  initialValues: PropTypes.object
};

function NotificationSettings(props) {
  const history = useHistory();

  useEffect(() => {
    props.actions.settings.getNotificationSettings();
  }, []);

  let formInstance = null;

  const submit = async (formValues) => {
    try {
      await props.actions.settings.putNotificationSettings(formValues);
      props.actions.settings.getNotificationSettings();
      history.push('/profile/glucose-targets'); // go to next page
    } catch (err) {
      return { 'newReadings.push': err.message };
    }
  };

  const showConfirm = () => {
    const defaultOptions = {
      confirmText: 'Ok',
      showCancel: false
    };
    confirm('Please verify your phone first', defaultOptions);
  };

  const toggleAll = (e, isInputChecked) => {
    if (props.cellNumberVerified !== 'verified') {
      showConfirm();
      return;
    }

    formInstance.batch(() => {
      formInstance.change('newReadings.push', isInputChecked);

      formInstance.change('hyperEvents.push', isInputChecked);
      formInstance.change('hyperEvents.text', isInputChecked);
      formInstance.change('hyperEvents.email', isInputChecked);

      formInstance.change('hypoEvents.push', isInputChecked);
      formInstance.change('hypoEvents.text', isInputChecked);
      formInstance.change('hypoEvents.email', isInputChecked);

      formInstance.change('addCircleEvents.push', isInputChecked);
      formInstance.change('addCircleEvents.email', isInputChecked);

      formInstance.change('proactiveTestingReminders.push', isInputChecked);
      formInstance.change('proactiveTestingReminders.text', isInputChecked);

      formInstance.change('changeCircleEvents.push', isInputChecked);
      formInstance.change('changeCircleEvents.email', isInputChecked);
    });
  };

  const handleTextOnChange = (e, value) => {
    if (value === true && props.cellNumberVerified !== 'verified') {
      e.preventDefault();
      showConfirm();
      return true;
    }
  };

  return (
    <Container className="c-section">
      <Link className="c-nav-header" to="/profile">
        <ChevronIcon />
        <h2 className="c-nav-header__label">Notification Settings</h2>
      </Link>

      <Form
        keepDirtyOnReinitialize
        initialValues={props.initialValues}
        onSubmit={submit}
      >
        {({ handleSubmit, isActive, form, values }) => {
          formInstance = form;
          const toggled = areAllChecked(values);
          const disabledText = props.cellNumberVerified !== 'verified';

          return (
            <form
              name="notificationsForm"
              className="c-form"
              onSubmit={handleSubmit}
            >
              <WarnAboutUnsavedChanges />

              <div className="c-form-section">
                <div className="c-list-item">
                  <span className="h-bold">Enable All Notifications</span>
                  <div>
                    <Switch
                      data-testid="jest-toggle"
                      checked={toggled}
                      onChange={toggleAll}
                    />
                  </div>
                </div>
              </div>

              <div className="c-form-section">
                <div className="c-form-section__heading">
                  <h3 className="c-form-section__title">Glucose Reading Synced</h3>
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Push</span>
                  <Field
                    data-testid="newReadings-push"
                    component={MuiToggle}
                    type="checkbox"
                    name="newReadings.push"
                  />
                </div>
              </div>

              <div className="c-form-section">
                <div className="c-form-section__heading">
                  <h3 className="c-form-section__title">Proactive Testing Reminder</h3>
                </div>

                <div className="c-list-item">
                  <span className="h-bold">Push</span>
                  <Field
                    data-testid="proactiveTestingReminders-push"
                    component={MuiToggle}
                    type="checkbox"
                    name="proactiveTestingReminders.push"
                  />
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Text</span>
                  <Field
                    data-testid="proactiveTestingReminders-text"
                    component={MuiToggle}
                    type="checkbox"
                    name="proactiveTestingReminders.text"
                    disabled={disabledText}
                    onChange={handleTextOnChange}
                  />
                </div>
              </div>

              <div className="c-form-section">
                <div className="c-form-section__heading">
                  <h3 className="c-form-section__title">Low Glucose</h3>
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Push</span>
                  <Field
                    data-testid="hypoEvents-push"
                    component={MuiToggle}
                    type="checkbox"
                    name="hypoEvents.push"
                  />
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Text</span>
                  <Field
                    data-testid="hypoEvents-text"
                    component={MuiToggle}
                    type="checkbox"
                    name="hypoEvents.text"
                    disabled={disabledText}
                    onChange={handleTextOnChange}
                  />
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Email</span>
                  <Field
                    data-testid="hypoEvents-email"
                    component={MuiToggle}
                    type="checkbox"
                    name="hypoEvents.email"
                  />
                </div>
              </div>

              <div className="c-form-section">
                <div className="c-form-section__heading">
                  <h3 className="c-form-section__title">High Glucose</h3>
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Push</span>
                  <Field
                    data-testid="hyperEvents-push"
                    component={MuiToggle}
                    type="checkbox"
                    name="hyperEvents.push"
                  />
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Text</span>
                  <Field
                    data-testid="hyperEvents-text"
                    component={MuiToggle}
                    type="checkbox"
                    name="hyperEvents.text"
                    disabled={disabledText}
                    onChange={handleTextOnChange}
                  />
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Email</span>
                  <Field
                    data-testid="hyperEvents-email"
                    component={MuiToggle}
                    type="checkbox"
                    name="hyperEvents.email"
                  />
                </div>
              </div>

              <div className="c-form-section">
                <div className="c-form-section__heading">
                  <h3 className="c-form-section__title">Added to a Sharing Circle</h3>
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Push</span>
                  <Field
                    data-testid="addCircleEvents-push"
                    component={MuiToggle}
                    type="checkbox"
                    name="addCircleEvents.push"
                  />
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Email</span>
                  <Field
                    data-testid="addCircleEvents-email"
                    component={MuiToggle}
                    type="checkbox"
                    name="addCircleEvents.email"
                  />
                </div>
              </div>

              <div className="c-form-section">
                <div className="c-form-section__heading">
                  <h3 className="c-form-section__title">Changes to your Sharing Circle</h3>
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Push</span>
                  <Field
                    data-testid="changeCircleEvents-push"
                    component={MuiToggle}
                    type="checkbox"
                    name="changeCircleEvents.push"
                  />
                </div>
                <div className="c-list-item">
                  <span className="h-bold">Email</span>
                  <Field
                    data-testid="changeCircleEvents-email"
                    component={MuiToggle}
                    type="checkbox"
                    name="changeCircleEvents.email"
                  />
                </div>
              </div>

              <input
                className="c-button c-button--block c-button--action c-button--submit jest_submit"
                disabled={isActive}
                type="submit"
                value="Save"
              />
            </form>
          );
        }}
      </Form>
    </Container>
  );
}

NotificationSettings.propTypes = propTypes;
export default NotificationSettings;
