import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ForgotPasswordSent from './ForgotPasswordSent';
import MuiTextField from './muiComponents/MuiTextField';
import ERROR_MESSAGES from '../constants/errorMessages';
import ChevronIcon from './icons/ChevronIcon';
import formValidationService from '../services/formValidation';
import { Dialog, Paper } from '@material-ui/core';

export default class ForgotPassword extends Component {
  static propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    isOpen: false,
    email: ''
  }

  handleCloseModal = () => {
    this.props.history.push('/welcome/sign-in');
  }

  submit = async (formVals) => {
    try {
      await this.props.forgotPassword(formVals);
      this.setState({
        isOpen: true,
        email: formVals.emailAddress
      });
    } catch (err) {
      let message = String(err.message).toLowerCase();
      // It's a feature. As requested we should insert clickable
      // phone number as error text message.
      if (
        message === 'email address not found' ||
        message === 'blocked'
      ) {
        message = ERROR_MESSAGES[err.message];
      }

      return { emailAddress: message };
    }
  }

  validateFields(values) {
    return (new formValidationService.Validation())
      .email(values.emailAddress)
      .getErrors();
  }

  render() {
    return (
      <Fragment>
        <div className="c-card">
          <div className="c-card__header c-card__header--borderless c-card__header--xs">
            <Link className="c-nav-header" to="/welcome/sign-in">
              <ChevronIcon />
              <h2 className="c-nav-header__label">Forgot Password</h2>
            </Link>
          </div>
          <div className="c-card__body">
            <div className="h-padded h-center">Please enter your email and we'll send you a link to update your password</div>

            <Form
              onSubmit={this.submit}
              validate={this.validateFields}
            >
              {({ handleSubmit, valid, submitting, hasSubmitErrors, pristine }) => {
                return (
                  <form
                    name="forgotPassword"
                    className="c-form"
                    onSubmit={handleSubmit}
                  >
                    <fieldset className="c-fieldset">
                      <Field
                        parse={value => value.toLowerCase()}
                        component={MuiTextField}
                        label="Email"
                        name="emailAddress"
                      />
                    </fieldset>
                    <input
                      className="c-button c-button--block c-button--action c-button--submit jest_submit"
                      disabled={(!valid && !hasSubmitErrors) || pristine || submitting}
                      type="submit"
                      value="Submit"
                    />
                  </form>
                );
              }}
            </Form>
          </div>
          <Dialog
            open={this.state.isOpen}
            onClose={this.handleCloseModal}
            scroll="paper"
            data-testid="dialog-invites"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperComponent={Paper}
            maxWidth="md"
            PaperProps={{ elevation: 0, style: { width: 500 } }}
          >
            <ForgotPasswordSent />
          </Dialog>
        </div>
      </Fragment>
    );
  }
}
