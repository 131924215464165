import { connect } from 'react-redux';
import { getIAmSharingWithContacts } from '../actions/contactsActions';
import { getIAmSharingWithInvitationsCount } from '../actions/contactsIAmSharingWithInvitesActions';
import ContactsIAmSharingWith from '../pages/contacts/children/iAmSharingWith/ContactsIAmSharingWith';
import { immutableToJsHOC } from './ImmutableToJsHOC';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    itemsList: state.contacts.getIn(['iAmSharingWithContacts', 'sharingWith']),
    itemsListCount: state.contacts.getIn(['iAmSharingWithContacts', 'sharingWithCount'])
  };
}

export default connect(mapStateToProps, {
  getIAmSharingWithInvitationsCount,
  getIAmSharingWithContacts
})(immutableToJsHOC(ContactsIAmSharingWith));
