import PropTypes from 'prop-types';
import React, { memo } from 'react';
import useContactTypesSetHook from '../../../hooks/useContactTypesSetHook';
import useContactTypesGetHook from '../../../hooks/useContactTypesGetHook';
import { useStyles } from './ContactsTypesFilter.styles';

const propTypes = {
  skipType: PropTypes.array
};

function ContactsTypesFilter({ skipType }) {
  const { setFilterStatus } = useContactTypesSetHook(skipType);
  const { filterList } = useContactTypesGetHook(skipType);
  const classes = useStyles();

  return (
    <div className={`${classes.container} h-content`}>
      <div className={classes.title}>Select Contacts Types: </div>

      {filterList.map((item, key) => (
        <div key={key} className={classes.btnContainer}>
          <button onClick={setFilterStatus(item)} className={classes.btn}>
            <img src={item.enabled ? item.activeIcon : item.disabledIcon} className={classes.icon}/>
            <span className={!item.enabled ? classes.disabled : ''}>{item.title}</span>
          </button>
        </div>
      ))}
    </div>
  );
}

ContactsTypesFilter.propTypes = propTypes;
export default memo(ContactsTypesFilter);
