import PropTypes from 'prop-types';
import React, { Fragment, useRef, useState } from 'react';
import { Grid, Container, Paper, Dialog, DialogContent } from '@material-ui/core';
import ChevronLeft from 'src/assets/img/icons/chevron-left-dark.svg';
import Text from 'src/shared/Text/Text';
import Divider from 'src/shared/Divider/Divider';
import Tag from 'src/shared/Tag/Tag';
import { StyledDialogTitle } from 'src/components/muiStyledComponents/StyledDialogTitle';
import { StyledDialogActions } from 'src/components/muiStyledComponents/StyledDialogActions';
import { useStyles } from './Disconnected.styles';

const propTypes = {
  handleConnect: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  device: PropTypes.shape({
    connect_url: PropTypes.string,
    type: PropTypes.string,
    logo_url: PropTypes.string,
    display_name: PropTypes.string
  })
};

export default function Disconnected({ handleConnect, handleBack, device }) {
  const [isOpen, setIsOpen] = useState(false);
  const descriptionElementRef = useRef(null);
  const classes = useStyles();

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const connectDevice = () => {
    if (
      (device || {}).type === 'fitbit' ||
      (device || {}).type === 'google_fit'
    ) {
      openModal();
      return;
    }
    handleConnect(device)();
  };

  const handleSuccess = () => {
    handleConnect(device)();
    closeModal();
  };

  const wordings = {
    fitbit: `Once the page in default browser is open, enter your ${(device || {}).display_name} credentials.`,
    google_fit: 'Select your desired google account if you have more than one.',
    defaultWording: `Once the page in default browser is open, enter your ${(device || {}).display_name} credentials.`
  };

  return (
    <Fragment>
      <Container className={classes.blockContainer}>
        <Grid
          container
          onClick={handleBack}
          justifyContent="flex-start"
          className={`${classes.vPadded} ${classes.padded} h-cursor__pointer`}
        >
          <Grid item style={{ paddingRight: '8px' }} className={classes.itemBlock}>
            <img src={ChevronLeft} alt="left"/>
          </Grid>
          <Grid item xs={10} className={classes.itemBlock}>
            <Text fontSize={16} lineHeight={24} color="gray-dark" font="bold">
              {(device || {}).display_name}
            </Text>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={`${classes.vPadded} ${classes.padded}`}
        >
          <img src={(device || {}).logo_url} alt="left" className={classes.imgContainer}/>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-start" className={`${classes.vPaddedSmall} ${classes.padded}`}>
          <Text fontSize={16} lineHeight={20}>
            Tap <strong>Connect Now</strong> button.
          </Text>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-start" className={`${classes.vPaddedSmall} ${classes.padded}`}>
          <Text fontSize={16} lineHeight={20}>
            {wordings[device.type] || wordings.defaultWording}
          </Text>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-start" className={`${classes.vPaddedSmall} ${classes.padded}`}>
          <Text fontSize={16} lineHeight={20}>
            Once authenticated, you will see your ${(device || {}).display_name} data in the Patterns app.
          </Text>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={classes.vPaddedSmall}>
          <Divider/>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={`${classes.vPadded} ${classes.padded}`}>
          <Text fontSize={18} lineHeight={20} font="bold">Supported events</Text>
        </Grid>
        <Grid container justifyContent="center" className={classes.vPaddedSmall}>
          <Grid item xs={12} sm={10}>
            <Grid container className={classes.tagContainer}>
              <Grid item><Tag text="Steps" type="steps"/></Grid>
              <Grid item><Tag text="Weight" type="weight"/></Grid>
              <Grid item><Tag text="Water" type="water"/></Grid>
              <Grid item><Tag text="Burned Calories" type="calories"/></Grid>
              <Grid item><Tag text="Sleep" type="sleep"/></Grid>
              <Grid item><Tag text="Carbohydrates" type="carbohydrates"/></Grid>
              <Grid item><Tag text="Calories Consumed" type="caloriesConsumed"/></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className={classes.buttonBlock}>
        <button
          data-testid="connect"
          className="c-button c-button--action"
          style={{ width: '100%' }}
          onClick={connectDevice}
        >
          Connect Now
        </button>
      </Container>

      <Dialog
        open={isOpen}
        onClose={closeModal}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        data-testid="dialog-invites"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperComponent={Paper}
        PaperProps={{ elevation: 0 }}
      >
        <Container maxWidth="sm" className={classes.modalContainer}>
          <Grid container justifyContent="space-between" alignItems="center" direction="column">
            <StyledDialogTitle>
              <strong>Before Connecting</strong>
            </StyledDialogTitle>
            <DialogContent
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {(device || {}).type === 'fitbit' && (
                <Fragment>
                  <Text fontSize={16} lineHeight={20} >
                    1. Please choose <strong>Allow All</strong> on the permissions screen after providing your login credentials, otherwise data will not sync properly
                  </Text>
                  <Text fontSize={16} lineHeight={20}>
                    2. Please Note: Carbohydrate data from Fitbit is not supported and thus may not be accurate. Please use Patterns to record carbohydrates
                  </Text>
                </Fragment>
              )}
              {(device || {}).type === 'google_fit' && (
                <Text fontSize={16} lineHeight={20}>
                  Please check all options on the permissions screen after providing your login credentials, otherwise data will not sync properly
                </Text>
              )}
            </DialogContent>
            <StyledDialogActions>
              <button
                className="c-button c-button--action jest-proceed"
                onClick={handleSuccess}
              >
                Ok
              </button>
            </StyledDialogActions>
          </Grid>
        </Container>
      </Dialog>
    </Fragment>
  );
}

Disconnected.propTypes = propTypes;
