import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import AvatarPresenter from 'src/components/AvatarPresenter';
import isEmpty from 'lodash/isEmpty';
import { Grid } from '@material-ui/core';
import { makeNameWithSalutation } from 'src/utils/stringGeneratorHelpers';
import hcp from 'src/assets/img/icons/hcp_list.svg';
import { useStyles } from './ContactsHcpInfoViewData.styles';

const propTypes = {
  sendInvite: PropTypes.func,
  hcpInfo: PropTypes.shape({
    salutation: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    specialty: PropTypes.string,
    medicalPractices: PropTypes.arrayOf(PropTypes.shape({
      practiceId: PropTypes.number,
      medicalPracticeName: PropTypes.string,
      invitationStatus: PropTypes.string
    }))
  })
};

export default function ContactsHcpInfoViewData({ hcpInfo, sendInvite }) {
  const classes = useStyles();

  const makeButtonTxt = (invitationStatus) => {
    switch (invitationStatus) {
    case 'connected':
      return 'Connected';
    case 'invite_sent':
      return 'Sent';
    default:
      return 'Invite';
    }
  };

  return (
    <Fragment>
      <AvatarPresenter avatarUrl={null} classNames="c-avatar__big--painted h-margin-top"/>

      <h4 className="h-title--head h-center">
        {makeNameWithSalutation(hcpInfo.salutation, hcpInfo.firstName, hcpInfo.lastName)}
      </h4>

      <div className="h-content--sm h-c-lime h-padded-top-sm h-center">
        <img src={hcp}/><span>HCP/Practice</span>
      </div>

      <div className="h-content--sm h-center">{hcpInfo.specialty}</div>

      {!isEmpty(hcpInfo.medicalPractices) && hcpInfo.medicalPractices.map((practice, key) => (
        <Grid container style={{ padding: '0 0 0.7rem 0' }} key={key} data-testid="practices">
          <Grid item xs={4} sm={6}>
            <label className="h-content h-break-word">
              <strong>{practice.medicalPracticeName}</strong>
            </label>
          </Grid>
          <Grid item xs={8} sm={6}>
            <Grid container alignItems="center" justifyContent="flex-end" className={classes.buttonGroup}>
              <button
                disabled={practice.invitationStatus !== 'invite_available'}
                className="c-button c-button--condensed c-button--action jest-view h-no-break-word"
                onClick={() => sendInvite(practice.practiceId)}
              >
                {makeButtonTxt(practice.invitationStatus)}
              </button>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
}

ContactsHcpInfoViewData.propTypes = propTypes;
