/*
 * This is for handling scenario's when EC will click link in email
 * template for stop being an EC only. Invite/Decline logic is handled via
 * TokenHandler component.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parse } from 'query-string';

// Components
import ECUnsubscribe from '../components/ECUnsubscribe';

// Actions
import { selfRemoveFromEmergencyContact } from '../actions/invitationActions';

export class ECUnsubscribeContainer extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    selfRemoveFromEC: PropTypes.func.isRequired,
    handlingType: PropTypes.string
  };

  async componentDidMount() {
    const token = this.props.match.params.token;

    if (!token) {
      this.props.history.push('/');
    } else {
      try {
        await this.props.selfRemoveFromEC(token);
      } catch (err) {
        this.props.history.push('/');
      }
    }
  }

  render() {
    const {
      patients_first_name: firstName,
      patients_last_name: lastName
    } = parse(this.props.history.location.search);
    const {
      selfRemoveFromEC,
      handlingType,
      match: { params: { token } },
      history
    } = this.props;

    return (
      <ECUnsubscribe
        firstName={firstName}
        history={history}
        handlingType={handlingType}
        lastName={lastName}
        selfRemoveFromEC={selfRemoveFromEC}
        token={token}
      />
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    handlingType: state.invitations.getIn(['selfRemoveFromEmergencyContactResponse', 'handling_type'])
  };
}
/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    selfRemoveFromEC: bindActionCreators(selfRemoveFromEmergencyContact, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ECUnsubscribeContainer);
