import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Grid, Container } from '@material-ui/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ChevronLeft from 'src/assets/img/icons/chevron-left-dark.svg';
import Text from 'src/shared/Text/Text';
import Divider from 'src/shared/Divider/Divider';
import Tag from 'src/shared/Tag/Tag';
import { useStyles } from './Connected.styles';

dayjs.extend(utc);

const propTypes = {
  handleDisconnect: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSync: PropTypes.func.isRequired,
  syncOnServerStatus: PropTypes.bool,
  device: PropTypes.shape({
    type: PropTypes.string,
    logo_url: PropTypes.string,
    last_success_sync_time: PropTypes.string,
    most_recent_sync_attempt: PropTypes.string,
    connect_url: PropTypes.string,
    display_name: PropTypes.string
  })
};

export default function Connected({
  syncOnServerStatus,
  handleSync,
  handleDisconnect,
  handleBack,
  device
}) {
  const classes = useStyles();

  function formatDate(dateString) {
    if (!dateString) { // check for empty values or null
      return 'Invalid date';
    }
    const date = new Date(dateString);

    if (isNaN(date)) { // check for invalid date formats
      return 'Invalid date';
    }

    return dayjs.utc(dateString).format('MM/DD/YYYY hh:mm a');
  }

  return (
    <Fragment>
      <Container data-testid="connectView" className={classes.blockContainer}>
        <Grid
          container
          onClick={handleBack}
          justifyContent="flex-start"
          className={`${classes.vPadded} ${classes.padded} h-cursor__pointer`}
        >
          <Grid item style={{ paddingRight: '8px' }} className={classes.itemBlock}>
            <img src={ChevronLeft} alt="left"/>
          </Grid>
          <Grid item xs={10} className={classes.itemBlock}>
            <Text fontSize={16} lineHeight={24} color="gray-dark" font="bold">
              {(device || {}).display_name}
            </Text>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={`${classes.vPadded} ${classes.padded}`}
        >
          <img src={(device || {}).logo_url} alt="left" className={classes.imgContainer}/>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={`${classes.vPaddedSmall} ${classes.padded}`}>
          <Text fontSize={16} lineHeight={20} font="bold">
            {`Last Successful Sync: ${formatDate((device || {}).last_success_sync_time)}`}
          </Text>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={`${classes.vPaddedSmall} ${classes.padded}`}>
          <Text fontSize={16} lineHeight={20} font="bold">
            {`Most Recent Attempt: ${formatDate((device || {}).most_recent_sync_attempt)}`}
          </Text>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={classes.vPadded}>
          <button
            disabled={syncOnServerStatus}
            data-testid="connect"
            className="c-button c-button--action"
            style={{ width: '285Px' }}
            onClick={handleSync(device)}
          >
            Sync Now
          </button>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={classes.vPaddedSmall}>
          <Divider/>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" className={`${classes.vPadded} ${classes.padded}`}>
          <Text fontSize={18} lineHeight={20} font="bold">Supported events</Text>
        </Grid>

        <Grid container justifyContent="center" className={classes.vPaddedSmall}>
          <Grid item xs={12} sm={10}>
            <Grid container className={classes.tagContainer}>
              <Grid item><Tag text="Steps" type="steps"/></Grid>
              <Grid item><Tag text="Weight" type="weight"/></Grid>
              <Grid item><Tag text="Water" type="water"/></Grid>
              <Grid item><Tag text="Burned Calories" type="calories"/></Grid>
              <Grid item><Tag text="Sleep" type="sleep"/></Grid>
              <Grid item><Tag text="Carbohydrates" type="carbohydrates"/></Grid>
              <Grid item><Tag text="Calories Consumed" type="caloriesConsumed"/></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className={classes.buttonBlock}>
        <button
          data-testid="connect"
          className="c-button c-button--danger-hollow"
          style={{ width: '100%' }}
          onClick={handleDisconnect(device)}
        >
          Remove
        </button>
      </Container>
    </Fragment>
  );
}

Connected.propTypes = propTypes;
