import Immutable from 'immutable';
import actionTypes from 'src/constants/actionTypes';
import ReadingsRecord from 'src/records/ReadingsRecord';
import { isMealTag } from 'src/utils/readingHelpers';

export const INITIAL_STATE = new ReadingsRecord();

export default function readingsReducer(state = INITIAL_STATE, action) { // eslint-disable-line complexity
  switch (action.type) {
  case actionTypes.GET_RANGE_OF_READINGS_START:
    return state.set('isActive', true);

  case actionTypes.GET_RANGE_OF_READINGS_FAILURE:
    return state.set('isActive', false);

  case actionTypes.GET_LAST_KNOWN_READING_DATE_START:
    return state.set('isActiveLoadTimestamp', true);

  case actionTypes.DELETE_READING_FOOD_SUCCESS:
    return state.withMutations((map) => {
      map.updateIn(['cache', action.payload.readingId, 'foods'], (foods) => {
        return foods.filterNot((f) => `${f.get('id')}` === `${action.payload.foodId}`);
      });
      map.set('isActive', false);
    });

  case actionTypes.GET_RANGE_OF_READINGS_SUCCESS:
    return state.withMutations((map) => {
      map.update('cache', (cache) => {
        return cache.filterNot((reading) => (
          action.payload.readings.some((x) => (
            `${x.sequenceNumber}${x.serialNumber}` === `${reading.get('sequenceNumber')}${reading.get('serialNumber')}`
          ))
        )).mergeDeep(new Immutable.Map(
          action.payload.readings.map((value) => ([value.id, Immutable.fromJS(value)]))
        ));
      });
      map.set('isActive', false);
    });

  case actionTypes.GET_READING_SUCCESS:
    return state.updateIn(['cache', action.payload.reading.id], (reading) => {
      return reading.merge(Immutable.fromJS(action.payload.reading));
    });

  case actionTypes.SET_SELECTED_READING_ID:
    return state.set('selectedId', action.payload.readingId);

  case actionTypes.CLEAR_SELECTED_READING:
    return state.delete('selectedId');

  case actionTypes.GET_READING_FOODS_SUCCESS:
    return state.withMutations((map) => {
      map.setIn(
        ['cache', parseFloat(action.payload.readingId), 'foods'],
        Immutable.fromJS(action.payload.foods)
      );
      map.set('isActive', false);
    });

  case actionTypes.POST_READING_FOOD_SUCCESS:
    return state.withMutations((map) => {
      map.updateIn(['cache', action.payload.readingId, 'foods'], (foods) => {
        if (!foods) {
          return Immutable.fromJS([action.payload.food]);
        }
        return foods.push(Immutable.fromJS(action.payload.food));
      });
    });

  case actionTypes.POST_READING_TAG_SUCCESS: {
    const updatedTag = Immutable.fromJS(action.payload.tag);

    return state.withMutations((map) => {
      map.updateIn(['cache', action.payload.readingId, 'full_tags'], (fullTags) => {
        return fullTags.push(updatedTag);
      });
      map.updateIn(['cache', action.payload.readingId, 'tags'], (tags) => {
        return updatedTag.get('type') === 'meal' ?
          tags.push(updatedTag.get('value')) :
          tags.push(updatedTag.get('type'));
      });
    });
  }

  case actionTypes.PUT_READING_TAG_SUCCESS: {
    const updatedTag = Immutable.fromJS(action.payload.tag);

    return state.withMutations((map) => {
      map.updateIn(['cache', action.payload.readingId, 'full_tags'], (fullTags) => {
        return fullTags.map((tag) => {
          return (tag.get('id') === updatedTag.get('id')) ? updatedTag : tag;
        });
      });
      if (action.payload.tag.type === 'meal') {
        map.updateIn(['cache', action.payload.readingId, 'tags'], (tags) => {
          // find the old meal tag
          const prevMealTag = tags.findIndex((t) => isMealTag(t));
          // replace it with the new meal tag
          return tags.set(prevMealTag, updatedTag.get('value'));
        });
      }
    });
  }

  case actionTypes.PUT_READING_FOOD_SUCCESS:
    return state.withMutations((map) => {
      map.updateIn(['cache', action.payload.readingId, 'foods'], (foods) => {
        const idx = foods.findIndex((f) => f.get('id') === action.payload.foodId);
        return foods.update(idx, () => Immutable.fromJS(action.payload.food));
      });
    });

  case actionTypes.DELETE_READING_TAG_SUCCESS: {
    return state.withMutations((map) => {
      map.updateIn(['cache', action.payload.readingId, 'full_tags'], (fullTags) => {
        return fullTags.filter(tag => tag.get('type') !== action.payload.tag.type);
      });
      map.updateIn(['cache', action.payload.readingId, 'tags'], (tags) => {
        return tags.filter(taq => taq !== action.payload.tag.value);
      });
    });
  }

  case actionTypes.GET_LAST_KNOWN_READING_DATE_SUCCESS:
  case actionTypes.GET_LAST_KNOWN_READING_DATE_FAILURE:
    return state.set('isActiveLoadTimestamp', false);

  case actionTypes.CLEAR_SELECTED_PATIENT:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
