import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { parse } from 'query-string';
import TOKEN_TYPES from 'src/constants/tokenTypes';
import apiService from 'src/services/apiService';
import { Grid } from '@material-ui/core';
import Text from 'src/shared/Text';
import Divider from 'src/shared/Divider';
import TokenViewWrapper from 'src/components/TokenViewWrapper';
import { useStyles } from './SharingCircleAcceptDecline.styles';

const AUTH_URL = process.env.AUTH_URL || '';

export default function SharingCircleAcceptDecline() {
  const history = useHistory();
  const { token } = useParams();
  const location = useLocation();
  const { status } = parse(location.search);
  const tokenType = token.slice(0, 3);

  const [showConfirm, setShowConfirm] = React.useState(status === 'decline');
  const [message, setMessage] = React.useState('Please Wait...');

  const classes = useStyles();

  const acceptInvite = async () => {
    const { SHARING_CIRCLE_CONTACT } = TOKEN_TYPES;
    const role = tokenType === SHARING_CIRCLE_CONTACT ? 'carer' : 'ec_carer';

    try {
      await apiService.post(`${AUTH_URL}/sharing/${role}/accept_invite`, { token });
      setMessage('You have successfully accepted the invitation.');
    } catch (e) {
      setMessage(e?.response?.data?.error);
    }
  };

  const decline = async () => {
    try {
      await apiService.delete(`${AUTH_URL}/sharing/${token}`);
      setMessage(<span data-testid="declined">Your contact has been notified that you have declined the request to view their data.</span>);
    } catch (e) {
      setMessage(e?.response?.data?.error);
    }

    setShowConfirm(false);
  };

  React.useEffect(() => {
    // For SC status "accept" means user always go to create account
    status === 'accept' && history.push('/welcome/register');
    status === 'registered' && acceptInvite();
    status === 'decline' && setMessage(<span>Are you sure you want to decline this patient's request to view their data in <strong>Patterns<sup>&reg;</sup></strong>?</span>);
    status !== 'accept' && status !== 'registered' && status !== 'decline' && history.push('/');
  }, [status]);

  return (
    <TokenViewWrapper>
      <Grid item xs={12}  className={classes.header}>
        <Text fontSize={20} lineHeight={24} font="bold" alignText="left">
          Sharing Circle Invite
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Text fontSize={16} lineHeight={22} alignText="left">
          {message}
        </Text>
      </Grid>

      <Divider vPadding="16"/>

      {!showConfirm && (
        <Grid container justifyContent="center" direction="column">
          <Grid item xs={12}>
            <Link to="/welcome/sign-in" className="c-button c-button--block c-button--active">
              Home
            </Link>
          </Grid>
        </Grid>
      )}

      {showConfirm && (
        <Grid container justifyContent="center" direction="column">
          <Grid item xs={12}>
            <button data-testid="jest-decline-btn" onClick={decline} className="c-button c-button--block c-button--danger">
              Decline
            </button>
          </Grid>
        </Grid>
      )}
    </TokenViewWrapper>
  );
}
