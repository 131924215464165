import React from 'react';

export default function ShareIcon() {
  return (
    <svg height="24" width="24" viewBox="0 0 26 26">
      <rect x="0" y="0" width="26" height="26" fill="rgba(255,255,255,0.01)" />
      <g fill="none" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="5 16.1111111 5 22.1111111 21 22.1111111 21 16.1111111" />
        <polyline points="7 8.55555556 13 3 19 8.55555556" />
        <path d="M13,3.55555556 L13,16.8888889" />
      </g>
    </svg>
  );
}
