import { connect } from 'react-redux';
import { getSharedWithInvitationsCount } from 'src/actions/contactsSharedWithMeInvitesActions';
import SharedWithMeInvitesCount from 'src/components/contacts/SharedWithMeInvitesCount';
import { immutableToJsHOC } from './ImmutableToJsHOC';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    receivedInvitations: state.contactsSharedWithMeInvitations.get('receivedInvitations')
  };
}

export default connect(mapStateToProps, {
  getSharedWithInvitationsCount
})(immutableToJsHOC(SharedWithMeInvitesCount));
