/* istanbul ignore file */
import { connect } from 'react-redux';
import { clearNutritionix } from 'src/actions/nutritionixActions';
import EditCarbsTag from 'src/components/logs/EditCarbsTag';
import { immutableToJsHOC } from 'src/containers/ImmutableToJsHOC';

function mapStateToProps(state) {
  const selectedId = state.readings.get('selectedId');
  return {
    favoriteFoods: state.meals.get('favoriteFoods'),
    recentFoods: state.meals.get('recentFoods'),
    nutritionix: state.nutritionix,
    selectedReading: state.readings.getIn(['cache', selectedId])
  };
}

export default connect(mapStateToProps, { clearNutritionix })(immutableToJsHOC(EditCarbsTag));
