/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

function PatternsSnackbar(props) {
  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      message={props.message}
      autoHideDuration={4000}
      onClick={props.closeSnackbar}
      onClose={props.closeSnackbar}
    />
  );
}

PatternsSnackbar.propTypes = {
  closeSnackbar: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired
};

export default PatternsSnackbar;
