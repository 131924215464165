import actionTypes from '../constants/actionTypes';
import readingFoodsService from '../services/readingFoodsService';

// Utils
import { optimizeFavoriteFoodList, optimizeRecentFoodList } from '../utils/logBookFoods';

// DELETE_READING_FOOD

function deleteReadingFoodFailure(err) {
  return {
    type: actionTypes.DELETE_READING_FOOD_FAILURE,
    error: true,
    payload: err
  };
}

function deleteReadingFoodStart() {
  return {
    type: actionTypes.DELETE_READING_FOOD_START
  };
}

function deleteReadingFoodSuccess(readingId, foodId) {
  return {
    type: actionTypes.DELETE_READING_FOOD_SUCCESS,
    payload: { readingId, foodId }
  };
}

// GET_READING_FOOD

function getReadingFoodFailure(err) {
  return {
    type: actionTypes.GET_READING_FOOD_FAILURE,
    error: true,
    payload: err
  };
}

function getReadingFoodStart() {
  return {
    type: actionTypes.GET_READING_FOOD_START
  };
}

function getReadingFoodSuccess(food) {
  return {
    type: actionTypes.GET_READING_FOOD_SUCCESS,
    payload: { food }
  };
}

// POST_READING_FOOD

function postReadingFoodFailure(err) {
  return {
    type: actionTypes.POST_READING_FOOD_FAILURE,
    error: true,
    payload: err
  };
}

function postReadingFoodStart() {
  return {
    type: actionTypes.POST_READING_FOOD_START
  };
}

function postReadingFoodSuccess(readingId, food) {
  return {
    type: actionTypes.POST_READING_FOOD_SUCCESS,
    payload: { food, readingId }
  };
}

// PUT_READING_FOOD

function putReadingFoodFailure(err) {
  return {
    type: actionTypes.PUT_READING_FOOD_FAILURE,
    error: true,
    payload: err
  };
}

function putReadingFoodStart() {
  return {
    type: actionTypes.PUT_READING_FOOD_START
  };
}

function putReadingFoodSuccess(readingId, foodId, food) {
  return {
    type: actionTypes.PUT_READING_FOOD_SUCCESS,
    payload: {
      food,
      foodId,
      readingId
    }
  };
}

// GET_READING_FOODS

function getReadingFoodsFailure(err) {
  return {
    type: actionTypes.GET_READING_FOODS_FAILURE,
    error: true,
    payload: err
  };
}

function getReadingFoodsStart() {
  return {
    type: actionTypes.GET_READING_FOODS_START
  };
}

function getReadingFoodsSuccess(readingId, foods) {
  return {
    type: actionTypes.GET_READING_FOODS_SUCCESS,
    payload: { readingId, foods }
  };
}

export function deleteReadingFood(readingId, foodId) {
  return async function (dispatch) {
    dispatch(deleteReadingFoodStart());

    try {
      await readingFoodsService.deleteReadingFood(readingId, foodId);
      dispatch(deleteReadingFoodSuccess(readingId, foodId));
    } catch (err) {
      dispatch(deleteReadingFoodFailure(err));
      throw err;
    }
  };
}

export function getReadingFood(readingId, foodId) {
  return async function (dispatch) {
    dispatch(getReadingFoodStart());

    try {
      const food = await readingFoodsService.getReadingFood(readingId, foodId);
      dispatch(getReadingFoodSuccess(food));
    } catch (err) {
      dispatch(getReadingFoodFailure(err));
      throw err;
    }
  };
}

export function postReadingFood(readingId, foodToPost) {
  return async function (dispatch) {
    dispatch(postReadingFoodStart());

    try {
      const food = await readingFoodsService.postReadingFood(readingId, foodToPost);
      dispatch(postReadingFoodSuccess(readingId, food));
    } catch (err) {
      dispatch(postReadingFoodFailure(err));
      throw err;
    }
  };
}

export function putReadingFood(readingId, foodId, foodToPut) {
  return async function (dispatch) {
    dispatch(putReadingFoodStart());

    try {
      const food = await readingFoodsService.putReadingFood(readingId, foodId, foodToPut);
      dispatch(putReadingFoodSuccess(readingId, foodId, food));
    } catch (err) {
      dispatch(putReadingFoodFailure(err));
      throw err;
    }
  };
}

export function getReadingFoods(readingId) {
  return async function (dispatch) {
    dispatch(getReadingFoodsStart());

    try {
      const data = await readingFoodsService.getReadingFoods(readingId);
      dispatch(getReadingFoodsSuccess(readingId, data.foods));
    } catch (err) {
      dispatch(getReadingFoodsFailure(err));
      throw err;
    }
  };
}

function getFavoriteFoodsSuccess(payload) {
  return {
    type: actionTypes.GET_FAVORITE_FOODS_SUCCESS,
    payload
  };
}

export function getFavoriteFoods() {
  return async function (dispatch) {
    const food = await readingFoodsService.getFavoriteFoods();
    const result = optimizeFavoriteFoodList(food.favorite_foods);
    dispatch(getFavoriteFoodsSuccess(result));
  };
}

function getRecentFoodsSuccess(payload) {
  return {
    type: actionTypes.GET_RECENT_FOODS_SUCCESS,
    payload
  };
}

export function getRecentFoods() {
  return async function (dispatch) {
    const food = await readingFoodsService.getRecentFoods();
    const result = optimizeRecentFoodList(food.recent_foods);
    dispatch(getRecentFoodsSuccess(result));
  };
}

export function addFavoriteFood(food) {
  return async function () {
    await readingFoodsService.addFavoriteFood(food);
  };
}

export function removeFavoriteFood(id) {
  return async function () {
    await readingFoodsService.removeFavoriteFood(id);
  };
}
