import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useStyles } from './Divider.styles';

const propTypes = {
  color: PropTypes.oneOf(['default', 'grey-light']),
  vPadding: PropTypes.oneOf(['2, 4, 6, 8, 12, 16, 24, 48, 56, 64'])
};

export default function Divider({ color, vPadding }) {
  const classes = useStyles();
  const classesNames = classNames(
    classes.container,
    classes[`color-${color || 'default'}`]
  );

  const wrapperClasses = classNames(
    classes.container,
    classes[`v-padding-${vPadding || '0'}`]
  );

  return (
    <div className={wrapperClasses}>
      <div className={classesNames}/>
    </div>
  );
}

Divider.propTypes = propTypes;
