/* istanbul ignore file */
import { connect } from 'react-redux';
import AppStartStatusReviewer from 'src/components/AppStartStatusReviewer';

function mapStateToProps(state) {
  const skippedSetupRemaining = state.status.get('skippedSetupRemaining').sort();
  const setupRemaining = state.status.get('setupRemaining').sort();
  const hasHypoTrendReadingsSize = state.status.get('hypoTrendReadings').size;

  // After clicking "Do not show again" arrays "skippedSetupRemaining" and "setupRemaining"
  // should contain same array values. If that is the case we do not show Onboard pop-up
  const skipped = skippedSetupRemaining ? skippedSetupRemaining.toJS() : [];
  const remaining = setupRemaining ? setupRemaining.toJS() : [];

  return {
    profileCheckListClosed: !remaining?.length || remaining.every(item => skipped.includes(item)),
    hypoTrendOpen: hasHypoTrendReadingsSize > 0
  };
}

export default connect(mapStateToProps)(AppStartStatusReviewer);
