/* istanbul ignore file */
import { connect } from 'react-redux';
import SharingCircleMain from '../pages/sharingCircle/SharingCircleMain';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const mapStateToProps = (state) => {
  return {
    patientDataViewMode: !!state.selectedPatient.get('patientId')
  };
};

export default connect(mapStateToProps)(immutableToJsHOC(SharingCircleMain));
