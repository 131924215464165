/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setSelectedReadingId } from '../actions/readingsActions';
import {
  getFavoriteFoods,
  getRecentFoods,
  getReadingFoods
} from '../actions/readingFoodsActions';

export class LogBookFetcherContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classNames: PropTypes.string,
    getFavoriteFoods: PropTypes.func.isRequired,
    getReadingFoods: PropTypes.func.isRequired,
    getRecentFoods: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    isSelected: PropTypes.bool,
    isSharingCircleView: PropTypes.bool.isRequired,
    readingId: PropTypes.number.isRequired,
    setSelectedReadingId: PropTypes.func.isRequired
  };

  handleClick = async (e) => {
    e.preventDefault();
    const { isSelected, readingId, isSharingCircleView } = this.props;

    if (!isSelected) {
      await this.props.getReadingFoods(readingId);
      this.props.getFavoriteFoods();
      this.props.getRecentFoods();
      this.props.setSelectedReadingId(readingId);
      this.props.history.push(`${isSharingCircleView ? '/sharing-circle' : ''}/log-book/reading/${readingId}`);
    }
  }

  render() {
    return (
      <a data-testid="link" className={this.props.classNames} onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

function makeMapStateToProps() {
  return function (state) {
    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId')
    };
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setSelectedReadingId: bindActionCreators(setSelectedReadingId, dispatch),
    getRecentFoods: bindActionCreators(getRecentFoods, dispatch),
    getReadingFoods: bindActionCreators(getReadingFoods, dispatch),
    getFavoriteFoods: bindActionCreators(getFavoriteFoods, dispatch)
  };
}

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(LogBookFetcherContainer));
