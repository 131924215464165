import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { normalizeInteger, normalizeFloat } from '../services/fieldNormalizationService';
import WarnAboutUnsavedChanges from '../containers/warnAboutUnsavedChanges';
import formValidationService from '../services/formValidation';
import { handleFormSubmissionErrors } from '../services/errorHandlingService';
import ChevronIcon from './icons/ChevronIcon';
import MuiTextField from './muiComponents/MuiTextField';

class GlucoseTargets extends React.Component {
  static propTypes = {
    getPatientTargets: PropTypes.func.isRequired,
    getStatus: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    initialValues: PropTypes.shape({
      hypoLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      targetLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      targetHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      hyperLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      preMealLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      preMealHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      postMealLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      postMealHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      eA1cTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired,
    putPatientTargets: PropTypes.func.isRequired
  };

  submit = async (formValues) => {
    const vals = {
      ...formValues,
      eA1cTarget: parseFloat(formValues.eA1cTarget).toFixed(1)
    };

    try {
      await this.props.putPatientTargets(vals);

      this.props.getPatientTargets(); // no need to wait on this
      this.props.getStatus();
      this.props.history.push('/profile/testing-schedule'); // go to next page
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        ['targetLow', 'targetHigh', 'hypoLimit', 'hyperLimit', 'eA1cTarget', 'preMealLow', 'preMealHigh', 'postMealLow', 'postMealHigh']
      );
    }
  }

  /* istanbul ignore next */
  validateFields(values) {
    return new formValidationService.Validation()
      .eA1cTarget(values.eA1cTarget)
      .hypoHyperTargetRange('targetLow', values.targetLow, values.hypoLimit, values.hyperLimit)
      .hypoHyperTargetRange('targetHigh', values.targetHigh, values.hypoLimit, values.hyperLimit)
      .hypoHyperTargetRange('preMealLow', values.preMealLow, values.hypoLimit, values.hyperLimit)
      .hypoHyperTargetRange('preMealHigh', values.preMealHigh, values.hypoLimit, values.hyperLimit)
      .hypoHyperTargetRange('postMealLow', values.postMealLow, values.hypoLimit, values.hyperLimit)
      .hypoHyperTargetRange('postMealHigh', values.postMealHigh, values.hypoLimit, values.hyperLimit)
      .targetRange('hypoLimit', values.hypoLimit, 59, 109)
      .targetRange('hyperLimit', values.hyperLimit, 121, 301)
      .targetRange('targetLow', values.targetLow, 70, 100)
      .targetRange('targetHigh', values.targetHigh, 101, 200)
      .targetRange('preMealLow', values.preMealLow, 70, 100)
      .targetRange('preMealHigh', values.preMealHigh, 101, 200)
      .targetRange('postMealLow', values.postMealLow, 70, 100)
      .targetRange('postMealHigh', values.postMealHigh, 101, 200)
      .getErrors();
  }

  render() {
    const { initialValues } = this.props;

    return (
      <section className="c-section">
        <Link className="c-nav-header" to="/profile">
          <ChevronIcon />
          <h2 className="c-nav-header__label">Glucose Targets</h2>
        </Link>

        <Form
          initialValues={{
            ...initialValues,
            eA1cTarget: parseFloat(initialValues.eA1cTarget).toFixed(1)
          }}
          onSubmit={this.submit}
          validate={this.validateFields}
        >
          {({ handleSubmit, isActive }) => {
            return (
              <form
                name="glucoseTargetsForm"
                className="c-form"
                onSubmit={handleSubmit}
                noValidate
              >
                <WarnAboutUnsavedChanges />
                <fieldset className="c-fieldset">
                  <legend className="c-fieldset__legend h-left">Target Range</legend>
                  <div className="c-fieldset__row">
                    <div className="c-fieldset__field">
                      <Field
                        data-testid="targetLow"
                        component={MuiTextField}
                        label="Low"
                        name="targetLow"
                        type="text"
                        parse={normalizeInteger}
                      />
                    </div>
                    <span className="c-fieldset__break">to</span>
                    <div className="c-fieldset__field c-fieldset-icon">
                      <Field
                        data-testid="targetHigh"
                        className="c-fieldset__field"
                        component={MuiTextField}
                        label="High"
                        name="targetHigh"
                        type="text"
                        parse={normalizeInteger}
                      />
                      <span className="c-fieldset-icon__symbol">mg/dL</span>
                    </div>
                  </div>
                </fieldset>
                <legend className="c-fieldset__legend h-left">Low / High Alert Settings</legend>
                <div className="c-fieldset-icon">
                  <Field
                    data-testid="hypoLimit"
                    component={MuiTextField}
                    label="Low Limit"
                    name="hypoLimit"
                    type="text"
                    parse={normalizeInteger}
                  />
                  <span className="c-fieldset-icon__symbol">mg/dL</span>
                </div>
                <div className="c-fieldset-icon">
                  <Field
                    data-testid="hyperLimit"
                    component={MuiTextField}
                    label="High Limit"
                    name="hyperLimit"
                    type="text"
                    parse={normalizeInteger}
                  />
                  <span className="c-fieldset-icon__symbol">mg/dL</span>
                </div>
                <legend className="c-fieldset__legend h-left">A1C Target</legend>
                <div className="c-fieldset-icon">
                  <Field
                    data-testid="eA1cTarget"
                    component={MuiTextField}
                    label="A1C Target"
                    name="eA1cTarget"
                    step=".01"
                    type="text"
                    parse={normalizeFloat}
                  />
                  <span className="c-fieldset-icon__symbol">%</span>
                </div>

                <fieldset className="c-fieldset c-fieldset">
                  <legend className="c-fieldset__legend h-left">Pre Meal Target Range</legend>
                  <div className="c-fieldset__row">
                    <div className="c-fieldset__field">
                      <Field
                        data-testid="preMealLow"
                        className="c-fieldset__field"
                        component={MuiTextField}
                        label="Low"
                        name="preMealLow"
                        type="text"
                        parse={normalizeInteger}
                      />
                    </div>
                    <span className="c-fieldset__break">to</span>
                    <div className="c-fieldset__field c-fieldset-icon">
                      <Field
                        data-testid="preMealHigh"
                        className="c-fieldset__field"
                        component={MuiTextField}
                        label="High"
                        name="preMealHigh"
                        type="text"
                        parse={normalizeInteger}
                      />
                      <span className="c-fieldset-icon__symbol">mg/dL</span>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="c-fieldset c-fieldset--inline">
                  <legend className="c-fieldset__legend h-left">Post Meal Target Range</legend>
                  <div className="c-fieldset__row">
                    <div className="c-fieldset__field">
                      <Field
                        data-testid="postMealLow"
                        className="c-fieldset__field"
                        component={MuiTextField}
                        label="Low"
                        name="postMealLow"
                        type="text"
                        parse={normalizeInteger}
                      />
                    </div>
                    <span className="c-fieldset__break">to</span>
                    <div className="c-fieldset__field c-fieldset-icon">
                      <Field
                        data-testid="postMealHigh"
                        className="c-fieldset__field"
                        component={MuiTextField}
                        label="High"
                        name="postMealHigh"
                        type="text"
                        parse={normalizeInteger}
                      />
                      <span className="c-fieldset-icon__symbol">mg/dL</span>
                    </div>
                  </div>
                </fieldset>
                <input
                  className="c-button c-button--block c-button--action c-button--submit"
                  disabled={isActive}
                  type="submit"
                  value="Save"
                />
              </form>
            );
          }}
        </Form>
      </section>
    );
  }
}

export default GlucoseTargets;
