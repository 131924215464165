import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import EditTag from './EditTag';
import CountlyTrackedButton from '../countly/CountlyTrackedButton';
import warnAboutUnsavedChangesNoForm from '../../containers/warnAboutUnsavedChangesNoForm';
import isEqualWith from 'lodash/isEqualWith';
import { diffByIdOrTypeReading } from '../../utils/logBookFoods';

export class EditMoodTag extends Component {
  static propTypes = {
    disablePreventRouteLeave: PropTypes.func.isRequired,
    deleteReadingTag: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    postReadingTag: PropTypes.func.isRequired,
    putReadingTag: PropTypes.func.isRequired,
    preventRouteLeave: PropTypes.func.isRequired,
    selectedReading: PropTypes.shape({
      full_tags: PropTypes.array,
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    let tag;
    if (props.selectedReading) {
      tag = props.selectedReading.full_tags.find(t => t.type === 'mood');
    }
    this.state = {
      tag,
      value: tag ? tag.value : null
    };
  }

  componentDidMount() {
    if (!this.props.selectedReading) {
      this.props.history.replace({ pathname: '/log-book' });
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // This is for closing window if user clicks on other date
    if (
      !isEqualWith(prevProps.selectedReading, this.props.selectedReading, diffByIdOrTypeReading) ||
      !this.props.selectedReading
    ) {
      this.props.history.replace({
        pathname: '/log-book'
      });
    }

    if (prevState.value !== this.state.value) {
      this.props.preventRouteLeave();
    }
  }

  changeValue = (value) => {
    return () => {
      this.setState({ value });
    };
  }

  handleSave = (link) => {
    return async () => {
      const { selectedReading: { id }, history } = this.props;
      const tag = { type: 'mood', value: this.state.value };

      await this.props.disablePreventRouteLeave();

      if (this.state.tag) {
        tag.id = this.state.tag.id;

        this.props.putReadingTag(id, tag);
        history.push(link);
        return;
      }

      this.props.postReadingTag(id, tag);
      history.push(link);
    };
  }

  handleDelete = (link) => async () => {
    const { selectedReading: { id, full_tags: fullTags }, history } = this.props;
    const tag =  fullTags.find(el => el.type === 'mood');

    await this.props.disablePreventRouteLeave();

    await this.props.deleteReadingTag(id, tag.id, tag);
    history.push(link);
  }

  isOfTypeMood = el => el.type === 'mood';

  /*
   * Will return tag of type 'mood'
   */
  returnTag = () => {
    const { selectedReading } = this.props;
    return selectedReading && selectedReading.full_tags.find(this.isOfTypeMood);
  };

  isSubmitDisabled = () => {
    return this.state.value === null;
  }

  isDeleteDisabled = () => {
    const isTag = this.returnTag();

    if (!isTag) { return true; }

    return false;
  }

  render() {
    const { history } = this.props;
    const linkString = '/edit/mood';
    const backLink = history.location.pathname.slice(0, history.location.pathname.length - linkString.length);
    const moodTags = ['good', 'neutral', 'bad', 'sick', 'stressed'];

    return (
      <EditTag
        backLink={backLink}
        className="c-log__detail c-card"
        label="Mood"
      >
        <div className="c-card__body">
          <div className="h-center h-margin-bottom-lg">
            Tell us how you are feeling
            <br />
            around this reading.
          </div>
          {moodTags.map((tag, i) => {
            const selected = this.state.value === tag;
            const buttonClasses = classNames(
              'c-button',
              'c-button--block',
              'c-button--vertical',
              'h-capitalize',
              { 'c-button--hollow': !selected },
              { 'c-button--active': selected }
            );
            return (
              <button
                data-testid={`tag-${tag}`}
                className={buttonClasses}
                key={i}
                onClick={this.changeValue(tag)}
              >
                {tag}
              </button>
            );
          })}
        </div>
        <div className="h-padded">
          <CountlyTrackedButton
            dataTestid="update"
            countlyevent="update"
            disabled={this.isSubmitDisabled()}
            className="c-button c-button--block c-button--action h-margin-bottom-sm"
            onClick={this.handleSave(backLink)}
          >
            Save
          </CountlyTrackedButton>
          <CountlyTrackedButton
            dataTestid="delete"
            countlyevent="delete"
            disabled={this.isDeleteDisabled()}
            className="c-button c-button--block c-button--danger-hollow"
            onClick={this.handleDelete(backLink)}
          >
            Remove Data and Save
          </CountlyTrackedButton>
        </div>
      </EditTag>
    );
  }
}

export default warnAboutUnsavedChangesNoForm(EditMoodTag);
