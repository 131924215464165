import moment from 'moment';

export const mealTags = [
  'snack',
  'fasting',
  'pre_breakfast',
  'post_breakfast',
  'pre_lunch',
  'post_lunch',
  'pre_dinner',
  'post_dinner'
];

export const BG_AVERAGE_DISPLAY_MAX = 500;

export const BG_AVERAGE_DISPLAY_MIN = 20;

export const BG_DISPLAY_MAX = 501;

export const BG_DISPLAY_MIN = 19;

export function determineModifier(glucose, high, low) {
  let modifier = 'goal';

  if (high && glucose >= high) {
    modifier = 'warning';
  }

  if (low && glucose <= low) {
    modifier = 'danger';
  }

  return modifier;
}

export function isMealTag(tag) {
  return mealTags.includes(tag);
}

export function hasMealTag(tagArray) {
  const items = tagArray.filter(tag => isMealTag(tag));
  return items.length > 0;
}

export function mealTagNamePrettifier(mealTag) {
  if (!mealTag) { return null; }

  const mealTagNameArray = mealTag.split('_');

  if (mealTagNameArray[0] === 'pre') {
    mealTagNameArray[0] = 'before';
  } else if (mealTagNameArray[0] === 'post') {
    mealTagNameArray[0] = 'after';
  } else if (mealTag === 'snack') {
    mealTagNameArray[0] = 'snack';
  } else {
    mealTagNameArray[0] = 'fasting';
  }

  return mealTagNameArray.join(' ');
}

export function normalizeBloodGlucoseReading(reading) {
  return reading.update('bloodGlucose', (bg) => {
    if (bg > BG_DISPLAY_MAX) {
      return BG_DISPLAY_MAX;
    } else if (bg < BG_DISPLAY_MIN) {
      return BG_DISPLAY_MIN;
    }

    return bg;
  });
}

export function setMealMarker(tags) {
  const mealTag = tags && tags.find(tag => isMealTag(tag));

  if (!mealTag) {
    return 'NO_TAG';
  }
  if (mealTag.includes('pre')) {
    return 'BEFORE';
  }
  if (mealTag.includes('post')) {
    return 'AFTER';
  }
  if (mealTag.includes('fasting')) {
    return 'FASTING';
  }
  if (mealTag.includes('snack')) {
    return 'SNACK';
  }

  // all meal tags include either pre, post, or fasting so we should never get here
}

export function setReadingColor(bg, targets, colors) {
  if (bg <= targets.low) {
    return colors.red;
  }

  if (bg >= targets.high) {
    return colors.yellow;
  }

  return colors.green;
}

export function timeframeFilter(timeframe) {
  return function (reading) {
    const ts = moment(reading.get('timestamp')).utc();

    return (
      ts.isSameOrAfter(timeframe.start) && ts.isSameOrBefore(timeframe.end)
    );
  };
}

export function sortByTimestampAndId(reading1, reading2) {
  if (new Date(reading1.timestamp).getTime() > new Date(reading2.timestamp).getTime()) { return -1; }
  if (new Date(reading1.timestamp).getTime() < new Date(reading2.timestamp).getTime()) { return 1; }
  if (reading1.id > reading2.id) { return -1; }
  if (reading1.id < reading2.id) { return 1; }
}
