import apiService from './apiService';

async function getSharedWithMeContacts(searchQuery = '') {
  try {
    const { data } = await apiService.get(`/v2/contacts/shared_with_me?${searchQuery}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getIAmSharingWithContacts(searchQuery = '') {
  try {
    const { data } = await apiService.get(`/v2/contacts/sharing_with?${searchQuery}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function deleteYourSharingCircle(relationId) {
  try {
    const { data } = await apiService.delete(`/relationships/${relationId}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function deleteYourPractice(practiceId) {
  try {
    const { data } = await apiService.delete(`/rpm/medical_practices/${practiceId}/patients`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function deleteSentInvitation(invitationId) {
  try {
    const { data } = await apiService.delete(`/v2/invitations/${invitationId}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function sendInvitationReminder(invitationId) {
  try {
    const { data } = await apiService.post(`/v2/contacts/${invitationId}/invitation_send_reminder`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function acceptInvitationFromPractice(invitationId) {
  try {
    const { data } = await apiService.post(`/my_practices/invitations/${invitationId}/accept`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function resendInvitationToPractice(invitationId) {
  try {
    const { data } = await apiService.post(`/my_practices/invitations/${invitationId}/resend`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function editIAmSharingWithContactsInfo(memberProfileId, formData) {
  try {
    const { data } = await apiService.put(`/scoped_by_patient_carer_profiles/${memberProfileId}`, {
      ...formData
    });
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function getAboutContactInfo(profileId) {
  try {
    const { data } = await apiService.get(`/v2/contacts/sharing_with/${profileId}`);
    return data;
  } catch (err) {
    throw err.response && err.response.data;
  }
}

export default {
  getAboutContactInfo,
  editIAmSharingWithContactsInfo,
  resendInvitationToPractice,
  acceptInvitationFromPractice,
  sendInvitationReminder,
  deleteSentInvitation,
  deleteYourPractice,
  deleteYourSharingCircle,
  getSharedWithMeContacts,
  getIAmSharingWithContacts
};
