/* istanbul ignore file */
import React from 'react';

function LogLoading() {
  return (
    <div className="c-card-list">
      <div className="c-card">
        <div className="c-card__header c-card__header--xs c-card__header--borderless c-card__header--spaced">
          <h3 className="c-card__title c-card__title--placeholder" />
        </div>
        <ul className="c-log-list">
          <li className="c-log">
            <span className="c-time c-time--placeholder" />
            <span className="c-reading c-reading--placeholder" />
          </li>
          <li className="c-log">
            <span className="c-time c-time--placeholder" />
            <span className="c-reading c-reading--placeholder" />
          </li>
        </ul>
      </div>
      <div className="c-card">
        <div className="c-card__header c-card__header--xs c-card__header--borderless c-card__header--spaced">
          <h3 className="c-card__title c-card__title--placeholder" />
        </div>
        <ul className="c-log-list">
          <li className="c-log">
            <span className="c-time c-time--placeholder" />
            <span className="c-reading c-reading--placeholder" />
          </li>
          <li className="c-log">
            <span className="c-time c-time--placeholder" />
            <span className="c-reading c-reading--placeholder" />
          </li>
          <li className="c-log">
            <span className="c-time c-time--placeholder" />
            <span className="c-reading c-reading--placeholder" />
          </li>
          <li className="c-log">
            <span className="c-time c-time--placeholder" />
            <span className="c-reading c-reading--placeholder" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LogLoading;
