import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import dayjs from 'dayjs';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelectField from '../muiComponents/MuiSelectField';
import MuiTextField from '../muiComponents/MuiTextField';
import MuiDatePicker from '../muiComponents/MuiDatePicker';
import AvatarFieldContainer from 'src/containers/AvatarFieldContainer';
import { normalizePhone } from 'src/services/fieldNormalizationService';
import formValidationService from 'src/services/formValidation';
import { handleFormSubmissionErrors } from 'src/services/errorHandlingService';
import { registrationOnboardEvent } from 'src/services/countlyRegistrationTrackService';

export class OnboardMe extends Component {
  static propTypes = {
    getAboutMe: PropTypes.func.isRequired,
    getAvatar: PropTypes.func.isRequired,
    getStatus: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    putAboutMe: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.mountTime = dayjs();
    this.props.getAvatar();
  }

  submit = async (values) => {
    const formValues = { ...values, diabetesType: Number(values.diabetesType) };

    try {
      await this.props.putAboutMe(formValues, 'patient', true);
      // Countly funnel registration
      registrationOnboardEvent(this.mountTime);

      this.props.getAboutMe(); // no need to wait on this
      this.props.getStatus();
      this.props.history.push('/onboarding/pogo-meter'); // go to next page
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        ['salutation', 'firstName', 'lastName', 'dateOfBirth', 'diabetesType', 'gender']
      );
    }
  }

  /* istanbul ignore next */
  validateFields(values) {
    return new formValidationService.Validation()
      .cellNumberRequired(values.cellNumber)
      .dateOfBirth(values.dateOfBirth)
      .gender(values.gender)
      .salutation(values.salutation)
      .diabetesType(values.diabetesType)
      .firstName(values.firstName)
      .lastName(values.lastName)
      .getErrors();
  }

  render() {
    return (
      <div className="c-card">
        <AvatarFieldContainer />

        <Form
          initialValues={this.props.initialValues}
          onSubmit={this.submit}
          validate={this.validateFields}
        >
          {({ handleSubmit, valid, submitting, hasSubmitErrors, pristine }) => {
            return (
              <form
                name="onboardme"
                className="c-form c-card__body"
                onSubmit={handleSubmit}
              >
                <Field name="salutation" component={MuiSelectField} label="Name Prefix" className="h-mui-native__select">
                  <MenuItem value="Dr.">Dr.</MenuItem>
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Miss">Miss</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="No answer">No Answer</MenuItem>
                </Field>
                <Field
                  component={MuiTextField}
                  label="First Name"
                  name="firstName"
                />
                <Field
                  component={MuiTextField}
                  label="Last Name"
                  name="lastName"
                />
                <Field
                  component={MuiDatePicker}
                  floatingLabelText="DOB*"
                  name="dateOfBirth"
                  openToYearSelection
                  id="uniqueid" // This is to fix warning explained here https://github.com/mui-org/material-ui/issues/4659
                />
                <p className="h-center h-padded">*Date of Birth is used to verify your identity with your Healthcare Provider when sharing your data.</p>
                <Field name="gender" component={MuiSelectField} label="Gender" className="h-mui-native__select">
                  <MenuItem value="m">Male</MenuItem>
                  <MenuItem value="f">Female</MenuItem>
                  <MenuItem value="n">No Answer</MenuItem>
                </Field>
                <Field
                  component={MuiTextField}
                  label="Cell Phone Number"
                  name="cellNumber"
                  parse={normalizePhone}
                  placeholder="Cell Number"
                />
                <Field name="diabetesType" component={MuiSelectField} label="Diabetes Type" className="h-mui-native__select">
                  <MenuItem value={1}>Type 1</MenuItem>
                  <MenuItem value={2}>Type 2</MenuItem>
                  <MenuItem value={3}>Prediabetes</MenuItem>
                  <MenuItem value={4}>Gestational</MenuItem>
                </Field>
                <input
                  className="c-button c-button--block c-button--action c-button--submit"
                  disabled={(!valid && !hasSubmitErrors) || pristine || submitting}
                  type="submit"
                  value="Next"
                />
              </form>
            );
          }}
        </Form>
      </div>
    );
  }
}

export default OnboardMe;
