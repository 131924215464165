import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function CircleIcon({ className }) {
  let classes = 'c-icon c-icon--circle';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <circle className="c-icon__fill" cx="12" cy="12" r="6" />
    </svg>
  );
}

CircleIcon.propTypes = propTypes;

export default CircleIcon;
