/* istanbul ignore file */
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import OnboardMe from 'src/components/onboard/OnboardMe';
import { getAvatar } from 'src/actions/avatarActions';
import { getAboutMe, putAboutMe } from 'src/actions/profileActions';
import { getStatus } from 'src/actions/statusActions';

dayjs.extend(utc);

function mapStateToProps(state) {
  return {
    initialValues: {
      salutation: state.profile.get('salutation') || undefined,
      firstName: state.profile.get('firstName'),
      gender: state.profile.get('gender') || undefined,
      lastName: state.profile.get('lastName'),
      dateOfBirth: state.profile.get('dateOfBirth') || dayjs().format('MM/DD/YYYY'),
      cellNumber: state.profile.get('cellNumber'),
      diabetesType: state.profile.get('diabetesType') || undefined
    }
  };
}

export default connect(mapStateToProps, {
  getAboutMe,
  getAvatar,
  getStatus,
  putAboutMe
})(OnboardMe);
