/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
  transform: PropTypes.string
};

function ShapeNoTag(props) {
  return (
    <g
      id="shape-legend"
      {...props}
    >
      <rect x="6" y="10.5" rx="1" height="3" width="12" />
      <rect x="10.5" y="6" rx="1" height="12" width="3" />
    </g>
  );
}

ShapeNoTag.propTypes = propTypes;

export default ShapeNoTag;
