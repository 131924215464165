import React from 'react';

export default () => {
  return (
    <svg className="c-nav-tab__icon c-icon" viewBox="0 0 24 24">
      <g transform="translate(1, 3)">
        <path d="M2.11,5.83A6.19,6.19,0,0,1,.68,4.41,2.87,2.87,0,0,1,0,2.73,2.46,2.46,0,0,1,.51,1.14,1.73,1.73,0,0,1,2,.5H20a1.88,1.88,0,0,1,1.38.58A1.85,1.85,0,0,1,22,2.46a3.26,3.26,0,0,1-.6,1.86,5.82,5.82,0,0,1-1.5,1.51q-4.62,3.2-5.74,4l-.53.37c-.26.2-.48.35-.66.47l-.64.4a3.35,3.35,0,0,1-.7.33,2.08,2.08,0,0,1-.62.11h0a2.08,2.08,0,0,1-.62-.11,3.35,3.35,0,0,1-.7-.33L9,10.66c-.18-.12-.4-.27-.66-.47l-.53-.37C7.1,9.29,6,8.55,4.63,7.58Z" />
        <path d="M20.77,7.43a6.63,6.63,0,0,0,1.23-1v9.48a1.79,1.79,0,0,1-.58,1.35,1.88,1.88,0,0,1-1.38.57H2a1.88,1.88,0,0,1-1.38-.57A1.79,1.79,0,0,1,0,15.87V6.39a6.28,6.28,0,0,0,1.24,1c3,2,5,3.33,6.1,4.12q.71.51,1.14.78a6.9,6.9,0,0,0,1.16.58A3.69,3.69,0,0,0,11,13.2h0a3.69,3.69,0,0,0,1.35-.29,6.9,6.9,0,0,0,1.16-.58q.44-.27,1.14-.78Q16.75,10.08,20.77,7.43Z" />
      </g>
    </svg>
  );
};

