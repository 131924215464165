/* istanbul ignore file */
import { connect } from 'react-redux';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import LogDetail from '../components/logs/LogDetail';
import { makeGetSelectedReading } from '../selectors/readingTagsSelectors';

/* istanbul ignore next */
function makeMapStateToProps() {
  const getSelectedReading = makeGetSelectedReading();

  return function (state, props) {
    return {
      isSharingCircleView: !!state.selectedPatient.get('patientId'),
      patientTargets: state.settings.get('patientTargets'),
      selectedReading: getSelectedReading(state, props)
    };
  };
}

export default connect(makeMapStateToProps)(immutableToJsHOC(LogDetail));
