import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { scaleLinear, scaleTime } from 'd3-scale';
import { line } from 'd3-shape';

// Records
import TimeframeRecord from '../../records/TimeframeRecord';
import withViewportWidth from '../../containers/withViewportWidth';
import DashboardGlucoseSummary from './DashboardGlucoseSummary';
import DashboardGraphData from './DashboardGraphData';
import DashboardGraphMessageGroup from './DashboardGraphMessageGroup';
import XAxis from './XAxis';
import YAxis from './YAxis';
import DropShadowFilter from './defs/DropShadowFilter';
import Legend from './Legend';
import LinearGradientFilter from './defs/LinearGradientFilter';
import SelectedReading from './SelectedReading';
import { determineModifier } from '../../utils/readingHelpers';
import { setCompYDomain, yDomain } from '../../utils/trendHelpers';
import graphLine from '../../utils/graphLine';
import styleVars, { bold, p, sv, xAxisLabel, yAxisLabel } from '../../assets/css/1_settings/variables.js';
import withTimeFrame from '../../containers/withTimeFrame';

const propTypes = {
  comparisonValues: PropTypes.array,
  comparisonUnit: PropTypes.string,
  filteredReadings: PropTypes.array,
  goalsPerDay: PropTypes.number,
  hasAnyReadings: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSharingCircleView: PropTypes.bool.isRequired,
  patientTargets: PropTypes.shape({
    targetHigh: PropTypes.number,
    targetLow: PropTypes.number
  }),
  previousReadings: PropTypes.array,
  selectedReading: PropTypes.object,
  setSelectedReadingId: PropTypes.func.isRequired,
  timeframe: PropTypes.instanceOf(TimeframeRecord),
  width: PropTypes.number,
  windowWidth: PropTypes.number.isRequired
};

function DashboardGraphSVG(props) {
  const {
    comparisonValues,
    comparisonUnit,
    filteredReadings,
    goalsPerDay = 3,
    hasAnyReadings = false,
    isMobile,
    isSharingCircleView,
    patientTargets,
    previousReadings,
    selectedReading,
    setSelectedReadingId,
    timeframe,
    width = 900,
    windowWidth
  } = props;
  const graphContainer = {
    height: 440,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    width: width || 900
  };
  const svgContainer = {
    height: isMobile ?
      graphContainer.height + 416 + sv.spacing.md :
      graphContainer.height + 256 + sv.spacing.md,
    width: width || 900
  };
  const dropShadowID = 'dropShadow';
  const linearGradientID = 'linearGradient';

  const low = (patientTargets && patientTargets.targetLow) || 80;
  const high = (patientTargets && patientTargets.targetHigh) || 180;

  let selected = null;

  if (selectedReading) {
    selected = selectedReading;
    selected.modifier = determineModifier(selected.bloodGlucose, high, low);
    selected.color = sv.color[sv.theme[selected.modifier]];
  }

  // Set default left and right spacing
  let leftRightSpacing = sv.spacing.sm;

  if (width > 700 && width < 980) {
    leftRightSpacing = sv.spacing.md;
  } else if (width >= 980) {
    leftRightSpacing = sv.spacing.xl;
  }

  const margin = {
    bottom: sv.spacing.xl + sv.spacing.md,
    left: leftRightSpacing,
    right: leftRightSpacing,
    top: sv.spacing.xxl
  };

  // Set Ranges
  const inner = {
    width: graphContainer.width - margin.left - margin.right,
    height: graphContainer.height - margin.top - margin.bottom
  };

  // Set Domains
  const xDomain = [moment(timeframe.start).utc(), moment(timeframe.end).utc()];
  const compYDomain = setCompYDomain(comparisonValues, comparisonUnit);

  // Set Scales
  const xScale = scaleTime().domain(xDomain).range([0, inner.width]);
  const yScale = scaleLinear().domain(yDomain).range([inner.height, 0]);
  const compYScale = scaleLinear().domain(compYDomain).range([inner.height, 0]);

  // Set Ticks
  const xAxis = timeframe.makeAxis(xScale, inner.width);
  const yAxis = {
    ticks: [
      { y: yScale(low), label: low, color: sv.color.red, type: 'low' },
      { y: yScale(high), label: high, color: sv.color.yellow, type: 'high' }
    ]
  };

  // Set trend path
  const trendPath = line().x(d => xScale(d.x)).y(d => yScale(d.y));

  const hasComparisonValues = comparisonValues && comparisonValues.filter(value => typeof value[comparisonUnit] !== 'undefined').length > 0;

  return (
    <svg id="graph" style={{ ...graphContainer, height: svgContainer.height }}>
      <defs>
        <DropShadowFilter filterID={dropShadowID} />
        <LinearGradientFilter filterID={linearGradientID} />
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="goal-gradient">
          <stop stopColor="#79B400" offset="0%" />
          <stop stopColor="#8fd600" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="goal-gradient-inverse">
          <stop stopColor="#8fd600" offset="0%" />
          <stop stopColor="#79B400" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="warning-gradient">
          <stop stopColor="#FFA600" offset="0%" />
          <stop stopColor="#fdb62c" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="warning-gradient-inverse">
          <stop stopColor="#fdb62c" offset="0%" />
          <stop stopColor="#FFA600" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="danger-gradient">
          <stop stopColor="#db2815" offset="0%" />
          <stop stopColor="#e74113" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="danger-gradient-inverse">
          <stop stopColor="#e74113" offset="0%" />
          <stop stopColor="#db2815" offset="100%" />
        </linearGradient>
      </defs>
      <rect
        fill={`url(#${linearGradientID})`}
        height={graphContainer.height + sv.spacing.md}
        width={graphContainer.width}
        x="0"
        y="0"
      />
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <XAxis
          innerHeight={inner.height}
          sv={sv}
          xAxis={xAxis}
          xAxisLabel={xAxisLabel}
        />

        {(filteredReadings.length || hasComparisonValues) && compYScale && (
          <g>
            {comparisonValues && comparisonValues.map((day, i) => {
              if (day[comparisonUnit] || day[comparisonUnit] === 0) {
                const isZero = day[comparisonUnit] === 0;
                const compyYHeight = compYScale(compYDomain[0]) - compYScale(day[comparisonUnit]);

                return (
                  <rect
                    key={i}
                    x={xScale(day.start) + 2}
                    y={isZero ? inner.height - 2 : compYScale(day[comparisonUnit]) + 5}
                    width={xScale(day.end) - xScale(day.start) - 4}
                    height={isZero ? 2 : compyYHeight}
                    fill={sv.color.textLight}
                  />
                );
              }

              return null;
            })}
          </g>
        )}
        {filteredReadings.length &&
          <path
            d={trendPath(graphLine(filteredReadings.sort((a, b) => a.x - b.x), moment(timeframe.start).isSame(moment(timeframe.end), 'day')))}
            stroke={sv.color.active}
            strokeWidth="2"
            fill="none"
          />}
        {selected &&
          <SelectedReading
            innerHeight={inner.height}
            innerWidth={inner.width}
            leftRightSpacing={leftRightSpacing}
            isSharingCircleView={isSharingCircleView}
            selectedReading={selected}
            sv={sv}
            width={width}
            xScale={xScale}
            yScale={yScale}
          />}
        {filteredReadings.length &&
          <DashboardGraphData
            innerHeight={inner.height}
            innerWidth={inner.width}
            leftRightSpacing={leftRightSpacing}
            readingVals={filteredReadings}
            setSelectedReadingId={setSelectedReadingId}
            selectedReading={selectedReading}
            sv={sv}
            width={width}
            windowWidth={windowWidth}
            xScale={xScale}
            yScale={yScale}
          />}
        <YAxis
          innerWidth={inner.width}
          sv={sv}
          xAxisLabel={xAxisLabel}
          yAxis={yAxis}
          yAxisLabel={yAxisLabel}
        />
      </g>
      {!filteredReadings.length && !hasComparisonValues && (
        <DashboardGraphMessageGroup
          bold={bold}
          container={graphContainer}
          filterID={dropShadowID}
          hasAnyReadings={hasAnyReadings}
          p={p}
          sv={sv}
        />
      )}
      <Legend
        inner={inner}
        isMobile={isMobile}
        p={p}
        sv={sv}
        transform={`translate(${margin.left}, ${graphContainer.height - sv.spacing.md * 1.25})`}
      />
      <DashboardGlucoseSummary
        goalsPerDay={goalsPerDay}
        graphContainer={graphContainer}
        isMobile={graphContainer.width < 720}
        containerHeight={graphContainer.height}
        containerWidth={graphContainer.width}
        filterID={dropShadowID}
        highGlucose={high}
        lowGlucose={low}
        prevVals={previousReadings}
        readingVals={filteredReadings}
        styleVars={styleVars}
        xDomain={xDomain}
      />
    </svg>
  );
}

DashboardGraphSVG.propTypes = propTypes;
export default withViewportWidth(withTimeFrame(DashboardGraphSVG));
