/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

import GlucoseSummaryRow from './GlucoseSummaryRow';

const propTypes = {
  direction: PropTypes.string,
  label: PropTypes.string.isRequired,
  styleVars: PropTypes.shape({
    sv: PropTypes.object.isRequired
  }).isRequired,
  transform: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired
};

function PercentGoalCompletedRow(props) {
  const { direction, label, styleVars, transform, value, width } = props;
  const { sv } = styleVars;
  const rectHeight = sv.spacing.sm;
  const rectRx = rectHeight / 2;
  let progressWidth = value > 100 ? width : value / 100 * width;
  progressWidth = isNaN(progressWidth) ? 0 : progressWidth;

  return (
    <g className="c-percent-goal-complete" transform={transform}>
      <g className="c-progress-bar">
        <rect
          x={0}
          width={width}
          y={0}
          height={rectHeight}
          fill={sv.color.textLight}
          rx={rectRx}
        />
        <rect
          className="c-progress-bar__filled"
          x={0}
          width={progressWidth}
          y={0}
          height={rectHeight}
          fill={sv.color.primaryColor}
          rx={rectRx}
        />
      </g>
      <GlucoseSummaryRow
        direction={direction}
        inResultsSection
        label={label}
        styleVars={styleVars}
        transform={`translate(0, ${sv.spacing.lg * 1.25})`}
        units="%"
        value={value}
        width={width}
      />
    </g>
  );
}

PercentGoalCompletedRow.propTypes = propTypes;

export default PercentGoalCompletedRow;
