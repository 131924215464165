/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from './Header';
import DashboardContainer from 'src/containers/DashboardContainer';
import HealthfeedContainer from 'src/containers/HealthfeedContainer';
import LogBookContainer from 'src/containers/LogBookContainer';
import MHealthContainer from 'src/containers/MHealthContainer';
import ProfileContainer from 'src/containers/ProfileContainer';
import TrendsContainer from 'src/containers/TrendsContainer';
import CountlyTrackedRoute from './countly/CountlyTrackedRoute';
import Contacts from 'src/pages/contacts/Contacts';
import AppStartStatusReviewerContainer from 'src/containers/AppStartStatusReviewerContainer';
import { PAGE_NAMES } from 'src/constants/routesCountlyNameConstants';

const propTypes = {
  avatar: PropTypes.string
};

export default function PatientApp(props) {
  const { avatar } = props;

  return (
    <div>
      <Header avatar={avatar}/>

      <Switch>
        <Route path="/log-book" component={LogBookContainer}/>
        <Route path="/contacts" component={Contacts}/>
        <Route path="/mhealth/:type" component={MHealthContainer}/>
        <Route path="/profile" component={ProfileContainer}/>
        <CountlyTrackedRoute pageGroup="Dashboard" pageName={PAGE_NAMES.DASHBOARD} path="/dashboard" component={DashboardContainer}/>
        <CountlyTrackedRoute pageGroup="Trends" pageName={PAGE_NAMES.TRENDS} path="/trends" component={TrendsContainer}/>
        <CountlyTrackedRoute pageGroup="General" pageName={PAGE_NAMES.HEALTHFEED} path="/healthfeed" component={HealthfeedContainer}/>
        <Redirect to="/dashboard"/>
      </Switch>

      <AppStartStatusReviewerContainer/>
    </div>
  );
}

PatientApp.propTypes = propTypes;
