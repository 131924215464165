import React from 'react';
import { mediaQuery } from 'src/utils/svgHelpers';

export { mediaQuery };

export default function fullViewportHOC(WrappedComponent) {
  return class extends React.Component {
    state = {
      isMobile: window.innerWidth < 980,
      width: mediaQuery(window.innerWidth),
      windowWidth: window.innerWidth
    };

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      this.setState({
        isMobile: window.innerWidth < 980,
        width: mediaQuery(window.innerWidth),
        windowWidth: window.innerWidth,
        height: window.innerHeight
      });
    }

    render() {
      return (
        <WrappedComponent
          isMobile={this.state.isMobile}
          width={this.state.width}
          windowWidth={this.state.windowWidth}
          {...this.props}
        />
      );
    }
  };
}
