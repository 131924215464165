import PropTypes from 'prop-types';
import React from 'react';
import CheckCircle from './CheckCircle';

const propTypes = {
  checked: PropTypes.bool
};

export default function CheckCircleStatus({ checked }) {
  return  <CheckCircle checked={checked} />;
}

CheckCircleStatus.propTypes = propTypes;
