import dayjs from 'dayjs';

export function getTimeDiff(mountTime) {
  const now = dayjs();
  const durationValue = now.diff(mountTime, 'seconds');
  // Duration as seconds
  return durationValue;
}

export function getPageNameFromStore(state) {
  return state.countly.get('pageName');
}

export function getPageGroupFromStore(state) {
  return state.countly.get('pageGroup');
}

export function getUserEmailAddressFromStore(state) {
  return state.countly.get('userEmailAddress');
}
