import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ChevronIcon from '../icons/ChevronIcon';
import MuiToggle from '../muiComponents/MuiToggle';
import WarnAboutUnsavedChanges from '../../containers/warnAboutUnsavedChanges';

class SharingCircleNotificationSettings extends React.Component {
  static propTypes = {
    getNotificationSettings: PropTypes.func.isRequired,
    notificationScope: PropTypes.string.isRequired,
    putNotificationSettings: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    initialValues: PropTypes.object
  }

  componentDidMount() {
    this.props.getNotificationSettings(this.props.notificationScope);
  }

  submit = async (formValues) => {
    try {
      await this.props.putNotificationSettings({ ...formValues }, this.props.notificationScope);
      this.props.getNotificationSettings(this.props.notificationScope);
      this.props.history.push('/sharing-circle/profile');
    } catch (err) {
      return { 'addCircleEvents.email': err.message };
    }
  }

  render() {
    return (
      <section className="c-section">
        <Link className="c-nav-header" to="/sharing-circle/profile">
          <ChevronIcon />
          <h2 className="c-nav-header__label">Notification Settings</h2>
        </Link>

        <Form
          initialValues={this.props.initialValues}
          onSubmit={this.submit}
          validate={this.validateFields}
        >
          {({ handleSubmit, isActive }) => {
            return (
              <form
                name="SCNotifications"
                className="c-form"
                onSubmit={handleSubmit}
              >
                <WarnAboutUnsavedChanges />
                <div className="c-form-section">
                  <div className="c-form-section__heading">
                    <h3 className="c-form-section__title">Invited to a Sharing Circle</h3>
                  </div>
                  <div className="c-list-item">
                    <span className="h-bold">Email</span>
                    <Field
                      type="checkbox"
                      component={MuiToggle}
                      name="addCircleEvents.email"
                    />
                  </div>
                </div>

                <input
                  className="c-button c-button--block c-button--action c-button--submit"
                  disabled={isActive}
                  type="submit"
                  value="Save"
                />
              </form>
            );
          }}
        </Form>
      </section>
    );
  }
}

export default SharingCircleNotificationSettings;
