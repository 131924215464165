import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, Grid, Paper } from '@material-ui/core';
import logoLoader from 'src/assets/img/loader/Logo.svg';
import { useStyles } from './Loader.styles';

const propTypes = {
  isEnabled: PropTypes.bool
};

function Loader(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.isEnabled}
      scroll="paper"
      data-testid="dialog-invites"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      PaperComponent={Paper}
      className={classes.root}
      PaperProps={{ elevation: 0, classes: { root: classes.paper } }}
      BackdropProps={{ classes: { root: classes.backDrop } }}
    >
      <Grid container className={classes.container} justifyContent="center">
        <img src={logoLoader} className={classes.logo}/>
      </Grid>
    </Dialog>
  );
}

Loader.propTypes = propTypes;
export default Loader;
