/* istanbul ignore file */
import { combineReducers } from 'redux';
// Reducers
import activeMealTabReducer from './activeMealTabReducer';
import authenticationReducer from './authenticationReducer';
import avatarReducer from './avatarReducer';
import contactsIAmSharingWithInvitationsReducer from './contactsIAmSharingWithInvitationsReducer';
import contactsSharedWithMeInvitationsReducer from './contactsSharedWithMeInvitationsReducer';
import contactsReducer from './contactsReducer';
import contactsInvitesReducer from './contactsInvitesReducer';
import countlyReducer from './countlyReducer';
import emergencyEventReducer from './emergencyEventReducer';
import healthDataReducer from './healthDataReducer';
import healthfeedLoadReducer from './healthfeedLoadReducer';
import incomingInvitationReducer from './incomingInvitationReducer';
import invitationReducer from './invitationReducer';
import mealsReducer from './mealsReducer';
import nutritionixReducer from './nutritionixReducer';
import profileReducer from './profileReducer';
import readingsReducer from './readingsReducer';
import readingTagsReducer from './readingTagsReducer';
import selectedPatientReducer from './selectedPatientReducer';
import settingsReducer from './settingsReducer';
import snackbarReducer from './snackbarReducer';
import statusReducer from './statusReducer';
import timeframeReducer from './timeframeReducer';
import tokenReducer from './tokenReducer';
import validicReducer from './validicReducer';
import screensSeenStatusReducers from './screensSeenStatusReducers';
import deviceListReducer from './deviceListReducer';

// For those who saw persistCombineReducers and is confused
// which one to use persistCombineReducers or combineReducers
// persistCombineReducers is a combination of redux combineReducers and persistReducer.
export default combineReducers({
  activeMealTab: activeMealTabReducer,
  authentication: authenticationReducer,
  avatar: avatarReducer,
  contacts: contactsReducer,
  contactsInvites: contactsInvitesReducer,
  contactsIAmSharingWithInvitations: contactsIAmSharingWithInvitationsReducer,
  contactsSharedWithMeInvitations: contactsSharedWithMeInvitationsReducer,
  countly: countlyReducer,
  emergencyEvent: emergencyEventReducer,
  healthData: healthDataReducer,
  healthfeedLoad: healthfeedLoadReducer,
  incomingInvitation: incomingInvitationReducer,
  invitations: invitationReducer,
  readings: readingsReducer,
  readingTags: readingTagsReducer,
  meals: mealsReducer,
  nutritionix: nutritionixReducer,
  profile: profileReducer,
  selectedPatient: selectedPatientReducer,
  deviceList: deviceListReducer,
  settings: settingsReducer,
  snackbar: snackbarReducer,
  status: statusReducer,
  timeframe: timeframeReducer,
  token: tokenReducer,
  validic: validicReducer,
  screensSeenStatus: screensSeenStatusReducers
});
