/* istanbul ignore file */
import { connect } from 'react-redux';
import { reportOptions } from '../services/timeframeService';
import ExportReport from '../components/ExportReport';

function mapStateToProps(state) {
  return {
    from: state.timeframe.get('start'),
    options: reportOptions,
    to: state.timeframe.get('end')
  };
}

export default connect(mapStateToProps)(ExportReport);
