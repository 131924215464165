/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InvitationBar from 'src/components/contacts/InvitationBar';
import routeConstants from 'src/constants/routes';
import Text from 'src/shared/Text';

const propTypes = {
  isPatient: PropTypes.bool,
  getSharedWithInvitationsCount: PropTypes.func.isRequired,
  receivedInvitations: PropTypes.shape({
    avatarUrls: PropTypes.array,
    pending: PropTypes.number,
    phone_not_verified: PropTypes.number
  })
};

function SharedWithMeInvitesCount({
  getSharedWithInvitationsCount,
  receivedInvitations
}) {
  const { CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS } = routeConstants;

  useEffect(() => {
    getSharedWithInvitationsCount();
  }, []);

  if (!receivedInvitations || (receivedInvitations.pending <= 0 && receivedInvitations.phone_not_verified <= 0)) {
    return null;
  }

  return (
    <InvitationBar
      avatarList={null}
      content={
        <Fragment>
          <Text font="bold" fontSize={18} lineHeight="24" alignText="center">{receivedInvitations.pending + receivedInvitations.phone_not_verified} Received Invitations</Text>
          <Text fontSize={14} alignText="center">Click to Accept or Decline Invitations</Text>
        </Fragment>
      }
      inviteLink={
        <Link to={CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS} className="h-content">
          <strong>Go to Received Invitations {'>'}</strong>
        </Link>
      }
      color="blue"
    />
  );
}

SharedWithMeInvitesCount.propTypes = propTypes;
export default SharedWithMeInvitesCount;
