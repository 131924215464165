/* istanbul ignore file */
import { connect } from 'react-redux';

// Actions
import { setNutritionixSelectedFood } from '../actions/nutritionixActions';

// Components
import FoodsListPresenter from '../components/logs/FoodsListPresenter';

export default connect(null, { setNutritionixSelectedFood })(FoodsListPresenter);
