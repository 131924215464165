/* istanbul ignore file */
import { connect } from 'react-redux';
import moment from 'moment';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import HypoTrendNotificator from '../components/HypoTrendNotificator';

const mapStateToProps = state => ({
  hypoTrendReadings: state.status.get('hypoTrendReadings').sort((a, b) => moment(b.get('timestamp')) - moment(a.get('timestamp')))
});

export default connect(mapStateToProps, {})(immutableToJsHOC(HypoTrendNotificator));
