/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TestingSchedule from '../components/TestingSchedule';
import WithOnboardingTracking from './withOnboardingTracking';

function TestingScheduleContainer(props) {
  return (
    <WithOnboardingTracking type="testing">
      <TestingSchedule {...props} />
    </WithOnboardingTracking>
  );
}

const mapStateToProps = state => {
  const testingTimes = state.settings.get('testingTimes') ? state.settings.get('testingTimes').toJS() : [];
  return {
    isActive: state.settings.get('isActive'),
    testingTimes: testingTimes.map(time => ({
      ...time,
      endTime: new Date(time.endTime),
      startTime: new Date(time.startTime)
    }))
  };
};

export default connect(mapStateToProps)(withRouter(TestingScheduleContainer));
