/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  innerWidth: PropTypes.number.isRequired,
  sv: PropTypes.object,
  units: PropTypes.string,
  yAxis: PropTypes.shape({
    label: PropTypes.number,
    ticks: PropTypes.array,
    type: PropTypes.string
  }).isRequired,
  yAxisLabel: PropTypes.object
};

function YAxis({ innerWidth, sv, units = 'mg/dL', yAxis, yAxisLabel }) {
  return (
    <g id="y-ticks" style={{ pointerEvents: 'none' }}>
      {yAxis.ticks.map((tick, i) => {
        const labelLength = tick.label.toString().length;
        const unitsLength = units ? units.toString().length : 0;
        const length = labelLength + unitsLength;
        const additionalPadding = units === '%' ? sv.spacing.md : 0;
        const width = length * 10 + additionalPadding + sv.spacing.sm;
        const yBorderPositionValue = tick.type === 'high' ? tick.y - sv.spacing.md * 1.5 : tick.y - sv.spacing.md * 1.5 + 27;
        const yTextPositionValue = tick.type === 'high' ? tick.y - sv.spacing.sm : tick.y - sv.spacing.sm + 27;

        return (
          <g key={i}>
            <line
              x1={0}
              x2={innerWidth}
              y1={tick.y}
              y2={tick.y}
              stroke={tick.color || sv.color.textLight}
              strokeWidth="2"
              strokeDasharray="4,4"
            />
            <rect x={sv.spacing.sm} y={yBorderPositionValue} height={sv.spacing.md * 1.3} width={width} rx={3} fill="#fff" stroke={sv.color.textLight} strokeWidth={2} opacity={0.75} />
            <text x={sv.spacing.md} y={yTextPositionValue}>
              <tspan style={yAxisLabel}>{tick.label} </tspan>
              <tspan style={{ ...yAxisLabel, fontSize: sv.fontSize.sm }}>{units}</tspan>
            </text>
          </g>
        );
      })}
    </g>
  );
}

YAxis.propTypes = propTypes;

export default YAxis;
