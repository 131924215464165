import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { sv } from 'src/assets/css/1_settings/variables';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorHiddenUnderText: PropTypes.string,
  format: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  floatingLabelFixed: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitError: PropTypes.node,
    valid: PropTypes.bool,
    error: PropTypes.node,
    pristine: PropTypes.bool
  }).isRequired,
  min: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.number,
  underlineDisabledStyle: PropTypes.object,
  underText: PropTypes.string
};

const defaultProps = {
  type: 'text'
};

function MuiNumberField(props) {
  const {
    className,
    disabled,
    format,
    input,
    label,
    meta: {
      error,
      touched,
      submitError,
      submitFailed,
      valid
    },
    min,
    step,
    onChange,
    type,
    floatingLabelFixed
  } = props;

  const inputProps = { ...input };

  if (disabled) {
    inputProps.disabled = true;
  }

  const showErrorText = (touched && !valid) || submitFailed;

  return (
    <TextField
      classes={{ root: className }}
      label={label}
      type={type}
      focused={floatingLabelFixed}
      error={(showErrorText && (Boolean(error) || Boolean(submitError)))}
      helperText={showErrorText && (error || submitError)}
      errorstyle={{ color: sv.color.red }}
      autoComplete="current-password"
      fullWidth
      shrink="true"
      format={format}
      min={min}
      hinttext={label}
      step={step}
      {...inputProps}
      onChange={(e) => {
        input.onChange(e);
        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
}

MuiNumberField.propTypes = propTypes;
MuiNumberField.defaultProps = defaultProps;

export default MuiNumberField;
