/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import PatternsNavLink from './PatternsNavLink';
import {
  ContactsLink,
  DashboardLink,
  InboxLink,
  LogbookLink,
  TrendsLink
} from './svg/links';
import routesConstants from '../constants/routes';

const propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

function NavTabs({ isActive, onClick }) {
  const navClasses = classNames('l-nav-tabs', {
    'l-nav-tabs--visible': isActive
  });

  return (
    <nav className={navClasses}>
      <PatternsNavLink to={routesConstants.DASHBOARD} onClick={onClick} Icon={DashboardLink} label="Dashboard"/>
      <PatternsNavLink to={routesConstants.TRENDS} onClick={onClick} Icon={TrendsLink} label="Trends"/>
      <PatternsNavLink to={routesConstants.LOG_BOOK} onClick={onClick} Icon={LogbookLink} label="Log Book"/>
      <PatternsNavLink skipExact to={routesConstants.CONTACTS_I_AM_SHARING_WITH} label="Contacts" onClick={onClick} Icon={ContactsLink} />
      <PatternsNavLink to={routesConstants.HEALTHFEED} onClick={onClick} Icon={InboxLink} label="Inbox"/>
    </nav>
  );
}

NavTabs.propTypes = propTypes;

export default NavTabs;
