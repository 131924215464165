/* istanbul ignore file */
import { connect } from 'react-redux';

import AvatarField from '../components/AvatarField';
import { getAvatar, putAvatar } from '../actions/avatarActions';

function mapStateToProps(state) {
  return {
    avatar: state.avatar.get('image')
  };
}

export default connect(mapStateToProps, {
  getAvatar,
  putAvatar
})(AvatarField);
