import apiService from './apiService';

async function doNotShowChecklist(skippedSetupRemaining) {
  try {
    const { data } =  await apiService.put('/patient/skipped_setup_remaining', { skippedSetupRemaining });
    return { data };
  } catch (err) {
    throw err.response && err.response.data;
  }
}

async function putMarkSetupRemainingComplete(items) {
  try {
    const { data } =  await apiService.put('/patient/mark_setup_remaining_items_complete', {
      completeSetupRemainingSections: items
    });
    return { data };
  } catch (err) {
    throw err.response && err.response.data;
  }
}

export default {
  putMarkSetupRemainingComplete,
  doNotShowChecklist
};
