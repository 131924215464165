import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '1rem'
  },
  container: {
    borderRadius: '2px',
    padding: '2rem 4rem',
    boxShadow: '0 1px 3px rgb(0 0 0 / 10%), 0 6px 24px rgb(0 0 0 / 10%)'
  },
  splash: {
    padding: '4vh 6rem',
    [theme.breakpoints.down('md')]: {
      padding: '8vh 0.5rem 2rem'
    }
  },
  buttonWrapper: {
    marginTop: '1rem'
  }
}));
