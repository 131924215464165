import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/core/internal/svg-icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/core/internal/svg-icons/CheckBoxOutlineBlank';

const propTypes = {
  labelClassName: PropTypes.string,
  handleChange: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    checked: PropTypes.any
  }),
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitError: PropTypes.node,
    valid: PropTypes.bool
  }).isRequired,
  label: PropTypes.any,
  labelPlacement: PropTypes.string,
  underText: PropTypes.string
};

const defaultProps = {
  label: ''
};

function MuiFormControlledCheckbox(props) {
  const {
    labelClassName,
    handleChange,
    input: {
      onChange,
      checked
    },
    meta: {
      error,
      touched,
      submitFailed,
      submitError,
      valid
    },
    label,
    labelPlacement
  } = props;

  const showErrorText = (touched && !valid) || submitFailed;

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e, val) => {
              onChange(e, val);
              handleChange && handleChange(val);
            }}
            checkedIcon={<CheckBoxIcon className="c-checkbox-active"/>}
            icon={<CheckBoxOutlineBlank className="c-checkbox-default"/>}
          />
        }
        label={<span className={labelClassName}>{label}</span>}
        labelPlacement={labelPlacement}
      />
      {(showErrorText && (Boolean(error) || Boolean(submitError))) && <FormHelperText error>{error || submitError}</FormHelperText>}
    </Fragment>
  );
}

MuiFormControlledCheckbox.propTypes = propTypes;
MuiFormControlledCheckbox.defaultProps = defaultProps;
export default MuiFormControlledCheckbox;
