/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  monthName: PropTypes.string,
  next: PropTypes.func,
  prev: PropTypes.func,
  year: PropTypes.number
};

function MonthControl({ monthName, next, prev, year }) {
  return (
    <div className="c-month-control">
      <button data-testid="prev" className="c-month-control__button" onClick={prev}>←</button>
      <span>{monthName} {year}</span>
      <button data-testid="next" className="c-month-control__button" onClick={next}>→</button>
    </div>
  );
}

MonthControl.propTypes = propTypes;

export default MonthControl;
