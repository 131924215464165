import actionTypes from 'src/constants/actionTypes';
import profileService from 'src/services/profileService';
import { countlyPopulateUserDetails } from 'src/services/countlyService';

// GET_ABOUT_ME

function getAboutMeFailure(err) {
  return {
    type: actionTypes.GET_ABOUT_ME_FAILURE,
    error: true,
    payload: err
  };
}

function getAboutMeStart() {
  return {
    type: actionTypes.GET_ABOUT_ME_START
  };
}

function getAboutMeSuccess(profileInfo) {
  return {
    type: actionTypes.GET_ABOUT_ME_SUCCESS,
    payload: { profileInfo }
  };
}

// GET_PATIENT_MEDICATIONS

function getPatientMedicationsFailure(err) {
  return {
    type: actionTypes.GET_PATIENT_MEDICATIONS_FAILURE,
    error: true,
    payload: err
  };
}

function getPatientMedicationsStart() {
  return {
    type: actionTypes.GET_PATIENT_MEDICATIONS_START
  };
}

function getPatientMedicationsSuccess(medications) {
  return {
    type: actionTypes.GET_PATIENT_MEDICATIONS_SUCCESS,
    payload: { medications }
  };
}

// PUT_ABOUT_ME

function putAboutMeFailure(err) {
  return {
    type: actionTypes.PUT_ABOUT_ME_FAILURE,
    error: true,
    payload: err
  };
}

function putAboutMeStart() {
  return {
    type: actionTypes.PUT_ABOUT_ME_START
  };
}

function putAboutMeSuccess() {
  return {
    type: actionTypes.PUT_ABOUT_ME_SUCCESS
  };
}

// PUT_PATIENT_MEDICATIONS

function putPatientMedicationsFailure(err) {
  return {
    type: actionTypes.PUT_PATIENT_MEDICATIONS_FAILURE,
    error: true,
    payload: err
  };
}

function putPatientMedicationsStart() {
  return {
    type: actionTypes.PUT_PATIENT_MEDICATIONS_START
  };
}

function putPatientMedicationsSuccess() {
  return {
    type: actionTypes.PUT_PATIENT_MEDICATIONS_SUCCESS
  };
}

export function getAboutMe(type = 'patient') {
  return async function (dispatch) {
    dispatch(getAboutMeStart());

    try {
      const profileInfo = await profileService.getAboutMe(type);

      // Countly populate user profile
      countlyPopulateUserDetails();
      dispatch(getAboutMeSuccess(profileInfo));
    } catch (err) {
      dispatch(getAboutMeFailure(err));
    }
  };
}

export function getPatientMedications() {
  return async function (dispatch) {
    dispatch(getPatientMedicationsStart());

    try {
      const medications = await profileService.getPatientMedications();
      dispatch(getPatientMedicationsSuccess(medications));
    } catch (err) {
      dispatch(getPatientMedicationsFailure(err));
    }
  };
}

export function putAboutMe(formValues, type = 'patient', withTimeZone = false) {
  return async function (dispatch) {
    dispatch(putAboutMeStart());

    try {
      const data = await profileService.putAboutMe(formValues, type, withTimeZone);
      dispatch(getAboutMeSuccess(data));
      dispatch(putAboutMeSuccess());
    } catch (err) {
      dispatch(putAboutMeFailure(err));
      throw err;
    }
  };
}

export function putPatientMedications(values) {
  return async function (dispatch) {
    dispatch(putPatientMedicationsStart());

    try {
      const data = await profileService.putPatientMedications(values);
      dispatch(putPatientMedicationsSuccess());
      dispatch(getPatientMedicationsSuccess(data));
    } catch (err) {
      dispatch(putPatientMedicationsFailure(err));
      throw err;
    }
  };
}
