import { connect } from 'react-redux';
import {
  resendInvitationToPractice,
  deleteSentInvitation,
  sendInvitationReminder
} from '../actions/contactsActions';
import { resendInviteToPatient } from '../actions/contactsIAmSharingWithInvitesActions';
import ContactsIAmSharingSentInvitationsTableButtons from '../pages/contacts/children/iAmSharingWithSentInvites/ContactsIAmSharingWithSentInvitesTable/ContactsIAmSharingWithSentInvitesTableButtons';
import { immutableToJsHOC } from './ImmutableToJsHOC';

export default connect(null, {
  resendInviteToPatient,
  resendInvitationToPractice,
  deleteSentInvitation,
  sendInvitationReminder
})(immutableToJsHOC(ContactsIAmSharingSentInvitationsTableButtons));
