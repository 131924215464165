import flatten from 'lodash/flatten';
import toPairs from 'lodash/toPairs';

// Will check if all notifications settings are set to true
export function areAllChecked({ newReadings, addCircleEvents, changeCircleEvents, proactiveTestingReminders, ...values }) {
  if (
    !newReadings ||
    !addCircleEvents ||
    !changeCircleEvents ||
    !values
  ) {
    return false;
  }

  // Some useless data come from backend so we need to filter it
  const initialValues = {
    ...values,
    newReadings: { push: newReadings.push },
    addCircleEvents: { push: addCircleEvents.push, email: addCircleEvents.email },
    proactiveTestingReminders: { push: proactiveTestingReminders.push, text: proactiveTestingReminders.text },
    changeCircleEvents: { push: changeCircleEvents.push, email: changeCircleEvents.email }
  };

  const arrayFromObj = toPairs(initialValues)
    .map(el => el[1])
    .map(toPairs);
  const result = flatten(arrayFromObj)
    .map(el => el[1])
    .every(el => el === true);

  return result;
}
