/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';

export default function ForgotPasswordSent() {
  return (
    <div className="c-card c-card--full-height c-card--centered">
      <div className="c-card__body">
        <svg width="44px" height="32px" viewBox="0 0 44 32">
          <g id="mail-sent" stroke="#2da9df" strokeWidth="2" fill="none">
            <rect x="1" y="1" width="42" height="30" rx="2" />
            <polyline strokeLinecap="round" strokeLinejoin="round" points="4 2 22 18 40 2" />
          </g>
        </svg>
        <h3 className="c-card__title c-card__title--bold c-card__title--padded">Email Sent</h3>
        <div>
          You will receive a password reset email if an account with that email address exists.
          Additionally you will receive an SMS with the same reset information if you have verified
          your phone number.  If you do not receive these or have any questions, please contact
          Customer Support at <a href="mailto:support@presspogohelp.com" target="_top">support@presspogohelp.com</a> or <a href="tel:+18554647646">1-855-IMI-POGO (464-7646)</a>.
        </div>
      </div>
      <Link
        to="/welcome/sign-in"
        className="c-card__footer c-button c-button--card-action"
      >
        OK
      </Link>
    </div>
  );
}
