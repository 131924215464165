import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import MenuItem from '@material-ui/core/MenuItem';
import LogoSplash from './LogoSplash';
import formValidationService from '../services/formValidation';
import { handleFormSubmissionErrors } from '../services/errorHandlingService';
import { normalizePhone } from '../services/fieldNormalizationService';
import MuiSelectField from './muiComponents/MuiSelectField';
import MuiTextField from './muiComponents/MuiTextField';

export class HelpContact extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    postHelpContact: PropTypes.func.isRequired
  }

  submit = async (values) => {
    try {
      await this.props.postHelpContact({
        ...values,
        phoneNumber: values.phoneNumber.replace(/[^\d]/g, '')
      });
      this.props.openSnackbar('Your message has been sent.');
      this.props.history.push(this.props.isLoggedIn ? '/dashboard' : '/welcome/sign-in');
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        [
          'topic',
          'name',
          'emailAddress',
          'phoneNumber',
          'details'
        ]
      );
    }
  }

  /* istanbul ignore next */
  validateFields(values) {
    return (new formValidationService.Validation())
      .topic(values.topic)
      .name(values.name)
      .email(values.emailAddress, 'emailAddress')
      .phoneNumber(values.phoneNumber)
      .details(values.details)
      .getErrors();
  }

  render() {
    return (
      <div className="l-container l-container--narrow">
        <div className="l-welcome">
          <div className="l-welcome__splash">
            <LogoSplash />
          </div>
          <div className="l-welcome__body">
            <div className="c-card">
              <div className="c-card__body">
                <Form
                  onSubmit={this.submit}
                  validate={this.validateFields}
                >
                  {({ handleSubmit, isActive, valid }) => {
                    return (
                      <form
                        name="helpContact"
                        className="c-form h-margin-bottom"
                        onSubmit={handleSubmit}
                      >
                        <Field name="topic" component={MuiSelectField} label="Topic" className="h-mui-native__select">
                          <MenuItem value="Pogo Meter">POGO Automatic&#174;</MenuItem>
                          <MenuItem value="Patterns Application">Patterns&#174; Application</MenuItem>
                          <MenuItem value="Healthcare Provider Inquiry">Healthcare Provider Inquiry</MenuItem>
                          <MenuItem value="General Website Inquiry">General Website Inquiry</MenuItem>
                        </Field>
                        <Field
                          component={MuiTextField}
                          label="Your Name"
                          name="name"
                        />
                        <Field
                          component={MuiTextField}
                          label="Your Email"
                          name="emailAddress"
                        />
                        <Field
                          component={MuiTextField}
                          label="Your Phone Number"
                          name="phoneNumber"
                          parse={normalizePhone}
                        />
                        <Field
                          component={MuiTextField}
                          label="What do you need help with?"
                          multiLine
                          name="details"
                          props={{ underText: 'Please DO NOT include any sensitive information.' }}
                        />
                        <input
                          className="c-button c-button--block c-button--action c-button--submit"
                          disabled={!valid || isActive}
                          type="submit"
                          value="Contact Us"
                        />
                      </form>
                    );
                  }}
                </Form>
                <p className="h-center">
                  Our customer support team is available Monday to Friday 9:00AM to 9:00PM Eastern Time (ET) at <a href="tel:+18554647646">1-855-IMI-POGO (464-7646)</a> or email us at <a href="mailto:support@presspogohelp.com" target="_top">support@presspogohelp.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HelpContact;
