import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '16Px'
  },
  blockWrapper: {
    padding: '36px 0'
  },
  blockContainer: {
    boxShadow: '0px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 11px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  },
  item: {
    padding: '16px'
  },
  itemBlock: {
    display: 'flex',
    alignItems: 'center'
  }
}));
