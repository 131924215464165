/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ChevronIcon from '../icons/ChevronIcon';

function EditTag({ backLink, className, label, children }) {
  return (
    <div className="c-section">
      <div className={className}>
        <Link to={backLink} className="c-nav-header c-card__header c-card__header--elevated c-card__header--xs">
          <ChevronIcon />
          <h2 className="c-nav-header__label">{label}</h2>
        </Link>
        <div className="h-overflow--auto h-full-width">
          {children}
        </div>
      </div>
    </div>
  );
}

EditTag.propTypes = {
  backLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
};

export default EditTag;
