import apiService from './apiService';
import { readingFormat } from '../utils/requestHelpers';

async function getRangeOfReadings(from, to, format) {
  const { data } = await apiService.get(`/readings/range/${from}/${to}${readingFormat(format)}`, {
    apiVersion: '/v2'
  });
  return data;
}

async function getReading(readingId) {
  const { data } = await apiService.get(`/readings/${readingId}`);
  return data;
}

async function getLastKnownReading() {
  const { data } = await apiService.get(`/readings/last/timestamp`);
  return data;
}

export default {
  getLastKnownReading,
  getRangeOfReadings,
  getReading
};
