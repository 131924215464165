import PropTypes from 'prop-types';
import React from 'react';
import ExportReportMenu from './ExportReportMenu';
import ShareIcon from './icons/ShareIcon';

export default class ExportReport extends React.Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    to: PropTypes.string.isRequired
  };

  state = {
    menuOpen: false
  };

  closeModal = () => {
    this.setState({
      menuOpen: false
    });
  }

  openModal = () => {
    this.setState({
      menuOpen: true
    });
  }

  render() {
    if (!this.state.menuOpen) {
      return (
        <div className="c-export-report">
          <button data-testid="shareBtn" className="c-nav-tab h-bg-none" onClick={this.openModal}>
            <ShareIcon/>
          </button>
        </div>
      );
    }
    return (
      <ExportReportMenu
        closeOnEsc
        closeOnOutsideClick
        closeModal={this.closeModal}
        options={this.props.options}
        from={this.props.from}
        to={this.props.to}
      />
    );
  }
}
