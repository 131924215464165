import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import values from 'lodash/values';
import { dataTypes, getDataType, makeComparisonValues } from 'src/utils/healthDataHelpers';
import TimeframeRecord from 'src/records/TimeframeRecord';
import TimeframeContainer from 'src/containers/TimeframeContainer';
import withHealthData from 'src/containers/withHealthData';
import withReadingData from 'src/containers/withReadingData';
import DashboardGraphSVG from 'src/components/svg/DashboardGraphSVG';
import HealthCompare from 'src/components/HealthCompare';
import GoToMarketPlaceButton from 'src/components/GoToMarketPlaceButton';
import WidgetStepsContainer from 'src/containers/WidgetStepsContainer';
import WidgetHydrationContainer from 'src/containers/WidgetHydrationContainer';
import WidgetCaloriesBurnedContainer from 'src/containers/WidgetCaloriesBurnedContainer';
import WidgetSleepsContainer from 'src/containers/WidgetSleepsContainer';
import WidgetCarbsContainer from 'src/containers/WidgetCarbsContainer';
import WidgetCaloriesConsumedContainer from 'src/containers/WidgetCaloriesConsumedContainer';
import WidgetWeigthsContainer from 'src/containers/WidgetWeigthsContainer';
import withTimeFrame from 'src/containers/withTimeFrame';

const propTypes = {
  filteredReadings: PropTypes.object,
  getPatientTesting: PropTypes.func.isRequired,
  isSharingCircleView: PropTypes.bool.isRequired,
  previousReadings: PropTypes.array,
  selectedReading: PropTypes.object,
  patientTargets: PropTypes.object,
  testingTimes: PropTypes.array,
  setSelectedReadingId: PropTypes.func.isRequired,
  timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired,
  validic: PropTypes.shape({
    devices: PropTypes.any,
    connected: PropTypes.array.isRequired,
    disconnected: PropTypes.array.isRequired
  }).isRequired
};

export function Dashboard(props) {
  const filteredReadingList = values(props.filteredReadings);
  const [compareType, setCompareType] = useState();

  useEffect(() => {
    props.getPatientTesting();
  }, []);

  useEffect(() => {
    if (!filteredReadingList?.length && Boolean(compareType)) {
      setCompareType(null);
    }
  }, [filteredReadingList?.length]);

  const setComparisonType = (type) => {
    if (!filteredReadingList.length) {
      return setCompareType(null);
    }
    setCompareType(type);
  };

  const {
    testingTimes,
    patientTargets,
    isSharingCircleView,
    previousReadings,
    selectedReading,
    setSelectedReadingId,
    timeframe,
    validic
  } = props;
  const dataType = getDataType(compareType);

  const comparisonValues = compareType && makeComparisonValues(
    validic.devices[compareType],
    dataType,
    (dataType.totals && props[dataType.totals]),
    timeframe
  );
  const goalsPerDay = testingTimes && testingTimes.filter(tt => tt.enabled).length;

  return (
    <div className="l-container">
      <div className="l-dashboard-controls">
        <TimeframeContainer />
        <HealthCompare compareType={compareType} dataTypes={dataTypes} setComparisonType={setComparisonType} />
      </div>
      <DashboardGraphSVG
        comparisonValues={comparisonValues}
        comparisonType={compareType}
        comparisonUnit={dataType && dataType.unit}
        filteredReadings={filteredReadingList}
        isSharingCircleView={isSharingCircleView}
        previousReadings={previousReadings}
        patientTargets={patientTargets}
        goalsPerDay={goalsPerDay}
        setSelectedReadingId={setSelectedReadingId}
        selectedReading={selectedReading}
      />
      <div>
        <h2 className="c-list-item c-list-item--subheader c-list-item--borderless">Connected Data Sources</h2>
        <div className="c-card-grid">
          <WidgetStepsContainer />
          <WidgetWeigthsContainer />
          <WidgetHydrationContainer />
          <WidgetCaloriesBurnedContainer />
          <WidgetSleepsContainer />
          <WidgetCarbsContainer />
          <WidgetCaloriesConsumedContainer />
        </div>
        {!isSharingCircleView && (
          <div className="h-center h-padded">
            <GoToMarketPlaceButton />
          </div>
        )}
        <hr />
      </div>
    </div>
  );
}

Dashboard.propTypes = propTypes;
const DashboardWithReadings = withReadingData(Dashboard);
export default withTimeFrame(withHealthData(DashboardWithReadings));
