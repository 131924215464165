import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import EditTag from './EditTag';
import CountlyTrackedButton from '../countly/CountlyTrackedButton';
import warnAboutUnsavedChangesNoForm from '../../containers/warnAboutUnsavedChangesNoForm';
import isEqualWith from 'lodash/isEqualWith';
import { diffByIdOrTypeReading } from '../../utils/logBookFoods';

export class EditInsulinTag extends Component {
  static propTypes = {
    disablePreventRouteLeave: PropTypes.func.isRequired,
    deleteReadingTag: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    postReadingTag: PropTypes.func.isRequired,
    putReadingTag: PropTypes.func.isRequired,
    preventRouteLeave: PropTypes.func.isRequired,
    selectedReading: PropTypes.shape({
      full_tags: PropTypes.array,
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    let tag;
    if (props.selectedReading) {
      tag = props.selectedReading.full_tags.find(t => t.type === 'insulin');
    }
    this.state = {
      tag,
      error: '',
      value: tag ? tag.value : 0
    };
  }

  componentDidMount() {
    if (!this.props.selectedReading) {
      this.props.history.replace({ pathname: '/log-book' });
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // This is for closing window if user clicks on other date
    if (
      !isEqualWith(prevProps.selectedReading, this.props.selectedReading, diffByIdOrTypeReading) ||
      !this.props.selectedReading
    ) {
      this.props.history.replace({
        pathname: '/log-book'
      });
    }

    if (prevState.value !== this.state.value) {
      this.props.preventRouteLeave();
    }
  }

  changeValue = (evt) => {
    this.setState({ value: evt.target.value });
  }

  handleSave = (link) => async () => {
    const { selectedReading: { id }, history } = this.props;
    const tag = { type: 'insulin', value: this.state.value, unit: 'Units' };

    this.props.disablePreventRouteLeave();

    if (tag.value === '' || tag.value > 9999 || tag.value < 0) {
      return this.setState({
        error: 'Must be a value between 0.01 and 9999'
      });
    }

    if (/\.\d{3,}/.test(`${tag.value}`)) {
      return this.setState({
        error: 'Value must have no more than 2 decimal places'
      });
    }

    if (this.state.tag) {
      tag.id = this.state.tag.id;

      await this.props.putReadingTag(id, tag);
      history.push(link);
      return;
    }

    await this.props.postReadingTag(id, tag);
    history.push(link);
  }

  handleDelete = (link) => async () => {
    const { selectedReading: { id, full_tags: fullTags }, history } = this.props;
    const tag =  fullTags.find(el => el.type === 'insulin');

    await this.props.disablePreventRouteLeave();
    await this.props.deleteReadingTag(id, tag.id, tag);
    history.push(link);
  }

  isOfTypeInsulin = el => el.type === 'insulin';

  /*
   * Will return tag of type 'insulin'
   */
  returnTag = () => {
    const { selectedReading } = this.props;
    return selectedReading && selectedReading.full_tags.find(this.isOfTypeInsulin);
  };

  isSubmitDisabled = () => {
    return this.state.value <= 0;
  }

  isDeleteDisabled = () => {
    const isTag = this.returnTag();

    if (!isTag) { return true; }

    return false;
  }

  render() {
    const { history } = this.props;
    const linkString = '/edit/insulin';
    const backLink = history.location.pathname.slice(0, history.location.pathname.length - linkString.length);

    return (
      <EditTag
        backLink={backLink}
        className="c-log__detail c-card"
        label="Insulin"
      >
        <div className="c-card__body">
          <div className="h-center h-margin-bottom-lg">
            Please enter the number of fast-
            <br />
            acting insulin you took around this reading
          </div>

          <TextField
            id="jest-text-field"
            error={this.state.error}
            value={this.state.value}
            onChange={this.changeValue}
            helperText={this.state.error || 'Units of Insulin'}
            label="Units of Insulin"
            fullWidth
            type="number"
          />

        </div>
        <div className="h-padded">
          <CountlyTrackedButton
            dataTestid="save"
            countlyevent="update"
            disabled={this.isSubmitDisabled()}
            className="c-button c-button--block c-button--action h-margin-bottom-sm"
            onClick={this.handleSave(backLink)}
          >
            Save
          </CountlyTrackedButton>
          <CountlyTrackedButton
            dataTestid="delete"
            countlyevent="delete"
            disabled={this.isDeleteDisabled()}
            className="c-button c-button--block c-button--danger-hollow"
            onClick={this.handleDelete(backLink)}
          >
            Remove Data and Save
          </CountlyTrackedButton>
        </div>
      </EditTag>
    );
  }
}

export default warnAboutUnsavedChangesNoForm(EditInsulinTag);
