import PropTypes from 'prop-types';
import React from 'react';
import Switch from '@material-ui/core/Switch';

const propTypes = {
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string,
    checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func.isRequired
  })
};

function MuiToggle({ onChange, className, input, ...restProps }) {
  return (
    <Switch
      name={input.name}
      data-testid={restProps['data-testid']}
      className={className}
      checked={input.checked}
      onChange={(event, value) => {
        const shouldPrevent = onChange ? onChange(event, value, input) : false;
        if (!shouldPrevent) {
          input.onChange(event);
        }
      }}
    />
  );
}

MuiToggle.propTypes = propTypes;

export default MuiToggle;
