/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReadingsRecord from '../records/ReadingsRecord';
import TimeframeRecord from '../records/TimeframeRecord';
import TimeframeContainer from '../containers/TimeframeContainer';
import withHealthData from '../containers/withHealthData';
import withReadingData from '../containers/withReadingData';
import StandardDayGraphSVG from './svg/StandardDayGraphSVG';
import TrendPercentage from './TrendPercentage';

const propTypes = {
  filteredReadings: ImmutablePropTypes.list,
  readings: PropTypes.instanceOf(ReadingsRecord).isRequired,
  settings: ImmutablePropTypes.mapContains({
    patientTargets: ImmutablePropTypes.map
  }).isRequired,
  timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired
};

function Trends(props) {
  const { filteredReadings, readings, settings } = props;

  const preMealReadings = filteredReadings.filter(reading => reading.get('tags').filter(tag => tag.includes('pre')).size > 0);
  const postMealReadings = filteredReadings.filter(reading => reading.get('tags').filter(tag => tag.includes('post')).size > 0);
  const fastingReadings = filteredReadings.filter(reading => reading.get('tags').includes('fasting'));
  const snackReadings = filteredReadings.filter(reading => reading.get('tags').includes('snack'));

  return (
    <div className="l-container">
      <TimeframeContainer />
      <h2 className="c-list-item c-list-item--subheader c-list-item--borderless">All Glucose Readings</h2>
      <div className="l-card-div">
        <TrendPercentage patientTargets={settings.get('patientTargets')} readingVals={filteredReadings} title="All Glucose Readings" />
      </div>
      <div className="l-card-row">
        <TrendPercentage patientTargets={settings.get('patientTargets')} readingVals={fastingReadings} title="Fasting Readings" />
        <TrendPercentage patientTargets={settings.get('patientTargets')} readingVals={snackReadings} title="Snack Readings" />
        <TrendPercentage mealPrefix="pre" patientTargets={settings.get('patientTargets')} readingVals={preMealReadings} title="Readings Before Meals" />
        <TrendPercentage mealPrefix="post" patientTargets={settings.get('patientTargets')} readingVals={postMealReadings} title="Readings After Meals" />
      </div>
      <br />
      <h2 className="c-list-item c-list-item--subheader c-list-item--borderless">Readings by Time of Day</h2>
      <StandardDayGraphSVG
        hasAnyReadings={!!(readings.get('cache') && readings.get('cache').size > 0)}
        patientTargets={settings.get('patientTargets')}
        readingVals={filteredReadings}
      />
      <hr />
    </div>
  );
}

Trends.propTypes = propTypes;

const TrendsWithReadings = withReadingData(Trends);
export default withHealthData(TrendsWithReadings);
