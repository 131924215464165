/* istanbul ignore file */
import React from 'react';
import { Field } from 'react-final-form';
import MuiFormControlledCheckbox from './muiComponents/MuiFormControlledCheckbox';

export default function TermsAndCondition() {
  return (
    <div style={{ padding: '1rem 0 1rem 1rem' }}>
      <Field
        style={{ width: 'auto' }}
        inputStyle={{ marginLeft: 0 }}
        iconStyle={{ marginLeft: 0, marginRight: '16Px' }}
        name="hasAcceptedTermsAndConditions"
        component={MuiFormControlledCheckbox}
        label={<span>I accept the <a href="https://s3.amazonaws.com/uploaders.patternsforpogo.com/docs/terms_and_conditions.pdf" target="_blank">Terms and Conditions</a></span>}
        type="checkbox"
        isRequired
      />
    </div>
  );
}
