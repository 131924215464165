import keyMirror from 'keymirror';

export default keyMirror({
  /*
    ============ LOCAL ============
  */

  // Preferences
  PLEASE_KEEP_SIGNING_ME_OUT: null,
  STAY_SIGNED_IN: null,
  // Nutritionix
  CLEAR_NUTRITIONIX: null,
  CLEAR_NUTRITIONIX_SELECTED_FOOD: null,
  SET_NUTRITIONIX_SELECTED_FOOD: null,
  SET_NUTRITIONIX_QUERY: null,
  // Readings
  CLEAR_SELECTED_READING: null,
  SET_SELECTED_READING_ID: null,
  // Timeframe
  SET_TIMEFRAME: null,
  SHIFT_TIMEFRAME_BY_SPAN: null,
  // Tokens
  SET_TOKEN: null,
  // Validic
  SET_DEVICE_PER_DATA_TYPE: null,
  SYNC_DEVICE_ON_SERVICE_START: null,
  SYNC_DEVICE_ON_SERVICE_SUCCESS: null,
  SYNC_DEVICE_ON_SERVICE_FAILURE: null,
  // Carer's selected patient
  SET_SELECTED_PATIENT: null,
  CLEAR_SELECTED_PATIENT: null,

  /*
    ============ SERVER ============
  */

  /* Account */
  // => Email Address
  // => Forgot Password
  FORGOT_PASSWORD_SUCCESS: null,
  FORGOT_PASSWORD_FAILURE: null,
  FORGOT_PASSWORD_START: null,
  // => Login
  SIGN_IN: null,
  SIGN_IN_FAILURE: null,
  SIGN_IN_START: null,
  // => Logout
  SIGN_OUT: null,
  // => Password
  // => Refresh Token
  REFRESH_TOKEN_START: null,
  REFRESH_TOKEN_SUCCESS: null,
  REFRESH_TOKEN_FAILURE: null,
  // => Reset Password
  RESET_PASSWORD_FAILURE: null,
  RESET_PASSWORD_START: null,
  RESET_PASSWORD_SUCCESS: null,

  /* Autocomplete */
  // => Carers
  // => Practices

  /* Carer Invitations */
  // => Accept or Decline
  ACCEPT_INVITATION_FAILURE: null,
  ACCEPT_INVITATION_START: null,
  ACCEPT_INVITATION_SUCCESS: null,

  // => remove from emergency contacts via link in email
  SELF_REMOVE_FROM_EMERGENCY_CONTACT_SUCCESS: null,

  // => Sharing Requests

  /* Carer Profile */
  // => About
  // => Practice

  /* Content */
  // => Snippets

  /* Health Data */
  // PGHD
  //  => New PGHD
  //  => Range of PGHD
  GET_RANGE_OF_PGHD_FAILURE: null,
  GET_RANGE_OF_PGHD_START: null,
  GET_RANGE_OF_PGHD_SUCCESS: null,
  GET_PGHD_DEVICE_LIST_SUCCESS: null,
  // Sleeps
  //  => Sleep
  //  => New Sleeps
  //  => Range of Summaries
  // Summaries
  //  => Summary
  GET_SUMMARY_FAILURE: null,
  GET_SUMMARY_START: null,
  GET_SUMMARY_SUCCESS: null,
  //  => New Summaries
  GET_NEW_SUMMARIES_FAILURE: null,
  GET_NEW_SUMMARIES_START: null,
  GET_NEW_SUMMARIES_SUCCESS: null,
  // Weights
  //  => Weight
  GET_WEIGHT_FAILURE: null,
  GET_WEIGHT_START: null,
  GET_WEIGHT_SUCCESS: null,
  //  => New Weights
  GET_NEW_WEIGHTS_FAILURE: null,
  GET_NEW_WEIGHTS_START: null,
  GET_NEW_WEIGHTS_SUCCESS: null,

  /* Invitations */
  // => Invitation
  GET_INVITATION_FAILURE: null,
  GET_INVITATION_START: null,
  GET_INVITATION_SUCCESS: null,

  /* Nutritionix */
  // => Autocomplete Branded and Common Foods Search
  GET_NUTRITIONIX_AUTOCOMPLETE_FAILURE: null,
  GET_NUTRITIONIX_AUTOCOMPLETE_START: null,
  GET_NUTRITIONIX_AUTOCOMPLETE_SUCCESS: null,
  // => Food Search by nix_item_id, upc or common name
  GET_NUTRITIONIX_FOOD_BY_ID_FAILURE: null,
  GET_NUTRITIONIX_FOOD_BY_ID_START: null,
  GET_NUTRITIONIX_FOOD_BY_ID_SUCCESS: null,
  GET_NUTRITIONIX_FOOD_ALT_MEASURES: null,

  /* Contacts Page */
  GET_CONTACTS_SHARED_WITH_ME_SUCCESS: null,
  GET_CONTACTS_I_AM_SHARING_WITH_SUCCESS: null,

  // => invitations
  GET_I_AM_SHARING_WITH_INVITATIONS_COUNT_SUCCESS: null,
  GET_CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS_SUCCESS: null,
  GET_CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS_SUCCESS: null,
  GET_CONTACTS_SHARED_WITH_ME_INVITATIONS_COUNT_SUCCESS: null,
  GET_CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS_SUCCESS: null,

  // => Invites manages pop-up
  SEARCH_HEALTHCARE_PROVIDERS_FAILURE: null,
  SEARCH_HEALTHCARE_PROVIDERS_START: null,
  SEARCH_HEALTHCARE_PROVIDERS_SUCCESS: null,
  SEARCH_PRACTICES_FAILURE: null,
  SEARCH_PRACTICES_START: null,
  SEARCH_PRACTICES_SUCCESS: null,
  GET_HCP_LIST_IN_PRACTICE_START: null,
  GET_HCP_LIST_IN_PRACTICE_SUCCESS: null,
  GET_HCP_LIST_IN_PRACTICE_FAILURE: null,
  GET_HCP_INFO_START: null,
  GET_HCP_INFO_SUCCESS: null,
  GET_HCP_INFO_FAILURE: null,
  STORE_INVITES_SEARCH_QUERY: null,
  STORE_INVITES_TYPE: null,
  CLEAR_INVITES_SEARCH_RESULTS: null,
  RESET_INVITES_FORM: null,

  /* Patient Invitations */
  // => Invitation
  // => Invitations

  /* Patient Profile */
  // => About
  GET_ABOUT_ME_FAILURE: null,
  GET_ABOUT_ME_START: null,
  GET_ABOUT_ME_SUCCESS: null,
  PUT_ABOUT_ME_FAILURE: null,
  PUT_ABOUT_ME_START: null,
  PUT_ABOUT_ME_SUCCESS: null,
  // => Emergency Contact
  POST_EMERGENCY_CONTACT_START: null,
  PUT_EMERGENCY_CONTACT_START: null,
  // => Medications
  GET_PATIENT_MEDICATIONS_FAILURE: null,
  GET_PATIENT_MEDICATIONS_START: null,
  GET_PATIENT_MEDICATIONS_SUCCESS: null,
  PUT_PATIENT_MEDICATIONS_FAILURE: null,
  PUT_PATIENT_MEDICATIONS_START: null,
  PUT_PATIENT_MEDICATIONS_SUCCESS: null,
  // => Validic Credentials

  /* Patient Settings */
  // => Meter
  POST_PATIENT_METER_WITH_TOKEN_FAILURE: null,
  POST_PATIENT_METER_WITH_TOKEN_START: null,
  POST_PATIENT_METER_WITH_TOKEN_SUCCESS: null,
  POST_PATIENT_METER_FAILURE: null,
  POST_PATIENT_METER_START: null,
  POST_PATIENT_METER_SUCCESS: null,
  DELETE_PATIENT_METER_FAILURE: null,
  DELETE_PATIENT_METER_START: null,
  DELETE_PATIENT_METER_SUCCESS: null,
  // => Meters
  GET_PATIENT_METERS_FAILURE: null,
  GET_PATIENT_METERS_START: null,
  GET_PATIENT_METERS_SUCCESS: null,
  // => Patient Targets
  GET_PATIENT_TARGETS_FAILURE: null,
  GET_PATIENT_TARGETS_START: null,
  GET_PATIENT_TARGETS_SUCCESS: null,
  PUT_PATIENT_TARGETS_FAILURE: null,
  PUT_PATIENT_TARGETS_START: null,
  PUT_PATIENT_TARGETS_SUCCESS: null,
  // => Patient Testing Frequency
  GET_PATIENT_TESTING_FAILURE: null,
  GET_PATIENT_TESTING_START: null,
  GET_PATIENT_TESTING_SUCCESS: null,
  EDIT_PATIENT_TESTING: null,

  /* Profile Avatar */
  GET_AVATAR_FAILURE: null,
  GET_AVATAR_START: null,
  GET_AVATAR_SUCCESS: null,
  PUT_AVATAR_FAILURE: null,
  PUT_AVATAR_START: null,
  PUT_AVATAR_SUCCESS: null,

  /* Reading Foods */
  // => FOOD
  DELETE_READING_FOOD_FAILURE: null,
  DELETE_READING_FOOD_START: null,
  DELETE_READING_FOOD_SUCCESS: null,
  GET_READING_FOOD_FAILURE: null,
  GET_READING_FOOD_START: null,
  GET_READING_FOOD_SUCCESS: null,
  POST_READING_FOOD_FAILURE: null,
  POST_READING_FOOD_START: null,
  POST_READING_FOOD_SUCCESS: null,
  PUT_READING_FOOD_FAILURE: null,
  PUT_READING_FOOD_START: null,
  PUT_READING_FOOD_SUCCESS: null,
  // => FOODS
  GET_READING_FOODS_FAILURE: null,
  GET_READING_FOODS_START: null,
  GET_READING_FOODS_SUCCESS: null,
  GET_FAVORITE_FOODS_SUCCESS: null,
  GET_RECENT_FOODS_SUCCESS: null,

  /* Reading Tags */
  // => Tag
  DELETE_READING_TAG_FAILURE: null,
  DELETE_READING_TAG_START: null,
  DELETE_READING_TAG_SUCCESS: null,
  GET_READING_TAG_BY_ID_FAILURE: null,
  GET_READING_TAG_BY_ID_START: null,
  GET_READING_TAG_BY_ID_SUCCESS: null,
  POST_READING_TAG_FAILURE: null,
  POST_READING_TAG_START: null,
  POST_READING_TAG_SUCCESS: null,
  PUT_READING_TAG_FAILURE: null,
  PUT_READING_TAG_START: null,
  PUT_READING_TAG_SUCCESS: null,
  // => Range of Tags
  GET_RANGE_OF_READING_TAGS_FAILURE: null,
  GET_RANGE_OF_READING_TAGS_START: null,
  GET_RANGE_OF_READING_TAGS_SUCCESS: null,
  GET_TAGS_DEVICE_LIST_SUCCESS: null,
  // => Tags
  GET_READING_TAGS_FAILURE: null,
  GET_READING_TAGS_START: null,
  GET_READING_TAGS_SUCCESS: null,

  /* Readings */
  // => Range of Readings
  GET_RANGE_OF_READINGS_FAILURE: null,
  GET_RANGE_OF_READINGS_START: null,
  GET_RANGE_OF_READINGS_SUCCESS: null,
  GET_LAST_KNOWN_READING_DATE_START: null,
  GET_LAST_KNOWN_READING_DATE_SUCCESS: null,
  GET_LAST_KNOWN_READING_DATE_FAILURE: null,
  // => Reading
  GET_READING_FAILURE: null,
  GET_READING_START: null,
  GET_READING_SUCCESS: null,

  /* Registration */
  // => Carer
  // => Patient
  REGISTER_FAILURE: null,
  REGISTER_START: null,
  REGISTER_SUCCESS: null,

  /* Reports */

  /* Settings */
  // => General Settings
  // => Notification Settings
  GET_NOTIFICATION_SETTINGS_FAILURE: null,
  GET_NOTIFICATION_SETTINGS_START: null,
  GET_NOTIFICATION_SETTINGS_SUCCESS: null,
  PUT_NOTIFICATION_SETTINGS_FAILURE: null,
  PUT_NOTIFICATION_SETTINGS_START: null,
  PUT_NOTIFICATION_SETTINGS_SUCCESS: null,

  /* Sharing */
  // => Decline
  DELETE_EMERGENCY_CONTACT_DECLINE_FAILURE: null,
  DELETE_EMERGENCY_CONTACT_DECLINE_START: null,
  DELETE_EMERGENCY_CONTACT_DECLINE_SUCCESS: null,
  // => Opt Out
  DELETE_EMERGENCY_CONTACT_OPT_OUT_FAILURE: null,
  DELETE_EMERGENCY_CONTACT_OPT_OUT_START: null,
  DELETE_EMERGENCY_CONTACT_OPT_OUT_SUCCESS: null,
  // Accept
  GET_EMERGENCY_CONTACT_ACCEPT_FAILURE: null,
  GET_EMERGENCY_CONTACT_ACCEPT_START: null,
  GET_EMERGENCY_CONTACT_ACCEPT_SUCCESS: null,

  /* Status */
  GET_STATUS_FAILURE: null,
  GET_STATUS_START: null,
  GET_STATUS_SUCCESS: null,
  UPDATE_STATUS_SKIPPED_SETUP_REMAINGN_SUCCESS: null,
  UPDATE_ACCEPT_TERMS_AND_CONDITIONS: null,

  /* Validic */
  // => Connect Source
  POST_MARKETPLACE_CONNECT_SOURCE_FAILURE: null,
  POST_MARKETPLACE_CONNECT_SOURCE_START: null,
  POST_MARKETPLACE_CONNECT_SOURCE_SUCCESS: null,
  // => Disconnect Source
  DELETE_MARKETPLACE_DISCONNECT_SOURCE_FAILURE: null,
  DELETE_MARKETPLACE_DISCONNECT_SOURCE_START: null,
  DELETE_MARKETPLACE_DISCONNECT_SOURCE_SUCCESS: null,
  // => Marketplace
  GET_MARKETPLACE_FAILURE: null,
  GET_MARKETPLACE_START: null,
  GET_MARKETPLACE_SUCCESS: null,

  /* Active Contact Tab */
  UPDATE_ACTIVE_CONTACT_TAB: null,

  /* Active Meal Tab */
  UPDATE_ACTIVE_MEAL_TAB: null,

  /* Snackbar */
  OPEN_SNACKBAR: null,
  CLOSE_SNACKBAR: null,

  /* Practices */
  // => Delete
  DELETE_PRACTICE_FAILURE: null,
  DELETE_PRACTICE_START: null,
  DELETE_PRACTICE_SUCCESS: null,

  /* Healthcare Provider Sharing Circle */
  /* Healthcare Provider Incoming Invitations */
  // => Get
  GET_INCOMING_INVITATIONS_FROM_PRACTICE_FAILURE: null,
  GET_INCOMING_INVITATIONS_FROM_PRACTICE_START: null,
  GET_INCOMING_INVITATIONS_FROM_PRACTICE_SUCCESS: null,

  /* Health plan seen screen statuses */
  UPDATE_SEEN_STATUS_SCREEN: null,

  /* Search for suggestions in health plan forms */
  FULL_INSURED_SUGGESTIONS_SUCCESS: null,

  /* Change tracking message seen status */
  PUT_TRACKING_MESSAGE_SEEN_STATUS: null,

  /* Progress */
  SHOW_PROGRESS: null,
  HIDE_PROGRESS: null,

  /* Location service */
  GET_EMERGENCY_EVENT_DATA: null,

  /* Countly */
  COUNTLY_SET_USER_EMAIL: null,
  COUNTLY_SET_PAGE: null
});
