import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: '0 1rem 2rem 1rem',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      padding: '2rem 1rem'
    }
  },
  description: {
    color: theme.palette.primary.main,
    paddingTop: '20px'
  }
}));
