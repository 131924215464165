import React, { Fragment, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import ContactsInviteManagerContainer from '../../../../../containers/ContactsInviteManagerContainer';

function ContactsInviteBtn() {
  const [isOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const memoizedCloseModal = useCallback(closeModal, []);

  return (
    <Fragment>
      <button
        onClick={openModal}
        data-testid="button-invites"
        className="c-button c-button--hollow c-button--action c-button--condensed h-ws-pre jest-open"
      >
        + SEND INVITATION
      </button>

      <Dialog
        open={isOpen}
        onClose={memoizedCloseModal}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        data-testid="dialog-invites"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperComponent={Paper}
        PaperProps={{ elevation: 0 }}
      >
        <ContactsInviteManagerContainer closeModal={memoizedCloseModal}/>
      </Dialog>
    </Fragment>
  );
}

export default ContactsInviteBtn;
