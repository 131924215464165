import moment from 'moment';

export const isOfTypeWater = el => el.type === 'water';

export const hasTagWater = reading => reading.full_tags.some(isOfTypeWater);

export const getSelectedReadingWaterTag = (props) => {
  const { selectedReading } = props;
  return selectedReading && selectedReading.full_tags.find(isOfTypeWater);
};

export const generateBackLink = (props) => {
  const { history: { location: { pathname } } } = props;
  const linkString = '/edit/hydration';
  return pathname.slice(0, pathname.length - linkString.length);
};

export const findWaterTagValue = (el) => {
  const waterTag = el.full_tags.find(isOfTypeWater);
  return Number(waterTag.value);
};

export const isPreviousReading = selectedReading => reading => {
  const diff = moment(selectedReading.timestamp).diff(moment(reading.timestamp));

  if (diff === 0) {
    return selectedReading.id < reading.id;
  }
  return diff > 0;
};

export const isNextReading = selectedReading => reading => {
  const diff = moment(selectedReading.timestamp).diff(moment(reading.timestamp));

  if (diff === 0) {
    return selectedReading.id > reading.id;
  }
  return diff < 0;
};


