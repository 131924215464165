import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  tooltip: {
    color: 'unset',
    backgroundColor: '#ffffff',
    borderRadius: '4Px',
    boxShadow: '0px 2px 11px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px -1px rgba(0, 0, 0, 0.20);'
  }
}));
