import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import Radio from '@material-ui/core/Radio';
import apiService from 'src/services/apiService';
import { makeCurrentReportOption } from 'src/services/timeframeService';
import confirm from 'src/utils/ConfirmHelpers';
import CircleXIcon from './icons/CircleXIcon';
import { makeEndDateForApi, makeStartDateForApi } from 'src/utils/dateFormatter';

export default class ExportReportMenu extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    closeOnOutsideClick: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
    from: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    to: PropTypes.string.isRequired
  };

  state = {
    id: 5,
    from: this.props.from,
    to: this.props.to,
    disabledBtn: false
  };

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeydown);
    }

    if (this.props.closeOnOutsideClick) {
      document.addEventListener('mouseup', this.handleOutsideMouseClick);
      document.addEventListener('touchend', this.handleOutsideMouseClick);
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeydown);
    }

    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('mouseup', this.handleOutsideMouseClick);
      document.removeEventListener('touchend', this.handleOutsideMouseClick);
    }
  }

  setSelection = (selection) => {
    return () => {
      this.setState({
        id: selection.id,
        from: selection.from,
        to: selection.to
      });
    };
  }

  handleOutsideMouseClick = (e) => {
    const root = findDOMNode(this.modal);
    if (root.contains(e.target) || (e.button && e.button !== 0)) {
      return;
    }

    e.stopPropagation();
    this.props.closeModal();
  }

  handleKeydown = (e) => {
    if (e.keyCode === 27) {
      this.props.closeModal();
    }
  }

  createReport = async () => {
    this.setState({ disabledBtn: true });
    const from = makeStartDateForApi(this.state.from);
    const to =  makeEndDateForApi(this.state.to);

    try {
      const { data } = await apiService.get(`/report/range/${from}/${to}`, { responseType: 'arraybuffer' });
      const blob = new Blob([data]);
      const link = document.createElement('a');
      const fileName = `report_${this.state.from}_${this.state.to}.pdf`;

      if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
        navigator.msSaveBlob(blob, fileName);
      } else {
        document.body.appendChild(link);
        link.setAttribute('type', 'hidden');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        setTimeout(function () {
          link.click();
          document.body.removeChild(link);
        }, 77);
      }

      this.props.closeModal();
    } catch (err) {
      const response = JSON.parse(new TextDecoder().decode(err.response.data));
      const errorText = response.error;

      const confirmOptions = {
        confirmText: 'Ok',
        showCancel: false,
        headerText: `${errorText[0].toUpperCase()}${errorText.slice(1)}`
      };

      await confirm(null, confirmOptions);
    }
    this.setState({ disabledBtn: false });
  }

  render() {
    const currentOption = makeCurrentReportOption(this.props.from, this.props.to);
    const currentClasses = classNames(
      'c-export-report__choice',
      { 'c-export-report__choice--selected': this.state.id === currentOption.id }
    );

    return (
      <div className="c-export-report" ref={(node) => { this.modal = node; }}>
        <button className="c-nav-tab" onClick={this.props.closeModal}>
          <CircleXIcon />
        </button>
        <div className="c-export-report__menu c-card c-card--elevated c-card--white">
          <div data-testid="text" className="c-card__header">
            Select the timeframe of data you would like<br />
            to include in your report.
          </div>
          <ul className="c-export-report__list">
            <li className={currentClasses} onClick={this.setSelection(currentOption)}>
              <span>{currentOption.label}</span>
              <span className="c-export-report__radio">
                <Radio checked={this.state.id === currentOption.id} className="jest-radio" />
              </span>
            </li>
            {this.props.options.map((o, i) => {
              const selected = this.state.id === o.id;
              const choiceClasses = classNames(
                'c-export-report__choice',
                { 'c-export-report__choice--selected': selected }
              );

              return (
                <li
                  key={i}
                  className={choiceClasses}
                  onClick={this.setSelection(o)}
                >
                  <span>{o.label}</span>
                  <span className="c-export-report__radio">
                    <Radio checked={selected} className="jest-radio"/>
                  </span>
                </li>
              );
            })}
          </ul>
          <button
            disabled={this.state.disabledBtn}
            className="c-button c-button--block c-button--action"
            onClick={this.createReport}
          >
            Create Report
          </button>
        </div>
      </div>
    );
  }
}
