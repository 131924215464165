import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    '& .c-button': {
      flexBasis: '50%',
      '&:first-child': {
        marginRight: '9px'
      },
      '&:last-child': {
        marginLeft: '9px'
      }
    }
  },
  singleButton: {
    justifyContent: 'flex-end',
    '& .c-button': {
      flexBasis: 'calc(50% - 9px)',
      '&:first-child': {
        marginRight: '0'
      },
      '&:last-child': {
        marginLeft: '0'
      }
    }
  }
}));
