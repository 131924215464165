/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settingsActionCreators from '../actions/settingsActions';
import * as statusActionCreators from '../actions/statusActions';
import NotificationSettings from '../components/NotificationSettings';
import WithOnboardingTracking from './withOnboardingTracking';

function NotificationSettingsContainer(props) {
  return (
    <WithOnboardingTracking type="notifications">
      <NotificationSettings {...props} />
    </WithOnboardingTracking>
  );
}

function mapStateToProps(state) {
  return {
    cellNumberVerified: state.profile.get('cellNumberVerified'),
    initialValues: {
      newReadings: state.settings.getIn(['notificationSettings', 'newReadings']).toJS(),
      hypoEvents: state.settings.getIn(['notificationSettings', 'hypoEvents']).toJS(),
      hyperEvents: state.settings.getIn(['notificationSettings', 'hyperEvents']).toJS(),
      addCircleEvents: state.settings.getIn(['notificationSettings', 'addCircleEvents']).toJS(),
      proactiveTestingReminders: state.settings.getIn(['notificationSettings', 'proactiveTestingReminders']).toJS(),
      changeCircleEvents: state.settings.getIn(['notificationSettings', 'changeCircleEvents']).toJS()
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      settings: bindActionCreators(settingsActionCreators, dispatch),
      status: bindActionCreators(statusActionCreators, dispatch)
    }
  };
}

export { mapStateToProps, mapDispatchToProps };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsContainer);
