import actionTypes from '../constants/actionTypes';
import { convertOptionToTimeframe } from '../services/timeframeService';

export function setTimeframe(timeframe) {
  return {
    type: actionTypes.SET_TIMEFRAME,
    payload: { timeframe: convertOptionToTimeframe(timeframe) }
  };
}

export function shiftTimeframeBySpan(timeframe) {
  return {
    type: actionTypes.SHIFT_TIMEFRAME_BY_SPAN,
    payload: { timeframe }
  };
}
