const moment = require('moment');
import { BG_AVERAGE_DISPLAY_MAX, BG_AVERAGE_DISPLAY_MIN } from './readingHelpers';

export function getDayMillisecondsFromTimestamp(dateString) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Extract the start of the day in UTC
  const startOfDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));

  // Calculate the time difference between the given date and the start of the day in milliseconds
  const timeDiff = date.getTime() - startOfDay.getTime();

  // Return the time difference in milliseconds
  return timeDiff;
}

export function getGlucoseAvg(readingVals) {
  const glucoseSum = readingVals.reduce((acc, val) => {
    return val ? acc + val.bloodGlucose : acc + 0;
  }, 0);
  return Math.round(glucoseSum / readingVals.length);
}

export function getGoalResults(vals, numDays, goalsPerDay) {
  return {
    goal: numDays * goalsPerDay,
    complete: vals ? vals.length : 0
  };
}

export function getHighGlucoseCount(readingVals, highGlucose) {
  const highFilter = readingVals.filter((val) => val.bloodGlucose >= highGlucose);
  return readingVals.length ? highFilter.length : NaN;
}

export function getLowGlucoseCount(readingVals, lowGlucose) {
  const lowFilter = readingVals.filter((val) => val.bloodGlucose <= lowGlucose);
  return readingVals.length ? lowFilter.length : NaN;
}

export function getNumDays(end, start) {
  return moment(end).diff(moment(start), 'days') + 1;
}

export function getTargetGlucoseCount(readingVals, lowGlucose, highGlucose) {
  const targetFilter = readingVals.filter((val) => {
    return (val.bloodGlucose > lowGlucose && val.bloodGlucose < highGlucose);
  });
  return Math.round(targetFilter.length / readingVals.length * 100);
}

export function setColor(colors, high, low) {
  return function (val) {
    if (val <= low) {
      return colors.red;
    }

    if (val > low && val < high) {
      return colors.green;
    }

    return colors.yellow; // val >= high
  };
}

export function setCompYDomain(vals, unit) {
  const compYDomain = vals ?
    vals.reduce((acc, val) => {
      if (!acc[0] || val[unit] < acc[0]) { acc[0] = val[unit]; }
      if (!acc[1] || val[unit] > acc[1]) { acc[1] = val[unit]; }
      return acc;
    }, [0, 0]) : [0, 0];

  if (unit === 'weight' && (compYDomain[1] - compYDomain[0]) >= 0) {
    const padding = Math.floor(compYDomain[0] / 2);
    compYDomain[0] -= padding;
    compYDomain[1] += padding;
  } else {
    compYDomain[0] = 0;
  }

  return compYDomain;
}

export function setGlucoseYDomain(low, high, vals) {
  let yDomain = [low - 8, high + 40];

  if (vals.length > 0) {
    yDomain = vals.reduce((acc, val) => {
      if (!acc[0] || val.y < acc[0]) { acc[0] = val.y; }
      if (!acc[1] || val.y > acc[1]) { acc[1] = val.y; }
      return acc;
    }, [low - 8, high + 40]);
  }

  return [yDomain[0] - 12, yDomain[1] + 24];
}

export function setTrendArrow(prev, val) {
  if (!val) {
    return 'flat';
  }

  if (prev > val) {
    return 'down';
  }

  if (prev < val) {
    return 'up';
  }

  return 'flat'; // prev == val
}

// Compound

export function getCompletion(readingVals, prevVals, numDays, goalsPerDay) {
  const results = getGoalResults(readingVals, numDays, goalsPerDay);
  const prevResults = getGoalResults(prevVals, numDays, goalsPerDay);
  const completionPercentage = Math.round(results.complete / results.goal * 100);
  const completionDirection = setTrendArrow(
    prevResults.complete / prevResults.goal,
    results.complete / results.goal
  );

  return {
    ...results,
    direction: completionDirection,
    percentage: completionPercentage
  };
}

export function normalizeAverageReadingText(averageReading) {
  if (isNaN(averageReading)) {
    return '--';
  }

  if (averageReading < BG_AVERAGE_DISPLAY_MIN) {
    return `< ${BG_AVERAGE_DISPLAY_MIN}`;
  }

  if (averageReading >= BG_AVERAGE_DISPLAY_MAX) {
    return `${BG_AVERAGE_DISPLAY_MAX}+`;
  }

  return Math.round(averageReading); // averageReading is within range
}

export function getGlucoseMetrics(readingVals, prevVals, highGlucose, lowGlucose, colors) {
  const colorMe = setColor(colors, highGlucose, lowGlucose);
  // Glucose
  const glucoseAvg = getGlucoseAvg(readingVals);
  const glucoseAvgText = normalizeAverageReadingText(glucoseAvg);
  const avgTrend = setTrendArrow(getGlucoseAvg(prevVals), glucoseAvg);

  // Low Glucose
  const lowCount = getLowGlucoseCount(readingVals, lowGlucose);
  const lowTrend = setTrendArrow(getLowGlucoseCount(prevVals, lowGlucose), lowCount);

  // High Glucose
  const highCount = getHighGlucoseCount(readingVals, highGlucose);
  const highTrend = setTrendArrow(getLowGlucoseCount(prevVals, highGlucose), highCount);

  // Target Glucose
  const targetCount = getTargetGlucoseCount(readingVals, lowGlucose, highGlucose);
  const prevTargetCount = getTargetGlucoseCount(prevVals, lowGlucose, highGlucose);
  const targetTrend = setTrendArrow(prevTargetCount, targetCount);

  return [
    { label: 'Average Glucose Value', value: glucoseAvgText, color: colorMe(glucoseAvg), direction: avgTrend },
    { label: '# of Low Results (Hypo)', value: isNaN(lowCount) ? 0 : lowCount, color: readingVals.length ? colors.red : colors.primaryColor, direction: lowTrend },
    { label: '# of High Results (Hyper)', value: isNaN(highCount) ? 0 : highCount, color: readingVals.length ? colors.yellow : colors.primaryColor, direction: highTrend },
    { label: 'Readings in target', value: targetCount, units: '%', color: colors.primaryColor, direction: targetTrend }
  ];
}

export const yDomain = [-15, 570];
