import apiService from './apiService';

async function deleteMarketplaceDisconnectSource(link) {
  const { data } = await apiService.put(`${process.env.API_URL || ''}${link}`);
  return data;
}

async function getMarketplace() {
  const { data } = await apiService.get('/marketplace?redirect_uri=web');
  return data;
}

async function getMarketplaceLink(link) {
  const data = await apiService.get(`${process.env.API_URL || ''}${link}`);
  return data;
}

async function syncMHealthDeviceOnServer(link) {
  const data = await apiService.put(`${process.env.API_URL || ''}${link}`);
  return data;
}

async function setDevicePerDataType(deviceList) {
  const data = {
    widgetSources: {
      averageCaloriesBurned: deviceList.caloriesBurned || null,
      averageCaloriesConsumed: deviceList.foods || null,
      averageCarbohydrates: deviceList.carbs || null,
      averageHydration: deviceList.water || null,
      averageSleep: deviceList.sleeps || null,
      averageSteps: deviceList.steps || null,
      averageWeight: deviceList.weights || null
    }
  };
  await apiService.put('/settings/widget_sources', data);
}

async function getStoredDevicesPerDataType() {
  const data = await apiService.get('/settings/widget_sources');
  return data;
}

export default {
  setDevicePerDataType,
  getStoredDevicesPerDataType,
  deleteMarketplaceDisconnectSource,
  syncMHealthDeviceOnServer,
  getMarketplaceLink,
  getMarketplace
};
