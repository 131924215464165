import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import FavoriteFoodIconContainer from '../../containers/FavoriteFoodIconContainer';
import { generateLinkWithQueries } from '../../utils/logBookFoods';

const propTypes = {
  history: PropTypes.object.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  list: PropTypes.array,
  label: PropTypes.string.isRequired
};

export default function FoodSearchResultsPresenter({ list, label, history, isEmpty }) {
  if (!list || !list.length || isEmpty) {
    return null;
  }

  return (
    <React.Fragment>
      <h3 className="c-list-item c-list-item--header">{label}</h3>
      {list.map((food, i) => {
        const foodLink = generateLinkWithQueries('post', history, food);

        return (
          <Link to={foodLink} key={i} className="c-list-item c-list-item--food">
            <img className="c-list-item__photo" src={food.photo.thumb}/>
            <span className="c-list-item__name">{food.food_name}</span>
            <FavoriteFoodIconContainer
              isVisible={!!food.favorite_food_id}
              foodName={food.name}
              nixItemId={food.nix_item_id}
              favoriteFoodId={food.favorite_food_id}
              isFavorite={!!food.favorite_food_id}
              notEditable
            />
            <span className="c-list-item__icon">
              <svg className="c-icon" viewBox="0 0 24 24">
                <polyline strokeWidth="2" points="10 8 14 12.5 10 17"/>
              </svg>
            </span>
          </Link>
        );
      })}
    </React.Fragment>
  );
}

FoodSearchResultsPresenter.propTypes = propTypes;
