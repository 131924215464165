import React, { memo } from 'react';
// Icons
import iconSearchNoresults from '../../../../../../assets/img/icons/iconSearchNoresults.svg';
// Styles
import { useStyles } from './SearchResultsEmpty.styles';

const SearchResultsEmpty = memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid="empty">
      <img src={iconSearchNoresults}/>
      <div className={classes.description}><strong>Your search returned no results</strong></div>
    </div>
  );
});

export default SearchResultsEmpty;
