/* istanbul ignore file */
import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from '@material-ui/core';
import routeConstants from 'src/constants/routes';
import SharingCircleSharingWithMeContainer from 'src/containers/SharingCircleSharingWithMeContainer';
import SharingCircleSharedWithMeReceivedInvitesContainer from 'src/containers/SharingCircleSharedWithMeReceivedInvitesContainer';
import SharingCircleProfile from 'src/components/sharingCircle/SharingCircleProfile';

export default function SharingCircle() {
  const {
    SHARING_CIRCLE_SHARED_WITH_ME,
    SHARING_CIRCLE_SHARED_WITH_ME_RECEIVED_INVITATIONS,
    SHARING_CIRCLE_PROFILE
  } = routeConstants;

  return (
    <Container maxWidth="lg" className="h-margin-top">
      <Route exact path={SHARING_CIRCLE_SHARED_WITH_ME} component={SharingCircleSharingWithMeContainer} />
      <Route exact path={SHARING_CIRCLE_SHARED_WITH_ME_RECEIVED_INVITATIONS} component={SharingCircleSharedWithMeReceivedInvitesContainer}/>
      <Route path={SHARING_CIRCLE_PROFILE} component={SharingCircleProfile} />
    </Container>
  );
}
