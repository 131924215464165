import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MenuIcon from '../MenuIcon';
import PatternsNavLink from '../PatternsNavLink';
import { DashboardLink, LogbookLink, TrendsLink } from '../svg/links';
import ExportReportContainer from '../../containers/ExportReportContainer';
import routesConstants from '../../constants/routes';
import { Container } from '@material-ui/core';
import PatientData from './PatientData';

const propTypes = {
  selectedPatient: PropTypes.object,
  isCompletePatient: PropTypes.bool.isRequired
};

export default function SharingCirclePatientDataViewHeader({ selectedPatient, isCompletePatient }) {
  const [isActive, setIsActive] = useState(false);

  const overlayClasses = classNames({
    'c-overlay': true,
    'c-overlay--visible': isActive
  });
  const navClasses = classNames('l-nav-tabs', {
    'l-nav-tabs--visible': isActive
  });

  return (
    <Fragment>
      <Container maxWidth="lg">
        <div className={overlayClasses} onClick={() => setIsActive(active => !active)}/>
        <div className="l-header">
          <div className="l-header__left">
            <MenuIcon isActive={isActive} onClick={() => setIsActive(active => !active)}/>
          </div>
          <nav className={navClasses}>
            <PatternsNavLink
              data-testid="patNav"
              to={routesConstants.SHARING_CIRCLE_DASHBOARD}
              onClick={() => setIsActive(active => !active)}
              Icon={DashboardLink}
              label="Dashboard"
            />
            <PatternsNavLink
              to={routesConstants.SHARING_CIRCLE_TRENDS}
              onClick={() => setIsActive(active => !active)}
              Icon={TrendsLink}
              label="Trends"
            />
            <PatternsNavLink
              to={routesConstants.SHARING_CIRCLE_LOG_BOOK}
              onClick={() => setIsActive(active => !active)}
              Icon={LogbookLink}
              label="Log Book"
            />
          </nav>
          <div className="l-header__right">
            <ExportReportContainer/>
          </div>
        </div>
      </Container>
      <Container maxWidth="lg">
        <div className=" h-padded-top-pre-lg">
          <Link to={isCompletePatient ? routesConstants.CONTACTS_SHARED_WITH_ME : routesConstants.SHARING_CIRCLE_SHARED_WITH_ME} className="h-c-primary">
            <strong>{'<'} Back to People sharing with me</strong>
          </Link>
        </div>
        <PatientData selectedPatient={selectedPatient} />
      </Container>
    </Fragment>
  );
}

SharingCirclePatientDataViewHeader.propTypes = propTypes;
