import helpContactService from '../services/helpContactService';

export function postHelpContact(values) {
  return async function () {
    try {
      await helpContactService.postHelpEmail(values);
    } catch (err) {
      throw err;
    }
  };
}
