import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import WarnAboutUnsavedChanges from 'src/containers/warnAboutUnsavedChanges';
import MuiTextField from 'src/components/muiComponents/MuiTextField';
import { normalizePhone } from 'src/services/fieldNormalizationService';
import { handleFormSubmissionErrors } from 'src/services/errorHandlingService';
import formValidationService from 'src/services/formValidation';
import AboutPhoneStatus from 'src/components/AboutPhoneStatus';

const propTypes = {
  cellNumberVerified: PropTypes.bool,
  getAboutMe: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  putAboutMe: PropTypes.func.isRequired
};

export default function SharingCircleAboutMeForm(props) {
  const history = useHistory();

  useEffect(() => {
    props.getAboutMe('carer');
  }, []);

  const submit = async (values) => {
    try {
      await props.putAboutMe(values, 'carer');
      props.getAboutMe('carer');
      props.getStatus();
      history.push('/sharing-circle/profile');
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        [
          'firstName',
          'lastName',
          'cellPhone'
        ]
      );
    }
  };

  const validateFields = (values) => {
    return (new formValidationService.Validation())
      .firstName(values.firstName)
      .lastName(values.lastName)
      .cellNumberOptional(values.cellNumber)
      .getErrors();
  };

  return (
    <Form
      initialValues={props.initialValues}
      onSubmit={submit}
      validate={validateFields}
    >
      {({ handleSubmit, submitting, pristine }) => {
        return (
          <form
            name="aboutSC"
            className="c-form"
            onSubmit={handleSubmit}
          >
            <WarnAboutUnsavedChanges />
            <Field
              component={MuiTextField}
              label="First Name"
              name="firstName"
            />
            <Field
              component={MuiTextField}
              label="Last Name"
              name="lastName"
            />
            <Field
              component={MuiTextField}
              label="Cell Phone"
              name="cellNumber"
              parse={normalizePhone}
              placeholder="Cell Phone"
              endAdornment={
                <AboutPhoneStatus status={props.cellNumberVerified}/>
              }
            />
            <input
              className="c-button c-button--block c-button--action c-button--submit"
              disabled={pristine || submitting}
              type="submit"
              value="Save"
            />
          </form>
        );
      }}
    </Form>
  );
}

SharingCircleAboutMeForm.propTypes = propTypes;
