import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import LogoSplash from './LogoSplash';
import failedIcon from '../assets/img/icons/verificationWrong.svg';
import verifiedIcon from '../assets/img/icons/verificationVerified.svg';

const pageData = {
  phone: {
    success: {
      icon: verifiedIcon,
      title: 'Your phone number has been verified',
      text: 'Thank you for verifying your phone number. We just wanted to make sure it was entered correctly.',
      buttonText: 'Continue'
    },
    failure: {
      icon: failedIcon,
      title: 'Something went wrong',
      text: 'Sorry, this verification link is no longer valid due to one of the following reasons:',
      buttonText: 'Proceed to Login'
    }
  }
};

export default class PhoneVerification extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.string
      }),
      push: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    const { history: { location, push } } = this.props;
    const query = parse(location.search);

    if (
      !query ||
      query.type !== 'phone' ||
      !(query.result === 'success' ||
      query.result === 'failure')
    ) {
      push('/');
    }
  }

  render() {
    const { history: { location } } = this.props;
    const query = parse(location.search);

    if (!pageData[query.type] || !pageData[query.type][query.result]) {
      return null;
    }

    return (
      <div className="l-container l-container--narrow">
        <div className="l-welcome">
          <div className="l-welcome__splash">
            <LogoSplash />
          </div>
          <div className="l-welcome__body">
            <div className="c-card">
              <div className="c-card__body c-card__container--centered">
                <div className="l-welcome__container-img">
                  <img src={pageData[query.type][query.result].icon}/>
                </div>
                <div className="l-welcome__title h-center h-full-width h-margin-top-lg">
                  {pageData[query.type][query.result].title}
                </div>
                <div className="l-welcome__text-container h-full-width h-padded">
                  <div className="l-welcome__text h-center">
                    {pageData[query.type][query.result].text}
                  </div>
                </div>
                {query.result === 'failure' && (
                  <div className="c-card__error">
                    <ul className="c-card__error-list">
                      <li>it has already been verified</li>
                      <li>it has expired or has been cancelled</li>
                    </ul>
                  </div>
                )}
                <div className="h-margin-top h-full-width">
                  <Link to="/welcome/sign-in" className="c-button c-button--block c-button--active">
                    {pageData[query.type][query.result].buttonText}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
