/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import ContactsIAmSharingWithSentInvitesTable from '../ContactsIAmSharingWithSentInvitesTable';
import ContactsTypesFilter from 'src/components/contacts/ContactsTypesFilter/ContactsTypesFilter';
import SearchContacts from 'src/components/contacts/SearchContacts';
import routesConstants from 'src/constants/routes';
import useFetchDataHook from 'src/hooks/useFetchDataHook';
import Loader from 'src/components/shared/Loader';
import Text from 'src/shared/Text/Text';

const propTypes = {
  itemsList: PropTypes.array,
  itemsListCount: PropTypes.number,
  getIAmSharingWithSentInvitations: PropTypes.func.isRequired
};

export default function ContactsIAmSharingWithSentInvites({ getIAmSharingWithSentInvitations, itemsList, itemsListCount = 0 }) {
  const { CONTACTS_I_AM_SHARING_WITH } = routesConstants;
  const { memoizedFetch, loading } = useFetchDataHook(getIAmSharingWithSentInvitations);

  return (
    <Container style={{ padding: '0' }}>
      <Link to={CONTACTS_I_AM_SHARING_WITH} className="h-c-primary">
        <strong>{'<'} Back to People I am sharing with</strong>
      </Link>

      <Grid container className="h-padded-top h-padded-bottom">
        <Text fontSize="24" lineHeight="32" font="bold">Sent Invitations</Text>
      </Grid>

      <div className="l-contact-filter h-padded-bottom-lg">
        <ContactsTypesFilter/>
        <SearchContacts/>
      </div>

      <ContactsIAmSharingWithSentInvitesTable
        memoizedFetch={memoizedFetch}
        itemsList={itemsList}
        itemsListCount={itemsListCount}
      />

      <Loader isEnabled={loading}/>
    </Container>
  );
}

ContactsIAmSharingWithSentInvites.propTypes = propTypes;
