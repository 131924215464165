import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { roleList } from 'src/constants/contactPageRoleList';

/**
 * This hooks handles contact page role types filter and modifies url get params
 * @returns {{
 * setFilterStatus: setFilterStatus
 * }}
 */

export default function useContactTypesSetHook(skipType = []) {
  const history = useHistory();
  const { search } = useLocation();
  const parsedQuery = parse(search, { arrayFormat: 'bracket' });
  const filteredRoleList = [...roleList.filter(el => !skipType.includes(el.type))];
  const defaultTypeList = filteredRoleList.map(el => el.type);

  const setFilterStatus = useCallback((filterItem) => () => {
    const { type } = filterItem || {};
    const { perPage = 5, contactTypes = [...defaultTypeList], ...rest } = parsedQuery;
    const hasTypeInUrl = contactTypes?.find(typeInUrl => type === typeInUrl);

    // If type is in url we filter it, if not we add it
    const updatedTypesInUrl = Boolean(hasTypeInUrl) ?
      contactTypes.filter(typeInUrl => type !== typeInUrl) :
      [...contactTypes, type];

    // At least 1 type in url should be enabled
    // User should not be able to switch off every filter types
    if (!updatedTypesInUrl?.length) {
      return;
    }

    // Update query params in url
    const queryParams = { ...rest, contactTypes: updatedTypesInUrl, perPage,  page: 0 };
    const url = stringify(queryParams, { arrayFormat: 'bracket' });
    history.replace({ search: url });
  }, [search]);

  return {
    setFilterStatus
  };
}
