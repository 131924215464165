import { connect } from 'react-redux';

// Actions
import { updateActiveMealTab } from '../actions/activeMealTabActions';
// Components
import FoodsTab from '../components/logs/FoodsTab';

/* istanbul ignore next */
function makeMapStateToProps() {
  return function (state) {
    return {
      activeTab: state.activeMealTab.get('activeTab')
    };
  };
}

export default connect(makeMapStateToProps, { updateActiveMealTab })(FoodsTab);
