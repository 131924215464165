import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import SignOutContainer from '../containers/SignOutContainer';

function ProfileMenu({ settingsList, showSignOut }) {
  return (
    <div className="c-sidebar">
      <div className="c-sidebar__header">
        <h2>Profile Info</h2>
      </div>
      <nav className="c-sidebar__nav">
        {settingsList.map((setting, i) => {
          return (
            <NavLink key={i} to={setting.location} className="c-sidebar-nav-item" activeClassName="c-sidebar-nav-item--active">
              <div className="c-sidebar-nav-item__body">
                <h3
                  className="c-sidebar-nav-item__label"
                  dangerouslySetInnerHTML={{ __html: setting.label }} // eslint-disable-line
                />
              </div>
            </NavLink>
          );
        })}
        <div className="h-margin-top-lg">
          {showSignOut && <SignOutContainer />}
        </div>
      </nav>
    </div>
  );
}

ProfileMenu.propTypes = {
  settingsList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired
  })).isRequired,
  showSignOut: PropTypes.bool.isRequired
};

ProfileMenu.defaultProps = {
  showSignOut: true
};

export default ProfileMenu;
