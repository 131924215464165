import { createSelector } from 'reselect';
import { getTimeframe } from './timeframeSelectors';
import { timeframeFilter } from '../utils/timeframeHelpers';
import { countUniqueDates } from '../utils/dateFormatter';
import { getWaterDeviceList } from './healthDataSelectors';

// Water
/* istanbul ignore next */
function getWaterTotals(state) {
  return state.readingTags.getIn(['water', 'cache']);
}

export function makeGetWaterInTimeframe() {
  return createSelector(
    [getWaterTotals, getTimeframe, getWaterDeviceList],
    (totals, timeframe, connectedDevices) => {
      return totals
        .filter(timeframeFilter(timeframe))
        .filter((unit) => !!connectedDevices.find((cd) => cd.get('type') === unit.get('source')))
        .groupBy((unit) => unit.get('source'))
        .map((source, type) => {
          const sourceConverted = source.toJS();
          const sourceValuesInNumber = sourceConverted.map(el => ({ ...el, value: Number(el.value) || 0 }));
          const total = sourceValuesInNumber.reduce((acc, cur) => cur.value + acc, 0);
          const uniqueDatesCount = countUniqueDates(sourceValuesInNumber);
          const avarageCalc = total / uniqueDatesCount;
          const average = avarageCalc < 1 ? 1 : Math.round(avarageCalc);

          return {
            total,
            average,
            details: connectedDevices.find((d) => d.get('type') === type),
            values: sourceValuesInNumber
          };
        });
    }
  );
}

function getSelectedReading(state) {
  return state.readings;
}

function getSelectedId(state) {
  return state.readings.get('selectedId');
}

export function makeGetSelectedReading() {
  return createSelector(
    [getSelectedReading, getSelectedId],
    (readings, selectedId) => {
      return selectedId ? readings.getIn(['cache', selectedId]) : null;
    }
  );
}
