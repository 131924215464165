import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Container } from '@material-ui/core';
import DrugListWrapper from 'src/pages/profile/Medications/childrens/DrugListWrapper';
import SelectorTypes from 'src/pages/profile/Medications/childrens/SelectorTypes';
import WarnAboutUnsavedChanges from 'src/containers/warnAboutUnsavedChanges';
import DrugList from 'src/pages/profile/Medications/childrens/DrugList';
import confirm from 'src/utils/ConfirmHelpers';
import { insulins } from 'src/constants/medicationDrugs';
import { useStyles } from './Medications.styles';

const propTypes = {
  putPatientMedications: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default function Medications(props) {
  const history = useHistory();
  const [insulinType, setInsulinType] = useState(null);
  const classes = useStyles();

  const makeSubmitValues = (values) => {
    // Converts structure from this { 'name' : true } to this ['name']
    const medicationTypes = Object.keys(values?.medicationTypes).filter(key => values?.medicationTypes[key]);

    // Converts structure from this [{ 'name' : true }] to this ['name']
    const injectableLong = values?.insulins?.injectableLong.filter(obj => Object.values(obj)[0]).map(obj => Object.keys(obj)[0]);
    const injectableFast = values?.insulins?.injectableFast.filter(obj => Object.values(obj)[0]).map(obj => Object.keys(obj)[0]);
    const pump = values?.insulins?.pump.filter(obj => Object.values(obj)[0]).map(obj => Object.keys(obj)[0]);
    const inhaled = values?.insulins?.inhaled.filter(obj => Object.values(obj)[0]).map(obj => Object.keys(obj)[0]);

    return {
      injectableLong,
      injectableFast,
      pump,
      inhaled,
      submitValues: {
        medicationTypes,
        insulins: {
          injectableLong,
          injectableFast,
          pump,
          inhaled
        }
      }
    };
  };

  const submit = async (values) => {
    const {
      submitValues, injectableLong, injectableFast, pump, inhaled
    } = makeSubmitValues(values);

    if (
      values?.medicationTypes?.insulin &&
      (
        (values?.medicationTypes?.injectable && (!injectableLong?.length && !injectableFast?.length)) ||
        (values?.medicationTypes?.pump && !pump?.length) ||
        (values?.medicationTypes?.inhaled && !inhaled?.length) ||
        (!values?.medicationTypes?.inhaled && !values?.medicationTypes?.pump && !values?.medicationTypes?.injectable)
      )
    ) {
      const confirmOptions = {
        confirmText: 'Ok',
        showCancel: false,
        headerText: "Insulins Not Selected"
      };
      confirm('To save, you must choose at least one insulin for each delivery pathway you toggled on', confirmOptions);
      return;
    }

    try {
      await props.putPatientMedications(submitValues);
      history.push('/profile/pogo'); // go to next page
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  const isDuplicate = useCallback((val) => (el) => String(Object.keys(el)[0]).trim() === String(val).trim(), []);

  const goBack = useCallback(() => setInsulinType(null), []);

  return (
    <Container className={classes.container}>
      <Form
        keepDirtyOnReinitialize
        mutators={{ ...arrayMutators }}
        initialValues={props.initialValues}
        onSubmit={submit}
      >
        {({ handleSubmit, values, form }) => {
          const { mutators: { push } } = form;

          const validateDuplicate = (drugGroup) => (value) => {
            const defaultDrugNameIndex = insulins[drugGroup].findIndex(isDuplicate(value));
            const isDefaultDrug = insulins[drugGroup].find(isDuplicate(value));
            const isDuplicateDrug = values?.insulins[drugGroup].find(isDuplicate(value));

            if (isDefaultDrug) {
              // Form is build in a way so typical field is called
              // insulins.injectableLong[0].Lantus and we set value to true here in case
              // it is one of default insulins from constant file
              // if it custom added insulin we just skipp adding
              form.change(`insulins[${drugGroup}][${defaultDrugNameIndex}][${value}]`, true);
            }

            return !isDuplicateDrug;
          };

          return (
            <form name="aboutMeForm" onSubmit={handleSubmit}>
              <WarnAboutUnsavedChanges />

              <FieldArray name="insulins.injectableLong">
                {({ fields }) => {
                  if (insulinType === 'longActing') {
                    const type = 'insulins.injectableLong';
                    const drugGroup = 'injectableLong';
                    const header = 'Long Acting';

                    return (
                      <DrugListWrapper type={type} validateDuplicate={validateDuplicate(drugGroup)} header={header} push={push} goBack={goBack}>
                        {fields.map((name, index) => (
                          <DrugList key={index} drugGroup={drugGroup} fields={fields} index={index} name={name}/>
                        ))}
                      </DrugListWrapper>
                    );
                  }
                }}
              </FieldArray>

              <FieldArray name="insulins.injectableFast">
                {({ fields }) => {
                  if (insulinType === 'fastActing') {
                    const type = 'insulins.injectableFast';
                    const drugGroup = 'injectableFast';
                    const header = 'Fast Acting';

                    return (
                      <DrugListWrapper type={type} validateDuplicate={validateDuplicate(drugGroup)} header={header} push={push} goBack={goBack}>
                        {fields.map((name, index) => (
                          <DrugList key={index} drugGroup={drugGroup} fields={fields} index={index} name={name}/>
                        ))}
                      </DrugListWrapper>
                    );
                  }
                }}
              </FieldArray>

              <FieldArray name="insulins.pump">
                {({ fields }) => {
                  if (insulinType === 'pump') {
                    const type = 'insulins.pump';
                    const drugGroup = 'pump';
                    const header = 'Pump';

                    return (
                      <DrugListWrapper type={type} validateDuplicate={validateDuplicate(drugGroup)} header={header} push={push} goBack={goBack}>
                        {fields.map((name, index) => (
                          <DrugList key={index} drugGroup={drugGroup} fields={fields} index={index} name={name}/>
                        ))}
                      </DrugListWrapper>
                    );
                  }
                }}
              </FieldArray>

              <FieldArray name="insulins.inhaled">
                {({ fields }) => {
                  if (insulinType === 'inhaled') {
                    const type = 'insulins.inhaled';
                    const drugGroup = 'inhaled';
                    const header = 'Inhaled';

                    return (
                      <DrugListWrapper type={type} validateDuplicate={validateDuplicate(drugGroup)} header={header} push={push} goBack={goBack}>
                        {fields.map((name, index) => (
                          <DrugList key={index} drugGroup={drugGroup} fields={fields} index={index} name={name}/>
                        ))}
                      </DrugListWrapper>
                    );
                  }
                }}
              </FieldArray>

              {!insulinType && (
                <SelectorTypes values={values} setInsulinType={setInsulinType}/>
              )}
            </form>
          );
        }}
      </Form>
    </Container>
  );
}

Medications.propTypes = propTypes;
