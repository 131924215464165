import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import confirm from 'src/utils/ConfirmHelpers';

const propTypes = {
  acceptReceivedInvitation: PropTypes.func.isRequired,
  deleteSentInvitation: PropTypes.func.isRequired,
  memoizedFetch: PropTypes.func.isRequired,
  itemsListCount: PropTypes.number,
  item: PropTypes.object
};

export default function ContactsSharedWithMeReceivedInvitesTableButtons(props) {
  const {
    item,
    acceptReceivedInvitation,
    deleteSentInvitation,
    memoizedFetch,
    itemsListCount
  } = props;

  const handleRemove = async () => {
    const confirmOptions = { confirmText: 'Confirm', cancelText: 'Cancel', headerText: 'Decline Invitation' };
    const confirmMessage = 'Please confirm that you want to decline this invitation?';

    if (await confirm(confirmMessage, confirmOptions)) {
      try {
        await deleteSentInvitation(item.invitationId);
        memoizedFetch('edit', itemsListCount === 1);
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    }
  };

  const handleAccept = async () => {
    const confirmOptions = { confirmText: 'Ok', showCancel: false, headerText: 'Verify Phone Number' };
    const confirmMessage = 'You have partially accepted an invitation to become an Emergency Contact for this user.\n' +
      'To complete acceptance of the invitation please verify your phone number by clicking the link you will receive via text message.';

    try {
      const data = await acceptReceivedInvitation(item.token);
      String(data?.success).includes('partially accepted') && await confirm(confirmMessage, confirmOptions);
      memoizedFetch('edit', itemsListCount === 1);
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  return (
    <Fragment>
      <Grid container style={{ columnGap: '16Px', display: 'flex', flexWrap: 'nowrap' }}>
        <Grid item xs={6}>
          <button
            data-testid="accept"
            className="c-button c-button--action c-button--condensed c-button--trailing h-ws-pre h-full-width jest-expired"
            onClick={handleAccept}
          >
            Accept
          </button>
        </Grid>
        <Grid item xs={6}>
          <button
            data-testid="decline"
            className="c-button c-button--danger-hollow c-button--condensed c-button--trailing h-full-width h-ws-pre jest-cancel"
            onClick={handleRemove}
          >
            Decline
          </button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

ContactsSharedWithMeReceivedInvitesTableButtons.propTypes = propTypes;
