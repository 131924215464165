/* istanbul ignore file */
/* eslint-disable */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CircleIcon from './icons/CircleIcon';
import PieChartSVG from './svg/PieChartSVG';
import MealIconChooser from './icons/MealIconChooser';
import { normalizeAverageReadingText } from '../utils/trendHelpers';

export default class TrendPercentage extends Component {
  static propTypes = {
    mealPrefix: PropTypes.string,
    patientTargets: ImmutablePropTypes.map,
    readingVals: ImmutablePropTypes.list,
    title: PropTypes.string
  };
  state = {
    mealTag: 'fasting'
  };

  setMeal = (meal) => () => {
    this.setState({
      mealTag: this.props.mealPrefix && meal ?
        `${this.props.mealPrefix}_${meal}` :
        'fasting'
    });
  }

  countGoalWidth = (highWidth, lowWidth) => {
    const result = 100 - highWidth - lowWidth;

    if (result < 0) {
      return 0;
    }

    if (result > 100) {
      return 100;
    }

    return result;
  }

  render() {
    const { mealPrefix, patientTargets, readingVals, title } = this.props;
    const { mealTag } = this.state;
    const low = patientTargets.get('targetLow') || 80;
    const high = patientTargets.get('targetHigh') || 180;
    const filteredVals = mealTag === 'fasting' ?
      readingVals :
      readingVals.filter((val) => val.get('tags') && val.get('tags').includes(mealTag));
    // total values
    const totalCount = filteredVals.size;
    const totalGlucose = filteredVals.reduce((acc, cur) => acc + cur.get('bloodGlucose'), 0);
    const averageReading = totalGlucose / totalCount;
    const averageReadingText = normalizeAverageReadingText(averageReading);
    // High values
    const highValues = filteredVals.filter((r) => r.get('bloodGlucose') >= high);
    const highCount = highValues.size;
    const highWidth = Math.round(highCount / totalCount * 100) || 0;
    // Low values
    const lowValues = filteredVals.filter((r) => r.get('bloodGlucose') <= low);
    const lowCount = lowValues.size;
    const lowWidth = Math.round(lowCount / totalCount * 100) || 0;
    // goal values
    const goalWidth = this.countGoalWidth(highWidth, lowWidth);
    const goalCount = totalCount - highCount - lowCount || 0;
    const goalClasses = !!totalCount ?
      'c-progress__bar c-progress__bar--success' :
      'c-progress__bar c-progress__bar--empty';
    const barHeight = 4;
    const data = totalCount > 0 ? [lowWidth, highWidth, goalWidth] : [];
    // button classes
    const buttonClasses = 'c-button c-button--sm c-button--condensed';

    return (
      <div className="c-card">
        <div className="c-card__header c-card__header--spaced c-card__header--borderless c-card__header--top-padded-sm">
          <h3 className="c-card__title c-card__title--bold">{title}</h3>
        </div>
        {!!mealPrefix &&
          <div className="c-button-group c-button-group--trends-card">
            <button onClick={this.setMeal()} className={`${buttonClasses} ${mealTag.includes('fasting') ? 'c-button--active' : 'c-button--hollow'}`}>All Meals</button>
            <button onClick={this.setMeal('breakfast')} className={`${buttonClasses} ${mealTag.includes('breakfast') ? 'c-button--active' : 'c-button--hollow'}`}>Breakfast</button>
            <button onClick={this.setMeal('lunch')} className={`${buttonClasses} ${mealTag.includes('lunch') ? 'c-button--active' : 'c-button--hollow'}`}>Lunch</button>
            <button onClick={this.setMeal('dinner')} className={`${buttonClasses} ${mealTag.includes('dinner') ? 'c-button--active' : 'c-button--hollow'}`}>Dinner</button>
          </div>}
        <div className="c-card__body c-card__body--unpadded c-pie-trend-group">
          <div className="c-pie-trend-group__pie">
            <PieChartSVG data={data} />
          </div>
          <div className="c-pie-trend-group__numbers">
            <div className="c-pie-trend-group__primary-numbers">
              <div className="c-pie-trend-group__totals">
                <h3 style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {!!mealPrefix && mealTag !== 'fasting' &&
                    <MealIconChooser className="c-icon c-icon--wide c-icon--alt" mealTag={this.state.mealTag} />}
                  <span style={{ marginLeft: '8px' }}>Avg Reading</span>
                </h3>
                <h2 className="h-bold">
                  {averageReadingText}
                </h2>
              </div>
            </div>
            <div className="c-pie-trend-group__progress-bar">
              <svg className="c-progress" width="100%" viewBox={`0 0 100 ${barHeight}`}>
                <rect className="c-progress__bar c-progress__bar--danger" x={0} y="0" width={lowWidth} height={barHeight} />
                <rect className={goalClasses} x={lowWidth} y="0" width={goalWidth} height={barHeight} />
                <rect className="c-progress__bar c-progress__bar--warning" x={lowWidth + goalWidth} y="0" width={highWidth} height={barHeight} />
              </svg>
            </div>
            <div className="c-pie-trend-group__secondary-numbers">
              <div className="c-pie-trend-group__totals">
                <CircleIcon className="c-icon--warning" />
                <span className="c-pie-trend-group__label">High</span>
                <b>{highCount}</b>
                <span className="c-pie-trend-group__dots" />
                <span className="h-bold">{highWidth}%</span>
              </div>
              <div className="c-pie-trend-group__totals">
                <CircleIcon className="c-icon--success" />
                <span className="c-pie-trend-group__label">In Range </span>
                <b>{goalCount}</b>
                <span className="c-pie-trend-group__dots" />
                <span className="h-bold">{!!totalCount ? goalWidth : 0}%</span>
              </div>
              <div className="c-pie-trend-group__totals">
                <CircleIcon className="c-icon--danger" />
                <span className="c-pie-trend-group__label">Low</span>
                <b>{lowCount}</b>
                <span className="c-pie-trend-group__dots" />
                <span className="h-bold">{lowWidth}%</span>
              </div>
            </div>
            <div className="c-pie-trend-group__totals c-pie-trend-group__totals--bottom">
              <h2 style={{ marginRight: '8px' }}>{totalCount}</h2>
              <h3>Total Readings</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/* eslint-enable */
