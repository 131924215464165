import PropTypes from 'prop-types';
import React from 'react';
import DeclineBtn from './DeclineBtn';
import TableButtonsBlock from '../../../../../components/shared/tableButtonsBlock/TableButtonsBlock';

const propTypes = {
  item: PropTypes.shape({
    invitationId: PropTypes.number
  }),
  itemsListCount: PropTypes.number,
  memoizedFetch: PropTypes.func.isRequired,
  deleteSentInvitation: PropTypes.func.isRequired,
  acceptInvitationFromPractice: PropTypes.func.isRequired
};

export default function ContactsIAmSharingWithReceivedInvitesTableButtons({
  item,
  memoizedFetch,
  itemsListCount,
  deleteSentInvitation,
  acceptInvitationFromPractice
}) {
  const handleAccept = async () => {
    try {
      await acceptInvitationFromPractice(item.invitationId);
      memoizedFetch();
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  return (
    <TableButtonsBlock>
      <button
        className="c-button c-button--hollow c-button--condensed c-button--trailing h-ws-pre jest-cancel"
        onClick={handleAccept}
      >
        Accept
      </button>
      <DeclineBtn
        deleteSentInvitation={deleteSentInvitation}
        memoizedFetch={memoizedFetch}
        item={item}
        itemsListCount={itemsListCount}
      />
    </TableButtonsBlock>
  );
}

ContactsIAmSharingWithReceivedInvitesTableButtons.propTypes = propTypes;
