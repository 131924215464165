import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { StyledDialogTitle } from '../../muiStyledComponents/StyledDialogTitle';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import DialogContent from '@material-ui/core/DialogContent';
import { StyledDialogActions } from '../../muiStyledComponents/StyledDialogActions';
import confirm from '../../../utils/ConfirmHelpers';
import { useStyles } from './ContactsRemoveContact.styles';

const propTypes = {
  item: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  deleteYourSharingCircle: PropTypes.func.isRequired
};

export default function ContactsRemoveContact({
  closeModal,
  updateTable,
  item,
  deleteYourSharingCircle
}) {
  const descriptionElementRef = useRef(null);
  const [value, setValue] = useState('Both');
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleRemove = async () => {
    const confirmOptions = { confirmText: 'Confirm', cancelText: 'Cancel', headerText: 'Remove Contact' };

    if (await confirm('Please confirm you want to remove this contact?', confirmOptions)) {
      try {
        (value === 'Both' || value === 'Emergency Contacts') && await deleteYourSharingCircle(item.relationId.emergency_contact);
        (value === 'Both' || value === 'Sharing Circle') && await deleteYourSharingCircle(item.relationId.carer);
        updateTable();
        closeModal();
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    }
  };

  return (
    <div className={classes.container}>
      <StyledDialogTitle>
        <strong>Remove Contact</strong>
      </StyledDialogTitle>

      <h4 className={classes.title}><strong>Remove current contact from:</strong></h4>

      <DialogContent
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup aria-label="invite type" name="inviteType" value={value} onChange={handleChange}>
            {item.type.map((el, key) => (
              <FormControlLabel
                key={key}
                className={classes.label}
                value={el}
                control={<Radio inputProps={{ 'data-testid': `radio-button-${el}` }}/>}
                label={el}
              />
            ))}
            <FormControlLabel
              className={classes.label}
              value="Both"
              control={<Radio inputProps={{ 'data-testid': 'radio-button-both' }}/>}
              label="Both"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <StyledDialogActions>
        <button className="c-button c-button--hollow jest-cancel" onClick={closeModal}>Cancel</button>
        <button className="c-button c-button--action jest-proceed" onClick={handleRemove}>Remove</button>
      </StyledDialogActions>
    </div>
  );
}

ContactsRemoveContact.propTypes = propTypes;
