import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function InsulinIcon({ className }) {
  let classes = 'c-icon c-icon--insulin';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <polygon className="c-icon__fill c-icon__fill--blue" points="6.18 15.208 10.863 10.674 15.026 13.615 9.9 18.85" />
      <path className="c-icon__fill c-icon__fill--alt" d="M22.7802,4.9589 L20.0092,2.1869 C19.7592,1.9379 19.3562,1.9379 19.1072,2.1869 C18.8592,2.4359 18.8592,2.8389 19.1072,3.0869 L19.6102,3.5899 L17.6812,5.5199 L19.4472,7.2859 L21.3772,5.3559 L21.8802,5.8589 C22.0042,5.9829 22.1672,6.0459 22.3302,6.0459 C22.4932,6.0459 22.6562,5.9839 22.7802,5.8589 C23.0292,5.6099 23.0292,5.2079 22.7802,4.9589" />
      <polygon className="c-icon__fill c-icon__fill--alt" points="11.826 15.839 11.859 15.871 13.02 14.71 13.153 14.577 13.12 14.544" />
      <polygon className="c-icon__fill c-icon__fill--alt" points="13.748 13.917 13.78 13.949 14.978 12.752 15.075 12.655 15.042 12.622" />
      <polygon className="c-icon__fill c-icon__fill--alt" points="13.153 14.577 13.153 14.577 13.02 14.71" />
      <polygon className="c-icon__fill c-icon__fill--alt" points="15.075 12.655 15.075 12.655 14.978 12.752" />
      <polygon className="c-icon__fill c-icon__fill--alt" points="11.826 15.839 11.199 16.466 11.231 16.499 13.02 14.71 11.859 15.871" />
      <polygon className="c-icon__fill c-icon__fill--alt" points="13.748 13.917 13.12 14.544 13.153 14.577 14.978 12.752 13.78 13.949" />
      <path className="c-icon__fill c-icon__fill--alt" d="M10.6581 14.6698C10.4851 14.4968 10.2031 14.4968 10.0311 14.6698 9.8571 14.8438 9.8571 15.1248 10.0311 15.2978L11.1991 16.4658 11.8261 15.8388 10.6581 14.6698zM11.9521 12.7479C11.7791 12.9219 11.7791 13.2029 11.9521 13.3759L13.1201 14.5439 13.7481 13.9169 12.5801 12.7479C12.4071 12.5749 12.1251 12.5749 11.9521 12.7479" />
      <path className="c-icon__fill c-icon__fill--alt" d="M18.1923,9.4725 L17.4393,10.2245 L16.7643,10.9005 L16.5183,11.1465 L15.6703,11.9945 L14.5023,10.8265 C14.3283,10.6535 14.0463,10.6535 13.8743,10.8265 C13.7013,10.9995 13.7013,11.2815 13.8743,11.4535 L15.0753,12.6555 L14.9783,12.7515 L13.1533,14.5765 L13.0203,14.7095 L11.2313,16.4985 L11.1983,16.4655 L9.6023,18.0625 C9.5373,18.1265 9.4503,18.1625 9.3593,18.1625 C9.2673,18.1625 9.1813,18.1265 9.1163,18.0625 L6.9043,15.8505 C6.7713,15.7165 6.7713,15.4985 6.9043,15.3655 L15.0743,7.1955 L15.7393,6.5315 L18.4353,9.2285 L18.1923,9.4725 Z M20.7583,9.7515 L19.0923,8.0855 L16.8803,5.8745 L15.2143,4.2075 C14.9653,3.9585 14.5623,3.9595 14.3143,4.2075 C14.0653,4.4565 14.0653,4.8605 14.3143,5.1085 L14.8383,5.6325 L14.6193,5.8505 C14.6113,5.8585 14.6023,5.8665 14.5943,5.8735 L6.0043,14.4645 C5.3943,15.0745 5.3743,16.0525 5.9423,16.6875 L5.5733,17.0555 C5.1913,17.4385 5.1913,18.0595 5.5733,18.4425 L5.7793,18.6475 L2.1113,22.3145 C1.9633,22.4635 1.9633,22.7055 2.1113,22.8555 C2.1863,22.9295 2.2843,22.9665 2.3823,22.9665 C2.4803,22.9665 2.5773,22.9295 2.6523,22.8555 L6.3193,19.1875 L6.5253,19.3935 C6.9073,19.7765 7.5283,19.7755 7.9113,19.3935 L8.2803,19.0245 C8.5773,19.2905 8.9573,19.4365 9.3593,19.4365 C9.7913,19.4365 10.1963,19.2685 10.5023,18.9635 L19.0923,10.3725 L19.3353,10.1295 L19.8583,10.6535 C19.9823,10.7775 20.1453,10.8395 20.3083,10.8395 C20.4713,10.8395 20.6343,10.7775 20.7583,10.6535 C21.0073,10.4045 21.0073,10.0005 20.7583,9.7515 L20.7583,9.7515 Z" />
    </svg>
  );
}

InsulinIcon.propTypes = propTypes;

export default InsulinIcon;
