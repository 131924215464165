import { stringify } from 'query-string';

/*
 * This function is made for usage for all tables, since there are
 * lots of tables, and they have tons of similar logic, what I have created is this small parser
 * for url, since backend accepts params like roles[] or q[widget] and they
 * have problem to be parsed from url. Also, this is weak point since this params
 * should be written here to be parsed.
 */
export function returnQueryString(pQuery) {
  // We set page to -1 because page will become 0 if not present
  const { perPage = 5, page = -1, ...rest } = pQuery;
  const queryMap = { ...rest, perPage, page: Number(page) + 1 };

  return stringify(queryMap);
}

/*
 * Simple helper to manage page number in url
 */
export function returnUrlQueryIfSingleItemOnNotFirstPage({ page, ...rest }) {
  const pageNumber = Number(page) !== 0 ? String(page - 1) : page;

  return { ...rest, page: pageNumber };
}
