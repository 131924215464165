import React, { useRef, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import { Container, Grid } from '@material-ui/core';
import { contactsViewsConstants } from '../../../../../constants/contactsInviteViews';
import { StyledDialogActions } from '../../../../../components/muiStyledComponents/StyledDialogActions';
import { useInboxActionsContext } from '../../../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';
import Text from '../../../../../shared/Text/Text';
import { useStyles } from './ContactsInviteTypes.styles';

export default function ContactsInviteTypes() {
  const descriptionElementRef = useRef(null);
  const classes = useStyles();

  const { closeModal, switchToView } = useInboxActionsContext();

  const { hcpSearchView, scFormView, ecWelcomeView } = contactsViewsConstants;
  const rolesList = [
    { key: hcpSearchView, title: 'Healthcare Provider' },
    { key: scFormView, title: 'Sharing Circle Contact' },
    { key: ecWelcomeView, title: 'Emergency Contact' }
  ];

  const [value, setValue] = useState(hcpSearchView);

  const handleChange = (event) => setValue(event.target.value);

  const switchView = () => switchToView(value);

  return (
    <Container maxWidth="sm" className="h-padded-lg" data-testid="invites-type">
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <Grid container>
          <Grid item xs={12} style={{ padding: '16Px 24Px' }}>
            <Text font="bold" alignText="center" fontSize={24} lineHeight={36}>
              Select contact type you want to invite
            </Text>
          </Grid>
        </Grid>

        <DialogContent
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup aria-label="invite type" name="inviteType" value={value} onChange={handleChange}>
              {rolesList.map((el, key) => (
                <FormControlLabel
                  key={key}
                  className={classes.label}
                  value={el.key}
                  control={<Radio inputProps={{ 'data-testid': `radio-button-${el.key}` }}/>}
                  label={el.title}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <StyledDialogActions>
          <button data-testid="jest-cancel" className="c-button c-button--hollow" onClick={closeModal}>Cancel</button>
          <button data-testid="jest-proceed" className="c-button c-button--action" onClick={switchView}>Next</button>
        </StyledDialogActions>
      </Grid>
    </Container>
  );
}
