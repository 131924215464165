import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelectField from './muiComponents/MuiSelectField';

const propTypes = {
  hintText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  food: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  border: PropTypes.object.isRequired
};

function MeasuresSelect({ food, border, onChange, hintText, label, name }) {
  let menuItemsArray = food.alt_measures;

  // For use case when alt_measures is not provided default measure will be provided
  if (!food.alt_measures || !food.alt_measures.length) {
    menuItemsArray = [
      {
        measure: food.serving_unit,
        qty: food.serving_qty,
        serving_weight: food.serving_weight_grams
      }
    ];
  }

  return (
    <Field
      disabled={!food.alt_measures}
      component={MuiSelectField}
      className="h-mui-native__select"
      onChange={onChange}
      hintText={hintText}
      label={label}
      fullWidth
      name={name}
      underlineDisabledStyle={border}
    >
      {menuItemsArray.map((item, key) => (
        <MenuItem data-testid="menuitem" key={key} value={item.measure}>{`${item.qty} ${item.measure}`}</MenuItem>
      ))}
    </Field>
  );
}

MeasuresSelect.propTypes = propTypes;

export default MeasuresSelect;
