import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

function MoodIcon({ className }) {
  let classes = 'c-icon c-icon--mood';

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <path
        className="c-icon__fill c-icon__fill--alt"
        d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M8.08695652,10.6956522 C9.04745174,10.6956522 9.82608696,9.91701696 9.82608696,8.95652174 C9.82608696,7.99602652 9.04745174,7.2173913 8.08695652,7.2173913 C7.1264613,7.2173913 6.34782609,7.99602652 6.34782609,8.95652174 C6.34782609,9.91701696 7.1264613,10.6956522 8.08695652,10.6956522 Z M15.9130435,10.6956522 C16.8735387,10.6956522 17.6521739,9.91701696 17.6521739,8.95652174 C17.6521739,7.99602652 16.8735387,7.2173913 15.9130435,7.2173913 C14.9525483,7.2173913 14.173913,7.99602652 14.173913,8.95652174 C14.173913,9.91701696 14.9525483,10.6956522 15.9130435,10.6956522 Z M12,18.0396963 C15.1216095,18.0396963 17.6521739,15.7231602 17.6521739,12.8655681 C17.6521739,12.7904747 6.34782609,12.8283193 6.34782609,12.8655681 C6.34782609,15.7231602 8.87839054,18.0396963 12,18.0396963 Z"
      />
    </svg>
  );
}

MoodIcon.propTypes = propTypes;

export default MoodIcon;
