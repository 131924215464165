import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';

// This hook for tables for handling pages and rowsPerPage changes
const usePaginatorHook = () => {
  const history = useHistory();
  const { search } = useLocation();
  const parsedQuery = parse(search);

  const handleChangePage = (event, newPage) => {
    const { perPage = 5 } = parsedQuery;
    const updatedQuery = { ...parsedQuery, perPage, page: newPage };
    const url = stringify(updatedQuery);

    history.replace({ search: url });
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    const updatedQuery = { ...parsedQuery, page: 0, perPage };
    const url = stringify(updatedQuery);

    history.replace({ search: url });
  };

  return {
    parsedQuery,
    handleChangeRowsPerPage,
    handleChangePage
  };
};

export default usePaginatorHook;
