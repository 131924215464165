/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getNotificationSettings,
  getPatientMeters,
  getPatientTargets,
  getPatientTesting
} from '../actions/settingsActions';
import { getAvatar } from '../actions/avatarActions';
import { getAboutMe, getPatientMedications } from '../actions/profileActions';
import { getLastKnownReading } from '../actions/readingsActions';
import { getStatus } from '../actions/statusActions';
import { clearSelectedPatient } from '../actions/selectedPatientActions';
import { requireDataAccess } from '../utils/routeHelpers';
import onEnter from './onEnter';
import PatientApp from '../components/PatientApp';
import { immutableToJsHOC } from './ImmutableToJsHOC';

const propTypes = {
  avatar: PropTypes.string,
  clearSelectedPatient: PropTypes.func.isRequired,
  getAboutMe: PropTypes.func.isRequired,
  getAvatar: PropTypes.func,
  getNotificationSettings: PropTypes.func.isRequired,
  getPatientMedications: PropTypes.func.isRequired,
  getPatientMeters: PropTypes.func.isRequired,
  getPatientTargets: PropTypes.func.isRequired,
  getPatientTesting: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  getLastKnownReading: PropTypes.func.isRequired,
  status: PropTypes.object
};

function PatientAppContainer(props) {
  useEffect(() => {
    props.clearSelectedPatient();
    props.getLastKnownReading(); // Reset date to last known readings
    props.getAboutMe();
    props.getAvatar();
    props.getNotificationSettings();
    props.getPatientTesting();
    props.getPatientMedications();
    props.getStatus();
    props.getPatientTargets();
    props.getPatientMeters();
  }, []);

  return (
    <PatientApp
      avatar={props.avatar}
      status={props.status}
    />
  );
}

PatientAppContainer.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    avatar: state.avatar.get('image')
  };
}

export default connect(mapStateToProps, {
  clearSelectedPatient,
  getAboutMe,
  getAvatar,
  getNotificationSettings,
  getPatientMedications,
  getPatientMeters,
  getPatientTargets,
  getPatientTesting,
  getStatus,
  getLastKnownReading
})(onEnter(requireDataAccess)(immutableToJsHOC(PatientAppContainer)));
