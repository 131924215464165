import apiService from './apiService';
import { prepTestingTimeForForm } from '../utils/testingTimeHelpers';

async function deletePatientMeter(meterId) {
  const { data } = await apiService.delete(`/meters/${meterId}`);
  return data;
}

async function getNotificationSettings(type = 'patient') {
  const { data } = await apiService.get(`/notifications?settingsFor=${type}`);
  return data;
}

async function getPatientMeters() {
  const { data } = await apiService.get('/meters', { apiVersion: '/v2' });
  return data;
}

async function getPatientTargets() {
  const { data } = await apiService.get('/targets');
  return data;
}

async function postPatientMeter(serialNumber) {
  const { data } = await apiService.post('/meters', { serialNumber });
  return data;
}

async function postPatientMeterWithToken(token) {
  const { data } = await apiService.post('/meters/token', { token });
  return data;
}

async function putNotificationSettings(notificationSettings, settingsFor = 'patient') {
  const { data } = await apiService.put('/notifications', { notificationSettings, settingsFor });
  return data;
}

async function putPatientTargets(targets) {
  const { data } = await apiService.put('/targets', targets);
  return data;
}

async function getPatientTesting() {
  const { data } = await apiService.get('/testing');
  return data.testingTimes.map(prepTestingTimeForForm);
}

async function postPatientTesting(testing) {
  return await apiService.post('/testing_windows', { ...testing });
}

async function deletePatientTesting(testingId) {
  return await apiService.delete(`/testing_windows/${testingId}`);
}

async function putPatientTesting(testing, testingId) {
  return await apiService.put(`/testing_windows/${testingId}`, { ...testing });
}

export default {
  deletePatientMeter,
  getPatientMeters,
  getPatientTesting,
  deletePatientTesting,
  putPatientTesting,
  postPatientTesting,
  postPatientMeter,
  postPatientMeterWithToken,
  getNotificationSettings,
  putNotificationSettings,
  getPatientTargets,
  putPatientTargets
};
