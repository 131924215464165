/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
  transform: PropTypes.string
};


function ShapeBreakfast(props) {
  return (
    <path
      {...props}
      d="M0.488200061,18.2799997 L0.361535855,18.2339959 C-0.15825145,18.0452114 -0.153590701,17.745803 0.367358171,17.5668245 L5.28396655,15.8776626 C5.80696943,15.6979785 6.04028927,15.1441781 5.8071541,14.64511 L3.60687232,9.93500743 C3.37281794,9.43397155 3.58782735,9.22555344 4.08275071,9.46736303 L8.75373567,11.7495123 C9.25061043,11.9922753 9.80718844,11.7656614 9.99523145,11.2479155 L11.7699427,6.36153586 C11.9587272,5.84174855 12.2581356,5.8464093 12.4371141,6.36735817 L14.126276,11.2839666 C14.3059601,11.8069694 14.8597605,12.0402893 15.3588286,11.8071541 L20.0689312,9.60687232 C20.569967,9.37281794 20.7783852,9.58782735 20.5365756,10.0827507 L18.2544263,14.7537357 C18.0116633,15.2506104 18.2382772,15.8071884 18.7560231,15.9952315 L23.6424027,17.7699427 C24.0292337,17.910438 24.1255998,18.1122023 23.9348644,18.2799997 L0.488200061,18.2799997 Z"
    />
  );
}

ShapeBreakfast.propTypes = propTypes;

export default ShapeBreakfast;
