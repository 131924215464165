/* istanbul ignore file */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import idleTimer from 'idle-timer';
import moment from 'moment';
import { refreshAccessToken, signOut } from '../actions/authenticationActions';
import PatientAppContainer from './PatientAppContainer';
import OnboardContainer from './OnboardContainer';
import SharingCircleMainContainer from './SharingCircleMainContainer';
import { countlyEndUserSession } from '../services/countlyService';

const propTypes = {
  expires: PropTypes.number,
  hasAcceptedTermsAndConditions: PropTypes.bool,
  idleTime: PropTypes.number,
  refreshAccessToken: PropTypes.func.isRequired,
  refreshToken: PropTypes.string,
  signOut: PropTypes.func.isRequired,
  staySignedIn: PropTypes.bool
};

function AuthenticatedAppContainer(props) {
  const history = useHistory();
  const isFirstRender = useRef(true);
  const idleMonitor = useRef(null);

  const idleCallback = () => {
    if (!props.staySignedIn) {
      // Put Countly in to offline mode until user will authorize
      countlyEndUserSession();
      props.signOut(props.refreshToken);
      return;
    }

    if (moment(props.expires).isBefore(moment())) {
      props.refreshAccessToken(props.refreshToken);
    }
  };

  useEffect(() => {
    if (props.hasAcceptedTermsAndConditions === false) {
      history.push('/terms-and-conditions');
    }
  }, []);

  useEffect(() => {
    if (!props.staySignedIn) {
      idleMonitor.current = idleTimer({
        callback: idleCallback,
        idleTime: props.idleTime // 15 mins
      });
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Skip execution on first render
    }
    !props.refreshToken && history.push('/welcome/sign-in');
  }, [props.refreshToken]);

  useEffect(() => {
    return () => {
      if (!props.staySignedIn && idleMonitor) {
        idleMonitor.current.destroy();
      }
    };
  }, [props.refreshToken]);

  return (
    <Switch>
      <Route path="/onboarding" component={OnboardContainer}/>
      <Route path="/sharing-circle" component={SharingCircleMainContainer}/>
      <Route path="/" component={PatientAppContainer}/>
    </Switch>
  );
}

AuthenticatedAppContainer.propTypes = propTypes;

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    expires: state.authentication.get('expires'),
    idleTime: state.authentication.get('idleTime'),
    refreshToken: state.authentication.get('refreshToken'),
    staySignedIn: state.authentication.get('staySignedIn'),
    hasAcceptedTermsAndConditions: state.status.get('hasAcceptedTermsAndConditions')
  };
}

export default connect(mapStateToProps, {
  refreshAccessToken,
  signOut
})(AuthenticatedAppContainer);
