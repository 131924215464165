import actionTypes from '../constants/actionTypes';
import healthDataService from '../services/healthDataService';
import { makePGHDDeviceList } from '../utils/deviceList';
import { adjustTimeByOffset } from 'src/utils/dateFormatter';

// GET_RANGE_OF_PGHD

function getRangeOfPGHDFailure(err) {
  return {
    type: actionTypes.GET_RANGE_OF_PGHD_FAILURE,
    error: true,
    payload: err
  };
}

function getRangeOfPGHDStart() {
  return {
    type: actionTypes.GET_RANGE_OF_PGHD_START
  };
}

// Since our app should not have any date adjusting according to user timezone
// we need, for our app to work properly,
// modify timestamp, from 2023-12-29T01:01:20.000Z with utcOffset 7200 produce
// 2023-12-29T03:01:20.000Z
function getRangeOfPGHDSuccess(data) {
  const foodsDateUpdated = data.foods?.map(el => ({ ...el, timestamp: adjustTimeByOffset(el.timestamp, el.utcOffset) }));
  const sleepsDateUpdated = data.sleeps?.map(el => ({
    ...el,
    startTimestamp: adjustTimeByOffset(el.startTimestamp, el.utcOffset),
    endTimestamp: adjustTimeByOffset(el.endTimestamp, el.utcOffset)
  }));
  const summariesDateUpdated = data.summaries?.map(el => ({ ...el, timestamp: adjustTimeByOffset(el.timestamp, el.utcOffset) }));
  const weightsDateUpdated = data.weights?.map(el => ({ ...el, timestamp: adjustTimeByOffset(el.timestamp, el.utcOffset) }));
  const workoutsDateUpdated = data.workouts?.map(el => ({
    ...el,
    startTimestamp: adjustTimeByOffset(el.startTimestamp, el.utcOffset),
    endTimestamp: adjustTimeByOffset(el.endTimestamp, el.utcOffset)
  }));

  return {
    type: actionTypes.GET_RANGE_OF_PGHD_SUCCESS,
    payload: {
      foods: foodsDateUpdated,
      sleeps: sleepsDateUpdated,
      summaries: summariesDateUpdated,
      weights: weightsDateUpdated,
      workouts: workoutsDateUpdated
    }
  };
}

function makePGHDDeviceListSuccess(data) {
  const foodsDevices = makePGHDDeviceList(data.foods);
  const sleepsDevices = makePGHDDeviceList(data.sleeps);
  const summariesDevices = makePGHDDeviceList(data.summaries);
  const weightsDevices = makePGHDDeviceList(data.weights);
  const workoutsDevices = makePGHDDeviceList(data.workouts);

  return {
    type: actionTypes.GET_PGHD_DEVICE_LIST_SUCCESS,
    payload: {
      foodsDevices,
      sleepsDevices,
      summariesDevices,
      weightsDevices,
      workoutsDevices
    }
  };
}

export function getRangeOfPGHD(from, to) {
  return async function (dispatch) {
    dispatch(getRangeOfPGHDStart());

    try {
      const pghd = await healthDataService.getRangeOfPGHD(from, to);
      dispatch(getRangeOfPGHDSuccess(pghd));
      dispatch(makePGHDDeviceListSuccess(pghd));
    } catch (err) {
      dispatch(getRangeOfPGHDFailure(err));
    }
  };
}
