/* istanbul ignore file */
import React from 'react';
import { NavLink } from 'react-router-dom';

function WelcomeNav() {
  return (
    <nav className="c-slash-nav">
      <NavLink
        className="c-slash-nav__invert-item"
        activeClassName="c-slash-nav__invert-item--active"
        to="/welcome/register"
      >
        Registration
      </NavLink>
      <span className="c-slash-nav__item">/</span>
      <NavLink
        className="c-slash-nav__invert-item"
        activeClassName="c-slash-nav__invert-item--active"
        to="/welcome/sign-in"
      >
        Sign In
      </NavLink>
    </nav>
  );
}

export default WelcomeNav;
