/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  transform: PropTypes.string
};

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

function GraphIconGroup({ color = '#2da9df', strokeWidth = 2, transform = 'translate(0, 0)' }) {
  const radius = 5;
  const circles = [
    { cx: 6, cy: 29 },
    { cx: 16, cy: 12 },
    { cx: 29, cy: 24 },
    { cx: 39, cy: 7 }
  ];
  const angles = {
    bottomLeft: 110,
    bottomRight: 45,
    topLeft: 220,
    topRight: 305
  };

  return (
    <g id="graph-icon" fill="none" strokeWidth={strokeWidth} stroke={color} transform={transform}>
      {circles.map((circle, i) => {
        return <circle key={i} cx={circle.cx} cy={circle.cy} r={radius} />;
      })}
      <line
        x1={circles[0].cx + radius * Math.cos(toRadians(angles.topRight))}
        y1={circles[0].cy + radius * Math.sin(toRadians(angles.topRight))}
        x2={circles[1].cx + radius * Math.cos(toRadians(angles.bottomLeft))}
        y2={circles[1].cy + radius * Math.sin(toRadians(angles.bottomLeft))}
      />
      <line
        x1={circles[1].cx + radius * Math.cos(toRadians(angles.bottomRight))}
        y1={circles[1].cy + radius * Math.sin(toRadians(angles.bottomRight))}
        x2={circles[2].cx + radius * Math.cos(toRadians(angles.topLeft))}
        y2={circles[2].cy + radius * Math.sin(toRadians(angles.topLeft))}
      />
      <line
        x1={circles[2].cx + radius * Math.cos(toRadians(angles.topRight))}
        y1={circles[2].cy + radius * Math.sin(toRadians(angles.topRight))}
        x2={circles[3].cx + radius * Math.cos(toRadians(angles.bottomLeft))}
        y2={circles[3].cy + radius * Math.sin(toRadians(angles.bottomLeft))}
      />
    </g>
  );
}

GraphIconGroup.propTypes = propTypes;

export default GraphIconGroup;
