import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  onCheck: PropTypes.func.isRequired
};

function MuiCheckbox(props) {
  const {
    className,
    checked,
    label,
    onCheck
  } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onCheck}
          name="checkedB"
          color="primary"
          inputProps={{ 'data-testid': 'checkedB' }}
        />
      }
      label={label || ''}
      className={className}
    />
  );
}

MuiCheckbox.propTypes = propTypes;

export default MuiCheckbox;
