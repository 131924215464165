import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import confirm from '../utils/ConfirmHelpers';
import MuiTextField from './muiComponents/MuiTextField';
import MuiTimePicker from './muiComponents/MuiTimePicker';
import MuiToggle from './muiComponents/MuiToggle';
import WarnAboutUnsavedChanges from '../containers/warnAboutUnsavedChanges';
import ChevronIcon from './icons/ChevronIcon';
import formValidationService from '../services/formValidation';
import { isDefaultMeal } from '../utils/testingTimeHelpers';
import { handleFormSubmissionErrors } from '../services/errorHandlingService';

class TestingAdjustment extends React.Component {
  static propTypes = {
    deletePatientTesting: PropTypes.func.isRequired,
    postPatientTesting: PropTypes.func.isRequired,
    putPatientTesting: PropTypes.func.isRequired,
    getPatientTesting: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    initialValues: PropTypes.shape({
      enabled: PropTypes.bool,
      endTime: PropTypes.string,
      label: PropTypes.string,
      startTime: PropTypes.string
    }),
    testingTimeId: PropTypes.number
  };

  deleteTestingTime = async () => {
    const confirmOptions = {
      showCancel: true,
      cancelText: 'Cancel',
      confirmText: 'Delete',
      headerText: 'Please confirm that you want to delete this testing window?'
    };

    if (await confirm(null, confirmOptions)) {
      await this.props.deletePatientTesting(this.props.testingTimeId);
      await this.props.getPatientTesting();

      this.props.history.push('/profile/testing-schedule');
    }
  };

  submitTestingTime = async (values) => {
    const formValues = {
      ...values,
      icon: isDefaultMeal(values.label) ? String(values.label.toLowerCase()).trim() : 'custom',
      enabled: Boolean(values.enabled),
      label: String(values.label).trim(),
      startTime: moment(values.startTime, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm'),
      endTime: moment(values.endTime, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm')
    };

    try {
      if (this.props.testingTimeId) {
        await this.props.putPatientTesting(formValues, this.props.testingTimeId);
      } else {
        await this.props.postPatientTesting(formValues);
      }

      this.props.getPatientTesting();
    } catch (err) {
      return handleFormSubmissionErrors(
        err,
        ['startTime', 'endTime']
      );
    }

    this.props.history.push('/profile/testing-schedule');
  }

  /* istanbul ignore next */
  validateFields(values) {
    return new formValidationService.Validation()
      .label(values.label)
      .startTime(values.startTime)
      .endTime(values.endTime)
      .endTimeAfterStartTime(values.startTime, values.endTime)
      .getErrors();
  }

  render() {
    const label = (this.props.initialValues && this.props.initialValues.label) || 'New Testing Time';
    const preexisting = Boolean(this.props.testingTimeId);
    const mealIcon = isDefaultMeal(label) ? label.toLowerCase() : 'timing';
    const iconClasses = `c-icon c-icon--xl c-icon--${mealIcon}`;
    const submitLabel = preexisting ? 'Update Testing Time' : 'Create Testing Time';

    return (
      <section className="c-section">
        <Link className="c-nav-header" to="/profile/testing-schedule">
          <ChevronIcon />
          <h2 className="c-nav-header__label">{label}</h2>
        </Link>
        <div className="h-center h-padded">
          <span className={iconClasses} />
        </div>
        <Form
          initialValues={this.props.initialValues}
          onSubmit={this.submitTestingTime}
          validate={this.validateFields}
        >
          {({ handleSubmit }) => {
            return (
              <form name="testingTimeForm" onSubmit={handleSubmit}>
                <WarnAboutUnsavedChanges />
                <div className="h-margin-bottom">
                  <div className="c-list-item">
                    <Field
                      component={MuiTextField}
                      label="Title"
                      name="label"
                    />
                  </div>
                  <div className="c-list-item">
                    <span className="h-bold">Active</span>
                    <Field
                      type="checkbox"
                      component={MuiToggle}
                      name="enabled"
                    />
                  </div>
                  <div className="c-list-item c-list-item--subheader">Time Ranges</div>
                  <div className="c-list-item">
                    <span className="h-bold c-list-item__flex-basis-50">Starts</span>
                    <Field
                      classes={{ root: 'c-list-item__flex-basis-50' }}
                      component={MuiTimePicker}
                      name="startTime"
                    />
                  </div>
                  <div className="c-list-item">
                    <span className="h-bold c-list-item__flex-basis-50">Ends</span>
                    <Field
                      classes={{ root: 'c-list-item__flex-basis-50' }}
                      component={MuiTimePicker}
                      name="endTime"
                    />
                  </div>
                </div>
                <div>
                  <input
                    className="c-button c-button--block c-button--full-width  c-button--action c-button--action h-margin-bottom"
                    type="submit"
                    value={submitLabel}
                  />
                </div>
              </form>
            );
          }}
        </Form>
        {preexisting && (
          <button
            data-testid="delete"
            className="c-button c-button--block c-button--full-width  c-button--danger-hollow"
            onClick={this.deleteTestingTime}
          >
            Delete Testing Time
          </button>
        )}
      </section>
    );
  }
}

export default TestingAdjustment;
