/* istanbul ignore file */
export default {
  ROOT: '/',
  CONTACTS: '/contacts',
  CONTACTS_SHARED_WITH_ME: '/contacts/shared-with-me',
  CONTACTS_SHARED_WITH_ME_RECEIVED_INVITATIONS: '/contacts/shared-with-me/received-invitations',
  CONTACTS_I_AM_SHARING_WITH: '/contacts/i-am-sharing',
  CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS: '/contacts/i-am-sharing/sent-invitations',
  CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS: '/contacts/i-am-sharing/received-invitations',
  DASHBOARD: '/dashboard',
  TRENDS: '/trends',
  LOG_BOOK: '/log-book',
  HEALTHFEED: '/healthfeed',

  // - Sharing circle routes
  SHARING_CIRCLE_SHARED_WITH_ME: '/sharing-circle',
  SHARING_CIRCLE_SHARED_WITH_ME_RECEIVED_INVITATIONS: '/sharing-circle/received-invitations',
  SHARING_CIRCLE_PROFILE: '/sharing-circle/profile',
  SHARING_CIRCLE_DASHBOARD: '/sharing-circle/dashboard',
  SHARING_CIRCLE_TRENDS: '/sharing-circle/trends',
  SHARING_CIRCLE_MHEALTH: '/sharing-circle/mhealth/:type',
  SHARING_CIRCLE_LOG_BOOK: '/sharing-circle/log-book'
};
