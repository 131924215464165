import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ContactsRemoveRelation from './ContactsRemoveRelation';
import confirm from 'src/utils/ConfirmHelpers';
import ModalContainer from 'src/components/modals/ModalContainer';

const propTypes = {
  deleteYourSharingCircle: PropTypes.func.isRequired,
  getLastKnownReading: PropTypes.func.isRequired,
  memoizedFetch: PropTypes.func.isRequired,
  setSelectedPatient: PropTypes.func.isRequired,
  itemsListCount: PropTypes.number,
  patient: PropTypes.object
};

export default function ContactsSharingWithMeTableButtons({
  patient,
  deleteYourSharingCircle,
  getLastKnownReading,
  memoizedFetch,
  itemsListCount,
  setSelectedPatient
}) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const viewData = async () => {
    await setSelectedPatient(patient);
    getLastKnownReading();
    history.push('/sharing-circle/dashboard');
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const updateTable = async () => {
    await memoizedFetch('edit', itemsListCount === 1);
  };

  const handleRemove = async () => {
    const confirmOptions = { confirmText: 'Confirm', cancelText: 'Cancel', headerText: 'Remove Contact' };

    if (patient.type.length > 1) {
      openModal();
      return;
    }

    if (await confirm('Please confirm you want to remove this contact?', confirmOptions)) {
      switch (patient.type[0]) {
      case 'Sharing Circle':
        try {
          await deleteYourSharingCircle(patient.relationId.carer);
        } catch (err) {
          console.error(err); // eslint-disable-line
        }
        break;
      case 'Emergency Contacts':
        try {
          await deleteYourSharingCircle(patient.relationId.emergency_contact);
        } catch (err) {
          console.error(err); // eslint-disable-line
        }
        break;
      default:
        break;
      }

      updateTable();
    }
  };

  const isSC = patient.type.includes('Sharing Circle');

  return (
    <>
      <Grid container style={{ columnGap: '16Px', display: 'flex', flexWrap: 'nowrap' }} justifyContent="center">
        {isSC && (
          <Grid item xs={6}>
            <button
              data-testid="view-data"
              className="c-button c-button--action c-button--condensed h-full-width h-ws-pre"
              onClick={viewData}
            >
              View Data
            </button>
          </Grid>
        )}

        {!isSC && <Grid item xs={6}/>}

        <Grid item xs={6}>
          <button
            data-testid="remove"
            className="c-button c-button--danger-hollow c-button--condensed h-full-width h-ws-pre"
            onClick={handleRemove}
          >
            Remove
          </button>
        </Grid>
      </Grid>

      <ModalContainer open={isOpen} onClose={closeModal}>
        <ContactsRemoveRelation
          patient={patient}
          deleteYourSharingCircle={deleteYourSharingCircle}
          closeModal={closeModal}
          updateTable={updateTable}
        />
      </ModalContainer>
    </>
  );
}

ContactsSharingWithMeTableButtons.propTypes = propTypes;
