/* istanbul ignore file */
import { connect } from 'react-redux';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import { getSharedWithReceivedInvitations } from '../actions/contactsSharedWithMeInvitesActions';
import SharingCircleSharedWithMeReceivedInvites
  from '../pages/sharingCircle/children/SharingCircleSharingWIthMeReceivedInvites/SharingCircleSharedWithMeReceivedInvites';

function mapStateToProps(state) {
  return {
    itemsList: state.contactsSharedWithMeInvitations.getIn(['sentInvitations', 'invitations']),
    itemsListCount: state.contactsSharedWithMeInvitations.getIn(['sentInvitations', 'sharedWithMeInvitationCount'])
  };
}

export default connect(mapStateToProps, {
  getSharedWithReceivedInvitations
})(immutableToJsHOC(SharingCircleSharedWithMeReceivedInvites));
