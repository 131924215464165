import PropTypes from 'prop-types';
import React from 'react';
import sc from '../../../assets/img/icons/sharing_circle_list.svg';
import ec from '../../../assets/img/icons/emergency_contact_list.svg';
import hcp from '../../../assets/img/icons/hcp_list.svg';
import scDisabled from '../../../assets/img/icons/sharing_circle_list_disabled.svg';
import ecDisabled from '../../../assets/img/icons/emergency_contact_list_disabled.svg';
import hcpDisabled from '../../../assets/img/icons/hcp_list_disabled.svg';
import useTypePresenterHook from '../../../hooks/useTypePresenterHook';

const propTypes = {
  type: PropTypes.array
};

export default function TypesPresenter({ type }) {
  const typeList = useTypePresenterHook(type);

  const iconsEnabled = {
    sharing_circle: sc,
    emergency_contacts: ec,
    practices: hcp
  };

  const iconsDisabled = {
    sharing_circle: scDisabled,
    emergency_contacts: ecDisabled,
    practices: hcpDisabled
  };

  if (!typeList) {
    return null;
  }

  if (typeof typeList === 'string') {
    return (
      <div>
        <span className="h-padded-right-sm">
          <img data-testid="type" src={iconsEnabled[String(typeList).toLowerCase()]}/>
        </span>
      </div>
    );
  }

  return (
    <div>
      {typeList.map((item, key) => (
        <span className="h-padded-right-sm" key={key}>
          {item.enabled ?
            <img src={iconsEnabled[String(item.name).toLowerCase()]} id="type"/> :
            <img src={iconsDisabled[String(item.name).toLowerCase()]} id="type"/>}
        </span>
      ))}
    </div>
  );
}

TypesPresenter.propTypes = propTypes;
