import PropTypes from 'prop-types';
import React from 'react';
import confirm from '../../utils/ConfirmHelpers';

const propTypes = {
  terminateAccount: PropTypes.func.isRequired,
  getTerminationDetails: PropTypes.func.isRequired
};

export default function TerminateAccountButton({ terminateAccount, getTerminationDetails }) {
  const handleClick = async () => {
    try {
      const { wording } = await getTerminationDetails();
      if (await confirm(wording)) {
        terminateAccount();
      }
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };

  return (
    <button
      data-testid="delete"
      className="c-button c-button--danger-hollow c-button--sign-out h-no-break-words"
      onClick={handleClick}
    >
      Delete Account
    </button>
  );
}

TerminateAccountButton.propTypes = propTypes;
