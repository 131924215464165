import apiService from './apiService';

async function getStatus() {
  const { data } = await apiService.get('/status');

  if (data.healthfeed) {
    window.healthfeedId = data.healthfeed.userId;
    window.healthfeedClientId = data.healthfeed.clientId || null;
    window.healthfeedSecret = data.healthfeed.clientSecret || null;
    window.healthfeedServerDomain = process.env.HEALTHFEED_SERVER_DOMAIN;
  }

  return data;
}

async function updateIsSeenTrackingMessageStatus() {
  const { data } = await apiService.put('/location', { enable_tracking_message_seen: true });

  return data;
}

async function updateIsAgreeTermsAndConditions() {
  await apiService.post('/accept_terms_and_conditions');
}

export default {
  getStatus,
  updateIsSeenTrackingMessageStatus,
  updateIsAgreeTermsAndConditions
};
