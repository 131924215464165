import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { scaleLinear, scaleTime } from 'd3-scale';
import TimeframeRecord from '../../records/TimeframeRecord';
import { createPerDayArray, makeYDomainValues } from '../../utils/healthDataHelpers';
import withElementWidth from '../../containers/withElementWidth';
import XAxis from './XAxis';
import { sv, xAxisLabel, yAxisLabel } from '../../assets/css/1_settings/variables.js';

const propTypes = {
  avgValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  source: PropTypes.string, // ex fitbit
  type: PropTypes.string, // ex steps
  timeframe: PropTypes.instanceOf(TimeframeRecord).isRequired,
  values: PropTypes.object, // TODO: refactor this to already and only be data (line 27)
  width: PropTypes.number
};

function BarChartSVG({ avgValue, height = 280, source, type, timeframe, values, width = 32 }) {
  const data = values && source && values[source];
  const dataArray = data && data.values;
  const dayArray = createPerDayArray(dataArray, type, timeframe.diffDays() + 1, timeframe.start);

  const margin = {
    bottom: sv.spacing.lg,
    left: sv.spacing.md,
    right: sv.spacing.md,
    top: sv.spacing.lg
  };

  // Set Ranges
  const inner = {
    width: width > 0 ? width - margin.left - margin.right : 32,
    height: height - margin.top - margin.bottom
  };

  // Set Domains
  const xDomain = [moment(timeframe.start), moment(timeframe.end)];
  const yDomain = makeYDomainValues(dayArray, type);
  // Set Scales
  const xScale = scaleTime().domain(xDomain).range([0, inner.width]);
  const yScale = scaleLinear().domain(yDomain).range([inner.height, 0]);
  // Make Axes
  const xAxis = timeframe.makeWidgetsAxis(xScale);

  return (
    <svg className="c-bar-chart" height={height} width={width}>
      <g height={inner.height} width={inner.width} transform={`translate(${margin.left}, ${margin.top})`}>
        <XAxis
          innerHeight={inner.height}
          sv={sv}
          xAxis={xAxis}
          xAxisLabel={xAxisLabel}
        />
        {dayArray.map((day, i) => {
          if (day[type] || day[type] === 0) {
            const xWidth = xScale(day.end) - xScale(day.start) - 4;
            const yHeight = yScale(yDomain[0]) - yScale(day[type]);

            return (
              <rect
                key={i}
                x={xScale(day.start) + 2}
                y={day[type] === 0 ? inner.height - 2 : yScale(day[type])}
                width={xWidth > 0 ? xWidth : 0.5}
                height={day[type] === 0 ? 2 : yHeight}
                fill={sv.color.textLight}
              />
            );
          }

          return null;
        })}
        {data && data.average &&
          <g>
            <line
              x1={0}
              x2={inner.width}
              y1={yScale(data.average)}
              y2={yScale(data.average)}
              stroke={sv.color.primaryColor}
              strokeWidth="2"
              strokeDasharray="4,4"
            />
            <rect x={sv.spacing.xs} y={yScale(data.average) - (sv.spacing.xs * 6)} height={sv.spacing.md + sv.spacing.xs} width={sv.spacing.xl} rx="2" stroke={sv.color.textSecondary} strokeWidth="2" fill="none" />
            <text x={sv.spacing.md} y={yScale(data.average) - sv.spacing.sm}>
              <tspan style={{ ...yAxisLabel, fill: sv.color.primaryColor }}>{avgValue}</tspan>
            </text>
          </g>}
      </g>
    </svg>
  );
}

BarChartSVG.propTypes = propTypes;
export { BarChartSVG };
export default withElementWidth(BarChartSVG);
