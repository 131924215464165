/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
  islarge: PropTypes.string,
  transform: PropTypes.string
};

function ShapeSnack(props) {
  if (props.islarge === 'true') {
    return (
      <g id="glyph:snack:monochrome_20x22@1x" transform="translate(-2.000000, -1.000000)" fillRule="nonzero" {...props}>
        <rect id="Rectangle-Copy-5" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24"/>
        <g id="Group" transform="translate(2.000000, 1.000000)" fill={props.fill}>
          <path d="M10.0202903,20.6053795 C9.238353,20.6053795 8.58487969,21.1805633 7.85444573,21.0355502 C4.22673907,19.8792228 0.599825711,16.10041 0.0405807549,11.6713886 C-0.237564478,8.61900592 0.948930266,6.57960005 2.53301707,5.70783158 C3.53296364,5.15753224 6.50000001,3.42376683 10.010326,5.70783158 C12.3687195,4.50688212 14.3305354,4.31414376 16.1001821,5.02916202 C18.1110649,5.8416507 20.2284343,7.87004434 19.9800713,11.6713886 C19.19285,16.3343218 15.9883263,20.2354867 12.0087759,21.0355502 C11.3651258,21.1500352 10.7126413,20.6053795 10.0202903,20.6053795 Z M8,14 C8.66666667,13.4030463 9,12.7363797 9,12 C9,11.2636203 8.66666667,10.5969537 8,10 C7.33333333,10.5969537 7,11.2636203 7,12 C7,12.7363797 7.33333333,13.4030463 8,14 Z M12,14 C12.6666667,13.4030463 13,12.7363797 13,12 C13,11.2636203 12.6666667,10.5969537 12,10 C11.3333333,10.5969537 11,11.2636203 11,12 C11,12.7363797 11.3333333,13.4030463 12,14 Z"/>
          <path d="M9.4,0 L10.0957275,0 L10.0957275,3.82723913 C11.0888965,1.82412234 12.5236541,0.822563923 14.4,0.822563923 C14.4,0.822563923 14.0838129,2.12498964 13.8373527,3.78781569 C11.0098014,3.52022443 10.025,5 10.025,5 C9.87050086,4.83455016 9.57438819,4.44720602 9.4,4.37499999 L9.4,0 Z"/>
        </g>
      </g>
    );
  }

  return (
    <g id="glyph:snack:color_20x20" transform="translate(-5.000000, -4.000000)" fillRule="nonzero" {...props}>
      <rect id="Rectangle-Copy-5" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24"/>
      <g id="glyph:snack:monochrome_20x20" transform="translate(5.000000, 4.000000)" fill={props.fill}>
        <path d="M7.01420322,14.9857305 C6.4668471,14.9857305 6.00941579,15.4040461 5.49811201,15.298582 C2.95871735,14.4576166 0.419877998,11.7093891 0.0284065284,8.48828262 C-0.166295135,6.26836794 0.664251186,4.78516367 1.77311195,4.15115024 C2.47307455,3.75093254 4.55000001,2.49001224 7.00722821,4.15115024 C8.65810366,3.27773245 10.0313748,3.1375591 11.2701275,3.65757238 C12.6777455,4.24847324 14.159904,5.72366861 13.9860499,8.48828262 C13.434995,11.8795068 11.1918284,14.7167176 8.40614314,15.298582 C7.95558807,15.3818438 7.49884892,14.9857305 7.01420322,14.9857305 Z M5.6,10.1818182 C6.06666667,9.74767004 6.3,9.2628216 6.3,8.72727273 C6.3,8.19172385 6.06666667,7.70687542 5.6,7.27272727 C5.13333333,7.70687542 4.9,8.19172385 4.9,8.72727273 C4.9,9.2628216 5.13333333,9.74767004 5.6,10.1818182 Z M8.4,10.1818182 C8.86666667,9.74767004 9.1,9.2628216 9.1,8.72727273 C9.1,8.19172385 8.86666667,7.70687542 8.4,7.27272727 C7.93333333,7.70687542 7.7,8.19172385 7.7,8.72727273 C7.7,9.2628216 7.93333333,9.74767004 8.4,10.1818182 Z"/>
        <path d="M6.58,0 L7.06700923,0 L7.06700923,2.78344664 C7.76222756,1.32663443 8.76655786,0.598228308 10.08,0.598228308 C10.08,0.598228308 9.85866906,1.54544701 9.6861469,2.75477505 C7.70686096,2.56016323 7.0175,3.63636364 7.0175,3.63636364 C6.9093506,3.51603648 6.70207173,3.23433165 6.58,3.18181818 L6.58,0 Z"/>
      </g>
    </g>
  );
}

ShapeSnack.propTypes = propTypes;

export default ShapeSnack;
