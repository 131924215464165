/* istanbul ignore file */
import apiService from './apiService';

async function getAvatar() {
  const { data } = await apiService({
    method: 'GET',
    headers: { Accept: 'image/png' },
    responseType: 'blob',
    url: '/avatar'
  });

  if (data.size > 0) {
    return URL.createObjectURL(data);
  }

  return null;
}

async function putAvatar(avatar) {
  const formData = new FormData();
  formData.append('avatar', avatar);

  return await apiService({
    data: formData,
    method: 'PUT',
    headers: { 'Content-Type': 'form/multipart' },
    url: '/avatar'
  });
}

export default {
  getAvatar,
  putAvatar
};
