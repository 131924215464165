/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import AboutMeForm from '../components/AboutMeForm/AboutMeForm';
import { getAboutMe, putAboutMe } from '../actions/profileActions';
import WithOnboardingTracking from './withOnboardingTracking';

function AboutMeFormContainer(props) {
  return (
    <WithOnboardingTracking type="about">
      <AboutMeForm {...props} />
    </WithOnboardingTracking>
  );
}

function mapStateToProps(state) {
  return {
    cellNumberVerified: state.profile.get('cellNumberVerified'),
    initialValues: {
      salutation: state.profile.get('salutation') || undefined,
      firstName: state.profile.get('firstName'),
      lastName: state.profile.get('lastName'),
      dateOfBirth: state.profile.get('dateOfBirth') || moment().format('MM/DD/YYYY'),
      diabetesType: state.profile.get('diabetesType') || undefined,
      gender: state.profile.get('gender') || undefined,
      addressOne: state.profile.get('addressOne'),
      addressTwo: state.profile.get('addressTwo'),
      cityVillage: state.profile.get('cityVillage'),
      stateProvince: state.profile.get('stateProvince'),
      postalCode: state.profile.get('postalCode'),
      cellNumber: state.profile.get('cellNumber'),
      homeNumber: state.profile.get('homeNumber') || undefined
    }
  };
}

export default connect(mapStateToProps, {
  getAboutMe,
  putAboutMe
})(AboutMeFormContainer);
