/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
// HOCs
import withViewportWidth from '../../containers/withViewportWidth';
// Styling
import { sv } from '../../assets/css/1_settings/variables.js';
// Components
import Pie from './Pie';

const propTypes = {
  data: PropTypes.array,
  width: PropTypes.number
};

function PieChartSVG({ data, width }) {
  const size = Math.max(width / 5, sv.spacing.xxl * 2); // sv.spacing.xxl * 2;

  const radius = (size * 0.7) / 2;
  const x = size / 2;
  const y = size / 2;

  return (
    <svg className="c-pie" width={size} height={size}>
      <Pie
        data={data}
        innerRadius={radius * 0.55}
        outerRadius={radius}
        cornerRadius={7}
        padAngle={0.02}
        radius={radius}
        sv={sv}
        x={x}
        y={y}
      />
    </svg>
  );
}

PieChartSVG.propTypes = propTypes;

export default withViewportWidth(PieChartSVG);
