/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as avatarActionCreators from 'src/actions/avatarActions';
import * as profileActionCreators from 'src/actions/profileActions';
import * as settingsActionCreators from 'src/actions/settingsActions';
import * as statusActionCreators from 'src/actions/statusActions';
import Profile from 'src/pages/profile/Profile';
import { immutableToJsHOC } from 'src/containers/ImmutableToJsHOC';

const propTypes = {
  actions: PropTypes.shape({
    avatar: PropTypes.shape({
      getAvatar: PropTypes.func.isRequired
    }).isRequired,
    profile: PropTypes.shape({
      getAboutMe: PropTypes.func.isRequired,
      getPatientMedications: PropTypes.func.isRequired
    }).isRequired,
    settings: PropTypes.shape({
      getNotificationSettings: PropTypes.func.isRequired,
      getPatientMeters: PropTypes.func.isRequired,
      getPatientTargets: PropTypes.func.isRequired,
      getPatientTesting: PropTypes.func.isRequired,
      putPatientTargets: PropTypes.func.isRequired
    }).isRequired,
    status: PropTypes.shape({
      getStatus: PropTypes.func.isRequired
    }).isRequired
  }).isRequired
};

class ProfileContainer extends Component {
  componentDidMount() {
    this.props.actions.avatar.getAvatar();
    this.props.actions.profile.getAboutMe();
    this.props.actions.profile.getPatientMedications();
    this.props.actions.settings.getNotificationSettings();
    this.props.actions.settings.getPatientTesting();
    this.props.actions.settings.getPatientMeters();
    this.props.actions.settings.getPatientTargets();
    this.props.actions.status.getStatus();
  }

  render() {
    return <Profile {...this.props} />;
  }
}

ProfileContainer.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      avatar: bindActionCreators(avatarActionCreators, dispatch),
      profile: bindActionCreators(profileActionCreators, dispatch),
      settings: bindActionCreators(settingsActionCreators, dispatch),
      status: bindActionCreators(statusActionCreators, dispatch)
    }
  };
}

export default connect(null, mapDispatchToProps)(immutableToJsHOC(ProfileContainer));
