/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const propTypes = {
  day: PropTypes.object,
  disabled: PropTypes.bool,
  inBetween: PropTypes.bool,
  noGo: PropTypes.bool,
  select: PropTypes.func,
  selected: PropTypes.bool,
  unselectable: PropTypes.bool
};

function Day({ day, disabled, inBetween, noGo, select, selected, unselectable }) {
  const classes = classNames(
    'c-calendar__day',
    { 'c-calendar__day--disabled': disabled },
    { 'c-calendar__day--no-go': noGo },
    { 'c-calendar__day--selected': selected },
    { 'c-calendar__day--in-between': inBetween }
  );

  return (
    <div
      onClick={() => !unselectable && select(day)}
      className={classes}
    >
      {day.format('D')}
    </div>
  );
}

Day.propTypes = propTypes;

export default Day;
