import { connect } from 'react-redux';
import {
  clearInvitesSearchResults,
  clearInvitesForm,
  searchPractices,
  searchHealthCareProviders,
  storeSearchQuery,
  storeType
} from '../actions/contactsInvitesActions';
import { immutableToJsHOC } from './ImmutableToJsHOC';
import ContactsHcpAndPracticeSearch from '../pages/contacts/children/invites/hcp/ContactsHcpAndPracticeSearch/ContactsHcpAndPracticeSearch';

function mapStateToProps(state) {
  return {
    searchQuery: state.contactsInvites.get('searchQuery'),
    type: state.contactsInvites.get('type')
  };
}

export default connect(mapStateToProps, {
  clearInvitesForm,
  clearInvitesSearchResults,
  storeSearchQuery,
  storeType,
  searchPractices,
  searchHealthCareProviders
})(immutableToJsHOC(ContactsHcpAndPracticeSearch));
