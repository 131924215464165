import actionTypes from '../constants/actionTypes';
import contactsIAmSharingSentInvitationsService from '../services/contactsIAmSharingInvitationsService';

function getIAmSharingWithSentInvitationsSuccess(response) {
  return {
    type: actionTypes.GET_CONTACTS_I_AM_SHARING_WITH_SENT_INVITATIONS_SUCCESS,
    payload: response
  };
}

export function getIAmSharingWithSentInvitations(searchQuery) {
  return async function (dispatch) {
    try {
      const results = await contactsIAmSharingSentInvitationsService.getIAmSharingWithSentInvitations(searchQuery);
      dispatch(getIAmSharingWithSentInvitationsSuccess(results));
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

function getIAmSharingWithReceivedInvitationsSuccess(response) {
  return {
    type: actionTypes.GET_CONTACTS_I_AM_SHARING_WITH_RECEIVED_INVITATIONS_SUCCESS,
    payload: response
  };
}

export function getIAmSharingWithReceivedInvitations(searchQuery) {
  return async function (dispatch) {
    try {
      const results = await contactsIAmSharingSentInvitationsService.getIAmSharingWithReceivedInvitations(searchQuery);
      dispatch(getIAmSharingWithReceivedInvitationsSuccess(results));
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

function getIAmSharingWithInvitationsCountSuccess(response) {
  return {
    type: actionTypes.GET_I_AM_SHARING_WITH_INVITATIONS_COUNT_SUCCESS,
    payload: response
  };
}

export function getIAmSharingWithInvitationsCount() {
  return async function (dispatch) {
    try {
      const results = await contactsIAmSharingSentInvitationsService.getIAmSharingWithInvitationsCount();
      dispatch(getIAmSharingWithInvitationsCountSuccess(results));
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

export function resendInviteToPatient(inviteId) {
  return async function () {
    try {
      await contactsIAmSharingSentInvitationsService.resendInviteToPatient(inviteId);
    } catch (err) {
      throw err;
    }
  };
}
