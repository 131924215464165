import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { parse } from 'query-string';
import { Form, Field } from 'react-final-form';
import dayjs from 'dayjs';
import formValidationService from 'src/services/formValidation';
import confirm from 'src/utils/ConfirmHelpers';
import TOKEN_TYPES from 'src/constants/tokenTypes';
import { normalizeMaxPasswordLength } from 'src/services/fieldNormalizationService';
import { registrationEvent } from 'src/services/countlyRegistrationTrackService';
import { countlyStartUserSession } from 'src/services/countlyService';
import MuiTextField from './muiComponents/MuiTextField';
import TermsAndCondition from './TermsAndCondition';

export class Register extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      authentication: PropTypes.shape({
        register: PropTypes.func.isRequired,
        signIn: PropTypes.func.isRequired
      }),
      settings: PropTypes.shape({
        postPatientMeterWithToken: PropTypes.func.isRequired
      }).isRequired,
      status: PropTypes.shape({
        getStatus: PropTypes.func.isRequired
      }).isRequired
    }),
    history: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.string.isRequired
      }).isRequired,
      push: PropTypes.func.isRequired
    }).isRequired,
    pogoToken: PropTypes.string
  };

  componentDidMount() {
    this.mountTime = dayjs();
  }

  formInstance = null;

  collectCountlyData = (values) => {
    // Countly funnel registration first step
    registrationEvent(this.mountTime);
    values.emailAddress && countlyStartUserSession(values.emailAddress);
  };

  handleECAccountCreate = async () => {
    const defaultOptions = {
      confirmText: 'Ok',
      headerText: 'Attention',
      showCancel: false
    };
    const bodyText = <span>You are currently an emergency contact for a <strong>Patterns<sup>&reg;</sup></strong> user. To be a <strong>Patterns<sup>&reg;</sup></strong> patient, you need to download <strong>Patterns<sup>&reg;</sup></strong> from the Apple App Store or Google Play Store, register for a patient account and pair a POGO Automatic<sup>&reg;</sup> monitor. If you do not have an iOS or Android phone, please call Customer Support at <a href="tel:+18554647646">1-855-IMI-POGO (464-7646)</a></span>;
    await confirm(bodyText, defaultOptions);
  }

  register = async (values) => {
    const {
      pogoToken,
      actions: { settings, status, authentication },
      history: { location: { search } }
    } = this.props;

    try {
      await authentication.register(values);
      this.collectCountlyData(values);
    } catch (err) {
      if (String(err.message).toLowerCase() === 'email address already in use at emergency_contact') {
        await this.handleECAccountCreate();
        return;
      }

      await this.formInstance.reset();
      return;
    }

    try {
      await authentication.signIn(values);
    } catch (err) {
      this.props.history.push('/welcome/sign-in');
      console.warn(err); // eslint-disable-line no-console
    }

    // If there is an add meter token, attempt to post it
    if (pogoToken) {
      try {
        await settings.postPatientMeterWithToken(TOKEN_TYPES.ADD_METER, pogoToken);
      } catch (err) {
        console.warn(err); // eslint-disable-line no-console
        // This function is QoL and isn't necessary for registration, so we don't care if this fails.
      }
    }

    try {
      await status.getStatus();
    } catch (err) {
      console.warn(err); // eslint-disable-line no-console
    }

    // registration and sign in successful, route appropriately
    const query = parse(search);
    this.props.history.push({
      pathname: query.next || '/',
      query: _omit(query, 'next')
    });
  }

  /* istanbul ignore next */
  validateFields(values) {
    return new formValidationService.Validation()
      .email(values.emailAddress)
      .emailMatches(values.emailAddress, values.emailConfirmation)
      .password(values.password)
      .passwordComplexity(values.password)
      .passwordMatches(values.password, values.passwordConfirmation)
      .passwordMaxLength(values.password)
      .termsAndConditionsAccepted(values.hasAcceptedTermsAndConditions)
      .getErrors();
  }

  render() {
    return (
      <Form
        onSubmit={this.register}
        validate={this.validateFields}
      >
        {({ handleSubmit, valid, submitting, hasSubmitErrors, pristine, form }) => {
          this.formInstance = form;

          return (
            <form
              name="registerForm"
              className="c-form"
              onSubmit={handleSubmit}
            >
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Email Address (will be your username)"
                name="emailAddress"
              />
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Confirm Email Address"
                name="emailConfirmation"
              />
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Password"
                name="password"
                parse={normalizeMaxPasswordLength}
                props={{ errorHiddenUnderText: 'Your secure password must be at least 8 characters in length and contain at least one uppercase letter, one lowercase letter and one number.' }}
                type="password"
              />
              <Field
                autoComplete="new-password"
                component={MuiTextField}
                label="Confirm Password"
                name="passwordConfirmation"
                type="password"
              />
              <TermsAndCondition />
              <br />
              <input
                className="c-button c-button--block c-button--hollow"
                disabled={(!valid && !hasSubmitErrors) || pristine || submitting}
                type="submit"
                value="Next"
              />
            </form>
          );
        }}
      </Form>
    );
  }
}

export default Register;
