/* istanbul ignore file */
import React from 'react';
import { contactsViewsConstants } from '../../../../../../constants/contactsInviteViews';
import { useInboxActionsContext } from '../../../../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';

export const fromHcpWrapper = Component => ({ ...props }) => {
  const { switchToView } = useInboxActionsContext();

  const goBack = () => {
    // eslint-disable-next-line react/prop-types
    switchToView(contactsViewsConstants.hcpSearchView);
  };

  return (
    <Component {...props} goBack={goBack} />
  );
};


