/* istanbul ignore file */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import { Grid, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import ModalContainer from 'src/components/modals/ModalContainer';
import Text from 'src/shared/Text/Text';
import { useStyles } from './Confirm.styles';

const propTypes = {
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  modalBody: PropTypes.node,
  proceed: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showCancel: PropTypes.bool.isRequired
};

export const Confirm = (props) => {
  const {
    cancelText,
    confirmText,
    headerText,
    modalBody = '',
    proceed,
    show,
    showCancel
  } = props;
  const classes = useStyles();
  const descriptionElementRef = useRef(null);

  return (
    <ModalContainer open={show} classes={{ paper: classes.container }}>
      {headerText && (
        <DialogTitle id="scroll-dialog-title">
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Text fontSize="24" font="bold" lineHeight="36" alignText="center">
                {headerText}
              </Text>
            </Grid>
          </Grid>
        </DialogTitle>
      )}

      {modalBody && (
        <DialogContent
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          className={classes.dialogContent}
        >
          <Grid container justifyContent="center">
            <Text fontSize="16" lineHeight="20" alignText="center">
              {modalBody}
            </Text>
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        <Grid container className={classes.buttonGroup} justifyContent="center" alignItems="center" spacing={2}>
          {showCancel && (
            <Grid item xs={6} sm={6} justifyContent="center" style={{ width: '100%' }}>
              <button onClick={() => proceed(false)} className="c-button c-button--block c-button--hollow">
                {cancelText}
              </button>
            </Grid>
          )}
          <Grid item xs={6} sm={6} justifyContent="center" style={{ width: !showCancel ? '190Px' : '100%' }}>
            <button data-testid="confirm" onClick={() => proceed(true)} className="c-button c-button--block c-button--action">
              {confirmText}
            </button>
          </Grid>
        </Grid>
      </DialogActions>
    </ModalContainer>
  );
};

Confirm.propTypes = propTypes;
export default confirmable(Confirm);
