/* istanbul ignore file */
import React from 'react';
import { contactsViewsConstants } from '../../../../../../constants/contactsInviteViews';
import { useInboxActionsContext } from '../../../../../../pages/contacts/children/invites/ContactsInviteManager/ContactsInviteManager';

// When we drill to practice Hcp list we need to know practice id to return back.
// So we pass it in "ContactsPracticeInfoView" and handle it on top level
export const fromPracticeWrapper = Component => ({ ...props }) => {
  const { switchToView } = useInboxActionsContext();

  const goBack = () => {
    // eslint-disable-next-line react/prop-types
    switchToView(contactsViewsConstants.practiceInfoView, { id: props.practiceId });
  };

  return (
    <Component {...props} goBack={goBack} />
  );
};
