import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  medications: new Immutable.Map({
    insulins: new Immutable.Map({
      injectableFast: new Immutable.List(),
      injectableLong: new Immutable.List(),
      pump: new Immutable.List(),
      inhaled: new Immutable.List()
    }),
    medicationTypes: new Immutable.List()
  }),
  practices: new Immutable.List()
});

export default function profileReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.REFRESH_TOKEN_START:
  case actionTypes.GET_ABOUT_ME_START:
  case actionTypes.GET_PATIENT_MEDICATIONS_START:
  case actionTypes.PUT_ABOUT_ME_START:
  case actionTypes.PUT_PATIENT_MEDICATIONS_START:
    return state.set('isActive', true);

  case actionTypes.GET_ABOUT_ME_SUCCESS:
    return state.withMutations((map) => {
      map.set('salutation', action.payload.profileInfo.salutation);
      map.set('firstName', action.payload.profileInfo.firstName);
      map.set('lastName', action.payload.profileInfo.lastName);
      map.set('dateOfBirth', action.payload.profileInfo.dateOfBirth);
      map.set('diabetesType', action.payload.profileInfo.diabetesType);
      map.set('gender', action.payload.profileInfo.gender);
      map.set('addressOne', action.payload.profileInfo.addressOne);
      map.set('addressTwo', action.payload.profileInfo.addressTwo);
      map.set('cityVillage', action.payload.profileInfo.cityVillage);
      map.set('stateProvince', action.payload.profileInfo.stateProvince);
      map.set('postalCode', action.payload.profileInfo.postalCode);
      map.set('country', action.payload.profileInfo.country);
      map.set('phoneNumbers', Immutable.fromJS(action.payload.profileInfo.phoneNumbers));
      map.set('cellNumber', Immutable.fromJS(action.payload.profileInfo.cellNumber));
      map.set('cellNumberVerified', Immutable.fromJS(action.payload.profileInfo.cellNumberVerified));
      map.set('homeNumber', Immutable.fromJS(action.payload.profileInfo.homeNumber));
      map.set('timezone', action.payload.profileInfo.timezone);
      map.set('locale', action.payload.profileInfo.locale);
      action.payload.profileInfo.practices && map.set('practices', Immutable.fromJS(action.payload.profileInfo.practices));
      map.set('specialty', action.payload.profileInfo.specialty);
      map.set('isActive', false);
    });

  case actionTypes.GET_PATIENT_MEDICATIONS_SUCCESS:
    return state.withMutations((map) => {
      map.set('medications', Immutable.fromJS(action.payload.medications));
    });

  case actionTypes.PUT_ABOUT_ME_SUCCESS:
  case actionTypes.PUT_PATIENT_MEDICATIONS_SUCCESS:
  case actionTypes.GET_ABOUT_ME_FAILURE:
  case actionTypes.GET_PATIENT_MEDICATIONS_FAILURE:
  case actionTypes.PUT_ABOUT_ME_FAILURE:
  case actionTypes.PUT_PATIENT_MEDICATIONS_FAILURE:
    return state.set('isActive', false);

  case actionTypes.REFRESH_TOKEN_FAILURE:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
