/* istanbul ignore file */
import { connect } from 'react-redux';
import { immutableToJsHOC } from './ImmutableToJsHOC';
// Actions
import { getIAmSharingWithSentInvitations } from '../actions/contactsIAmSharingWithInvitesActions';
// Components
import ContactsIAmSharingWithSentInvites from '../pages/contacts/children/iAmSharingWithSentInvites/ContactsIAmSharingWithSentInvites';

function mapStateToProps(state) {
  return {
    itemsList: state.contactsIAmSharingWithInvitations.getIn(['sentInvitations', 'invitations']),
    itemsListCount: state.contactsIAmSharingWithInvitations.getIn(['sentInvitations', 'sentInvitationCount'])
  };
}

export default connect(mapStateToProps, {
  getIAmSharingWithSentInvitations
})(immutableToJsHOC(ContactsIAmSharingWithSentInvites));
