/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string,
  transform: PropTypes.string
};

function ShapeAfter(props) {
  return (
    <g
      id="shape-legend"
      {...props}
    >
      <circle cx="12" cy="12" r="6" />
    </g>
  );
}

ShapeAfter.propTypes = propTypes;

export default ShapeAfter;
