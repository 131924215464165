import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import SearchedHcp from '../../../../../../components/contacts/SearchedHcp/SearchedHcp';
import SearchResultsEmpty from '../SearchResultsEmpty/SearchResultsEmpty';

const propTypes = {
  isSearching: PropTypes.bool,
  searchQuery: PropTypes.string,
  searchResults: PropTypes.array
};

function SearchHcpResults({ searchResults, searchQuery, isSearching }) {
  if (isSearching) {
    return <div className="h-center" data-testid="loading">Loading...</div>;
  }

  if (isEmpty(searchResults) && searchQuery.length > 2) {
    return <SearchResultsEmpty/>;
  }

  if (searchQuery.length <= 2) {
    return null;
  }

  return (
    <div className="h-full-width" data-testid="results">
      {searchResults.map((hcp, key) => <SearchedHcp hcp={hcp} key={key} />)}
    </div>
  );
}

SearchHcpResults.propTypes = propTypes;
export default SearchHcpResults;
