import React from 'react';
import PropTypes from 'prop-types';
import { BG_DISPLAY_MAX, BG_DISPLAY_MIN } from '../utils/readingHelpers';

const propTypes = {
  value: PropTypes.number
};

function GlucoseReading({ value }) {
  if (value >= BG_DISPLAY_MAX) {
    return <span data-testid="hi-value" className="c-reading__value">HI</span>;
  }

  if (value <= BG_DISPLAY_MIN) {
    return <span data-testid="lo-value" className="c-reading__value">LO</span>;
  }

  return (
    <React.Fragment>
      <span data-testid="default" className="c-reading__value">{value}</span>
      <span className="c-reading__units">mg/dL</span>
    </React.Fragment>
  );
}

GlucoseReading.propTypes = propTypes;

export default GlucoseReading;
