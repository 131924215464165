/* istanbul ignore file */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as healthDataActionCreators from '../actions/healthDataActions';
import * as readingTagsActionCreators from '../actions/readingTagsActions';
import * as readingsActionCreators from '../actions/readingsActions';
import * as settingsActionCreators from '../actions/settingsActions';
import * as validicActionCreators from '../actions/validicActions';
import { makeGetReadingsInTimeframe } from '../selectors/readingSelectors';
import Trends from '../components/Trends';

function makeMapStateToProps() {
  const getFilteredReadings = makeGetReadingsInTimeframe();

  return function (state, props) {
    return {
      filteredReadings: getFilteredReadings(state, props),
      patientTargets: state.settings.get('patientTargets'),
      readings: state.readings,
      settings: state.settings,
      timeframe: state.timeframe,
      validic: state.validic
    };
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      healthData: bindActionCreators(healthDataActionCreators, dispatch),
      readingTags: bindActionCreators(readingTagsActionCreators, dispatch),
      readings: bindActionCreators(readingsActionCreators, dispatch),
      settings: bindActionCreators(settingsActionCreators, dispatch),
      validic: bindActionCreators(validicActionCreators, dispatch)
    }
  };
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Trends);
