/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import Day from './Day';

const propTypes = {
  days: PropTypes.array,
  monthNumber: PropTypes.number,
  select: PropTypes.func,
  selectedFrom: PropTypes.string,
  selectedTo: PropTypes.string,
  selecting: PropTypes.string
};

function Week(props) {
  const {
    days,
    monthNumber,
    select,
    selectedFrom,
    selectedTo,
    selecting
  } = props;

  return (
    <div className="c-calendar__week">
      {days.map((day, i) => {
        const isAfter = day.isAfter(selectedTo);
        const isBefore = day.isSameOrBefore(selectedFrom);
        const thirty = selectedFrom && moment(selectedFrom).add(29, 'days').endOf('day');
        const isGtThirty = day.isAfter(thirty);
        const today = moment().endOf('day');
        const isInTheFuture = day.isAfter(today);
        const formattedDay = day.utc().format();
        const notPartOfMonth = parseFloat(day.format('M')) !== parseFloat(monthNumber);
        const selectingFrom = selecting === 'from';

        return (
          <Day
            key={i}
            day={day}
            disabled={isBefore || isGtThirty || notPartOfMonth}
            inBetween={(selectedTo && selectedFrom) && (!isBefore && !isAfter)}
            noGo={isInTheFuture}
            select={select}
            selected={formattedDay === selectedFrom || formattedDay === selectedTo}
            unselectable={!selectingFrom && (isBefore || isGtThirty) || isInTheFuture}
          />
        );
      })}
    </div>
  );
}

Week.propTypes = propTypes;

export default Week;
