import contactsInvitesService from '../services/contactsInvitesService';
import actionTypes from '../constants/actionTypes';

function searchHealthCareProvidersSuccess(payload) {
  return {
    type: actionTypes.SEARCH_HEALTHCARE_PROVIDERS_SUCCESS,
    payload
  };
}

function searchHealthCareProvidersFailure() {
  return {
    type: actionTypes.SEARCH_HEALTHCARE_PROVIDERS_FAILURE
  };
}

function searchHealthCareProvidersStart() {
  return {
    type: actionTypes.SEARCH_HEALTHCARE_PROVIDERS_START
  };
}

export function searchHealthCareProviders(searchQuery) {
  return async function (dispatch) {
    dispatch(searchHealthCareProvidersStart());
    try {
      const results = await contactsInvitesService.searchHealthCareProviders(searchQuery);
      dispatch(searchHealthCareProvidersSuccess(results));
    } catch (err) {
      dispatch(searchHealthCareProvidersFailure());
      console.error(err); // eslint-disable-line
    }
  };
}

function searchPracticesSuccess(payload) {
  return {
    type: actionTypes.SEARCH_PRACTICES_SUCCESS,
    payload
  };
}

function searchPracticesFailure() {
  return {
    type: actionTypes.SEARCH_PRACTICES_FAILURE
  };
}

function searchPracticesStart() {
  return {
    type: actionTypes.SEARCH_PRACTICES_START
  };
}

export function searchPractices(searchQuery) {
  return async function (dispatch) {
    dispatch(searchPracticesStart());
    try {
      const results = await contactsInvitesService.searchPractices(searchQuery);
      dispatch(searchPracticesSuccess(results));
    } catch (err) {
      dispatch(searchPracticesFailure());
      console.error(err); // eslint-disable-line
    }
  };
}

function getHcpInfoStart() {
  return {
    type: actionTypes.GET_HCP_INFO_START
  };
}

function getHcpInfoSuccess(payload) {
  return {
    type: actionTypes.GET_HCP_INFO_SUCCESS,
    payload
  };
}

function getHcpInfoFailure() {
  return {
    type: actionTypes.GET_HCP_INFO_FAILURE
  };
}

export function getHcpInfo(id) {
  return async function (dispatch) {
    dispatch(getHcpInfoStart());
    try {
      const results = await contactsInvitesService.getHcpInfo(id);
      dispatch(getHcpInfoSuccess(results));
    } catch (err) {
      dispatch(getHcpInfoFailure());
      console.error(err); // eslint-disable-line
    }
  };
}

function getHcpListInPracticeStart() {
  return {
    type: actionTypes.GET_HCP_LIST_IN_PRACTICE_START
  };
}

function getHcpListInPracticeSuccess(payload) {
  return {
    type: actionTypes.GET_HCP_LIST_IN_PRACTICE_SUCCESS,
    payload
  };
}

function getHcpListInPracticeFailure() {
  return {
    type: actionTypes.GET_HCP_LIST_IN_PRACTICE_FAILURE
  };
}

export function getHcpListInPractice(id) {
  return async function (dispatch) {
    dispatch(getHcpListInPracticeStart());
    try {
      const results = await contactsInvitesService.getHcpListInPractice(id);
      dispatch(getHcpListInPracticeSuccess(results));
    } catch (err) {
      dispatch(getHcpListInPracticeFailure());
      console.error(err); // eslint-disable-line
    }
  };
}

export function invitePractice(id) {
  return async function () {
    try {
      await contactsInvitesService.invitePractice(id);
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
  };
}

export function inviteSharingCircleOrEmergencyContact(formData) {
  return async function () {
    try {
      await contactsInvitesService.inviteSharingCircleOrEmergencyContact(formData);
    } catch (err) {
      throw err;
    }
  };
}

export function storeSearchQuery(payload) {
  return {
    type: actionTypes.STORE_INVITES_SEARCH_QUERY,
    payload
  };
}

export function storeType(payload) {
  return {
    type: actionTypes.STORE_INVITES_TYPE,
    payload
  };
}

export function clearInvitesSearchResults() {
  return {
    type: actionTypes.CLEAR_INVITES_SEARCH_RESULTS
  };
}

export function clearInvitesForm() {
  return {
    type: actionTypes.RESET_INVITES_FORM
  };
}
