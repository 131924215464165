import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Container,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles
} from '@material-ui/core';
import apiService from 'src/services/apiService';
import ModalContainer from 'src/components/modals/ModalContainer';
import { StyledDialogTitle } from './muiStyledComponents/StyledDialogTitle';
import { StyledDialogActions } from './muiStyledComponents/StyledDialogActions';

dayjs.extend(utc);

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  hypoTrendReadings: PropTypes.array
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    padding: '16Px',
    width: '40%',
    '&:nth-of-type(3)': {
      width: '20%'
    }
  },
  body: {
    padding: '16Px',
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff'
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#f5f5f5'
    }
  }
}))(TableRow);

export default function HypoTrendNotificator({ closeModal, hypoTrendReadings }) {
  const history = useHistory();
  const markAsViewed = async () => {
    try {
      await apiService.post('notifications/mark_hypo_trend_viewed');
    } catch (err) {
      console.warn(err); // eslint-disable-line no-console
    }
  };

  const handleConfirm = () => {
    markAsViewed();
    history.push('/dashboard');
    closeModal();
  };

  return (
    <ModalContainer open>
      <Container maxWidth="sm" className="h-padded-lg">
        <Grid container justifyContent="space-between" alignItems="center" direction="column">
          <StyledDialogTitle style={{ textAlign: 'left' }}>
            <strong className="h-padded-bottom">Pattern Detected</strong>
          </StyledDialogTitle>
          <p>
            Over the last 7 days, you had the following blood glucose readings:
          </p>

          <TableContainer component={Paper} elevation={0} style={{ maxHeight: 250 }}>
            <Table className="" aria-label="simple table" stickyHeader style={{ maxWidth: '100%', minWidth: 'auto' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell><strong>DAY</strong></StyledTableCell>
                  <StyledTableCell><strong>TIME</strong></StyledTableCell>
                  <StyledTableCell><strong>mg/dL</strong></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hypoTrendReadings.map((el, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell component="th" scope="row" style={{ fontSize: '16PX' }}>
                      {dayjs(el.timestamp).format('ddd, MMMM DD')}
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{ fontSize: '16PX' }}>{dayjs(el.timestamp).format('h:mm[ ]a')}</StyledTableCell>
                    <StyledTableCell align="left" style={{ fontSize: '16PX' }}>{el.blood_glucose}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <StyledDialogActions>
            <button
              data-testid="close"
              className="c-button c-button--block c-button--hollow h-margin"
              onClick={closeModal}
            >
              Reminder me later
            </button>
            <button
              data-testid="confirm"
              className="c-button c-button--block c-button--action h-margin"
              onClick={handleConfirm}
            >
              Ok
            </button>
          </StyledDialogActions>
        </Grid>
      </Container>
    </ModalContainer>
  );
}

HypoTrendNotificator.propTypes = propTypes;
