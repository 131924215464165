import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@material-ui/core';
import LogoSplash from 'src/components/LogoSplash';
import { useStyles } from './TokenViewWrapper.styles';

const propTypes = {
  children: PropTypes.node
};

export default function TokenViewWrapper({ children }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      <Grid container>
        <Grid item xs={12} justifyContent="center" className={classes.splash}>
          <LogoSplash/>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.container}>
        <Grid container justifyContent="center" direction="column">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

TokenViewWrapper.propTypes = propTypes;
