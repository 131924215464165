import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

export const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  water: new Immutable.Map({
    cache: new Immutable.Map(),
    totals: new Immutable.Map()
  }),
  carbs: new Immutable.Map({
    cache: new Immutable.Map(),
    totals: new Immutable.Map()
  })
});

export default function readingTagsReducer(state = INITIAL_STATE, action) { // NOSONAR
  switch (action.type) {
  case actionTypes.REFRESH_TOKEN_START:
  case actionTypes.GET_RANGE_OF_READING_TAGS_START:
    return state.set('isActive', true);

  case actionTypes.GET_RANGE_OF_READING_TAGS_SUCCESS:
    return state.withMutations((map) => {
      map.setIn(['water', 'cache'], Immutable.fromJS(action.payload.tags.map((el) => ({ ...el, timestamp: el.createdAt })).filter((el) => {
        return el.type === 'water';
      })));
      map.setIn(['carbs', 'cache'], Immutable.fromJS(action.payload.tags.map((el) => ({ ...el, timestamp: el.createdAt })).filter((el) => {
        return el.type === 'carbs';
      })));
      map.setIn(['water', 'totals'], Immutable.fromJS(action.payload.totals.filter((el) => {
        return el.type === 'water';
      })));
      map.setIn(['carbs', 'totals'], Immutable.fromJS(action.payload.totals.filter((el) => {
        return el.type === 'carbs';
      })));
    });

  case actionTypes.REFRESH_TOKEN_SUCCESS:
  case actionTypes.REFRESH_TOKEN_FAILURE:
  case actionTypes.GET_RANGE_OF_READING_TAGS_FAILURE:
    return state.set('isActive', false);

  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}
