import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  'default': {
    '&:not(last-child)': {
      margin: 0
    }
  },
  'font-regular': {
    fontFamily: '"HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif !important'
  },
  'font-bold': {
    fontWeight: 700,
    fontFamily: '"HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif !important'
  },
  'color-body': {
    color: theme.palette.text.main // #282829;
  },
  'color-secondary': {
    color: theme.palette.text.secondary // #5D5D5D
  },
  'color-body-light': {
    color: theme.palette?.text?.light // #B7B7B7
  },
  'color-error': {
    color: theme.palette?.error?.main
  },
  'color-active': {
    color: theme.palette?.secondary?.main // #2da9df
  },
  'color-lime': {
    color: theme.palette?.success?.main
  },
  'color-yellow': {
    color: theme.palette?.yellow?.main
  },
  'background-error': {
    backgroundColor: theme.palette?.error?.light,
    padding: '8Px 8Px',
    borderRadius: '2Px'
  },
  'line-height-14': {
    lineHeight: '14Px'
  },
  'line-height-15': {
    lineHeight: '15Px'
  },
  'line-height-16': {
    lineHeight: '16Px'
  },
  'line-height-18': {
    lineHeight: '18Px'
  },
  'line-height-20': {
    lineHeight: '20Px'
  },
  'line-height-22': {
    lineHeight: '22Px'
  },
  'line-height-24': {
    lineHeight: '24Px'
  },
  'line-height-28': {
    lineHeight: '28Px'
  },
  'line-height-30': {
    lineHeight: '30Px'
  },
  'line-height-32': {
    lineHeight: '32Px'
  },
  'line-height-36': {
    lineHeight: '36Px'
  },
  'line-height-46': {
    lineHeight: '46Px'
  },
  'font-size-11': {
    fontSize: '11Px'
  },
  'font-size-12': {
    fontSize: '12Px'
  },
  'font-size-14': {
    fontSize: '14Px'
  },
  'font-size-16': {
    fontSize: '16Px'
  },
  'font-size-18': {
    fontSize: '18Px'
  },
  'font-size-20': {
    fontSize: '20Px'
  },
  'font-size-22': {
    fontSize: '22Px'
  },
  'font-size-24': {
    fontSize: '24Px'
  },
  'font-size-26': {
    fontSize: '26Px'
  },
  'font-size-28': {
    fontSize: '28Px'
  },
  'font-size-32': {
    fontSize: '32Px'
  },
  'font-size-36': {
    fontSize: '36Px'
  },
  'align-text-center': {
    textAlign: 'center'
  },
  'align-text-right': {
    textAlign: 'right'
  },
  'align-text-left': {
    textAlign: 'left'
  },
  'white-space-pre': {
    whiteSpace: 'pre'
  },
  'white-space-initial': {
    whiteSpace: 'initial'
  }
}));
