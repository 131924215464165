import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LogIconRow from './LogIconRow';
import MealIconChooser from 'src/components/icons/MealIconChooser';
import GlucoseReading from 'src/components/GlucoseReading';
import LogBookFetcherContainer from 'src/containers/LogBookFetcherContainer';
import { determineModifier, isMealTag } from 'src/utils/readingHelpers';
import triangleRight from 'src/assets/img/icons/triangleRight.svg';

dayjs.extend(utc);

const propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object.isRequired
  }).isRequired,
  patientTargets: PropTypes.shape({
    targetHigh: PropTypes.number,
    targetLow: PropTypes.number
  }).isRequired,
  reading: PropTypes.shape({
    id: PropTypes.number.isRequired,
    timestamp: PropTypes.string,
    full_tags: PropTypes.array,
    tags: PropTypes.array,
    bloodGlucose: PropTypes.number
  }),
  readingRouteId: PropTypes.string
};

export function LogRow(props) {
  const { history, patientTargets, reading } = props;

  const match = /\/(\d+)\/{0,1}/.exec(history.location.pathname); // NOSONAR
  const readingRouteId = match && match.length > 0 && match.pop();
  const time = dayjs.utc(reading.timestamp).format('h:mma');
  const glucose = reading.bloodGlucose;
  const mealTag = reading.tags ? reading.tags.find(isMealTag) : null;
  const isSelected = readingRouteId && `${readingRouteId}` === `${reading.id}`;
  const modifier = determineModifier(glucose, patientTargets.targetHigh, patientTargets.targetLow);
  const logClasses = classNames(
    'c-log',
    `c-log--${modifier}`,
    { 'c-log--selected': isSelected }
  );
  const triangleClass = classNames(
    'c-log__triangle',
    { 'c-log__triangle--visible': isSelected }
  );
  const readingClasses = `c-log__reading c-reading c-reading--${modifier}`;
  const mealIconClasses = `c-time__icon c-icon--alt c-icon--${mealTag}`;

  return (
    <li className="c-log--bordered h-position--relative">
      <LogBookFetcherContainer
        readingId={reading.id}
        isSelected={isSelected}
        classNames={logClasses}
      >
        <span className="c-log__time">
          <span className="c-time__value">{time}</span>
          {mealTag && <MealIconChooser className={mealIconClasses} mealTag={mealTag}/>}
        </span>
        <span className="c-log__icons c-icon-row">
          <LogIconRow tags={reading.full_tags}/>
        </span>
        <span className={readingClasses}>
          <GlucoseReading value={glucose}/>
        </span>
        <img src={triangleRight} className={triangleClass} />
      </LogBookFetcherContainer>
    </li>
  );
}

LogRow.propTypes = propTypes;

export default withRouter(LogRow);
